import { CANCELED_CODE } from 'constants/errorsCode'

import {
	ADD_PRODUCTION_ITEMS_FROM_SCANNED_ORDER,
	COMPLETE_PRODUCTION,
	COMPLETE_PRODUCTION_FAIL,
	COMPLETE_PRODUCTION_SUCCESS,
	GET_FILTERED_PRODUCTIONS,
	GET_FILTERED_PRODUCTIONS_FAIL,
	GET_FILTERED_PRODUCTIONS_SUCCESS,
	GET_PRODUCTIONS_BY_TRACKING,
	GET_PRODUCTIONS_BY_TRACKING_FAIL,
	GET_PRODUCTIONS_BY_TRACKING_SUCCESS,
	GET_PRODUCTIONS_LIST_FROM_ORDER_ID,
	GET_PRODUCTIONS_LIST_FROM_ORDER_ID_FAIL,
	GET_PRODUCTIONS_LIST_FROM_ORDER_ID_SUCCESS,
	GET_PRODUCTIONS_PICKED_UP_BY_COURIER,
	GET_PRODUCTIONS_PICKED_UP_BY_COURIER_FAIL,
	GET_PRODUCTIONS_PICKED_UP_BY_COURIER_SUCCESS,
	GET_PRODUCTION_CHAT,
	GET_PRODUCTION_CHAT_FAIL,
	GET_PRODUCTION_CHAT_SUCCESS,
	GET_PRODUCTION_DETAIL,
	GET_PRODUCTION_DETAIL_FAIL,
	GET_PRODUCTION_DETAIL_SUCCESS,
	GET_PRODUCTION_ITEMS_BY_ORDER_ID,
	GET_PRODUCTION_ITEMS_BY_ORDER_ID_FAIL,
	GET_PRODUCTION_ITEMS_BY_ORDER_ID_SUCCESS,
	GET_PRODUCTION_ITEMS_BY_PART_ID,
	GET_PRODUCTION_ITEMS_BY_PART_ID_FAIL,
	GET_PRODUCTION_ITEMS_BY_PART_ID_SUCCESS,
	GET_PRODUCTION_ITEMS_LABELS,
	GET_PRODUCTION_ITEMS_LABELS_FAIL,
	GET_PRODUCTION_ITEMS_LABELS_SUCCESS,
	GET_PRODUCTION_LIST,
	GET_PRODUCTION_LIST_BY_SUPPLIER,
	GET_PRODUCTION_LIST_BY_SUPPLIER_FAIL,
	GET_PRODUCTION_LIST_BY_SUPPLIER_SUCCESS,
	GET_PRODUCTION_LIST_FAIL,
	GET_PRODUCTION_LIST_SUCCESS,
	GET_PURCHASES_DASHBOARD,
	GET_PURCHASES_DASHBOARD_FAIL,
	GET_PURCHASES_DASHBOARD_SUCCESS,
	GET_SHIPMENTS_IMPORTS,
	GET_SHIPMENTS_IMPORTS_FAIL,
	GET_SHIPMENTS_IMPORTS_SUCCESS,
	GET_SUPPLIER_SHIPMENT_PRICING_LIST,
	GET_SUPPLIER_SHIPMENT_PRICING_LIST_FAIL,
	GET_SUPPLIER_SHIPMENT_PRICING_LIST_SUCCESS,
	GET_TRACKING_NUMBERS_FROM_PROVIDER,
	GET_TRACKING_NUMBERS_FROM_PROVIDER_FAIL,
	GET_TRACKING_NUMBERS_FROM_PROVIDER_SUCCESS,
	HANDLE_COMPLETE_PRODUCTION,
	HANDLE_COMPLETE_PRODUCTION_FAIL,
	HANDLE_COMPLETE_PRODUCTION_SUCCESS,
	LAUNCH_PRODUCTION,
	LAUNCH_PRODUCTION_FAIL,
	LAUNCH_PRODUCTION_SUCCESS,
	POST_PRODUCITON_CHAT,
	POST_PRODUCITON_CHAT_FAIL,
	POST_PRODUCITON_CHAT_SUCCESS,
	REJECT_PRODUCTION,
	REJECT_PRODUCTION_FAIL,
	REJECT_PRODUCTION_SUCCESS,
	UPDATE_PRODUCTION,
	UPDATE_PRODUCTION_FAIL,
	UPDATE_PRODUCTION_ITEM,
	UPDATE_PRODUCTION_ITEM_FAIL,
	UPDATE_PRODUCTION_ITEM_SUCCESS,
	UPDATE_PRODUCTION_SUCCESS,
	UPDATE_SHIPMENT_IMPORT,
	UPDATE_SHIPMENT_IMPORT_FAIL,
	UPDATE_SHIPMENT_IMPORT_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
	productionList: [],
	productionDetail: {},
	productionLabels: null,
	isLoadingProductonLabels: false,
	isLoading: false,
	updateSuccess: null,
	error: null,
	productionChat: [],
	productionsPickedUpByCourier: [],
	productionItemsfromScannedOrders: [],
	productionItemsByOrderId: [],
	productionsListByOrderId: [],
	isProductionRejected: null,
	errorProductionLabels: null,
	isLoadingHandleCompleteProduction: false,
	shipmentsImportsData: {
		list: [],
		totalCount: null,
		statusGet: {
			isLoading: null,
			success: null,
			error: null,
		},
		statusUpdate: {
			isLoading: null,
			success: null,
			error: null,
		},
	},
	purchasesItems: [],
	purchasesStats: [],
	productionsByTracking: [],
	updateProduction_Status: {
		loading: false,
		success: null,
		error: null,
	},
	updateProductionItemCallStatus: {
		loading: false,
		success: null,
		error: null,
	},
	getTrackingNumbersFromProvider_Status: {
		loading: false,
		error: null,
		response: null,
	},
}

const Productions = (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_PRODUCTION_LIST:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_PRODUCTION_LIST_SUCCESS:
			return {
				...state,
				productionList: action.payload.productionList,
				count: action.payload.count,
				error: null,
				isLoading: false,
			}
		case GET_PRODUCTION_LIST_FAIL:
			return {
				...state,
				productionList: [],
				error: action.payload,
				isLoading: false,
			}
		case GET_PRODUCTION_DETAIL:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_PRODUCTION_DETAIL_SUCCESS:
			return {
				...state,
				productionDetail: {
					production: action.payload.production,
				},
				error: null,
				isLoading: false,
			}
		case GET_PRODUCTION_DETAIL_FAIL:
			return {
				...state,
				productionDetail: {},
				error: action.payload,
				isLoading: false,
			}
		case GET_PRODUCTION_LIST_BY_SUPPLIER:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_PRODUCTION_LIST_BY_SUPPLIER_SUCCESS:
			return {
				...state,
				productionList: action.payload.productions,
				count: action.payload.count,
				error: null,
				isLoading: false,
			}
		case GET_PRODUCTION_LIST_BY_SUPPLIER_FAIL:
			return {
				...state,
				productionList: [],
				error: action.payload,
				isLoading: false,
			}
		case GET_SUPPLIER_SHIPMENT_PRICING_LIST:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_SUPPLIER_SHIPMENT_PRICING_LIST_SUCCESS:
			return {
				...state,
				supplierShipmentPricingList: action.payload.pricings,
				error: null,
				isLoading: false,
			}
		case GET_SUPPLIER_SHIPMENT_PRICING_LIST_FAIL:
			return {
				...state,
				supplierShipmentPricingList: [],
				error: action.payload,
				isLoading: false,
			}
		case LAUNCH_PRODUCTION:
			return {
				...state,
				isLaunchingProduction: true,
				error: null,
			}
		case LAUNCH_PRODUCTION_FAIL:
			return {
				...state,
				error: action.payload,
				launchProductionFail: true,
				isLaunchingProduction: false,
			}
		case GET_PRODUCTION_ITEMS_BY_PART_ID:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_PRODUCTION_ITEMS_BY_PART_ID_SUCCESS:
			return {
				...state,
				productionItemsByPartId: action.payload.parts,
				error: null,
				isLoading: false,
			}
		case GET_PRODUCTION_ITEMS_BY_PART_ID_FAIL:
			return {
				...state,
				productionList: [],
				error: action.payload,
				isLoading: false,
			}
		case GET_PRODUCTION_CHAT:
		case GET_PRODUCTION_ITEMS_LABELS:
			return {
				...state,
				productionLabels: null,
				isLoadingProductonLabels: true,
				errorProductionLabels: null,
			}
		case GET_PRODUCTION_ITEMS_LABELS_SUCCESS:
			return {
				...state,
				productionLabels: action.payload,
				errorProductionLabels: null,
				isLoadingProductonLabels: false,
			}
		case GET_PRODUCTION_ITEMS_LABELS_FAIL:
			return {
				...state,
				productionLabels: null,
				errorProductionLabels: action.payload.message,
				isLoadingProductonLabels: false,
			}
		case UPDATE_PRODUCTION:
			return {
				...state,
				isLoading: true,
				error: null,
				updateProduction_Status: {
					loading: true,
					success: null,
					error: null,
				},
			}
		case GET_PRODUCTION_CHAT_SUCCESS:
			return {
				...state,
				productionChat: action.payload.productionChatMessages,
				error: null,
				isLoading: false,
			}
		case GET_PRODUCTION_CHAT_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}
		case POST_PRODUCITON_CHAT:
			return {
				...state,
				error: null,
			}
		case POST_PRODUCITON_CHAT_SUCCESS:
			if (action.payload.productionChatMessage.content !== 'emptyMessageToIncludeImage') {
				return {
					...state,
					productionChat: [...state.productionChat, action.payload.productionChatMessage],
					error: null,
					isLoading: false,
				}
			} else {
				return {
					...state,
					error: null,
					isLoading: false,
				}
			}
		case POST_PRODUCITON_CHAT_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}

		case UPDATE_PRODUCTION_SUCCESS:
			return {
				...state,
				isLoading: false,
				updateSuccess: true,
				error: false,
				updateProduction_Status: {
					loading: false,
					success: true,
					error: false,
				},
			}
		case UPDATE_PRODUCTION_FAIL:
			return {
				...state,
				isLoading: false,
				error: true,
				updateProduction_Status: {
					loading: false,
					success: false,
					error: true,
				},
			}

		case UPDATE_PRODUCTION_ITEM:
			return {
				...state,
				updateProductionItemCallStatus: {
					loading: true,
					success: null,
					error: null,
				},
			}

		case UPDATE_PRODUCTION_ITEM_SUCCESS:
			return {
				...state,
				updateProductionItemCallStatus: {
					loading: false,
					success: true,
					error: false,
				},
			}
		case UPDATE_PRODUCTION_ITEM_FAIL:
			return {
				...state,
				updateProductionItemCallStatus: {
					loading: false,
					success: false,
					error: true,
				},
			}

		case COMPLETE_PRODUCTION:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case COMPLETE_PRODUCTION_SUCCESS:
			return {
				...state,
				productionDetail: {
					production: {
						...state.productionDetail.production,
						...action.payload.production,
					},
				},
				completeProductionSuccess: true,
				isLoading: false,
				error: false,
			}
		case COMPLETE_PRODUCTION_FAIL:
			return {
				...state,
				isLoading: false,
				error: true,
			}

		case GET_PRODUCTION_ITEMS_BY_ORDER_ID:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_PRODUCTION_ITEMS_BY_ORDER_ID_SUCCESS:
			return {
				...state,
				productionItemsByOrderId: action.payload.productionItems,
				error: null,
				isLoading: false,
			}
		case GET_PRODUCTION_ITEMS_BY_ORDER_ID_FAIL:
			return {
				...state,
				productionList: [],
				error: action.payload,
				isLoading: false,
			}

		case LAUNCH_PRODUCTION_SUCCESS:
			return {
				...state,
				error: null,
				isLaunchingProduction: false,
				launchProductionSuccess: true,
				production: action.payload.production,
			}

		case GET_PRODUCTIONS_PICKED_UP_BY_COURIER:
			return {
				...state,
				isLoading: true,
			}
		case GET_PRODUCTIONS_PICKED_UP_BY_COURIER_SUCCESS:
			return {
				...state,
				isLoading: false,
				productionsPickedUpByCourier: action.payload.productions,
			}
		case GET_PRODUCTIONS_PICKED_UP_BY_COURIER_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}
		case GET_PRODUCTIONS_LIST_FROM_ORDER_ID:
		case REJECT_PRODUCTION:
			return {
				...state,
				isLoading: true,
			}
		case GET_PRODUCTIONS_LIST_FROM_ORDER_ID_SUCCESS:
			return {
				...state,
				isLoading: false,
				productionsListByOrderId: action.payload.productions,
			}
		case GET_PRODUCTIONS_LIST_FROM_ORDER_ID_FAIL:
		case REJECT_PRODUCTION_SUCCESS:
			return {
				...state,
				isLoading: false,
				isProductionRejected: true,
			}
		case REJECT_PRODUCTION_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}
		case ADD_PRODUCTION_ITEMS_FROM_SCANNED_ORDER:
			return {
				...state,
				productionItemsfromScannedOrders: [...state.productionItemsfromScannedOrders, action.payload],
				isProductionRejected: false,
			}
		case GET_FILTERED_PRODUCTIONS:
			return {
				...state,
				isLoading: true,
				productionList: [],
				error: null,
			}
		case GET_FILTERED_PRODUCTIONS_SUCCESS:
			return {
				...state,
				productionList: action.payload.productions,
				count: action.payload.totalRows,
				error: null,
				isLoading: false,
			}
		case GET_FILTERED_PRODUCTIONS_FAIL:
			return {
				...state,
				productionList: [],
				error: action.payload.error.message,
				isLoading: action.payload.error.code === CANCELED_CODE,
			}
		case GET_SHIPMENTS_IMPORTS:
			return {
				...state,
				shipmentsImportsData: {
					...state.shipmentsImportsData,
					statusGet: {
						isLoading: true,
						success: null,
						error: null,
					},
				},
			}
		case GET_SHIPMENTS_IMPORTS_SUCCESS:
			return {
				...state,
				shipmentsImportsData: {
					...state.shipmentsImportsData,
					list: action.payload.list,
					totalCount: action.payload.totalCount,
					statusGet: {
						isLoading: false,
						success: true,
						error: null,
					},
				},
			}
		case GET_SHIPMENTS_IMPORTS_FAIL:
			return {
				...state,
				shipmentsImportsData: {
					...state.shipmentsImportsData,
					statusGet: {
						isLoading: false,
						success: null,
						error: action.payload?.error?.message || 'error',
					},
				},
			}
		case UPDATE_SHIPMENT_IMPORT:
			return {
				...state,
				shipmentsImportsData: {
					...state.shipmentsImportsData,
					statusUpdate: {
						isLoading: true,
						success: null,
						error: null,
					},
				},
			}
		case UPDATE_SHIPMENT_IMPORT_SUCCESS:
			return {
				...state,
				shipmentsImportsData: {
					...state.shipmentsImportsData,
					statusUpdate: {
						isLoading: false,
						success: true,
						error: null,
					},
				},
			}
		case UPDATE_SHIPMENT_IMPORT_FAIL:
			return {
				...state,
				shipmentsImportsData: {
					...state.shipmentsImportsData,
					statusUpdate: {
						isLoading: false,
						success: null,
						error: action.payload?.error?.message || 'error',
					},
				},
			}

		case HANDLE_COMPLETE_PRODUCTION:
			return {
				...state,
				error: null,
				isLoadingHandleCompleteProduction: true,
			}
		case HANDLE_COMPLETE_PRODUCTION_SUCCESS:
			return {
				...state,
				error: false,
				isLoadingHandleCompleteProduction: false,
			}
		case HANDLE_COMPLETE_PRODUCTION_FAIL:
			return {
				error: action.payload,
				isLoadingHandleCompleteProduction: false,
			}
		case GET_PURCHASES_DASHBOARD:
			return {
				...state,
				error: null,
				isLoading: true,
			}
		case GET_PURCHASES_DASHBOARD_SUCCESS:
			return {
				...state,
				purchasesItems: action.payload.items,
				purchasesStats: action.payload.stats,
				error: null,
				isLoading: false,
			}
		case GET_PURCHASES_DASHBOARD_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoading: false,
			}
		case GET_PRODUCTIONS_BY_TRACKING:
			return {
				...state,
				error: null,
				isLoading: true,
				productionsByTracking: [],
			}
		case GET_PRODUCTIONS_BY_TRACKING_SUCCESS:
			return {
				...state,
				productionsByTracking: action.payload.productions,
				error: null,
				isLoading: false,
			}
		case GET_PRODUCTIONS_BY_TRACKING_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoading: false,
			}
		case GET_TRACKING_NUMBERS_FROM_PROVIDER:
			return {
				...state,
				getTrackingNumbersFromProvider_Status: {
					loading: true,
					error: null,
					response: null,
				},
			}
		case GET_TRACKING_NUMBERS_FROM_PROVIDER_SUCCESS:
			return {
				...state,
				getTrackingNumbersFromProvider_Status: {
					loading: false,
					error: null,
					response: action.payload,
				},
			}
		case GET_TRACKING_NUMBERS_FROM_PROVIDER_FAIL:
			return {
				...state,
				getTrackingNumbersFromProvider_Status: {
					loading: false,
					error: action.payload.message,
					response: null,
				},
			}
		default:
			return state
	}
}
export default Productions
