import Dropdown from 'components/Common/Dropdown/Dropdown'
import PartsFinder from 'components/Common/PartsFinder'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
class OfferTopMenu extends Component {
	handleEditDropDown(value) {
		alert(JSON.stringify(value))
	}

	render() {
		const { t } = this.props
		return (
			<React.Fragment>
				<div className="d-flex justify-content-between align-items mb-3">
					<div className="d-flex">
						<Dropdown
							id={'Actions'}
							value={t('actions', { ns: 'naming' })}
							className="d-flex justify-content-start align-items-center h-100 btn-sm btn-round btn btn-secondary mx-2"
							sameWidth
							options={[
								{
									value: 0,
									text: t('upload', { ns: 'naming' }),
									action: () => this.props.openUploadModal(),
									isDisabled: false,
								},
								{
									value: 1,
									text: t('requestQuote', { ns: 'naming' }),
									action: () => this.props.openQuotationModal(),
									isDisabled: false,
								},
								{
									value: 2,
									text: t('simulateOrder', { ns: 'naming' }),
									action: () => this.props.openOrderSimulationModal(),
									isDisabled: false,
								},
								{
									value: 3,
									text: t('cloneQuote', { ns: 'naming' }),
									action: () => this.props.handleOnChangeCloneQuoteModal(true),
									isDisabled: false,
								},
								{
									value: 4,
									text: t('sendEmail', { ns: 'naming' }),
									action: () => this.props.openSendUploadEmailModal(),
									isDisabled: false,
								},
							]}
						/>
						<Dropdown
							id={'Edit'}
							value={t('edit', { ns: 'naming' })}
							className="d-flex justify-content-start align-items-center h-100 btn-sm btn-round btn btn-secondary mx-2"
							sameWidth
							options={[
								{
									value: 0,
									text: t('status', { ns: 'naming' }),
									action: () => this.props.openEditStatusModal(),
									isDisabled: false,
								},
								{
									value: 1,
									text: t('configuration', { ns: 'naming' }),
									action: () => this.props.openEditConfigurationModal(),
									isDisabled: false,
								},
								{
									value: 4,
									text: t('quantity', { ns: 'naming' }),
									action: () => this.props.openEditQuantitiesModal(),
									isDisabled: false,
								},
								{
									value: 5,
									text: t('uploadBlueprint', { ns: 'naming' }),
									action: () => this.props.openBluePrintModal(),
									isDisabled: false,
								},
								{
									value: 6,
									text: t('downloadBlueprint', { ns: 'naming' }),
									action: () => this.props.downloadBluePrint(),
									isDisabled: false,
								},
								{
									value: 7,
									text: t('deleteBlueprint', { ns: 'naming' }),
									action: () => this.props.openDeleteModifiedBlueprintsModal(),
									isDisabled: false,
								},
								{
									value: 8,
									text: t('editBindingNote', { ns: 'naming' }),
									action: () => this.props.openEditBindingNoteModal(),
									isDisabled: false,
								},
							]}
							onClick={value => this.handleEditDropDown(value)}
						/>
					</div>
					<PartsFinder />
				</div>
			</React.Fragment>
		)
	}
}

OfferTopMenu.propTypes = {
	openQuotationModal: PropTypes.func,
	openUploadModal: PropTypes.func,
	openOrderSimulationModal: PropTypes.func,
	openBluePrintModal: PropTypes.func,
	downloadBluePrint: PropTypes.func,
	openEditStatusModal: PropTypes.func,
	openEditQuantitiesModal: PropTypes.func,
	openSendUploadEmailModal: PropTypes.func,
	openEditConfigurationModal: PropTypes.func,
	openDeleteModifiedBlueprintsModal: PropTypes.func,
	handleOnChangeCloneQuoteModal: PropTypes.func,
	openEditBindingNoteModal: PropTypes.func,
	t: PropTypes.func,
}
export default withTranslation()(OfferTopMenu)
