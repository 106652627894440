import { findManager } from 'common/Utils/ManagersUtils'
import { getDateWithHour, getIdFromUrl } from 'common/Utils/StringUtilities'
import { getClientMarginValueById } from 'common/Utils/clientMarginUtils'
import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import { POSTPONED_PAYMENT_METHOD } from 'constants/paymentPostponedMethods'
import i18n from 'i18n'
import CountryFlag from 'pages/CRMUserDetail/ComponenteTablas/CountryFlag'
import PhoneFlag from 'pages/CRMUserDetail/ComponenteTablas/PhoneFlag'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Flag } from 'semantic-ui-react'
import { getClientMargin, getCrmUsersDetail, getManagers, updateOrganizationData, updateShippingAddressData } from 'store/actions'
import CrmTable from './CrmTable'
import ModalUpdateAdress from './UpdateModals/ModalUpdateAddress'
import ModalUpdateOrganization from './UpdateModals/ModalUpdateOrganization'
import ModalUpdatePaymentMethod from './UpdateModals/ModalUpdatePaymentMethod'
import ModalUpdateUser from './UpdateModals/ModalUpdateUser'

export const UserTables = ({ updateOrganizationData, updateShippingAddressData, userDetail, getCrmUsersDetail, t, isLoading, getManagers, managers, error, errorUpdate, getClientMargin, clientMargin }) => {

	const [accountToRender, setAccountToRender] = useState(null)
	const [organizationToRender, setOrganizationToRender] = useState(null)
	const [paymentToRender, setPaymentToRender] = useState(null)
	const [isModalMessageOpen, setIsModalMessageOpen] = useState(false)

	useEffect(() => {
		if (userDetail == null) getCrmUsersDetail(getIdFromUrl())
	}, [userDetail])

	useEffect(() => {
		if (managers.length === 0) getManagers()
	}, [])

	useEffect(() => {
		if (userDetail != null && managers.length > 0 && clientMargin.length > 0) {
			setAccountToRender(mapAccountData(userDetail))
			setOrganizationToRender(mapOrganizationData(userDetail))
			setPaymentToRender(mapPaymentData(userDetail))
		}
	}, [userDetail, managers, i18n.language, clientMargin])

	useEffect(() => {
		if (clientMargin.length === 0) {
			getClientMargin()
		}
	}, [clientMargin])

	useEffect(() => {
		if (errorUpdate != null) setIsModalMessageOpen(true)
	}, [errorUpdate])

	const mapAccountData = userDetail => {
		const { account } = userDetail
		if (account == null) return null
		return [
			[
				{
					label: t('firstName', { ns: 'naming' }),
					value: account.personalInformation.firstName,
				},
				{
					label: t('lastName', { ns: 'naming' }),
					value: account.personalInformation.lastName,
				},
			],
			[
				{
					label: t('country', { ns: 'naming' }),
					value: <CountryFlag country={account.personalInformation?.country} />,

				},
				{
					label: t('mobilePhone', { ns: 'naming' }),
					value: <PhoneFlag prefix={account.phoneData?.mobilePhone?.prefix} phoneNumber={account.phoneData?.mobilePhone?.number} />,
				},
			],
			[
				{
					label: t('phoneNumber', { ns: 'naming' }),
					value: <PhoneFlag prefix={account.phoneData?.phone?.prefix} phoneNumber={account.phoneData?.phone?.number} />,
				},
				{
					label: t('extension', { ns: 'naming' }),
					value: account.phoneData.phone.extension,
				},
			],
			[
				{
					label: t('phoneNumber', { ns: 'naming' }),
					value: <PhoneFlag prefix={account.phoneData?.phone2?.prefix} phoneNumber={account.phoneData?.phone2?.number} />,
				},
				{
					label: t('extension', { ns: 'naming' }),
					value: account.phoneData.phone2.extension,
				},
			],
			[
				{
					label: t('lastLogin', { ns: 'naming' }),
					value: getDateWithHour(account.statistics?.lastLogin),
				},
				{
					label: t('registrationDate', { ns: 'naming' }),
					value: getDateWithHour(account.statistics?.registerDate),
				},
			],
			[
				{
					label: t('email', { ns: 'naming' }),
					value: account.loginCredentials?.email,
				},
				{
					label: t('language', { ns: 'naming' }),
					value: t(account.personalInformation?.language, { ns: 'languages' }),
				},
			],
			[
				{
					label: t('observations', { ns: 'naming' }),
					value: account.comments,
				},
			],
		]
	}

	const closeModal = () => {
		location.reload()
	}

	const mapOrganizationData = userDetail => {
		if (userDetail.organization == null) return null
		const { organization } = userDetail
		return [
			[
				{
					label: t('organizationName', { ns: 'naming' }),
					value: organization.organizationName,
				},
			],
			[
				{
					label: t('vat', { ns: 'naming' }),
					value: organization.vat,
				},
				{
					label: t('manager', { ns: 'naming' }),
					value: organization.managerId && findManager(managers, organization.managerId)?.label,
				},
			],
			[
				{
					label: t('billingAddress', { ns: 'naming' }),
					value: mapAddress(organization.billingInfo, saveBillingAddress),
				},
			],
			[
				{
					label: t('classificationCommercial', { ns: 'naming' }),
					value: organization.customerRate,
				},
				{
					label: t('customerMargin', { ns: 'naming' }),
					value: organization.customerRate && getClientMarginValueById(clientMargin, organization.customerRate) + '%',
				},
			],
			[
				{
					label: t('sector', { ns: 'naming' }),
					value: organization.activitySector && t('activity_sector_' + organization.activitySector, { ns: 'activitySectors' }),
				},
			],
			[
				{
					label: t('shippingAddress', { ns: 'naming' }),
					value: userDetail.shippingAddress && mapAddress({ ...userDetail.shippingAddress, province: userDetail.shippingAddress.state }, saveShippingAddress),
				},
			],
		]
	}

	const mapPaymentData = userDetail => {
		if (userDetail.paymentMethod == null) return null
		const { paymentMethod } = userDetail
		return [
			[
				{
					label: t('paymentMethod', { ns: 'naming' }),
					value: paymentMethod.paymentMethod && Object.values(POSTPONED_PAYMENT_METHOD).includes(paymentMethod.paymentMethod) && t(`postponed_payment_type_${paymentMethod.paymentMethod}`, { ns: 'postponedPaymentType' }),
				},
				{
					label: t('paymentPostponement', { ns: 'naming' }),
					value: paymentMethod.paymentPostponed ? <i className='fs-4 text-success bx bx-check'></i> : <i className='fs-4 text-red bx bx-x'></i>,
				},
			],
			[
				{
					label: t('amountAwarded', { ns: 'naming' }),
					value: paymentMethod.amountAwarded,
				},
				{
					label: t('postponement', { ns: 'naming' }),
					value: paymentMethod.postponement.daysPostponed && `${paymentMethod.postponement.daysPostponed} ${t('days', { ns: 'naming' })}`,
				},
			],
			[
				{
					label: t('firstPaymentDay', { ns: 'naming' }),
					value: paymentMethod.postponement.firstPaymentDay === 'billing' ? t('billing_date', { ns: 'naming' }) : t(paymentMethod.postponement.firstPaymentDay, { ns: 'naming' }),
				},
				{
					label: t('secondPaymentDay', { ns: 'naming' }),
					value: paymentMethod.postponement.secondPaymentDay === 'billing' ? t('billing_date', { ns: 'naming' }) : t(paymentMethod.postponement.secondPaymentDay, { ns: 'naming' }),
				},
			],
			[
				{
					label: t('titularName', { ns: 'naming' }),
					value: paymentMethod.directDebitAccount?.ownerName,
				},
				{
					label: t('iban', { ns: 'naming' }),
					value: mapIban(paymentMethod.directDebitAccount),
				},
			],
			[
				{
					label: t('administrationPerson', { ns: 'naming' }),
					value: paymentMethod.contactAdministration?.fullName,
				},
				{
					label: t('administrationEmail', { ns: 'naming' }),
					value: paymentMethod.contactAdministration?.email,
				},
			],
		]
	}

	const accountTitle = (
		<div className="d-flex justify-content-between">
			<h3>
				<i className="bx bx-user me-2"></i>
				{t('contactInformation', { ns: 'naming' })}
			</h3>
			<ModalUpdateUser propsUserData={userDetail} />
		</div>
	)

	const organizationTitle = (
		<div className="d-flex justify-content-between">
			<h3>
				<i className="bx bxs-bank me-2"></i>
				{t('billingInfo', { ns: 'naming' })}
			</h3>
			<ModalUpdateOrganization propsUserData={userDetail} />
		</div>
	)

	const paymentTitle = (
		<div className="d-flex justify-content-between">
			<h3>
				<i className="bx bx-money me-2"></i>
				{t('paymentMethod', { ns: 'naming' })}
			</h3>
			<ModalUpdatePaymentMethod propsUserData={userDetail} />
		</div>
	)

	const mapIban = (directDebitAccount) => {
		const { cCountry, dCtrl, dCtrl2, entity, office, nAccount } = directDebitAccount
		return `${cCountry || ''} ${dCtrl || ''}${entity || ''}${office || ''}${dCtrl2 || ''} ${nAccount || ''} `
	}

	const mapAddress = (shippingAddress, saveData) => {
		if (shippingAddress == null) return null
		const { address1, city, country, postalCode, province } = shippingAddress
		return (
			<div className='d-flex w-100  align-items-center form-cell justify-content-between'>
				<div className='end-ellipsis'>
					{address1 && address1 + ' , '}
					{city && city + ' , '}
					{province && province + ' , '}
					{postalCode && postalCode + ' '}
					{<Flag name={country && country.toLowerCase()} />}
				</div>
				<div>
					<ModalUpdateAdress address={shippingAddress} saveData={saveData} />
				</div>
			</div>)
	}

	const saveBillingAddress = (addressState) => {
		const inputDTO = {
			data: {
				organization: {
					id: userDetail.organization.id,
					billingInfo: {
						...addressState,
					},
				},
			},
		}
		updateOrganizationData(inputDTO)
	}
	const saveShippingAddress = (addressState) => {
		const inputDTO = {
			data: {
				shippingAddress: {
					id: userDetail.shippingAddress.id,
					...addressState,
					state: addressState.province,
				},
			},
		}
		updateShippingAddressData(inputDTO)
	}
	return (
		<div className='w-100'>
			<ModalMessage isModalOpen={isModalMessageOpen} closeModal={closeModal} message={errorUpdate} />
			{isLoading ? <div className='d-flex justify-content-center'><Loader className={'fs-1'} /></div>
				: error ? <Alert
					type="danger"
					centered
					fitContent
					message={error.message}
				/> :
					<div className='mt-1'>
						<div className='mt-1 '>
							<CrmTable data={accountToRender} title={accountTitle} />
						</div>
						<div className='mt-1'>
							<CrmTable data={organizationToRender} title={organizationTitle} />
						</div>
						<div className='mt-1'>
							<CrmTable data={paymentToRender} title={paymentTitle} />
						</div>
					</div>
			}
		</div>
	)
}

UserTables.propTypes = {
	userDetail: PropTypes.object,
	getCrmUsersDetail: PropTypes.func,
	isLoading: PropTypes.bool,
	error: PropTypes.object,
	t: PropTypes.func,
	managers: PropTypes.array,
	getManagers: PropTypes.func,
	errorUpdate: PropTypes.string,
	clientMargin: PropTypes.array,
	getClientMargin: PropTypes.func,
	updateOrganizationData: PropTypes.func,
	updateShippingAddressData: PropTypes.func,
}

const mapStateToProps = (state) => ({
	userDetail: state.Crm.userData,
	isLoading: state.Crm.isLoading,
	error: state.Crm.errorDetail,
	managers: state.Users.managers,
	error: state.Crm.error,
	errorUpdate: state.Crm.errorUpdate,
	clientMargin: state.Quotes.clientMargin,
})

const mapDispatchToProps = {
	getCrmUsersDetail,
	getManagers,
	getClientMargin,
	updateOrganizationData,
	updateShippingAddressData,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserTables))
