import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
const FileInput = ({
	value,
	setValue,
	setFiles,
	title,
	hasPressed,
	isRequired,
	placeholder,
	multiple,
	accept,
	objectElement,
	className,
	t,
}) => {
	return (
		<>
			<div className={`m-2 ${className}`}>
				{title && (
					<h6>
						{isRequired && <span className="text-primary me-1">✲</span>}
						{title}
					</h6>
				)}
				<input
					onChange={e => {
						objectElement ? setValue(objectElement, e.target.value) : setValue(e.target.value)
						setFiles(e.target.files)
					}}
					type={'file'}
					className={hasPressed && isRequired && !value ? 'form-control is-invalid' : 'form-control'}
					id="exampleFormControlInput1"
					value={value}
					placeholder={placeholder}
					multiple={multiple}
					accept={accept}
				></input>
				{hasPressed && isRequired && !value && (
					<div>
						<div className="invalid-feedback">{t('required_field', { ns: 'naming' })}</div>
					</div>
				)}
			</div>
		</>
	)
}
FileInput.propTypes = {
	isRequired: PropTypes.bool,
	value: PropTypes.string,
	setValue: PropTypes.func,
	setFiles: PropTypes.func,
	title: PropTypes.string,
	hasPressed: PropTypes.bool,
	placeholder: PropTypes.string,
	multiple: PropTypes.bool,
	accept: PropTypes.string,
	objectElement: PropTypes.string,
	className: PropTypes.string,
	t: PropTypes.func,
}

export default withTranslation()(FileInput)
