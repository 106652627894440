import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { setSelectedOrderId } from 'store/actions'
import { getScanPartData } from 'utils/getScanPartData'

const SearchAndClientInfo = (props) => {

	const {
		scannedOrderList,
		isLoading,
		handleScanActions,
		selectedOrderId,
	} = props

	const { t } = useTranslation()
	const [orderInfo, setOrderInfo] = useState({})
	const [inputManualCode, setInputManualCode] = useState('')
	const [invalidCode, setInvalidCode] = useState(false)

	useEffect(() => {
		if (scannedOrderList.length > 0 && selectedOrderId) {
			const selectedScannedOrder = scannedOrderList.find((order) => order.id == selectedOrderId.orderId)
			setOrderInfo(selectedScannedOrder)
		}
	}, [selectedOrderId, scannedOrderList])

	const scanPartManually = () => {
		const { orderId, orderFragmentId, partId, quantity } = getScanPartData(inputManualCode)
		if (orderId != null && orderFragmentId != null && partId != null && quantity != null) {
			handleScanActions(orderId, partId, quantity, orderFragmentId)
		} else {
			setInvalidCode(true)
		}
	}
	const handleKeyDown = (e) => {
		if (e.key === 'Enter') scanPartManually()
	}
	return (
		<>
			<div className='bg-white'>
				<div
					className="w-100 p-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', backgroundColor: 'whitesmoke' }}
				>
					{scannedOrderList?.length} {t('ordersInTheList', { ns: 'naming' })}
				</div>
				<div
					className="w-100 p-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
				>
					<Input
						placeholder={t('enter_code_manually', { ns: 'naming' })}
						onChange={(e) => setInputManualCode(e.target.value)}
						onKeyDown={(e) => handleKeyDown(e)}
						invalid={invalidCode}
					/>
				</div>
				<button
					style={{ border: '0px', borderRadius: '4px', color: 'gray', width: 'fit-content' }}
					className="w-100 p-2 d-flex justify-content-center align-items-center"
					onClick={() => scanPartManually()}
				>
					{t('scanPart', { ns: 'naming' })}
				</button>
			</div>
			{Object.keys(orderInfo).length != 0 && !isLoading &&
				<div className='mt-3 bg-white'>
					<div
						className="w-100 p-2 d-flex align-items-center"
						style={{
							border: '0.5px solid lightgray',
							borderTopLeftRadius: '4px',
							borderTopRightRadius: '4px',
							backgroundColor: 'whitesmoke',
						}}
					>
						{t('shipment-data', { ns: 'naming' })}
					</div>
					<div
						className="w-100 ps-2 d-column align-items-center"
						style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
					>
						<p className="pt-3">
							{t('name', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.shippingAddress?.firstName} {orderInfo?.shippingAddress?.lastName}
							</strong>
						</p>
						<p>{t('company', { ns: 'naming' })}: <strong>{orderInfo?.shippingAddress?.company}</strong></p>
						<p>
							{t('address', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.shippingAddress?.postalCode} -&nbsp;
								{orderInfo?.shippingAddress?.city} -&nbsp;
								{orderInfo?.shippingAddress?.country}
							</strong>
						</p>
					</div>
				</div>
			}
		</>
	)
}

SearchAndClientInfo.propTypes = {
	// from connect HOC - mapStateToProps:
	isLoading: PropTypes.bool,
	orderDetail: PropTypes.object,
	scannedOrderList: PropTypes.array,
	selectedOrderId: PropTypes.object,

	// from connect HOC - mapDispatchToProps:
	setSelectedOrderId: PropTypes.func,

	// from parent component:
	handleScanActions: PropTypes.func,
	setManualScanned: PropTypes.func, // no se utiliza que yo sepa

	// ???
	getOrderDetailById: PropTypes.func, // no se utiliza que yo sepa
}

const mapStateToProps = (state) => ({
	isLoading: state.Orders.isLoading,
	orderDetail: state.Orders.orderDetail,
	scannedOrderList: state.Orders.scannedOrderList,
	selectedOrderId: state.Orders.selectedOrderId,
})

const mapDispatchToProps = {
	setSelectedOrderId,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(
	withTranslation()(
		SearchAndClientInfo,
	),
)
