import { call, put, takeEvery } from 'redux-saga/effects'

// Crypto Redux States
import {
	CLONE_QUOTE,
	CREATE_QUOTE,
	GET_CLIENT_MARGIN,
	GET_PART_COSTS_BY_SUPPLIER_ID,
	GET_PART_PRICES_BY_SUPPLIER_ID,
	GET_QUOTE_CHAT,
	GET_QUOTE_DETAIL,
	GET_QUOTE_LIST,
	GET_QUOTE_LIST_COUNT,
	GET_QUOTE_REGRESSIONS_BY_PART_ID,
	GET_REGRESSION_VALUE_BY_QUANTITY,
	GET_SIMILAR_QUOTE_PARTS,
	MARK_QUOTE_MESSAGES_AS_READ,
	POST_QUOTE_CHAT,
	SAVE_AND_CONFIRM_QUOTE,
	UPDATE_QUOTE_QUANTITIES
} from './actionTypes'

import {
	cloneQuoteFail,
	cloneQuoteSuccess,
	createQuoteFail,
	createQuoteSuccess,
	getClientMarginFail,
	getClientMarginSuccess,
	getPartCostsBySupplierIdFail,
	getPartCostsBySupplierIdSuccess,
	getPartPricesBySupplierIdFail,
	getPartPricesBySupplierIdSuccess,
	getQuoteChatFail,
	getQuoteChatSuccess,
	getQuoteDetailFail,
	getQuoteDetailSuccess,
	getQuoteListFail,
	getQuoteListFailCount,
	getQuoteListSuccess,
	getQuoteListSuccessCount,
	getQuoteRegressionsByPartIdSuccess,
	getQuoteRegressiosnByPartIdFail,
	getRegressionValueByQuantityFail,
	getRegressionValueByQuantitySuccess,
	getSimilarQuotePartsFail,
	getSimilarQuotePartsSuccess,
	markQuoteMessagesAsReadFail,
	markQuoteMessagesAsReadSuccess,
	postQuoteChatFail,
	postQuoteChatSuccess,
	saveAndConfirmQuoteFail,
	saveAndConfirmQuoteSuccess,
	updateQuoteQuantitiesFail,
	updateQuoteQuantitiesSuccess
} from './actions'

import {
	CLONE_QUOTE_ENDPOINT,
	CREATE_QUOTE_ENDPOINT,
	GET_CLIENT_MARGIN_ENDPOINT,
	GET_PART_COSTS_BY_SUPPLIER_ID_ENDPOINT,
	GET_PART_PRICES_BY_SUPPLIER_ID_ENDPOINT,
	GET_QUOTE_CHAT_ENDPOINT,
	GET_QUOTE_DETAIL_ENDPOINT,
	GET_QUOTE_LIST_COUNT_ENDPOINT,
	GET_QUOTE_LIST_ENDPOINT,
	GET_QUOTE_REGRESSIONS_BY_PART_ID_ENDPOINT,
	GET_REGRESSION_VALUE_BY_QUANTITY_ENDPOINT,
	GET_SIMILAR_QUOTE_PARTS_ENDPOINT,
	MARK_QUOTE_MESSAGES_AS_READ_ENDPOINT,
	POST_QUOTE_CHAT_ENDPOINT,
	SAVE_AND_CONFIRM_QUOTE_ENDPOINT,
	UPDATE_QUOTE_QUANTITIES_ENDPOINT
} from '../../constants/backendRoutes'

import { get, post, put as putCall } from '../../helpers/api_helper'


function* getRegressionValueByQuantity({ payload: { data, signal } }) {
	try {
		const url = GET_REGRESSION_VALUE_BY_QUANTITY_ENDPOINT
		const response = yield call(post, url, data, { signal })
		yield put(getRegressionValueByQuantitySuccess(response))
	} catch (error) {
		yield put(getRegressionValueByQuantityFail(error))
	}
}

function* getQuoteList({ payload: { input } }) {
	try {
		const url = GET_QUOTE_LIST_ENDPOINT
		const response = yield call(post, url, input)
		yield put(getQuoteListSuccess(response))
	} catch (error) {
		yield put(getQuoteListFailCount(error))
	}
}
function* getQuoteListCount() {
	try {
		const url = GET_QUOTE_LIST_COUNT_ENDPOINT
		const response = yield call(get, url)
		yield put(getQuoteListSuccessCount(response))
	} catch (error) {
		yield put(getQuoteListFail(error))
	}
}
function* getQuoteDetail({ payload: { quoteId, supplierId } }) {
	try {
		const url = GET_QUOTE_DETAIL_ENDPOINT + quoteId
		supplierId = supplierId || localStorage.getItem('supplierId')
		const response = yield call(get, `${url}/${supplierId}`)
		yield put(getQuoteDetailSuccess(response))
	} catch (error) {
		yield put(getQuoteDetailFail(error))
	}
}
function* updateQuoteQuantities({ payload: { quote } }) {
	try {
		const url = UPDATE_QUOTE_QUANTITIES_ENDPOINT
		const response = yield call(post, url, quote)
		yield put(updateQuoteQuantitiesSuccess(response))
	} catch (error) {
		yield put(updateQuoteQuantitiesFail(error))
	}
}
function* createQuote({ payload: { data } }) {
	try {
		const url = CREATE_QUOTE_ENDPOINT
		const response = yield call(post, url, { data })
		yield put(createQuoteSuccess(response))
	} catch (error) {
		yield put(createQuoteFail(error))
	}
}
function* getQuotesRegressionsByPartId({ payload: { partId } }) {
	try {
		const url = `${GET_QUOTE_REGRESSIONS_BY_PART_ID_ENDPOINT}/${partId}`
		const response = yield call(get, url)
		yield put(getQuoteRegressionsByPartIdSuccess(response))
	} catch (error) {
		yield put(getQuoteRegressiosnByPartIdFail(error))
	}
}
function* getQuoteChat({ payload: { input, signal } }) {
	try {
		const { partId, supplierId } = input
		const url = `${GET_QUOTE_CHAT_ENDPOINT}/${partId}/${supplierId}`
		const response = yield call(get, url, { signal })
		yield put(getQuoteChatSuccess(response))
	} catch (error) {
		yield put(getQuoteChatFail(error))
	}
}
function* postQuoteChat({ payload: { data } }) {
	try {
		const url = POST_QUOTE_CHAT_ENDPOINT
		const response = yield call(post, url, { data })
		yield put(postQuoteChatSuccess(response))
	} catch (error) {
		yield put(postQuoteChatFail(error))
	}
}
function* markQuoteMessagesAsRead({ payload: { data } }) {
	try {
		const url = MARK_QUOTE_MESSAGES_AS_READ_ENDPOINT
		const response = yield call(putCall, url, { data })
		yield put(markQuoteMessagesAsReadSuccess(response))
	} catch (error) {
		yield put(markQuoteMessagesAsReadFail(error))
	}
}
function* saveAndConfirmQuote({ payload: { quote } }) {
	try {
		const url = `${SAVE_AND_CONFIRM_QUOTE_ENDPOINT}`
		const response = yield call(post, url, quote)
		yield put(saveAndConfirmQuoteSuccess(response))
	} catch (error) {
		yield put(saveAndConfirmQuoteFail(error))
	}
}

function* getPartCostsBySupplierId({ payload: { partId, supplierId } }) {
	try {
		const url = `${GET_PART_COSTS_BY_SUPPLIER_ID_ENDPOINT}/${partId}/${supplierId}`
		const response = yield call(get, url)
		yield put(getPartCostsBySupplierIdSuccess(response))
	} catch (error) {
		yield put(getPartCostsBySupplierIdFail(error))
	}
}

function* getPartPricesBySupplierId({ payload: { partId, supplierId } }) {
	try {
		const url = `${GET_PART_PRICES_BY_SUPPLIER_ID_ENDPOINT}/${partId}/${supplierId}`
		const response = yield call(get, url)
		yield put(getPartPricesBySupplierIdSuccess(response))
	} catch (error) {
		yield put(getPartPricesBySupplierIdFail(error))
	}
}

function* getClientMargin() {
	try {
		const url = `${GET_CLIENT_MARGIN_ENDPOINT}`
		const response = yield call(get, url)
		yield put(getClientMarginSuccess(response))
	} catch (error) {
		yield put(getClientMarginFail(error))
	}
}

function* cloneQuote({ payload: { input } }) {
	try {
		const url = `${CLONE_QUOTE_ENDPOINT}`
		const response = yield call(post, url, input)
		yield put(cloneQuoteSuccess(response))
	} catch (error) {
		yield put(cloneQuoteFail(error))
	}
}

function* getSimilarQuoteParts({ payload: { partIds, supplierId } }) {
	try {
		const input = { data: { partIds, supplierId } }
		const url = `${GET_SIMILAR_QUOTE_PARTS_ENDPOINT}`
		const response = yield call(post, url, input)
		yield put(getSimilarQuotePartsSuccess(response))
	} catch (error) {
		yield put(getSimilarQuotePartsFail(error))
	}
}

function* quotesSaga() {
	yield takeEvery(GET_QUOTE_LIST, getQuoteList)
	yield takeEvery(GET_QUOTE_LIST_COUNT, getQuoteListCount)
	yield takeEvery(GET_QUOTE_DETAIL, getQuoteDetail)
	yield takeEvery(UPDATE_QUOTE_QUANTITIES, updateQuoteQuantities)
	yield takeEvery(CREATE_QUOTE, createQuote)
	yield takeEvery(GET_QUOTE_REGRESSIONS_BY_PART_ID, getQuotesRegressionsByPartId)
	yield takeEvery(GET_QUOTE_CHAT, getQuoteChat)
	yield takeEvery(POST_QUOTE_CHAT, postQuoteChat)
	yield takeEvery(MARK_QUOTE_MESSAGES_AS_READ, markQuoteMessagesAsRead)
	yield takeEvery(SAVE_AND_CONFIRM_QUOTE, saveAndConfirmQuote)
	yield takeEvery(GET_PART_COSTS_BY_SUPPLIER_ID, getPartCostsBySupplierId)
	yield takeEvery(GET_PART_PRICES_BY_SUPPLIER_ID, getPartPricesBySupplierId)
	yield takeEvery(GET_CLIENT_MARGIN, getClientMargin)
	yield takeEvery(CLONE_QUOTE, cloneQuote)
	yield takeEvery(GET_SIMILAR_QUOTE_PARTS, getSimilarQuoteParts)
	yield takeEvery(GET_REGRESSION_VALUE_BY_QUANTITY, getRegressionValueByQuantity)
}

export default quotesSaga
