import { voucherTypes } from 'pages/DiscountVoucher/VoucherTypes'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const OrderSummary = ({
	t,
	orderId,
	orderName,
	orderNumber,
	orderDate,
	partsPrice,
	fee,
	shipmentPrice,
	discount,
	vouchers,
	subtotal,
	taxes,
	totalPrice,
	paymentMethod,
	productionPeriod,
	estimatedShippingDate,
	realShippingDate,
	totalImportCost,
	totalWeight
}) => {

	const discountVoucher = vouchers.filter(v => [voucherTypes.AMOUNT, voucherTypes.PERCENTAGE].includes(v.voucherTypeId))[0]
	const freeShippingVoucher = vouchers.filter(v => v.voucherTypeId == voucherTypes.FREE_SHIPPING)[0]
	const noMinimumPriceVoucher = vouchers.filter(v => v.voucherTypeId == voucherTypes.NO_MINIMUM_PRICE)[0]
	const giftVoucher = vouchers.filter(v => v.voucherTypeId == voucherTypes.GIFT)[0]

	return (
		<div className="p-4">
			<div className="d-flex flex justify-content-between">
				<h3>{`${t('order', { ns: 'naming' })} ${orderId}`}</h3>
				<div>
					{orderName && <h4>{`${orderName}`}</h4>}
					{orderNumber && <h4>{`${orderNumber}`}</h4>}
				</div>
			</div>
			<p>
				<strong>{t('orderDate', { ns: 'naming' })}</strong>
				<br />
				{orderDate}
			</p>
			<p>
				<strong>{t('totalPrice', { ns: 'naming' })}</strong>
				<br />{t('partsPrice', { ns: 'naming' })}: {partsPrice}€
				<br />{t('fee', { ns: 'naming' })}: {noMinimumPriceVoucher ? <span>
					<span className="text-decoration-line-through">{fee}€</span>
					<span className="mx-2 px-1 bg-cyan rounded-1 text-white p-0 m-4">{noMinimumPriceVoucher.code}</span>
				</span> : <span>{fee}€</span>}
				<br />{t('shipmentPrice', { ns: 'naming' })}: {freeShippingVoucher ? <span>
					<span className="text-decoration-line-through">{shipmentPrice}€</span>
					<span className="mx-2 px-1 bg-cyan rounded-1 text-white">{freeShippingVoucher.code}</span>
				</span> : <span>{shipmentPrice}€</span>}
				<br />{t('discount', { ns: 'naming' })}: <span>
					<span>{discount}€</span>
					{discountVoucher && <span className="mx-2 px-1 bg-cyan rounded-1 text-white">{discountVoucher.code}</span>}
				</span>
				<br /><strong>{t('Subtotal', { ns: 'naming' })}: {subtotal}€</strong>
				<br />{t('taxes', { ns: 'naming' })}: {taxes}€
				<br />{t('total', { ns: 'naming' })}: {totalPrice}€
			</p>
			<p>
				<strong>{t('paymentMethod', { ns: 'naming' })}</strong>
				<br />
				{paymentMethod}
			</p>
			<p>
				<strong>{t('productionTime', { ns: 'naming' })}</strong>
				<br />
				{productionPeriod} {productionPeriod ? productionPeriod > 1 ? t('workingDays', { ns: 'naming' }) : t('workingDay', { ns: 'naming' }) : ''}
			</p>
			<p>
				<strong>{t('customerShipmentDate', { ns: 'naming' })}</strong>
				<br />
				{estimatedShippingDate ? estimatedShippingDate : '-'} {`(${t('estimated', { ns: 'naming' })})`}
				<br />
				{realShippingDate && <strong>{realShippingDate} ({t('real', { ns: 'naming' })})</strong>}
			</p>
			<p>
				<strong>{t('totalImportCost', { ns: 'naming' })}</strong>
				<br />
				{totalImportCost} €
			</p>
			<p>
				<strong>{t('totalWeight', { ns: 'naming' })}</strong>
				<br />
				{totalWeight}
			</p>
			{giftVoucher && <p>
				<strong>{t('include-gift', { ns: 'naming' })}</strong><span className="mx-2 px-1 bg-cyan rounded-1 text-white">{giftVoucher.code}</span>
			</p>}

		</div>
	)
}
OrderSummary.propTypes = {
	t: PropTypes.func,
	orderId: PropTypes.number,
	orderName: PropTypes.string,
	orderNumber: PropTypes.string,
	orderDate: PropTypes.string,
	partsPrice: PropTypes.string,
	fee: PropTypes.string,
	shipmentPrice: PropTypes.string,
	discount: PropTypes.string,
	vouchers: PropTypes.array,
	subtotal: PropTypes.string,
	taxes: PropTypes.string,
	totalPrice: PropTypes.string,
	paymentMethod: PropTypes.string,
	productionPeriod: PropTypes.number,
	estimatedShippingDate: PropTypes.string,
	realShippingDate: PropTypes.string,
}
export default withTranslation()(OrderSummary)
