import React from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
//import pathToStl from "../assets/75.220.5.stl";
import matcapPorcelainWhite from 'assets/textures/GRIS3.jpg'
import worldImage from 'assets/textures/whitePixel.jpg'

import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { getMeasure } from 'common/Utils/StringUtilities'
import Loader from 'components/Common/Loader'
import PropTypes from 'prop-types'
import TreeSTLLoader from 'three-stl-loader'

const WEIGHT_MEASURE = 'g'
const SIZE_MEASURE = 'mm'

const STLLoader = TreeSTLLoader(THREE)

const loader = new STLLoader()
const textureLoader = new THREE.TextureLoader()
const imageLoader = new THREE.ImageLoader()

/**
 * https://threejs.org/examples/#webgl_lightprobe
 * https://github.com/mrdoob/three.js/blob/master/examples/webgl_lightprobe.html
 * @param {*} param0
 */

function initEnvironment({ scene, imageSrc }) {
	const sphere = new THREE.SphereGeometry(750, 64, 64)
	sphere.scale(-1, 1, 1)

	const texture = new THREE.Texture()

	const material = new THREE.MeshBasicMaterial({
		map: texture,
	})

	imageLoader.load(imageSrc, image => {
		texture.image = image
		texture.needsUpdate = true
	})

	scene.add(new THREE.Mesh(sphere, material))
}

function createAnimate({ scene, camera, renderer }) {
	const triggers = []

	function animate() {
		requestAnimationFrame(animate)

		triggers.forEach(trigger => {
			trigger()
		})

		renderer.render(scene, camera)
	}
	function addTrigger(cb) {
		if (typeof cb === 'function') triggers.push(cb)
	}
	function offTrigger(cb) {
		const triggerIndex = triggers.indexOf(cb)
		if (triggerIndex !== -1) {
			triggers.splice(triggerIndex, 1)
		}
	}

	return {
		animate,
		addTrigger,
		offTrigger,
	}
}

export default class StlViewer extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loadedPercentage: 0,
		}
	}
	componentDidMount() {
		const scene = new THREE.Scene()
		const camera = new THREE.PerspectiveCamera(
			750,
			this.props.windowSize.x / this.props.windowSize.y,
			0.1,
			100000,
		)

		if (this.props.modelSrc === null || this.props.modelSrc === undefined) {
			return
		}

		loader.load(
			this.props.modelSrc,
			geometry => {
				const material = new THREE.MeshMatcapMaterial({
					color: 0xffffff,
					matcap: textureLoader.load(matcapPorcelainWhite),
				})
				const mesh = new THREE.Mesh(geometry, material)

				mesh.geometry.computeVertexNormals(true)
				mesh.geometry.center()

				scene.add(mesh)

				mesh.rotation.x = -1.2

				animate.addTrigger(() => {
					// mesh.rotation.x += 0.05;
					// mesh.rotation.y += 0.05;
				})
			},
			(xhr) => {
				this.setState({
					...this.state,
					loadedPercentage: xhr.loaded / xhr.total * 100,
				})
			},
			error => {
				alert('Render error')
				alert(error)
			},
		)

		initEnvironment({ scene, imageSrc: worldImage })

		const renderer = new THREE.WebGLRenderer()

		const controls = new OrbitControls(camera, renderer.domElement)

		controls.maxDistance = 100
		controls.minDistance = 1

		const geometry = new THREE.BoxGeometry(10, 10, 10)
		const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 })

		/**
		 * Light setup
		 */
		const secondaryLight = new THREE.PointLight(0xff0000, 1, 100)
		secondaryLight.position.set(5, 5, 5)
		scene.add(secondaryLight)

		renderer.setSize(this.props.windowSize.x, this.props.windowSize.y)
		this.mount.appendChild(renderer.domElement)

		const animate = createAnimate({ scene, camera, renderer })

		camera.position.z = 10

		animate.animate()
	}
	render() {
		const { partName, partId, size, weight } = this.props

		if (this.props.modelSrc === null || this.props.modelSrc === undefined) {
			return <div>No model to show</div>
		}

		return (
			<div className="mx-auto">
				{this.state.loadedPercentage < 100 ? (
					<div className="position-absolute">
						<Loader className="me-4 py-4 d-flex font-size-80" />
					</div>) :
					<div className="position-absolute">
						<div className="py-4 d-flex flex-column">
							<div>{partId && `${partId}`}</div>
							<div>{size &&
								size.x &&
								size.y &&
								size.z &&
								`${roundAtDecimals(size.x, 1)} x ${roundAtDecimals(
									size.y,
									1,
								)} x ${roundAtDecimals(size.z, 1)} ${size.measure ? size.measure : SIZE_MEASURE}`}{' '}
							</div>
							<div>{weight && getMeasure(weight.value ? weight.value : weight, weight.measure ? weight.measure : WEIGHT_MEASURE)}</div>
						</div>
					</div>
				}
				<div ref={ref => (this.mount = ref)}>
				</div>
			</div>
		)
	}
}

StlViewer.propTypes = {
	modelSrc: PropTypes.string,
	windowSize: PropTypes.shape({
		x: PropTypes.number,
		y: PropTypes.number,
	}),
	partName: PropTypes.string,
	partId: PropTypes.number,
	size: PropTypes.shape({
		x: PropTypes.number,
		y: PropTypes.number,
		z: PropTypes.number,
		measure: PropTypes.string,
	}),
	weight: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.shape({
			value: PropTypes.number,
			measure: PropTypes.string,
		}),
	]),
}
