import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const VoucherErrorModal = ({
	t,
	isErrorModalOpen,
	setErrorModalOpen,
	error,
}) => {

	const specificErrors = ['voucher-code-used', 'voucher-not-found']

	return <div>
		<Modal
			title={t('voucher-error', { ns: 'naming' })}
			isOpen={isErrorModalOpen}
			closeModal={() => setErrorModalOpen(false)}
			body={
				<div className="m-3">
					{specificErrors.includes(error) ? t(error, { ns: 'errors' }) : t('unknownMessage', { ns: 'errors' })}
				</div>
			}
		/>
	</div>
}

VoucherErrorModal.propTypes = {
	t: PropTypes.func,
	isErrorModalOpen: PropTypes.bool,
	setErrorModalOpen: PropTypes.func,
	error: PropTypes.object,
}
export default withTranslation()(VoucherErrorModal)
