import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

const TitleWithValueColumn = ({ title, value, isCurrency, previousValue, reversedColors }) => {
	const percentageValue = !Number.isNaN(((value - previousValue) * 100) / previousValue) ? (((value - previousValue) * 100) / previousValue).toFixed(2) : null
	const tooltipId = `${title}_previous_value_id`
	const isUpper = (percentageValue >= 0)

	const COLOR_UPPER = reversedColors ? 'text-danger' : 'text-success'
	const COLOR_DOWN = reversedColors ? 'text-success' : 'text-danger'

	return (
		<div className="p-2 px-2 align-items-center text-center border-bottom d-flex flex-column justify-content-center">
			<h6 className=" text-nowrap mb-1">{title}</h6>
			<span className="fs-6">
				{isCurrency ? value?.toFixed(2) + '€' : value}
			</span>

			{((percentageValue == null || previousValue == null) ? <span className="fs-7 text-gray"><i className='bx bx-dots-horizontal-rounded text-secondary-emphasis'></i></span>
				:
				<div>

					<ReactTooltip
						id={tooltipId}
						place="right"
						type="info"
						effect="solid" />

					{(
						!isFinite(percentageValue) ? (
							<span data-tip={isCurrency ? previousValue.toFixed(2) + '€' : previousValue} data-for={tooltipId} className="fs-7">
								<i className='bx bx-dots-horizontal-rounded text-secondary-emphasis'></i>
							</span>) :
							isUpper ? (
								<span data-tip={isCurrency ? previousValue.toFixed(2) + '€' : previousValue} data-for={tooltipId} className={`${COLOR_UPPER} fs-7`}>
									<i className="bx bx-up-arrow-alt m-0 p-0"></i>
									{percentageValue}%
								</span>
							) : (
								<span data-tip={isCurrency ? previousValue.toFixed(2) + '€' : previousValue} data-for={tooltipId} className={`${COLOR_DOWN} fs-7`}>
									<i className="bx bx-down-arrow-alt m-0 p-0"></i>
									{percentageValue}%
								</span>
							))}

				</div>
			)}

		</div>
	)
}

TitleWithValueColumn.propTypes = {
	title: PropTypes.string,
	value: PropTypes.number,
	isCurrency: PropTypes.bool,
	previousValue: PropTypes.any,
}

export default TitleWithValueColumn
