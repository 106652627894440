import { getDate } from 'common/Utils/StringUtilities'
import BlueprintAndStp from 'components/Common/BlueprintAndStp'
import { IconTooltip } from 'components/Common/IconTooltip'
import PartThumbnail from 'components/Common/PartThumbnail'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getQualityVerificationImages } from 'store/actions'
import ResolutionIncidenceModal from './ResolutionIncidenceModal'

const PartIncidence = ({ item, solutions, problems, getQualityVerificationImages, qualityVerficationImages, t }) => {
	const { incidences, orderItem } = item
	const [modalData, setModalData] = useState({
		visible: false,
		incidence: null,
		quantity: 0
	})

	const handleDisplayIdClick = (incidence) => {
		getQualityVerificationImages({
			data: {
				partId: incidence.partId,
				qualityVerificationId: incidence.id,
			}
		}, true)
		setModalData({
			visible: true,
			incidence,
			quantity: orderItem.quantity
		})
	}

	const handleModalClose = () => {
		setModalData({
			visible: false,
			incidence: null,
		})
	}

	return (
		<>
			{incidences.map((incidence, index) => {
				const problem = problems.find((problem) => problem.id === incidence.problem)
				const solution = solutions.find((solution) => solution.id === incidence.solution)
				return (
					<div
						key={`${incidence.displayId}_${index}`}
						className={'w-100 m-1 mx-2 p-4 rounded d-flex flex-row align-items-center'}
						style={{ backgroundColor: incidence.solved ? '#C4D79B' : '#F08080' }}
					>
						<span className='col-1'>

							<span className='cursor-pointer' onClick={() => handleDisplayIdClick(incidence)}>
								{incidence.displayId}
							</span>

						</span>
						<span className='col-1 mt-2'>
							<i
								onClick={() => {
									getQualityVerificationImages({
										data: {
											partId: incidence.partId,
											qualityVerificationId: incidence.id,
										},
									})
								}}
								className='cursor-pointer bx bx-image fs-3 mx-2'
							></i>
						</span>
						<span className='col-1'>
							<PartThumbnail
								propsImageUrl={orderItem.part.fileLinks.thumbnail}
								propsStlUrl={orderItem.part.fileLinks.stlModel}
								stlData={{
									partId: orderItem.part.id,
									partName: orderItem.part.name,
									size: orderItem.part.size,
									weight: orderItem.part.weight,
								}}
							/>
						</span>
						<span className='col-1'>
							<BlueprintAndStp
								propsModel3dUrl={orderItem.part.fileLinks.stepModel}
								propsBlueprintUrl={orderItem.part.fileLinks.originalBlueprint}
								propsModifiedBluePrintUrl={orderItem.part.fileLinks.modifiedBlueprint}
							/>
						</span>
						<span className='col-1 d-flex align-items-center'>
							<i className='bx bx-check fs-4 text-success ms-1'></i>
							{incidence.partsOK}
						</span>
						<span className='col-1 d-flex align-items-center'>
							<i className='bx bx-x fs-4 text-danger ms-1'></i>
							{incidence.partsWrong}
						</span>
						<span className='col-1'>
							<IconTooltip message={incidence.observation} icon='bx bx-note fs-4 d-flex align-items-center' position='left' />
						</span>
						<span className='col-1'>
							{t(incidence.notifiedBy, { ns: 'naming' })}
						</span>
						<span className='col-1'>
							{getDate(incidence.creationDate)}
						</span>
						<span className='col-2'>
							{problem.description}
						</span>
						<span className='col-1'>
							{solution.description}
						</span>
					</div>
				)
			})}

			{modalData.incidence && (
				<ResolutionIncidenceModal
					isOpen={modalData.visible}
					onClose={handleModalClose}
					incidence={modalData.incidence}
					partId={modalData.incidence.partId}
					problems={problems}
					solutions={solutions}
					quantity={modalData.quantity}
				/>
			)}
		</>
	)
}

PartIncidence.propTypes = {
	item: PropTypes.object,
	solutions: PropTypes.array,
	problems: PropTypes.array,
	getQualityVerificationImages: PropTypes.func,
	qualityVerficationImages: PropTypes.array,
}

const mapStateToProps = (state) => ({
	problems: state.Orders.qualityProblems,
	solutions: state.Orders.qualitySolutions,
	qualityVerficationImages: state.CloudStorage.qualityVerficationImages,

})

const mapDispatchToProps = { getQualityVerificationImages }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PartIncidence))
