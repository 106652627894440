import { useObjectState } from 'common/Hooks/UseObjectState'
import { useCouriers } from 'common/Hooks/UseTranslatedCouriers'
import { isDateOlder } from 'common/Utils/DateUtils'
import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { roundAtDecimals, roundCurrency } from 'common/Utils/NumberUtilities'
import { getDate, getFilterArriveShipingOrderDate, getFilterShipmentOrderDate, getHour } from 'common/Utils/StringUtilities'
import { getSupplierViewId } from 'common/Utils/SuppliersUtils'
import Alert from 'components/Common/Alert'
import Breadcrumbs from 'components/Common/Breadcrumb'
import { IconTooltip } from 'components/Common/IconTooltip'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PartsFinder from 'components/Common/PartsFinder'
import PrintLabelsModal from 'components/Common/ProductionLabels/PrintLabelsModal'
import Select from 'components/Common/Select/Select'
import { InputNumber } from 'components/InputNumber'
import { NoteTooltip } from 'components/NoteTooltip'
import Table from 'components/Table/Table'
import AppRoutes from 'constants/appRoutes'
import { ProductionStatus } from 'constants/productionStatus'
import { USER_ROLE_SUPPLIER } from 'constants/userRoles'
import i18n from 'i18n'
import OrganizationFlag from 'pages/Orders/OrganizationFlag'
import RealShipmentDate from 'pages/Orders/RealShipmentDate'
import ProductionStatusComponent from 'pages/SupplierProduction/ProductionStatus'
import { Tracking } from 'pages/SupplierProduction/Tracking'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { isStringInList } from '../../common/Utils/StringUtilities'
import {
	getCompleteProductionFiles,
	getManagers,
	getOrderDetailById,
	getProductionDetail,
	getProductionItemsLabels,
	getProductionList,
	getProductionListBySupplier,
	getSupplierDetail,
	getSupplierInfo,
	getSuppliers,
	setSupplierIdView,
	updateProduction,
	updateShipmentImport,
} from '../../store/actions'
import ArriveAndShipmentSemaphore from './ArriveAndShipmentSemaphore'
import { IdWithWarning } from './IdWithWarning'
import ProductionFilesModal from './Modals/ProductionFilesModal'
import ProductionsFilterModal from './ProductionsFilterModal'

const PAGE_SIZE_LIST = [
	{ value: 50, text: '50' },
	{ value: 100, text: '100' },
	{ value: 200, text: '200' },
	{ value: 500, text: '500' },
	{ value: 1000, text: '1000' },
]

const Productions = ({
	t,
	suppliers,
	productionList,
	count,
	roles,
	getSuppliers,
	showSupplierProductions,
	isLoadingProd,
	productionDetail,
	getSupplierInfo,
	supplierInfo,
	error,
	updateProduction,
	updateProduction_Status,
	getManagers,
	managers
}) => {

	const parseSuppliers = suppliers => {
		return suppliers.map(supplier => {
			return {
				value: supplier?.id,
				label: supplier?.personalInformation?.firstName,
			}
		})
	}

	const headerToRender = {
		idToRender: t('idProduction', { ns: 'naming' }),
		statusToRender: t('status', { ns: 'naming' }),
		organizationName: t('client', { ns: 'naming' }),
		supplier: t('supplier', { ns: 'naming' }),
		orderDate: t('releaseDate', { ns: 'naming' }),
		productionDays: t('term', { ns: 'naming' }),
		shipmentDate: t('shipingDate', { ns: 'naming' }),
		lighting: t('arrival_shipment_production_header', { ns: 'naming' }),
		weight: t('weight', { ns: 'naming' }),
		tracking: t('tracking', { ns: 'naming' }),
		// chat: t('chat', { ns: 'naming' }), --> TODO ProductionChat no funciona todavía
		comment: t('comment', { ns: 'naming' }),
		labeling: t('labeling', { ns: 'naming' }),
	}

	const supplierHeaderToRender = {
		id: t('id', { ns: 'naming' }),
		statusToRender: t('status', { ns: 'naming' }),
		orderDate: t('productionCreationDate', { ns: 'naming' }),
		shipmentDate: t('shipmentProductionDate', { ns: 'naming' }),
		// chat: t('chat', { ns: 'naming' }), --> TODO ProductionChat no funciona todavía
		comment: t('comment', { ns: 'naming' }),
		weight: t('weight', { ns: 'naming' }),
		tracking: t('tracking', { ns: 'naming' }),
		totalSales: t('totalPrice', { ns: 'naming' }),
		files: t('files', { ns: 'naming' }),
	}

	const getHeaderToRender = () => {
		return showSupplierProductions ? supplierHeaderToRender : headerToRender
	}

	const getSupplierFirstNameById = id => {
		const supplierFound = suppliers.find(element => element.id == id)
		return supplierFound && supplierFound.personalInformation.firstName
	}

	const isSupplier = () => {
		return isStringInList(USER_ROLE_SUPPLIER, roles) != null
	}

	const openFilesModal = (productionId) => {
		setSelectedProductionId(productionId)
		setIsProductionFilesModalOpen(true)
	}

	const statusToShowFiles = [ProductionStatus.PICKED_UP_BY_COURIER, ProductionStatus.QUALITY_CONTROL, ProductionStatus.COMPLETED]

	const [trackingModalIsOpen, setTrackingModalIsOpen] = useState(false)
	const [productionInTrackingModal, setProductionInTrackingModal] = useState(null)
	const [newDeclaredValueInTrackingModal, setNewDeclaredValueInTrackingModal] = useState()
	const [errorModalIsOpen, setErrorModalIsOpen] = useState(false)
	useEffect(() => {
		setNewDeclaredValueInTrackingModal(productionInTrackingModal?.declaredValue)
	}, [trackingModalIsOpen])
	useEffect(() => {
		if (!updateProduction_Status.loading) {
			if (updateProduction_Status.success) {
				if (productionInTrackingModal != null) {
					productionInTrackingModal.declaredValue = newDeclaredValueInTrackingModal
				}
			} else if (updateProduction_Status.error) {
				setErrorModalIsOpen(true)
			}
		}
	}, [updateProduction_Status.loading])

	const formatSupplierProduction = productionObject => {

		return {
			id: productionObject.production.displayId,
			status: {
				value: {
					id: productionObject.production.statusId,
					text: t('production_status_' + productionObject.production.statusId, {
						ns: 'status',
					}),
				},
			},
			filterShipmentOrderDate: getFilterShipmentOrderDate(
				productionObject.production.shipmentDateEstimate,
				productionObject.production.shipmentDateReal,
				productionObject.production.shipmentDateDelay,
			),
			statusToRender: {
				value: {
					status: (
						<ProductionStatusComponent
							status={productionObject.production.statusId}
							disconformity={productionObject.production.disconformity}
							partialShipment={productionObject.production.partialShipment}
						/>
					),
				},
			},
			orderDate: [getDate(productionObject.production.orderDate), getHour(productionObject.production.orderDate)],
			shipmentDate: {
				value: {
					shipmentDate: (
						<RealShipmentDate
							date={productionObject.production.shipmentDateEstimate}
							realDate={productionObject.production.shipmentDateReal}
							delayDate={productionObject.production.shipmentDateDelay}
							statusSupplierProduction={productionObject.production.statusId}
						/>
					),
				},
			},
			comment: <NoteTooltip note={productionObject.production.comment} t={t} />,
			// chat: {
			// 	value: {
			// 		chat: (
			// 			<ChatIcon
			// 				quantityUnread={productionObject?.chatMessages?.filter(x => x.readDate == null).length}
			// 				production={productionObject?.production}
			// 			/>
			// 		),
			// 	},
			// }, --> TODO ProductionChat no funciona todavía
			weight: productionObject.production.weight && productionObject.production.weight < 0.1 ? 0.1 : productionObject.production.weight < 1000 ? roundAtDecimals(productionObject.production.weight, 0) + 'g' : roundAtDecimals(productionObject.production.weight / 1000, 1) + 'kg',
			totalSales: roundCurrency(productionObject.production.totalPrice) + currency,
			tracking: {
				value: {
					tracking: <Tracking
						trackingId={productionObject.production.shipmentTrackingNumber}
						courier={productionObject.production.courier}
						estimatedArrivalDate={productionObject.production.arrivalDateEstimated}
						status={productionObject.production.statusId}
					/>,
				},
			},
			files: statusToShowFiles.includes(productionObject.production.statusId) && <IconTooltip
				key={`files_${productionObject.production.displayId}`}
				name={`files_${productionObject.production.displayId}`}
				icon='bx bxs-file-blank fs-2 cursor-pointer'
				message={t('production-files', { ns: 'naming' })}
				onClick={() => openFilesModal(productionObject.production.displayId)}
			/>,
		}
	}

	const formatProduction = productionObject => {
		return {
			id: productionObject.production.id,
			idToRender: <IdWithWarning id={productionObject.production.displayId} warning={productionObject.warning} />,
			statusToRender: (
				<ProductionStatusComponent
					status={productionObject.production.statusId}
					disconformity={productionObject.production.disconformity}
					partialShipment={productionObject.production.partialShipment}
					late={isDateOlder(
						productionObject.production.estimatedShipmentDate,
						productionObject.production.arrivalDate || productionObject.production.arrivalDateEstimated,
					)}
					qualityIncidencesCount={productionObject.qualityIncidencesCount}
				/>
			),
			status: {
				value: {
					id: productionObject.production.statusId,
					text: t('production_status_' + productionObject.production.statusId, {
						ns: 'status',
					}),
				},
			},
			supplier: suppliers && suppliers.length != 0 && getSupplierFirstNameById(productionObject.production.supplierId),
			organizationName: (
				<OrganizationFlag
					userId={productionObject.customer?.account?.id || undefined}
					country={productionObject.customer?.organization?.billingInfo.country || undefined}
					organizationName={productionObject.customer?.organization?.organizationName || undefined}
					firstName={productionObject.customer?.account.personalInformation.firstName || undefined}
					lastName={productionObject.customer?.account.personalInformation.lastName || undefined}
				/>
			),
			orderDate: productionObject.production.orderDate && [getDate(productionObject.production.orderDate), getHour(productionObject.production.orderDate)],
			productionDays: productionObject.production.productionDays + ' ' + t('days', { ns: 'naming' }),
			shipmentDate: (
				<RealShipmentDate
					date={productionObject.production.shipmentDateEstimate}
					realDate={productionObject.production.shipmentDateReal}
					delayDate={productionObject.production.shipmentDateDelay}
				/>
			),
			filterShipmentOrderDate: getFilterShipmentOrderDate(
				productionObject.production.shipmentDateEstimate,
				productionObject.production.shipmentDateReal,
			),
			filterArriveShipingDate: getFilterArriveShipingOrderDate(
				productionObject.production.arrivalDateEstimated,
				productionObject.production.estimatedShipmentDate,
				productionObject.production.arrivalDate,
				productionObject.production.deliveryDate,
			),
			lighting: {
				value: {
					status: (
						<ArriveAndShipmentSemaphore
							estArriveDate={productionObject.production.arrivalDateEstimated}
							estShipmentDate={productionObject.production.estimatedShipmentDate}
							realArriveDate={productionObject.production.arrivalDate}
							realShipmentDate={productionObject.production.deliveryDate}
						/>
					),
				},
			},
			weight: productionObject.production.weight && productionObject.production.weight < 0.1 ? 0.1 : productionObject.production.weight < 1000 ? roundAtDecimals(productionObject.production.weight, 0) + 'g' : roundAtDecimals(productionObject.production.weight / 1000, 1) + 'kg',
			tracking: {
				value: {
					tracking: (
						<div>
							<Tracking
								trackingId={productionObject.production.shipmentTrackingNumber}
								courier={productionObject.production.courier}
								estimatedArrivalDate={productionObject.production.arrivalDateEstimated}
								status={productionObject.production.statusId}
								overrideOnClick={() => {
									setProductionInTrackingModal(productionObject.production)
									setTrackingModalIsOpen(true)
								}}
							/>
						</div>
					),
				},
			},

			comment: <NoteTooltip note={productionObject.production.comment} t={t} />,
			// chat: {
			// 	value: {
			// 		chat: <ChatIcon quantityUnread={productionObject?.chatCount} production={productionObject?.production} />,
			// 	},
			// }, --> TODO ProductionChat no funciona todavía
			labeling: <PrintLabelsModal
				orderId={productionObject.production.orderId}
				orderFragmentId={productionObject.production.orderFragmentId}
				addCustomerInfoLabel
			/>,
		}
	}

	const parseData = data => {
		if (data && data.length !== 0) {
			return data.map(element => {
				return showSupplierProductions ? formatSupplierProduction(element) : formatProduction(element)
			})
		} else {
			return []
		}
	}

	const [currency, setCurrency] = useState('€')

	const [itemsToRender, setItemsToRender] = useState(parseData(productionList))
	const [currentPage, setCurrentPage] = useState(1)
	const [pageSize, setPageSize] = useState(50)
	const [supplierSelected, setSupplierSelected] = useState(null)
	const [productionItems, setProductionItems] = useState([])
	const [isProductionFilesModalOpen, setIsProductionFilesModalOpen] = useState(false)
	const [selectedProductionId, setSelectedProductionId] = useState()

	const { objectData, handleOnChange, resetState } = useObjectState({
		supplierShipmentDateSemaphore: [],
		arrivalShipmentDateSemaphore: [],
	})

	useEffect(() => {
		if (Object.keys(productionDetail).length > 0)
			setProductionItems([...productionItems, productionDetail.production.productionItemList])
	}, [productionDetail])

	useEffect(() => {
		if (supplierInfo?.personalSettings != null) {
			if (supplierInfo.personalSettings.currency.toLowerCase() == 'dollar') setCurrency('$')
		}
	}, [supplierInfo])

	useEffect(() => {
		getSuppliers()
		getManagers()
	}, [])

	useEffect(() => {
		const supplierIdView = getSupplierViewId(supplierSelected)
		if (supplierIdView != null) getSupplierInfo(supplierIdView)
	}, [supplierSelected])

	useEffect(() => {
		setItemsToRender(applySemaphoreFilters(parseData(productionList)))
	}, [i18n.language, productionList])

	const applySemaphoreFilters = (productionsMapped) => {
		return productionsMapped?.filter((item) => {
			const supplierShipmentFilter = objectData.supplierShipmentDateSemaphore.length === 0 || objectData.supplierShipmentDateSemaphore.includes(item.filterShipmentOrderDate)
			const arrivalShipmentFilter = objectData.arrivalShipmentDateSemaphore.length === 0 || objectData.arrivalShipmentDateSemaphore.includes(item.filterArriveShipingDate)
			return supplierShipmentFilter && arrivalShipmentFilter
		})
	}

	const handleOnChangeSupplierSelected = supplierId => {
		setSupplierSelected(supplierId)
		setSupplierIdView(supplierId)
		setItemsToRender([])
	}

	const handlePageChange = (page, pageSize) => {

		setCurrentPage(page)
		setPageSize(pageSize)
	}

	const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
		<li key={text} role="presentation" className="d-flex align-items-center">
			<a
				href="#"
				tabIndex="-1"
				role="menuitem"
				data-page={page}
				onMouseDown={e => {
					e.preventDefault()
					onSizePerPageChange(page)
				}}
				className="btn btn-default btn-primary dropdown-toggle text-white width-3rem"
			>
				{text}
			</a>
		</li>
	)

	const getBreadcrumb = () => {
		return !showSupplierProductions ? (
			<Breadcrumbs
				title={t('management', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('productions', { ns: 'naming' }),
						link: '/productions',
					},
				]}
			/>
		) : (
			<Breadcrumbs
				title={t('suppliers', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('productions', { ns: 'naming' }),
						link: '/supplier-productions',
					},
				]}
			/>
		)
	}

	const { COURIER } = useCouriers(t)

	return (
		<div className="page-content mb-4">
			<MetaTags>
				<title>Proto&Go! | {t('productions', { ns: 'naming' })}</title>
			</MetaTags>

			{getBreadcrumb()}
			{!isSupplier() && showSupplierProductions && suppliers && suppliers.length > 0 && (
				<div className="d-flex mb-4 bg-white my-4 p-4 py-3 ps-2">
					<span className="center-vertically me-3">Visualización cómo proveedor</span>
					<div>
						<Select
							placeholder="Selecciona un Proveedor"
							options={parseSuppliers(suppliers)}
							className="width-by-content "
							onChange={e => handleOnChangeSupplierSelected(e.target.value)}
							value={supplierSelected}
							disable={isLoadingProd}
						/>
					</div>
				</div>
			)}

			{!isLoadingProd && error ? <div className="d-flex justify-content-center">{<Alert type={'danger'} message={error} />}</div>
				:
				<div>
					<div className="bg-white p-4 pb-0 d-flex justify-content-between">
						<div className="d-flex justify-content-between align-items-center">
							<ProductionsFilterModal currentPage={currentPage} pageSize={pageSize} setCurrentPage={setCurrentPage} isSupplier={isSupplier()}
								handleOnChangeSemaphoreFilter={handleOnChange} semaphoreFilter={objectData} resetSemaphoreFilters={resetState}
								selectedSupplier={supplierSelected} showSupplierProductions={showSupplierProductions} />
						</div>
						{!showSupplierProductions &&
							<PartsFinder />
						}
					</div>

					{isLoadingProd ? (
						<Loader className="font-size-80 mx-auto" />
					) : (
						<div className="bg-white mb-4 p-4 pt-0 marginFooter">
							<div>
								{itemsToRender?.length > 0 && (showSupplierProductions ? isSupplier() || supplierSelected : true) ? (
									<Table
										remote
										customFilter
										header={getHeaderToRender()}
										items={itemsToRender}
										selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
										language={getLanguage()}
										pageToNavigate={isSupplier ? AppRoutes.SUPPLIER_PRODUCTION_DETAIL_NO_ID : AppRoutes.PRODUCTION_DETAIL_NO_ID}
										paginationProps={{
											custom: true,
											totalSize: count,
											page: currentPage || 1,
											sizePerPage: pageSize,
											sizePerPageList: PAGE_SIZE_LIST,
											sizePerPageOptionRenderer,
											onPageChange: (page, pageSize) => {
												handlePageChange(page, pageSize)
											},
											onSizePerPageChange: (pageSize, page) => {
												handlePageChange(page, pageSize)
											},
										}}
										styleForFieldList={[
											{
												field: 'orderDate',
												styles: [{ type: 'list', class: 'd-block' }],
											},
											{
												field: 'id',
												styles: [
													{
														type: 'link',
													},
												],
											},
											{
												field: 'status',
												styles: [
													{
														type: 'grayBg',
														values: [t('production_status_1', { ns: 'status' })],
														class: 'rounded-pill bg-orange text-white',
													},
													{
														type: 'greenBg',
														values: [t('production_status_2', { ns: 'status' })],
														class: 'rounded-pill bg-orange text-white',
													},
													{
														type: 'yellowBg',
														values: [t('production_status_3', { ns: 'status' })],
														class: 'rounded-pill bg-pink text-white',
													},
													{
														type: 'redBg',
														values: [t('production_status_4', { ns: 'status' })],
														class: 'rounded-pill bg-yellow text-white',
													},
													{
														type: 'blueBg',
														values: [t('production_status_5', { ns: 'status' })],
														class: 'rounded-pill bg-red text-white',
													},
													{
														type: 'blueBg',
														values: [t('production_status_6', { ns: 'status' })],
														class: 'rounded-pill bg-success text-white',
													},
													{
														type: 'blueBg',
														values: [t('production_status_7', { ns: 'status' })],
														class: 'rounded-pill bg-red text-white',
													},
													{
														type: 'blueBg',
														values: [t('production_status_8', { ns: 'status' })],
														class: 'rounded-pill bg-blue text-white',
													},
													{
														type: 'blueBg',
														values: [t('production_status_9', { ns: 'status' })],
														class: 'rounded-pill bg-red text-white',
													},
													{
														field: 'labeling',
														styles: [
															{
																type: 'reject',
																class: 'bxs-dislike',
															},
														],
													},
												],
											},
										]}
										rowQuantityList={PAGE_SIZE_LIST}
									/>
								) : (
									<div>
										<div className="d-flex justify-content-center">
											<h2>{t('noProductionsToShow', { ns: 'naming' })}</h2>
										</div>
									</div>
								)}
							</div>
							<Modal
								isOpen={trackingModalIsOpen}
								closeModal={() => setTrackingModalIsOpen(false)}
								body={(
									<div className='m-3 mt-5 row gap-2 align-items-center'>
										{/* Transportista */}
										<div className='col'>
											<strong>{t('courier', { ns: 'naming' })}{': '}</strong>
										</div>
										<div className='col'>
											{COURIER[productionInTrackingModal?.courier]}
										</div>
										<div className="w-100" />
										{/* Seguimiento */}
										<div className='col'>
											<strong>{t('tracking', { ns: 'naming' })}{': '}</strong>
										</div>
										<div className='col'>
											<Tracking
												trackingId={productionInTrackingModal?.shipmentTrackingNumber}
												courier={productionInTrackingModal?.courier}
												estimatedArrivalDate={productionInTrackingModal?.arrivalDateEstimated}
												status={productionInTrackingModal?.statusId}
											/>
										</div>
										<div className="w-100" />
										{/* Fecha de envio */}
										<div className='col'>
											<strong>{t('shipmentDate', { ns: 'naming' })}{': '}</strong>
										</div>
										<div className='col'>
											{getDate(productionInTrackingModal?.shipmentDateReal)}
										</div>
										<div className="w-100" />
										{/* Valor declarado (editable) */}
										<div className='col'>
											<strong>{t('declared-value', { ns: 'naming' })}{': '}</strong>
										</div>
										<div className='col'>
											<InputNumber
												value={newDeclaredValueInTrackingModal}
												setValue={setNewDeclaredValueInTrackingModal}
											/>
										</div>
									</div>
								)}
								closeButtonColor="secondary"
								buttons={[
									<button
										disabled={updateProduction_Status.loading}
										type="button"
										key="btn2"
										className="btn btn-primary"
										onClick={() => {
											updateProduction({
												production: {
													orderId: productionInTrackingModal?.orderId,
													orderFragmentId: productionInTrackingModal?.orderFragmentId,
													declaredValue: newDeclaredValueInTrackingModal,
												},
											})
										}}
									>
										{t('save', { ns: 'naming' })}
									</button>,
								]}
							/>
							<Modal
								isOpen={errorModalIsOpen}
								closeModal={() => setErrorModalIsOpen(false)}
								body={(
									<h1 className='m-3 mt-5'>
										{t('error_message', { ns: 'naming' })}
									</h1>
								)}
							/>
							<ProductionFilesModal
								key={'productionFilesModal'}
								productionId={selectedProductionId}
								isOpen={isProductionFilesModalOpen}
								close={() => setIsProductionFilesModalOpen(false)}
							/>
						</div>
					)}

				</div>
			}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		suppliers: state.Users.suppliers,
		productionList: state.Productions.productionList,
		isLoadingProductonLabels: state.Productions.isLoadingProductonLabels,
		isLoadingProd: state.Productions.isLoading,
		count: state.Productions.count,
		productionLabels: state.Productions.productionLabels,
		error: state.Quotes.error,
		roles: state.Login.roles,
		accountId: state.Login.id,
		productionDetail: state.Productions.productionDetail,
		orderParts: state.Orders.orderDetail.order,
		supplierInfo: state.Users.supplierShippingTiming?.supplierInfo,
		orderDetail: state.Orders.orderDetail,
		productionFiles: state.CloudStorage.productionFiles,
		isLoadingCloud: state.CloudStorage.isLoading,
		isLoadingOrder: state.Orders.isLoading,
		supplierDetail: state.Users.supplierDetail,
		updateProduction_Status: state.Productions.updateProduction_Status,
		managers: state.Users.managers
	}
}

Productions.propTypes = {
	t: PropTypes.func,
	suppliers: PropTypes.array,
	getSuppliers: PropTypes.func,
	productionList: PropTypes.array,
	count: PropTypes.number,
	getProductionList: PropTypes.func,
	getProductionListBySupplier: PropTypes.func,
	getProductionItemsLabels: PropTypes.func,
	roles: PropTypes.arrayOf(PropTypes.string),
	accountId: PropTypes.number,
	productionLabels: PropTypes.array,
	isLoadingProductonLabels: PropTypes.bool,
	showSupplierProductions: PropTypes.bool,
	location: PropTypes.object,
	isLoadingProd: PropTypes.bool,
	getProductionDetail: PropTypes.func,
	productionDetail: PropTypes.object,
	getOrderDetailById: PropTypes.func,
	orderParts: PropTypes.array,
	getSupplierInfo: PropTypes.func,
	supplierInfo: PropTypes.object,
	error: PropTypes.string,
	orderDetail: PropTypes.object,
	productionFiles: PropTypes.array,
	isLoadingCloud: PropTypes.bool,
	getCompleteProductionFiles: PropTypes.func,
	isLoadingOrder: PropTypes.bool,
	getSupplierDetail: PropTypes.func,
	supplierDetail: PropTypes.object,
	managers: PropTypes.array,
}

export default connect(mapStateToProps, {
	getSuppliers,
	getProductionList,
	getProductionListBySupplier,
	getProductionItemsLabels,
	getProductionDetail,
	getOrderDetailById,
	getSupplierInfo,
	getSupplierDetail,
	getCompleteProductionFiles,
	updateShipmentImport,
	updateProduction,
	getManagers,
})(withTranslation()(Productions))
