import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { setSelectedOrderId } from 'store/actions'
import Loader from '../../../components/Common/Loader'
import ReceptionOrderSummary from './ReceptionOrderSummary'

const OrderSummaryList = (props) => {

	const {
		title,
		orders,
		productions,
		isLoading,
		setSelectedOrderId,
	} = props

	const isProductions = productions != null

	const ordersMapped = useMemo(() => {
		if (orders == null && productions == null) return []

		const ordersMod = isProductions ? productions.map(order => ({
			id: order.production.displayId,
			organizationName: order.organizationName,
			shipmentDate: order.production.shipmentDateEstimate,
			supplier: order.supplier.name,
			arrivalDate: order.production.arrivalDate,
			trackingNumber: order.production.shipmentTrackingNumber,
			supplierId: order.supplier.id,
		})) : orders

		return ordersMod.map(order => (
			<div
				key={order.id}
				onClick={() => {
					if (isProductions == false) setSelectedOrderId(order.id)
				}}>
				<ReceptionOrderSummary
					order={order}
					isProductions={isProductions} />
			</div>
		))
	}, [orders, productions])

	return (
		<div>
			<h3>{title}</h3>
			<div className="border-bottom">
				{!isLoading ? (
					<SimpleBar style={{
						maxHeight: '22.5rem',
						border: '0.5px solid lightgray',
						borderTopLeftRadius: '4px',
						borderTopRightRadius: '4px',
					}}>
						{ordersMapped}
					</SimpleBar>
				) :
					<div className="d-flex justify-content-center">
						<Loader className="fs-1 p-4" />
					</div>
				}
			</div>
		</div>
	)
}

OrderSummaryList.propTypes = {
	title: PropTypes.string,
	t: PropTypes.func,
	orders: PropTypes.array,
	isProductions: PropTypes.bool,
	isLoading: PropTypes.bool,
	setSelectedOrderId: PropTypes.func,
}

const mapStateToProps = (state) => ({
	isLoading: state.Orders.isLoading,
})

const mapDispatchToProps = {
	setSelectedOrderId,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(
	withTranslation()(
		OrderSummaryList,
	),
)
