import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { getDate, getFilterShipmentOrderDate, getHour, getIdFromUrl } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import Table from 'components/Table/Table'
import AppRoutes from 'constants/appRoutes'
import OrganizationFlag from 'pages/Orders/OrganizationFlag'
import RealShipmentDate from 'pages/Orders/RealShipmentDate'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getDomainOrders } from 'store/actions'

export const DomainOrders = ({ getDomainOrders, t, isLoading, error, domainOrders, isTabSelected }) => {

	const headerToRender = {
		id: t('id', { ns: 'naming' }),
		statusToRender: t('status', { ns: 'naming' }),
		customer: t('organizationCustomer', { ns: 'naming' }),
		comment: t('comment', { ns: 'naming' }),
		totalPrice: t('totalPrice', { ns: 'naming' }),
		paymentMethod: t('paymentMethod', { ns: 'naming' }),
		orderDate: t('orderDate', { ns: 'naming' }),
		orderDays: t('orderDays', { ns: 'naming' }),
		shipmentDate: t('shipmentOrderDate', { ns: 'naming' }),
		customerName: t('customerName', { ns: 'naming' }),
		organizationName: t('organizationName', { ns: 'naming' }),
		email: t('email', { ns: 'naming' }),
	}

	const [itemsToRender, setItemsToRender] = useState([])

	useEffect(() => {
		if (domainOrders.length === 0 && isTabSelected) getDomainOrders(getIdFromUrl())
	}, [isTabSelected])

	useEffect(() => {
		if (domainOrders.length > 0) setItemsToRender(mapOrders(domainOrders))
	}, [domainOrders])

	const mapOrders = orders => {
		orders.sort((a, b) => new Date(b.order.creationDate) - new Date(a.order.creationDate))
		return orders.map(order => formatOrder(order))
	}

	const formatOrder = orderObject => {
		const subtotal = orderObject.order.feeApplied + orderObject.order.shippingTotal + orderObject.order.partsPricing
		return {
			id: orderObject.order.id,
			status: {
				value: {
					id: 'order_status_' + orderObject.order.statusId,
					text: t('order_status_' + orderObject.order.statusId, { ns: 'status' }),
				},
			},
			statusToRender: t(`order_status_${orderObject.order.statusId}`, {
				ns: 'status',
			}),
			customer:
				<OrganizationFlag
					country={
						(orderObject.organization.billingInfo.country &&
							orderObject.organization.billingInfo.country.toLowerCase()) ||
						''
					}
					organizationName={orderObject.organization?.organizationName || ''}
					firstName={orderObject.customer?.personalInformation?.firstName || ''}
					lastName={orderObject.customer?.personalInformation?.lastName || ''}
					userId={orderObject.customer?.id || ''}
				/>,
			filterShipmentOrderDate: getFilterShipmentOrderDate(
				orderObject.order.estimatedShipmentDate,
				orderObject.order.realShipmentDate,
			),
			comment: orderObject.order.comment,
			customerName: orderObject.account?.personalInformation?.firstName + ' ' + orderObject.account?.personalInformation?.lastName,
			organizationName: orderObject.organization?.organizationName,
			email: orderObject.account?.email,
			totalPrice: roundAtDecimals(subtotal, 2) + '€',
			paymentMethod: orderObject.order.paymentMethodId ? t(`payment_method_${orderObject.order.paymentMethodId}_name`, { ns: 'paymentMethods' }) : '',
			orderDate: {
				value: {
					date: getDate(orderObject.order.creationDate, getLanguage()),
					hour: getHour(orderObject.order.creationDate),
				},
			},
			orderDays: orderObject.order.orderDays && orderObject.order.orderDays + (orderObject.orderDays == 1 ? ' ' + t('day', { ns: 'naming' }) : ' ' + t('days', { ns: 'naming' })),
			shipmentDate: <RealShipmentDate date={orderObject.order.estimatedShipmentDate} realDate={orderObject.realShipmentDate} />, //TODO ADD REAL SHIPMENT DATE IT DOESENT EXIST RN
		}
	}

	return (
		<div>
			{isLoading ? <div className='d-flex justify-content-center'><Loader className={'fs-1'} /></div>
				: error ? <Alert
					type="danger"
					centered
					fitContent
					message={error}
				/> :

					domainOrders.length === 0 ? <div className='py-4'>{t('no_orders_yet', { ns: 'naming' })}</div> :
						<div>{itemsToRender?.length !== 0 && (
							<Table
								hiddenFields={['customerName', 'organizationName', 'email']}
								header={headerToRender}
								items={itemsToRender}
								selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
								language={getLanguage()}
								showStatusFilter={true}
								showShipmentOrderDateFilter={t('shipmentOrderDate', {
									ns: 'naming',
								})}
								pageToNavigate={AppRoutes.ORDER_DETAIL_ROUTE_NO_ID}
								rowActions={[
									{
										label: 'code',
										action: id => openModalWithOrders(id),
									},
								]}
								styleForFieldList={[
									{
										field: 'orderDate',
										styles: [{ type: 'list', class: 'd-block' }],
									},
									{
										field: 'id',
										styles: [
											{
												type: 'link',
											},
										],
									},
									{
										field: 'code',
										styles: [
											{
												type: 'text',
												class: 'text-primary cursor-pointer',
											},
										],
									},
									{
										field: 'flag',
										styles: [{ type: 'flag', class: 'mx-auto' }],
									},
									{
										field: 'comment',
										styles: [
											{
												type: 'tooltip',
												class: 'ms-1 fs-2 bx bx-message-dots',
											},
										],
									},
									{
										field: 'statusToRender',
										styles: [
											{
												type: 'grayBg',
												values: [t('order_status_10', { ns: 'status' })],
												class: 'rounded-pill bg-secondary text-white',
											},
											{
												type: 'greenBg',
												values: [t('order_status_20', { ns: 'status' })],
												class: 'rounded-pill bg-yellow text-white',
											},
											{
												type: 'yellowBg',
												values: [t('order_status_30', { ns: 'status' })],
												class: 'rounded-pill bg-red text-white',
											},
											{
												type: 'redBg',
												values: [t('order_status_40', { ns: 'status' })],
												class: 'rounded-pill bg-orange text-white',
											},
											{
												type: 'blueBg',
												values: [t('order_status_50', { ns: 'status' })],
												class: 'rounded-pill bg-blue text-white',
											},
											{
												type: 'blueBg',
												values: [t('order_status_60', { ns: 'status' })],
												class: 'rounded-pill bg-green text-white',
											},
											{
												type: 'blueBg',
												values: [t('order_status_70', { ns: 'status' })],
												class: 'rounded-pill bg-blue text-white',
											},
											{
												type: 'blueBg',
												values: [t('order_status_80', { ns: 'status' })],
												class: 'rounded-pill bg-red text-white',
											},
											{
												type: 'blueBg',
												values: [t('order_status_90', { ns: 'status' })],
												class: 'rounded-pill bg-red text-white',
											},
										],
									},
								]}
								rowQuantityList={[
									{ text: '10', value: 10 },
									{ text: '20', value: 20 },
									{ text: '50', value: 50 },
									{ text: '200', value: 200 },
									{ text: '500', value: 500 },
									{ text: '1000', value: 1000 },
								]}
								allowAllItemsPerPage
							/>
						)}</div>}

		</div>
	)
}

DomainOrders.propTypes = {
	domainOrders: PropTypes.array,
	getDomainOrders: PropTypes.func,
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	error: PropTypes.object,
	isTabSelected: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	domainOrders: state.Orders.domainOrders,
	isLoading: state.Orders.isLoading,
	error: state.Orders.error,
})

const mapDispatchToProps = {
	getDomainOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DomainOrders))
