// Table data
export const items = [
	{
		id: 223795,
		name: 'Airi Satou',
		contactInfo: [
			'+34 636-441-236',
			'+34 93-392-34-87#243',
			'myEmail@myDomain.es',
		],
		position: 'Accountant',
		office: 'Tokyo',
		age: '33',
		startdate: '2008/11/28',
		salary: '$162,700',
	},

	{
		id: 223794,
		name: 'Angelica Ramos',
		contactInfo: [
			'+34 634-451-210',
			'+34 93-392-34-87#223',
			'myEmail@myDomain.es',
		],
		position: 'Chief Executive Officer (CEO)',
		office: 'London',
		age: '47',
		startdate: '2009/10/09',
		salary: '$1,200,000',
	},

	{
		id: 223792,
		name: 'Ashton Cox',
		contactInfo: [
			'+34 632-128-840',
			'+34 93-392-34-87#245',
			'myEmail@myDomain.es',
		],
		position: 'Junior Technical Author',
		office: 'San Francisco',
		age: '66',
		startdate: '2009/01/12',
		salary: '$86,000',
	},

	{
		id: 4,
		name: 'Bradley Greer',
		contactInfo: [
			'+34 689-981-285',
			'+34 93-392-34-87#242',
			'myEmail@myDomain.es',
		],
		position: 'Software Engineer',
		office: 'London',
		age: '41',
		startdate: '2012/10/13',
		salary: '$132,000',
	},

	{
		id: 5,
		name: 'Brenden Wagner',
		contactInfo: [
			'+34 626-545-817',
			'+34 93-392-34-87#241',
			'myEmail@myDomain.es',
		],
		position: 'Software Engineer',
		office: 'San Francisco',
		age: '28',
		startdate: '2011/06/07',
		salary: '$206,850',
	},

	{
		id: 6,
		name: 'Brielle Williamson',
		contactInfo: [
			'+34 648-681-680',
			'+34 93-392-34-87#240',
			'myEmail@myDomain.es',
		],
		position: 'Integration Specialist',
		office: 'New York',
		age: '61',
		startdate: '2012/12/02',
		salary: '$372,000',
	},

	{
		id: 7,
		name: 'Bruno Nash',
		contactInfo: [
			'+34 666-666-666',
			'+34 93-392-34-87#423',
			'myEmail@myDomain.es',
		],
		position: 'Software Engineer',
		office: 'London',
		age: '38',
		startdate: '2011/05/03',
		salary: '$163,500',
	},

	{
		id: 8,
		name: 'Caesar Vance',
		contactInfo: [
			'+34 648-485-516',
			'+34 93-392-34-87#223',
			'myEmail@myDomain.es',
		],
		position: 'Pre-Sales Support',
		office: 'New York',
		age: '21',
		startdate: '2011/12/12',
		salary: '$106,450',
	},

	{
		id: 9,
		name: 'Cara Stevens',
		contactInfo: [
			'+34 611-811-288',
			'+34 93-392-34-87#224',
			'myEmail@myDomain.es',
		],
		position: 'Sales Assistant',
		office: 'New York',
		age: '46',
		startdate: '2011/12/06',
		salary: '$145,600',
	},

	{
		id: 10,
		name: 'Cedric Kelly',
		contactInfo: [
			'+34 650-047-949',
			'+34 93-392-34-87#225',
			'myEmail@myDomain.es',
		],
		position: 'Senior Javascript Developer',
		office: 'Edinburgh',
		age: '22',
		startdate: '2012/03/29',
		salary: '$433,060',
	},

	{
		id: 11,
		name: 'Marshall',
		contactInfo: [
			'+34 686-411-510',
			'+34 93-392-34-87#226',
			'myEmail@myDomain.es',
		],
		position: 'Regional Director',
		office: 'San Francisco',
		age: '36',
		startdate: '2008/10/16',
		salary: '$470,600',
	},

	{
		id: 12,
		name: 'Hurst',
		contactInfo: [
			'+34 684-849-512',
			'+34 93-392-34-87#225',
			'myEmail@myDomain.es',
		],
		position: 'Javascript Developer',
		office: 'San Francisco',
		age: '39',
		startdate: '2009/09/15',
		salary: '$205,500',
	},

	{
		id: 13,
		name: 'Rios',
		contactInfo: [
			'+34 695-642-888',
			'+34 93-392-34-87#211',
			'myEmail@myDomain.es',
		],
		position: 'Personnel Lead',
		office: 'Edinburgh',
		age: '35',
		startdate: '2012/09/26',
		salary: '$217,500',
	},

	{
		id: 14,
		name: 'Snider',
		contactInfo: [
			'+34 677-848-642',
			'+34 93-392-34-87#251',
			'myEmail@myDomain.es',
		],
		position: 'Customer Support',
		office: 'New York',
		age: '27',
		startdate: '2011/01/25',
		salary: '$112,000',
	},

	{
		id: 15,
		name: 'Wilder',
		contactInfo: [
			'+34 600-220-630',
			'+34 93-392-34-87#276',
			'myEmail@myDomain.es',
		],
		position: 'Sales Assistant',
		office: 'Sidney',
		age: '23',
		startdate: '2010/09/20',
		salary: '$85,600',
	},

	{
		id: 16,
		name: 'Camacho',
		contactInfo: [
			'+34 682-121-131',
			'+34 93-392-34-87#248',
			'myEmail@myDomain.es',
		],
		position: 'Support Engineer',
		office: 'San Francisco',
		age: '47',
		startdate: '2009/07/07',
		salary: '$87,500',
	},

	{
		id: 17,
		name: 'Green',
		contactInfo: [
			'+34 648-451-210',
			'+34 93-392-34-87#276',
			'myEmail@myDomain.es',
		],
		position: 'Chief Operating Officer (COO)',
		office: 'San Francisco',
		age: '48',
		startdate: '2010/03/11',
		salary: '$850,000',
	},

	{
		id: 18,
		name: 'Winters',
		contactInfo: [
			'+34 645-511-210',
			'+34 93-392-34-87#476',
			'myEmail@myDomain.es',
		],
		position: 'Accountant',
		office: 'Tokyo',
		age: '63',
		startdate: '2011/07/25',
		salary: '$170,750',
	},

	{
		id: 19,
		name: 'Cortez',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#244',
			'myEmail@myDomain.es',
		],
		position: 'Team Leader',
		office: 'San Francisco',
		age: '22',
		startdate: '2008/10/26',
		salary: '$235,500',
	},

	{
		id: 20,
		name: 'Joyce',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#346',
			'myEmail@myDomain.es',
		],
		position: 'Developer',
		office: 'Edinburgh',
		age: '42',
		startdate: '2010/12/22',
		salary: '$92,575',
	},

	{
		id: 21,
		name: 'Gloria Little',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#453',
			'myEmail@myDomain.es',
		],
		position: 'Systems Administrator',
		office: 'New York',
		age: '59',
		startdate: '2009/04/10',
		salary: '$237,500',
	},

	{
		id: 22,
		name: 'Haley Kennedy',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#576',
			'myEmail@myDomain.es',
		],
		position: 'Senior Marketing Desi,ner',
		office: 'London',
		age: '43',
		startdate: '2012/12/18',
		salary: '$313,500',
	},

	{
		id: 23,
		name: 'Hermione Butler',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#678',
			'myEmail@myDomain.es',
		],
		position: 'Regional Director',
		office: 'London',
		age: '47',
		startdate: '2011/03/21',
		salary: '$356,250',
	},

	{
		id: 24,
		name: 'Herrod Chandler',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#948',
			'myEmail@myDomain.es',
		],
		position: 'Sales Assistant',
		office: 'San Francisco',
		age: '59',
		startdate: '2012/08/06',
		salary: '$137,500',
	},

	{
		id: 25,
		name: 'Hope Fuentes',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#915',
			'myEmail@myDomain.es',
		],
		position: 'Secretary',
		office: 'San Francisco',
		age: '41',
		startdate: '2010/02/12',
		salary: '$109,850',
	},

	{
		id: 26,
		name: 'Howard Hatfield',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#735',
			'myEmail@myDomain.es',
		],
		position: 'Office Manager',
		office: 'San Francisco',
		age: '51',
		startdate: '2008/12/16',
		salary: '$164,500',
	},

	{
		id: 27,
		name: 'Jackson Bradshaw',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#195',
			'myEmail@myDomain.es',
		],
		position: 'Director',
		office: 'New York',
		age: '65',
		startdate: '2008/09/26',
		salary: '$645,750',
	},

	{
		id: 28,
		name: 'Jena Gaines',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#502',
			'myEmail@myDomain.es',
		],
		position: 'Office Manager',
		office: 'London',
		age: '30',
		startdate: '2008/12/19',
		salary: '$90,560',
	},

	{
		id: 29,
		name: 'Jenette Caldwell',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#004',
			'myEmail@myDomain.es',
		],
		position: 'Development Lead',
		office: 'New York',
		age: '30',
		startdate: '2011/09/03',
		salary: '$345,000',
	},

	{
		id: 30,
		name: 'Jennifer Acosta',
		contactInfo: [
			'+34 666-451-210',
			'+34 93-392-34-87#001',
			'myEmail@myDomain.es',
		],
		position: 'Junior Javascript Devel,per',
		office: 'Edinburgh',
		age: '43',
		startdate: '2013/02/01',
		salary: '$75,650',
	},
]

export const styleForFieldList = [
	{
		field: 'position',
		styles: [
			{
				type: 'greenBg',
				values: [
					'Software Engineer',
					'Javascript Developer',
					'Senior Javascript Developer',
				],
				class: 'rounded-pill bg-success',
			},
			{
				type: 'blueBg',
				values: [
					'Team Leader',
					'Office Manager',
					'Personnel Lead',
					'Development Lead',
				],
				class: 'rounded-pill bg-info',
			},
			{
				type: 'yellowBg',
				values: ['Director', 'Regional Director'],
				class: 'rounded-pill bg-warning',
			},
			{
				type: 'redBg',
				values: [
					'Chief Executive Officer (CEO)',
					'Chief Operating Officer (COO)',
				],
				class: 'rounded-pill bg-danger',
			},
		],
	},
	{
		field: 'office',
		styles: [
			{
				type: 'greenBg',
				values: ['London'],
				class: 'rounded-pill bg-success',
			},
			{
				type: 'blueBg',
				values: ['San Francisco'],
				class: 'rounded-pill bg-info',
			},
			{
				type: 'yellowBg',
				values: ['New York'],
				class: 'rounded-pill bg-warning',
			},
			{
				type: 'redBg',
				values: ['Tokyo'],
				class: 'rounded-pill bg-danger',
			},
		],
	},
	{
		field: 'contactInfo',
		styles: [
			{
				type: 'list',
				class: 'd-block',
			},
		],
	},
]

export const columns = [
	{
		dataField: 'id',
		text: 'Id',
		sort: true,
	},
	{
		dataField: 'name',
		text: 'Name',
		sort: true,
	},
	{
		dataField: 'position',
		text: 'Position',
		sort: true,
	},
	{
		dataField: 'office',
		text: 'Office',
		sort: true,
	},
	{
		dataField: 'age',
		text: 'Age',
		sort: true,
	},
	{
		dataField: 'startdate',
		text: 'Start Date',
		sort: true,
	},
	{
		dataField: 'salary',
		text: 'Salary',
		sort: true,
	},
]

export const pageOptions = {
	sizePerPage: 10,
	custom: true,
}

export const defaultSorted = {
	dataField: 'id',
	order: 'asc',
}

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable react/react-in-jsx-scope */
export const selectRow = {
	mode: 'checkbox',
	selectionHeaderRenderer: ({ mode, checked, indeterminate }) => {
		return (
			<input
				className="form-check-input"
				type={mode}
				checked={checked}
				readOnly
				ref={input => {
					if (input) {
						input.indeterminate = indeterminate
					}
				}}
			/>
		)
	},
	selectionRenderer: ({ mode, checked, disabled }) => (
		<input
			className="form-check-input"
			type={mode}
			checked={checked}
			readOnly
		/>
	),
}
/* eslint-enable react/react-in-jsx-scope */
/* eslint-enable react/display-name */
/* eslint-enable react/prop-types */

export const rowQuantityList = [
	{ value: 50, text: '50' },
	{ value: 100, text: '100' },
	{ value: 200, text: '200' },
	{ value: 500, text: '500' },
	{ value: 1000, text: '1000' },
]
