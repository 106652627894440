import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { Button, Col, Input, Label, Row } from 'reactstrap'

import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

const partQuantityFields = [
	{
		quantityField: 'partsQuantity1',
		valueField: 'partsQ1Field',
	},
	{
		quantityField: 'partsQuantity2',
		valueField: 'partsQ2Field',
	},
	{
		quantityField: 'partsQuantity3',
		valueField: 'partsQ3Field',
	},
]
const moldQuantityFields = [
	{
		quantityField: 'moldsQuantity1',
		valueField: 'moldsQ1Field',
	},
	{
		quantityField: 'moldsQuantity2',
		valueField: 'moldsQ2Field',
	},
	{
		quantityField: 'moldsQuantity3',
		valueField: 'moldsQ3Field',
	},
]
const masterQuantityFields = [
	{
		quantityField: 'masterQuantity1',
		valueField: 'masterQ1Field',
	},
	{
		quantityField: 'masterQuantity2',
		valueField: 'masterQ2Field',
	},
	{
		quantityField: 'masterQuantity3',
		valueField: 'masterQ3Field',
	},
]

const QuoteQuantityPricingForVC = (
	// prettier-ignore
	{
		id,
		partsPerMold,
		moldsPerMaster,
		quantities,
		disabled,
		checkFields,
		enableSaveButtonForVC,
		t,
	},
) => {
	/*
	 * Cálculos previos
	 */

	const defaultPartsPerMold = partsPerMold || 20
	const defaultMoldsPerMaster = moldsPerMaster || 3

	//Valor máximo de piezasSolicitado por el cliente
	//TODO Ver si hay que controlar que vengan los tres valores informados.  De momento no se controla
	const maxQuantity = getMaxQuantity(quantities)

	//Calcular el valor inicial para la tercera cantidad de piezas, que es la cantidad máxima que ha pedido el cliente redondeada a la capacidad de los moldes quese van a utilizar
	const initialCustomerMaxPartsQuantity = getInitialCustomerMaxPartsQuantity(maxQuantity, defaultPartsPerMold)

	//Calcular el valor inicial para la tercera cantidad e moldes
	const initialMoldsQuantity = getInitialMoldsQuantity(initialCustomerMaxPartsQuantity, defaultPartsPerMold)

	const maxValueIsHigherThanDefaultPartsPerMold = maxQuantity > defaultPartsPerMold
	/*
	 * Propiedades iniciales del componente
	 */

	//Identificador de la pieza
	const [ident, setIdent] = useState(id)

	//Valor por defecto de piezas que salen de un molde
	const [ppm, setPpm] = useState(defaultPartsPerMold)
	const [ppmBu, setPpmBu] = useState(defaultPartsPerMold) //campo para restaurar el valor original en caso de cancelar la edición

	//Valor por defecto de moldes que se pueden construir con un master
	const [mpm, setMpm] = useState(defaultMoldsPerMaster)
	const [mpmBu, setMpmBu] = useState(defaultMoldsPerMaster) //campo para restaurar el valor original en caso de cancelar la edición

	/*Vacuum Casting Values.  Incluyye:
	 * -Todos campos que introduce el cotizador por pantalla
	 * -Las etiquetas que se modifican en función de molds per part y masters per mold
	 */
	const [vcv, setVacuumCastingValues] = useState({
		//La primera cantidad de piezas es una.  No se modifica
		partsQuantity1: 1,
		//La segunda cantidad de piezas coincide conla cantidad de piezas que produce un molde (por defecto 30).  SE modifica según el valor de ppm
		partsQuantity2: maxValueIsHigherThanDefaultPartsPerMold ? defaultPartsPerMold : 4,
		//La tercera cantidad de piezas es la cantidad máxima que ha pedido el cliente redondeada a la capacidad de los moldes quese van a utilizar
		//TODO si coincide con parsQuantity2, quitar la tercera fila
		partsQuantity3: initialCustomerMaxPartsQuantity,

		//Campos de precios para las cantidades de piezas de 1 a 3
		partsQ1Field: quantities[0].unitPrice ? quantities[0].unitPrice : '',
		partsQ2Field: quantities[1].unitPrice ? quantities[1].unitPrice : '',
		partsQ3Field: quantities[2].unitPrice ? quantities[2].unitPrice : '',
		//vnr estos dos
		moldsQuantity1: 1,
		moldsQuantity2: 1,
		//Cantidad de moldes necesarios (Calculado a partir de molds perpart y masters per mold ) . (Partsquantity3/ppm)
		moldsQuantity3: initialMoldsQuantity,
		//Campos de precios para los moldes (cantidades 1 a 3)
		moldsQ1Field: quantities[0].moldPrice ? quantities[0].moldPrice : '',
		moldsQ2Field: quantities[1].moldPrice ? quantities[1].moldPrice : '',
		moldsQ3Field: quantities[2].moldPrice ? quantities[2].moldPrice : '',
		//vnr estos dos también
		masterQuantity1: 1,
		masterQuantity2: 1,
		//Cantidad de masters necesarios (Calculado a partir de molds perpart y masters per mold ) (moldsQuantity/3)
		masterQuantity3: initialMoldsQuantity / defaultMoldsPerMaster,
		//Campos de precio para las cantidades de master de 1 a 3
		masterQ1Field: quantities[0].masterPrice ? quantities[0].masterPrice : '',
		masterQ2Field: quantities[1].masterPrice ? quantities[1].masterPrice : '',
		masterQ3Field: quantities[2].masterPrice ? quantities[2].masterPrice : '',

		//Campos de Manufacturing time days: quantities 1 a 3
		mtdQ1Field: quantities[0].manufacturingTime ? quantities[0].manufacturingTime : '',
		mtdQ2Field: quantities[1].manufacturingTime ? quantities[1].manufacturingTime : '',
		mtdQ3Field: quantities[2].manufacturingTime ? quantities[2].manufacturingTime : '',
	})

	//Backup de los campos varibles del formulario para restablecer on cancel
	const [vcvBu, setVacuumCastingValuesBackup] = useState('')

	/*** Control de eventos, estados y propiedades del modal ****/
	const [modalMessage, setModalMessage] = useState('')
	const [isOpenModal, setOpenModal] = useState(false)
	const [hasBeenPressedSave, setHasBeenPressedOk] = useState(false)
	const [hasBeenPressedCancel, setHasBeenPressedCancel] = useState(false)

	useEffect(() => {
		checkAllFieldsAreInformed()
	}, [])

	useEffect(() => {
		/*
		 * Si no están informados los mold per master, se desactiva MoldsQ3
		 */

		/*
		 * Si ppm está vacío, entonces vaciar todos los campos y ponerlos en rojo
		 * Si no, gestionar el cambio
		 */

		if (ppm === '' || ppm == 0) {
			setVacuumCastingValues({
				...vcv,
				partsQuantity2: '',
				partsQuantity3: '',
				moldsQuantity3: '',
				masterQuantity3: '',
			})
		} else {
			handlePpmChange()
		}
	}, [ppm])

	const updatePricesWithSameQuantity = () => {
		const fieldsToCheck = [partQuantityFields, moldQuantityFields, masterQuantityFields]
		const updatedVcv = { ...vcv }
		fieldsToCheck.forEach((field) => {
			checkSameQuantityField(field, updatedVcv)
		})
		setVacuumCastingValues(updatedVcv)
	}

	const checkSameQuantityField = (fields, updatedVcv) => {
		for (let i = 0; i < fields.length - 1; i++) {
			const currentField = fields[i]
			const nextField = fields[i + 1]
			if (vcv[currentField.quantityField] === vcv[nextField.quantityField]) {
				updatedVcv[nextField.valueField] = vcv[currentField.valueField]
			}
		}
	}

	//En caso de cambio de valor de parts per mold
	const handlePpmChange = () => {
		//La segunda cantidad tiene que coincidir siempre con el valor de PPM
		const q2 = ppm

		/*
		  La tercera cantidad es:
		  el número de piezas que quiere el cliente, 
		  redondeado al número de moldes mínimo necesario
		*/
		const tmpPq3 = maxQuantity + (ppm - (maxQuantity % ppm) == ppm ? 0 : ppm - (maxQuantity % ppm))

		/*
		  La cantidad de moldes de silicona para la tercera cantidad de piezas,
		  es la cantidad de piezas entre las piezas que hace un molde
		*/
		const tmpMoq3 = Math.ceil(tmpPq3 / ppm)

		/*
		  La cantidad de masters para la tercera cantidad de piezas,
		  Es la cantidad de moldes para la tercera cantidad entre la cantidad de masters por molde
		*/

		const tmpMaq3 = mpm == '' || ppm == 0 ? '' : Math.ceil(tmpMoq3 / mpm)

		setVacuumCastingValues({
			...vcv,
			partsQuantity2: maxValueIsHigherThanDefaultPartsPerMold ? q2 : 4,
			partsQuantity3: tmpPq3,
			moldsQuantity3: tmpMoq3,
			masterQuantity3: tmpMaq3,
		})
	}

	useEffect(() => {
		if (mpm === '' || ppm === '' || mpm == 0 || ppm == 0) {
			setVacuumCastingValues({ ...vcv, masterQuantity3: '' })
		} else {
			handleMpmChange()
		}
	}, [mpm])

	const vcvQuantityFields = [
		vcv.partsQuantity1,
		vcv.partsQuantity2,
		vcv.partsQuantity3,
		vcv.moldsQuantity1,
		vcv.moldsQuantity2,
		vcv.moldsQuantity3,
		vcv.masterQuantity1,
		vcv.masterQuantity2,
		vcv.masterQuantity3,
	]

	useEffect(() => {
		updatePricesWithSameQuantity()
	}, vcvQuantityFields)

	useEffect(() => {
		const roundedMasterQuantity = Math.ceil(vcv.masterQuantity3)
		setVacuumCastingValues({
			...vcv,
			masterQuantity3: roundedMasterQuantity,
		})
	}, [])

	const handleOnChangeValues = (value, quantity, quantityValuesFields) => {
		if (value < 0) return

		const mappedQuantityValueFields = quantityValuesFields.map(quantityValueField => {
			const { quantityField, valueField } = quantityValueField
			if (vcv[quantityField] == quantity) {
				return {
					...quantityValueField,
					value: value,
				}
			}
			return {
				...quantityValueField,
				value: vcv[valueField],
			}
		})
		changeVcvFields(mappedQuantityValueFields)
	}

	const changeVcvFields = quantityValuesFields => {
		const updatedVcv = { ...vcv }
		quantityValuesFields.forEach(quantityValueField => {
			updatedVcv[quantityValueField.valueField] = quantityValueField.value
		})
		setVacuumCastingValues(updatedVcv)
	}

	const handleMpmChange = () => {
		/*
		  La cantidad de moldes de silicona para la tercera cantidad de piezas,
		  Es la cantidad de piezas entre las piezas que hace un molde
		*/
		setVacuumCastingValues({
			...vcv,
			masterQuantity3: Math.ceil(vcv.moldsQuantity3 / mpm),
		})
	}

	//ClassName para mostrar un campo en rojo cuando es invalido
	const getInputClassName = value => {
		//Si ha sido pulsado el botón de guardar y no tiene valor, el campo se muestra en rojo
		return hasBeenPressedSave && !hasBeenPressedCancel && !value ? 'form-control is-invalid' : 'form-control'
	}

	const openModal = message => {
		setModalMessage(message)

		//Guarda los datos
		setPpmBu(ppm)
		setMpmBu(mpm)
		setVacuumCastingValuesBackup({ ...vcv })

		setOpenModal(true)
	}

	// prettier-ignore
	const acceptAndCloseModal = () => {
		setHasBeenPressedOk(true)
		setHasBeenPressedCancel(false)

		const allFieldsInformed = checkAllFieldsAreInformed()
		setOpenModal(!allFieldsInformed)
	}

	const checkAllFieldsAreInformed = () => {
		const allFieldsInformed =
			ppm &&
			mpm &&
			vcv.partsQ1Field &&
			vcv.partsQ2Field &&
			vcv.partsQ3Field &&
			vcv.moldsQ1Field &&
			vcv.moldsQ2Field &&
			vcv.moldsQ3Field &&
			vcv.masterQ1Field &&
			vcv.masterQ2Field &&
			vcv.masterQ3Field &&
			vcv.mtdQ1Field &&
			vcv.mtdQ2Field &&
			vcv.mtdQ3Field &&
			true

		const values = [
			{
				quantity: vcv.partsQuantity1,
				prevQuantity: vcvBu.partsQuantity1,
				unitPrice: vcv.partsQ1Field,
				moldsQuantity: vcv.moldsQuantity1,
				moldsUnitPrice: vcv.moldsQ1Field,
				mastQuantity: vcv.masterQuantity1,
				mastUnitPrice: vcv.masterQ1Field,
				regressionUnitPrice: null,
				manufacturingTime: vcv.mtdQ1Field,
			},
			{
				quantity: vcv.partsQuantity2,
				prevQuantity: vcvBu.partsQuantity2,
				unitPrice: vcv.partsQ2Field,
				moldsQuantity: vcv.moldsQuantity2,
				moldsUnitPrice: vcv.moldsQ2Field,
				mastQuantity: vcv.masterQuantity1,
				mastUnitPrice: vcv.masterQ2Field,
				regressionUnitPrice: null,
				manufacturingTime: vcv.mtdQ2Field,
			},
			{
				quantity: vcv.partsQuantity3,
				prevQuantity: vcvBu.partsQuantity3,
				unitPrice: vcv.partsQ3Field,
				moldsQuantity: vcv.moldsQuantity3,
				moldsUnitPrice: vcv.moldsQ3Field,
				mastQuantity: vcv.masterQuantity1,
				mastUnitPrice: vcv.masterQ3Field,
				regressionUnitPrice: null,
				manufacturingTime: vcv.mtdQ3Field,
			},
		]

		if (allFieldsInformed) enableSaveButtonForVC(id, allFieldsInformed, values, ppm, mpm)
		return allFieldsInformed
	}

	const restoreAndCloseModal = () => {
		///Restablece los datos
		setPpm(ppmBu)
		setMpm(mpmBu)
		setVacuumCastingValues({ ...vcvBu })

		//Quitar los warning de los campos (si los hubiera)
		setHasBeenPressedCancel(true)
		setHasBeenPressedOk(false)

		//Cerrar el modal
		setOpenModal(false)
	}

	return rederComponent()

	//FIN

	//Funciones internas

	//Devuelve el mayor de tres elementos
	function getMaxQuantity(quantities) {
		return quantities[0].quantity > quantities[1].quantity && quantities[0].quantity > quantities[2].quantity
			? quantities[0].quantity
			: quantities[1].quantity > quantities[2].quantity
				? quantities[1].quantity
				: quantities[2].quantity
	}

	function getInitialCustomerMaxPartsQuantity(maxQuantity, partsPerMold) {
		return Math.ceil(
			maxQuantity + (partsPerMold - (maxQuantity % partsPerMold) == partsPerMold ? 0 : partsPerMold - (maxQuantity % partsPerMold)),
		)
	}

	function getInitialMoldsQuantity(initialCustomerMaxPartsQuantity, partsPerMold) {
		return Math.ceil(initialCustomerMaxPartsQuantity) / partsPerMold
	}

	function rederComponent() {
		return (
			<span>
				<span>
					<Button
						className="ms-4 "
						onClick={e => {
							openModal()
						}}
					>
						{disabled ? 'view' : 'edit'}
					</Button>
				</span>
				<span>
					<Modal
						title={'VC Quote ' + ident + ' (Vacuum casting )'}
						size="lg"
						closeButtonText="Cancel"
						closeButtonColor="secondary"
						isOpen={isOpenModal}
						closeModal={() => {
							restoreAndCloseModal()
						}}
						body={
							<div className=" p-3">
								<Row className="pb-2">
									<Col sm="3" className="d-flex justify-content-end align-self-center">
										<Label for="ppm_field">{t('parts_per_mold', { ns: 'naming' })}</Label>
									</Col>
									<Col sm="2">
										<Input
											disabled={disabled}
											type="number"
											className={getInputClassName(ppm)}
											id="ppm_field"
											value={ppm}
											style={{ textAlign: 'right' }}
											onChange={e => setPpm(e.target.value)}
										/>
									</Col>
									<Col sm="3" className="d-flex justify-content-end align-self-center">
										<Label for="ppm_field">{t('molds_per_master', { ns: 'naming' })}</Label>
									</Col>
									<Col sm="2">
										<Input
											disabled={disabled}
											type="number"
											className={getInputClassName(mpm)}
											id="mpm_field"
											value={mpm}
											style={{ textAlign: 'right' }}
											onChange={e => setMpm(e.target.value)}
										/>
									</Col>
								</Row>
								<Row>
									{/* Columna de piezas */}
									<Col sm="3" className=" mx-1">
										<Row className="border border-light rounded mb-1">
											<Col sm="12" className="d-flex justify-content-center">
												{t('parts', { ns: 'naming' })}
											</Col>
										</Row>
										<Row className="border border-light rounded ">
											<Col>
												<Row className="mb-1">
													<Col sm="4" className="d-flex justify-content-center">
														{t('quantity', { ns: 'naming' })}
													</Col>
													<Col sm="8" className="d-flex justify-content-center">
														{t('unit_price', { ns: 'naming' })}
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm="4" className="d-flex align-self-center mx-auto">
														<Input
															type="number"
															value={vcv.partsQuantity1} //vnr
															disabled
															className="px-1"
															style={{ textAlign: 'right' }}
														/>
													</Col>
													<Col sm="8">
														<Input
															disabled={disabled}
															type="number"
															className={getInputClassName(vcv.partsQ1Field) + ' quantityPricingCell'}
															id="ppm_field"
															value={vcv.partsQ1Field}
															style={{ textAlign: 'right' }}
															onChange={e => handleOnChangeValues(e.target.value, vcv.partsQuantity1, partQuantityFields)}
														/>
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm="4" className="d-flex align-self-center">
														<Input type="number" value={vcv.partsQuantity2} disabled className="px-1" style={{ textAlign: 'right' }} />
													</Col>
													<Col sm="8">
														<Input
															disabled={disabled}
															type="number"
															className={getInputClassName(vcv.partsQ2Field) + ' quantityPricingCell'}
															id="ppm_field"
															value={vcv.partsQ2Field}
															style={{ textAlign: 'right' }}
															onChange={e => handleOnChangeValues(e.target.value, vcv.partsQuantity2, partQuantityFields)}
														/>
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm="4" className="d-flex align-self-center">
														<Input type="number" value={vcv.partsQuantity3} disabled className="px-1" style={{ textAlign: 'right' }} />
													</Col>
													<Col sm="8">
														<Input
															disabled={disabled}
															type="number"
															className={getInputClassName(vcv.partsQ3Field) + ' quantityPricingCell'}
															id="ppm_field"
															value={vcv.partsQ3Field}
															style={{ textAlign: 'right' }}
															onChange={e => handleOnChangeValues(e.target.value, vcv.partsQuantity3, partQuantityFields)}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									{/* Columna de moldes */}
									<Col sm="3" className="mx-1">
										<Row className="border border-light rounded mb-1">
											<Col sm="12" className="d-flex justify-content-center">
												{t('silicone_molds', { ns: 'naming' })}
											</Col>
										</Row>
										<Row className="border border-light rounded">
											<Col>
												<Row className=" mb-1">
													<Col sm="4" className="d-flex justify-content-center">
														{t('quantity', { ns: 'naming' })}
													</Col>
													<Col sm="8" className="d-flex justify-content-center">
														{t('unit_price', { ns: 'naming' })}
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm="4" className="d-flex align-self-center">
														<Input type="number" value={vcv.moldsQuantity1} disabled className="px-1" style={{ textAlign: 'right' }} />
													</Col>
													<Col sm="8">
														<Input
															disabled={disabled}
															type="number"
															className={getInputClassName(vcv.moldsQ1Field) + ' quantityPricingCell'}
															id="ppm_field"
															value={vcv.moldsQ1Field}
															style={{ textAlign: 'right' }}
															onChange={e => handleOnChangeValues(e.target.value, vcv.moldsQuantity1, moldQuantityFields)}
														/>
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm="4" className="d-flex align-self-center">
														<Input
															type="number"
															value={vcv.moldsQuantity2} //vnr
															disabled
															className="px-1"
															style={{ textAlign: 'right' }}
														/>
													</Col>
													<Col sm="8">
														<Input
															disabled={disabled}
															type="number"
															className={getInputClassName(vcv.moldsQ2Field) + ' quantityPricingCell'}
															id="ppm_field"
															value={vcv.moldsQ2Field}
															style={{ textAlign: 'right' }}
															onChange={e => handleOnChangeValues(e.target.value, vcv.moldsQuantity2, moldQuantityFields)}
														/>
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm="4" className="d-flex align-self-center">
														<Input type="number" value={vcv.moldsQuantity3} disabled className="px-1" style={{ textAlign: 'right' }} />
													</Col>
													<Col sm="8">
														<Input
															disabled={disabled}
															type="number"
															className={getInputClassName(vcv.moldsQ3Field) + ' quantityPricingCell'}
															id="ppm_field"
															value={vcv.moldsQ3Field}
															style={{ textAlign: 'right' }}
															onChange={e => handleOnChangeValues(e.target.value, vcv.moldsQuantity3, moldQuantityFields)}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									{/* Columna de Masters */}
									<Col sm="3" className="mx-1">
										<Row className="border border-light rounded mb-1">
											<Col sm="12" className="d-flex justify-content-center">
												{t('masters', { ns: 'naming' })}
											</Col>
										</Row>
										<Row className="border border-light rounded">
											<Col>
												<Row className="mb-1">
													<Col sm="4" className="d-flex justify-content-center">
														{t('quantity', { ns: 'naming' })}
													</Col>
													<Col sm="8" className="d-flex justify-content-center ">
														{t('unit_price', { ns: 'naming' })}
													</Col>
												</Row>
												<Row>
													<Col>
														<Row className="mb-2">
															<Col sm="4" className="d-flex align-self-center">
																<Input
																	type="number"
																	value={vcv.masterQuantity1} //vnr
																	disabled
																	className="px-1"
																	style={{ textAlign: 'right' }}
																/>
															</Col>
															<Col sm="8">
																<Input
																	disabled={disabled}
																	type="number"
																	className={getInputClassName(vcv.masterQ1Field) + ' quantityPricingCell'}
																	id="ppm_field"
																	value={vcv.masterQ1Field}
																	style={{ textAlign: 'right' }}
																	onChange={e => handleOnChangeValues(e.target.value, vcv.masterQuantity1, masterQuantityFields)}
																/>
															</Col>
														</Row>
														<Row className="mb-2">
															<Col sm="4" className="d-flex align-self-center">
																<Input
																	type="number"
																	value={vcv.masterQuantity2} //vnr
																	disabled
																	className="px-1"
																	style={{ textAlign: 'right' }}
																/>
															</Col>
															<Col sm="8">
																<Input
																	disabled={disabled}
																	type="number"
																	className={getInputClassName(vcv.masterQ2Field) + ' quantityPricingCell'}
																	id="ppm_field"
																	value={vcv.masterQ2Field}
																	style={{ textAlign: 'right' }}
																	onChange={e => handleOnChangeValues(e.target.value, vcv.masterQuantity2, masterQuantityFields)}
																/>
															</Col>
														</Row>
														<Row className="mb-2">
															<Col sm="4" className="d-flex align-self-center">
																<Input type="number" value={vcv.masterQuantity3} disabled className="px-1" style={{ textAlign: 'right' }} />
															</Col>
															<Col sm="8">
																<Input
																	disabled={disabled}
																	type="number"
																	className={getInputClassName(vcv.masterQ3Field) + ' quantityPricingCell'}
																	id="ppm_field"
																	value={vcv.masterQ3Field}
																	style={{ textAlign: 'right' }}
																	onChange={e => handleOnChangeValues(e.target.value, vcv.masterQuantity3, masterQuantityFields)}
																/>
															</Col>
														</Row>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
									{/* Columna de días de facturación */}
									<Col sm="2" className="mx-1">
										<Row className="border border-light rounded mb-1">
											<Col sm="12" className="d-flex justify-content-center">
												{t('manufracuring', { ns: 'naming' })}
											</Col>
										</Row>
										<Row className="border border-light rounded">
											<Col>
												<Row className="mb-1">
													<Col sm="12" className="d-flex justify-content-center">
														{t('time_days', { ns: 'naming' })}
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm="12">
														<Input
															disabled={disabled}
															type="text"
															className={getInputClassName(vcv.mtdQ1Field)}
															id="ppm_field"
															value={vcv.mtdQ1Field}
															style={{ textAlign: 'right' }}
															onChange={e =>
																setVacuumCastingValues({
																	...vcv,
																	mtdQ1Field: e.target.value,
																})
															}
														/>
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm="12">
														<Input
															disabled={disabled}
															type="number"
															className={getInputClassName(vcv.mtdQ2Field)}
															id="ppm_field"
															value={vcv.mtdQ2Field}
															style={{ textAlign: 'right' }}
															onChange={e =>
																setVacuumCastingValues({
																	...vcv,
																	mtdQ2Field: e.target.value,
																})
															}
														/>
													</Col>
												</Row>
												<Row className="mb-2">
													<Col sm="12">
														<Input
															disabled={disabled}
															type="number"
															className={getInputClassName(vcv.mtdQ3Field)}
															id="ppm_field"
															value={vcv.mtdQ3Field}
															style={{ textAlign: 'right' }}
															onChange={e =>
																setVacuumCastingValues({
																	...vcv,
																	mtdQ3Field: e.target.value,
																})
															}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
									</Col>
								</Row>
							</div>
						}
						buttons={[
							<button
								disabled={disabled}
								type="button"
								key="btn2"
								className="btn btn-primary"
								onClick={e => {
									acceptAndCloseModal()
								}}
							>
								Ok
							</button>,
						]}
					/>
				</span>
			</span>
		)
	}
}

QuoteQuantityPricingForVC.propTypes = {
	id: PropTypes.string,
	quantities: PropTypes.arrayOf(
		PropTypes.shape({
			quantity: PropTypes.number,
			unitPrice: PropTypes.number,
			manufacturingTime: PropTypes.number,
		}),
	),
	disabled: PropTypes.bool,
	checkFields: PropTypes.func,
	enableSaveButtonForVC: PropTypes.func,
	t: PropTypes.func,
}

export default withTranslation()(QuoteQuantityPricingForVC)
