import { Couriers } from 'constants/couriers'

export const useCouriers = (t) => {

	const COURIER = {
		[Couriers.DHL]: 'DHL',
		[Couriers.NACEX]: 'NACEX',
		[Couriers.HAND_DELIVERY]: t('hand-delivery', { ns: 'naming' }),
		[Couriers.UPS]: 'UPS',
	}

	const COURIER_URL = {
		[Couriers.DHL]: 'https://mydhl.express.dhl/es/en/tracking.html#/results?id=',
		[Couriers.NACEX]: 'https://www.nacex.es/seguimientoDetalle.do?agencia_origen=0802&numero_albaran=',
		[Couriers.HAND_DELIVERY]: '',
		[Couriers.UPS]: 'https://www.ups.com/track?loc=es_ES&requester=QUIC&tracknum=',
	}

	return { COURIER, COURIER_URL }

}
