import { getDate } from 'common/Utils/StringUtilities'
import Modal from 'components/Common/Modal/Modal'
import SimpleTable from 'components/Common/SimpleTable'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import { addInternalComment, createInternalComment } from 'store/actions'

export const InternalMessageModal = ({ t, isOpen, closeModal, messages, account, orderId, createInternalComment, newComment, isLoading, isCommentCreated, addInternalComment }) => {
	const internalMessageHeader = ['name', 'date', 'message']

	const [messageList, setMessageList] = useState([])
	const [messagesToRender, setMessagesToRender] = useState([])
	const [newMessage, setNewMessage] = useState('')

	useEffect(() => {
		if (messages != null) {
			setMessageList(messages)
		}
	}, [messages])

	useEffect(() => {
		if (newMessage == '') {
			setMessagesToRender(parseMessages())
		}
	}, [messageList])

	useEffect(() => {
		if (newComment) {
			addInternalComment(newComment)
		}
	}, [newComment])

	useEffect(() => {
		if (isCommentCreated) {
			setMessagesToRender(parseMessages())
			setMessageList((prevState) => ([
				...prevState,
				{
					managerFullName: `${account.personalInformation.firstName} ${account.personalInformation.lastName}`,
					date: getDate(new Date()),
					message: newMessage,
				},
			]))
		}
	}, [isCommentCreated])

	const parseMessages = () => {
		const parsedMessageList = messageList?.map(message => {
			return {
				name: message.managerFullName,
				date: getDate(message.date),
				message: message.message,
			}
		})
		if (newMessage != '') {
			parsedMessageList.push({
				name: `${account.personalInformation.firstName} ${account.personalInformation.lastName}`,
				date: getDate(new Date()),
				message: newMessage,
			})
		}
		return parsedMessageList
	}

	const addMessage = () => {
		setNewMessage('')
		const parsedMessages = parseMessages()
		parsedMessages.push({
			name: `${account.personalInformation.firstName} ${account.personalInformation.lastName}`,
			date: getDate(new Date()),
			message: (
				<div className="d-flex flex-row">
					<input className="form-control" type="text" id={'newMessageInput'} autoFocus />
					<button
						onClick={saveMessage}
						className="btn btn-sm btn-secondary ms-2 fs-6 h-100 w-auto d-flex align-items-center justify-content-center"
					>
						{isLoading ? <Loader /> : <i className="bx bxs-save"></i>}
					</button>
				</div>
			),
		})
		setMessagesToRender(parsedMessages)
	}

	const saveMessage = () => {
		const message = document.getElementById('newMessageInput').value
		if (message !== '') {
			setNewMessage(message)
			const comment = {
				accountId: account.id,
				type: 'internal_comment',
				subject: 'Comentario interno',
				managerFullName: `${account.personalInformation.firstName} ${account.personalInformation.lastName}`,
				message: message,
				orderId: orderId,
			}
			createInternalComment({ data: { comment } })
		}
	}

	const getTableBody = () => {
		return messagesToRender.map((message, index) => {
			return (
				<tr key={`message_${index}`} valign="middle">
					<td>{message.name}</td>
					<td>{message.date}</td>
					<td>{message.message}</td>
				</tr>
			)
		})
	}

	const close = () => {
		closeModal()
		setNewMessage('')
		setMessagesToRender([])
	}

	return (
		<>
			<Modal
				className="modal-lg"
				isOpen={isOpen}
				closeModal={close}
				title={t('internal-messages', { ns: 'naming' })}
				body={
					<div className="px-4 py-2">
						<SimpleTable key={'internalMessagesTable'} header={internalMessageHeader} getTableBody={getTableBody} />
						<button
							className="btn btn-sm btn-light me-2 fs-6 w-auto d-flex align-items-center justify-content-center"
							onClick={() => addMessage()}
						>
							<i className="bx bx-plus"></i>
						</button>
					</div>
				}
			/>
		</>
	)
}

const mapStateToProps = state => {
	return {
		newComment: state.Orders.newComment,
		isLoading: state.Orders.isLoadingComment,
		isCommentCreated: state.Orders.isCommentCreated,
	}
}

InternalMessageModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	messages: PropTypes.array,
	account: PropTypes.object,
	orderId: PropTypes.number,
	createInternalComment: PropTypes.func,
	newComment: PropTypes.object,
	isLoading: PropTypes.bool,
	isCommentCreated: PropTypes.bool,
	addInternalComment: PropTypes.func
}

export default connect(mapStateToProps, { createInternalComment, addInternalComment })(withTranslation()(InternalMessageModal))
