import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import TitleWithValueColumn from '../CommonComponents/TitleWithValueColumn'

export const TechnologiesSideBar = ({ technologiesDashboardStats, isOffers }) => {

	const { technology3DStats, technologyCNCStats, technologyVCStats } = technologiesDashboardStats || {}

	return (
		<div>
			{technologiesDashboardStats != null &&
				<div className="d-flex flex-column w-100 border-top">
					<div>
						<TitleWithValueColumn
							title="Referencias 3D"
							value={technology3DStats.currentStat?.totalParts}
							previousValue={technology3DStats.previousStat?.totalParts}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title={isOffers ? 'Valor ofertado 3D' : 'Subtotal 3D'}
							value={technology3DStats.currentStat?.totalSellingPrice}
							previousValue={technology3DStats.previousStat?.totalSellingPrice}
							isCurrency
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Clientes 3D"
							value={technology3DStats.currentStat?.totalAccounts}
							previousValue={technology3DStats.previousStat?.totalAccounts}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Referencias CNC"
							value={technologyCNCStats.currentStat?.totalParts}
							previousValue={technologyCNCStats.previousStat?.totalParts}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title={isOffers ? 'Valor ofertado CNC' : 'Subtotal CNC'}
							value={technologyCNCStats.currentStat?.totalSellingPrice}
							previousValue={technologyCNCStats.previousStat?.totalSellingPrice}
							isCurrency
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Clientes CNC"
							value={technologyCNCStats.currentStat?.totalAccounts}
							previousValue={technologyCNCStats.previousStat?.totalAccounts}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Referencias VC"
							value={technologyVCStats.currentStat?.totalParts}
							previousValue={technologyVCStats.previousStat?.totalParts}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title={isOffers ? 'Valor ofertado VC' : 'Subtotal VC'}
							value={technologyVCStats.currentStat?.totalSellingPrice}
							previousValue={technologyVCStats.previousStat?.totalSellingPrice}
							isCurrency
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Clientes VC"
							value={technologyVCStats.currentStat?.totalAccounts}
							previousValue={technologyVCStats.previousStat?.totalAccounts}
						/>
					</div>
				</div>

			}
		</div>
	)
}

TechnologiesSideBar.propTypes = {
	dateRange: PropTypes.object,
	technologiesDashboardStats: PropTypes.object,
	isOffers: PropTypes.bool,

}

const mapStateToProps = (state) => ({
	technologiesDashboardStats: state.Parts.technologiesDashboardStats,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TechnologiesSideBar)
