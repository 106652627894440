import quoteStatus from 'constants/quoteStatus'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

const SimulationPartCard = ({ t, part, defaultSupplier, suppliers, update }) => {

	const [partSupplier, setPartSupplier] = useState()
	const [partQuantity, setPartQuantity] = useState()
	const [supplierError, setSupplierError] = useState(false)

	useEffect(() => {
		if (defaultSupplier != undefined) {
			let error = true
			for (const supplier of part.costsAndMargins.suppliers) {
				if (supplier.id == defaultSupplier && supplier.statusId == quoteStatus.QUOTE_STATUS_QUOTED) {
					setPartSupplier(supplier)
					setPartQuantity(supplier.quotes[supplier.quotes.length - 1].quantity)
					document.getElementById(`select_${part.id.value}`).value = defaultSupplier
					error = false
				}
			}
			setSupplierError(error)
			if (error) {
				document.getElementById(`select_${part.id.value}`).value = ''
				setPartSupplier(undefined)
				setPartQuantity(undefined)
				update(part.id.value, {
					supplierId: undefined,
					quantity: undefined,
				})
			}
		}
	}, [defaultSupplier])

	useEffect(() => {
		if (partSupplier && partQuantity) {
			update(part.id.value, {
				supplierId: partSupplier.id,
				quantity: partQuantity,
			})
		}
	}, [partSupplier, partQuantity])

	const parseSuppliers = () => {
		const parsedSuppliers = []
		for (const supplier of suppliers) {
			for (const supplierPart of part.costsAndMargins.suppliers) {
				if (supplier.value == supplierPart.id && supplierPart.statusId == quoteStatus.QUOTE_STATUS_QUOTED) {
					parsedSuppliers.push(supplier)
					continue
				}
			}
		}
		return parsedSuppliers
	}

	const handleChangePartSupplier = (supplierId) => {
		for (const supplier of part.costsAndMargins.suppliers) {
			if (supplier.id == supplierId) {
				setPartSupplier(supplier)
				setPartQuantity(supplier.quotes[supplier.quotes.length - 1].quantity)
				setSupplierError(false)
			}
		}
	}

	const getMaxQuantity = () => {
		const quotes = part.costsAndMargins.suppliers.find(supplier => supplier.id == partSupplier.id)?.quotes
		return quotes[quotes.length - 1].quantity
	}

	return <div className={supplierError ? 'error-simulation-part-card' : 'simulation-part-card'}>
		<div className="d-flex justify-content-center p-2">
			{part.image}
		</div>
		<p className="my-1">
			<strong className="mx-2">{part.id.value}</strong>
		</p>
		<div className="mx-2 mb-2">
			<select
				id={`select_${part.id.value}`}
				className="form-select my-1"
				style={{ width: '8rem' }}
				onChange={e => handleChangePartSupplier(e.target.value)}
			>
				<option value="" disabled selected hidden>
					{t('select', { ns: 'naming' })}
				</option>
				{parseSuppliers(suppliers).map(option => (
					<option
						key={option.value}
						value={option.value}
					>
						{option.label}
					</option>
				))}
			</select>
			{partSupplier && !supplierError && <input
				className="form-control my-1"
				style={{ width: '8rem' }}
				id={`quantity_${part.id.value}`}
				type="number"
				defaultValue={getMaxQuantity()}
				min={1}
				max={getMaxQuantity()}
				onChange={e => {
					e.target.value = e.target.value.split('.')[0]
					let quantity = parseInt(e.target.value)
					const maxValue = getMaxQuantity()
					if (isNaN(quantity)) {
						e.target.value = ''
						quantity = maxValue
					} else if (quantity > maxValue) {
						e.target.value = `${maxValue}`
						quantity = maxValue
					} else if (quantity < 1) {
						e.target.value = '1'
						quantity = 1
					}
					setPartQuantity(quantity)
				}}
				onBlur={() => {
					const value = document.getElementById(`quantity_${part.id.value}`).value
					if (!value) {
						document.getElementById(`quantity_${part.id.value}`).value = '1'
					}
				}}
			/>}
		</div>
	</div>
}

SimulationPartCard.propTypes = {
	t: PropTypes.func,
	part: PropTypes.object,
	defaultSupplier: PropTypes.number,
	suppliers: PropTypes.array,
	update: PropTypes.func,
}

export default (withTranslation()(SimulationPartCard))
