import { isObjectEmpty } from 'common/Utils/ObjectUtils'
import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { getIcon } from './CrmLogsUtils'

export const ModalDetailCrmLog = ({ currentInteraction, t, history, setCurrentInteraction }) => {

	const [showModal, setShowModal] = useState(false)

	useEffect(() => {
		if (!isObjectEmpty(currentInteraction)) setShowModal(true)
	}, [currentInteraction])

	const getRelatedId = () => {
		const { interaction: { orderId, quoteId, relatedId, related } } = currentInteraction

		const mapToDetails = {
			order: { path: '/order/', label: t('order', { ns: 'naming' }) },
			quote: { path: '/offer/', label: t('offer', { ns: 'naming' }) },
		}

		const id = orderId || quoteId || (related && mapToDetails[related] ? relatedId : null)
		const type = orderId ? 'order' : quoteId ? 'quote' : related

		if (id && mapToDetails[type]) {
			return (
				<span className='cursor-pointer' onClick={() => history.push(`${mapToDetails[type].path}${id}`)}>
					{`${mapToDetails[type].label} #${id}`}
				</span>
			)
		}
	}

	const handleOnClose = () => {
		setCurrentInteraction({})
		setShowModal(false)
	}

	return (
		<div>
			<Modal
				isOpen={showModal}
				title={!isObjectEmpty(currentInteraction) &&
					<div className='d-flex flex-row align-items-center'>
						{getIcon(currentInteraction.interaction)}
						{currentInteraction.interaction.subject}
					</div>
				}
				closeModal={() => handleOnClose(false)}
				body={
					<div>
						{!isObjectEmpty(currentInteraction) &&
							<div className='p-4 d-flex flex-column'>
								<span >{currentInteraction.interaction.message}</span>
								<span className='mb-2'> {currentInteraction.interaction.managerFullName}</span>
								<span>{currentInteraction.interaction.interactionType}</span>
								{getRelatedId()}
								<span className='d-flex flex-row align-items-center'>
									<i className="bx bx-user me-1"></i>
									<strong className='text-primary'> {`${currentInteraction.account.personalInformation.firstName} ${currentInteraction.account.personalInformation.lastName}`}</strong>
								</span>
							</div>
						}
					</div>
				}
			/>

		</div>
	)
}

ModalDetailCrmLog.propTypes = {
	currentInteraction: PropTypes.object,
	t: PropTypes.func,
	history: PropTypes.func,
	setCurrentInteraction: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalDetailCrmLog)))
