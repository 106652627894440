import { languages } from 'locales/es'

export const getLanguageKeyValuePair = () => {
	const languageArray = Object.entries(languages)
	return languageArray.map((language) => {
		return {
			label: language[1],
			value: language[0],
		}
	})
}

export const findLangueByCode = (languageCode) => {
	const languages = getLanguageKeyValuePair()
	return languages.find((language) => language.value === languageCode)
}
