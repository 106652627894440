import {
	DELETE_MODIFIED_BLUEPRINT,
	DOWNLOAD_BLUEPRINT,
	DOWNLOAD_BLUEPRINT_FAIL,
	DOWNLOAD_BLUEPRINT_SUCCESS,
	GET_DOMAIN_OFFERS,
	GET_DOMAIN_OFFERS_FAIL,
	GET_DOMAIN_OFFERS_SUCCESS,
	GET_FILTERED_OFFERS,
	GET_FILTERED_OFFERS_FAIL,
	GET_FILTERED_OFFERS_SUCCESS,
	GET_OFFERS_DASHBOARD,
	GET_OFFERS_DASHBOARD_FAIL,
	GET_OFFERS_DASHBOARD_SUCCESS,
	GET_OFFER_DETAIL,
	GET_OFFER_DETAIL_FAIL,
	GET_OFFER_DETAIL_SUCCESS,
	GET_OFFER_LIST,
	GET_OFFER_LIST_COUNT,
	GET_OFFER_LIST_COUNT_FAIL,
	GET_OFFER_LIST_COUNT_SUCCESS,
	GET_OFFER_LIST_FAIL,
	GET_OFFER_LIST_SUCCESS,
	UPDATE_OFFER_CUSTOMER,
	UPDATE_OFFER_CUSTOMER_FAIL,
	UPDATE_OFFER_CUSTOMER_SUCCESS,
	UPDATE_QUOTE_MARGIN,
	UPDATE_QUOTE_MARGIN_FAIL,
	UPDATE_QUOTE_MARGIN_SUCCESS,
	UPLOAD_BLUEPRINT,
	UPLOAD_BLUEPRINT_FAIL,
	UPLOAD_BLUEPRINT_SUCCESS,
	UPLOAD_OFFER,
	UPLOAD_OFFER_FAIL,
	UPLOAD_OFFER_SUCCESS,
} from './actionTypes'




export const getOfferList = ({ page, pageSize }, signal) => ({
	type: GET_OFFER_LIST,
	payload: { page, pageSize, signal },
})

export const getOfferListSuccess = ({
	offers,
	count,
}) => ({
	type: GET_OFFER_LIST_SUCCESS,
	payload: { offers, count },
})

export const getOfferListFail = error => ({
	type: GET_OFFER_LIST_FAIL,
	payload: error,
})

export const getOfferListCount = () => ({
	type: GET_OFFER_LIST_COUNT,
})

export const getOfferListCountSuccess = ({ data: { count } }) => ({
	type: GET_OFFER_LIST_COUNT_SUCCESS,
	payload: { count },
})

export const getOfferListCountFail = error => ({
	type: GET_OFFER_LIST_COUNT_FAIL,
	payload: error,
})
export const getOfferDetail = offerId => ({
	type: GET_OFFER_DETAIL,
	payload: { offerId },
})

export const getOfferDetailSuccess = payload => ({
	type: GET_OFFER_DETAIL_SUCCESS,
	payload,
})

export const getOfferDetailFail = error => ({
	type: GET_OFFER_DETAIL_FAIL,
	payload: error,
})

export const updateQuoteMargin = (quotes) => ({
	type: UPDATE_QUOTE_MARGIN,
	payload: { quotes },
})

export const updateQuoteMarginSuccess = (response) => ({
	type: UPDATE_QUOTE_MARGIN_SUCCESS,
	payload: { response },
})

export const updateQuoteMarginFail = () => ({
	type: UPDATE_QUOTE_MARGIN_FAIL,
})

export const uploadOffer = data => {
	return {
		type: UPLOAD_OFFER,
		payload: { data },
	}
}

export const uploadOfferSuccess = (response) => ({
	type: UPLOAD_OFFER_SUCCESS,
	payload: { response },
})

export const uploadOfferFail = () => ({
	type: UPLOAD_OFFER_FAIL,
})

export const uploadBluePrintOffer = (data, callback) => {
	return {
		type: UPLOAD_BLUEPRINT,
		payload: { data, callback },
	}
}
export const updateOfferCustomer = data => {
	return {
		type: UPDATE_OFFER_CUSTOMER,
		payload: { data },
	}
}

export const uploadBlueprintOfferSuccess = () => ({
	type: UPLOAD_BLUEPRINT_SUCCESS,
})

export const uploadBlueprintOfferFail = () => ({
	type: UPLOAD_BLUEPRINT_FAIL,
})
export const updateOfferCustomerSuccess = () => ({
	type: UPDATE_OFFER_CUSTOMER_SUCCESS,
})

export const updateOfferCustomerFail = () => ({
	type: UPDATE_OFFER_CUSTOMER_FAIL,
})

export const downloadBluePrintOffer = data => {
	return {
		type: DOWNLOAD_BLUEPRINT,
		payload: { data },
	}
}

export const downloadBluePrintOfferSuccess = (response) => ({
	type: DOWNLOAD_BLUEPRINT_SUCCESS,
	payload: { response },
})

export const downloadBlueprintOfferFail = () => ({
	type: DOWNLOAD_BLUEPRINT_FAIL,
})

export const getDomainOffers = (accountId) => ({
	type: GET_DOMAIN_OFFERS,
	payload: { accountId },
})

export const getDomainOffersSuccess = (response) => ({
	type: GET_DOMAIN_OFFERS_SUCCESS,
	payload: { response },
})

export const getDomainOffersFail = (error) => ({
	type: GET_DOMAIN_OFFERS_FAIL,
	payload: { error },
})

export const getFilteredOffers = (input, signal) => ({
	type: GET_FILTERED_OFFERS,
	payload: { input, signal },
})
export const getFilteredOffersSuccess = (response) => ({
	type: GET_FILTERED_OFFERS_SUCCESS,
	payload: response,
})
export const getFilteredOffersFail = (error) => ({
	type: GET_FILTERED_OFFERS_FAIL,
	payload: { error },
})
export const deleteModifiedBluePrint = (error) => ({
	type: DELETE_MODIFIED_BLUEPRINT,
	payload: { error },
})

export const getOffersDashboard = (data) => ({
	type: GET_OFFERS_DASHBOARD,
	payload: { data },
})

export const getOffersDashboardSuccess = (response) => ({
	type: GET_OFFERS_DASHBOARD_SUCCESS,
	payload: response,
})
export const getOffersDashboardFail = (error) => ({
	type: GET_OFFERS_DASHBOARD_FAIL,
	payload: { error },
})
