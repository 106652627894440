import { useObjectState } from 'common/Hooks/UseObjectState'
import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import {
	getDate,
	getFilterShipmentOrderDate,
	getHour,
	getStatusClassName,
} from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import PartsFinder from 'components/Common/PartsFinder'
import { NoteTooltip } from 'components/NoteTooltip'
import Table from 'components/Table/Table'
import AppRoutes from 'constants/appRoutes'
import { OrderStatus } from 'constants/orderStatus'
import { PAYMENT_METHOD } from 'constants/paymentMethods'
import i18n from 'i18n'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { getManagers, getOrderDetailById, getOrderList } from 'store/actions'
import RealshipmentDate from '../Orders/RealShipmentDate'
import ActionButtons from './ActionButtons'
import ModalGenerateDeliveryNote from './ModalGenerateDeliveryNote'
import OrderFiltersModal from './OrderFiltersModal'
import { OrdersNoDatePaymentAlert } from './OrdersNoDatePaymentAlert'
import { OrdersNotAcceptedProductionAlert } from './OrdersNotAcceptedProductionAlert'
import OrganizationFlag from './OrganizationFlag'

const PAGE_SIZE_LIST = [
	{ value: 50, text: '50' },
	{ value: 100, text: '100' },
	{ value: 200, text: '200' },
	{ value: 500, text: '500' },
	{ value: 1000, text: '1000' },
]

const Orders = ({ t, orderList, getOrderDetailById, getManagers, isLoading, orderListCount, error, managers, partConfigOptions }) => {

	const [isOpenModal, setIsOpenModal] = useState(false)

	useEffect(() => {
		getManagers()
	}, [])

	const handleClickModal = (id) => {
		getOrderDetailById(id)
		setIsOpenModal(!isOpenModal)
	}

	const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
		<li key={text} role="presentation" className="d-flex align-items-center">
			<a
				href="#"
				tabIndex="-1"
				role="menuitem"
				data-page={page}
				onMouseDown={e => {
					e.preventDefault()
					onSizePerPageChange(page)
				}}
				className="btn btn-default btn-primary dropdown-toggle text-white width-3rem"
			>
				{text}
			</a>
		</li>
	)

	const headerToRender = {
		id: t('id', { ns: 'naming' }),
		statusToRender: t('status', { ns: 'naming' }),
		customer: t('organizationCustomer', { ns: 'naming' }),
		manager: t('manager', { ns: 'naming' }),
		comment: t('comment', { ns: 'naming' }),
		totalPrice: t('totalPrice', { ns: 'naming' }),
		paymentMethod: t('paymentMethod', { ns: 'naming' }),
		paymentDate: t('paymentDate', { ns: 'naming' }),
		orderDate: t('orderDate', { ns: 'naming' }),
		orderDays: t('orderDays', { ns: 'naming' }),
		shipmentDate: t('shipmentOrderDate', { ns: 'naming' }),
		actions: t('actions', { ns: 'naming' }),

	}

	const formatOrder = orderObject => {
		const totalPrice = roundAtDecimals(orderObject.order.subtotal, 2)
		const isHot = totalPrice > 3000

		const isCriticalStatus = [
			OrderStatus.CANCELLED,
			OrderStatus.PAYMENT_ERROR,
			OrderStatus.REFUNDED,
		].includes(orderObject.order.statusId)
		const isTwoWarnings = isCriticalStatus
		return {
			id: orderObject.order.id,
			status: {
				value: {
					id: 'order_status_' + orderObject.order.statusId,
					text: t('order_status_' + orderObject.order.statusId, { ns: 'status' }),
				},
			},
			statusToRender:
				<div className='d-flex flex-column'>
					<div className='d-flex flex-row align-items-center'>

						<span className={getStatusClassName(`order_status_${orderObject.order.statusId}`)}>
							{t(`order_status_${orderObject.order.statusId}`, { ns: 'status' })}

						</span>
						{!isCriticalStatus && (
							<OrdersNoDatePaymentAlert orderObject={orderObject} t={t} />
						)}

						{orderObject.isProdNotAccepted && (
							<OrdersNotAcceptedProductionAlert orderObject={orderObject} t={t} />
						)}
					</div>
					{orderObject.isOrderLate && (
						<div className="my-2">
							<span className="rounded-pill text-white bg-red">
								{t('late', { ns: 'naming' })?.toUpperCase()}
							</span>
						</div>
					)}

					{orderObject.order.partialShipment && <div>
						<span className='rounded-pill my-2 text-white w100 bg-purple'>
							{t('partial-shipment', { ns: 'naming' })}
						</span>
					</div>}

				</div>
			,
			customer:
				<OrganizationFlag
					country={orderObject.organization?.billingInfo?.country?.toLowerCase() || ''}
					organizationName={orderObject.organization?.organizationName || ''}
					firstName={orderObject.account?.personalInformation?.firstName || ''}
					lastName={orderObject.account?.personalInformation?.lastName || ''}
					userId={orderObject.account?.id || ''}
					isNewCustomer={orderObject.isFirstValidOrder}
				/>,

			shipmentDateSemaphoreFilter: getFilterShipmentOrderDate(
				orderObject.order.estimatedShipmentDate,
				orderObject.order.realShipmentDate,
			),
			comment: <NoteTooltip note={orderObject.order.comment} t={t} />,
			totalPrice: (
				<>
					{totalPrice && totalPrice + '€ '}
					{isHot && <i className='bx bxs-hot' style={{ color: 'orange' }}></i>}
				</>
			),
			paymentMethod: orderObject.order.paymentMethodId ? t(`payment_method_${orderObject.order.paymentMethodId}_name`, { ns: 'paymentMethods' }) : '',
			orderDate: {
				value: {
					date: getDate(orderObject.order.creationDate, getLanguage()),
					hour: getHour(orderObject.order.creationDate),
				},
			},
			paymentDate: orderObject.order.paymentMethodId === PAYMENT_METHOD.WIRE_TRANSFER ? {
				value: {
					date: orderObject.order.paymentDate && getDate(orderObject.order.paymentDate, getLanguage()),
				},
			} : null,
			orderDays: orderObject.order.orderDays && orderObject.order.orderDays + (orderObject.orderDays == 1 ? ' ' + t('day', { ns: 'naming' }) : ' ' + t('days', { ns: 'naming' })),
			shipmentDate: <RealshipmentDate date={orderObject.order.estimatedShipmentDate} realDate={orderObject.order.deliveryDate} statusOrder={orderObject.order.statusId} />,
			actions: <ActionButtons orderId={orderObject.order.id} handleClickModal={() => handleClickModal(orderObject.order.id)} />,
			manager: orderObject.organization.managerId ? ((managers.find(manager => manager.id === orderObject.organization.managerId)?.personalInformation.firstName || '') + ' ' +
				(managers.find(manager => manager.id === orderObject.organization.managerId)?.personalInformation.lastName || ''))
				: '',
		}
	}
	const parseData = data => {
		if (data == null) return []
		return data.map(element => {
			return formatOrder(element)
		})
	}

	const [itemsToRender, setItemsToRender] = useState(parseData(orderList))
	const [currentPage, setCurrentPage] = useState(1)
	const [pageSize, setPageSize] = useState(50)

	const { objectData, resetState, handleOnChange } = useObjectState({ shipmentDateSemaphoreFilter: [] })

	const handlePageChange = (page, pageSize) => {
		setCurrentPage(page)
		setPageSize(pageSize)
	}

	useEffect(() => {
		setItemsToRender(applySemaphoreFilters(parseData(orderList)))
	}, [i18n.language, orderList])

	const applySemaphoreFilters = (orderList) => {
		if (objectData.shipmentDateSemaphoreFilter.length === 0) return orderList

		return orderList.filter((order) => {
			return objectData.shipmentDateSemaphoreFilter.includes(order.shipmentDateSemaphoreFilter)
		})
	}

	return (
		<div className="page-content marginFooter">
			<MetaTags>
				<title>Proto&Go! | {t('orders', { ns: 'naming' })}</title>
			</MetaTags>
			<div>
				<Breadcrumbs
					title={t('management', { ns: 'naming' })}
					breadcrumbItems={[
						{
							item: t('orders', { ns: 'naming' }),
							link: '/orders',
						},
					]}
				/>
			</div>
			<div className="bg-white">
				<div className="bg-white p-4 pb-0 d-flex justify-content-between">
					<div className="d-flex justify-content-between align-items-center">
						<OrderFiltersModal pageSize={pageSize} currentPage={currentPage}
							setCurrentPage={setCurrentPage} semaphoreFilter={objectData.shipmentDateSemaphoreFilter} setSemaphoreFilter={handleOnChange} resetSemaphoreFilter={resetState} />
					</div>
					<PartsFinder />
				</div>
				{isLoading ? (
					<Loader className="font-size-80 mx-auto" />
				) : error ? <div className="d-flex justify-content-center">{<Alert type={'danger'} message={error} />}</div> :
					(<div className="bg-white mb-4 px-4">
						<div className="py-2">
							{itemsToRender?.length !== 0 ? (
								<Table
									remote
									customFilter
									header={headerToRender}
									items={itemsToRender}
									selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
									language={getLanguage()}
									pageToNavigate={AppRoutes.ORDER_DETAIL_ROUTE_NO_ID}
									counters={[{ label: 'orders', value: orderListCount }]}
									styleForFieldList={[{
										field: 'orderDate',
										styles: [{ type: 'list', class: 'd-block' }],
									}, {
										field: 'paymentDate',
										styles: [{ type: 'list', class: 'd-block' }],
									}, {
										field: 'id',
										styles: [{
											type: 'link',
										}],
									}, {
										field: 'code',
										styles: [{
											type: 'text',
											class: 'text-primary cursor-pointer',
										}],
									}, {
										field: 'flag',
										styles: [{ type: 'flag', class: 'mx-auto' }],
									},
									]}
									paginationProps={{
										custom: true,
										totalSize: orderListCount,
										page: currentPage || 1,
										sizePerPage: pageSize,
										sizePerPageList: PAGE_SIZE_LIST,
										sizePerPageOptionRenderer,
										onPageChange: (page, pageSize) => {
											handlePageChange(page, pageSize)
										},
										onSizePerPageChange: (pageSize, page) => {
											handlePageChange(page, pageSize)
										},
									}}
									rowQuantityList={[
										{ text: '100', value: 100 },
										{ text: '150', value: 150 },
										{ text: '200', value: 200 },
										{ text: '500', value: 500 },
										{ text: '1000', value: 1000 },
									]}
								/>
							) : <div className="d-flex justify-content-center">
								<h2>{t('no_orders_to_show', { ns: 'naming' })}</h2>
							</div>}
						</div>

					</div>

					)}
				<ModalGenerateDeliveryNote isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} partConfigOptions={partConfigOptions} />

			</div>
		</div>
	)
}
const mapStateToProps = state => {
	return {
		orderParts: state.Orders.orderDetail.order,
		orderList: state.Orders.orderList,
		isLoading: state.Orders.isLoadingOrderList,
		error: state.Orders.error,
		orderListCount: state.Orders.orderListCount,
		managers: state.Users.managers,
	}
}
Orders.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.func,
	orderList: PropTypes.object,
	getOrderList: PropTypes.func,
	getOrderDetailById: PropTypes.func,
	orderParts: PropTypes.array,
	isLoading: PropTypes.bool,
	isLoadingDeliveryNote: PropTypes.bool,
	orderListCount: PropTypes.number,
	error: PropTypes.string,
	getManagers: PropTypes.func,
	managers: PropTypes.object,
}
export default withGetPartConfigOptions(connect(mapStateToProps, { getOrderList, getOrderDetailById, getManagers })(
	withTranslation()(Orders),
))
