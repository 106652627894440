import PropTypes from 'prop-types'

const OptionsButtons = ({ options, valueSelected, handleOnChange, isMultiple }) => {

	const mapOption = option => {
		const { value, text, color } = option
		const isSelected = isMultiple ? valueSelected?.includes(value) : value === valueSelected
		const className = isSelected ? 'text-white bg-' + color : 'btn-outline-' + color
		return (

			<span key={`${text}_option`} className={'border border-' + color + ' mx-2 py-2 px-3 cursor-pointer rounded ' + className} onClick={() => handleOnChange(value)}>
				{text}
			</span>
		)
	}

	const allOptions = options.map(option => { return mapOption(option) })

	return <div>{allOptions}</div>
}

OptionsButtons.propTypes = {
	options: PropTypes.array,
	valueSelected: PropTypes.any,
	handleOnChange: PropTypes.func,
	isMultiple: PropTypes.bool,
}

export default OptionsButtons
