import PropTypes from 'prop-types'
import PrefixFlag from './prefixFlagComponent'

const PhoneFlag = ({ prefix, phoneNumber }) => {
	return (
		<div className="d-flex flex-row">
			<span>
				{' '}
				<PrefixFlag prefix={prefix} />
			</span>
			<span>{phoneNumber}</span>
		</div>
	)
}
PhoneFlag.propTypes = {
	prefix: PropTypes.number,
	phoneNumber: PropTypes.number,
}
export default PhoneFlag
