export const STATUS = {
	NEW: 10,
	REQUESTED: 20,
	QUOTED: 30,
	UPLOADED: 40,
	DRAFT_ORDER: 45,
	ACCEPTANCE_PENDING: 50,
	IN_PROGRESS: 60,
	SHIPPED: 70,
	RECEIVED: 80,
	CANCELLED: 90,
	REJECTED: 91,
	HIDDEN: 92,
	REJECT_PENDING: 93,
}
