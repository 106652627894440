import { isInDateRange } from 'common/Utils/DateUtils'
import UsersChart from 'components/Common/Charts/UsersChart'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getChartLabels, getIndexByType, getPreviousDateRange } from '../utils'

export const UsersDashboard = ({ t, dashboardUsers, dateRange, isOffers }) => {

	const getSeriesByData = (usersArray, previousUsersArray, newUsersArray, previousNewUsersArray) => {
		return {
			series: [
				{
					name: 'Empresas pasadas',
					type: 'column',
					data: previousUsersArray,
				},
				{
					name: 'Empresas',
					type: 'column',
					data: usersArray,
				}
				, {
					name: isOffers ? 'Nuevos clientes' : 'Nuevas empresas',
					type: 'line',
					data: newUsersArray,
				}, {
					name: isOffers ? 'Nuevos clientes pasados' : 'Nuevas empresas pasados',
					type: 'line',
					data: previousNewUsersArray,
				},
			],
			//Esto es para mostrar los gráficos de manera que se entienda mejor. El 1.1 no llegen al techo.
			maxValue: Math.max(...usersArray, ...previousUsersArray, ...newUsersArray, ...previousNewUsersArray) * 1.1 || 1,
		}
	}

	const getDashboardValues = () => {
		const usersArray = Array.from({ length: labels.length }, () => 0)
		const previousUssersArray = Array.from({ length: labels.length }, () => 0)
		const newUssersArray = Array.from({ length: labels.length }, () => 0)
		const previousnewUssersArray = Array.from({ length: labels.length }, () => 0)
		if (dashboardUsers.length === 0) return getSeriesByData(usersArray, previousUssersArray, newUssersArray, previousnewUssersArray) // RETURNS A EMPTY DASHBOARD
		for (const { creationDate, isNewUser } of dashboardUsers) {
			const index = getIndexByType(type, creationDate, dateRange)
			if (isInDateRange(creationDate, dateRange.startDate, dateRange.endDate)) {
				usersArray[index] += 1
				if (isNewUser) newUssersArray[index] += 1
			} else if (previousDateRange && isInDateRange(creationDate, previousDateRange.startDate, previousDateRange.endDate)) {
				previousUssersArray[index] += 1
				if (isNewUser) previousnewUssersArray[index] += 1
			}
		}
		return getSeriesByData(usersArray, previousUssersArray, newUssersArray, previousnewUssersArray)
	}
	const previousDateRange = useMemo(() => getPreviousDateRange(dateRange), [dateRange])
	const { labels, type } = useMemo(() => getChartLabels(dateRange, t), [dateRange])
	const { series, maxValue } = useMemo(() => getDashboardValues(), [dashboardUsers])

	return (
		<div><UsersChart series={series} labels={labels} maxValue={maxValue} isOffers={isOffers} /></div>
	)
}

UsersDashboard.propTypes = {
	t: PropTypes.func,
	dashboardUsers: PropTypes.array,
	dateRange: PropTypes.object,
	isOffers: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	dashboardUsers: state.Users.dashboardUsers,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UsersDashboard))
