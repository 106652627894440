export const getUpdatedBarcode = (barcode, quantity) => {
	const arrayBarcode = barcode.split('')
	arrayBarcode[arrayBarcode.length - 1] = quantity
	return arrayBarcode.join('')
}

export const getBarcode = (orderId, orderFragmentId, partId, quantity) => {
	return `${orderId}S${orderFragmentId}-${partId}-${quantity}`
}

export const getFileName = filename => {
	if (filename.length <= 30) return filename
	return `${filename.substring(0, 15)}...${filename.substring(filename.length - 15, filename.length)}`
}

export function base64ToArrayBuffer(base64) {
	if (typeof base64 !== 'string' || !(/^[a-zA-Z0-9+/]*={0,2}$/).test(base64)) {
		return null
	}

	var binaryString = atob(base64)
	var bytes = new Uint8Array(binaryString.length)
	for (var i = 0; i < binaryString.length; i++) {
		bytes[i] = binaryString.charCodeAt(i)
	}
	return bytes.buffer
}
