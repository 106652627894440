import PropTypes from 'prop-types'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

export const CrmLogsFilter = ({ filterItems, filteredArray, setFilteredArray, t }) => {

	const [isOpen, setIsOpen] = useState(false)

	const addOrRemoveItem = (item) => {
		if (filteredArray.includes(item)) {
			setFilteredArray(filteredArray.filter(i => i !== item))
		} else {
			setFilteredArray([...filteredArray, item])
		}
	}

	const renderFilteredItems = () => {
		return filterItems.map(item => {
			return (
				<div key={item} className="form-check form-check-outline form-check-warning mb-3" onClick={() => addOrRemoveItem(item)}>
					<input
						type="checkbox"
						className="form-check-input"
						id={`customCheck-${item}`}
						checked={filteredArray.includes(item)}
					/>
					<label
						className="form-check-label"
						htmlFor="customCheck-outlinecolor1"
					>
						{item === 'quote' ? t('offer', { ns: 'naming' }) : t(item, { ns: 'naming' })}
					</label>
				</div>
			)
		})
	}

	return (
		<div>
			<Dropdown
				isOpen={isOpen}
				direction="center"
				size="sm"
				toggle={() => setIsOpen(!isOpen)}
			>
				<DropdownToggle
					color="white"
					className="btn btn-outline-warning"
				>
					{t('filter', { ns: 'naming' })}
				</DropdownToggle>
				<DropdownMenu data-popper-placement="left-start">
					<div className="m-3">
						{filterItems.length !== 0 && renderFilteredItems()}
					</div>
				</DropdownMenu>
			</Dropdown>
		</div>
	)
}

CrmLogsFilter.propTypes = {
	filteredArray: PropTypes.array,
	setFilteredArray: PropTypes.func,
	filterItems: PropTypes.array,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CrmLogsFilter))
