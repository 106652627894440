import PropTypes from 'prop-types'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const ActiveColumnsDropdown = ({
	header,
	handleOnChangeHeader,
	t,
	resetHeader,
}) => {
	const getHeaderArray = headerInObject => {
		const array = Object.entries(headerInObject)
		const arrayWithChecked = []
		array.forEach(element => {
			arrayWithChecked.push({
				item: element[0],
				label: element[1],
				checked: true,
			})
		})
		return arrayWithChecked
	}

	const [isToggleOpened, setToggle] = useState(false)
	const [headerArray, handleOnChangeCheck] = useState(getHeaderArray(header))

	const enableAllCheckboxes = handleOnChangeCheck => {
		const checkedArray = headerArray.map(element => checkElement(element))
		handleOnChangeCheck(checkedArray)
	}
	const checkElement = element => {
		const elementChecked = Object.assign(element)
		elementChecked.checked = true
		return elementChecked
	}
	const enableAll = handleOnChangeCheck => {
		enableAllCheckboxes(handleOnChangeCheck)
		resetHeader()
	}

	const handleOnChangeHeaderCheck = (item, handleOnChangeCheck) => {
		const newArrayWithElementChecked = Object.assign(headerArray)
		newArrayWithElementChecked.forEach(element => {
			if (element.item == item) {
				element.checked = !element.checked
			}
		})
		handleOnChangeCheck(newArrayWithElementChecked)
	}
	const renderInput = (item, label, checked) => {
		return (
			<div key={label} className="form-check form-check-outline form-check-warning mb-3">
				<input
					type="checkbox"
					className="form-check-input"
					id={'customCheck-' + label}
					checked={checked}
					onChange={e => handleOnChangeHeaderAndChecked(item, label)}
				/>
				<label className="form-check-label" htmlFor={'customCheck-' + label}>
					{t(label, { ns: 'naming' })}
				</label>
			</div>
		)
	}

	const options =
		header &&
		headerArray.map(item => renderInput(item.item, item.label, item.checked))

	const handleOnChangeHeaderAndChecked = (item, label) => {
		if (!itemIsId(item)) {
			handleOnChangeHeaderCheck(item, handleOnChangeCheck)
			handleOnChangeHeader(item, label)
		}
	}

	const itemIsId = item => {
		const ID = 'id'
		if (item == ID) {
			return true
		}
		return false
	}

	return (
		<div className="my-1">
			<Dropdown
				isOpen={isToggleOpened}
				direction="center"
				size="sm"
				toggle={e => setToggle(!isToggleOpened)}
			>
				<DropdownToggle color="white" className="btn btn-secondary">
					Columnas activas
				</DropdownToggle>
				<DropdownMenu data-popper-placement="left-start">
					<div className="m-3">{options}</div>
					<div className="mx-3 border-top mb-2">
						<button
							className=" px-4 mx-4 mt-2 btn btn-primary btn-sm"
							onClick={e => enableAll(handleOnChangeCheck)}
						>
							Reset
						</button>
					</div>
				</DropdownMenu>
			</Dropdown>
		</div>
	)
}

ActiveColumnsDropdown.propTypes = {
	header: PropTypes.object,
	handleOnChangeHeader: PropTypes.func,
	t: PropTypes.func,
	resetHeader: PropTypes.func,
}

export default withTranslation()(ActiveColumnsDropdown)
