import { getBarcode } from 'common/Utils/LabelUtils'
import Modal from 'components/Common/Modal/Modal'
import PartLabel from 'components/Common/PartLabel/PartLabel'
import { PartName } from 'components/Common/PartName'
import LabelsPrintableContent from 'components/Common/ProductionLabels/LabelsPrintableContent'
import { BARCODE_FORMAT } from 'constants/BarcodeLabels'
import SelectedQuantity from 'pages/Productions/SelectedQuantity'
import ProductionStatus from 'pages/SupplierProduction/ProductionStatus'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const LabelsModal = ({
	t,
	i18n: { language },
	partId,
	productionsListByOrderId,
	suppliers,
	closeModal,
	partConfigOptions,
}) => {
	const MINIMUM_BOUNDARY_BOX_VOLUME = 20
	const [partQuantities, setPartQuantities] = useState([])

	const getFilteredProductions = () => {
		if (partId === null) return productionsListByOrderId
		const productionsFiltred = productionsListByOrderId.map((production) => {
			const productionItems = production.productionItems.filter((productionItem) => productionItem.part.id === partId)
			if (productionItems.length === 0) return null
			return {
				...production,
				productionItems,
			}
		})
		return productionsFiltred.filter((production) => production !== null)
	}

	const getPartQuantities = (filteredProductions) => {
		return filteredProductions.flatMap((production) => (
			production.productionItems.map((productionItem) => (
				{
					orderItemNumber: productionItem.orderItemNumber,
					id: productionItem.displayId,
					quantity: productionItem.totalQuantity,
				}
			))
		))
	}

	const handleOnChangePartQuantity = (newQuantity, id) => {
		setPartQuantities(partQuantities.map((el) => (el.id === id ? { id, quantity: newQuantity } : el)))
	}

	useEffect(() => {
		const filteredProductions = getFilteredProductions()
		setPartQuantities(getPartQuantities(filteredProductions))
	}, [partId])

	const mapProductions = () => {
		if (productionsListByOrderId == null || partId === undefined) return null
		const filteredProductions = getFilteredProductions()
		return filteredProductions.map((production, index) => {
			const supplier = suppliers.find((supplier) => supplier.id === production.supplierId)
			return (
				<div className='d-flex flex-column' key={`${production.displayId}_${index}`}>
					<div className='d-flex flex-row align-items-center'>
						<span className='fs-4 me-2'>{production.displayId}</span>
						<span className='fs-4 me-2'>{supplier.personalInformation.firstName}</span>
						<ProductionStatus status={production.statusId} />
					</div>

					<div className='m-4'>
						{production.productionItems.map((productionItem, index) => {
							const { part } = productionItem
							return (
								<div key={`${part.id}_${index}_table`} className='d-flex align-items-center justify-content-between w-100 row my-1'>
									<span className='col-2'><img src={part.imageUrl} height={100} width={75}></img></span>
									<span className='col-2'>{part.id}</span>
									<span className='col-4'>{<PartName name={part.name} maxLength={26}></PartName>}</span>
									<span className='col-2'>
										<SelectedQuantity
											value={partQuantities.find(el => el.id === productionItem.displayId)?.quantity}
											setValue={(newQuantity) => handleOnChangePartQuantity(newQuantity, productionItem.displayId)}
											min={0}
											max={productionItem.totalQuantity} />
									</span>

								</div>
							)
						})}</div>
				</div>
			)
		})
	}

	const findProductionItem = (id) => {
		for (const production of productionsListByOrderId) {
			for (const productionItem of production.productionItems) {
				if (productionItem.displayId === id) return productionItem
			}
		}
	}

	const getLabelsHtml = () => {
		const labels = []
		const sortedPartQuantities = Array.from(partQuantities).sort((a, b) => a.orderItemNumber - b.orderItemNumber)
		sortedPartQuantities.forEach(({ id, quantity }, index) => {
			if (!isNaN(quantity) && quantity > 0) {
				const productionItem = findProductionItem(id)
				if (productionItem != null) {
					const { part, orderId, orderFragmentId } = productionItem
					const barcode = getBarcode(orderId, orderFragmentId, part.id, quantity)
					const partConfiguration = {
						alloy: part.material.alloy.id,
						finishing: part.finishing.id,
						material: part.material.id,
						color: part.finishing.color.id,
						hardnessId: part.configuration.hardnessId,
						colorFinishId: part.finishing.color.colorFinish.id,
					}
					if (quantity > 0) {
						const label = (
							<PartLabel
								itemNumber={productionItem.orderItemNumber}
								id={`${barcode}_${index}`} //required
								code={barcode} //Barcode
								fileName={part.name}
								format={BARCODE_FORMAT} //Format. P&G uses CODE128B
								supplierContactName={barcode}
								partConfiguration={partConfiguration}
								size={part.size}
								quantity={quantity}
								weight={part.weight * quantity}
								orderId={orderId}
								partConfigOptions={partConfigOptions}
								language={language}
							/>
						)
						labels.push(label)
						// If the BBV is less than 20, then the parts are in a bag, and we only need to print the label with all quantities.
						if (quantity > 1 && part.boundaryBoxVolume > MINIMUM_BOUNDARY_BOX_VOLUME) {
							for (let i = 0; i < quantity; i++) {
								const barcodeOne = getBarcode(orderId, orderFragmentId, part.id, 1)
								const label = (
									<PartLabel
										itemNumber={productionItem.orderItemNumber}
										id={`${barcodeOne}_${i}`} //required
										code={barcodeOne} //Barcode
										fileName={part.name}
										format={BARCODE_FORMAT} //Format. P&G uses CODE128B
										supplierContactName={barcodeOne}
										partConfiguration={partConfiguration}
										size={part.size}
										quantity={1}
										weight={part.weight}
										orderId={orderId}
										partConfigOptions={partConfigOptions}
										language={language}
									/>
								)
								labels.push(label)

							}
						}
					}
				}
			}
		})
		return labels
	}

	const productionsMapped = useMemo(() => mapProductions(), [partQuantities])
	const htmlLables = useMemo(() => getLabelsHtml(), [partQuantities])

	return (
		<Modal
			title={t('print_labels_modal', { ns: 'naming' })}
			body={<div className='m-4 '>{productionsMapped && productionsMapped}</div>}
			isOpen={partId !== undefined}
			closeModal={closeModal}
			size='lg'
			buttons={
				[<LabelsPrintableContent
					key={'printable_content_receptions'} //required
					id={'printable_content_receptions'} //required
					isHidingContent
					orientation='l'
					buttonDisabled={htmlLables?.length === 0}
					filename={`reception_labels_${productionsListByOrderId[0]?.orderId || 0}`} //Filename
					buttonClassName="btn btn-primary"
					buttonContent={
						<div className="d-flex flex-row justify-content-center align-items-center m-0">
							{t('print', { ns: 'naming' })}
							<i className="bx bxs-printer font-size-20 align-middle ms-2" />
						</div>
					}
				>
					{htmlLables}
				</LabelsPrintableContent>]}
		/>

	)
}

LabelsModal.propTypes = {
	t: PropTypes.func,
	suppliers: PropTypes.array,
	productionsListByOrderId: PropTypes.array,
	closeModal: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

const mapStateToProps = (state) => ({
	productionsListByOrderId: state.Productions.productionsListByOrderId,
	suppliers: state.Users.suppliers,
	partConfigOptions: state.Parts.partConfigOptions,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LabelsModal))
