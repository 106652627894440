/**
Con el fin de que no sea necesario modificar el .env de "dev" ni de producción, he
añadido que primero busque el parametro (que por el momento solo tengo yo en local) y
que si no lo encuentra, utilize la base_url para ir a remoto.
TODO: No utilizar REACT_APP_BASE_URL, y utilizar solamente los otros parametros. 
  */
const USERS_SERVICE = process.env.REACT_APP_USERS_SERVICE || (process.env.REACT_APP_BASE_URL + '/users')
//const USERS_SERVICE_local = "http://localhost:10000/pgo" + "/users"; 

const OLD_USERS_SERVICE = process.env.REACT_APP_OLD_OLD_USERS_SERVICE || (process.env.REACT_APP_OLD_BASE_URL + '/users')
// const OLD_USERS_SERVICE = "http://localhost:4010/api" + "/users";

const OFFERS_SERVICE = process.env.REACT_APP_OFFERS_SERVICE || (process.env.REACT_APP_BASE_URL + '/offers')
// const OFFERS_SERVICE = "http://localhost:10001/pgo" + "/offers";

const OLD_OFFERS_SERVICE = process.env.REACT_APP_OLD_OLD_OFFERS_SERVICE || (process.env.REACT_APP_OLD_BASE_URL + '/offers')
// const OLD_OFFERS_SERVICE = "http://localhost:4011/api" + "/offers";

const QUOTES_SERVICE = process.env.REACT_APP_QUOTES_SERVICE || (process.env.REACT_APP_BASE_URL + '/quotes')
//const QUOTES_SERVICE = "http://localhost:10003/pgo" + "/quotes";

const OLD_QUOTES_SERVICE = process.env.REACT_APP_OLD_OLD_QUOTES_SERVICE || (process.env.REACT_APP_OLD_BASE_URL + '/quotes')
// const OLD_QUOTES_SERVICE = "http://localhost:4013/api" + "/quotes";

const ORDERS_SERVICE = process.env.REACT_APP_ORDERS_SERVICE || (process.env.REACT_APP_BASE_URL + '/orders')
//const ORDERS_SERVICE = "http://localhost:10004/pgo" + "/orders"

const CHAT_SERVICE = process.env.REACT_APP_CHAT_SERVICE || (process.env.REACT_APP_BASE_URL + '/chat')
// const CHAT_SERVICE = "http://localhost:10006/pgo" + "/chat";

const PRODUCTIONS_SERVICE = process.env.REACT_APP_PRODUCTIONS_SERVICE || (process.env.REACT_APP_BASE_URL + '/productions')
// const PRODUCTIONS_SERVICE = "http://localhost:10005/pgo" + "/productions";

const CLOUD_STORAGE_SERVICE = process.env.REACT_APP_CLOUD_STORAGE_SERVICE || (process.env.REACT_APP_BASE_URL + '/cloud-storage')
//const CLOUD_STORAGE_SERVICE = "http://localhost:20002/pgo" + "/cloud-storage"

const PARTS_SERVICE = process.env.REACT_APP_PARTS_SERVICE || (process.env.REACT_APP_BASE_URL + '/parts')
//const PARTS_SERVICE = "http://localhost:10002/pgo" + "/parts";

const EMAILS_SERVICE = process.env.REACT_APP_OLD_EMAILS_SERVICE || (process.env.REACT_APP_OLD_BASE_URL + '/smtp-relay')
// const EMAILS_SERVICE = "http://localhost:20001/api" + "/smtp-relay";

const CAD_SERVICE = process.env.REACT_APP_OLD_OLD_CAD_SERVICE

//USERS----------------------------------
export const LOGIN_ENDPOINT = USERS_SERVICE + '/login'
export const LOGOUT_ENDPOINT = OLD_USERS_SERVICE + '/logout'
export const VERIFY_USER_TOKEN_ENDPOINT = OLD_USERS_SERVICE + '/verify-user-token'
export const SEND_CHANGE_PASSWORD_EMAIL_ENDPOINT = USERS_SERVICE + '/request-change-password'
export const CHANGE_PASSWORD_ENDPOINT = USERS_SERVICE + '/reset-password'
export const VERIFY_TOKEN_ENDPOINT = USERS_SERVICE + '/verify-token'
export const GET_MANAGERS_ENDPOINT = USERS_SERVICE + '/get-managers'
export const GET_SUPPLIERS_ENDPOINT = USERS_SERVICE + '/get-suppliers'
export const GET_SUPPLIERS_BY_ORDER_ID_ENDPOINT = USERS_SERVICE + '/get-suppliers-by-order-id'
export const GET_CRM_USERS_LIST_ENDPOINT = USERS_SERVICE + '/get-crm-list'
export const GET_SUPPLIER_SHIPPING_TIMING_ENDPOINT = USERS_SERVICE + '/get-supplier-shipping-timing'
export const GET_CRM_USERS_DETAIL_ENDPOINT = USERS_SERVICE + '/get-customer-detail'
export const UPDATE_CRM_USER_DETAIL_ENDPOINT = OLD_USERS_SERVICE + '/update-crm-table-detail'
export const CREATE_NEW_CUSTOMER_ENDPOINT = USERS_SERVICE + '/create-customer-account'
export const CREATE_NEW_LEAD_ENDPOINT = USERS_SERVICE + '/create-lead-account'
export const CREATE_NEW_SUPPLIER_ENDPOINT = USERS_SERVICE + '/create-supplier-account'
export const CREATE_NEW_LOG_ENDPOINT = OLD_USERS_SERVICE + '/create-newRegisterLog'
export const GET_SUPPLIERS_INFO_ENDPOINT = USERS_SERVICE + '/get-supplier-detail-list'
export const GET_SUPPLIER_DETAIL_ENDPOINT = USERS_SERVICE + '/get-supplier-detail'
export const UPDATE_SUPPLIER_DETAIL_ENDPOINT = USERS_SERVICE + '/update-supplier'
export const GET_USERS_DASHBOARD_ENDPOINT = USERS_SERVICE + '/get-users-dashboard'
export const GET_COUNTRIES_DASHBOARD_ENDPOINT = USERS_SERVICE + '/get-countries-dashboard'
export const GET_NOTIFICATIONS_BY_ACCOUNT_ID_ENDPOINT = USERS_SERVICE + '/get-notifications-by-account-id'
export const UPDATE_NOTIFICATIONS_ENDPOINT = USERS_SERVICE + '/update-notifications'
export const GET_CRM_INTERACTIONS_LIST_ENDPOINT = USERS_SERVICE + '/get-crm-interactions-list'
export const CREATE_NOTIFICATION_ENDPOINT = USERS_SERVICE + '/create-notification'

//export const CREATE_MANAGER_ENDPOINT = OLD_USERS_SERVICE + "/create-Manager";
export const UPDATE_HOLIDAYS_ENDPOINT = OLD_USERS_SERVICE + '/update-holidays'
export const GET_ORGANIZATION_ENDPOINT = USERS_SERVICE + '/get-organization-by-id'
export const GET_ACCOUNT_BY_EMAIL_ENDPOINT = USERS_SERVICE + '/get-account-by-email'
export const CREATE_CRM_INTERACTION_ENDPOINT = USERS_SERVICE + '/create-crm-interaction'
export const GET_SUPPLIER_INFO_ENDPOINT = USERS_SERVICE + '/get-supplier-info'
export const GET_CUSTOMERS_ENDPOINT = USERS_SERVICE + '/get-customers'
export const CREATE_NEW_SHIPPING_ADDRESS_ENDPOINT = OLD_USERS_SERVICE + '/create-newShippingAddress'
export const UPDATE_SHIPPING_ADDRESS_ENDPOINT = OLD_USERS_SERVICE + '/update-shippingAddress'
export const UPDATE_PAYMENT_POSTPONEMENT_ENDPOINT = USERS_SERVICE + '/update-payment-postponement-by-email'
export const UPDATE_MANAGER_ENDPOINT = USERS_SERVICE + '/update-manager-by-email'
export const GET_DOMAIN_USERS_ENDPOINT = USERS_SERVICE + '/get-domain-accounts-by-email'
export const UPDATE_USER_DATA_ENDPOINT = USERS_SERVICE + '/update-account'
export const UPDATE_ORGANIZATION_DATA_ENDPOINT = USERS_SERVICE + '/update-organization'
export const UPDATE_ORGANIZATION_COMMON_DATA_ENDPOINT = USERS_SERVICE + '/update-organization-common-data'
export const UPDATE_PAYMENT_DATA_ENDPOINT = USERS_SERVICE + '/update-paymentMethod'
export const UPDATE_SHIPPING_ADDRESS_DATA_ENDPOINT = USERS_SERVICE + '/update-shipping-address'
export const GET_CRM_INTERACTIONS_ENDPOINT = USERS_SERVICE + '/get-domain-crm-interactions'
export const GET_LOG_IN_AS_TOKEN_ENDPOINT = USERS_SERVICE + '/get-login-as-token-by-id'
export const GET_MANAGER_LIST_ENDPOINT = USERS_SERVICE + '/get-manager-list'
export const GET_MANAGER_DETAIL_BY_ID_ENDPOINT = USERS_SERVICE + '/get-manager-detail'
export const UPDATE_MANAGER_BY_ID_ENDPOINT = USERS_SERVICE + '/update-manager-by-id'
export const CREATE_NEW_MANAGER_ENDPOINT = USERS_SERVICE + '/create-manager-account'
export const GET_ORGANIZATION_BY_ID_ENDPOINT = USERS_SERVICE + '/get-organization-by-id'
export const CREATE_NEW_HOLIDAY_ENDPOINT = USERS_SERVICE + '/create-holiday'
export const GET_HOLIDAYS_ENDPOINT = USERS_SERVICE + '/get-holidays'
export const UPDATE_HOLIDAY_BY_ID_ENDPOINT = USERS_SERVICE + '/update-holiday-by-id'
export const DELETE_HOLIDAY_BY_ID_ENDPOINT = USERS_SERVICE + '/remove-holiday-by-id'
export const REMOVE_MANAGER_COMMISSION_BY_ID_ENDPOINT = USERS_SERVICE + '/remove-manager-commission-by-id'
export const GET_MANAGERS_STATISTICS_BY_IDS_ENDPOINT = USERS_SERVICE + '/get-managers-statistics-by-ids'
export const GET_CUSTOMERS_ORDERS_OFFERS_BY_MANAGER_ID_ENDPOINT = USERS_SERVICE + '/get-customers-orders-offers-by-manager-id'
export const GET_FILTERED_CUSTOMERS_LIST_ENDPOINT = USERS_SERVICE + '/get-filtered-customers-list'

//OFFERS---------------------------------
export const GET_OFFER_LIST_ENDPOINT = OFFERS_SERVICE + '/get-offer-list-records'
export const GET_OFFER_LIST_COUNT_ENDPOINT = OLD_OFFERS_SERVICE + '/list-count'
export const GET_OFFER_DETAIL_ENDPOINT = OFFERS_SERVICE + '/get-offer-detail-by-id'
export const UPLOAD_OFFER_ENDPOINT = OFFERS_SERVICE + '/upload-offer/'
export const UPDATE_OFFER_CUSTOMER_ENDPOINT = OFFERS_SERVICE + '/update-offer-customer/'
export const GET_DOMAIN_OFFERS_ENDPOINT = OFFERS_SERVICE + '/get-domain-offers'
export const GET_FILTERED_OFFERS_ENDPOINT = OFFERS_SERVICE + '/get-filtered-offers'
export const GET_OFFERS_DASHBOARD_ENDPOINT = OFFERS_SERVICE + '/get-offers-dashboard'
//QUOTES---------------------------------
export const GET_QUOTE_LIST_ENDPOINT = QUOTES_SERVICE + '/get-quote-list-by-supplier-id/'
export const GET_QUOTE_LIST_COUNT_ENDPOINT = OLD_QUOTES_SERVICE + '/list-count/'
export const GET_QUOTE_DETAIL_ENDPOINT = QUOTES_SERVICE + '/get-quote-detail/'
export const UPDATE_QUOTE_QUANTITIES_ENDPOINT = QUOTES_SERVICE + '/update-quote-quantities'
export const CREATE_QUOTE_ENDPOINT = QUOTES_SERVICE + '/request-quote'
export const UPDATE_MARGIN_ENDPOINT = QUOTES_SERVICE + '/update-quote-margins'
export const GET_QUOTE_REGRESSIONS_BY_PART_ID_ENDPOINT = QUOTES_SERVICE + '/get-quote-regressions-by-part-id'
export const SAVE_AND_CONFIRM_QUOTE_ENDPOINT = QUOTES_SERVICE + '/save-and-confirm-quotation'
export const GET_PART_COSTS_BY_SUPPLIER_ID_ENDPOINT = QUOTES_SERVICE + '/get-part-costs-by-supplier-id'
export const GET_PART_PRICES_BY_SUPPLIER_ID_ENDPOINT = QUOTES_SERVICE + '/get-part-prices-by-supplier-id'
export const GET_CLIENT_MARGIN_ENDPOINT = QUOTES_SERVICE + '/get-all-client-margin'
export const CLONE_QUOTE_ENDPOINT = QUOTES_SERVICE + '/clone-quote'
export const GET_SIMILAR_QUOTE_PARTS_ENDPOINT = QUOTES_SERVICE + '/get-similar-quote-parts'
export const GET_REGRESSION_VALUE_BY_QUANTITY_ENDPOINT = QUOTES_SERVICE + '/get-regression-value-by-quantity'
export const GET_QUOTE_REGRESSION_VALUES_ENDPOINT = QUOTES_SERVICE + '/get-quote-regression-values'

//ORDERS---------------------------------
export const UPDATE_ORDER_SHIPPING_ADDRESS_ENDPOINT = ORDERS_SERVICE + '/update-shipping-address-by-order-id'
export const UPDATE_ORDER_ENDPOINT = ORDERS_SERVICE + '/update-order-by-id'
export const GET_ORDER_LIST_ENDPOINT = ORDERS_SERVICE + '/get-order-list'
export const GET_BILLING_DASHBOARD_ENDPOINT = ORDERS_SERVICE + '/get-billing-dashboard'
export const GET_ORDER_DETAIL_BY_ID_ENDPOINT = ORDERS_SERVICE + '/get-order-detail-by-id'
export const HANDLE_SUCCESSFUL_ORDER_ENDPOINT = ORDERS_SERVICE + '/handle-successful-order'
export const HANDLE_CANCELLED_ORDER_ENDPOINT = ORDERS_SERVICE + '/handle-cancelled-order'
export const GET_VOUCHER_LIST_ENDPOINT = ORDERS_SERVICE + '/get-voucher-list'
export const CREATE_VOUCHER_ENDPOINT = ORDERS_SERVICE + '/create-voucher'
export const UPDATE_VOUCHER_BY_CODE_ENPOINT = ORDERS_SERVICE + '/update-voucher-by-code'
export const GET_DOMAIN_ORDERS_ENDPOINT = ORDERS_SERVICE + '/get-domain-orders'
export const GET_BOX_LIST_ENDPOINT = ORDERS_SERVICE + '/get-box-list'
export const UPDATE_BOXES_BY_REF_ENDPOINT = ORDERS_SERVICE + '/update-boxes-by-ref'
export const GET_BILLING_LIST_ENDPOINT = ORDERS_SERVICE + '/get-billing-list'
export const GET_EXPORTED_PART_LIST_BY_DATE_ENDPOINT = ORDERS_SERVICE + '/get-exported-part-list-by-date'
export const GET_FILTERED_ORDERS_ENDPOINT = ORDERS_SERVICE + '/get-filtered-order-list'
export const GET_OPENED_ORDERS_ENDPOINT = ORDERS_SERVICE + '/get-opened-orders'
export const GET_OPENED_VERIFICATION_ORDERS_ENDPOINT = ORDERS_SERVICE + '/get-opened-verification-orders'
export const GET_OPENED_EXPEDITION_ORDERS_ENDPOINT = ORDERS_SERVICE + '/get-opened-expedition-orders'
export const UPDATE_ORDER_EXPEDITION_INFO_ENDPOINT = ORDERS_SERVICE + '/update-order-expedition-info'
export const UPDATE_ORDER_ITEM_ENDPOINT = ORDERS_SERVICE + '/update-order-item'
export const SCAN_ORDER_EXPEDITION_PART_ENDPOINT = ORDERS_SERVICE + '/scan-order-expedition-part'
export const CREATE_SHIPMENT_EXPORT_ENDPOINT = ORDERS_SERVICE + '/create-shipment-export'
export const GET_LAST_THREE_DAYS_SHIPMENTS_ENDPOINT = ORDERS_SERVICE + '/get-last-three-days-shipments'
export const PUT_EXPEDITION_NACEX_ENDPOINT = ORDERS_SERVICE + '/put-expedition-nacex'
export const GET_QUALITY_QUANTITIES_ENDPOINT = ORDERS_SERVICE + '/get-quality-quantities'
export const GET_QUALITY_PROBLEMS_ENDPOINT = ORDERS_SERVICE + '/get-quality-problems'
export const GET_QUALITY_SOLUTIONS_ENDPOINT = ORDERS_SERVICE + '/get-quality-solutions'
export const CREATE_PART_VERIFICATION_ENDPOINT = ORDERS_SERVICE + '/create-part-verification'
export const PUT_EXPEDITION_UPS_ENDPOINT = ORDERS_SERVICE + '/put-expedition-ups'
export const RETRIEVE_UPS_EXPEDITION_LABELS_ENDPOINT = ORDERS_SERVICE + '/retrieve-ups-expedition-label'
export const RETRIEVE_NACEX_EXPEDITION_LABELS_ENDPOINT = ORDERS_SERVICE + '/retrieve-nacex-expedition-label'
export const CREATE_REMIT_ENDPOINT = ORDERS_SERVICE + '/create-remit'
export const CREATE_INVOICE_ENDPOINT = ORDERS_SERVICE + '/create-invoice'
export const GET_INCIDENCES_LIST_ENDPOINT = ORDERS_SERVICE + '/get-incidences-list'
export const GET_PENDING_INVOICE_ORDERS_ENDPOINT = ORDERS_SERVICE + '/get-pending-invoice-orders'
export const HANDLE_SENT_ORDER_ENDPOINT = ORDERS_SERVICE + '/handle-sent-order'
export const UPDATE_QUALITY_VERIFICATION_ENDPOINT = ORDERS_SERVICE + '/update-quality-verification'
export const GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_ENDPOINT = ORDERS_SERVICE + '/get-order-item-by-order-id-search'

//PRODUCTIONS----------------------------
export const UPDATE_PRODUCTION_ENDPOINT = PRODUCTIONS_SERVICE + '/update-production'
export const GET_PRODUCTION_LIST_ENDPOINT = PRODUCTIONS_SERVICE + '/get-production-detail-list'
export const GET_PRODUCTION_ITEMS_LABELS_ENDPOINT = PRODUCTIONS_SERVICE + '/get-production-items-labels-by-id'
export const GET_SUPPLIER_PRODUCTION_ITEMS_LABELS_ENDPOINT = PRODUCTIONS_SERVICE + '/get-production-items-labels-by-id'
export const COMPLETE_PRODUCTION_ENDPOINT = PRODUCTIONS_SERVICE + '/complete-production'
export const GET_PRODUCTION_ITEMS_BY_ORDER_ID_ENDPOINT = PRODUCTIONS_SERVICE + '/get-production-items-by-order-id'
export const GET_PRODUCTIONS_PICKED_UP_BY_COURIER_ENDPOINT = PRODUCTIONS_SERVICE + '/get-productions-picked-up-by-courier'
export const GET_PRODUCTIONS_LIST_BY_ORDER_ID_ENDPOINT = PRODUCTIONS_SERVICE + '/get-production-list-by-order-id'
export const REJECT_PRODUCTION_ENDPOINT = PRODUCTIONS_SERVICE + '/reject-production'
export const GET_PRODUCTION_ITEMS_BY_PART_ID_ENDPOINT = PRODUCTIONS_SERVICE + '/get-production-items-by-part-id'
export const GET_SUPPLIER_SHIPMENT_PRICING_LIST_ENDPOINT = PRODUCTIONS_SERVICE + '/get-supplier-shipment-pricing-list'
export const LAUNCH_PRODUCTION_ENDPOINT = PRODUCTIONS_SERVICE + '/launch-production'
export const GET_PRODUCTION_DETAIL_ENDPOINT = PRODUCTIONS_SERVICE + '/get-production-detail-by-id'
export const GET_PRODUCTION_LIST_BY_SUPPLIER_ENDPOINT = PRODUCTIONS_SERVICE + '/get-production-list-by-supplier-id'
export const GET_FILTERED_PRODUCTIONS_ENDPOINT = PRODUCTIONS_SERVICE + '/get-filtered-production-detail-list/'
export const UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_ENDPOINT = PRODUCTIONS_SERVICE + '/update-production-item-received-quantity'
export const GET_SHIPMENTS_IMPORTS_ENDPOINT = PRODUCTIONS_SERVICE + '/get-shipments-imports'
export const UPDATE_SHIPMENT_IMPORT_ENDPOINT = PRODUCTIONS_SERVICE + '/update-shipment-import'
export const HANDLE_COMPLETE_PRODUCTION_ENDPOINT = PRODUCTIONS_SERVICE + '/handle-complete-production'
export const GET_PURCHASES_DASHBOARD_ENDPOINT = PRODUCTIONS_SERVICE + '/get-purchases-dashboard'
export const GET_PRODUCTIONS_BY_TRACKING_ENDPOINT = PRODUCTIONS_SERVICE + '/get-productions-by-tracking'
export const GET_TRACKING_NUMBERS_FROM_PROVIDER_ENDPOINT = PRODUCTIONS_SERVICE + '/get-tracking-numbers-from-provider'
export const UPDATE_PRODUCTION_ITEM_ENDPOINT = PRODUCTIONS_SERVICE + '/update-production-item'

//CHAT-----------------------------------
export const GET_PRODUCTION_CHAT_ENDPOINT = CHAT_SERVICE + '/get-production-chat'
export const POST_PRODUCITON_CHAT_ENDPOINT = CHAT_SERVICE + '/create-production-chat-message'
export const GET_QUOTE_CHAT_ENDPOINT = CHAT_SERVICE + '/get-quote-chat'
export const POST_QUOTE_CHAT_ENDPOINT = CHAT_SERVICE + '/create-quote-chat-message'
export const MARK_QUOTE_MESSAGES_AS_READ_ENDPOINT = CHAT_SERVICE + '/mark-quote-messages-as-read'

//CLOUD_STORAGE--------------------------
export const GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-supplier-quotation-files-by-offer-id'
export const GET_PART_LIST_IMAGES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-parts-images'
export const UPLOAD_PART_IMAGE_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-part-files/'
export const UPLOAD_QUOTE_CHAT_IMAGE_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-quote-chat-image'
export const GET_QUOTE_CHAT_IMAGES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-quote-chat-images'
export const GET_PART_LIST_BLUEPRINTS_AND_STP_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-parts-blueprints-and-stp'
export const GET_PART_LIST_FILES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-parts-files'
export const UPLOAD_OFFER_BLUEPRINT_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-part-files'
export const GET_PRODUCTION_CHAT_IMAGES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-production-chat-images'
export const UPLOAD_PRODUCTION_CHAT_IMAGE_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-production-chat-image'
export const UPLOAD_COMPLETE_PRODUCTION_FILES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-complete-production-files'
export const GET_COMPLETE_PRODUCTION_FILES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-complete-production-files'
export const DELETE_MODIFIED_BLUEPRINTS_ENDPOINT = CLOUD_STORAGE_SERVICE + '/delete-modified-blueprint'
export const DELETE_OLD_PART_FILES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/delete-old-part-files'
export const UPLOAD_ORDER_SHIPMENT_FILES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-order-shipment-files'
export const UPLOAD_QUALITY_VERIFICATION_IMAGES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-quality-verification-images'
export const UPLOAD_ORDER_DELIVERY_NOTE_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-order-delivery-note'
export const UPLOAD_PROFILE_IMAGE_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-profile-image'
export const UPLOAD_PART_FILES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-part-files'
export const GET_DELIVERY_RECEIPT_BY_ORDER_ID = CLOUD_STORAGE_SERVICE + '/get-delivery-receipt-by-order-id'

export const GET_ALL_ORDER_SHIPMENT_FILES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-all-order-shipment-files'
export const GET_QUALITY_VERIFICATION_IMAGES_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-quality-verification-images'
export const DELETE_COMPLETE_PRODUCTION_FILE_ENDPOINT = CLOUD_STORAGE_SERVICE + '/delete-complete-production-file'
export const GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-resolutions-images-by-incidence-id'
export const UPLOAD_RESOLUTION_IMAGE_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-resolution-image'
export const GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_ENDPOINT = CLOUD_STORAGE_SERVICE + '/get-manager-task-images-by-notification-id'
export const UPLOAD_MANAGER_TASK_IMAGE_ENDPOINT = CLOUD_STORAGE_SERVICE + '/upload-manager-task-image'

//PARTS----------------------------------
export const UPDATE_PART_STATUS_ENDPOINT = PARTS_SERVICE + '/update-parts-status'
export const CREATE_PARTS_QUANTITIES_ENDPOINT = PARTS_SERVICE + '/create-parts-quantities'
export const GET_PART_CONFIG_OPTIONS_ENDPOINT = PARTS_SERVICE + '/get-part-config-options'
export const UPDATE_PARTS_ENDPOINT = PARTS_SERVICE + '/update-parts-by-id'
export const GET_FILTERED_PARTS_ENDPOINT = PARTS_SERVICE + '/get-filtered-parts'
export const GET_SIMILAR_PARTS_DETAIL_ENDPOINT = PARTS_SERVICE + '/get-similar-parts-detail'
export const UPDATE_BINDING_NOTES_ENDPOINT = PARTS_SERVICE + '/update-binding-notes'
export const DELETE_BINDING_NOTES_ENDPOINT = PARTS_SERVICE + '/delete-binding-notes'
export const GET_TECHNOLOGIES_DASHBOARD_ENDPOINT = PARTS_SERVICE + '/get-technologies-dashboard'
export const GET_PARTS_PREDICTS_AND_REAL_ENDPOINT = PARTS_SERVICE + '/get-parts-predicts-and-real'

//EMAILS---------------------------------
export const SEND_UPLOAD_OFFER_EMAIL_ENDPOINT = EMAILS_SERVICE + '/send-upload-offer-email'

//CAD---------------------------------
export const UPLOAD_TO_CAD_ENDPOINT = CAD_SERVICE + '/upload'
