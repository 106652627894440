import { formatCurrencyWithSeparators } from 'common/Utils/NumberUtilities'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'

//TODO , MAKE A CHART COMPONENT THAT CAN BE USED IN BOTH DASHBOARDS
export const TechnologyChart = ({ labels, series, maxCount, maxSellingPrice, leftYAxisText, rightYAxisText }) => {

	const BAR_3D_COLOR = '#05f2d3'
	const BAR_CNC_COLOR = '#8cfc03'
	const BAR_VC_COLOR = '#ee05f2'

	const LINE_3D_COLOR = '#054cf2'
	const LINE_CNC_COLOR = '#33b044'
	const LINE_VC_COLOR = '#ae33b0'

	const options = {
		colors: [BAR_3D_COLOR, BAR_CNC_COLOR, BAR_VC_COLOR, LINE_3D_COLOR, LINE_CNC_COLOR, LINE_VC_COLOR], //color linea pequeña, color borde barra, color linea gorda
		chart: {
			type: 'line',
			stacked: false,
			height: 350,
			toolbar: { show: false },
		},
		stroke: {
			width: 3, // [borde barra pequeña, borde barra grande, linea gorda, linea pequeña
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				columnWidth: '81%',
			},
		},
		labels,
		markers: {
			size: 0,
		},
		yaxis: [{
			max: maxCount,
			title: {
				text: leftYAxisText,

			},
		}, {
			show: false,
			max: maxCount,
			title: {
				text: leftYAxisText,

			},
		}, {
			show: false,
			max: maxCount,
			title: {
				text: leftYAxisText,

			},
		}, {
			opposite: true,
			max: maxSellingPrice,
			title: {
				text: rightYAxisText,
			},
		}, {
			show: false,
			opposite: true,
			max: maxSellingPrice,
			title: {
				text: rightYAxisText,
			},
		}, {
			show: false,
			opposite: true,
			max: maxSellingPrice,
			title: {
				text: rightYAxisText,
			},
		}],

		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (y) {
					if (typeof y !== 'undefined') {
						return formatCurrencyWithSeparators(y)
					}
					return y

				},
			},
		},
	}

	return (
		<div style={{ height: '42.5rem' }}>
			<ReactApexChart height={'100%'} options={options} series={series} type="line" />

		</div>
	)
}

TechnologyChart.propTypes = {
	series: PropTypes.array,
	labels: PropTypes.array,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TechnologyChart) 
