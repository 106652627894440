import JSZip from 'jszip'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import Loader from './Loader'

export const generateZipFile = async (zipName, files) => {
	const zip = new JSZip

	for (const file of files) {
		// eslint-disable-next-line valid-typeof
		if (typeof file.file === 'blob' || file.file instanceof Blob) {
			zip.file(file.name, file.file)
		} else {
			if (file.file == null || file.name == null) continue
			const blob = await fetch(file.file).then(r => r.blob())
			zip.file(file.name, blob)
		}
	}
	zip.generateAsync({ type: 'blob' }).then(zipBlob => {
		// eslint-disable-next-line no-undef
		saveAs(zipBlob, `${zipName}.zip`)
	})
}

const ZipDownloader = ({ zipName, files, t }) => {

	const [isZipLoading, setIsZipLoading] = useState(false)

	const createZip = async (files) => {
		setIsZipLoading(true)
		generateZipFile(zipName, files)
		setIsZipLoading(false)
	}

	return (
		<button type="button" className="btn btn-pink mx-1 w-auto" onClick={() => createZip(files)} disabled={files.length === 0}>
			<div>
				{isZipLoading ? (
					<Loader />
				) : (
					<span className="d-flex flex-row">
						<i className="bx bxs-download fs-4 me-1"></i>
						<p className="mx-1 my-0">{t('download-zip', { ns: 'naming' })}</p>
					</span>
				)}
			</div>
		</button>
	)
}

ZipDownloader.propTypes = {
	files: PropTypes.array,
	t: PropTypes.func,
	zipName: PropTypes.string,
}

export default withTranslation()(ZipDownloader)
