import appRoutes from 'constants/appRoutes'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

export const IdWithWarning = ({ id, warning }) => {
	return (
		<div>
			<div className="d-flex flex-row position-absoulute">
				<span
					data-tip="Hay una incidencia de calidad"
					data-for={warning && 'id'}
				>
					<a className="text-decoration-none"
						href={`${appRoutes.PRODUCTION_DETAIL_NO_ID}${id}`}
						target="_blank"
						rel="noopener noreferrer"
					>{id}</a>
				</span>
				{warning && <i className=" mx-1 bx bxs-error text-primary fs-4"></i>}
				<ReactTooltip
					id="id"
					place="bottom"
					type="info"
					effect="solid"
					backgroundColor="orange"
					multiline={false}
					offset={{ left: -43 }}
				/>
			</div>
		</div>
	)
}

IdWithWarning.propTypes = {
	id: PropTypes.string,
	warning: PropTypes.bool,
}
