import { roundCurrency } from 'common/Utils/NumberUtilities'
import Barchart from 'components/Common/Charts/BarChart'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const CustomersDashboard = ({ t, billingCustomersStats }) => {

	const mapData = () => {
		const labels = []
		const values = []
		billingCustomersStats.sort((a, b) => b.subtotal - a.subtotal).forEach((item, index) => {
			if (index <= 9) {
				labels.push((item.domain.split('.')[0]).toUpperCase())
				values.push(roundCurrency(item.subtotal))
			}
		})
		return { labels, values }
	}

	const { labels, values } = mapData()

	return (
		<div className='pe-4'>
			<div style={{ height: '42.5rem', padding: '0.5rem' }}>
				<Barchart labels={labels} values={values} tooltipLabel={t('billing', { ns: 'naming' })} />
			</div>
		</div>
	)
}

CustomersDashboard.propTypes = {
	t: PropTypes.func,
	billingCustomersStats: PropTypes.array,
	isLoading: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	isLoading: state.Orders.isLoading,
	billingCustomersStats: state.Orders.billingCustomersStats,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomersDashboard))
