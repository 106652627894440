import { call, put, takeEvery } from 'redux-saga/effects'

// Crypto Redux States
import {
	DOWNLOAD_BLUEPRINT,
	GET_DOMAIN_OFFERS,
	GET_FILTERED_OFFERS,
	GET_OFFERS_DASHBOARD,
	GET_OFFER_DETAIL,
	GET_OFFER_LIST,
	GET_OFFER_LIST_COUNT,
	UPDATE_OFFER_CUSTOMER,
	UPDATE_QUOTE_MARGIN,
	UPLOAD_BLUEPRINT,
	UPLOAD_OFFER
} from './actionTypes'
import {
	downloadBluePrintOfferSuccess,
	downloadBlueprintOfferFail,
	getDomainOffersFail,
	getDomainOffersSuccess,
	getFilteredOffersFail,
	getFilteredOffersSuccess,
	getOfferDetailFail,
	getOfferDetailSuccess,
	getOfferListCountFail,
	getOfferListCountSuccess,
	getOfferListFail,
	getOfferListSuccess,
	getOffersDashboardFail,
	getOffersDashboardSuccess,
	updateOfferCustomerFail,
	updateOfferCustomerSuccess,
	updateQuoteMarginFail,
	updateQuoteMarginSuccess,
	uploadBlueprintOfferFail,
	uploadBlueprintOfferSuccess,
	uploadOfferFail,
	uploadOfferSuccess
} from './actions'

import {
	GET_DOMAIN_OFFERS_ENDPOINT,
	GET_FILTERED_OFFERS_ENDPOINT,
	GET_OFFERS_DASHBOARD_ENDPOINT,
	GET_OFFER_DETAIL_ENDPOINT,
	GET_OFFER_LIST_COUNT_ENDPOINT,
	GET_OFFER_LIST_ENDPOINT,
	GET_PART_LIST_FILES_ENDPOINT,
	UPDATE_MARGIN_ENDPOINT,
	UPDATE_OFFER_CUSTOMER_ENDPOINT,
	UPLOAD_OFFER_BLUEPRINT_ENDPOINT,
	UPLOAD_OFFER_ENDPOINT
} from '../../constants/backendRoutes'

import { updateDashboardCountryList } from 'store/actions'
import ApiHelper from '../../helpers/api_helper'




function* getOfferList({ payload: { page, pageSize, signal } }) {
	if (!page || !pageSize)
		throw new Error('PGO - ADMIN: Page and pageSize are required')
	try {
		const url = `${GET_OFFER_LIST_ENDPOINT}${page && pageSize ? `?page=${page}&pageSize=${pageSize}` : ''}`
		const response = yield call(ApiHelper.get, url, { signal })
		yield put(getOfferListSuccess(response))
	} catch (error) {
		yield put(getOfferListFail(error))
	}
}
function* getOfferListCount() {
	try {
		const url = `${GET_OFFER_LIST_COUNT_ENDPOINT}`
		const response = yield call(ApiHelper.get, url)
		yield put(getOfferListCountSuccess(response))
	} catch (error) {
		yield put(getOfferListCountFail(error))
	}
}
function* getOfferDetail({ payload: { offerId } }) {
	try {
		const url = `${GET_OFFER_DETAIL_ENDPOINT}/${offerId}`
		const response = yield call(ApiHelper.get, url)
		yield put(getOfferDetailSuccess(response))
	} catch (error) {
		yield put(getOfferDetailFail(error))
	}
}
function* updateQuoteMargin({ payload: { quotes } }) {
	try {
		const url = UPDATE_MARGIN_ENDPOINT
		yield call(ApiHelper.post, url, {
			data: {
				quoteAnalysisQuantity: quotes,
			},
		})
		yield put(updateQuoteMarginSuccess())
	} catch (error) {
		yield put(updateQuoteMarginFail())
	}
}
function* uploadOffer({ payload: { data } }) {
	try {
		const url = UPLOAD_OFFER_ENDPOINT
		const response = yield call(ApiHelper.post, url, { data })
		yield put(uploadOfferSuccess(response))
	} catch (error) {
		yield put(uploadOfferFail())
	}
}
function* uploadBlueprint({ payload: { data } }) {
	try {
		const url = UPLOAD_OFFER_BLUEPRINT_ENDPOINT
		yield call(ApiHelper.postForm, url, { data })
		yield put(uploadBlueprintOfferSuccess())
	} catch (error) {
		yield put(uploadBlueprintOfferFail())
	}
}
function* updateOfferCustomer({ payload: { data } }) {
	try {
		const url = UPDATE_OFFER_CUSTOMER_ENDPOINT
		yield call(ApiHelper.post, url, { data })
		yield put(updateOfferCustomerSuccess())
	} catch (error) {
		yield put(updateOfferCustomerFail())
	}
}
function* downloadBlueprint({ payload: { data } }) {
	try {
		const url = GET_PART_LIST_FILES_ENDPOINT
		const response = yield call(ApiHelper.post, url, { data })
		yield put(downloadBluePrintOfferSuccess(response))
	} catch (error) {
		yield put(downloadBlueprintOfferFail())
	}
}

function* getDomainOffers({ payload: { accountId } }) {
	try {
		const url = GET_DOMAIN_OFFERS_ENDPOINT + `/${accountId}`
		const response = yield call(ApiHelper.get, url)
		yield put(getDomainOffersSuccess(response))
	} catch (error) {
		yield put(getDomainOffersFail(error))
	}
}

function* getFilteredOffers({ payload: { input, signal } }) {
	try {
		const url = GET_FILTERED_OFFERS_ENDPOINT
		const response = yield call(ApiHelper.post, url, { data: { ...input } }, { signal })
		yield put(getFilteredOffersSuccess(response))
	} catch (error) {
		yield put(getFilteredOffersFail(error))
	}
}

function* getOffersDashboard({ payload }) {
	try {
		const url = GET_OFFERS_DASHBOARD_ENDPOINT
		const response = yield call(ApiHelper.post, url, payload)
		if (response.countryList != null) {
			yield put(updateDashboardCountryList(response.countryList))
		}
		yield put(getOffersDashboardSuccess(response))
	} catch (error) {
		yield put(getOffersDashboardFail(error))
	}
}

function* offersSaga() {
	yield takeEvery(GET_OFFER_LIST, getOfferList)
	yield takeEvery(GET_OFFER_LIST_COUNT, getOfferListCount)
	yield takeEvery(GET_OFFER_DETAIL, getOfferDetail)
	yield takeEvery(UPDATE_QUOTE_MARGIN, updateQuoteMargin)
	yield takeEvery(UPLOAD_OFFER, uploadOffer)
	yield takeEvery(UPLOAD_BLUEPRINT, uploadBlueprint)
	yield takeEvery(UPDATE_OFFER_CUSTOMER, updateOfferCustomer)
	yield takeEvery(DOWNLOAD_BLUEPRINT, downloadBlueprint)
	yield takeEvery(GET_DOMAIN_OFFERS, getDomainOffers)
	yield takeEvery(GET_FILTERED_OFFERS, getFilteredOffers)
	yield takeEvery(GET_OFFERS_DASHBOARD, getOffersDashboard)

}

export default offersSaga
