import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

export const PartSizeAndSimilarity = ({ t, size, measure, similarParts, onClick, similarity }) => {

	const hasValidSimilarity = Array.isArray(similarity) && similarity.some(item => item && item.trim() !== "")
	return (
		<div style={{ minHeight: '19.5px' }}>
			{size && size.x && size.y && size.z ? (
				<div className="d-flex align-items-center">
					<span>
						{roundAtDecimals(size.x, 1)} * {roundAtDecimals(size.y, 1)} * {roundAtDecimals(size.z, 1)} {measure}
					</span>
					{similarParts && similarParts.length != 0 && (
						<span className="d-flex align-items-center" data-tip={t('similar-parts', { ns: 'naming' })} data-for={'similarPartsTooltip'}>
							<i className={`bx bxs-error-alt ms-1 ${hasValidSimilarity ? 'text-green' : 'text-yellow'}`} onClick={() => onClick()}></i>
							<ReactTooltip id={'similarPartsTooltip'} place="top" type="info" effect="solid" />
						</span>
					)}
				</div>
			) : (
				<div></div>
			)}
		</div>
	)
}
PartSizeAndSimilarity.propTypes = {
	t: PropTypes.func,
	size: PropTypes.object,
	measure: PropTypes.string,
	similarParts: PropTypes.array,
	onClick: PropTypes.func,
	similarity: PropTypes.array,
}

export default withTranslation()(PartSizeAndSimilarity)
