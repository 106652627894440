export const mapPriceToString = (price) => {
	const insertDots = (num) => {
		const numStr = num.toString()
		const reversedStr = numStr.split('').reverse().join('') // Reverse the string to apply regex from right to left
		const chunks = reversedStr.replace(/(\d{3}(?!$))/g, '$1.') // Insert dot every three digits, except at the end
		return chunks.split('').reverse().join('') // Reverse back to original order
	}

	if (!price && price !== 0) return ''
	if (typeof price === 'string') price = parseFloat(price)

	const n = Number(price || 0).toFixed(2)

	// Split the number into integer and fraction parts
	const parts = n.split('.')

	// Format the integer part with the system locale
	// const integerPart = Number(parts[0]).toLocaleString();
	const integerPart = insertDots(parseInt(parts[0]))

	// The fraction part is already rounded to two digits by toFixed
	const fractionPart = parts[1]

	// Combine the two parts and return
	return integerPart + ',' + fractionPart
}
