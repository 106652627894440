import PropTypes from 'prop-types'

export const InputNumber = ({ value, setValue, isInvalid }) => {
	return (
		<input
			className={`form-control ${isInvalid ? 'is-invalid' : ''}`}
			value={value != null ? value : ''}
			type="number"
			onChange={(e) => {
				const newValue = parseFloat(e.currentTarget.value) || null
				if (newValue == null || typeof newValue == 'number') {
					setValue(newValue)
				} else {
					e.currentTarget.value = value
				}
			}}
		/>
	)
}

InputNumber.propTypes = {
	value: PropTypes.number,
	setValue: PropTypes.func,
	isInvalid: PropTypes.bool,
}
