import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const ShipmentOrderDateFilter = ({ t, onChange, title }) => {
	const getoptionsArray = () => {
		return [
			{
				option: 'RED',
				active: false,
				text: t('Late', { ns: 'naming' }),
				className: 'bx bxs-circle text-danger me-1',
			},
			{
				option: 'YELLOW',
				active: false,
				text: t('lastCall', { ns: 'naming' }),
				className: 'bx bxs-circle text-warning me-1',
			},
			{
				option: 'GREEN',
				active: false,
				text: t('onTime', { ns: 'naming' }),
				className: 'bx bxs-circle text-success me-1',
			},
			{
				option: 'DELIVERED',
				active: false,
				text: t('delivered', { ns: 'naming' }),
				className: 'bx bxs-plane-take-off text-success me-1',
			},
		]
	}

	const [isToggleOpened, setToggle] = useState(false)
	const [optionsActiveArray, setOptionActiveArray] = useState(
		getoptionsArray(),
	)

	useEffect(() => {
		if (optionsActiveArray) {
			onChange(getOptionsActiveArray())
		}
	}, [])

	const getOptionsActiveArray = () => {
		const activeOptionsArray = []
		optionsActiveArray.forEach(element => {
			if (element.active == true) {
				activeOptionsArray.push(element.option)
			}
		})
		return activeOptionsArray
	}

	const renderInput = option => {
		return (
			<div
				className="p-3 list-status-item-filter cursor-pointer d-flex"
				onClick={e => changeListItems(option.option, setOptionActiveArray)}
			>
				<div className="mx-2">
					<i
						className={
							option.active
								? option.className
								: option.className + ' text-white'
						}
					></i>
				</div>
				<div className="mx-2">{option.text}</div>
			</div>
		)
	}

	const changeListItems = (option, setOptionsActiveArray) => {
		activate(option, setOptionsActiveArray)
		onChange(getOptionsActiveArray())
	}

	const activate = (option, setOptionsActiveArray, isActivatingHoover) => {
		const newArray = optionsActiveArray.map(element =>
			activateOption(element, option, isActivatingHoover),
		)
		setOptionsActiveArray(newArray)
	}

	const activateOption = (element, option, isActivatingHoover) => {
		if (element.option == option) {
			if (isActivatingHoover) {
				return activateElement(
					element,
					!element.isOnHoover,
					isActivatingHoover,
				)
			}
			return activateElement(element, !element.active, isActivatingHoover)
		}
		return element
	}

	const activateElement = (element, bool, isActivatingHoover) => {
		const elementActive = Object.assign(element)
		if (!isActivatingHoover) {
			elementActive.active = bool
		} else {
			elementActive.isOnHoover = bool
		}
		return elementActive
	}

	const options = optionsActiveArray.map(status => renderInput(status))

	return (
		<div className="my-1">
			<Dropdown
				isOpen={isToggleOpened}
				direction="center"
				size="sm"
				toggle={e => setToggle(!isToggleOpened)}
			>
				<DropdownToggle color="white" className="btn btn-secondary">
					{title}
				</DropdownToggle>
				<DropdownMenu data-popper-placement="left-start">
					<div className="my-3 mb-0">
						<div className="d-flex flex-column text-center filter-width">
							{options}
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
		</div>
	)
}

ShipmentOrderDateFilter.propTypes = {
	t: PropTypes.func,
	statusArray: PropTypes.array,
	onChange: PropTypes.func,
	resetFilter: PropTypes.func,
	title: PropTypes.string,
}

export default withTranslation()(ShipmentOrderDateFilter)
