import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { ConfigTranslations } from 'common/Utils/PartConfigTranslations'
import Modal from 'components/Common/Modal/Modal'
import Table from 'components/Table/Table'
import RegisterIncidenceModal from 'pages/Verification/Modals/RegisterIncidenceModal'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

const SelectPartOfOrderModal = ({ t, i18n: { language }, orderItemList, partConfigOptions, closeModal, isOpen, productions, orderDetail, account, }) => {
	const header = {
		item: t('item', { ns: 'naming' }),
		id: t('id', { ns: 'naming' }),
		part: t('part', { ns: 'naming' }),
		technology: t('technology', { ns: 'naming' }),
		files: t('files', { ns: 'naming' }),
		description: t('description', { ns: 'naming' }),
	}

	const [selectedPart, setSelectedPart] = useState(null)
	const [isIncidenceModalOpen, setIncidenceModalOpen] = useState(false)
	const [quantityList, setQuantityList] = useState([])


	const handleOpenRegisterModal = () => {
		setIncidenceModalOpen(true)
	}
	const handleCloseRegisterModal = () => {
		setIncidenceModalOpen(false)
	}

	useEffect(() => {
		setSelectedPart(null)
	}, [closeModal])




	useEffect(() => {
		if (selectedPart?.quantity) {
			const newQuantityList = calculatePartQuantityList(selectedPart.quantity.value)
			setQuantityList(newQuantityList)
		}
	}, [selectedPart])



	const calculatePartQuantityList = (maxQuantity) => {
		return [0, ...Array(maxQuantity).fill().map((element, index) => index + 1)]
	}



	return (
		<>
			<Modal
				className="modal-lg"
				isOpen={isOpen}
				closeModal={closeModal}
				title={`${t('select_part_of_order', { ns: 'naming' })} ${orderDetail && orderDetail.id}`}
				buttons={[
					<button
						className={'btn btn-primary'}
						onClick={handleOpenRegisterModal}
						key="register-incidence"
						disabled={!selectedPart}
					>
						{t('register-incidence', { ns: 'naming' })}
					</button>
				]}
				body={
					<div className="mb-4 px-3 mt-4">
						<Table
							disableInteractions
							header={header}
							items={orderItemList}
							language={getLanguage()}
							paginationProps={{
								custom: true,
								sizePerPage: 200,
							}}
							selectRow={{
								mode: 'radio',
								clickToSelect: true,
								hideSelectColumn: false,
								onSelect: (row, isSelect, rowIndex, e) => {
									setSelectedPart(row)

								},
							}}
							rowQuantityList={[{ text: '32', value: 32 }]}
							allowAllItemsPerPage
							defaultSort={{
								dataField: 'item',
								order: 'desc',
							}}
							styleForFieldList={[
								{
									field: 'technology',
									styles: [
										{
											values: [ConfigTranslations.getTechnologyName({ id: 1, partConfigOptions, language })],
											class: 'rounded-pill bg-olive text-white',
										},
										{
											values: [ConfigTranslations.getTechnologyName({ id: 2, partConfigOptions, language })],
											class: 'rounded-pill bg-turquoise text-white',
										},
										{
											values: [ConfigTranslations.getTechnologyName({ id: 4, partConfigOptions, language })],
											class: 'rounded-pill bg-pink text-white',
										},
									],
								},
								{
									field: 'offer',
									styles: [
										{
											type: 'link',
											class: '',
										},
									],
								},
								{
									field: 'description',
									styles: [{ type: 'list', class: 'd-block' }],
								},
							]}
						/>
					</div>
				}

			/>
			{isIncidenceModalOpen && (() => {

				const registerIncidenceProps = {
					accountId: account?.id,
					productions: productions,
					partId: selectedPart?.id.value,
					quantityList: quantityList,
					orderId: orderDetail?.id,
					closeModal: handleCloseRegisterModal,
					isOpen: isIncidenceModalOpen,
					isModalInOrderDetail: true
				}
				return (
					<RegisterIncidenceModal {...registerIncidenceProps} />
				)
			})()}
		</>
	)
}

SelectPartOfOrderModal.propTypes = {
	t: PropTypes.func,
	orderItemList: PropTypes.array,
	partConfigOptions: PropTypes.object,
	productions: PropTypes.array,
	orderDetail: PropTypes.object,
	account: PropTypes.object,

}
export default withTranslation()(SelectPartOfOrderModal)


