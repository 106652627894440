import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BlueprintOpener from './BlueprintOpener'
import StpDownloader from './StpDownloader'

export const BlueprintAndStp = ({ partId, partsFiles, propsBlueprintUrl, propsModel3dUrl, propsModifiedBluePrintUrl }) => {
	const [blueprintUrl, setBlueprintUrl] = useState(null)
	const [model3dtUrl, setModel3dUrl] = useState(null)

	useEffect(() => {
		if (propsBlueprintUrl == null && propsModel3dUrl == null) {
			if (partsFiles.parts != null && partsFiles.parts.length > 0) {
				const fileData = getFilesData(partId)
				if (fileData != null) {
					setBlueprintUrl(fileData.fileLinks?.modifiedBlueprint || fileData.fileLinks?.originalBlueprint)
					setModel3dUrl(fileData.fileLinks.stepModel)
				}
			}
		} else {
			setBlueprintUrl(propsBlueprintUrl)
			setModel3dUrl(propsModel3dUrl)
		}
	}, [partsFiles])

	const getFilesData = partId => {
		return partsFiles.parts.find(part => parseInt(part.id) === partId)
	}

	return (
		<div className="d-flex flex-row ms-3">
			{
				propsModifiedBluePrintUrl != null &&
				<BlueprintOpener url={propsModifiedBluePrintUrl} partId={partId} isModifiedBluePrint />
			}
			<BlueprintOpener url={blueprintUrl} partId={partId} />
			<StpDownloader url={model3dtUrl} partId={partId} />
		</div>
	)
}

const mapStateToProps = state => {
	return {
		errorCloud: state.CloudStorage.error,
		partsFiles: state.CloudStorage.partsFiles,
		isLoadingCloud: state.CloudStorage.isLoading,
	}
}

BlueprintAndStp.propTypes = {
	partId: PropTypes.number,
	partsFiles: PropTypes.array,
	propsBlueprintUrl: PropTypes.string,
	propsModel3dUrl: PropTypes.string,
	propsModifiedBluePrintUrl: PropTypes.string,
}

export default connect(mapStateToProps, {})(BlueprintAndStp)
