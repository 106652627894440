import { useTable } from 'common/Hooks/UseTable'
import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { downloadXLSX } from 'common/Utils/downloadUtils'
import { PartConfigTranslations, getClassnameByTechnology } from 'common/Utils/PartConfigTranslations'
import Loader from 'components/Common/Loader'
import SimpleTable from 'components/Common/SimpleTable'
import { PART_STATUS, STATUS_OPTIONS } from 'constants/partStatus'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { getPartConfigOptions, getPartsPredictsAndReal } from 'store/actions'


export const PredictionsDashboard = ({ t, i18n: { language }, isOpen, closeModal, partsWithPredict, getPartsPredictsAndReal, isLoading, partConfigOptions, partConfigOptionsLoading }) => {
	const mainTablePartsHeader = ['Id', 'part_name', 'configuration', 'status', 'comparison_with_ia']
	useEffect(() => {
		getPartsPredictsAndReal()
	}, [])


	const getStatusClass = (status) => {
		const styles = [
			{
				type: 'grayBg',
				values: [PART_STATUS.NEW.toString()],
				class: 'cursor-pointer rounded-pill bg-secondary text-white',
			},
			{
				type: 'yellowBg',
				values: [PART_STATUS.REQUESTED.toString()],
				class: 'cursor-pointer rounded-pill bg-yellow text-white',
			},
			{
				type: 'orangeBg',
				values: [PART_STATUS.QUOTED.toString()],
				class: 'cursor-pointer rounded-pill bg-orange text-white',
			},
			{
				type: 'greenBg',
				values: [PART_STATUS.UPLOADED.toString()],
				class: 'cursor-pointer rounded-pill bg-green text-white',
			},
			{
				type: 'redBg',
				values: [PART_STATUS.REJECTED.toString(), PART_STATUS.HIDDEN.toString()],
				class: 'cursor-pointer rounded-pill bg-red text-white',
			},
		]
		const matchedStyle = styles.find(style => style.values.includes(status.toString()))
		return matchedStyle ? matchedStyle.class : ''
	}


	const downloadCsv = () => {
		const rows = []
		const headers = {
			partId: 'ID de pieza',
			quantity: 'Cantidad'
		}
		const supplierNames = []
		partsWithPredict?.partsWithPredict?.forEach(part => {
			part.costsAndMargins.suppliers.forEach(supplier => {
				if (!supplierNames.includes(supplier.name)) {
					supplierNames.push(supplier.name)
					headers[`supplier_${supplier.id}`] = supplier.name
				}
			})
		})

		partsWithPredict?.partsWithPredict?.forEach(part => {
			const partRows = []
			part.costsAndMargins.suppliers[0].quotes.forEach(quote => {
				partRows.push({
					partId: part.id,
					quantity: quote.quantity,
				})
			})
			part.costsAndMargins.suppliers.forEach(supplier => {
				supplier.quotes.forEach(quote => {
					const row = partRows.find(row => row.quantity === quote.quantity)
					row[`supplier_${supplier.id}`] = quote.quotation
				})

			})
			rows.push(...partRows)
		})

		downloadXLSX({
			rows: [headers, ...rows],
			fileName: `parts_with_comparisonIA_${new Date().toISOString().slice(0, 10)}.xlsx`
		})
	}


	const getTableBody = () => {
		return partsWithPredict?.partsWithPredict?.map(part => {
			const supplierNames = Object.keys(part.costsAndMargins.suppliers).map(supplierId => {
				return part.costsAndMargins.suppliers[supplierId].name
			})
			const dynamicTableHeader = ['quantity', ...supplierNames]

			return (
				{
					partId: part.id,
					partName: part.modelFiles[0]?.name?.replace('.stp', ''),
					technology: (
						<div>
							<span className={getClassnameByTechnology({ part })}>
								{PartConfigTranslations.getTechnologyName({ part, partConfigOptions, language })}
							</span><br />
							{PartConfigTranslations.getMaterialName({ part, partConfigOptions, language })}<br />
							{PartConfigTranslations.getFinishName({ part, partConfigOptions, language })}
						</div>
					),
					status: (
						<span className={getStatusClass(part.status)}>
							{t(STATUS_OPTIONS[part.status], { ns: 'status' })}
						</span>
					),
					comparisonWithIA: (
						<div>
							<SimpleTable
								header={dynamicTableHeader}
								getTableBody={() => (
									part.costsAndMargins.suppliers[Object.keys(part.costsAndMargins.suppliers)[0]].quotes.map((quote, index) => (
										<tr key={index}>
											<td>{quote.quantity}</td>
											{Object.keys(part.costsAndMargins.suppliers).map(supplierId => (
												<td key={supplierId}>
													{part.costsAndMargins.suppliers[supplierId].quotes[index]?.quotation || '-'}
												</td>
											))}
										</tr>
									))
								)}
								striped={false}
								t={t}
							/>
						</div>
					)
				}
			)
		})
	}
	const {
		TableElement,
		page,
		pageSize,
	} = useTable({
		t,
		headers: {
			partId: 'Id',
			partName: t('part_name', { ns: 'naming' }),
			technology: t('configuration', { ns: 'naming' }),
			status: t('status', { ns: 'naming' }),
			comparisonWithIA: t('comparison_with_ia', { ns: 'naming' }),
		},
		rows: getTableBody(),
		stripedStyle: true,
	})

	return (
		<div className='my-4'>
			<div><button onClick={() => downloadCsv()} className='btn btn-secondary my-2 pe-3 width-by-content' disabled={!partsWithPredict}>{t('export', { ns: 'naming' })}</button></div>
			{isLoading || partConfigOptionsLoading ? <Loader /> : TableElement}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		partsWithPredict: state.Parts.partsWithPredict,
		isLoading: state.Parts.isLoading,
		partConfigOptions: state.Parts.partConfigOptions,
		partConfigOptionsLoading: state.Parts.partConfigOptionsLoading,
	}
}

PredictionsDashboard.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	partsWithPredict: PropTypes.object,
	getPartsPredictsAndReal: PropTypes.func,
	isLoading: PropTypes.bool,
	partConfigOptions: PropTypes.any,
	getPartConfigOptions: PropTypes.func,
	partConfigOptionsLoading: PropTypes.bool,
}

const mapDispatchToProps = {
	getPartsPredictsAndReal,
	getPartConfigOptions
}

export default withGetPartConfigOptions(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PredictionsDashboard)))
