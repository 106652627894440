import { IconTooltip } from 'components/Common/IconTooltip'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const ProductionStatusComponent = ({ status, partialShipment, disconformity, late, t, qualityIncidencesCount }) => {

	return (
		<div>
			<div className="d-flex flex-row align-items-center my-2">
				<span className={getStatusClassName(status)}>
					{t('production_status_' + status, { ns: 'status' })}
				</span>
				{
					qualityIncidencesCount > 0 && <a href='/incidences'><IconTooltip icon='mx-1 bx bx-error fs-4 text-red' message={`${t('production_quality_incidence_count_message', { ns: 'naming' })} ${qualityIncidencesCount} `} /></a>
				}
			</div>
			{disconformity && (
				<div className="my-2">
					<span className={getStatusClassName(9)}>
						{t('production_status_9', { ns: 'status' })}
					</span>
				</div>
			)}
			{partialShipment && (
				<div className="my-2">
					<span className={getStatusClassName(10)}>
						{t('production_status_10', { ns: 'status' })}
					</span>
				</div>
			)}
			{late && (
				<div className="my-2">
					<span className="rounded-pill text-white bg-red">
						{t('late', { ns: 'naming' })?.toUpperCase()}
					</span>
				</div>
			)}
		</div>
	)
}
const getStatusClassName = status => {
	const statusDefault = 'rounded-pill text-white '
	switch (status) {
		case 1:
			return statusDefault + 'bg-orange'
		case 2:
			return statusDefault + 'bg-cyan'
		case 3:
			return statusDefault + 'bg-purple'
		case 4:
			return statusDefault + 'bg-yellow'
		case 5:
			return statusDefault + 'bg-red'
		case 6:
			return statusDefault + 'bg-green'
		case 7:
			return statusDefault + 'bg-danger'
		case 9:
			return statusDefault + 'bg-red'
		case 10:
			return statusDefault + 'bg-pink'
		default:
			return statusDefault + 'bg-blue'
	}
}

ProductionStatusComponent.propTypes = {
	status: PropTypes.number,
	partialShipment: PropTypes.bool,
	disconformity: PropTypes.bool,
	qualityIncidencesCount: PropTypes.number,
	t: PropTypes.func,
}

export default withTranslation()(ProductionStatusComponent)
