import germany from '../assets/images/flags/germany.jpg'
import italy from '../assets/images/flags/italy.jpg'
import russia from '../assets/images/flags/russia.jpg'
import spain from '../assets/images/flags/spain.jpg'
import usFlag from '../assets/images/flags/us.jpg'

const languages = {
	es: {
		label: 'Spanish',
		flag: spain,
	},
	de: {
		label: 'German',
		flag: germany,
	},
	fr: {
		label: 'French',
		flag: russia,
	},
	it: {
		label: 'Italian',
		flag: italy,
	},
	en: {
		label: 'English',
		flag: usFlag,
	},
	cn: {
		label: 'Chinesse',
		flag: usFlag,
	},
}

export default languages
