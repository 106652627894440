import { getIdFromUrl } from 'common/Utils/StringUtilities'
import Breadcrumbs from 'components/Common/Breadcrumb'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import LeadScoreAndCrmLogs from './Components/LeadScoreAndCrmLogs.js/LeadScoreAndCrmLogs'
import OffersAndOrdersTables from './Components/OffersAndOrdersTables/OffersAndOrdersTables'
import Sidebar from './Components/Sidebar'
import UserData from './Components/UserData/UserData'

export const UserDetail = ({ t }) => {

	return (
		<div className='page-content marginFooter'>
			<div>
				<MetaTags>
					<title>Proto&Go! | {t('clientDetail', { ns: 'naming' })}</title>
				</MetaTags>
				<Breadcrumbs
					title="CRM"
					breadcrumbItems={[
						{
							item: t('clientList', { ns: 'naming' }),
							link: '/crm-user-list',
						},
						{
							item: t('clientDetail', { ns: 'naming' }),
							link: `/crm-user-detail/${getIdFromUrl()}`,
						},
					]}
				/>
			</div>
			<div className='row' >
				<div className='col-9 p-0'>
					<div className='row m-0'>
						<div className='col-3 p-0'>
							<Sidebar />
						</div>
						<div className=' col-9 p-0 bg-white'>
							<UserData />
						</div>
					</div>
				</div>
				<div className='col-3 p-0'>
					<div>
						<LeadScoreAndCrmLogs />
					</div>
				</div>
			</div>
			<div className='row'>
				<OffersAndOrdersTables />
			</div>
		</div>
	)
}

UserDetail.propTypes = {
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserDetail))
