import { getPaymentDueDate } from 'common/Utils/DateUtils'
import { formatCurrencyWithSeparators } from 'common/Utils/NumberUtilities'
import { getDate, getDeliveryNoteSerial } from 'common/Utils/StringUtilities'
import { PAYMENT_METHOD } from 'constants/paymentMethods'
import { POSTPONED_PAYMENT_METHOD } from 'constants/paymentPostponedMethods'
import logo from '../../assets/images/brands/protoAndGoLogoDark.png'

export function htmlToPdfInvoice(invoiceItems, orderInfo, invoiceInfo, organization, t) {
	const ITEMS_ON_FIRST_PAGE = 12
	const ITEMS_PER_PAGE = 21
	const FOOTER_SIZE_AS_ITEM = 3
	const { taxesApplied, subtotal, totalPrice } = orderInfo.order
	const { daysPostponed, firstPaymentDay, secondPaymentDay } = organization.paymentSetting.postponement
	const paymentDueDate = organization.paymentSetting.paymentPostponed ? getDate(getPaymentDueDate(invoiceInfo.creationDate, daysPostponed, firstPaymentDay, secondPaymentDay)) : getDate(new Date(invoiceInfo.creationDate))
	const { cCountry, dCtrl, dCtrl2, entity, nAccount, office } = organization.paymentSetting.directDebitAccount
	const iban = `${cCountry}${dCtrl}${entity}${office}${dCtrl2}${nAccount}`
	const pages = []
	const header = (<div>
		<div className="row" style={{ width: "100%", display: "flex", flexDirection: 'row' }}>
			<div
				className="col"
				style={{
					display: "flex",
					alignItems: "flex-start",
					justifyContent: "flex-start",
					width: "40%",
					height: "100%",
				}}
			>
				<img
					src={logo}
					alt="DeliveryNote"
					width="250px"
					style={{ alignSelf: "flex-start", paddingTop: "3%" }}
				/>
			</div>

			<div className="col" style={{ marginTop: "2%", marginLeft: "5%" }}>
				<div style={{ display: "flex", flexDirection: "row" }}>
					<div
						style={{
							height: "155px",
							border: "1px solid lightgray",
							borderLeft: "0px",
							marginRight: "15px",
							alignSelf: "center",
						}}
					/>
					<div style={{ flexDirection: "column" }}>
						<p style={{ color: "#282828", marginBottom: 1 }}>
							<strong>Ingeniería de Procesos y Producto, I-MAS S.L.</strong>
						</p>
						<p style={{ color: "dimgray", marginBottom: 1 }}>
							C/Castelló, 19<br />08110 - Montcada i Reixac (Barcelona)
						</p>
						<p style={{ color: "dimgray" }}>CIF: ES63729495</p>
						<p style={{ color: "dimgray" }}>
							ES: <strong style={{ color: "#282828" }}>(+34) 932 20 71 04</strong>&nbsp;&nbsp;
							EN: <strong style={{ color: "#282828" }}>(+44) 20 3318 1736</strong>
							<br />
							FR: <strong style={{ color: "#282828" }}>(+33) 176 44 09 62</strong>
							&nbsp;&nbsp; IT: <strong style={{ color: "#282828" }}>(+39) 011 1962 0263</strong>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div
			style={{
				width: "fit-content",
				height: "40px",
				backgroundColor: "#F08C01",
				display: "flex",
			}}
		>
			<div
				style={{
					color: 'white',
					fontSize: '24px',
					paddingLeft: '2em',
					paddingRight: '2em',
				}}
			>
				Invoice
			</div>
		</div>

		<div
			style={{
				width: 100 + "%",
				minHeight: 15 + "%",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				flexDirection: "row",
				marginTop: 2 + "%"
			}}>
			<div style={{ height: "100%", width: "50%" }}>
				<table style={{ borderCollapse: "collapse", width: "100%" }}>
					<tr>
						<th style={{
							border: "1px solid #F08C01",
							borderRight: "0px",
							padding: "8px",
							textAlign: "center",
							color: "dimgray"
						}}
						>
							Series
						</th>
						<th style={{
							border: "1px solid #F08C01",
							borderRight: "0px",
							padding: "8px",
							textAlign: "center",
							color: "dimgray"
						}}
						>
							Number
						</th>
						<th style={{
							border: '1px solid #F08C01',
							padding: '8px',
							textAlign: 'center',
							color: 'dimgray',
						}}>
							Date
						</th>
					</tr>
					<tr>
						<td
							style={{
								borderBottom: '1px solid #F08C01',
								borderLeft: '1px solid #F08C01',
								padding: '8px',
								color: 'dimgray',
								textAlign: 'center',
							}}
						>
							PG
						</td>
						<td style={{
							borderBottom: '1px solid #F08C01',
							borderLeft: '1px solid #F08C01',
							padding: '8px',
							color: 'dimgray',
							textAlign: 'center',
						}}>
							{getDeliveryNoteSerial(invoiceInfo.creationDate, invoiceInfo.serialNumber)}
						</td>
						<td style={{
							borderBottom: '1px solid #F08C01',
							borderLeft: '1px solid #F08C01',
							borderRight: '1px solid #F08C01',
							padding: '8px',
							color: 'dimgray',
							textAlign: 'center',
						}}>
							{getDate(new Date(invoiceInfo.creationDate))}
						</td>
					</tr>
				</table>

				<div style={{
					width: "20%",
					borderBottom: "1px solid #F08C01",
					marginTop: "5%",
					marginBottom: "5%"
				}} />
				<table style={{ borderCollapse: "collapse", width: "100%" }}>
					<tr>
						<th style={{
							border: "1px solid #F08C01",
							borderRight: "0px",
							padding: "8px",
							textAlign: "center",
							color: "dimgray"
						}}
						>
							Order Ref
						</th>
						<th style={{
							border: '1px solid #F08C01',
							padding: '8px',
							textAlign: 'center',
							color: 'dimgray',
						}}>
							Your Ref
						</th>
					</tr>
					<tr>
						<td style={{
							borderBottom: "1px solid #F08C01",
							borderLeft: "1px solid #F08C01",
							padding: "8px",
							color: "dimgray",
							textAlign: "center"
						}}>
							#{orderInfo?.order?.id}
						</td>
						<td style={{
							borderBottom: "1px solid #F08C01",
							borderLeft: "1px solid #F08C01",
							borderRight: "1px solid #F08C01",
							padding: "8px",
							color: "dimgray",
							textAlign: "center"
						}}>
							{orderInfo?.order?.customerOrderNumber}
						</td>
					</tr>
				</table>
			</div>
			<div style={{
				height: "100%",
				width: "50%",
				marginLeft: "50px",
				textAlign: "right"
			}}
			>
				<div style={{ width: "100%", alignItems: "center", border: "1px solid #F08C01" }}>
					<p style={{
						paddingLeft: "5%",
						paddingRight: "5%",
						paddingTop: "5%",
						marginBottom: "0",
						lineHeight: "2",
						color: "#282828",
						fontSize: "17px"
					}}>
						<strong>{orderInfo?.order?.billingInfo?.companyName}</strong>
					</p>
					<p style={{
						paddingLeft: "5%",
						paddingRight: "5%",
						paddingTop: "0%",
						marginBottom: "0",
						lineHeight: "2",
						color: "#282828",
						fontSize: "13px"
					}}>
						<strong>{`${orderInfo?.order?.billingInfo?.firstName} ${orderInfo?.order?.billingInfo?.lastName}`}</strong>
					</p>
					<p style={{
						paddingLeft: "5%",
						paddingRight: "5%",
						marginBottom: "0",
						lineHeight: "2",
						fontSize: "13px"
					}}>
						{orderInfo?.order?.billingInfo?.address1}, {orderInfo?.order?.billingInfo?.postalCode}, {orderInfo?.order?.billingInfo?.city}
					</p>
					<p style={{
						paddingLeft: "5%",
						paddingRight: "5%",
						marginBottom: "0",
						lineHeight: "2",
						fontSize: "13px"
					}}>
						{orderInfo?.order?.billingInfo?.province} - {orderInfo?.order?.billingInfo?.country}
					</p>
					<p style={{
						paddingLeft: "5%",
						paddingRight: "5%",
						paddingBottom: "5%",
						marginBottom: "0",
						lineHeight: "2",
						fontSize: "13px"
					}}>
						CIF: {orderInfo?.order?.billingInfo?.vat}
					</p>
				</div>
			</div>
		</div>
	</div>)

	const generatePartsTable = (items, shipping, fee, discount) => {
		return (
			<table style={{
				borderCollapse: 'separate',
				borderSpacing: '0 10px',
				width: '100%',
				marginTop: '2%',
			}}>
				<tr>
					<th style={{
						border: '1px solid #F08C01',
						borderRight: '0px',
						padding: '8px',
						textAlign: 'left',
						color: 'dimgray',
					}}>
						Concept
					</th><th style={{
						border: "1px solid #F08C01",
						borderRight: "0px",
						padding: "8px",
						textAlign: "center",
						color: "dimgray"
					}}>
						Unit
					</th>
					<th style={{
						border: '1px solid #F08C01',
						borderRight: '0px',
						padding: '8px',
						textAlign: 'center',
						color: 'dimgray',
					}}>
						Unit Price
					</th>
					<th style={{
						border: '1px solid #F08C01',
						padding: '8px',
						textAlign: 'center',
						color: 'dimgray',
					}}>
						Amount(EUR)
					</th>
				</tr>
				{items.map((item, i) => {
					return <tr key={i}>
						<td style={{
							border: "1px solid lightgray",
							borderRight: "0px",
							padding: "8px",
							color: "dimgray",
							textAlign: "left",
							width: "55%"
						}}>
							{item.name}
						</td>
						<td style={{
							border: "1px solid lightgray",
							borderRight: "0px",
							padding: "8px",
							color: "dimgray",
							textAlign: "right",
							width: "15%"
						}}>
							{item.units}
						</td>
						<td style={{
							border: "1px solid lightgray",
							borderRight: "0px",
							padding: "8px",
							color: "dimgray",
							textAlign: "right",
							width: "15%",
						}}>
							{item.unitPrice}
						</td>
						<td style={{
							border: "1px solid lightgray",
							padding: "8px",
							color: "dimgray",
							textAlign: "right",
							width: "15%"
						}}>
							{item.totalPrice}
						</td>
					</tr>
				})}
			</table >
		)
	}

	let isFooterPrintedOnLastPage = false
	const footer = (
		<div className='d-flex flex-row' style={{ marginTop: "15%", marginBottom: "5%" }}>
			<table className='me-1' style={{ borderCollapse: "collapse", width: "100%" }}>
				<tr>
					<th style={{
						border: "1px solid #F08C01",
						borderBottom: "0px",
						padding: "8px",
						textAlign: "center",
						color: "dimgray",
						width: "33%",
					}}>
						Payment due
					</th>
					<td style={{
						border: "1px solid #F08C01",
						borderLeft: "0px",
						borderBottom: "0px",
						padding: "8px",
						color: "dimgray",
						textAlign: "center",
						width: "33%",
					}}>
						{paymentDueDate}
					</td>
					<td style={{
						border: "1px solid #F08C01",
						borderLeft: "0px",
						borderBottom: "0px",
						padding: "8px",
						color: "dimgray",
						textAlign: "center",
						width: "33%",
					}}>
						{formatCurrencyWithSeparators(totalPrice)}
					</td>
				</tr>
				<tr>
					<th style={{
						border: '1px solid #F08C01',
						borderBottom: "0px",
						padding: '8px',
						textAlign: 'center',
						color: 'dimgray',
					}}>
						Payment terms
					</th>
					<td style={{
						border: "1px solid #F08C01",
						borderLeft: "0px",
						borderBottom: "0px",
						padding: "8px",
						color: "dimgray",
						textAlign: "center",
					}}
						colSpan="2"
					>
						{orderInfo?.order?.paymentMethodId === PAYMENT_METHOD.POSTPONED ? `${t(`postponed_payment_type_${organization.paymentSetting.paymentMethod}`, { ns: "postponedPaymentType" })} - ${organization.paymentSetting.postponement.daysPostponed} ${t("days", { ns: "naming" })}` : t(`payment_method_${orderInfo?.order?.paymentMethodId}_name`, { ns: "paymentMethods" })}
					</td>
				</tr>
				<tr>
					<th style={{
						border: '1px solid #F08C01',
						padding: '8px',
						textAlign: 'center',
						color: 'dimgray',
					}}>
						Bank account
					</th>
					<td style={{
						border: "1px solid #F08C01",
						borderLeft: "0px",
						padding: "7px",
						color: "dimgray",
						textAlign: "center",
					}}
						colSpan="2"
					>
						{orderInfo?.order?.paymentMethodId === PAYMENT_METHOD.POSTPONED && organization.paymentSetting.paymentMethod === POSTPONED_PAYMENT_METHOD.BANK_DIRECT ? `IBAN: ${iban}` : ""}
					</td>
				</tr>
			</table>
			<table className='ms-1' style={{ borderCollapse: "collapse", width: "100%" }}>
				<tr>
					<th style={{
						border: "1px solid #F08C01",
						borderRight: "0px",
						padding: "8px",
						textAlign: "center",
						color: "dimgray",
						width: "33%",
					}}>
						Value
					</th>
					<th style={{
						border: '1px solid #F08C01',
						borderRight: "0px",
						padding: '8px',
						textAlign: 'center',
						color: 'dimgray',
						width: "33%",
					}}>
						%VAT
					</th>
					<th style={{
						border: '1px solid #F08C01',
						padding: '8px',
						textAlign: 'center',
						color: 'dimgray',
						width: "33%",
					}}>
						VAT
					</th>
				</tr>
				<tr>
					<td style={{
						borderBottom: "1px solid #F08C01",
						borderLeft: "1px solid #F08C01",
						padding: "8px",
						color: "dimgray",
						textAlign: "center"
					}}>
						{formatCurrencyWithSeparators(subtotal)}
					</td>
					<td style={{
						borderBottom: "1px solid #F08C01",
						borderLeft: "1px solid #F08C01",
						padding: "8px",
						color: "dimgray",
						textAlign: "center"
					}}>
						{taxesApplied != 0 ? "21,00" : "0,00"}
					</td>
					<td style={{
						borderBottom: "1px solid #F08C01",
						borderLeft: "1px solid #F08C01",
						borderRight: "1px solid #F08C01",
						padding: "8px",
						color: "dimgray",
						textAlign: "center"
					}}>
						{formatCurrencyWithSeparators(taxesApplied)}
					</td>
				</tr>
				<tr>
					<td style={{
						borderBottom: "1px solid #F08C01",
						borderLeft: "1px solid #F08C01",
						padding: "8px",
						color: "dimgray",
						textAlign: "center",
					}}
						colSpan="2"
					>
						Total Invoice(EUR):
					</td>
					<td style={{
						borderBottom: "1px solid #F08C01",
						borderLeft: "1px solid #F08C01",
						borderRight: "1px solid #F08C01",
						padding: "8px",
						color: "dimgray",
						textAlign: "center"
					}}>
						{formatCurrencyWithSeparators(totalPrice)}
					</td>
				</tr>
			</table>
		</div>
	)

	const firstPage = <div>
		{header}
		{generatePartsTable(invoiceItems.slice(0, ITEMS_ON_FIRST_PAGE))}
		{invoiceItems.length <= ITEMS_ON_FIRST_PAGE - FOOTER_SIZE_AS_ITEM ? footer : null}
	</div>
	if (invoiceItems.length <= ITEMS_ON_FIRST_PAGE - FOOTER_SIZE_AS_ITEM) {
		isFooterPrintedOnLastPage = true
	}
	pages.push(firstPage)

	for (let i = ITEMS_ON_FIRST_PAGE; i < invoiceItems.length; i++) {
		if ((i - ITEMS_ON_FIRST_PAGE) % ITEMS_PER_PAGE === 0) {
			const slicedRemitItems = invoiceItems.slice(i, i + ITEMS_PER_PAGE)
			const table = generatePartsTable(slicedRemitItems)
			const page = <div>
				{table}
				{slicedRemitItems.length <= ITEMS_PER_PAGE - FOOTER_SIZE_AS_ITEM ? footer : null}
			</div>
			if (slicedRemitItems.length <= ITEMS_PER_PAGE - FOOTER_SIZE_AS_ITEM) {
				isFooterPrintedOnLastPage = true
			}
			pages.push(page)
		}
	}

	if (!isFooterPrintedOnLastPage) pages.push(footer)

	const pagesRendered = pages.map((page, i) => {
		return (
			<div key={i} className="page" style={{
				width: 'calc(210mm - 4em)',
				height: 'calc(297mm - 2em)',
				maxWidth: '210mm',
				maxHeight: '297mm',
				marginLeft: '2em',
				marginRight: '2em',
			}}>
				{page}
			</div>
		)
	})
	return pagesRendered
}
