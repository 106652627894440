import Alert from 'components/Common/Alert'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import TooltipMessage from 'components/Common/TooltipMessage'
import Table from 'components/Table/Table'
import { SUPPLIER_DETAIL_ROUTE_NO_ID } from 'constants/appRoutes'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { Flag } from 'semantic-ui-react'
import { getSuppliersInfo } from 'store/actions'
import ActiveSupplier from './ActiveSupplier'

const SupplierList = ({ suppliers, t, getSuppliersInfo, history, isLoading, error }) => {

	const [supplierToRender, setSupplierToRender] = useState([])

	useEffect(() => {
		getSuppliersInfo()
	}, [])

	useEffect(() => {
		if (suppliers.length !== 0) {
			setSupplierToRender(parseSuppliers())
		}
	}, [suppliers])

	const renderStarRating = (rating) => {
		const stars = []
		const fullStars = Math.floor(rating)
		const hasHalfStar = rating % 1 >= 0.5

		for (let i = 1; i <= 5; i++) {
			if (i <= fullStars) {
				stars.push(<i key={i} className='bx bxs-star' style={{ color: 'orange' }}></i>)
			} else if (i === fullStars + 1 && hasHalfStar) {
				stars.push(<i key={i} className='bx bxs-star-half' style={{ color: 'orange' }}></i>)
			} else {
				stars.push(<i key={i} className='bx bx-star'></i>)
			}
		}

		return stars
	}

	const parseSuppliers = () => {
		suppliers.sort((a, b) => b.account?.id - a.account?.id)
		return suppliers.map(supplier => {
			const { account, supplierInfo } = supplier
			const deliveryRating = supplierInfo?.deliveryPeriodRating || 0
			const qualityRating = supplierInfo?.qualityRating || 0
			const responseRating = supplierInfo?.responseTimeRating || 0
			const averageRating = parseFloat(Math.min(
				(deliveryRating + qualityRating + responseRating) / 3,
				5
			).toFixed(2))

			const contentMessage = `${averageRating}`

			return {
				id: <a href={`${SUPPLIER_DETAIL_ROUTE_NO_ID}${account.id}`}>{account.id}</a>,
				firstName: account.personalInformation?.firstName,
				language: <Flag name={account.personalInformation?.language?.toLowerCase()} />,
				supplierCountry: <Flag name={account.personalInformation?.country?.toLowerCase()} />,
				contactPerson: supplierInfo?.contactPerson,
				email: account.loginCredentials?.email,
				actualDate: !supplierInfo?.timeZone?.includes('GMT') && obtenerFechaHoraEnTimeZone(supplierInfo?.timezone),
				isActive: <ActiveSupplier isActive={account.isActive} id={account.id} isLoading={false} />,
				rating: (
					<div>
						<TooltipMessage message={contentMessage} place={"top"}>
							<span style={{ marginLeft: '5px' }}>{renderStarRating(averageRating)}</span>
						</TooltipMessage>
					</div>
				),
			}
		})
	}

	const obtenerFechaHoraEnTimeZone = (timeZone) => {
		const fechaActual = new Date()
		const opciones = { timeZone: timeZone, hour12: false }

		const fechaHoraLocale = fechaActual.toLocaleString('es-ES', opciones)

		return fechaHoraLocale
	}

	const headerToRender = {
		id: t('id', { ns: 'naming' }),
		firstName: t('commercial_brand', { ns: 'naming' }),
		language: t('language', { ns: 'naming' }),
		supplierCountry: t('country', { ns: 'naming' }),
		contactPerson: t('contact_person', { ns: 'naming' }),
		email: t('email', { ns: 'naming' }),
		actualDate: t('actual_date', { ns: 'naming' }),
		isActive: t('visible', { ns: 'naming' }),
		rating: t('rating', { ns: 'naming' }),
	}

	return (
		<div className="page-content">
			<MetaTags>
				<title>Proto&Go! | {t('suppliers', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('configuration', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('supplierList', { ns: 'naming' }),
						link: '/supplier-list',
					},
				]}
			/>

			<div className="bg-white p-4">
				<button
					type="button"
					className="btn btn-secondary my-2"
					onClick={e => {
						history.push('supplier-register')
					}}
				>
					{t('add_supplier', { ns: 'naming' })}
				</button>
				<div >
					{error != null ? <Alert type="danger" fitContent message={error} /> :
						<div>
							{isLoading ? <div className="d-flex justify-content-center fs-1"><Loader /></div> :
								<div>
									{supplierToRender.length > 0 ?
										<Table
											header={headerToRender}
											selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
											items={supplierToRender}
											language="es"
											showActiveColumFilter={true}
											rowQuantityList={[
												{ text: '8', value: 8 },

												{ text: '16', value: 16 },

												{ text: '32', value: 32 },
											]}
											allowAllItemsPerPage
											defaultSort={{
												dataField: 'id',
												order: 'asc',
											}}
											paginationProps={{
												custom: true,
												sizePerPage: 20,
											}}
											styleForFieldList={[
												{
													field: 'actualDate',
													styles: [{ type: 'list', class: 'd-block' }],
												},
											]}
										/>
										: <div className="text-center">{t('no_suppliers', { ns: 'naming' })}</div>}
								</div>}
						</div>}
				</div>
			</div>
		</div>
	)

}

const mapStateToProps = state => {
	return {
		suppliers: state.Users.supplierDetailList,
		isLoading: state.Users.isLoading,
		error: state.Users.error,
	}
}

SupplierList.propTypes = {
	getSuppliersInfo: PropTypes.func,
	suppliers: PropTypes.array,
	history: PropTypes.func,
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
}
export default connect(mapStateToProps, { getSuppliersInfo })(
	withTranslation()(SupplierList),
)
