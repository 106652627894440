import { ConfigTranslations } from 'common/Utils/PartConfigTranslations'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const OfferTechnologies = ({ technologies, t, i18n: { language }, className, partConfigOptions }) => {
	return (
		<div>
			{technologies.map((technology) => {
				if (technology !== 0) {
					return (
						<div className={className || 'my-2'} key={`technology_type_${technology}`}>
							<span className={getTechnologyClassName(technology)}>
								{ConfigTranslations.getTechnologyName({ id: technology, partConfigOptions, language })}
							</span>
						</div>
					)
				}
			})
			}
		</div>
	)
}
const getTechnologyClassName = technology => {
	const technologyDefault = 'rounded-pill text-white end-ellipsis '
	switch (technology) {
		case 1:
			return technologyDefault + 'bg-olive'
		case 2:
			return technologyDefault + 'bg-turquoise'
		case 3:
			return technologyDefault + 'bg-purple'
		case 4:
			return technologyDefault + 'bg-pink'
		case 5:
			return technologyDefault + 'bg-orange'
	}
}

OfferTechnologies.propTypes = {
	technologies: PropTypes.array,
	t: PropTypes.func,
	className: PropTypes.string,
	partConfigOptions: PropTypes.object,
}

export default withTranslation()(OfferTechnologies)
