import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { createPartVerification } from 'store/actions'

export const RegisterVerificationModal = ({ t, isOpen, closeModal, partId, orderId, quantityList, scannedQuantity, shippedQuantity, incidences, createPartVerification, qualityPartState, qualityQuantities, isLoading }) => {
	const [quantity, setQuantity] = useState()
	const [isCreatingVerification, setIsCreatingVerification] = useState(false)

	const MISSING_QUANTITIES_PROBLEM = 5
	const missingQuantitiesIncidence = useMemo(() => {
		return incidences?.filter((incidence) => incidence.problem == MISSING_QUANTITIES_PROBLEM).length != 0
	}, [incidences])

	const close = () => {
		setQuantity(null)
		closeModal()
	}

	const saveVerification = () => {
		setIsCreatingVerification(true)
		const verificationInput = {
			qualityPartState: {
				partId,
				orderId,
				quantity: Number(quantity),
				state: true,
			},
		}
		createPartVerification({ data: verificationInput })
	}

	useEffect(() => {
		if (quantityList.length > 0 && qualityQuantities.length > 0) {
			const maxQuantity = Math.max(...quantityList)
			const defaultQuantity = qualityQuantities.find((e) => e.min <= maxQuantity && e.max >= maxQuantity)?.quantity || 0
			setQuantity(defaultQuantity)
		}
	}, [qualityQuantities, quantityList])

	useEffect(() => {
		if (qualityPartState != null && isCreatingVerification) {
			close()
		}
	}, [qualityPartState])

	return (
		<>
			<Modal
				isOpen={isOpen}
				closeModal={close}
				title={`${t('register-verification', { ns: 'naming' })} ${t('of-the-part', { ns: 'naming' })} ${partId} ${t('of-the-order', { ns: 'naming' })} ${orderId}`}
				body={
					<div className="px-4 py-2">
						{scannedQuantity < Math.max(...quantityList) && (
							<div className="bg-danger rounded p-1 px-2 ms-1">
								<div className=" d-flex flex-row align-items-center">
									<i className="bx bxs-error mx-2 text-primary fs-4"></i>
									<span className="fs-5">{t('missing-reception-parts', { ns: 'naming' })}</span>
								</div>
								{scannedQuantity != shippedQuantity && !missingQuantitiesIncidence && (
									<span className="ms-2 d-flex fs-5">{t('missing-shipped-parts', { ns: 'naming' })}</span>
								)}
							</div>
						)}
						<OptionsInput
							value={quantity}
							setValue={setQuantity}
							title={t('verified-quantity', { ns: 'naming' })}
							options={quantityList}
							automaticMappedValue
						/>
					</div>
				}
				buttons={[
					<button
						type="button"
						key="saveButton"
						className="btn btn-primary"
						disabled={scannedQuantity < Math.max(...quantityList) && scannedQuantity < shippedQuantity && !missingQuantitiesIncidence}
						onClick={e => {
							saveVerification()
						}}
					>
						{isLoading ? <Loader /> : t('save', { ns: 'naming' })}
					</button>,
				]}
			/>
		</>
	)
}

const mapStateToProps = state => {
	return {
		qualityPartState: state.Orders.qualityPartState,
		qualityQuantities: state.Orders.qualityQuantities.qualityQuantities,
		isLoading: state.Orders.isLoadingQuality,
	}
}

RegisterVerificationModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	partId: PropTypes.number,
	orderId: PropTypes.number,
	quantityList: PropTypes.array,
	scannedQuantity: PropTypes.number,
	shippedQuantity: PropTypes.number,
	incidences: PropTypes.array,
	createPartVerification: PropTypes.func,
	qualityPartState: PropTypes.object,
	qualityQuantities: PropTypes.array,
	isLoading: PropTypes.bool,
}

export default connect(mapStateToProps, { createPartVerification })(withTranslation()(RegisterVerificationModal))
