import { formatCurrencyWithSeparators } from 'common/Utils/NumberUtilities'
import { getDeliveryNoteSerial } from 'common/Utils/StringUtilities'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import { getPdfBlob } from 'components/Common/PrintableContent/GetPdfBlob'
import { htmlToPdfInvoice } from 'components/PdfTemplates/InvoicePDFTemplate'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { createInvoice, getPendingInvoiceOrders, uploadOrderDeliveryNote } from 'store/actions'

const OrderInvoiceGenerator = ({ t, getPendingInvoiceOrders, orders, createInvoice, invoice, uploadOrderDeliveryNote }) => {

	const [ordersToCreateInvoice, setOrdersToCreateInvoice] = useState(null)
	const [isFinished, setIsFinished] = useState(false)

	useEffect(() => {
		getPendingInvoiceOrders()
	}, [])

	useEffect(() => {
		if (orders != null && orders?.length !== 0) {
			setOrdersToCreateInvoice(orders)
		} else if (orders?.length == 0) {
			setIsFinished(true)
		}
	}, [orders])

	useEffect(() => {
		if (ordersToCreateInvoice != null && ordersToCreateInvoice.length != 0) {
			createInvoice({ orderId: ordersToCreateInvoice[0].id })
		} else if (ordersToCreateInvoice?.length == 0) {
			setIsFinished(true)
		}
	}, [ordersToCreateInvoice])

	useEffect(() => {
		if (isFinished) {
			setTimeout(() => {
				window.close()
			}, 1000)
		}
	}, [isFinished])

	const width = 210
	const height = 297
	const orientation = 'p'
	const id = 'invoicePrint'
	const filename = useMemo(() => {
		if (invoice == null) return undefined
		return `Fra_PG_${getDeliveryNoteSerial(invoice.creationDate, invoice.serialNumber)}_${ordersToCreateInvoice[0]?.billingInfo?.companyName}`
	}, [invoice])

	const options = {
		height,
		width,
		printPageCounter: true,
		printDocumentFooter: true,
		jsPDF: {
			format: [width, height],
			unit: 'mm',
			orientation,
			hotfixes: ['px_scaling'],
		},
		margin: 0,
		html2canvas: { scale: 8 },
		filename: filename,
	}

	useEffect(async () => {
		if (invoice) {
			const pdf = await getPdfBlob(id, options)
			uploadOrderDeliveryNote({ data: { orderId: ordersToCreateInvoice[0].id }, deliveryNote: pdf })
			setOrdersToCreateInvoice([...ordersToCreateInvoice.slice(1)])
		}
	}, [invoice])

	const generateHtmlToPrint = (orderInfo) => {
		if (invoice == null) return null
		const { orderItems } = orderInfo
		const invoiceItems = orderItems.map((orderItem) => {
			const unitPrice = orderItem.total / orderItem.quantity
			return {
				name: orderItem.part.name,
				units: orderItem.quantity,
				unitPrice: formatCurrencyWithSeparators(unitPrice),
				totalPrice: formatCurrencyWithSeparators(orderItem.total)
			}
		})
		if (orderInfo.feeApplied && orderInfo.feeApplied != 0) {
			invoiceItems.push({
				name: "Minimum Fee",
				units: 1,
				unitPrice: formatCurrencyWithSeparators(orderInfo.feeApplied),
				totalPrice: formatCurrencyWithSeparators(orderInfo.feeApplied)
			})
		}
		if (orderInfo.shipmentPricing && orderInfo.shipmentPricing != 0) {
			invoiceItems.push({
				name: "Shipment Price",
				units: 1,
				unitPrice: formatCurrencyWithSeparators(orderInfo.shipmentPricing),
				totalPrice: formatCurrencyWithSeparators(orderInfo.shipmentPricing)
			})
		}
		if (orderInfo.discountApplied && orderInfo.discountApplied != 0) {
			invoiceItems.push({
				name: "Discount",
				units: 1,
				unitPrice: `-${formatCurrencyWithSeparators(orderInfo.discountApplied)}`,
				totalPrice: `-${formatCurrencyWithSeparators(orderInfo.discountApplied)}`
			})
		}
		return htmlToPdfInvoice(invoiceItems, { organization: { id: orderInfo.organizationId }, order: { ...orderInfo } }, invoice, orderInfo.organization, t)
	}

	const invoiceHtmlToPrint = useMemo(() => {
		if (ordersToCreateInvoice == null || ordersToCreateInvoice.length == 0) return null
		return generateHtmlToPrint(ordersToCreateInvoice[0])
	}, [invoice])


	return (
		<div className="page-content">
			<MetaTags>
				<title>Proto&Go! | {t('order-pdf-generation', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('management', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('order-pdf-generation', { ns: 'naming' }),
						link: '/generate-order-pdfs',
					},
				]}
			/>
			{isFinished ? <div>{t('no-pdfs-to-generate', { ns: 'naming' })}</div> : <Loader className='mx-auto font-size-80' />}
			<div>
				<div id={id} className='d-none'>
					{invoiceHtmlToPrint}
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		orders: state.Orders.pendingOrders,
		invoice: state.Orders.invoice,
	}
}

OrderInvoiceGenerator.propTypes = {
	t: PropTypes.func,
	getPendingInvoiceOrders: PropTypes.func,
	orders: PropTypes.array,
	createInvoice: PropTypes.func,
	invoice: PropTypes.object,
	uploadOrderDeliveryNote: PropTypes.func,
}

export default withRouter(connect(mapStateToProps, { createInvoice, getPendingInvoiceOrders, uploadOrderDeliveryNote })(
	withTranslation()(OrderInvoiceGenerator),
))
