import PropTypes from 'prop-types'
import { Component } from 'react'
import { Input } from 'reactstrap'

class QuoteQuantityPricing extends Component {
	constructor(props) {
		super(props)
		this.state = {
			quantities: props.quantities,
			areAllFieldsPopulated: false,
		}
	}

	sortQuantities(quantities) {
		return quantities.sort((a, b) => {
			if (a.quantity > b.quantity) return 1
			if (a.quantity < b.quantity) return -1
			return 0
		})
	}

	componentDidMount() {
		this.setState({
			...this.state,
			areAllFieldsPopulated: this.areAllFieldsPopulated(),
			quantities: this.sortQuantities(this.props.quantities),
		})
	}

	areAllFieldsPopulated() {
		let areAllFieldsPopulated = true
		this.state.quantities &&
			this.state.quantities.forEach(quantity => {
				areAllFieldsPopulated =
					areAllFieldsPopulated &&
					!!quantity.unitPrice &&
					quantity.unitPrice > 0 &&
					!!quantity.manufacturingTime &&
					quantity.manufacturingTime > 0
			})
		return this.state.quantities && areAllFieldsPopulated
	}
	handleChange(event, i, field) {
		if (isNaN(event.target.value)) return

		const { quantities } = this.state

		quantities[i][field] = event.target.value
		this.props.checkFields(
			this.props.id,
			this.areAllFieldsPopulated(),
			quantities,
		)

		this.setState({
			...this.state,
			quantities,
			areAllFieldsPopulated: this.areAllFieldsPopulated(),
		})
	}

	render() {
		return (
			<table>
				<tr>
					<th className="quantityPricingCell">{this.props.quantityLabel}</th>
					<th className="quantityPricingCell">{this.props.unitPriceLabel}</th>
					<th className="quantityPricingCell">
						{this.props.manufacturingTimeTitleLabel}
					</th>
				</tr>
				{this.state.quantities &&
					this.state.quantities.map((info, i) => {
						return (
							<tr key={this.props.id + i}>
								<td>
									<Input
										type="text"
										className="form-control quantityPricingCell"
										id={this.props.id + i + 'quantity'}
										placeholder={this.props.quantityLabel}
										value={info.quantity}
										disabled
										onChange={event => this.handleChange(event, i, 'quantity')}
									/>
								</td>
								<td>
									<Input
										type="text"
										className="form-control quantityPricingCell"
										id={this.props.id + i + 'unitPrice'}
										placeholder={this.props.unitPriceLabel}
										value={info.unitPrice}
										disabled={this.props.disabled}
										onChange={event => this.handleChange(event, i, 'unitPrice')}
									/>
								</td>
								<td>
									<Input
										type="text"
										className="form-control quantityPricingCell"
										id={this.props.id + i + 'manufacturingTime'}
										placeholder={this.props.manufacturingTimeLabel}
										value={info.manufacturingTime}
										disabled={this.props.disabled}
										onChange={event =>
											this.handleChange(event, i, 'manufacturingTime')
										}
									/>
								</td>
							</tr>
						)
					})}
			</table>
		)
	}
}

QuoteQuantityPricing.propTypes = {
	quantities: PropTypes.arrayOf(
		PropTypes.shape({
			quantity: PropTypes.number,
			unitPrice: PropTypes.number,
			manufacturingTime: PropTypes.number,
		}),
	),
	quantityLabel: PropTypes.string,
	unitPriceLabel: PropTypes.string,
	manufacturingTimeTitleLabel: PropTypes.string,
	manufacturingTimeLabel: PropTypes.string,
	id: PropTypes.string,
	checkFields: PropTypes.func,
	disabled: PropTypes.bool,
}
export default QuoteQuantityPricing
