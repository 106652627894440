import { call, put, takeEvery } from 'redux-saga/effects'
import { post } from '../../helpers/api_helper'

// Crypto Redux States
import {
	SEND_UPLOAD_OFFER_EMAIL,
} from './actionTypes'

import {
	sendUploadOfferEmailFail,
	sendUploadOfferEmailSuccess,
} from './actions'

import {
	SEND_UPLOAD_OFFER_EMAIL_ENDPOINT,
} from '../../constants/backendRoutes'

function* sendUploadOfferEmail({ payload: { customer, language, message, offerId, manager } }) {
	try {
		const url = SEND_UPLOAD_OFFER_EMAIL_ENDPOINT
		const data = {
			data: {
				customer,
				language,
				message,
				offerId,
				manager
			},
		}
		const response = yield call(post, url, data)
		yield put(sendUploadOfferEmailSuccess(response))
	} catch (error) {
		yield put(sendUploadOfferEmailFail(error))
	}
}

function* emailsSaga() {
	yield takeEvery(SEND_UPLOAD_OFFER_EMAIL, sendUploadOfferEmail)
}

export default emailsSaga
