export const ProductionStatus = {
	ACCEPTANCE_PENDING: 1,
	IN_PROCESS: 2,
	PICKED_UP_BY_COURIER: 3,
	DELIVERING: 4,
	CANCELLED: 5,
	COMPLETED: 6,
	DENIED: 7,
	QUALITY_CONTROL: 8,
}
