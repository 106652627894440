import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BlueprintOpener from './BlueprintOpener'
import ObjDownloader from './ObjDownloader'
import StlDownloader from './StlDownloader'
import StpDownloader from './StpDownloader'

export const DownloadFilesButtons = ({ partsFiles, isSupplierView, part: { id, fileLinks: { originalBlueprint, modifiedBlueprint, stepModel, stlModel, objModel } = {} } }) => {
	const [blueprintUrl, setBlueprintUrl] = useState(null)
	const [model3dtUrl, setModel3dUrl] = useState(null)

	const dontShowOriginalBlueprint = isSupplierView && modifiedBlueprint != null

	useEffect(() => {
		if (originalBlueprint == null && stepModel == null) {
			if (partsFiles.parts != null && partsFiles.parts.length > 0) {
				const fileData = getFilesData(id)
				if (fileData != null) {
					setBlueprintUrl(fileData.fileLinks?.modifiedBlueprint || fileData.fileLinks?.originalBlueprint)
					setModel3dUrl(fileData.fileLinks.stepModel)
				}
			}
		} else {
			setBlueprintUrl(originalBlueprint)
			setModel3dUrl(stepModel)
		}
	}, [partsFiles])

	const getFilesData = partId => {
		return partsFiles.parts.find(part => parseInt(part.id) === partId)
	}

	return (
		<div id={id} className="d-flex flex-row ms-3">
			{
				modifiedBlueprint != null &&
				<BlueprintOpener url={modifiedBlueprint} partId={id} isModifiedBluePrint={!isSupplierView} />
			}
			{!dontShowOriginalBlueprint && <BlueprintOpener url={blueprintUrl} partId={id} />}
			<StpDownloader url={model3dtUrl} partId={id} />
			{(model3dtUrl == null && stlModel != null) && <StlDownloader url={stlModel} partId={id} />}
			{objModel != null && <ObjDownloader url={objModel} partId={id} />}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		errorCloud: state.CloudStorage.error,
		partsFiles: state.CloudStorage.partsFiles,
		isLoadingCloud: state.CloudStorage.isLoading,
	}
}

DownloadFilesButtons.propTypes = {
	part: PropTypes.object,
}

export default connect(mapStateToProps, {})(DownloadFilesButtons)
