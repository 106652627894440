/**
 * FINISH  
 * 1 = Sin acabado  
 * 22 = Impresión - Sin acabado  
 * 23 = CNC - Sin acabado  
 *   
 * Q: ¿Porqué no usar siempre 1 y eliminar el 22 y 23?  
 * A: Porque 22 y 23 tienen descripciones diferentes en los modales de ayuda del formulario de /new-offer.  
 * *Para entender esto al 100% puedes mirar la tabla "Finish" de la base de datos.  
*/
const NO_FINISH_ARRAY = [1, 22, 23]

/**
 * ALLOY  
 * 1 = Sin especificar  
 */
const NO_ALLOY_ARRAY = [1]

/**
 * COLOR  
 * 1 = Sin especificar  
 */
const NO_COLOR_ARRAY = [1]

/**
 * Aleaciones incorrectas en la base de datos
 */
const BAD_ALLOYS = [29, 30, 31, 32, 33, 35, 37, 38, 39, 41, 42, 43, 44, 45, 46]

const PALETTE_ID = {
	RAL: 1,
	PANTONE: 2,
}

const onDev = process.env.NODE_ENV === 'development'

export const ConfigTranslations = {
	getTechnologyAcronym: ({ id, partConfigOptions, language }) => {
		if (partConfigOptions == null) return '...'
		if (id == null) return ''
		return partConfigOptions?.fields?.technologyType?.options?.[id]?.abbreviation
			|| (onDev ? `${language}_technology_${id}_abbreviation` : '')
	},
	getTechnologyName: ({ id, partConfigOptions, language }) => {
		if (partConfigOptions == null) return '...'
		if (id == null) return ''
		return partConfigOptions?.fields?.technologyType?.options?.[id]?.[`name_${language}`]
			|| (onDev ? `${language}_technology_${id}_name` : '')
	},
	getMaterialName: ({ id, partConfigOptions, language }) => {
		if (partConfigOptions == null) return '...'
		if (id == null) return ''
		return partConfigOptions?.fields?.material?.options?.[id]?.[`name_${language}`]
			|| (onDev ? `${language}_material_${id}_name` : '')
	},
	getAlloyName: ({ id, partConfigOptions, language }) => {
		if (partConfigOptions == null) return '...'
		if (id == null || NO_ALLOY_ARRAY.includes(id) || BAD_ALLOYS.includes(id)) return ''
		return partConfigOptions?.fields?.alloy?.options?.[id]?.[`name_${language}`]
			|| (onDev ? `${language}_alloy_${id}_name` : '')
	},
	getFinishName: ({ id, partConfigOptions, language }) => {
		if (partConfigOptions == null) return '...'
		if (id == null || NO_FINISH_ARRAY.includes(id)) return ''
		return partConfigOptions?.fields?.finish?.options?.[id]?.[`name_${language}`]
			|| (onDev ? `${language}_finish_${id}_name` : '')
	},
	getPaletteName: ({ id, partConfigOptions }) => {
		if (partConfigOptions == null) return '...'
		if (id == null) return ''
		return partConfigOptions?.fields?.palette?.options?.[id]?.['name']
			|| (onDev ? `palette_${id}_name` : '')
	},
	getColorName: ({ id, partConfigOptions, language }) => {
		if (partConfigOptions == null) return '...'
		if (id == null || NO_COLOR_ARRAY.includes(id)) return ''
		return partConfigOptions?.fields?.color?.options?.[id]?.[`name_${language}`]
			|| (onDev ? `${language}_color_${id}_name` : '')
	},
	getPaletteNameByColor: ({ colorId, partConfigOptions }) => {
		if (partConfigOptions == null) return '...'
		if (colorId == null || NO_COLOR_ARRAY.includes(colorId)) return ''
		const colorOption = partConfigOptions?.fields?.color?.options?.[colorId]
		if (colorOption != null) {
			// ral value (solo si es RAL)
			let ral = colorOption?.ral
			if (!ral.startsWith('RAL')) ral = ''

			// palette (no mostrar 'RAL' porque se incluye en el valor 'ral' del color, mientras que 'PANTONE' no)
			let paletteName = ''
			const paletteId = colorOption?.palette?.[0]
			if (paletteId === PALETTE_ID.PANTONE) {
				paletteName = ConfigTranslations.getPaletteName({ id: paletteId, partConfigOptions })
			}

			// return all
			return `${ral}${paletteName}`
		}
		return (onDev ? `color_${colorId}_name` : '')
	},
	getPaletteAndColor: ({ colorId, partConfigOptions, language }) => {
		if (partConfigOptions == null) return '...'
		if (colorId == null || NO_COLOR_ARRAY.includes(colorId)) return ''
		const colorOption = partConfigOptions?.fields?.color?.options?.[colorId]
		if (colorOption != null) {
			// ral value (solo si es RAL)
			let ral = colorOption?.ral
			if (!ral.startsWith('RAL')) ral = ''

			// palette (no mostrar 'RAL' porque se incluye en el valor 'ral' del color, mientras que 'PANTONE' no)
			let paletteName = ''
			const paletteId = colorOption?.palette?.[0]
			if (paletteId === PALETTE_ID.PANTONE) {
				paletteName = ConfigTranslations.getPaletteName({ id: paletteId, partConfigOptions })
			}

			// color
			const colorName = ConfigTranslations.getColorName({ id: colorId, partConfigOptions, language })

			// return all
			return `${ral}${paletteName} ${colorName}`
		}
		return (onDev ? `${language}_color_${colorId}_name` : '')
	},
	getColorFinishName: ({ id, partConfigOptions, language }) => {
		if (partConfigOptions == null) return '...'
		if (id == null) return ''
		return partConfigOptions?.fields?.colorFinish?.options?.[id]?.[`name_${language}`]
			|| (onDev ? `${language}_colorFinish_${id}_name` : '')
	},
	getHardnessName: ({ id, partConfigOptions }) => {
		if (partConfigOptions == null) return '...'
		if (id == null) return ''
		return partConfigOptions?.fields?.hardness?.options?.[id]?.['name']
			|| (onDev ? `hardness_${id}_name` : '')
	},
}

const getTechnologyAcronym = ({ part, partConfigOptions }) => {
	const technologyId = part?.technology?.id || part?.config?.technologyType || part?.configuration?.technologyTypeId
	return ConfigTranslations.getTechnologyAcronym({ id: technologyId, partConfigOptions })
}

const getTechnologyName = ({ part, partConfigOptions, language }) => {
	const technologyId = part?.technology?.id || part?.config?.technologyType || part?.configuration?.technologyTypeId
	return ConfigTranslations.getTechnologyName({ id: technologyId, partConfigOptions, language })
}

const getMaterialName = ({ part, partConfigOptions, language }) => {
	const materialId = part?.material?.id || part?.config?.material || part?.configuration?.materialId || part?.configuration?.material
	return ConfigTranslations.getMaterialName({ id: materialId, partConfigOptions, language })
}

const getAlloyName = ({ part, partConfigOptions, language }) => {
	const alloyId = part?.material?.alloy?.id || part?.config?.alloy || part?.configuration?.alloyId || part?.configuration?.alloy
	return ConfigTranslations.getAlloyName({ id: alloyId, partConfigOptions, language })
}

const getFinishName = ({ part, partConfigOptions, language }) => {
	const finishId = part?.finishing?.id || part?.config?.finish || part?.configuration?.finishId || part?.configuration?.finishing
	return ConfigTranslations.getFinishName({ id: finishId, partConfigOptions, language })
}

const getPaletteName = ({ part, partConfigOptions }) => {
	const colorId = part?.finishing?.color?.id || part?.config?.color || part?.configuration?.colorId || part?.configuration?.color
	return ConfigTranslations.getPaletteNameByColor({ colorId, partConfigOptions })
}

const getColorName = ({ part, partConfigOptions, language }) => {
	const colorId = part?.finishing?.color?.id || part?.config?.color || part?.configuration?.colorId || part?.configuration?.color
	return ConfigTranslations.getColorName({ id: colorId, partConfigOptions, language })
}

const getColorWithPaletteName = ({ part, partConfigOptions, language }) => {
	const colorId = part?.finishing?.color?.id || part?.config?.color || part?.configuration?.colorId || part?.configuration?.color
	return ConfigTranslations.getPaletteAndColor({ colorId, partConfigOptions, language })
}

const getColorFinishName = ({ part, partConfigOptions, language }) => {
	const colorFinishId = part?.finishing?.color?.colorFinish?.id || part?.config?.colorFinish || part?.configuration?.colorFinishId
	return ConfigTranslations.getColorFinishName({ id: colorFinishId, partConfigOptions, language })
}

const getHardnessName = ({ part, partConfigOptions }) => {
	const hardnessId = part?.material?.hardness?.id || part?.config?.hardness || part?.configuration?.hardnessId
	return ConfigTranslations.getHardnessName({ id: hardnessId, partConfigOptions })
}

export const PartConfigTranslations = {
	getTechnologyAcronym,
	getTechnologyName,
	getMaterialName,
	getAlloyName,
	getFinishName,
	getPaletteName,
	getColorName,
	getColorWithPaletteName,
	getColorFinishName,
	getHardnessName,
	getAllNames: ({ part, partConfigOptions, language }) => ({
		material: PartConfigTranslations.getMaterialName({ part, partConfigOptions, language }),
		alloy: PartConfigTranslations.getAlloyName({ part, partConfigOptions, language }),
		hardness: PartConfigTranslations.getHardnessName({ part, partConfigOptions, language }),
		finishing: PartConfigTranslations.getFinishName({ part, partConfigOptions, language }),
		palette: PartConfigTranslations.getPaletteName({ part, partConfigOptions, language }),
		color: PartConfigTranslations.getColorName({ part, partConfigOptions, language }),
		colorFinishing: PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language }),
	}),
}

export const getClassnameByTechnology = ({ part }) => {
	const technologyId = part?.technology?.id || part?.config?.technologyType || part?.configuration?.technologyTypeId
	switch (technologyId) {
		case 1: return 'd-inline-block rounded-pill bg-olive text-white'
		case 2: return 'd-inline-block rounded-pill bg-turquoise text-white'
		case 4: return 'd-inline-block rounded-pill bg-pink text-white'
		case 3: return 'd-inline-block rounded-pill bg-purple text-white'
		case 5: return 'd-inline-block rounded-pill bg-orange text-white'
	}
}
