import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const SearchAndClientInfo = ({ scannedOrderList, isLoading, selectedOrderId }) => {
	const { t } = useTranslation()
	const [orderInfo, setOrderInfo] = useState({})

	useEffect(() => {
		if (scannedOrderList.length > 0 && selectedOrderId) {
			const selectedScannedOrder = scannedOrderList.find(scannedOrder => scannedOrder.order.id == selectedOrderId)?.order
			setOrderInfo(selectedScannedOrder)
		}
	}, [selectedOrderId, scannedOrderList])

	return (
		<div>
			{Object.keys(orderInfo).length != 0 && !isLoading && (
				<div className="mb-3 p-4 bg-white">
					<div
						className="w-100 p-2 d-flex align-items-center"
						style={{
							border: '0.5px solid lightgray',
							borderTopLeftRadius: '4px',
							borderTopRightRadius: '4px',
							backgroundColor: 'whitesmoke',
						}}
					>
						{t('shipment-data', { ns: 'naming' })}
					</div>
					<div
						className="w-100 ps-2 d-column align-items-center"
						style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
					>
						<p className="pt-3">
							{t('name', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.shippingAddress?.firstName} {orderInfo?.shippingAddress?.lastName}
							</strong>
						</p>
						<p>
							{t('company', { ns: 'naming' })}: <strong>{orderInfo?.shippingAddress?.company}</strong>
						</p>
						<p>
							{t('address', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.shippingAddress?.postalCode} -&nbsp;
								{orderInfo?.shippingAddress?.city} -&nbsp;
								{orderInfo?.shippingAddress?.country}
							</strong>
						</p>
					</div>
				</div>
			)}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		orderDetail: state.Orders.orderDetail,
		isLoading: state.Orders.isLoading,
		scannedOrderList: state.Orders.scannedVerificationOrderList,
		selectedOrderId: state.Orders.selectedVerificationOrderId?.orderId,
	}
}

SearchAndClientInfo.propTypes = {
	orderDetail: PropTypes.object,
	getOrderDetailById: PropTypes.func,
	isLoading: PropTypes.bool,
	scannedOrderList: PropTypes.array,
	selectedOrderId: PropTypes.number,
}

export default connect(mapStateToProps, {})(withTranslation()(SearchAndClientInfo))
