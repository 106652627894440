import { getIdFromUrl } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { getCrmInteractions } from 'store/actions'
import CrmLogsFilter from './CrmLogsFilter'
import { renderInteraction } from './CrmLogsUtils'
import ModalCreateCrmLog from './ModalCreateCrmLog'
import ModalDetailCrmLog from './ModalDetailCrmLog'

export const CrmLogs = ({ getCrmInteractions, crmInteractions, t, isLoading, error }) => {

	const [crmInteractionsToRender, setCrmInteractionsToRender] = useState([])
	const [filteredArray, setFilteredArray] = useState([])
	const [currentInteraction, setCurrentInteraction] = useState({})

	useEffect(() => {
		if (crmInteractions.length === 0) getCrmInteractions(getIdFromUrl())
	}, [])

	useEffect(() => {
		filterItems()
	}, [filteredArray])

	useEffect(() => {
		if (crmInteractions.length !== 0) {
			setCrmInteractionsToRender(crmInteractions)
		}
	}, [crmInteractions])

	const filterItems = () => {
		if (filteredArray.length === 0) return setCrmInteractionsToRender(crmInteractions)

		const filteredItems = crmInteractions.filter((incidence) => {
			if (incidence.interaction.type === 'agent') {
				return filteredArray.includes(incidence.interaction.interaction)
			}
			return filteredArray.includes(incidence.interaction.type)
		})
		setCrmInteractionsToRender(filteredItems)
	}

	const interactions = crmInteractionsToRender.map((interaction) => {
		return (
			<div key={interaction.interaction.id} className='mx-2' onClick={() => setCurrentInteraction(interaction)}>
				{renderInteraction(interaction, t)}
			</div>
		)
	})

	const getFilteredItems = () => {
		const itemsArray = crmInteractions.map((incidence) => {
			if (incidence.interaction.type === 'agent') {
				return incidence.interaction.interaction
			}
			return incidence.interaction.type
		})
		return [...new Set(itemsArray)]
	}

	return (
		<div style={{ height: '46.6rem' }} className='bg-white my-2 mb-4 p-2'>
			{isLoading ? <div className='d-flex justify-content-center'><Loader className={'fs-1'} /></div>
				: error ? <Alert
					type="danger"
					centered
					fitContent
					message={error.message}
				/> :
					<div >
						<ModalDetailCrmLog currentInteraction={currentInteraction} setCurrentInteraction={setCurrentInteraction} />
						<ModalCreateCrmLog />
						{crmInteractionsToRender.length !== 0 ?
							<div className='d-flex flex-row px-3 pt-4 pb-2 justify-content-between'>
								<div className='d-flex flex-row mb-0 align-items-center'>
									<div>
										<span className='badge bg-danger'>{crmInteractionsToRender.length}</span>
									</div>
									<h6 className='ms-2 mb-0'>{t('available_interactions', { ns: 'naming' })}</h6>
								</div>
								<div><CrmLogsFilter filterItems={getFilteredItems()} filteredArray={filteredArray} setFilteredArray={setFilteredArray} /></div>
							</div>
							: <div className='d-flex justify-content-center mt-2'><h6 className='text-muted'>{t('no_interactions', { ns: 'naming' })}</h6></div>
						}
						<SimpleBar style={{ maxHeight: '37.2rem' }}>
							{crmInteractionsToRender.length !== 0 && interactions}
						</SimpleBar>
					</div>
			}
		</div>
	)
}

CrmLogs.propTypes = {
	getCrmInteractions: PropTypes.func,
	crmInteractions: PropTypes.array,
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	error: PropTypes.string,

}

const mapStateToProps = (state) => ({
	crmInteractions: state.Crm.crmInteractions,
	isLoading: state.Crm.isLoading,
	error: state.Crm.error,
})

const mapDispatchToProps = {
	getCrmInteractions,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CrmLogs))
