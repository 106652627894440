/* PRODUCTIONS */
export const GET_PRODUCTION_LIST = 'GET_PRODUCTION_LIST'
export const GET_PRODUCTION_LIST_SUCCESS = 'GET_PRODUCTION_LIST_SUCCESS'
export const GET_PRODUCTION_LIST_FAIL = 'GET_PRODUCTION_LIST_FAIL'
export const GET_PRODUCTION_ITEMS_BY_PART_ID = 'GET_PRODUCTION_ITEMS_BY_PART_ID'
export const GET_PRODUCTION_ITEMS_BY_PART_ID_SUCCESS = 'GET_PRODUCTION_ITEMS_BY_PART_ID_SUCCESS'
export const GET_PRODUCTION_ITEMS_BY_PART_ID_FAIL = 'GET_PRODUCTION_ITEMS_BY_PART_ID_FAIL'
export const GET_SUPPLIER_SHIPMENT_PRICING_LIST = 'GET_SUPPLIER_SHIPMENT_PRICING_LIST'
export const GET_SUPPLIER_SHIPMENT_PRICING_LIST_SUCCESS = 'GET_SUPPLIER_SHIPMENT_PRICING_LIST_SUCCESS'
export const GET_SUPPLIER_SHIPMENT_PRICING_LIST_FAIL = 'GET_SUPPLIER_SHIPMENT_PRICING_LIST_FAIL'
export const LAUNCH_PRODUCTION = 'LAUNCH_PRODUCTION'
export const LAUNCH_PRODUCTION_SUCCESS = 'LAUNCH_PRODUCTION_SUCCESS'
export const LAUNCH_PRODUCTION_FAIL = 'LAUNCH_PRODUCTION_FAIL'
export const GET_PRODUCTION_DETAIL = 'GET_PRODUCTION_DETAIL'
export const GET_PRODUCTION_DETAIL_SUCCESS = 'GET_PRODUCTION_DETAIL_SUCCESS'
export const GET_PRODUCTION_DETAIL_FAIL = 'GET_PRODUCTION_DETAIL_FAIL'
export const GET_PRODUCTION_CHAT = 'GET_PRODUCTION_CHAT'
export const GET_PRODUCTION_CHAT_SUCCESS = 'GET_PRODUCTION_CHAT_SUCCESS'
export const GET_PRODUCTION_CHAT_FAIL = 'GET_PRODUCTION_CHAT_FAIL'
export const POST_PRODUCITON_CHAT = 'POST_PRODUCITON_CHAT'
export const POST_PRODUCITON_CHAT_SUCCESS = 'POST_PRODUCITON_CHAT_SUCCESS'
export const POST_PRODUCITON_CHAT_FAIL = 'POST_PRODUCITON_CHAT_FAIL'
export const GET_PRODUCTION_LIST_BY_SUPPLIER = 'GET_PRODUCTION_LIST_BY_SUPPLIER'
export const GET_PRODUCTION_LIST_BY_SUPPLIER_SUCCESS = 'GET_PRODUCTION_LIST_BY_SUPPLIER_SUCCESS'
export const GET_PRODUCTION_LIST_BY_SUPPLIER_FAIL = 'GET_PRODUCTION_LIST_BY_SUPPLIER_FAIL'
export const GET_PRODUCTION_ITEMS_LABELS = 'GET_PRODUCTION_ITEMS_LABELS'
export const GET_PRODUCTION_ITEMS_LABELS_SUCCESS = 'GET_PRODUCTION_ITEMS_LABELS_SUCCESS'
export const GET_PRODUCTION_ITEMS_LABELS_FAIL = 'GET_PRODUCTION_ITEMS_LABELS_FAIL'
export const UPDATE_PRODUCTION = 'UPDATE_PRODUCTION'
export const UPDATE_PRODUCTION_SUCCESS = 'UPDATE_PRODUCTION_SUCCESS'
export const UPDATE_PRODUCTION_FAIL = 'UPDATE_PRODUCTION_FAIL'
export const UPDATE_PRODUCTION_ITEM = 'UPDATE_PRODUCTION_ITEM'
export const UPDATE_PRODUCTION_ITEM_SUCCESS = 'UPDATE_PRODUCTION_ITEM_SUCCESS'
export const UPDATE_PRODUCTION_ITEM_FAIL = 'UPDATE_PRODUCTION_ITEM_FAIL'
export const COMPLETE_PRODUCTION = 'COMPLETE_PRODUCTION'
export const COMPLETE_PRODUCTION_SUCCESS = 'COMPLETE_PRODUCTION_SUCCESS'
export const COMPLETE_PRODUCTION_FAIL = 'COMPLETE_PRODUCTION_FAIL'
export const GET_PRODUCTION_ITEMS_BY_ORDER_ID = 'GET_PRODUCTION_ITEMS_BY_ORDER_ID'
export const GET_PRODUCTION_ITEMS_BY_ORDER_ID_SUCCESS = 'GET_PRODUCTION_ITEMS_BY_ORDER_ID_SUCCESS'
export const GET_PRODUCTION_ITEMS_BY_ORDER_ID_FAIL = 'GET_PRODUCTION_ITEMS_BY_ORDER_ID_FAIL'
export const GET_PRODUCTIONS_PICKED_UP_BY_COURIER = 'GET_PRODUCTIONS_PICKED_UP_BY_COURIER'
export const GET_PRODUCTIONS_PICKED_UP_BY_COURIER_SUCCESS = 'GET_PRODUCTIONS_PICKED_UP_BY_COURIER_SUCCESS'
export const GET_PRODUCTIONS_PICKED_UP_BY_COURIER_FAIL = 'GET_PRODUCTIONS_PICKED_UP_BY_COURIER_FAIL'
export const GET_PRODUCTIONS_LIST_FROM_ORDER_ID = 'GET_PRODUCTIONS_LIST_FROM_ORDER_ID'
export const GET_PRODUCTIONS_LIST_FROM_ORDER_ID_SUCCESS = 'GET_PRODUCTIONS_LIST_FROM_ORDER_ID_SUCCESS'
export const GET_PRODUCTIONS_LIST_FROM_ORDER_ID_FAIL = 'GET_PRODUCTIONS_LIST_FROM_ORDER_ID_FAIL'
export const ADD_PRODUCTION_ITEMS_FROM_SCANNED_ORDER = 'ADD_PRODUCTION_ITEMS_FROM_SCANNED_ORDER'
export const REJECT_PRODUCTION = 'REJECT_PRODUCTION'
export const REJECT_PRODUCTION_SUCCESS = 'REJECT_PRODUCTION_SUCCESS'
export const REJECT_PRODUCTION_FAIL = 'REJECT_PRODUCTION_FAIL'
export const GET_FILTERED_PRODUCTIONS = 'GET_FILTERED_PRODUCTIONS'
export const GET_FILTERED_PRODUCTIONS_SUCCESS = 'GET_FILTERED_PRODUCTIONS_SUCCESS'
export const GET_FILTERED_PRODUCTIONS_FAIL = 'GET_FILTERED_PRODUCTIONS_FAIL'
export const GET_SHIPMENTS_IMPORTS = 'GET_SHIPMENTS_IMPORTS'
export const GET_SHIPMENTS_IMPORTS_SUCCESS = 'GET_SHIPMENTS_IMPORTS_SUCCESS'
export const GET_SHIPMENTS_IMPORTS_FAIL = 'GET_SHIPMENTS_IMPORTS_FAIL'
export const UPDATE_SHIPMENT_IMPORT = 'UPDATE_SHIPMENT_IMPORT'
export const UPDATE_SHIPMENT_IMPORT_SUCCESS = 'UPDATE_SHIPMENT_IMPORT_SUCCESS'
export const UPDATE_SHIPMENT_IMPORT_FAIL = 'UPDATE_SHIPMENT_IMPORT_FAIL'
export const HANDLE_COMPLETE_PRODUCTION = 'HANDLE_COMPLETE_PRODUCTION'
export const HANDLE_COMPLETE_PRODUCTION_SUCCESS = 'HANDLE_COMPLETE_PRODUCTION_SUCCESS'
export const HANDLE_COMPLETE_PRODUCTION_FAIL = 'HANDLE_COMPLETE_PRODUCTION_FAIL'
export const GET_PURCHASES_DASHBOARD = 'GET_PURCHASES_DASHBOARD'
export const GET_PURCHASES_DASHBOARD_SUCCESS = 'GET_PURCHASES_DASHBOARD_SUCCESS'
export const GET_PURCHASES_DASHBOARD_FAIL = 'GET_PURCHASES_DASHBOARD_FAIL'
export const GET_PRODUCTIONS_BY_TRACKING = 'GET_PRODUCTIONS_BY_TRACKING'
export const GET_PRODUCTIONS_BY_TRACKING_SUCCESS = 'GET_PRODUCTIONS_BY_TRACKING_SUCCESS'
export const GET_PRODUCTIONS_BY_TRACKING_FAIL = 'GET_PRODUCTIONS_BY_TRACKING_FAIL'
export const GET_TRACKING_NUMBERS_FROM_PROVIDER = 'GET_TRACKING_NUMBERS_FROM_PROVIDER'
export const GET_TRACKING_NUMBERS_FROM_PROVIDER_SUCCESS = 'GET_TRACKING_NUMBERS_FROM_PROVIDER_SUCCESS'
export const GET_TRACKING_NUMBERS_FROM_PROVIDER_FAIL = 'GET_TRACKING_NUMBERS_FROM_PROVIDER_FAIL'
