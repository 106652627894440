import { checkIsPublicDomain, getIdFromUrl } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import { CRM_USER_DETAIL_ROUTE_NO_ID } from 'constants/appRoutes'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

export const DomainUsers = ({ userList, error, isLoading, t, history }) => {

	const [domainUsers, setDominUsers] = useState([])
	const [currentUser, setCurrentUser] = useState(null)

	useEffect(() => {
		if (userList.length !== 0) {
			setCurrentUser(userList.find(user => user.id === getIdFromUrl()))
		}

	}, [userList])

	useEffect(() => {
		if (currentUser != null) {
			setDominUsers(getDomainUsers())
		}
	}, [currentUser])

	const getDomainUsers = () => {
		return userList.filter(user => user?.purchaseStats?.domain === currentUser?.purchaseStats?.domain && user.email != currentUser.email)
	}

	const usersMaped = domainUsers.map((user) => {
		const url = CRM_USER_DETAIL_ROUTE_NO_ID + user.id
		return (
			<div key={user.id} className='d-flex flex-row justify-content-between w-100 border-bottom py-2 pe-3'>
				<div>
					<strong>{user.email}</strong>
					<div className='d-flex flex-row  align-items-center'>
						<i className='bx bx-user me-1'></i>
						<strong className='text-primary'>{`${user.firstName} ${user.lastName}`}</strong>
					</div>

				</div>
				<div className='d-flex align-items-center'>
					<a href={url}>

						<i className='fs-3 bx bxs-user-detail text-primary cursor-pointer'></i>
					</a>
				</div>
			</div>
		)
	})

	return (
		<div className='p-4'>
			{isLoading ? <div className='d-flex justify-content-center'><Loader className={'fs-1'} /></div>
				: error ? <Alert
					type="danger"
					centered
					fitContent
					message={error.message}
				/> : <div className='d-flex flex-column'>
					{
						checkIsPublicDomain(currentUser?.email) ?
							<div>
								Este usuario pertenece a un dominio público
							</div>
							:
							<div>
								<SimpleBar style={{ maxHeight: '51rem' }}>

									{domainUsers.length !== 0 ?
										usersMaped :
										<div>{t('no_domain_users', { ns: 'naming' })}</div>
									}
								</SimpleBar>
							</div>
					}
				</div>}

		</div>
	)
}

DomainUsers.propTypes = {
	isTabActive: PropTypes.bool,
	userList: PropTypes.array,
	error: PropTypes.string,
	isLoading: PropTypes.bool,
	t: PropTypes.func,
	history: PropTypes.func,
}

const mapStateToProps = (state) => ({
	userList: state.Crm.userList,
	isLoading: state.Crm.isLoading,
	error: state.Crm.error,
})

const mapDispatchToProps = {

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DomainUsers)))
