import { useTable } from 'common/Hooks/UseTable'
import { useCouriers } from 'common/Hooks/UseTranslatedCouriers'
import Loader from 'components/Common/Loader'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

// TODO

const mockData = [
	{
		id: 1,
		courier: 1,
		shipmentDate: '2019-04-12 11:07:12.000',
		arrivalDate: '2019-04-05 11:26:02.000',
		transitDays: 1,
		supplier: 1,
		totalWeight: 1,
		shipmentInvoice: 1,
		customsInvoice: 1,
		shipmentCost: 1,
		customs: 1,
		complete: 1,
	},
	{
		id: 2,
		courier: 2,
		shipmentDate: '2019-04-09 13:31:32.000',
		arrivalDate: '2019-03-14 13:18:22.000',
		transitDays: 2,
		supplier: 2,
		totalWeight: 2,
		shipmentInvoice: 2,
		customsInvoice: 2,
		shipmentCost: 2,
		customs: 2,
		complete: 2,
	},
]

const showDate = (dateString) => {
	return (new Date(dateString)).toLocaleDateString()
}

export const Exports = (props) => {

	const {
		t,
		i18n: { language },
		// data
		// functions
	} = props

	const { COURIER } = useCouriers(t)

	const [response, setResponse] = useState() // TODO esto no será un useState, vendrá de redux saga

	const {
		TableElement,
		page,
		pageSize,
	} = useTable({
		t,
		headers: {
			id: t('id'),
			courier: t('courier'),
			shipmentDate: t('shipmentDate'),
			arrivalDate: t('arrivalDate'),
			transitDays: t('transitDays'),
			supplier: t('supplier'),
			totalWeight: t('totalWeight'),
			shipmentInvoice: t('shipmentInvoice'),
			customsInvoice: t('customsInvoice'), // "customs" es aduana
			shipmentCost: `${t('shipmentCost')} (€)`,
			edit: t('edit'),
			customs: t('customs'),
			complete: t('complete'),
		},
		rows: response?.data?.map(item => ({
			id: (
				<div>
					{item.id}
				</div>
			),
			courier: (
				<div>
					{COURIER[item.courier]}
				</div>
			),
			shipmentDate: (
				<div>
					{showDate(item.shipmentDate)}
				</div>
			),
			arrivalDate: (
				<div>
					{showDate(item.arrivalDate)}
				</div>
			),
			transitDays: (
				<div>
					{item.transitDays}
				</div>
			),
			supplier: (
				<div>
					{item.supplier}
				</div>
			),
			totalWeight: (
				<div>
					{item.totalWeight}
				</div>
			),
			shipmentInvoice: (
				<div>
					{item.shipmentInvoice}
				</div>
			),
			customsInvoice: (
				<div>
					{item.customsInvoice}
				</div>
			),
			shipmentCost: (
				<div>
					{item.shipmentCost}
				</div>
			),
			edit: (
				<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
					<div
						className="fs-1 bx bxs-edit text-btn"
						style={{ cursor: 'pointer' }}
						onClick={() => alert(`edit shipment ${item.id}`)}
					/>
				</div >
			),
			customs: (
				// <div>
				// 	{boxItem.customs}
				// </div>
				<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
					<div
						className="fs-1 bx bxs-download text-btn"
						style={{ cursor: 'pointer' }}
						onClick={() => alert(`download customs ${item.id}`)}
					/>
				</div>
			),
			complete: (
				<div>
					{item.complete}
				</div>
			),
		})),
		// rowsExport, TODO
		totalCount: 20, // TODO
		initialPage: 1,
		initialPageSize: 20,
		// exportFileName, TODO
		// extraRefreshDependencies, TODO
	})

	useEffect(() => {
		// TODO reload data
		setTimeout(() => {
			setResponse({
				data: mockData,
				error: null,
			})
		}, [1000])
	}, [page, pageSize])

	const renderLoading = useMemo(() => !response && (
		<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
			<Loader className="me-4 py-4 d-flex font-size-80" />
		</div>
	), [response?.data == null])

	const renderError = useMemo(() => response?.error && (
		<div>
			error...
		</div>
	), [response?.error])

	const renderPage = useMemo(() => response && (
		<div className="bg-white my-4 p-4 marginFooter">
			<div className="py-2">
				{TableElement}
			</div>
		</div>
	), [response?.data])

	return (
		<>
			<h4>{t('exports')}</h4>
			{renderPage}
			{renderError}
			{renderLoading}
		</>
	)

}

Exports.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
	// boxList: PropTypes.array,
	// getBoxList: PropTypes.func,
	// getBoxListStatus: PropTypes.shape({
	// 	isLoading: PropTypes.bool,
	// 	success: PropTypes.bool,
	// 	error: PropTypes.string
	// }),
	// updateBoxesByRef: PropTypes.func,
	// updateBoxesByRefStatus: PropTypes.shape({
	// 	isLoading: PropTypes.bool,
	// 	success: PropTypes.bool,
	// 	error: PropTypes.string
	// }),
}

const mapStateToProps = state => {
	return {
		// boxList: state.Orders.boxList,
		// getBoxListStatus: state.Orders.getBoxListStatus,
		// updateBoxesByRefStatus: state.Orders.updateBoxesByRefStatus,
	}
}

export default connect(mapStateToProps, {
	// getBoxList,
	// updateBoxesByRef,
})(withTranslation('naming')(Exports))
