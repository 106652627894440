import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const SearchInput = ({ t, value, onChange }) => {
	return (
		<div className="search-box me-2 d-inline-block">
			<div className="position-relative">
				<label htmlFor="search-bar-0" className="search-label no-margin">
					<input
						id="search-bar-0"
						type="text"
						aria-labelledby="search-bar-0-label"
						className="form-control "
						placeholder="Buscar"
						value={value}
						onChange={e => onChange(e.target.value)}
					></input>
				</label>
				<i className="bx bx-search-alt search-icon"></i>
			</div>
		</div>
	)
}

SearchInput.propTypes = {
	t: PropTypes.func,
	onChange: PropTypes.func,
	value: PropTypes.string,
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SearchInput))
