import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'

//TODO , MAKE A CHART COMPONENT THAT CAN BE USED IN BOTH DASHBOARDS
export const TechnologyPercentageChart = ({ series, title }) => {

	const TECHNOLOGY_3D_COLOR = '#05f2d3'
	const TECHNOLOGY_CNC_COLOR = '#c9fc47'
	const TECHNOLOGY_BAR_VC_COLOR = '#ed47d1'

	const options = {
		chart: {
			width: 380,
			type: 'pie',
		},
		colors: [TECHNOLOGY_3D_COLOR, TECHNOLOGY_CNC_COLOR, TECHNOLOGY_BAR_VC_COLOR],
		labels: ['3D', 'CNC', 'VC'],
		legend: {
			show: false,
		},
		responsive: [{
			breakpoint: 480,
			options: {
				chart: {
					width: 200,
				},

			},
		}],
	}

	return (
		<div style={{ height: '42.5rem', paddingTop: '5rem' }}>
			<ReactApexChart options={options} series={series} type="pie" />
			<div className='d-flex justify-content-center w-100'>
				<span>{title}</span>
			</div>
		</div >
	)
}

TechnologyPercentageChart.propTypes = {
	series: PropTypes.array,
	title: PropTypes.string,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TechnologyPercentageChart) 
