import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import OrderInfo from './ReceptionOrderInfo/OrderInfo'
import ReceptionOrders from './ReceptionOrders/ReceptionOrders'

const Receptions = ({ t, selectedOrderId }) => {
	const [selectedOrder, setSelectedOrder] = useState(null)
	const [manualScanned, setManualScanned] = useState(null)
	const [isOpenAsignBoxModal, setIsOpenAssignBoxModal] = useState(false)

	useEffect(() => {
		if (selectedOrderId) {
			setSelectedOrder(selectedOrderId)
		}
	}, [selectedOrderId])

	return (
		<div className="page-content marginFooter">
			{/* Nombre de la pestaña en el navegador */}
			<MetaTags>
				<title>Proto&Go! | {t('receptions', { ns: 'naming' })}</title>
			</MetaTags>
			{/* Titulo de la página y breadcrumbs */}
			<Breadcrumbs
				title={t('logistics', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('receptions', { ns: 'naming' }),
						link: '/receptions',
					},
				]}
			/>
			<div className="d-flex flex-row align-items-start">
				{/* Columna a la izquierda con el listado de pedidos abiertos, el input para escanear manualmente, y el listado de pedidos previstos a recibir en los próximos días */}
				<div className="col-3 d-flex flex-column">
					<ReceptionOrders
						isOpenAsignBoxModal={isOpenAsignBoxModal}
						setIsOpenAssignBoxModal={setIsOpenAssignBoxModal}
						manualScanned={manualScanned}
						setManualScanned={setManualScanned}
					/>
				</div>
				{/* Sección principal: Texto "Escanear pieza para comenzar" si no hay pedido seleccionado / Detalle del pedido si hay uno seleccionado */}
				<div className="col-9 p-4 mb-3 mx-2 bg-white">
					<OrderInfo
						selectedOrderId={selectedOrder}
						setIsOpenAssignBoxModal={setIsOpenAssignBoxModal}
					/>
				</div>

			</div>
		</div>
	)
}

export default connect(
	// mapStateToProps (select data from redux)
	(state) => ({
		selectedOrderId: state.Orders.selectedOrderId,
	}),
)(
	withTranslation()(
		Receptions,
	),
)
