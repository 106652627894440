import { call, put, takeEvery } from 'redux-saga/effects'

import {
	CREATE_PARTS_QUANTITIES,
	DELETE_BINDING_NOTES,
	GET_FILTERED_PARTS,
	GET_PARTS_PREDICTS_AND_REAL,
	GET_PART_CONFIG_OPTIONS,
	GET_SIMILAR_PARTS_DETAIL,
	GET_TECHNOLOGIES_DASHBOARD,
	UPDATE_BINDING_NOTES,
	UPDATE_PARTS,
	UPDATE_PARTS_STATUS,
} from './actionTypes'

import {
	createPartsQuantitiesFail,
	createPartsQuantitiesSuccess,
	deleteBindingNotesFail,
	deleteBindingNotesSuccess,
	getFiltredPartsFail,
	getFiltredPartsSuccess,
	getPartConfigOptionsFail,
	getPartConfigOptionsSuccess,
	getPartsPredictsAndRealFail,
	getPartsPredictsAndRealSuccess,
	getSimilarPartsDetailFail,
	getSimilarPartsDetailSuccess,
	getTechnologiesDashboardFail,
	getTechnologiesDashboardSuccess,
	updateBindingNotesFail,
	updateBindingNotesSuccess,
	updatePartsFail,
	updatePartsStatusFail,
	updatePartsStatusSuccess,
	updatePartsSuccess,
} from './actions'

import {
	CREATE_PARTS_QUANTITIES_ENDPOINT,
	DELETE_BINDING_NOTES_ENDPOINT,
	GET_FILTERED_PARTS_ENDPOINT,
	GET_PARTS_PREDICTS_AND_REAL_ENDPOINT,
	GET_PART_CONFIG_OPTIONS_ENDPOINT,
	GET_SIMILAR_PARTS_DETAIL_ENDPOINT,
	GET_TECHNOLOGIES_DASHBOARD_ENDPOINT,
	UPDATE_BINDING_NOTES_ENDPOINT,
	UPDATE_PARTS_ENDPOINT,
	UPDATE_PART_STATUS_ENDPOINT,
} from '../../constants/backendRoutes'

import { updateDashboardCountryList } from 'store/actions'
import { get, post } from '../../helpers/api_helper'

function* getPartsPredictsAndReal() {
	try {
		const url = `${GET_PARTS_PREDICTS_AND_REAL_ENDPOINT}`
		const response = yield call(get, url)
		yield put(getPartsPredictsAndRealSuccess(response))
	} catch (error) {
		yield put(getPartsPredictsAndRealFail(error))
	}
}

function* updatePartsStatus({ payload: { partIds, statusId, suppliers, editingWithinBindingNoteModal, newPartsStatus } }) {
	try {
		const url = `${UPDATE_PART_STATUS_ENDPOINT}`
		const data = {
			data: {
				partIds,
				statusId,
				suppliers,
				newPartsStatus,
			},
		}
		const response = yield call(post, url, data)
		yield put(updatePartsStatusSuccess(response, editingWithinBindingNoteModal))
	} catch (error) {
		yield put(updatePartsStatusFail(error, editingWithinBindingNoteModal))
	}
}

function* createPartsQuantities({ payload: { partIds, quantities } }) {
	try {
		const url = CREATE_PARTS_QUANTITIES_ENDPOINT
		const data = {
			data: {
				partIds,
				quantities,
			},
		}
		const response = yield call(post, url, data)
		yield put(createPartsQuantitiesSuccess(response))
	} catch (error) {
		yield put(createPartsQuantitiesFail(error))
	}
}

function* getPartConfigOptions({ payload }) {
	const url = GET_PART_CONFIG_OPTIONS_ENDPOINT
	try {
		const response = yield call(get, url, payload)
		yield put(getPartConfigOptionsSuccess(response))
	} catch (error) {
		yield put(getPartConfigOptionsFail(error))
	}
}

function* updateParts({ payload, context }) {
	const url = UPDATE_PARTS_ENDPOINT
	try {
		const response = yield call(post, url, { data: payload })
		yield put(updatePartsSuccess(response, context))
	} catch (error) {
		yield put(updatePartsFail(error, context))
	}
}

function* getFiltredParts({ payload: { input, signal } }) {
	try {
		const url = `${GET_FILTERED_PARTS_ENDPOINT}`
		const data = { data: input }
		const response = yield call(post, url, data, { signal })
		yield put(getFiltredPartsSuccess(response))
	} catch (error) {
		yield put(getFiltredPartsFail(error))
	}
}

function* getSimilarPartsDetail({ payload: { partIds } }) {
	try {
		const url = `${GET_SIMILAR_PARTS_DETAIL_ENDPOINT}`
		const data = { data: partIds }
		const response = yield call(post, url, data)
		yield put(getSimilarPartsDetailSuccess(response))
	} catch (error) {
		yield put(getSimilarPartsDetailFail(error))
	}
}

function* updateBindingNotes({ payload }) {
	try {
		const url = UPDATE_BINDING_NOTES_ENDPOINT
		const response = yield call(post, url, { data: payload })
		yield put(updateBindingNotesSuccess(response))
	} catch (error) {
		yield put(updateBindingNotesFail(error))
	}
}

function* deleteBindingNotes({ payload }) {
	try {
		const url = DELETE_BINDING_NOTES_ENDPOINT
		const response = yield call(post, url, { data: payload })
		yield put(deleteBindingNotesSuccess(response))
	} catch (error) {
		yield put(deleteBindingNotesFail(error))
	}
}

function* getTechnologiesDashboard({ payload: { data } }) {
	try {
		const url = GET_TECHNOLOGIES_DASHBOARD_ENDPOINT
		const response = yield call(post, url, { data })
		yield put(updateDashboardCountryList(response.countryList))
		yield put(getTechnologiesDashboardSuccess(response))
	} catch (error) {
		yield put(getTechnologiesDashboardFail(error))
	}
}

function* PartsSaga() {
	yield takeEvery(UPDATE_PARTS_STATUS, updatePartsStatus)
	yield takeEvery(CREATE_PARTS_QUANTITIES, createPartsQuantities)
	yield takeEvery(GET_PART_CONFIG_OPTIONS, getPartConfigOptions)
	yield takeEvery(UPDATE_PARTS, updateParts)
	yield takeEvery(GET_FILTERED_PARTS, getFiltredParts)
	yield takeEvery(GET_SIMILAR_PARTS_DETAIL, getSimilarPartsDetail)
	yield takeEvery(UPDATE_BINDING_NOTES, updateBindingNotes)
	yield takeEvery(DELETE_BINDING_NOTES, deleteBindingNotes)
	yield takeEvery(GET_TECHNOLOGIES_DASHBOARD, getTechnologiesDashboard)
	yield takeEvery(GET_PARTS_PREDICTS_AND_REAL, getPartsPredictsAndReal)

}

export default PartsSaga
