import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'


class TooltipMessage extends Component {
	constructor(props) {
		super(props)

		const randomFactor = new Date().getTime()
		this.state = {
			id: 'tooltipButton' + Math.floor(Math.random() * randomFactor),
		}
	}
	getParsedMessage(message) {
		const MAX_LINE_LENGTH = 50
		const words = message.split(' ')
		const lines = []
		let currentLine = ''

		words.forEach(word => {
			if ((currentLine + word).length < MAX_LINE_LENGTH) {
				currentLine += (currentLine.length === 0 ? '' : ' ') + word
			} else {
				lines.push(currentLine)
				currentLine = word
			}
		})

		if (currentLine) lines.push(currentLine)

		return lines.join('<br />')
	}
	render() {
		if (this.props.message == null) return this.props.children
		return (
			<React.Fragment>
				<span>
					{this.props.children ? (
						<div
							className={'bx ' + this.props.className}
							data-tip={this.getParsedMessage(this.props.message)}
							data-for={this.state.id}
						>{this.props.children}</div>
					) : (
						<i
							className={'bx ' + this.props.className}
							data-tip={this.getParsedMessage(this.props.message)}
							data-for={this.state.id}
						/>
					)}
					<ReactTooltip
						id={this.state.id}
						place={this.props.place || 'left'}
						type="info"
						effect="solid"
						multiline={true}
					/>
				</span>
			</React.Fragment>
		)
	}
}

TooltipMessage.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	children: PropTypes.element,
}

export default TooltipMessage
