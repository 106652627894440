import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
const TextInput = ({
	value,
	setValue,
	title,
	hasPressed,
	type,
	isRequired,
	objectElement,
	placeholder,
	maxLength,
	multiple,
	t,
	onBlur,
	className,
	disabled,
	length,
	inputActions,
	noMarginDefault,
}) => {
	const divClassName = `${!noMarginDefault ? 'm-2' : ''} ${className} ${inputActions && 'inside-input-div'}`

	return (
		<>
			<div className={divClassName}>
				{title &&
					<h6>
						{isRequired && <span className="text-primary me-1">✲</span>}
						{title}
					</h6>
				}
				<input
					onFocus={e => type === 'number' ? e.currentTarget.select() : e.preventDefault()}
					disabled={disabled}
					onBlur={e => onBlur && onBlur(e.target.value)}
					onChange={e =>
						objectElement
							? setValue(objectElement, e.target.value)
							: setValue(e.target.value)
					}
					type={type}
					className={
						hasPressed && isRequired && !value
							? 'form-control is-invalid'
							: 'form-control'
					}
					id="exampleFormControlInput1"
					value={value}
					placeholder={placeholder}
					maxLength={maxLength}
					multiple={multiple}
				></input>
				{
					inputActions &&
					<div className="inside-input-span">
						{inputActions}
					</div>
				}
				{length && length != value.length && (
					<div className="ms-2 invalid-feedback w-auto">
						{`${t('the_length_must_be', { ns: 'naming' })} ${length} ${t('characters', { ns: 'naming' })}`}
					</div>)
				}

			</div>
		</>
	)
}
TextInput.propTypes = {
	type: PropTypes.string,
	isRequired: PropTypes.bool,
	value: PropTypes.any,
	setValue: PropTypes.func,
	title: PropTypes.string,
	hasPressed: PropTypes.bool,
	objectElement: PropTypes.string,
	placeholder: PropTypes.string,
	maxLength: PropTypes.number,
	multiple: PropTypes.bool,
	t: PropTypes.func,
	onBlur: PropTypes.func,
	className: PropTypes.string,
	inputActions: PropTypes.object,
	disabled: PropTypes.bool,
	length: PropTypes.number,
	noMarginDefault: PropTypes.bool,
}

export default withTranslation()(TextInput)
