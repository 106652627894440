export const GET_CRM_USERS = 'GET_CRM_USERS'
export const GET_CRM_USERS_SUCCESS = 'GET_CRM_USERS_SUCCESS'
export const GET_CRM_USERS_FAIL = 'GET_CRM_USERS_FAIL'
export const GET_CRM_USERS_DETAIL = 'GET_CRM_USERS_DETAIL'
export const GET_CRM_USERS_DETAIL_SUCCESS = 'GET_CRM_USERS_DETAIL_SUCCESS'
export const GET_CRM_USERS_DETAIL_FAIL = 'GET_CRM_USERS_DETAIL_FAIL'
export const UPDATE_CRM_USER_DETAIL = 'UPDATE_CRM_USER_DETAIL'
export const UPDATE_CRM_USER_DETAIL_SUCCESS = 'UPDATE_CRM_USER_DETAIL_SUCCESS'
export const UPDATE_CRM_USER_DETAIL_FAIL = 'UPDATE_CRM_USER_DETAIL_FAIL'
export const CREATE_NEW_CUSTOMER = 'CREATE_NEW_CUSTOMER'
export const CREATE_NEW_CUSTOMER_SUCCESS = 'CREATE_NEW_CUSTOMER_SUCCESS'
export const CREATE_NEW_CUSTOMER_FAIL = 'CREATE_NEW_CUSTOMER_FAIL'
export const CREATE_NEW_LOG = 'CREATE_NEW_LOG'
export const CREATE_NEW_LOG_SUCCESS = 'CREATE_NEW_LOG_SUCCESS'
export const CREATE_NEW_LOG_FAIL = 'CREATE_NEW_LOG_FAIL'
export const UPDATE_MANAGER = 'UPDATE_MANAGER'
export const UPDATE_MANAGER_SUCCESS = 'UPDATE_MANAGER_SUCCESS'
export const UPDATE_MANAGER_FAIL = 'UPDATE_MANAGER_FAIL'
export const CREATE_NEW_LEAD = 'CREATE_NEW_LEAD'
export const CREATE_NEW_LEAD_SUCCESS = 'CREATE_NEW_LEAD_SUCCESS'
export const CREATE_NEW_LEAD_FAIL = 'CREATE_NEW_LEAD_FAIL'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS'
export const UPDATE_USER_DATA_FAIL = 'UPDATE_USER_DATA_FAIL'
export const UPDATE_ORGANIZATION_DATA = 'UPDATE_ORGANIZATION_DATA'
export const UPDATE_ORGANIZATION_DATA_SUCCESS = 'UPDATE_ORGANIZATION_DATA_SUCCESS'
export const UPDATE_ORGANIZATION_DATA_FAIL = 'UPDATE_ORGANIZATIONA_DATA_FAIL'
export const UPDATE_PAYMENT_DATA = 'UPDATE_PAYMENT_DATA'
export const UPDATE_PAYMENT_DATA_SUCCESS = 'UPDATE_PAYMENT_DATA_SUCCESS'
export const UPDATE_PAYMENT_DATA_FAIL = 'UPDATE_PAYMENT_DATA_FAIL'
export const UPDATE_SHIPPING_ADDRESS_DATA = 'UPDATE_SHIPPING_ADDRESS_DATA'
export const UPDATE_SHIPPING_ADDRESS_DATA_SUCCESS = 'UPDATE_SHIPPING_ADDRESS_DATA_SUCCESS'
export const UPDATE_SHIPPING_ADDRESS_DATA_FAIL = 'UPDATE_SHIPPING_ADDRESS_DATA_FAIL'
export const GET_CRM_INTERACTIONS = 'GET_CRM_INTERACTIONS'
export const GET_CRM_INTERACTIONS_SUCCESS = 'GET_CRM_INTERACTIONS_SUCCESS'
export const GET_CRM_INTERACTIONS_FAIL = 'GET_CRM_INTERACTIONS_FAIL'
export const CREATE_NEW_CRM_LOG = 'CREATE_NEW_CRM_LOG'
export const CREATE_NEW_CRM_LOG_SUCCESS = 'CREATE_NEW_CRM_LOG_SUCCESS'
export const CREATE_NEW_CRM_LOG_FAIL = 'CREATE_NEW_CRM_LOG_FAIL'
export const GET_LOG_IN_AS_TOKEN = 'GET_LOG_IN_AS_TOKEN'
export const GET_LOG_IN_AS_TOKEN_SUCCESS = 'GET_LOG_IN_AS_TOKEN_SUCCESS'
export const GET_LOG_IN_AS_TOKEN_FAIL = 'GET_LOG_IN_AS_TOKEN_FAIL'
export const UPDATE_ORGANIZATION_COMMON_DATA = 'UPDATE_ORGANIZATION_COMMON_DATA'
export const UPDATE_ORGANIZATION_COMMON_DATA_SUCCESS = 'UPDATE_ORGANIZATION_COMMON_DATA_SUCCESS'
export const UPDATE_ORGANIZATION_COMMON_DATA_FAIL = 'UPDATE_ORGANIZATION_COMMON_DATA_FAIL'
