import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import TechnologyChart from 'components/Common/Charts/TechnologyChart'
import { TECHNOLOGY_TYPE } from 'constants/technologies'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getChartLabels, getIndexByType } from '../utils'

export const TechnologiesDashboard = ({ t, technologiesDashboardItems, dateRange, isOffers }) => {

	const labelValueTitle = isOffers ? 'Valor ofertado' : 'Subtotal'

	const getSeriesByData = (currentTechnologiesCountArray, currentTechnologiesSellingPriceArray) => {
		return {
			series: [
				{
					name: 'Referencias 3D',
					type: 'column',
					data: currentTechnologiesCountArray.technology3D,
				},
				{
					name: 'Referencias CNC',
					type: 'column',
					data: currentTechnologiesCountArray.technologyCNC,
				},
				{
					name: 'Referencias VC',
					type: 'column',
					data: currentTechnologiesCountArray.technologyVC,
				},
				{
					name: `${labelValueTitle} 3D`,
					type: 'line',
					data: currentTechnologiesSellingPriceArray.technology3D,
				},
				{
					name: `${labelValueTitle} CNC`,
					type: 'line',
					data: currentTechnologiesSellingPriceArray.technologyCNC,
				},
				{
					name: `${labelValueTitle} VC`,
					type: 'line',
					data: currentTechnologiesSellingPriceArray.technologyVC,
				},

			],
			//Esto es para mostrar los gráficos de manera que se entienda mejor. El 1.1 no llegen al techo.
			maxCount: roundAtDecimals(Math.max(...currentTechnologiesCountArray.technology3D, ...currentTechnologiesCountArray.technologyCNC, ...currentTechnologiesCountArray.technologyVC) * 1.1, 2) || 1,
			maxSellingPrice: roundAtDecimals(Math.max(...currentTechnologiesSellingPriceArray.technology3D, ...currentTechnologiesSellingPriceArray.technologyCNC, ...currentTechnologiesSellingPriceArray.technologyVC) * 1.1, 2) || 1,
		}
	}

	const getDashboardValues = () => {

		const currentTechnologiesCountArray = {
			technology3D: Array.from({ length: labels.length }, () => 0),
			technologyCNC: Array.from({ length: labels.length }, () => 0),
			technologyVC: Array.from({ length: labels.length }, () => 0),
		}

		const currentTechnologiesSellingPriceArray = {
			technology3D: Array.from({ length: labels.length }, () => 0),
			technologyCNC: Array.from({ length: labels.length }, () => 0),
			technologyVC: Array.from({ length: labels.length }, () => 0),
		}

		if (technologiesDashboardItems.length === 0) return getSeriesByData(currentTechnologiesCountArray, currentTechnologiesSellingPriceArray) // RETURNS A EMPTY DASHBOARD
		for (const { creationDate, technologyId, totalSellingPrice } of technologiesDashboardItems) {
			const index = getIndexByType(type, creationDate, dateRange)
			const roundedTotalSellingPrice = Math.ceil(totalSellingPrice)

			if (technologyId === TECHNOLOGY_TYPE.ADDITIVE_MANUFACTURING) {
				currentTechnologiesCountArray.technology3D[index]++
				currentTechnologiesSellingPriceArray.technology3D[index] += roundedTotalSellingPrice
			}
			if (technologyId === TECHNOLOGY_TYPE.COMPUTERIZED_NUMERIC_CONTROL) {
				currentTechnologiesCountArray.technologyCNC[index]++
				currentTechnologiesSellingPriceArray.technologyCNC[index] += roundedTotalSellingPrice
			}
			if (technologyId === TECHNOLOGY_TYPE.VACUUM_CASTING) {
				currentTechnologiesCountArray.technologyVC[index]++
				currentTechnologiesSellingPriceArray.technologyVC[index] += roundedTotalSellingPrice
			}
		}
		return getSeriesByData(currentTechnologiesCountArray, currentTechnologiesSellingPriceArray)
	}

	const { labels, type } = useMemo(() => getChartLabels(dateRange, t), [dateRange])
	const { series, maxCount, maxSellingPrice } = useMemo(() => getDashboardValues(), [technologiesDashboardItems])

	return (
		<div><TechnologyChart maxCount={maxCount} maxSellingPrice={maxSellingPrice} series={series} labels={labels} leftYAxisText={'Referencias'} rightYAxisText={labelValueTitle} /></div>
	)
}

TechnologiesDashboard.propTypes = {
	technologiesDashboardItems: PropTypes.array,
	dateRange: PropTypes.object,
}

const mapStateToProps = (state) => ({
	technologiesDashboardItems: state.Parts.technologiesDashboardItems,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TechnologiesDashboard))
