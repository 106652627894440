import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const QuantitiesModal = ({ t, isOpen, closeModal, partId, quantity, qualityQuantities }) => {
	const quantityToVerify = useMemo(() => {
		return qualityQuantities.find((e) => e.min <= quantity && e.max >= quantity)?.quantity || 'N/A'
	}, [quantity, qualityQuantities])
	return (
		<>
			<Modal
				isOpen={isOpen}
				closeModal={closeModal}
				title={`${t('quantity-to-verify', { ns: 'naming' })} - ${t('part', { ns: 'naming' })} ${partId}`}
				body={
					<div className="px-4 py-2">
						<p>
							<span>{`${t('there-are', { ns: 'naming' })} `}</span>
							<strong>{quantity}</strong>
							<span>{` ${t('units-of-the-part', { ns: 'naming' })} `}</span>
							<strong>{partId}</strong>
						</p>
						<p>
							<span>{`${t('recommended-quantity-to-verify', { ns: 'naming' })}: `}</span>
							<strong>{quantityToVerify}</strong>
							<span>{` ${t('units', { ns: 'naming' })}`}</span>
						</p>
					</div>
				}
			/>
		</>
	)
}

const mapStateToProps = state => {
	return {
		qualityQuantities: state.Orders.qualityQuantities.qualityQuantities,
	}
}

QuantitiesModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	partId: PropTypes.number,
	quantity: PropTypes.number,
	qualityQuantities: PropTypes.array,
}

export default connect(mapStateToProps, {})(withTranslation()(QuantitiesModal))
