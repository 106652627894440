import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import PurchasesChart from 'components/Common/Charts/PurchasesChart'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const PurchasesDashboard = ({ t, suppliers, purchasesItems }) => {

	const getSeriesByData = (purchasesArray, costsArray) => {
		return {
			series: [

				{
					name: 'Compras',
					type: 'column',
					data: purchasesArray,
				}
				, {
					name: 'Costes',
					type: 'line',
					data: costsArray,
				}
			],
			//Esto es para mostrar los gráficos de manera que se entienda mejor. El 1.1 y 1.4 es para que las barras y lineas del gráfico no llegen al techo.
			maxPurchases: Math.max(...purchasesArray) * 1.1,
			maxCosts: Math.max(...costsArray) * 1.4,
		}
	}

	const getChartLabels = () => {
		if (purchasesItems.length === 0 || suppliers.length === 0) return []
		const supplierIds = [...new Set(purchasesItems.map(({ supplierId }) => supplierId))].sort((a, b) => b - a)
		return supplierIds.map((supplierId) => {
			const supplier = suppliers.find(({ account }) => account.id === supplierId)
			return supplier.account.personalInformation.firstName
		})
	}

	const getChartValues = (labels) => {
		if (purchasesItems.length === 0 || suppliers.length === 0) return { series: [], labels: [] }

		const purchasesArray = Array.from({ length: labels.length }, () => 0)
		const costsArray = Array.from({ length: labels.length }, () => 0)

		for (const { supplierId, costs, creationDate } of purchasesItems) {
			const supplierIndex = labels.indexOf(suppliers.find(({ account }) => account.id === supplierId).account.personalInformation.firstName)
			purchasesArray[supplierIndex] += 1
			costsArray[supplierIndex] += roundAtDecimals(costs, 0)
		}
		return getSeriesByData(purchasesArray, costsArray)
	}

	const labels = useMemo(() => getChartLabels(), [purchasesItems, suppliers])
	const { series, maxPurchases, maxCosts } = useMemo(() => getChartValues(labels), [purchasesItems])

	return (
		<div><PurchasesChart series={series} labels={labels} maxPurchases={maxPurchases} maxCosts={maxCosts} /></div>
	)
}

PurchasesDashboard.propTypes = {
	t: PropTypes.func,
	suppliers: PropTypes.array,
	purchasesItems: PropTypes.array,

}

const mapStateToProps = (state) => ({
	suppliers: state.Users.supplierDetailList,
	purchasesItems: state.Productions.purchasesItems,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PurchasesDashboard))
