import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { OptionsInput } from 'components/Common/OptionsInput'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TitleWithValueColumn from '../CommonComponents/TitleWithValueColumn'
import { getLapseDivisor } from '../utils'


export const PurchasesDashboardSideBar = ({ t, suppliers, purchasesStats, dateRange, purchasesItems }) => {

	const [selectedSupplierId, setSelectedSupplierId] = useState(purchasesStats[0]?.supplierId)

	const suppliersOptions = useMemo(() => {
		const availableSupplierIdOptions = [...new Set(purchasesItems.map(({ supplierId }) => supplierId))]
		return availableSupplierIdOptions.map((supplierId) => ({
			label: suppliers.find(({ account }) => account.id === supplierId)?.account?.personalInformation?.firstName,
			value: supplierId,
		}))
	}, [purchasesItems])

	const { currentStat, previousStat } = purchasesStats.find(({ supplierId }) => supplierId === selectedSupplierId) || {}
	const lapseDivisor = useMemo(() => getLapseDivisor(dateRange), [dateRange])

	return (
		<div className='d-flex flex-column justify-content-center align-items-start'>
			{suppliersOptions.length !== 0 && <OptionsInput
				className='w-100'
				automaticMappedValue
				options={suppliersOptions}
				setValue={setSelectedSupplierId}
				value={selectedSupplierId}

			/>}
			<div className="d-flex flex-column w-100">
				<div>
					<TitleWithValueColumn
						title={t('productions', { ns: 'naming' })}
						value={currentStat?.productionsCount}
						previousValue={previousStat?.productionsCount}
					/>
				</div>
				<div>
					<TitleWithValueColumn
						title={t('periodical_productions', { ns: 'naming' })}
						value={roundAtDecimals(currentStat?.productionsCount / lapseDivisor, 2)}
						previousValue={roundAtDecimals(previousStat?.productionsCount / lapseDivisor, 2)}
					/>
				</div>
				<div>
					<TitleWithValueColumn
						title={t('total_cost', { ns: 'naming' })}
						value={currentStat?.totalCost}
						previousValue={previousStat?.totalCost}
						isCurrency
					/>
				</div>
				<div>
					<TitleWithValueColumn
						title={t('periodical_total_cost', { ns: 'naming' })}
						value={currentStat?.totalCost / lapseDivisor}
						previousValue={previousStat?.totalCost / lapseDivisor}
						isCurrency
					/>
				</div>
				<div>
					<TitleWithValueColumn
						title={t('net_profit', { ns: 'naming' })}
						value={currentStat?.netProfits}
						previousValue={previousStat?.netProfits}
						isCurrency
					/>
				</div>
				<div>
					<TitleWithValueColumn
						title={t('shipment_cost', { ns: 'naming' })}
						value={currentStat?.shipmentCosts}
						previousValue={previousStat?.shipmentCosts}
						isCurrency
					/>
				</div>

				<div>
					<TitleWithValueColumn
						title={t('weight', { ns: 'naming' })}
						value={roundAtDecimals(currentStat?.totalWeight, 2)}
						previousValue={roundAtDecimals(previousStat?.totalWeight, 2)}
					/>
				</div>
			</div>
		</div>
	)
}

PurchasesDashboardSideBar.propTypes = {
	t: PropTypes.func,
	suppliers: PropTypes.array
}

const mapStateToProps = (state) => ({
	suppliers: state.Users.supplierDetailList,
	purchasesStats: state.Productions.purchasesStats,
	purchasesItems: state.Productions.purchasesItems,

})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PurchasesDashboardSideBar))
