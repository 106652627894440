import JsBarcode from 'jsbarcode'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

const Barcode = ({ id, code, format, className, width }) => {
	if (!id) throw new Error('Id attribute is required')

	useEffect(() => {
		JsBarcode(document.getElementById(`barcode_canvas_${id}`), code, {
			format,
			height: 40,
			width: (width || 1),
			putOnlyUsedFonts: true,
			displayValue: false,
		})
	}, [code])

	return (
		<div className={`d-flex ${className}`}>
			<canvas className="mx-auto" id={`barcode_canvas_${id}`}></canvas>
		</div>
	)
}

Barcode.propTypes = {
	id: PropTypes.string.isRequired,
	code: PropTypes.string,
	text: PropTypes.string,
	format: PropTypes.string,
	className: PropTypes.string,
}

export default Barcode
