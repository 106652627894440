import { render } from '@testing-library/react'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'

export const CrmTable = ({ data, title }) => {

    const table = () => {
        return data?.map((row) => {
            if (row.length === 1) {
                return renderSingelRow(row)
            }
            return renderDoubleRow(row)
        })
    }

    const renderSingelRow = (row) => {
        return (
	<div className="row">
		<div className="d-flex col-3 bg-whitened border-bone align-items-center form-cell flex-shrink-1 flex-grow-0">
			{row[0].label}
		</div>
		<div className="d-flex col-9 border-bone align-items-center form-cell flex-shrink-1 flex-grow-0">
			<span className='end-ellipsis w-100'>{row[0].value}</span>
		</div>

	</div>
        )
    }

    const renderDoubleRow = (row) => {
        return (
	<div className="row">
		<div className="d-flex col-3 bg-whitened border-bone align-items-center form-cell">
			{row[0].label}
		</div>
		<div className="d-flex col-3 border-bone align-items-center form-cell ">
			<span className='end-ellipsis w-100'>{row[0].value}</span>
		</div>
		<div className="d-flex col-3 bg-whitened border-bone align-items-center form-cell ">
			{row[1].label}
		</div>
		<div className="d-flex col-3 border-bone align-items-center form-cell end-ellipsis">
			<span className='end-ellipsis w-100'>{row[1].value}</span>
		</div>
	</div>
        )
    }

    return (
	<div className='py-1'>
		{data != null &&
		<div>
			{title}
			{table()}
		</div>
            }
	</div>
    )
}

CrmTable.propTypes = {
    data: PropTypes.array,
    title: PropTypes.object,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CrmTable)