export const findManager = (managers, managerId) => {
	if (managerId == null || managers.length === 0) return null
	const manager = managers.find(manager => manager.id === managerId)
	if (manager == null || manager?.personalInformation == null) return null
	return {
		label: manager?.personalInformation?.firstName + ' ' + manager?.personalInformation?.lastName,
		value: manager?.id,
	}
}

export const mapManagers = (managers) => {

	return managers.map(manager => {
		return {
			label: manager?.personalInformation?.firstName + ' ' + manager?.personalInformation?.lastName,
			value: manager?.id,
		}
	})
}
