import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

export const Counters = ({ t, counters }) => {
	if (!counters || counters.length == 0) return <></>
	return (
		<div className='d-flex flex-row'>
			{counters.map((counter, index) => (
				<div key={`${counter}_${index}`} className='d-flex flex-row align-items-center ms-2'>
					<span>{`${t(counter.label, { ns: 'naming' })}: ${counter.value}`}</span>
				</div>
			))}
		</div>
	)
}

Counters.propTypes = {
	t: PropTypes.func,
	counters: PropTypes.array,
}

export default (withTranslation()(Counters))
