import { getDateWithDay } from 'common/Utils/StringUtilities'
import RemainingDays from 'components/Common/RemainingDays'
import { VerificationStatus } from 'constants/verificationStatus'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const VerificationOrderSummary = ({ order, t, selectedOrderId }) => {
	const {
		id,
		organizationName,
		references,
		totalPieces,
		completed,
		shipmentDate,
		box,
		isOK,
		arrivalDate,
		receivingCountry,
		disconformity,
	} = order

	const defaultPClassName = 'm-0 p-0 me-1 '
	const flexRowClassName = 'd-flex flex-row '
	const flexColumnClassName = 'd-flex flex-column '

	const getStatusText = completed => {
		switch (completed) {
			case VerificationStatus.PENDING:
				return t('pending', { ns: 'naming' })
			case VerificationStatus.PROCESSING:
				return t('processing', { ns: 'naming' })
			case VerificationStatus.COMPLETED:
				return t('complete', { ns: 'naming' })
		}
	}

	const getCompletedClassName = completed => {
		switch (completed) {
			case VerificationStatus.PENDING:
				return 'bx bxs-circle text-secondary margin-top-017rem ms-1'
			case VerificationStatus.PROCESSING:
				return 'bx bxs-circle text-primary margin-top-017rem ms-1'
			case VerificationStatus.COMPLETED:
				return 'bx bxs-circle text-success margin-top-017rem ms-1'
		}
	}

	return (
		<div
			className="d-flex justify-content-between border p-2 pb-3 cursor-pointer"
			style={{ backgroundColor: selectedOrderId == id ? '#E3F2FD' : '' }}
		>
			<div className={flexColumnClassName}>
				<strong>{organizationName}</strong>

				<div className="d-flex flex-row align-items-center">
					<p className={defaultPClassName + 'me-1'}>ID: </p>
					<strong>{id}</strong>
					{isOK == false && (
						<span className="bg-red rounded p-1 px-2 text-white ms-1">
							<strong>NO OK</strong>
						</span>
					)}
				</div>

				<div className={flexRowClassName}>
					<p className={defaultPClassName}>{references}</p>
					<p className={defaultPClassName}>{t('Referencias', { ns: 'naming' })}</p>
					<p className={defaultPClassName}> {totalPieces}</p>
					<p className={defaultPClassName}>{t('parts', { ns: 'naming' })}</p>
				</div>

				<div className={flexRowClassName}>
					<p className={defaultPClassName}>{t('shipment', { ns: 'naming' }) + ':'}</p>
					<p className={defaultPClassName}>{getDateWithDay(shipmentDate)}</p>
				</div>
				<p className={defaultPClassName}>{<RemainingDays date={shipmentDate} finalDate={arrivalDate} />}</p>
				<div className="mt-2">
					{disconformity && (
						<span className="bg-red rounded p-1 px-2 text-white">
							<strong>DISCONFORMITY</strong>
						</span>
					)}
				</div>
			</div>
			<div>
				<div className={flexColumnClassName}>
					<span className={flexRowClassName + 'justify-content-end'}>
						{getStatusText(completed)}
						<i className={getCompletedClassName(completed)}></i>
					</span>

					<div className="d-flex justify-content-end">
						<span className="text-end">
							<strong>{box}</strong>
						</span>
					</div>

					<div className="d-flex justify-content-end">
						{receivingCountry !== 'ES' && (
							<span className="bg-yellow rounded p-1 px-2 text-white text-end">
								<strong>UPS</strong>
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		selectedOrderId: state.Orders.selectedVerificationOrderId?.orderId,
	}
}

VerificationOrderSummary.propTypes = {
	order: PropTypes.object,
	t: PropTypes.func,
	selectedOrderId: PropTypes.any,
}

export default connect(mapStateToProps)(withTranslation()(VerificationOrderSummary))
