import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'
import '../../../assets/scss/theme.scss'

class ApexRadial extends Component {
	constructor(props) {
		super(props)

		this.state = {
			chart: {
				height: 250,
				type: 'radialBar',
				offsetY: -10,
			},
			options: {
				plotOptions: {
					radialBar: {
						startAngle: -135,
						endAngle: 135,
						dataLabels: {
							name: {
								fontSize: '13px',
								color: void 0,
								offsetY: 60,
							},
							value: {
								offsetY: 22,
								fontSize: '16px',
								color: void 0,
								formatter: function (e) {
									return e + '%'
								},
							},
						},
					},
				},
				colors: ['#556ee6'],
				fill: {
					type: 'gradient',
					gradient: {
						shade: 'dark',
						shadeIntensity: 0.15,
						inverseColors: !1,
						opacityFrom: 1,
						opacityTo: 1,
						stops: [0, 50, 65, 91],
					},
				},
				stroke: {
					dashArray: 4,
				},
				labels: [' '],
			},
			//series:  this.props.series//[54],
		}
	}

	render() {
		return (
			<React.Fragment>
				<ReactApexChart
					options={this.state.options}
					series={[this.props.series]}
					type="radialBar"
					height="200"
					className="apex-charts"
				/>
			</React.Fragment>
		)
	}
}
ApexRadial.propTypes = {
	series: PropTypes.number,
}

export default ApexRadial
