export function roundAtDecimals(number, decimalsToRound) {
	if (!number) return 0
	const roundedNumber =
		Math.round(number * Math.pow(10, decimalsToRound)) /
		Math.pow(10, decimalsToRound)
	if (countDecimals(roundedNumber))
		return Number(roundedNumber.toFixed(decimalsToRound))

	return roundedNumber
}

export function countDecimals(number) {
	if (Math.floor(number) === number) return 0
	return (number.toString && number.toString().split('.')[1]?.length) || 0
}

export function roundCurrency(number) {
	const roundedNumber = Math.round(number * Math.pow(10, 2)) / Math.pow(10, 2)
	return roundedNumber.toFixed(2)
}

export function calculateVolumetricWeight(x, y, z) {
	const EXTRA_VOLUMETRIC_SIZE_BY_PACKAGING = 1.2
	const VOLUMETRIC_WEIGHT_CONSTANT = 5000
	//convert from milimeters to centimeters
	x = x / 10
	y = y / 10
	z = z / 10
	const volumetricSize = (x * y * z) * EXTRA_VOLUMETRIC_SIZE_BY_PACKAGING
	return volumetricSize / VOLUMETRIC_WEIGHT_CONSTANT
}

export function isEven(number) {
	return number % 2 === 0
}

export function formatWeight(orderWeight) {
	if (orderWeight && orderWeight > 1000) {
		return `${roundAtDecimals(orderWeight / 1000, 1)} kg`
	} else {
		return `${roundAtDecimals(orderWeight, 0)} g`

	}
}

// IT ROUNDS 0,5 example 1,3 -> 1,5 || 0,7 -> 1
export const roundIntrastatWeight = (weight) => {
	return Math.ceil(weight / 1000 * 2) / 2
}
// 1234567890 -> '1.234.567.890'
export function formatNumberWithDots(number) {
	let numStr = number.toString()
	numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	return numStr
}

export const formatCurrencyWithSeparators = (number) => {
	const numberString = number.toFixed(2)
	const splitNumber = numberString.split(".")
	const integerPart = splitNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	const decimalPart = splitNumber[1]
	return `${integerPart},${decimalPart}`
}
