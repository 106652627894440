import CryptoJS from 'crypto-js'

export function hashPassword(password) {
	const hashedPassword = CryptoJS.SHA3(password)
	const hashedPassowordString = hashedPassword.toString(CryptoJS.enc.Base64)

	return hashedPassowordString
}

export const hash = hashPassword
