import axios from 'axios'
import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'

const UNAUTHORIZED_STATUS = 401
//apply base url for axios
const API_URL = ''

const axiosApi = axios.create({
	baseURL: API_URL,
})

axiosApi.interceptors.response.use(
	response => response,
	error => Promise.reject(error),
)

axiosApi.interceptors.response.use(
	response => response,
	error => {
		if (error?.response?.status === UNAUTHORIZED_STATUS) {
			window.location.href = '/'
			return Promise.reject(null)
		}
		return Promise.reject(error)
	},
)

function setToken() {
	const authUser = getUserValuesFromLocalStorage()
	if (authUser && authUser.token)
		axiosApi.defaults.headers['Authorization'] = authUser.token
}
export async function get(url, config = {}, isReturningWholeResponse = false) {
	setToken()
	return await axiosApi
		.get(url, { ...config })
		.then(response => (isReturningWholeResponse ? response : response.data))
}

export async function post(
	url,
	data,
	config = {},
	isReturningWholeResponse = false,
) {
	setToken()
	return axiosApi
		.post(url, { ...data }, { ...config })
		.then(response => (isReturningWholeResponse ? response : response.data))
}

export async function postForm(
	url,
	data,
) {
	const formData = new FormData()
	formData.append('data', `{"partId": ${data.data.partId}}`)
	formData.append('modifiedBlueprint', data.data.files.modifiedBlueprint)

	const authUser = getUserValuesFromLocalStorage()
	return axios.post(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': authUser.token,
		},
	}).then(response => {
		return response.data
	}).catch(error => {
		console.error(error)
	})
}

export async function postFormData(url, data) {

	const formData = new FormData()
	formData.append('data', JSON.stringify(data.data))
	if (data.files?.invoice) {
		formData.append('invoice', data.files.invoice)
	}
	if (data.files?.photos) {
		for (const photo of data.files.photos) {
			formData.append('photos', photo)
		}
	}
	if (data.images) {
		for (const image of data.images) {
			formData.append('images', image)
		}
	}
	if (data.resolutionsImages) {
		for (const image of data.resolutionsImages) {
			formData.append('resolutionsImages', image)
		}
	}
	if (data.taskImages) {
		for (const image of data.taskImages) {
			formData.append('taskImages', image)
		}
	}

	if (data.deliveryNote) {
		formData.append('deliveryNote', data.deliveryNote)
	}

	if (data.files?.accountImage) {
		formData.append('accountImage', data.files.accountImage)
	}

	const authUser = getUserValuesFromLocalStorage()
	return axios.post(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': authUser.token,
		},
	}).then(response => response.data)
}

export async function postFormLabels(url, data) {
	const formData = new FormData()
	if (data.data) {
		formData.append('data', JSON.stringify(data.data))
	}
	if (data.deliveryNotes) {
		for (var i = 0; i < data.deliveryNotes.length; i++) {
			formData.append('deliveryNotes', data.deliveryNotes[i])
		}
	}
	if (data.label) {
		formData.append('label', data.label)
	}
	const authUser = getUserValuesFromLocalStorage()
	axios.post(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': authUser.token,
		},
	}).then(response => {
		return response
	})
}

export async function put(
	url,
	data,
	config = {},
	isReturningWholeResponse = false,
) {
	setToken()
	return axiosApi
		.put(url, { ...data }, { ...config })
		.then(response => (isReturningWholeResponse ? response : response.data))
}

export async function del(url, config = {}, isReturningWholeResponse = false) {
	setToken()
	return await axiosApi
		.delete(url, { ...config })
		.then(response => (isReturningWholeResponse ? response : response.data))
}

export default {
	get,
	post,
	put,
	del,
	postForm,
}
