import { useHandleCancelableApi } from 'common/Hooks/UseHandleCancelableApi'
import { useTable } from 'common/Hooks/UseTable'
import { useCouriers } from 'common/Hooks/UseTranslatedCouriers'
import { formatToThousands } from 'common/Utils/FormatToThousands'
import { formatWeight } from 'common/Utils/NumberUtilities'
import { getDate } from 'common/Utils/StringUtilities'
import { downloadXLSX } from 'common/Utils/downloadUtils'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import SearchInput from 'components/Common/SearchInput'
import { ProductionStatus } from 'constants/productionStatus'
import ImportsCustomsModal from 'pages/LogisticsShipments/components/ImportsCustomsModal'
import { ImportsEditModal } from 'pages/LogisticsShipments/components/ImportsEditModal'
import { ImportsFiltersModal } from 'pages/LogisticsShipments/components/ImportsFiltersModal'
import { Tracking } from 'pages/SupplierProduction/Tracking'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getCompleteProductionFiles, getSuppliersInfo, updateShipmentImport } from 'store/actions'
import { getShipmentsImports } from 'store/productions/actions'

export const Imports = (props) => {

	const t = (translationKey, options) => {
		return props.t(translationKey, options || { ns: 'naming' })
	}

	const {
		i18n: { language },
		getSuppliersInfo,
		suppliers,
		getShipmentsImports,
		list,
		totalCount,
		statusGet,
		updateShipmentImport,
		statusUpdate,
		getCompleteProductionFiles,
	} = props

	const [filtersModalIsOpen, setFiltersModalIsOpen] = useState(false)
	const [editModalIsOpen, setEditModalIsOpen] = useState(false)
	const [itemInEditModal, setItemInEditModal] = useState()

	useEffect(() => {
		if (suppliers == null || suppliers.length == 0) getSuppliersInfo()
	}, [])

	const { COURIER } = useCouriers(t)

	const [customsPDFModalIsOpen, setCustomsPDFModalIsOpen] = useState(false)

	const [customsPDFModalData, setCustomsPDFModalData] = useState({
		trackingNumber: null,
		productions: [],
	})

	const headers = useMemo(() => ({
		id: t('id'),
		courier: t('courier'),
		shipmentDate: t('shipmentDate'),
		arrivalDate: t('arrivalDate'),
		transitDays: t('transitDays'),
		supplier: t('supplier'),
		totalWeight: t('totalWeight'),
		shipmentInvoice: t('shipmentInvoice'),
		customsInvoice: t('customsInvoice'), // "customs" es aduana
		shipmentCost: `${t('shipmentCost')} (€)`,
		edit: t('edit'),
		customs: t('customs'),
		complete: t('complete'),
	}), [language])

	const {
		TableElement,
		page,
		pageSize,
		setPage,
	} = useTable({
		t,
		language,
		headers: headers,
		headerProps: {
			shipmentDate: {
				style: {
					maxWidth: '10%',
					textWrap: 'wrap',
				},
			},
			arrivalDate: {
				style: {
					maxWidth: '10%',
					textWrap: 'wrap',
				},
			},
			transitDays: {
				style: {
					maxWidth: '5%',
					textWrap: 'wrap',
				},
			},
			shipmentCost: {
				style: {
					maxWidth: '5%',
					textWrap: 'wrap',
				},
			},
		},
		rows: list?.map(item => {
			const {
				shipmentImport,

				computedColumns,
			} = item
			const { transitDays,
				complete,
				shipmentDate,
				arrivalDate,
				courier } = computedColumns
			const supplier = suppliers.find(supplier => supplier.account.id == shipmentImport.accountId)
			return {
				id: (
					<Tracking
						trackingId={shipmentImport.tracking}
						courier={courier}
						status={ProductionStatus.PICKED_UP_BY_COURIER}
					/>
				),
				courier: (
					<div>
						{COURIER[courier]}
					</div>
				),
				shipmentDate: (
					<div>
						{getDate(shipmentDate)}
					</div>
				),
				arrivalDate: (
					<div>
						{getDate(arrivalDate)}
					</div>
				),
				transitDays: (
					<div>
						{transitDays}
					</div>
				),
				supplier: (
					<div>
						{supplier?.account?.personalInformation?.firstName}
					</div>
				),
				totalWeight: (
					<div>
						{formatWeight(shipmentImport.weight)}
					</div>
				),
				shipmentInvoice: (
					<div>
						{shipmentImport.shippingInvoice}
					</div>
				),
				customsInvoice: (
					<div>
						{shipmentImport.customsInvoice}
					</div>
				),
				shipmentCost: (
					<div>
						{formatToThousands(shipmentImport.shippingCost)}{' €'}
					</div>
				),
				edit: (
					<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
						<div
							className="h3 bx bxs-edit text-btn"
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setItemInEditModal(item)
								setEditModalIsOpen(true)
							}}
						/>
					</div>
				),
				customs: (
					<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
						<div
							className="h3 bx bxs-download text-btn"
							style={{ cursor: 'pointer' }}
							onClick={() => {
								setCustomsPDFModalIsOpen(true)
								setCustomsPDFModalData({
									trackingNumber: shipmentImport.tracking,
								})
							}}
						/>
					</div>
				),
				complete: (
					<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
						{computedColumns.complete ? (
							<i className='h3 bx bx-check-circle text-success'></i>
						) : (
							<i className='h3 bx bx-minus-circle text-danger'></i>
						)}
					</div>
				),
			}
		}),
		totalCount: totalCount,
		initialPage: 1,
		initialPageSize: 20,
	})

	const exportExcel = () => {
		downloadXLSX({
			rows: [
				{
					tracking: t('id'),
					courier: t('courier'),
					shipmentDate: t('shipmentDate'),
					arrivalDate: t('arrivalDate'),
					transitDays: t('transitDays'),
					supplier: t('supplier'),
					shipmentInvoice: t('shipmentInvoice'),
					customsInvoice: t('customsInvoice'),
					totalWeight: `${t('totalWeight')} (g)`,
					shipmentCost: `${t('shipmentCost')} (€)`,
					customsDuty: `${t('customsDuty')} (€)`,
					tariffDuty: `${t('tariffDuty')} (€)`,
					totalCost: `${t('totalCost')} (€)`,
				},
				...list?.map(item => {
					const {
						shipmentImport,
						supplier,
						productions,
						computedColumns,
					} = item
					const {
						transitDays,
						complete,
						shipmentDate,
						arrivalDate,
						courier } = computedColumns
					const formatDate = (dateStr) => {
						const date = new Date(dateStr)
						const year = date.getFullYear()
						const month = String(date.getMonth() + 1).padStart(2, '0')
						const day = String(date.getDate()).padStart(2, '0')
						const hours = String(date.getHours()).padStart(2, '0')
						const minutes = String(date.getMinutes()).padStart(2, '0')
						return `${day}/${month}/${year} ${hours}:${minutes}`
					}
					return {
						tracking: shipmentImport.tracking,
						courier: COURIER[courier],
						shipmentDate: formatDate(shipmentDate),
						arrivalDate: formatDate(arrivalDate),
						transitDays: transitDays,
						supplier: supplier?.supplierInfo?.officialName,
						shipmentInvoice: shipmentImport.shippingInvoice,
						customsInvoice: shipmentImport.customsInvoice,
						totalWeight: formatToThousands(shipmentImport.weight),
						shipmentCost: formatToThousands(shipmentImport.shippingCost),
						customsDuty: formatToThousands(shipmentImport.customsDuty),
						tariffDuty: formatToThousands(shipmentImport.tariffDuty),
						totalCost: formatToThousands(
							(shipmentImport.shippingCost || 0) +
							(shipmentImport.customsDuty || 0) +
							(shipmentImport.tariffDuty || 0),
						),
					}
				}),
				// TODO last row with totals ?
			],
			fileName: 'imports',
		})
	}

	const [filters, _setFilters] = useState({
		courierIds: null, // number[]
		shipmentDateRange: null, // { startDate: string, endDate: string }
		arrivalDateRange: null, // { startDate: string, endDate: string }
		transitDaysRange: null, // { moreThan: number, lessThan: number }
		supplierIds: null, // number[]
		totalWeightRange: null, // { moreThan: number, lessThan: number }
		shipmentCostRange: null, // { moreThan: number, lessThan: number }
		tariffDutyRange: null, // { moreThan: number, lessThan: number }
		customsDutyRange: null, // { moreThan: number, lessThan: number }
		completed: null, // boolean
	})

	const setFilters = (filters) => {
		_setFilters({
			courierIds: filters?.courierIds?.length > 0 ? filters?.courierIds : null,
			shipmentDateRange: (filters?.shipmentDateRange?.startDate != null || filters?.shipmentDateRange?.endDate != null) ? filters?.shipmentDateRange : null,
			arrivalDateRange: (filters?.arrivalDateRange?.startDate != null || filters?.arrivalDateRange?.endDate != null) ? filters?.arrivalDateRange : null,
			transitDaysRange: (filters?.transitDaysRange?.moreThan != null || filters?.transitDaysRange?.lessThan != null) ? filters?.transitDaysRange : null,
			supplierIds: filters?.supplierIds?.length > 0 ? filters?.supplierIds : null,
			totalWeightRange: (filters?.totalWeightRange?.moreThan != null || filters?.totalWeightRange?.lessThan != null) ? filters?.totalWeightRange : null,
			shipmentCostRange: (filters?.shipmentCostRange?.moreThan != null || filters?.shipmentCostRange?.lessThan != null) ? filters?.shipmentCostRange : null,
			tariffDutyRange: (filters?.tariffDutyRange?.moreThan != null || filters?.tariffDutyRange?.lessThan != null) ? filters?.tariffDutyRange : null,
			customsDutyRange: (filters?.customsDutyRange?.moreThan != null || filters?.customsDutyRange?.lessThan != null) ? filters?.customsDutyRange : null,
			completed: filters?.completed,
		})
	}

	useEffect(() => setPage(1), [filters])
	useEffect(() => {
		if (!firstRender || !searchQuery) {
			getData()
		}
	}, [page, pageSize, filters])

	const [searchKeyword, setSearchKeyword] = useState()
	const [searchByKeywordTimeoutId, setSearchByKeywordTimeoutId] = useState()
	const [firstRender, setFirstRender] = useState(true)
	useEffect(() => {
		if (firstRender) {
			setFirstRender(false)
			return
		}
		let keyword = searchKeyword?.trim()
		if (keyword == '') keyword = null
		if (searchByKeywordTimeoutId != null) clearTimeout(searchByKeywordTimeoutId)
		const timeoutId = setTimeout(() => getData(), 1000)
		setSearchByKeywordTimeoutId(timeoutId)
	}, [searchKeyword])

	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const searchQuery = searchParams.get('search')
	useEffect(() => {
		setSearchKeyword(searchQuery)
	}, [searchQuery])

	const [updateSuccessModalIsOpen, setUpdateSuccessModalIsOpen] = useState()
	const [updateErrorModalIsOpen, setUpdateErrorModalIsOpen] = useState()
	useEffect(() => {
		if (statusUpdate.isLoading === false) {
			if (statusUpdate.success) {
				setUpdateSuccessModalIsOpen(true)
			} else {
				setUpdateErrorModalIsOpen(true)
			}
		}
	}, [statusUpdate.isLoading])

	const { handleApiCall: getData } = useHandleCancelableApi(
		getShipmentsImports,
		{
			data: {
				keyword: searchKeyword,
				pagination: {
					page: page,
					pageSize: pageSize,
				},
				filters: filters,
			},
		},
		0,
	)

	const SearchInputMemo = useMemo(() => (
		<SearchInput onChange={setSearchKeyword} value={searchKeyword} />
	), [searchKeyword, language])

	const FiltersButtonMemo = useMemo(() => {
		const infoClassName = 'border border-warning p-1 me-3'
		const dateRangeInfo = (name, dateRange) => (dateRange?.startDate != null || dateRange?.endDate != null) && (
			<div className={infoClassName}>
				{name}
				{': '}
				{t('date_from')}
				{' '}
				{dateRange?.startDate?.toLocaleDateString?.() || '~'}
				{' '}
				{t('date_to')}
				{' '}
				{dateRange?.endDate?.toLocaleDateString?.() || '~'}
			</div>
		)
		const numRangeInfo = (name, numRange) => (numRange?.moreThan != null || numRange?.lessThan != null) && (
			<div className={infoClassName}>
				{name}
				{': '}
				{t('date_from')}
				{' '}
				{numRange?.moreThan || '~'}
				{' '}
				{t('date_to')}
				{' '}
				{numRange?.lessThan || '~'}
			</div>
		)
		const priceRangeInfo = (name, priceRange) => (priceRange?.moreThan != null || priceRange?.lessThan != null) && (
			<div className={infoClassName}>
				{name}
				{': '}
				{t('date_from')}
				{' '}
				{formatToThousands(priceRange?.moreThan) || '~'}
				{' '}
				{t('date_to')}
				{' '}
				{formatToThousands(priceRange?.lessThan) || '~'}
			</div>
		)
		return (
			<React.Fragment>
				<button
					className="btn btn-secondary no-margin"
					style={{ width: 'auto', height: '36px' }}
					onClick={() => setFiltersModalIsOpen(true)}
				>
					{t('filter')}
				</button>
				<div
					className="d-flex flex-wrap align-items-start"
					onClick={() => setFiltersModalIsOpen(true)}
				>
					{(filters.courierIds != null && filters.courierIds.length > 0) && (
						<span className={infoClassName}>{t('courier')}{': '}{filters.courierIds.map(cid => COURIER[cid]).join(', ')}</span>
					)}
					{dateRangeInfo(t('shipmentDate'), filters.shipmentDateRange)}
					{dateRangeInfo(t('arrivalDate'), filters.arrivalDateRange)}
					{numRangeInfo(t('transitDays'), filters.transitDaysRange)}
					{(filters.supplierIds != null && filters.supplierIds.length > 0) && (
						<span className={infoClassName}>{t('supplier')}{': '}{filters.supplierIds.map(sid => suppliers.find(s => s.account.id == sid)?.account.personalInformation?.firstName).join(', ')}</span>
					)}
					{numRangeInfo(t('totalWeight'), filters.totalWeightRange)}
					{priceRangeInfo(t('shipmentCost'), filters.shipmentCostRange)}
					{filters.completed != null && (
						<span className={infoClassName}>{t('complete')}{': '}{filters.completed ? t('✓') : t('✗')}</span>
					)}
				</div>
			</React.Fragment>
		)
	}, [filters, language])

	const TableLoadingMemo = useMemo(() => statusGet.isLoading && (
		<div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
			<Loader className="me-4 py-4 d-flex font-size-80" />
		</div>
	), [statusGet.isLoading])

	const TableErrorMemo = useMemo(() => statusGet.error && (
		<div>
			error...
		</div>
	), [statusGet.error])

	const UpdateResultModalMemo = useMemo(() => (
		<>
			<Modal
				isOpen={updateSuccessModalIsOpen}
				closeModal={() => {
					getData()
					setUpdateSuccessModalIsOpen(false)
					setEditModalIsOpen(false)
				}}
				title={t('update_success')}
			/>
			<Modal
				isOpen={updateErrorModalIsOpen}
				closeModal={() => setUpdateErrorModalIsOpen(false)}
				title={t('error_message')}
			/>
		</>
	), [
		updateSuccessModalIsOpen,
		updateErrorModalIsOpen,
	])

	return (
		<>
			<div className="bg-white p-4">
				<div className="d-flex align-items-center justify-content-between w-100 gap-3">
					<div className="d-flex align-items-center gap-3">
						{SearchInputMemo}
						{FiltersButtonMemo}
					</div>
					<div className="d-flex align-items-center gap-3">
						<button
							className="btn btn-success" style={{ width: 'auto' }}
							onClick={() => exportExcel()}
						>
							{t('export_orders')}
						</button>
						{/* TODO export invoices */}
						{/* <button
							className="btn btn-info" style={{ width: "auto" }}
							onClick={() => alert("TODO export_invoices")}
						>
							{t("export_invoices")}
						</button> */}
					</div>
				</div>
				<div className="py-2">
					{statusGet.success && TableElement}
				</div>
			</div>
			{TableErrorMemo}
			{TableLoadingMemo}
			<ImportsEditModal
				t={t}
				isOpen={editModalIsOpen}
				closeModal={() => setEditModalIsOpen(false)}
				item={itemInEditModal}
				updateShipmentImport={updateShipmentImport}
				suppliers={suppliers}
			/>
			<ImportsFiltersModal
				t={t}
				isOpen={filtersModalIsOpen}
				closeModal={() => setFiltersModalIsOpen(false)}
				currentFilters={filters}
				setFilters={setFilters}
				suppliers={suppliers}
			/>
			{UpdateResultModalMemo}
			<ImportsCustomsModal
				t={t}
				isOpen={customsPDFModalIsOpen}
				closeModal={() => setCustomsPDFModalIsOpen(false)}
				suppliers={suppliers}
				productionFiles={props.productionFiles}
				{...customsPDFModalData}
			/>
		</>
	)

}

Imports.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,

	// GET all suppliers
	getSuppliersInfo: PropTypes.func,
	suppliers: PropTypes.array,

	// GET shipment import list
	getShipmentsImports: PropTypes.func,
	list: PropTypes.array,
	totalCount: PropTypes.number,
	statusGet: PropTypes.shape({
		isLoading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.string,
	}),

	// GET production files of a single shipment import (TODO)
	getCompleteProductionFiles: PropTypes.func,
	productionFiles: PropTypes.array,

	// UPDATE shipment import
	updateShipmentImport: PropTypes.func,
	statusUpdate: PropTypes.shape({
		isLoading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.string,
	}),
}

const mapStateToProps = state => {
	return {
		...state.Productions.shipmentsImportsData,
		suppliers: state.Users.supplierDetailList,
		productionFiles: state.CloudStorage.productionFiles,
	}
}

export default connect(mapStateToProps, {
	getSuppliersInfo,
	getShipmentsImports,
	updateShipmentImport,
	getCompleteProductionFiles,
})(withTranslation()(Imports))
