import {
	CREATE_NEW_CRM_LOG,
	CREATE_NEW_CRM_LOG_FAIL,
	CREATE_NEW_CRM_LOG_SUCCESS,
	CREATE_NEW_CUSTOMER,
	CREATE_NEW_CUSTOMER_FAIL,
	CREATE_NEW_CUSTOMER_SUCCESS,
	CREATE_NEW_LEAD,
	CREATE_NEW_LEAD_FAIL,
	CREATE_NEW_LEAD_SUCCESS,
	CREATE_NEW_LOG,
	CREATE_NEW_LOG_FAIL,
	CREATE_NEW_LOG_SUCCESS,
	GET_CRM_INTERACTIONS,
	GET_CRM_INTERACTIONS_FAIL,
	GET_CRM_INTERACTIONS_SUCCESS,
	GET_CRM_USERS,
	GET_CRM_USERS_DETAIL,
	GET_CRM_USERS_DETAIL_FAIL,
	GET_CRM_USERS_DETAIL_SUCCESS,
	GET_CRM_USERS_FAIL,
	GET_CRM_USERS_SUCCESS,
	GET_LOG_IN_AS_TOKEN,
	GET_LOG_IN_AS_TOKEN_FAIL,
	GET_LOG_IN_AS_TOKEN_SUCCESS,
	UPDATE_CRM_USER_DETAIL,
	UPDATE_CRM_USER_DETAIL_FAIL,
	UPDATE_CRM_USER_DETAIL_SUCCESS,
	UPDATE_MANAGER,
	UPDATE_MANAGER_FAIL,
	UPDATE_MANAGER_SUCCESS,
	UPDATE_ORGANIZATION_COMMON_DATA,
	UPDATE_ORGANIZATION_COMMON_DATA_FAIL,
	UPDATE_ORGANIZATION_COMMON_DATA_SUCCESS,
	UPDATE_ORGANIZATION_DATA,
	UPDATE_ORGANIZATION_DATA_FAIL,
	UPDATE_ORGANIZATION_DATA_SUCCESS,
	UPDATE_PAYMENT_DATA,
	UPDATE_PAYMENT_DATA_FAIL,
	UPDATE_PAYMENT_DATA_SUCCESS,
	UPDATE_SHIPPING_ADDRESS_DATA,
	UPDATE_SHIPPING_ADDRESS_DATA_FAIL,
	UPDATE_SHIPPING_ADDRESS_DATA_SUCCESS,
	UPDATE_USER_DATA,
	UPDATE_USER_DATA_FAIL,
	UPDATE_USER_DATA_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
	userList: [],
	crmInteractions: [],
	userData: null,
	isLoading: false,
	error: null,
	updateSuccess: null,
	updateManagerSuccess: null,
	isLoadingCreateUser: false,
	errorUpdate: null,
	isLoadingUpdate: false,
	isCreatingCrmLog: false,
	logInAsToken: null,
	createNewCrmLog_Status: {
		loading: false,
		success: null,
		error: null,
		response: null,
	},
}

const crm = (state = INIT_STATE, action) => {
	switch (action.type) {
		case GET_CRM_USERS:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_CRM_USERS_SUCCESS:
			return {
				...state,
				userList: action.payload.crmList,
				error: null,
				isLoading: false,
			}

		case GET_CRM_USERS_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}
		case GET_CRM_USERS_DETAIL:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_CRM_USERS_DETAIL_SUCCESS:
			return {
				...state,
				userData: action.payload.data,
				error: null,
				isLoading: false,
			}
		case GET_CRM_USERS_DETAIL_FAIL:
			return {
				...state,
				userData: {},
				error: action.payload,
				isLoading: false,
			}
		case UPDATE_CRM_USER_DETAIL:
			return {
				...state,
				error: null,
				updateSuccess: null,
			}
		case UPDATE_CRM_USER_DETAIL_SUCCESS:
			return {
				...state,
				error: false,
				updateSuccess: true,
			}
		case UPDATE_CRM_USER_DETAIL_FAIL:
			return {
				...state,
				error: true,
				updateSuccess: false,
			}
		case CREATE_NEW_CUSTOMER:
			return {
				...state,
				errorCreateUser: null,
				isLoadingCreateUser: true,
			}
		case CREATE_NEW_CUSTOMER_SUCCESS:
			return {
				...state,
				errorCreateUser: action.payload.message,
				isLoadingCreateUser: false,
			}
		case CREATE_NEW_CUSTOMER_FAIL:
			return {
				...state,
				errorCreateUser: action.payload,
				isLoadingCreateUser: false,
			}
		case CREATE_NEW_LOG:
			return {
				...state,
				error: null,
			}
		case CREATE_NEW_LOG_SUCCESS:
			return {
				...state,
				error: false,
			}
		case CREATE_NEW_LOG_FAIL:
			return {
				...state,
				error: true,
			}
		case UPDATE_MANAGER:
			return {
				...state,
				error: null,
			}
		case UPDATE_MANAGER_SUCCESS:
			return {
				...state,
				error: false,
				updateManagerSuccess: true,
			}
		case UPDATE_MANAGER_FAIL:
			return {
				...state,
				error: true,
				updateManagerSuccess: false,
			}
		case CREATE_NEW_LEAD:
			return {
				...state,
				errorCreateUser: null,
				isLoadingCreateUser: true,
			}
		case CREATE_NEW_LEAD_SUCCESS:
			return {
				...state,
				errorCreateUser: action.payload.message,
				isLoadingCreateUser: false,
			}
		case CREATE_NEW_LEAD_FAIL:
			return {
				...state,
				errorCreateUser: action.payload,
				isLoadingCreateUser: false,
			}
		case UPDATE_USER_DATA:
			return {
				...state,
				errorUpdate: null,
				isLoadingUpdate: true,
			}
		case UPDATE_USER_DATA_SUCCESS:
			return {
				...state,
				isLoadingUpdate: false,
				userData: {
					...state.userData,
					account: action.payload.data.account,
				},
			}
		case UPDATE_USER_DATA_FAIL:
			return {
				...state,
				errorUpdate: action.payload.message,
				isLoadingUpdate: false,
			}
		case UPDATE_ORGANIZATION_DATA:
			return {
				...state,
				errorUpdate: null,
				isLoadingUpdate: true,
			}
		case UPDATE_ORGANIZATION_DATA_SUCCESS:

			return {
				...state,
				isLoadingUpdate: false,
				userData: {
					...state.userData,
					organization: action.payload.data.organization,
				},
			}
		case UPDATE_ORGANIZATION_DATA_FAIL:
			return {
				...state,
				errorUpdate: action.payload.message,
				isLoadingUpdate: false,
			}
		case UPDATE_PAYMENT_DATA:
			return {
				...state,
				errorUpdate: null,
				isLoadingUpdate: true,
			}
		case UPDATE_PAYMENT_DATA_SUCCESS:
			return {
				...state,
				isLoadingUpdate: false,
				userData: {
					...state.userData,
					paymentMethod: action.payload.paymentSetting,
				},
			}
		case UPDATE_PAYMENT_DATA_FAIL:
			return {
				...state,
				errorUpdate: action.payload.message,
				isLoadingUpdate: false,
			}

		case UPDATE_SHIPPING_ADDRESS_DATA:
			return {
				...state,
				errorUpdate: null,
				isLoadingUpdate: true,
			}
		case UPDATE_SHIPPING_ADDRESS_DATA_SUCCESS:
			return {
				...state,
				isLoadingUpdate: false,
				userData: {
					...state.userData,
					shippingAddress: action.payload.data.shippingAddress,
				},
			}
		case UPDATE_SHIPPING_ADDRESS_DATA_FAIL:
			return {
				...state,
				errorUpdate: action.payload.message,
				isLoadingUpdate: false,
			}

		case GET_CRM_INTERACTIONS:
			return {
				...state,
				isLoadingInteractions: true,
				error: null,
			}
		case GET_CRM_INTERACTIONS_SUCCESS:
			return {
				...state,
				crmInteractions: action.payload.crmInteractions,
				error: null,
				isLoadingInteractions: false,
			}

		case GET_CRM_INTERACTIONS_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}

		case CREATE_NEW_CRM_LOG:
			return {
				...state,
				isCreatingCrmLog: true,
				errorUpdate: null,
				createNewCrmLog_Status: {
					loading: true,
					success: null,
					error: null,
					response: null,
				},
			}
		case CREATE_NEW_CRM_LOG_SUCCESS:
			return {
				...state,
				crmInteractions: [{ interaction: action.payload.crmInteraction, account: state.userData?.account }, ...state.crmInteractions],
				errorUpdate: null,
				isCreatingCrmLog: false,
				createNewCrmLog_Status: {
					loading: false,
					success: true,
					error: false,
					response: action.payload,
				},
			}

		case CREATE_NEW_CRM_LOG_FAIL:
			return {
				...state,
				errorUpdate: action.payload.message,
				isCreatingCrmLog: false,
				createNewCrmLog_Status: {
					loading: false,
					success: false,
					error: true,
					response: action.payload,
				},
			}

		case GET_LOG_IN_AS_TOKEN:
			return {
				...state,
				isLoadingToken: true,
				error: null,
			}
		case GET_LOG_IN_AS_TOKEN_SUCCESS:
			return {
				...state,
				logInAsToken: action.payload.token,
				error: null,
				isLoadingToken: false,
			}

		case GET_LOG_IN_AS_TOKEN_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoadingToken: false,
			}

		case UPDATE_ORGANIZATION_COMMON_DATA:
			return {
				...state,
				errorUpdate: null,
				isLoadingUpdate: true,
			}
		case UPDATE_ORGANIZATION_COMMON_DATA_SUCCESS:

			return {
				...state,
				isLoadingUpdate: false,
				userData: {
					...state.userData,
					organization: action.payload.organization,
				},
			}
		case UPDATE_ORGANIZATION_COMMON_DATA_FAIL:
			return {
				...state,
				errorUpdate: action.payload.message,
				isLoadingUpdate: false,
			}

		default:
			return state
	}
}

export default crm
