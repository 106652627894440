import * as activitySectors from '../../locales/es/activity_sectors.json'

export const getActivitySectors = () => {
	const objectSectors = JSON.parse(JSON.stringify(activitySectors))
	return Object.entries(objectSectors.default).map((el, index) => {
		return {
			label: el[0],
			value: index + 1,
		}
	})
}

export const getSectorOptionsTranslated = (t) => {
	const sectors = getActivitySectors()
	return sectors.map(sector => {
		return {
			...sector,
			label: t(sector.label, { ns: 'activitySectors' }),
		}
	})
}

export const getSectorKeyValuePair = (t, sector) => {
	if (sector == null) return null
	const sectors = getActivitySectors()
	const sectorFound = sectors.find((currentSector) => currentSector.value === sector)
	return {
		...sectorFound,
		label: t(sectorFound.label, { ns: 'activitySectors' }),
	}
}
