export const getTextColor = type => {
	const dangerType = [
		'call_no_answer',
		'call_voice_message',
		'call_wrong_phone_number',
	]
	if (checkIfIn(type, dangerType)) {
		return false
	}
	return true
}

export const needsTraduction = type => {
	const dangerType = [
		'call_success',
		'call_no_answer',
		'call_voice_message',
		'call_wrong_phone_number',
	]
	if (checkIfIn(type, dangerType)) {
		return true
	}
	return false
}

const checkIfIn = (type, array) => {
	for (let i = 0; i < array.length; i++) {
		if (type == array[i]) {
			return true
		}
	}
	return false
}

export const getBoxIconByInteraction = type => {
	switch (type) {
		case 'call':
			return <i className="bx bxs-phone-call fs-3"></i>
		case 'email':
			return <i className="bx bxs-envelope fs-3"></i>
		case 'visit':
			return <i className="bx bx-briefcase fs-3"></i>
		case 'chat':
			return <i className="bx bx-message-rounded-dots fs-3"></i>
		case 'internal_note':
			return <i className='bx bx-note fs-3'></i>
		default:
			return <></>
	}
}
