import classNames from 'classnames'
import { useObjectState } from 'common/Hooks/UseObjectState'
import { getDateWithHour } from 'common/Utils/StringUtilities'
import FileInput from 'components/Common/FileInput'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import SimpleTable from 'components/Common/SimpleTable'
import TextInput from 'components/Common/TextInput'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Card, CardBody, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { getResolutionsImagesByIncidenceId, updateQualityVerification, uploadResolutionImage } from 'store/actions'

export const IncidencesModal = ({ t, isOpen, closeModal, incidences, partId, orderId, problems, solutions, updateQualityVerification, getResolutionsImagesByIncidenceId, resolutionsImages, uploadResolutionImage }) => {
	const incidencesHeader = ['date', 'quantity', 'incidence', 'proposed-solution']
	const observationHeader = ['observation']
	const resolutionDateHeader = ['Fecha de resolución']
	const imagesHeader = ['images']
	const INIT_STATE = {
		resolutionMessage: '',
		uploadImages: [],
		resolutionDate: '',
		partsSolved: 0,
	}
	const [incidencesToRender, setIncidencesToRender] = useState([])
	const [activeTab, setActiveTab] = useState('0')
	const { handleOnChange, objectData, setObjectData } = useObjectState(INIT_STATE)
	const [isSolved, setIsSolved] = useState(false)
	const [idToSave, setIdToSave] = useState(null)
	const [partQuantityList, setPartQuantityList] = useState([])


	const parseIncidences = () => {
		setIncidencesToRender(
			incidences.map(incidence => {
				return {
					id: incidence.id,
					date: getDateWithHour(incidence.creationDate),
					quantity: incidence.partsWrong,
					incidence: problems.find((problem) => problem.id == incidence.problem)?.name,
					solution: solutions.find((solution) => solution.id == incidence.solution)?.name,
					observation: incidence.observation,
					images: incidence.images,
					solved: incidence.solved,
					resolutionMessage: incidence.resolutionMessage,
					resolutionDate: incidence.resolutionDate,
					partsSolved: incidence.partsSolved,
				}
			}),
		)
	}
	const [imageFiles, setImageFiles] = useState()


	const idIncidenceToUpdate = incidencesToRender[activeTab]
	useEffect(() => {
		if (idIncidenceToUpdate) {
			const maxQuantity = idIncidenceToUpdate.quantity || 0
			const calculatedQuantityList = calculatePartQuantityList(maxQuantity)
			setPartQuantityList(calculatedQuantityList)
			getResolutionsImagesByIncidenceId({
				data: {
					incidenceId: idIncidenceToUpdate.id,
					partId: partId,
				}
			})
			setObjectData({
				resolutionMessage: idIncidenceToUpdate.resolutionMessage || '',
				resolutionDate: idIncidenceToUpdate.resolutionDate || '',
				partsSolved: idIncidenceToUpdate.partsSolved || 0,
			})
		}


	}, [idIncidenceToUpdate, setObjectData, incidencesToRender])



	const getIncidenceTableBody = incidence => {
		return (
			<tr key={`incidence_${incidence.id}`} valign="middle">
				<td>{incidence.date}</td>
				<td>{incidence.quantity}</td>
				<td>{t(incidence.incidence, { ns: 'quality' })}</td>
				<td>{t(incidence.solution, { ns: 'quality' })}</td>
			</tr>
		)
	}

	const getObservationTableBody = incidence => {
		return (
			<tr key={`observation_${incidence.id}`}>
				<td style={{ 'wordBreak': 'break-word' }}>{incidence.observation}</td>
			</tr>
		)
	}

	const getResolutionDateTableBody = incidence => {
		return (
			<tr key={`resolution_${incidence.id}`}>
				<td style={{ 'wordBreak': 'break-word' }}>{incidence.resolutionDate}</td>
			</tr>
		)
	}

	const getImagesTablebody = incidence => {
		return (
			<tr key={`images_${incidence.id}`}>
				<td>{incidence.images.map((image, i) => {
					return (
						<a key={`incidence-image-${i}`} href={image.src} target="_blank" rel="noopener noreferrer">
							<img className="verificationImage" key={`image_${image.name}`} src={image.src} />
						</a>
					)
				})}</td>
			</tr>
		)
	}


	useEffect(() => {
		if (incidences && incidences.length != 0) {
			parseIncidences()
		}

	}, [incidences])

	const handleSave = (id) => {
		const incidence = incidences.find(incidence => incidence.id === id)
		if (incidence && objectData.resolutionMessage) {
			const incidenceToUpdate = {
				data: {
					qualityVerification: {
						id: incidence.id,
						resolutionMessage: objectData.resolutionMessage,
						solved: isSolved,
						partsSolved: objectData.partsSolved,
						resolutionDate: new Date()
					},
				},
			}
			if (imageFiles != null) {
				const resolutionImageToUpload = {
					data: {
						incidenceId: incidence.id,
						partId: partId,
					},
					resolutionsImages: imageFiles,
				}
				uploadResolutionImage(resolutionImageToUpload)
			}
			updateQualityVerification(incidenceToUpdate)
		}
	}

	useEffect(() => {
		if (incidencesToRender.length > 0) {
			if (incidencesToRender[activeTab]) {
				const currentIdToSave = incidencesToRender[activeTab].id
				setIdToSave(currentIdToSave)
			}

		}

	}, [incidencesToRender, activeTab])

	useEffect(() => {
		if (incidencesToRender[activeTab]) {
			if (objectData.partsSolved >= incidencesToRender[activeTab].quantity) {
				setIsSolved(true)
			}
		}


	}, [objectData, activeTab, incidencesToRender])

	const calculatePartQuantityList = (maxQuantity) => {
		return [0, ...Array(maxQuantity).fill().map((_, index) => index + 1)]
	}

	return (
		<>
			<Modal
				className="modal-lg"
				isOpen={isOpen}
				closeModal={closeModal}
				buttons={[<button
					className={'btn ms-2 mt-2 btn-primary'}
					disabled={objectData.resolutionMessage === ''}
					onClick={() => handleSave(idToSave)}
					key={`save_incidence_${idToSave}`}
				>
					Guardar
				</button>]}
				title={`${t('incidences-history', { ns: 'naming' })} ${t('of-the-part', { ns: 'naming' })} ${partId} ${t('of-the-order', { ns: 'naming' })} ${orderId}`}
				body={
					<div className="px-4 py-2">
						<Card className="col-12 my-1">
							<CardBody>
								<ul className="nav nav-tabs nav-tabs-custom" role="tablist">
									{incidencesToRender.map((incidence, index) => {
										return (
											<NavItem key={`incidence_${index}`}>
												<NavLink
													key={`incidence_${index}`}
													className={classNames({
														active: activeTab === `${index}`,
													})}
													onClick={() => {
														setActiveTab(`${index}`)
													}}
												>
													{t('incidence', { ns: 'naming' })} {index + 1} {incidence.solved ? <i class='bx bxs-check-circle' style={{ color: "green" }}></i> : ''}
												</NavLink>
											</NavItem>
										)
									})}
								</ul>

								<TabContent activeTab={activeTab} className="p-3">
									{incidencesToRender.map((incidence, index) => {

										return (
											<TabPane key={`incidence_${index}`} tabId={`${index}`} id={`incidence_${index}`}>
												<div className="d-flex flex-column">
													<SimpleTable
														key={`incidence_${incidence.id}_table`}
														header={incidencesHeader}
														getTableBody={() => getIncidenceTableBody(incidence)}
													/>
													{incidence.observation && (
														<SimpleTable
															key={`observation_${incidence.id}_table`}
															header={observationHeader}
															getTableBody={() => getObservationTableBody(incidence)}
														/>
													)}
													{incidence.images && incidence.images.length > 0 && (
														<SimpleTable
															key={`images_${incidence.id}_table`}
															header={imagesHeader}
															getTableBody={() => getImagesTablebody(incidence)}
														/>
													)}

													<div className="mt-3">
														<h3>Resolución Incidencia {index + 1}</h3>
													</div>
													{incidence.resolutionDate && (
														<SimpleTable
															key={`resolution_${incidence.id}_table`}
															header={resolutionDateHeader}
															getTableBody={() => getResolutionDateTableBody(incidence)}
														/>
													)}
													<div className="mt-3">
														<TextInput
															title={t('Indica cómo se ha resuelto la incidencia', { ns: 'naming' })}
															value={objectData.resolutionMessage}
															setValue={handleOnChange}
															objectElement='resolutionMessage'
															enabled
														/>
														{resolutionsImages && resolutionsImages.length > 0 && (
															resolutionsImages.map((image, i) => (
																<a key={`resolution-image-${i}`} href={image.src} target="_blank" rel="noopener noreferrer">
																	<img className="verificationImage" src={image.src} alt={`Resolution ${i}`} />
																</a>
															))
														)}
														<FileInput
															className="w-50"
															value={objectData.uploadImages}
															objectElement="uploadImages"
															setValue={handleOnChange}
															setFiles={setImageFiles}
															title={t('images', { ns: 'naming' })}
															multiple
															accept="image/*"
														/>
														<div className='mt-4'>
															<OptionsInput
																className="w-50"
																value={objectData.partsSolved}
																objectElement="partsSolved"
																setValue={handleOnChange}
																automaticMappedValue
																title={t('partsSolved', { ns: 'naming' })}
																type="number"
																onBlur={value => {
																	if (value == '') handleOnChange('partsSolved', 0)
																}}
																options={partQuantityList}
															/>
														</div>



													</div>
												</div>
											</TabPane>
										)
									})}
								</TabContent>
							</CardBody>
						</Card>
					</div>
				}
			/>
		</>
	)
}

const mapStateToProps = state => {
	return {
		problems: state.Orders.qualityProblems,
		solutions: state.Orders.qualitySolutions,
		isLoading: state.Orders.isLoading,
		resolutionsImages: state.CloudStorage.resolutionsImages,
	}
}

IncidencesModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	getResolutionsImagesByIncidenceId: PropTypes.func,
	uploadResolutionImage: PropTypes.func,
	updateQualityVerification: PropTypes.func,
	incidences: PropTypes.array,
	partId: PropTypes.number,
	orderId: PropTypes.number,
	problems: PropTypes.array,
	solutions: PropTypes.array,
}

const mapDispatchToProps = {
	updateQualityVerification,
	getResolutionsImagesByIncidenceId,
	uploadResolutionImage,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IncidencesModal))
