import PropTypes from 'prop-types'
import { Component } from 'react'
import PartThumbnail from '../PartThumbnail'

class PartCard extends Component {

	getPartImageClassName() {
		if (this.props.bgColor && this.props.bgColor !== 'secondary') {
			return `d-flex justify-content-center p-2 bg-${this.props.bgColor}`
		}
		return 'd-flex justify-content-center p-2'
	}

	render() {
		return (
			<div className="part-card m-1">
				<div className={this.getPartImageClassName()}>
					<PartThumbnail propsImageUrl={this.props.img} propsStlUrl={this.props.stl} stlData={this.props.stlData} />
				</div>
				<ul className="list-unstyled m-2">
					<li>
						{this.props.partId && <strong className="title">{this.props.partId || ''}</strong>}
					</li>
					{this.props.fileName && <li>{this.props.fileName || ''}</li>}
					{this.props.materialColorFinish && <li>{this.props.materialColorFinish || ''}</li>}
					{this.props.technology && <li>{this.props.technology || ''}</li>}
					{this.props.quantities && this.props.quantities.length != 0 && <li>{this.props.quantities.map((quantity, index) => {
						return index == 0 ? `${quantity} ` : `- ${quantity} `
					})}</li>}
				</ul>
				{this.props.supplierName && <div className="m-2">{this.props.supplierName || ''}</div>}
			</div>
		)
	}
}

PartCard.propTypes = {
	img: PropTypes.string,
	partId: PropTypes.number,
	fileName: PropTypes.object,
	materialColorFinish: PropTypes.string,
	technology: PropTypes.string,
	supplierName: PropTypes.string,
	stl: PropTypes.string,
	stlData: PropTypes.object,
	bgColor: PropTypes.string,
	quantities: PropTypes.array,
}
export default PartCard
