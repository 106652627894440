import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPartConfigOptions } from 'store/actions'

export const useGetPartConfigOptions = () => {

	const {
		partConfigOptions,
		partConfigOptionsLoading,
		partConfigOptionsError,
	} = useSelector(state => state.Parts)

	const dispatch = useDispatch()

	useEffect(() => {
		if (
			(partConfigOptions == null || Object.keys(partConfigOptions).length == 0)
			&& !partConfigOptionsLoading) {
			dispatch(getPartConfigOptions())
		}
	}, [partConfigOptions])

	return {
		partConfigOptions,
		partConfigOptionsLoading,
		partConfigOptionsError,
	}

} 
