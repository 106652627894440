import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import OptionsButtons from 'components/Common/OptionsButtons'
import PartCard from 'components/Common/PartCard/PartCard'
import { PART_STATUS } from 'constants/partStatus'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import { updatePartsStatus } from 'store/parts/actions'

const EditStatusModal = ({
	isEditStatusModalOpen,
	closeEditStatusModal,
	selectedRows,
	readonlyParts,
	t,
	isLoading,
	error,
	updatePartsStatus,
	openEditBindingNoteModal,
	suppliersSelected,
	setSuppliersSelected,
}) => {
	const [statusSelected, setStatusSelected] = useState(10)
	const [hasBeenPressed, setHasBeenPressed] = useState(false)

	const [isModalMessageOpen, setIsModalMessageOpen] = useState(false)
	const [message, setMessage] = useState('')

	const STATUS_OPTIONS = [{
		value: 10,
		text: t('Nueva', { ns: 'naming' }),
		color: 'secondary',
	},
	{
		value: 91,
		text: t('Rechazada', { ns: 'naming' }),
		color: 'danger',
	},
	{
		value: 92,
		text: t('Baja', { ns: 'naming' }),
		color: 'danger',
	},

	]

	useEffect(() => {
		if (error === false) {
			setMessage(t('update_part_status_success', { ns: 'naming' }))
		}
		if (error === true) {
			setMessage(t('update_part_status_error', { ns: 'naming' }))
		}
	}, [error])

	useEffect(() => {
		if (message !== '') {
			setIsModalMessageOpen(true)
		}
	}, [message])

	const getSuppliers = () => {
		const supplierArray = []
		selectedRows.forEach(row => {
			row.costsAndMargins.suppliers.forEach(supplier => {
				if (!supplierArray.find((supp) => supp.value === supplier.id) && supplier.id !== -1) {
					supplierArray.push({ label: supplier.name, value: supplier.id })
				}
			})
		})
		return supplierArray
	}

	const supplierOptions = getSuppliers()

	const getColorByValue = () => {
		return STATUS_OPTIONS.find(option => option.value === statusSelected).color
	}

	const statusSelectedIsNew = statusSelected === 10
	const showWarningMessage = statusSelectedIsNew && suppliersSelected.length === 0 && hasBeenPressed

	const handleOnChangeSuppliers = (value) => {
		setSuppliersSelected(value.map(supplier => supplier.value))
	}

	const [dontRefreshAfterModal, setDontRefreshAfterModal] = useState(false)

	const saveStatus = () => {
		if (statusSelectedIsNew && suppliersSelected.length === 0) {
			setHasBeenPressed(true)
			return
		}
		const partIds = selectedRows.map(row => row.id.value)
		if (statusSelected === PART_STATUS.REJECTED) {
			setDontRefreshAfterModal(true)
			openEditBindingNoteModal({ rejectPartsAfterEditingBindingNote: true })
		} else {
			const newPartsStatus = partIds.map(partId => {
				const selectedRow = selectedRows.find(row => row.id.value === partId)
				const suppliers = selectedRow.costsAndMargins.suppliers.filter(supplier => !suppliersSelected.includes(supplier.id))
				if (suppliers.length === 0) return { partId, statusId: statusSelected }
				const maxStatus = Math.max(...suppliers.map(supplier => supplier.statusId))
				return { partId, statusId: maxStatus }
			})
			updatePartsStatus(partIds, statusSelected, suppliersSelected, false, statusSelected === PART_STATUS.NEW && newPartsStatus)
		}
	}

	useEffect(() => {
		setHasBeenPressed(false)
		setSuppliersSelected([])
	}, [statusSelected])

	const closeModalMessage = () => {
		location.reload()
	}

	const mapParts = () => {
		return selectedRows.map(row => {
			return (

				<div key={row.id.value}>
					<PartCard
						key={row.id.value}
						img={row.fileLinks.thumbnail}
						stl={row.fileLinks.stlModel}
						stlData={{
							partId: row.id.value,
							partName: row.name,
							size: row.size,
							weight: row.weight,
						}}
						partId={row.id.value}
						fileName={row.name}
						bgColor={getColorByValue()}
					/>
				</div>
			)
		})
	}

	return <div>

		<Modal
			isOpen={isEditStatusModalOpen}
			closeModal={closeEditStatusModal}
			title={t('editStatus', { ns: 'naming' })}
			className={selectedRows?.length > 3 ? 'modal-lg' : ''}
			body={
				<div>
					{selectedRows?.length > 0 ?
						<div className="d-flex flex-column p-2">
							<div className="d-flex flex-row flex-wrap justify-content-center pb-3 border-bottom border-grey ">{mapParts()}</div>
							{statusSelectedIsNew && <div className="d-felx flex-column justify-content-start mt-4">
								<h5 className="ms-3">
									{t('select_suppliers', { ns: 'naming' })}
								</h5>
								<div className=" mx-3">
									<Select
										isMulti
										onChange={e => handleOnChangeSuppliers(e)}
										options={supplierOptions}
									/>
									{showWarningMessage &&
										<small><strong className="text-red ms-1">{t('at_least_one_supplier', { ns: 'naming' })}</strong></small>
									}
								</div>

							</div>}
							<div className="d-flex flex-column my-4">
								<div className="d-flex flex-row justify-content-center mt-2 mb-4"><OptionsButtons options={STATUS_OPTIONS} handleOnChange={setStatusSelected} valueSelected={statusSelected} /></div>
							</div>

						</div> : <div className="p-4"><h3>{t('at_least_one_part', { ns: 'naming' })}</h3></div>
					}
				</div>
			}

			buttons={[
				<button type="button" key="btnSaveStatus" disabled={selectedRows?.length === 0} className="btn btn-primary" onClick={() => saveStatus()} >
					{isLoading ? <Loader size="mini" /> :
						t('save', { ns: 'naming' })}
				</button>,
			]
			}
		/>
		<ModalMessage isModalOpen={isModalMessageOpen} closeModal={() => {
			if (!dontRefreshAfterModal) {
				closeModalMessage()
			} else {
				setDontRefreshAfterModal(false)
				setMessage('')
				setIsModalMessageOpen(false)
			}
		}} message={message} />
	</div>

}

const mapStateToProps = state => {
	return {
		isLoading: state.Parts.isLoading,
		error: state.Parts.errorUpdateStatus,

	}
}

EditStatusModal.propTypes = {
	isEditStatusModalOpen: PropTypes.bool,
	closeEditStatusModal: PropTypes.func,
	selectedRows: PropTypes.array,
	readonlyParts: PropTypes.array,
	hasAllSelectedRowsHaveAValidSupplier: PropTypes.bool,
	saveNewStatus: PropTypes.func,
	t: PropTypes.func,
	updatePartsStatus: PropTypes.func,
	isLoading: PropTypes.bool,
	error: PropTypes.object,
	openEditBindingNoteModal: PropTypes.func,
	suppliersSelected: PropTypes.array,
	setSuppliersSelected: PropTypes.func,
}

export default connect(mapStateToProps, { updatePartsStatus })(withTranslation()(EditStatusModal))
