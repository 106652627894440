import classNames from 'classnames'
import { IconTooltip } from 'components/Common/IconTooltip'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import SimpleTable from 'components/Common/SimpleTable'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { Card, CardBody, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import { deleteCompleteProductionFile, getCompleteProductionFiles, uploadCompleteProductionFiles } from 'store/actions'

export const ProductionFilesModal = ({
	t,
	productionId,
	isOpen,
	close,
	getCompleteProductionFiles,
	isLoading,
	files,
	uploadCompleteProductionFiles,
	uploadFilesSuccess,
	deleteCompleteProductionFile,
	deleteFilesSuccess
}) => {

	const [activeTab, setActiveTab] = useState('1')
	const [invoice, setInvoice] = useState()
	const [photos, setPhotos] = useState([])

	const retrieveFiles = () => {
		const splitId = productionId.split('-')
		getCompleteProductionFiles({
			data: {
				productionIds: [{
					orderId: Number(splitId[0]),
					orderFragmentId: Number(splitId[1]),
				}],
			},
		})
	}

	useEffect(() => {
		if (productionId) {
			retrieveFiles()
		}
	}, [productionId])

	useEffect(() => {
		if (uploadFilesSuccess) {
			retrieveFiles()
		}
	}, [uploadFilesSuccess])

	useEffect(() => {
		if (deleteFilesSuccess) {
			retrieveFiles()
		}
	}, [deleteFilesSuccess])

	useEffect(() => {
		if (files != null && files.length != 0 && files[0].fileUrls != null && files[0].fileUrls.length != 0) {
			setInvoice(files[0].fileUrls.invoice)
			setPhotos(files[0].fileUrls.photos)
		}
	}, [files])

	const openInvoiceInput = () => {
		const invoiceInput = document.getElementById('invoiceInput')
		if (invoiceInput) invoiceInput.click()
	}

	const uploadInvoice = (invoiceFile) => {
		if (invoiceFile) {
			const splitId = productionId.split('-')
			const data = {
				orderId: Number(splitId[0]),
				orderFragmentId: Number(splitId[1]),
			}
			const files = {
				invoice: invoiceFile[0],
			}
			uploadCompleteProductionFiles({ data, files })
		}
	}

	const openPhotosInput = () => {
		const photosInput = document.getElementById('photosInput')
		if (photosInput) photosInput.click()
	}

	const uploadPhotos = (photosFiles) => {
		if (photosFiles) {
			const splitId = productionId.split('-')
			const data = {
				orderId: Number(splitId[0]),
				orderFragmentId: Number(splitId[1]),
			}
			const files = {
				photos: photosFiles,
			}
			uploadCompleteProductionFiles({ data, files })
		}
	}

	const deleteFile = (filename) => {
		const splitId = productionId.split('-')
		deleteCompleteProductionFile({
			orderId: Number(splitId[0]),
			orderFragmentId: Number(splitId[1]),
			filename
		})
	}

	const invoiceHeader = ['invoice', 'file', 'actions']
	const photosHeader = ['file', 'photo', 'actions']

	const getInvoiceTableBody = () => {
		const body = []

		if (invoice) {
			body.push(<tr key={'production-invoice'} valign='middle'>
				<td style={{ 'width': '60%' }}>{invoice.name}</td>
				<td style={{ 'width': '20%' }}>{invoice ? <a key={'production-invoice'} href={invoice.src} target='_blank' rel='noopener noreferrer'>
					<i className='bx bxs-file-blank fs-2 cursor-pointer'></i>
				</a> : ''}</td>
				<td style={{ 'width': '20%' }}>
					<button type='button' className='btn btn-danger' onClick={() => deleteFile(invoice.name)}>
						<IconTooltip icon='bx bx-x fs-4 mt-1' message={t('delete', { ns: 'naming' })} key='deleteButton' name='deleteButtonText' />
					</button>
				</td>
			</tr>)
		} else {
			body.push(<tr key={'production-invoice'} valign='middle'>
				<td style={{ 'width': '60%' }}></td>
				<td style={{ 'width': '20%' }}></td>
				<td style={{ 'width': '20%' }}>
					<button type='button' className='btn btn-secondary' onClick={() => openInvoiceInput()}>
						<IconTooltip icon='bx bx-plus fs-4 mt-1' message={t('add-invoice', { ns: 'naming' })} key='addInvoiceButton' name='addInvoiceButtonText' />
					</button>
					<input
						onChange={(e) => uploadInvoice(e.target.files)}
						type={'file'}
						id='invoiceInput'
						accept={'.pdf'}
						hidden
					></input>
				</td>
			</tr>)
		}
		return body
	}

	const getPhotosTableBody = () => {
		const body = []

		if (photos && photos.length != 0) {
			body.push(...photos.map((photo, i) => {
				return <tr key={`production-photo-${i}`} valign='middle'>
					<td style={{ 'width': '30%' }}>{photo.name}</td>
					<td style={{ 'width': '60%', 'textAlign': 'center' }}>
						<a key={`production-photo-${i}`} href={photo.src} target='_blank' rel='noopener noreferrer'>
							<img className='productionFilesImage' key={`photo_${photo.name}`} src={photo.src} />
						</a>
					</td>
					<td style={{ 'width': '10%' }}>
						<button type='button' className='btn btn-danger' onClick={() => deleteFile(photo.name)}>
							<IconTooltip icon='bx bx-x fs-4 mt-1' message={t('delete', { ns: 'naming' })} key='deleteButton' name='deleteButtonText' />
						</button>
					</td>
				</tr>
			}))
		}
		body.push(<tr key={`production-photo`} valign='middle'>
			<td style={{ 'width': '30%' }}></td>
			<td style={{ 'width': '60%', 'textAlign': 'center' }}></td>
			<td style={{ 'width': '10%' }}>
				<button type='button' className='btn btn-secondary' onClick={() => openPhotosInput()}>
					<IconTooltip icon='bx bx-plus fs-4 mt-1' message={t('add-photo', { ns: 'naming' })} key='addPhotoButton' name='addPhotoButtonText' />
				</button>
				<input
					onChange={(e) => uploadPhotos(e.target.files)}
					type={'file'}
					id='photosInput'
					accept={'.png, .jpg'}
					multiple
					hidden
				></input>
			</td>
		</tr>
		)

		return body
	}

	return (
		<Modal
			title={`${t('production', { ns: 'naming' })} ${productionId} - ${t('files', { ns: 'naming' })}`}
			isOpen={isOpen}
			closeModal={() => close()}
			className='modal-lg'
			body={
				isLoading ? <Loader className='fs-2 mx-auto' /> : <div className='m-2 d-flex flex-row align-items-center'>
					{!files || files?.fileUrls?.length == 0 ? <div>{t('no-files-to-show', { ns: 'naming' })}</div> : (
						<div className='w-100 d-flex flex-column'>
							< Card className='col-12 mt-1 mb-4' >
								<CardBody>
									<ul className='nav nav-tabs nav-tabs-custom' role='tablist'>
										<NavItem>
											<NavLink
												className={classNames({
													active: activeTab === '1',
												})}
												onClick={() => {
													setActiveTab('1')
												}}
											>
												{t('invoice', { ns: 'naming' })}
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												className={classNames({
													active: activeTab === '2',
												})}
												onClick={() => {
													setActiveTab('2')
												}}
											>
												{t('photos', { ns: 'naming' })}
											</NavLink>
										</NavItem>
									</ul>

									<TabContent activeTab={activeTab} className='p-3'>
										<TabPane tabId='1' id='all-order'>
											<SimpleTable
												key={`production-invoice-${productionId}`}
												header={invoiceHeader}
												getTableBody={() => getInvoiceTableBody()}
											/>
										</TabPane>
										<TabPane tabId='2' id='processing'>
											<SimpleTable
												key={`production-photos-${productionId}`}
												header={photosHeader}
												getTableBody={() => getPhotosTableBody()}
											/>
										</TabPane>
									</TabContent>
								</CardBody>
							</Card >
						</div>
					)}</div>
			}
		/>
	)
}

ProductionFilesModal.propTypes = {
	t: PropTypes.func,
	productionId: PropTypes.number,
	isOpen: PropTypes.bool,
	close: PropTypes.func,
	getCompleteProductionFiles: PropTypes.func,
	isLoading: PropTypes.bool,
	files: PropTypes.array,
	uploadCompleteProductionFiles: PropTypes.func,
	uploadFilesSuccess: PropTypes.bool,
	deleteCompleteProductionFile: PropTypes.func,
	deleteFilesSuccess: PropTypes.bool
}

const mapStateToProps = (state) => ({
	isLoading: state.CloudStorage.isLoading,
	files: state.CloudStorage.productionFiles,
	uploadFilesSuccess: state.CloudStorage.uploadCompleteProductionFilesSuccess,
	deleteFilesSuccess: state.CloudStorage.deleteCompleteProductionFilesSuccess
})

export default withRouter(connect(mapStateToProps, { getCompleteProductionFiles, uploadCompleteProductionFiles, deleteCompleteProductionFile })(withTranslation()(ProductionFilesModal)))
