import { roundCurrency } from 'common/Utils/NumberUtilities'
import { getDate } from 'common/Utils/StringUtilities'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import TooltipMessage from 'components/Common/TooltipMessage'
import { Table2 } from 'components/Table/Table2'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { Flag } from 'semantic-ui-react'
import { getBillingList } from '../../store/actions'

export const BillingList = ({
	t,
	getBillingList,
	billingList,
	count,
	isLoading,
}) => {

	const [page, setPage] = useState(1)
	const [pageSize, _setPageSize] = useState(10)
	const [dateRange, setDateRange] = useState(null)

	const breadcrumbElement = (
		<Breadcrumbs
			title={t('management', { ns: 'naming' })}
			breadcrumbItems={[
				{
					item: t('billing', { ns: 'naming' }),
					link: '/billing',
				},
			]}
		/>
	)

	const reload = () => {
		if (count) {
			const maxPage = pageSize ? Math.ceil(count / pageSize) : 1
			if (page > maxPage) {
				setPage(maxPage)
				return
			}
		}
		getBillingList({ page, pageSize, dateRange })
	}

	const setPageSize = (pageSize) => {
		if (pageSize == null) reload()
		_setPageSize(pageSize)
	}

	useEffect(() => {
		reload()
	}, [page, pageSize, dateRange?.startDate, dateRange?.endDate, count])

	const handleOnChangeDates = dateStr => {
		const SEPARATOR = 'to'
		const DEFAULT_INITIAL_DATE_HOUR = '00:00:00'
		const DEFAULT_END_DATE_HOUR = '23:59:59'
		if (!dateStr.includes(SEPARATOR)) setDateRange(null)
		const [startDate, endDate] = dateStr.split(SEPARATOR).map(date => date.trim())
		if (!startDate || !endDate) return
		setDateRange({
			startDate: `${startDate} ${DEFAULT_INITIAL_DATE_HOUR}`,
			endDate: `${endDate} ${DEFAULT_END_DATE_HOUR}`,
		})
	}

	return (
		<div className="page-content mb-4">
			<MetaTags>
				<title>Proto&Go! | {t('billing', { ns: 'naming' })}</title>
			</MetaTags>
			{breadcrumbElement}
			{isLoading ? (
				<Loader className="font-size-80 mx-auto" />
			) : (
				<div className="bg-white my-4 p-4 marginFooter">
					<div className="py-2">
						<div className="d-flex align-items-center">
							<div className="ms-1">
								<Flatpickr
									options={{
										mode: 'range',
										dateFormat: 'Y-m-d',
										wrap: true,
									}}
									onChange={(e, dateStr) => handleOnChangeDates(dateStr)}
								>
									<div className="flatpickr" data-input>
										<input type="text" className="d-none" placeholder="Select Date.." />
										<a className="input-button d-flex align-items-center" title="toggle" data-toggle>
											<i className="bx bx-calendar fs-3 text-secondary"></i>
										</a>
									</div>
								</Flatpickr>
							</div>
						</div>
						{dateRange && (
							<div className="d-flex justify-content-center align-items-center">
								<span className="me-1">{`${getDate(dateRange.startDate)} - ${getDate(dateRange.endDate)}`}</span>
								<div
									onClick={() => setDateRange(null)}
									className="btn btn-outline-danger p-0 m-0 d-flex align-items-center h-auto"
								>
									<i className='m-0 p-0 bx bx-x' />
								</div>
							</div>
						)}
						<Table2
							t={t}
							headers={{
								id: 'ID',
								customer: t('client', { ns: 'naming' }),
								country: t('country', { ns: 'naming' }),
								orderDate: t('orderDate', { ns: 'naming' }),
								paymentMethodId: t('paymentMethod', { ns: 'naming' }),
								amountEuro: t('totalPrice', { ns: 'naming' }),
								logisticsCostEuro: t('logistics', { ns: 'naming' }),
								productionCostEuro: t('production', { ns: 'naming' }),
								marginEuro: t('margin_value', { ns: 'naming' }),
								manager: t('manager', { ns: 'naming' }),
							}}
							rows={billingList.map(billingItem => ({
								id: billingItem.id,
								customer: billingItem.customer,
								country: (
									<span>
										<TooltipMessage
											message={t(billingItem.country?.toLowerCase(), { ns: 'countries' })}
											onClick={() => this.openEditCustomerModal()}
										>
											<Flag id="flagTooltip" name={billingItem.country?.toLowerCase()} />
										</TooltipMessage>
									</span>
								),
								orderDate: getDate(billingItem.orderDate), // TODO formato de fecha no cambia con el idioma
								paymentMethodId: t(`payment_method_${billingItem.paymentMethodId}_name`, { ns: 'paymentMethods' }),
								amountEuro: `${roundCurrency(billingItem.amountEuro)}€`,
								logisticsCostEuro: `${roundCurrency(billingItem.logisticsCostEuro)}€`,
								productionCostEuro: `${roundCurrency(billingItem.productionCostEuro)}€`,
								marginEuro: `${roundCurrency(billingItem.marginEuro)}€`,
								manager: billingItem.managerName,
							}))}
							rowsExport={[
								...billingList.map(billingItem => ({
									id: billingItem.id,
									customer: billingItem.customer,
									country: t(billingItem.country?.toLowerCase(), { ns: 'countries' }),
									orderDate: getDate(billingItem.orderDate), // TODO formato de fecha no cambia con el idioma
									paymentMethodId: t(`payment_method_${billingItem.paymentMethodId}_name`, { ns: 'paymentMethods' }),
									amountEuro: `${roundCurrency(billingItem.amountEuro)}€`,
									logisticsCostEuro: `${roundCurrency(billingItem.logisticsCostEuro)}€`,
									productionCostEuro: `${roundCurrency(billingItem.productionCostEuro)}€`,
									marginEuro: `${roundCurrency(billingItem.marginEuro)}€`,
									manager: billingItem.managerName,
								})),
								{
									amountEuro: `${roundCurrency(billingList.reduce((prev, curr) => prev += curr.amountEuro || 0, 0))}€`,
									logisticsCostEuro: `${roundCurrency(billingList.reduce((prev, curr) => prev += curr.logisticsCostEuro || 0, 0))}€`,
									productionCostEuro: `${roundCurrency(billingList.reduce((prev, curr) => prev += curr.productionCostEuro || 0, 0))}€`,
									marginEuro: `${roundCurrency(billingList.reduce((prev, curr) => prev += curr.marginEuro || 0, 0))}€`,
								},
							]}
							currentPage={page}
							currentPageSize={pageSize}
							setPage={setPage}
							setPageSize={setPageSize}
							totalCount={count}
							exportFileName="billingList"
							showExportButton
						/>
					</div>
				</div>
			)}
		</div>
	)
}

BillingList.propTypes = {
	t: PropTypes.func,
	getBillingList: PropTypes.func,
	billingList: PropTypes.array,
	count: PropTypes.number,
	isLoading: PropTypes.bool,
}

const mapStateToProps = state => {
	return {
		billingList: state.Orders.billingList,
		count: state.Orders.count,
		isLoading: state.Orders.isLoadingBillingList,
	}
}

export default connect(mapStateToProps, {
	getBillingList,
})(withTranslation()(BillingList))
