import {
	CREATE_PARTS_QUANTITIES,
	CREATE_PARTS_QUANTITIES_FAIL,
	CREATE_PARTS_QUANTITIES_SUCCESS,
	DELETE_BINDING_NOTES,
	DELETE_BINDING_NOTES_FAIL,
	DELETE_BINDING_NOTES_SUCCESS,
	GET_FILTERED_PARTS,
	GET_FILTERED_PARTS_FAIL,
	GET_FILTERED_PARTS_SUCCESS,
	GET_PART_CONFIG_OPTIONS,
	GET_PART_CONFIG_OPTIONS_FAIL,
	GET_PART_CONFIG_OPTIONS_SUCCESS,
	GET_PARTS_PREDICTS_AND_REAL,
	GET_PARTS_PREDICTS_AND_REAL_FAIL,
	GET_PARTS_PREDICTS_AND_REAL_SUCCESS,
	GET_SIMILAR_PARTS_DETAIL,
	GET_SIMILAR_PARTS_DETAIL_FAIL,
	GET_SIMILAR_PARTS_DETAIL_SUCCESS,
	GET_TECHNOLOGIES_DASHBOARD,
	GET_TECHNOLOGIES_DASHBOARD_FAIL,
	GET_TECHNOLOGIES_DASHBOARD_SUCCESS,
	UPDATE_BINDING_NOTES,
	UPDATE_BINDING_NOTES_FAIL,
	UPDATE_BINDING_NOTES_SUCCESS,
	UPDATE_PARTS,
	UPDATE_PARTS_FAIL,
	UPDATE_PARTS_STATUS,
	UPDATE_PARTS_STATUS_FAIL,
	UPDATE_PARTS_STATUS_SUCCESS,
	UPDATE_PARTS_SUCCESS,
} from './actionTypes'

export const getPartsPredictsAndReal = () => ({
	type: GET_PARTS_PREDICTS_AND_REAL,
})

export const getPartsPredictsAndRealSuccess = (response) => ({
	type: GET_PARTS_PREDICTS_AND_REAL_SUCCESS,
	payload: response,
})

export const getPartsPredictsAndRealFail = (error) => ({
	type: GET_PARTS_PREDICTS_AND_REAL_FAIL,
	payload: error,
})

export const updatePartsStatus = (partIds, statusId, suppliers, editingWithinBindingNoteModal, newPartsStatus) => ({
	type: UPDATE_PARTS_STATUS,
	payload: { partIds, statusId, suppliers, editingWithinBindingNoteModal, newPartsStatus },
})
export const updatePartsStatusSuccess = (response, editingWithinBindingNoteModal) => ({
	type: UPDATE_PARTS_STATUS_SUCCESS,
	payload: { response, editingWithinBindingNoteModal },
})
export const updatePartsStatusFail = (error, editingWithinBindingNoteModal) => ({
	type: UPDATE_PARTS_STATUS_FAIL,
	payload: { error, editingWithinBindingNoteModal },
})

export const createPartsQuantities = ({ partIds, quantities }) => ({
	type: CREATE_PARTS_QUANTITIES,
	payload: { partIds, quantities },
})
export const createPartsQuantitiesSuccess = (response) => ({
	type: CREATE_PARTS_QUANTITIES_SUCCESS,
	payload: { response },
})
export const createPartsQuantitiesFail = (error) => ({
	type: CREATE_PARTS_QUANTITIES_FAIL,
	payload: { error },
})

export const getPartConfigOptions = payload => ({
	type: GET_PART_CONFIG_OPTIONS,
	payload: payload,
})
export const getPartConfigOptionsSuccess = payload => ({
	type: GET_PART_CONFIG_OPTIONS_SUCCESS,
	payload: payload,
})
export const getPartConfigOptionsFail = payload => ({
	type: GET_PART_CONFIG_OPTIONS_FAIL,
	payload: payload,
})

export const updateParts = (payload, context) => ({
	type: UPDATE_PARTS,
	payload: payload,
	context: context,
})
export const updatePartsSuccess = (payload, context) => ({
	type: UPDATE_PARTS_SUCCESS,
	payload: payload,
	context: context,
})
export const updatePartsFail = (payload, context) => ({
	type: UPDATE_PARTS_FAIL,
	payload: payload,
	context: context,
})

export const getFiltredParts = (input, signal) => ({
	type: GET_FILTERED_PARTS,
	payload: { input, signal },
})
export const getFiltredPartsSuccess = (response) => ({
	type: GET_FILTERED_PARTS_SUCCESS,
	payload: response,
})
export const getFiltredPartsFail = (error) => ({
	type: GET_FILTERED_PARTS_FAIL,
	payload: { error },
})

export const getSimilarPartsDetail = (partIds) => ({
	type: GET_SIMILAR_PARTS_DETAIL,
	payload: { partIds },
})
export const getSimilarPartsDetailSuccess = (response) => ({
	type: GET_SIMILAR_PARTS_DETAIL_SUCCESS,
	payload: response,
})
export const getSimilarPartsDetailFail = (error) => ({
	type: GET_SIMILAR_PARTS_DETAIL_FAIL,
	payload: { error },
})

export const updateBindingNotes = ({ partIds, bindingNoteTypesIds, comment }) => ({
	type: UPDATE_BINDING_NOTES,
	payload: { partIds, bindingNoteTypesIds, comment },
})
export const updateBindingNotesSuccess = (response) => ({
	type: UPDATE_BINDING_NOTES_SUCCESS,
	payload: { response },
})
export const updateBindingNotesFail = (error) => ({
	type: UPDATE_BINDING_NOTES_FAIL,
	payload: error,
})

export const deleteBindingNotes = ({ partIds }) => ({
	type: DELETE_BINDING_NOTES,
	payload: { partIds },
})
export const deleteBindingNotesSuccess = (response) => ({
	type: DELETE_BINDING_NOTES_SUCCESS,
	payload: { response },
})
export const deleteBindingNotesFail = (error) => ({
	type: DELETE_BINDING_NOTES_FAIL,
	payload: error,
})

export const getTechnologiesDashboard = (data) => ({
	type: GET_TECHNOLOGIES_DASHBOARD,
	payload: { data },
})

export const getTechnologiesDashboardSuccess = (response) => ({
	type: GET_TECHNOLOGIES_DASHBOARD_SUCCESS,
	payload: response,
})

export const getTechnologiesDashboardFail = (error) => ({
	type: GET_TECHNOLOGIES_DASHBOARD_FAIL,
	payload: error,
})
