import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { deleteModifiedBlueprints } from 'store/actions'

export const DeleteModifiedBlueprintsModal = (props) => {

	const {
		t,
		parts: partsSelected,
		deleteModifiedBlueprints,
		isLoading,
		isDeleteBlueprintsCompleted,
		isOpenModal,
		closeModal,
	} = props

	useEffect(() => {
		if (isDeleteBlueprintsCompleted) {
			location.reload()
		}
	}, [isDeleteBlueprintsCompleted])

	return (
		<Modal
			title={t('deleteBlueprint', { ns: 'naming' })}
			size='lg'
			isOpen={isOpenModal}
			closeModal={closeModal}
			body={
				<div className='p-4'>
					{partsSelected?.length === 0 ? (
						// Texto "Selecciona al menos una pieza"
						<div>{t('select_at_least_one', { ns: 'naming' })}</div>
					) : (
						<div>
							{/* Texto "Estás seguro de que quiere eliminar?" */}
							{t('sure_delete_modified_blueprints', { ns: 'naming' })}
							<div className='d-flex justify-content-center'>
								<div className="row mt-2 mb-2 mx-4" style={{ width: '100%' }}>
									{/* Mostrar una carta de información por cada pieza: */}
									{partsSelected?.map((part, index) => (
										<div
											key={index}
											style={{ marginBottom: '60px' }}
											className="col-md-3"										>
											<div
												className="mt-2"
												style={{ border: '1.5px solid lightgray', borderRadius: '5px', height: '100%' }}											>
												{/* Imagen de la pieza: */}
												<div
													style={{ flex: 1 }}
													className="d-flex justify-content-center align-items-center bg-gray p-3"												>
													<img src={part.imageUrl} alt={part.name} width="80" height="80" />
												</div>
												{/* Información: */}
												<div className="d-flex flex-column p-3">
													{/* ID de la pieza: */}
													<h5>{part.id.value}</h5>
													{/* Nombre de la pieza: */}
													<p style={{ wordBreak: 'break-all', color: 'gray' }}>
														{part.name}.stp
													</p>
													{/* Nombre del PDF modificado de la pieza (si tiene uno) */}
													{part?.blueprints[1]?.name && (
														<p style={{ wordBreak: 'break-all', color: 'gray' }}>
															<i
																className="mdi mdi-file-pdf-outline align-middle me-2"
																style={{ color: 'red' }}
															/>
															{part?.blueprints[1]?.name}
														</p>
													)}
												</div>
											</div>
										</div>
									))}
								</div>
							</div>

						</div>
					)}
				</div>
			}
			buttons={[(
				<button
					disabled={partsSelected.length === 0}
					className='btn btn-primary'
					onClick={() => deleteModifiedBlueprints({
						data: {
							partIds: partsSelected?.map(part => part.id.value),
						},
					})}
					key={'delete_blueprint'}
				>
					{isLoading ? <Loader /> : t('delete', { ns: 'naming' })}
				</button>
			)]}
		/>
	)
}

DeleteModifiedBlueprintsModal.propTypes = {
	// from connect HOC - mapStateToProps:
	isLoading: PropTypes.bool,
	isDeleteBlueprintsCompleted: PropTypes.bool,

	// from connect HOC - mapDispatchToProps:
	deleteModifiedBlueprints: PropTypes.func,

	// from withTranslation HOC:
	t: PropTypes.func,

	// from parent component:
	parts: PropTypes.array,
	isOpenModal: PropTypes.bool,
	closeModal: PropTypes.func,
}

const mapStateToProps = (state) => ({
	isLoading: state.CloudStorage.isLoading,
	isDeleteBlueprintsCompleted: state.CloudStorage.isDeleteBlueprintsCompleted,
})

const mapDispatchToProps = {
	deleteModifiedBlueprints,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(
	withTranslation()(
		DeleteModifiedBlueprintsModal,
	),
)
