/**
 * Escaneamos piezas en: Expedicion, Validacion, Recepcion.
 * 
 * Para escanear las piezas con la pistola utilizamos el hook useScanDetection() de 'use-scan-detection'
 * El string que devuelve tiene el siguiente formato:
 * <orderId>ShiftS<orderFragmentId>'<partId>'<quantity>
 * 
 * Para escanear las piezas manualmente (sin la pistola) utilizamos un input y un boton
 * El string que esperamos tiene el siguiente formato:
 * <orderId>S<orderFragmentId>-<partId>-<quantity>
 */
export const getScanPartData = (code) => {

	code = code.replaceAll('ShiftS', '|') // reemplazar ShiftS por separador comun
	code = code.replaceAll('\'', '|') // reemplazar comillas por separador comun

	code = code.replaceAll('S', '|') // reemplazar S por separador comun
	code = code.replaceAll('-', '|') // reemplazar guiones por separador comun

	const sections = code.split('|') // utilizar separador comun para obtener los datos

	const orderId = Number(sections[0])
	const orderFragmentId = Number(sections[1]) // no lo utilizamos
	const partId = Number(sections[2])
	const quantity = Number(sections[3])

	return { orderId, orderFragmentId, partId, quantity }
}
