import { isEven } from 'common/Utils/NumberUtilities'
import { getAdaptedDate } from 'common/Utils/StringUtilities'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const InteractionsTable = ({ t, interactions }) => {
	interactions.sort((a, b) => {
		return getAdaptedDate(a.date) - getAdaptedDate(b.date)
	})

	return (
		<table style={{ width: '100%' }} className="border-collapse">
			<tr>
				<th style={{ width: '15%' }} className="p-2">{t('manager', { ns: 'naming' })}</th>
				<th style={{ width: '15%' }} className="p-2">{t('subject', { ns: 'naming' })}</th>
				<th style={{ width: '15%' }} className="p-2">{t('interactionType', { ns: 'naming' })}</th>
				<th style={{ width: '40%' }} className="p-2">{t('message', { ns: 'naming' })}</th>
				<th style={{ width: '15%' }} className="p-2">{t('date', { ns: 'naming' })}</th>
			</tr>
			{interactions.map((interaction, i) => {
				return <tr key={`internalComment_${i}`} className={isEven(i) && 'bg-light-gray'}>
					<td className="border border-top-bottom p-2">{interaction.managerFullName}</td>
					<td className="border border-top-bottom p-2">{interaction.subject}</td>
					<td className="border border-top-bottom p-2">{interaction.interactionType}</td>
					<td className="border border-top-bottom p-2">{interaction.message}</td>
					<td className="border border-top-bottom p-2">{interaction.date}</td>
				</tr>
			})}
		</table>
	)
}

InteractionsTable.propTypes = {
	t: PropTypes.func,
	interactions: PropTypes.array,
}
export default withTranslation()(InteractionsTable)
