import { getStatusClassName } from 'common/Utils/StringUtilities'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import { OrderStatus } from 'constants/orderStatus'
import { PAYMENT_METHOD } from 'constants/paymentMethods'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import ReactTooltip from 'react-tooltip'
import { handleCancelledOrder, handleSuccessfulOrder, updateOrder } from 'store/actions'

const OrderActions = ({
	t,
	history,
	orderId,
	paymentDate,
	paymentMethod,
	orderStatus,
	orderItems,
	updateOrder,
	handleSuccessfulOrder,
	handleCancelledOrder,
	isLoadingStatus,
	isLoadingPaymentDate,
	isOrderHandledSuccessfully,
	isOrderUpdatedSuccessfully,
}) => {
	const [status, setStatus] = useState(orderStatus)
	const [tempStatus, setTempStatus] = useState(orderStatus)
	const [newPaymentDate, setNewPaymentDate] = useState(paymentDate)
	const [isOpenDeleteModal, setOpenDeleteModal] = useState(false)
	const [isPartOnlyIaQuoted, setIsPartOnlyIaQuoted] = useState(false)

	useEffect(() => {
		if (isOrderHandledSuccessfully || isOrderUpdatedSuccessfully) {
			history.go(0)
		}
	}, [isOrderHandledSuccessfully, isOrderUpdatedSuccessfully])

	useEffect(() => {
		setStatus(orderStatus)
		setTempStatus(orderStatus)
	}, [orderStatus])

	useEffect(() => {
		setNewPaymentDate(paymentDate)
	}, [paymentDate])

	useEffect(() => {
		if (orderItems && orderItems.length != 0) {
			for (const orderItem of orderItems) {
				const suppliers = orderItem.part.costsAndMargins.suppliers
				if (suppliers.length == 1) {
					if (suppliers[0].id == parseInt(process.env.REACT_APP_IA_3D_SUPPLIER_ID)) {
						setIsPartOnlyIaQuoted(true)
					}
				}
			}
		}
	}, [orderItems])

	const STATUS_OPTIONS = [
		{
			value: 10,
			label: (
				<div>
					<span className={getStatusClassName('order_status_10')}>{t('order_status_10', { ns: 'status' })}</span>
				</div>
			),
		},
		{
			value: 20,
			label: (
				<div>
					<span className={getStatusClassName('order_status_20')}>{t('order_status_20', { ns: 'status' })}</span>
				</div>
			),
		},
		{
			value: 40,
			label: (
				<div>
					<span className={getStatusClassName('order_status_40')}>{t('order_status_40', { ns: 'status' })}</span>
				</div>
			),
		},
		{
			value: 50,
			label: (
				<div>
					<span className={getStatusClassName('order_status_50')}>{t('order_status_50', { ns: 'status' })}</span>
				</div>
			),
		},
		{
			value: 60,
			label: (
				<div>
					<span className={getStatusClassName('order_status_60')}>{t('order_status_60', { ns: 'status' })}</span>
				</div>
			),
		},
		{
			value: 70,
			label: (
				<div>
					<span className={getStatusClassName('order_status_70')}>{t('order_status_70', { ns: 'status' })}</span>
				</div>
			),
		},
		{
			value: 80,
			label: (
				<div>
					<span className={getStatusClassName('order_status_80')}>{t('order_status_80', { ns: 'status' })}</span>
				</div>
			),
		},
		{
			value: 30,
			label: (
				<div>
					<span className={getStatusClassName('order_status_30')}>{t('order_status_30', { ns: 'status' })}</span>
				</div>
			),
		},
		{
			value: 90,
			label: (
				<div>
					<span className={getStatusClassName('order_status_90')}>{t('order_status_90', { ns: 'status' })}</span>
				</div>
			),
		},
	]
	const newProductionURL = `/new-production/${orderId}`

	const saveStatus = () => {
		if (tempStatus === OrderStatus.CANCELLED) {
			handleCancelledOrder({ orderId })
		} else {
			const order = {
				id: orderId,
				statusId: tempStatus,
			}

			/// TODO REMOVE THIS IF WHEN THE RECEPTION PAGE WORKS CORRECTLY
			if (tempStatus === OrderStatus.SENT) {
				order.deliveryDate = new Date()
			}
			updateOrder({ data: { order } })
		}
	}

	const savePaymentDate = () => {
		const paymentDateData = newPaymentDate.split('/')
		const paymentDate = `${paymentDateData[2]}-${paymentDateData[1]}-${paymentDateData[0]}`
		if (orderStatus == OrderStatus.PAYMENT_PENDING) {
			handleSuccessfulOrder({
				orderId,
				paymentDate,
				emailOnlyToCustomer: true,
				statusId: orderStatus,
			})
		} else {
			handleSuccessfulOrder({
				orderId,
				paymentDate,
				sendEmail: false,
				statusId: orderStatus,

			})
		}
	}

	const deleteOrder = () => {
		setOpenDeleteModal(false)
		//TODO deleteOrder
	}

	return (
		<div className="p-4 text-center">
			<h3>{t('actions', { ns: 'naming' })}</h3>
			<div className="d-flex flex-row align-items-center">
				<div className="flex-grow-1">
					<OptionsInput
						isMulti={false}
						initialValue={{
							value: tempStatus,
							label: STATUS_OPTIONS.find(option => option.value === tempStatus)?.label,
						}}
						setValue={setTempStatus}
						options={STATUS_OPTIONS}
					/>
				</div>
				<div className="p-2">
					<div type="button" className="btn btn-light" onClick={() => saveStatus()} disabled={tempStatus === status}>
						{isLoadingStatus ? <Loader /> : <i className="bx bxs-save"></i>}
					</div>
				</div>
			</div>
			{paymentMethod == PAYMENT_METHOD.WIRE_TRANSFER && <div className="d-flex flex-row align-items-center">
				<div className="p-2 flex-grow-1">
					<Flatpickr
						className="form-control bg-white"
						placeholder={t('paymentDate', { ns: 'naming' })}
						options={{
							dateFormat: 'd/m/Y',
							locale: {
								firstDayOfWeek: 1,
							},
						}}
						value={newPaymentDate}
						onChange={(e, dateStr) => {
							setNewPaymentDate(dateStr)
						}}
					/>
				</div>
				<div className="p-2">
					<div type="button" className="btn btn-light" onClick={() => savePaymentDate()} disabled={newPaymentDate === paymentDate}>
						{isLoadingPaymentDate ? <Loader /> : <i className="bx bxs-save"></i>}
					</div>
				</div>
			</div>}
			<div className="py-2">
				<button type="button" className="btn btn-warning" onClick={e => history.push(newProductionURL)}>
					<i className="bx bxs-rocket"></i>
					<span className="p-2">{t('newProduction', { ns: 'naming' })}</span>
				</button>
				{isPartOnlyIaQuoted && <span data-tip={t('no-real-supplier', { ns: 'naming' })} data-for={'no-real-supplier-tooltip'}>
					<i className="bx bxs-error text-danger fs-3 text mx-2"></i>
				</span>}
				{isPartOnlyIaQuoted && <ReactTooltip
					id={'no-real-supplier-tooltip'}
					place="bottom"
					type="info"
					effect="solid"
				/>}
			</div>
			{[OrderStatus.PAYMENT_PENDING, OrderStatus.PAYMENT_ERROR, OrderStatus.CANCELLED, OrderStatus.REFUNDED].includes(status) && (
				<div className="py-2">
					<button type="button" className="btn btn-danger" onClick={e => setOpenDeleteModal(true)}>
						<i className="bx bx-x"></i>
						<span className="p-2">{t('deleteOrder', { ns: 'naming' })}</span>
					</button>
				</div>
			)}
			<Modal
				isOpen={isOpenDeleteModal}
				closeButtonText={'Cerrar'}
				closeModal={() => setOpenDeleteModal(false)}
				title={t('deleteOrderConfirmation', { ns: 'naming' })}
				buttons={[
					<button key="deleteOrder" type="button" className="btn btn-danger" onClick={() => deleteOrder()}>
						<span className="p-2">{t('deleteOrder', { ns: 'naming' })}</span>
					</button>,
				]}
			/>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		isLoadingStatus: state.Orders.isLoading,
		isLoadingPaymentDate: state.Orders.isLoadingPaymentDate,
		isOrderHandledSuccessfully: state.Orders.isOrderHandledSuccessfully,
		isOrderUpdatedSuccessfully: state.Orders.isOrderUpdatedSuccessfully,
	}
}
OrderActions.propTypes = {
	t: PropTypes.func,
	history: PropTypes.object,
	orderId: PropTypes.number,
	paymentDate: PropTypes.string,
	paymentMethod: PropTypes.number,
	orderStatus: PropTypes.number,
	orderItems: PropTypes.array,
	updateOrder: PropTypes.func,
	handleSuccessfulOrder: PropTypes.func,
	handleCancelledOrder: PropTypes.func,
	isLoadingStatus: PropTypes.bool,
	isLoadingPaymentDate: PropTypes.bool,
	isOrderHandledSuccessfully: PropTypes.bool,
	isOrderUpdatedSuccessfully: PropTypes.bool,
}
export default withRouter(
	connect(mapStateToProps, { updateOrder, handleSuccessfulOrder, handleCancelledOrder })(withTranslation()(OrderActions)),
)
