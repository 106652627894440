export const FORGET_PASSWORD = 'FORGET_PASSWORD'
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS'
export const FORGET_PASSWORD_ERROR = 'FORGET_PASSWORD_ERROR'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const VERIFY_TOKEN = 'VERIFY_TOKEN'
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS'
export const VERIFY_TOKEN_ERROR = 'VERIFY_TOKEN_ERROR'
export const RESET_FORGET_PASSWORD_EMAIL_STATE =
	'RESET_FORGET_PASSWORD_EMAIL_STATE'
