import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

export const IconTooltip = ({ icon, message, position, name, onClick }) => {
	return (
		<>
			<i onClick={onClick} className={icon} data-tip={message} data-for={`Tooltip_${name}`}></i>
			<ReactTooltip
				id={`Tooltip_${name}`}
				place={position}
				type="info"
				effect="solid"
				multiline

			/>
		</>
	)
}

IconTooltip.propTypes = {
	icon: PropTypes.string,
	message: PropTypes.string,
	position: PropTypes.string,
	name: PropTypes.string,
	onClick: PropTypes.func,
}
