import { useHandleCancelableApi } from "common/Hooks/UseHandleCancelableApi"
import { useObjectState } from "common/Hooks/UseObjectState"
import DatePickr from "components/Common/DatePickr"
import Modal from "components/Common/Modal/Modal"
import { OptionsInput } from "components/Common/OptionsInput"
import SearchInput from "components/Common/SearchInput"
import Switch from "components/Common/Switch/Switch"
import { INTERACTION_COLOR, INTERACTION_TYPE_COLOR, RELATED_COLOR } from "constants/colorsInteractions"
import PropTypes from 'prop-types'
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getCrmInteractionsList } from "store/actions"



const INIT_STATE = {
	availableInteraction: [],
	availableRelated: [],
	availableInteractionType: [],
	availableManager: [],
	createdAt: { startDate: null, endDate: null },
	onlyNewUser: false
}

export const CrmInteractionsFiltersModal = ({ getCrmInteractionsList, t, pageSize, currentPage, setCurrentPage, managerList }) => {

	const { objectData, handleOnChange, handleOnChangeDateRange, resetState, resetDateRange } = useObjectState(INIT_STATE)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [keyword, setKeyword] = useState('')

	const pagination = {
		page: currentPage,
		pageSize,
	}

	const inputBody = {
		data: {
			pagination,
			keyword,
			filters: objectData,
		},
	}


	const getFilteredCrmInteractionsHandler = useHandleCancelableApi(getCrmInteractionsList, inputBody, 500, true)
	const getCrmInteractionsHandler = useHandleCancelableApi(getCrmInteractionsList, { data: { keyword: '', pagination } }, 0)

	const handleGetCrmInteractions = () => {
		getFilteredCrmInteractionsHandler.handleApiCall()
	}

	useEffect(() => {
		handleGetCrmInteractions()
	}, [pageSize, currentPage])

	useEffect(() => {
		if (currentPage !== 1) {
			setCurrentPage(1)
		} else {
			handleGetCrmInteractions()
		}
	}, [keyword])





	const handleApplyFilters = async () => {
		if (currentPage !== 1) {
			setCurrentPage(1)
		} else {
			handleGetCrmInteractions()
		}
		setIsModalOpen(false)
	}

	const handleResetFilters = () => {
		resetState()
		setKeyword('')
		setIsModalOpen(false)
		getFilteredCrmInteractionsHandler.cancelApiCall()
		getCrmInteractionsHandler.handleApiCall()
	}


	const availableManagerOptions = managerList.map(manager => {
		return {
			value: `${manager.personalInformation.firstName} ${manager.personalInformation.lastName}`,
			label: `${manager.personalInformation.firstName} ${manager.personalInformation.lastName}`
		}
	})



	const availableInteractionOptions = [
		{
			value: 'email',
			label: <span className={`rounded-pill my-2 text-white w100 bg-${INTERACTION_COLOR['email'] || 'gray'}`}>{t('email', { ns: 'naming' })}</span>,
		},
		{
			value: 'call',
			label: <span className={`rounded-pill my-2 text-white w100 bg-${INTERACTION_COLOR['call'] || 'gray'}`}>{t('call', { ns: 'naming' })}</span>,
		},
		{
			value: 'visit',
			label: <span className={`rounded-pill my-2 text-white w100 bg-${INTERACTION_COLOR['visit'] || 'gray'}`}>{t('visit', { ns: 'naming' })}</span>,
		},
		{
			value: 'chat',
			label: <span className={`rounded-pill my-2 text-white w100 bg-${INTERACTION_COLOR['chat'] || 'gray'}`}>{t('chat', { ns: 'naming' })}</span>,
		},
		{
			value: 'internal_note',
			label: <span className={`rounded-pill my-2 text-white w100 bg-${INTERACTION_COLOR['internal_note'] || 'gray'}`}>{t('internal_note', { ns: 'naming' })}</span>,
		},
	]

	const availableRelatedOptions = [
		{
			value: 'order',
			label: <span className={`rounded-pill my-2 text-white w100 bg-${RELATED_COLOR['order'] || 'gray'}`}>{t('order', { ns: 'naming' })}</span>,
		},
		{
			value: 'general',
			label: <span className={`rounded-pill my-2 text-white w100 bg-${RELATED_COLOR['general'] || 'gray'}`}>{t('general', { ns: 'naming' })}</span>,
		},
		{
			value: 'quote',
			label: <span className={`rounded-pill my-2 text-white w100 bg-${RELATED_COLOR['quote'] || 'gray'}`}>{t('offer', { ns: 'naming' })}</span>,
		}
	]

	const availableInteractionTypeOptions = [
		{
			value: 'customer_service',
			label: <span className={`rounded-pill my-2 text-white w100 bg-${INTERACTION_TYPE_COLOR['customer_service'] || 'gray'}`}>{t('customer_service', { ns: 'naming' })}</span>,
		},
		{
			value: 'comercial',
			label: <span className={`rounded-pill my-2 text-white w100 bg-${INTERACTION_TYPE_COLOR['comercial'] || 'gray'}`}>{t('comercial_tracking', { ns: 'naming' })}</span>,
		}
	]

	const getAvailableRelatedOptions = () => {
		if (objectData.availableRelated === null) {
			return availableRelatedOptions
		} else {
			return availableRelatedOptions.map(option => {
				if (objectData.availableRelated.includes(option.value)) {
					return { ...option }
				}
				return option
			})
		}
	}

	const getAvailableInteractionOptions = () => {
		if (objectData.availableInteraction === null) {
			return availableInteractionOptions
		} else {
			return availableInteractionOptions.map(option => {
				if (objectData.availableInteraction.includes(option.value)) {
					return { ...option }
				}
				return option
			})
		}
	}

	const getAvailableInteractionTypeOptions = () => {
		if (objectData.availableInteractionType === null) {
			return availableInteractionTypeOptions
		} else {
			return availableInteractionTypeOptions.map(option => {
				if (objectData.availableInteractionType.includes(option.value)) {
					return { ...option }
				}
				return option
			})
		}
	}

	const getAvailableManagerOptions = () => {
		if (objectData.availableManager === null) {
			return availableManagerOptions
		} else {
			return availableManagerOptions.map(option => {
				if (objectData.availableManager.includes(option.value)) {
					return { ...option }
				}
				return option
			})
		}
	}

	return (
		<div className='d-flex'>
			<div className="position-relative me-3">
				<SearchInput onChange={setKeyword} value={keyword} />
			</div>
			<div>
				<button className='btn btn-secondary' onClick={() => { setIsModalOpen(true) }}>{t('filter', { ns: 'naming' })}</button>
				<Modal
					title={t('filterInteractions', { ns: 'naming' })}
					buttons={[
						<button onClick={() => { handleResetFilters() }} key='reset_production_filters' className='btn btn-primary'>{t('reset', { ns: 'naming' })}</button>,
						<button onClick={() => { handleApplyFilters() }} key='apply_production_filters' className='btn btn-primary'>{t('apply', { ns: 'naming' })}</button>,
					]}
					body={
						<div className='m-4'>
							<OptionsInput
								isMulti
								value={objectData.availableRelated || []}
								setValue={handleOnChange}
								objectElement='availableRelated'
								title={t('related', { ns: 'naming' })}
								options={getAvailableRelatedOptions()}
								automaticMappedValue

							/>
							<OptionsInput
								isMulti
								value={objectData.availableInteraction}
								setValue={handleOnChange}
								objectElement='availableInteraction'
								title={t('interaction', { ns: 'naming' })}
								options={getAvailableInteractionOptions()}
								automaticMappedValue
							/>
							<div className='my-1 ms-2' style={{ flex: 1 }}>
								<span>{t('new-user', { ns: 'naming' })}</span>
								<Switch
									id={'switch_new_user'}
									value={objectData.onlyNewUser}
									onChange={(e) => { handleOnChange('onlyNewUser', e.target.checked) }}
									className={'fs-3'} />
							</div>
							<OptionsInput
								isMulti
								value={objectData.availableInteractionType}
								setValue={handleOnChange}
								objectElement='availableInteractionType'
								title={t('consult_type', { ns: 'naming' })}
								options={getAvailableInteractionTypeOptions()}
								automaticMappedValue
							/>
							<OptionsInput
								isMulti
								value={objectData.availableManager}
								setValue={handleOnChange}
								objectElement='availableManager'
								title={t('manager', { ns: 'naming' })}
								options={getAvailableManagerOptions()}
								automaticMappedValue
							/>

							<DatePickr
								mode={'range'}
								format={'Y-m-d'}
								value={[objectData.createdAt?.startDate, objectData.createdAt?.endDate]}
								setValue={handleOnChangeDateRange}
								title={t('interaction_date', { ns: 'naming' })}
								objectElement={'createdAt'}
								placeholder={t('start_end_date', { ns: 'naming' })}
								cleanData={resetDateRange}
							/>
						</div>
					}
					isOpen={isModalOpen}
					closeModal={() => { setIsModalOpen(false) }}
				/>
			</div>
		</div>
	)
}

CrmInteractionsFiltersModal.propTypes = {
	getCrmInteractionsList: PropTypes.func,
	t: PropTypes.func,
	pageSize: PropTypes.number,
	currentPage: PropTypes.number,
	setCurrentPage: PropTypes.func,
	managerList: PropTypes.array
}

const mapStateToProps = (state) => ({
	managerList: state.Users.managerList,
})

const mapDispatchToProps = {
	getCrmInteractionsList
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CrmInteractionsFiltersModal))
