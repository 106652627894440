import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import Loader from 'components/Common/Loader'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateActiveSupplier } from 'store/actions'

export const ActiveSupplier = ({ isActive, id, updateActiveSupplier, isLoading, updateActiveSupplierResponse, error, history }) => {

	const [message, setMessage] = useState(null)
	const [showModal, setShowModal] = useState(false)

	useEffect(() => {
		if (message) {
			setShowModal(true)
		}
	}, [message])

	useEffect(() => {
		if (error != null) setMessage(error)
	}, [error])

	return (
		<div onClick={(e) => { updateActiveSupplier({ data: { account: { id, isActive: !isActive } } }) }}>

			{
				isLoading.includes(id) ? <div className='ms-2 fs-3'><Loader /></div> : isActive ?
					<div className='cursor-pointer ms-2'><i className='fs-3 bx bx-show'></i></div> :
					<div className='cursor-pointer ms-2'><i className='fs-3 bx bx-hide' ></i></div>
			}
			<ModalMessage
				isModalOpen={showModal}
				message={message}
				closeModal={() => history.go(0)}

			/>
		</div>
	)
}

ActiveSupplier.propTypes = {
	isActive: PropTypes.bool,
	id: PropTypes.number,
	updateActiveSupplier: PropTypes.func,
	isLoading: PropTypes.array,
	updateActiveSupplierResponse: PropTypes.string,
	error: PropTypes.string,
	history: PropTypes.func,
}

const mapStateToProps = state => {
	return {
		isLoading: state.Users.isLoadingUpdateActiveSupplier,
		updateActiveSupplierResponse: state.Users.updateActiveSupplierResponse,
		error: state.Crm.error,
	}
}

const mapDispatchToProps = {
	updateActiveSupplier,
}

export default withRouter(connect(
	mapStateToProps, mapDispatchToProps)(
		withTranslation()(ActiveSupplier)))
