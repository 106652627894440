import { jsPDF } from 'jspdf'
import PropTypes from 'prop-types'
import { useState } from 'react'

export const PRINT_BUTTON_ID_PREFIX = 'printButton'
const LABEL_HEIGHT_AND_WIDTH = {
	height: 180,
	width: 300,
}

export const LabelsPrintableContent = ({
	id,
	orientation,
	filename,
	isHidingContent,
	children,
	buttonClassName,
	buttonContent,
	buttonDisabled,
}) => {

	const doc = new jsPDF({
		format: [LABEL_HEIGHT_AND_WIDTH.height, LABEL_HEIGHT_AND_WIDTH.width],
		unit: 'px',
		orientation,
		precision: 1,
		compress: true,
		hotfixes: ['px_scaling'],
		filename,
	})

	const [loading, setLoading] = useState(false)

	const downloadPdf = async () => {

		const element = document.querySelector(`#${id}`)
		setLoading(true)
		doc.setProperties({
			title: filename,
		}).html(element, {
			callback: function (doc) {
				const totalPages = doc.internal.getNumberOfPages()
				doc.deletePage(totalPages)
				doc
				window.open(doc.output('bloburl'), '_blank')

				setLoading(false)
			},
		})

	}
	//It must return <></> because the component is nested within a button array in the modal, and adding a div breaks the alignment of the buttons.
	return (
		<>
			<button
				id={`${PRINT_BUTTON_ID_PREFIX}_${id}`}
				className={buttonClassName}
				disabled={loading || buttonDisabled}
				onClick={(e) => {
					e.preventDefault()
					downloadPdf()
				}}
			>
				{buttonContent}
			</button>
			<div className={isHidingContent ? 'd-none' : ''}>
				<div id={id}>
					{children}
				</div>
			</div>
		</>
	)
}

LabelsPrintableContent.propTypes = {
	id: PropTypes.string.isRequired,
	filename: PropTypes.string.isRequired,
	width: PropTypes.number,
	height: PropTypes.number,
	orientation: PropTypes.string,
	children: PropTypes.any,
	buttonClassName: PropTypes.string,
	buttonContent: PropTypes.element,
	isHidingContent: PropTypes.bool,
	isHidingButton: PropTypes.bool,
	printPageCounter: PropTypes.bool,
	printDocumentFooter: PropTypes.bool,
	openInNewTab: PropTypes.bool,
	buttonDisabled: PropTypes.bool,
}

export default LabelsPrintableContent
