import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { PartConfigTranslations } from 'common/Utils/PartConfigTranslations'
import OfferTechnology from 'pages/OfferList/OfferTechnology'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

const ChatPartInfo = ({ t, partData, partConfigOptions, i18n: { language } }) => {

	const { name, configuration, imageUrl, size, weight } = partData
	const { technologyTypeId } = configuration
	return (
		<div className='ms-4 d-flex flex-row align-items-center'>
			<section className="sectionPartImg ms-4">
				{imageUrl ? (
					<img src={imageUrl} alt={'partImg'} height={80} width={80} />
				) : (
					null
				)}
			</section>
			<section className="sectionPartInfo ms-4">
				<strong>{name}</strong><br />
				<text>
					<strong>{t('dimensions', { ns: 'naming' })}:</strong> {`${parseFloat(size.x).toFixed(0)} x ${parseFloat(size.y).toFixed(0)} x ${parseFloat(size.z).toFixed(0)} mm`}
				</text>
				<br />
				<text>
					<strong>{t('weight', { ns: 'naming' })}:</strong> {weight < 1000 ? `${roundAtDecimals(weight, 0)} g` : `${roundAtDecimals(weight / 1000, 2)} kg`}
				</text>
			</section>
			<section className="sectionPartInfo ms-4">
				<OfferTechnology className='mt-3' technologies={[technologyTypeId]} partConfigOptions={partConfigOptions} />
				<text className='d-flex flex-column'>
					<span>{PartConfigTranslations.getMaterialName({ part: partData, partConfigOptions, language })}</span>
					<span>{PartConfigTranslations.getAlloyName({ part: partData, partConfigOptions, language })}</span>
					<span>{PartConfigTranslations.getFinishName({ part: partData, partConfigOptions, language })}</span>
					<br />
				</text>
			</section>

		</div>
	)
}

ChatPartInfo.propTypes = {
	t: PropTypes.func,
	partData: PropTypes.object,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatPartInfo))
