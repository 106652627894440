export const packagesSize = [
	{
		label: 'XS - 20x14x8',
		width: '20',
		height: '14',
		depth: '8',
		value: '01',
		codeExp: '02',
		codeSta: '02',
	},
	{
		label: 'S - 25x20x10',
		width: '25',
		height: '20',
		depth: '10',
		value: '02',
		codeExp: '02',
		codeSta: '02',
	},
	{
		label: 'M - 31x22x15',
		width: '31',
		height: '22',
		depth: '15',
		value: '03',
		codeExp: '02',
		codeSta: '02',
	},
	{
		label: 'L - 43x30x18',
		width: '43',
		height: '30',
		depth: '18',
		value: '04',
		codeExp: '02',
		codeSta: '02',
	},
	{
		label: 'XL - 55x40x30',
		width: '55',
		height: '40',
		depth: '30',
		value: '05',
		codeExp: '02',
		codeSta: '02',
	},
	{
		label: 'XXL - 60x40x40',
		width: '60',
		height: '40',
		depth: '40',
		value: '06',
		codeExp: '02',
		codeSta: '02',
	},
]
