// @flow
import {
	CHANGE_LAYOUT,
	CHANGE_LAYOUT_WIDTH,
	CHANGE_PRELOADER,
	CHANGE_SIDEBAR_THEME,
	CHANGE_SIDEBAR_THEME_IMAGE,
	CHANGE_SIDEBAR_TYPE,
	CHANGE_TOPBAR_THEME,
	HIDE_RIGHT_SIDEBAR,
	SHOW_RIGHT_SIDEBAR,
	TOGGLE_RIGHT_SIDEBAR,
} from './actionTypes'

//constants
import {
	layoutTypes,
	layoutWidthTypes,
	leftBarThemeImageTypes,
	leftSideBarThemeTypes,
	leftSidebarTypes,
	topBarThemeTypes,
} from '../../constants/layout'

const INIT_STATE = {
	layoutType: layoutTypes.VERTICAL,
	layoutWidth: layoutWidthTypes.FLUID,
	leftSideBarTheme: leftSideBarThemeTypes.DARK,
	leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
	leftSideBarType: leftSidebarTypes.DEFAULT,
	topbarTheme: topBarThemeTypes.LIGHT,
	isPreloader: false,
	showRightSidebar: false,
	isMobile: false,
	showSidebar: true,
	leftMenu: false,
}

const Layout = (state = INIT_STATE, action) => {
	switch (action.type) {
		case CHANGE_LAYOUT:
			return {
				...state,
				layoutType: action.payload,
			}
		case CHANGE_PRELOADER:
			return {
				...state,
				isPreloader: action.payload,
			}

		case CHANGE_LAYOUT_WIDTH:
			return {
				...state,
				layoutWidth: action.payload,
			}
		case CHANGE_SIDEBAR_THEME:
			return {
				...state,
				leftSideBarTheme: action.payload,
			}
		case CHANGE_SIDEBAR_THEME_IMAGE:
			return {
				...state,
				leftSideBarThemeImage: action.payload,
			}
		case CHANGE_SIDEBAR_TYPE:
			return {
				...state,
				leftSideBarType: action.payload.sidebarType,
			}
		case CHANGE_TOPBAR_THEME:
			return {
				...state,
				topbarTheme: action.payload,
			}
		case TOGGLE_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: !state.showRightSidebar,
			}
		case SHOW_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: true,
			}
		case HIDE_RIGHT_SIDEBAR:
			return {
				...state,
				showRightSidebar: false,
			}
		default:
			return state
	}
}

export default Layout
