/**
 * Order an array of arrays based on an array
 */
export function sortByArray(array, refArray) {
	// Assign the right position to each array
	array.forEach(arr => {
		const index = refArray.findIndex(refEl => refEl == arr[0])
		arr.push(index)
	})
	// Order by position
	array.sort((a, b, c) => a[2] - b[2])
	// Removes the position
	array.forEach(arr => {
		arr.pop()
	})
	// Return the array sorted
	return array
}

export function findUniqueNumber(arr) {
	const counts = {}

	for (const num of arr) {
		counts[num] = (counts[num] || 0) + 1
	}

	for (const num of arr) {
		if (counts[num] === 1) {
			return num
		}
	}

	return null
}

export function sortByDate(array, dateKey) {
	return array.toSorted((a, b) => new Date(b[dateKey]) - new Date(a[dateKey]))
}

export const ArraysAreEqual = (array1, array2) => {
	return (
		array1.length == array2.length &&
		array1.every((element, index) => {
			return element === array2[index]
		})
	)
}

export const arraysAreEqual = (...arrays) => {
	// If there's only one array or none, return true by default
	if (arrays == null || arrays.length <= 1) return true

	const firstArrayLength = arrays[0].length

	// Ensure all arrays have the same length
	if (!arrays.every(arr => arr.length === firstArrayLength)) return false

	// Sort each array before comparing
	const sortedArrays = arrays.map(arr => [...arr].sort())

	// Check each index of the sorted arrays
	for (let i = 0; i < firstArrayLength; i++) {
		const value = sortedArrays[0][i]
		if (!sortedArrays.every(arr => arr[i] === value)) return false
	}

	return true
}

export const sortByString = (a, b) => {
	const aLowerCase = a.toLowerCase()
	const bLowerCase = b.toLowerCase()

	if (aLowerCase < bLowerCase) {
		return -1
	}
	if (aLowerCase > bLowerCase) {
		return 1
	}
	return 0
}

// 1 = GREEN Semaphore, remainingDays > 1
// 2 = YELLOW SEMAPHORE, remainingDays 1 || 0
// 3 = RED SEMAPHORE, remainingDays < 0
// 4 = DELIVERED, there is a realDate.
const semaphoreFilterOptions = (t) => {
	return [
		{
			value: 1,
			icon: 'bx bxs-circle text-success',
			text: t('onTime', { ns: 'naming' }),
		},
		{
			value: 2,
			icon: 'bx bxs-circle text-warning me-1',
			text: t('lastCall', { ns: 'naming' }),
		},
		{
			value: 3,
			icon: 'bx bxs-circle text-danger me-1',
			text: t('Late', { ns: 'naming' }),
		},
		{
			value: 4,
			icon: 'bx bxs-plane-take-off text-success me-1',
			text: t('delivered', { ns: 'naming' }),
		},
	]
}

export const mappedSemaphoreFilterOptions = (t) => {
	const options = semaphoreFilterOptions(t)
	return options.map((option) => {
		return {
			value: option.value,
			label: <div key={option.value} className={'d-flex mx-2 align-items-center '}>
				<i className={option.icon}></i>
				<div className="mx-2">{option.text}</div>
			</div>,
		}
	})
}
