export const OFFER_STATUS_OPTIONS = [10, 20, 30, 40, 91, 92]

export const OFFER_STATUS = {
	New: 10,
	Requested: 20,
	Quoted: 30,
	Uploaded: 40,
	Rejected: 91,
	Hidden: 92,
}

export const STATUS_COLOR = {
	[OFFER_STATUS.New]: 'secondary',
	[OFFER_STATUS.Requested]: 'yellow',
	[OFFER_STATUS.Quoted]: 'orange',
	[OFFER_STATUS.Uploaded]: 'green',
	[OFFER_STATUS.Rejected]: 'red',
	[OFFER_STATUS.Hidden]: 'red',
}
