import CommonFooter from 'components/Common/Footer'
import React, { Component } from 'react'

class Footer extends Component {
	render() {
		return (
			<React.Fragment>
				<footer className="footer app-footer bg-white z-index-negative-1 ">
					<div className="container-fluid">
						<div className="mx-auto w-25 mt-5">
							<CommonFooter />
						</div>
					</div>
				</footer>
			</React.Fragment>
		)
	}
}

export default Footer
