import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import OptionsSelector from 'components/Common/OptionsSelector'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getBillingDashboard, getPartConfigOptions } from 'store/actions'
import { getPreviousDateRange } from '../utils'
import BillingDashboard from './BillingDashboard'
import BillingSideBar from './BillingSidebar'
import CustomersDashboard from './CustomersDashboard'

export const BillingDashboardContainer = ({ t, dateRange, filters, getBillingDashboard, error, isLoading, getPartConfigOptions }) => {

	const BILLING_CHART = 1
	const CUSTOMER_CHART = 2
	const CHART_OPTIONS = [{ label: 'billing', value: BILLING_CHART }, { label: 'customers', value: CUSTOMER_CHART }]

	const [activeChart, setActiveChart] = useState(BILLING_CHART)

	useEffect(() => {
		if (dateRange != null) {
			const previousDateRange = getPreviousDateRange(dateRange)
			const data = {
				previousDateRange,
				dateRange,
				filters,
			}
			getBillingDashboard(data)
		}
		getPartConfigOptions()
	}, [dateRange, filters])

	return (
		<div style={{ height: '44rem' }} className='d-flex justify-content-center w-100 align-items-center'>
			{error ? <div className='h-auto mt-4'><Alert message={error} type='danger' /></div> : isLoading ? <Loader className='font-size-50' /> :
				<div className='d-flex col justify-content-center mt-4'>
					<div className='col-10 mx-2 d-flex flex-column'>
						<div className='d-flex justify-content-end me-4'>
							<OptionsSelector options={CHART_OPTIONS} setValue={setActiveChart} value={activeChart} />
						</div>
						{
							activeChart === BILLING_CHART && <BillingDashboard dateRange={dateRange} />
						}
						{
							activeChart === CUSTOMER_CHART && <CustomersDashboard />
						}

					</div>
					<div className='col-1 ms-2'>
						<BillingSideBar dateRange={dateRange} />
					</div>
				</div>
			}
		</div>
	)
}

BillingDashboardContainer.propTypes = {
	dateRange: PropTypes.object,
	getBillingDashboard: PropTypes.func,
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	filters: PropTypes.object,
	getPartConfigOptions: PropTypes.func,
}

const mapStateToProps = (state) => ({
	isLoading: state.Orders.isLoading,
	error: state.Orders.error,
})

const mapDispatchToProps = {
	getBillingDashboard,
	getPartConfigOptions
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingDashboardContainer))
