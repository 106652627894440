export default {
	BJ: '+229',
	BW: '+267',
	BF: '+226',
	BI: '+257',
	CV: '+238',
	CM: '+237',
	KM: '+269',
	CI: '+225',
	DJ: '+253',
	EG: '+20',
	EH: '+212',
	ER: '+291',
	ET: '+251',
	GA: '+241',
	GM: '+220',
	GH: '+233',
	GN: '+224',
	GQ: '+240',
	GW: '+245',
	KE: '+254',
	LS: '+266',
	LR: '+231',
	LY: '+218',
	MG: '+261',
	MW: '+265',
	ML: '+223',
	MA: '+212',
	MU: '+230',
	MR: '+222',
	MZ: '+258',
	NA: '+264',
	NE: '+227',
	NG: '+234',
	CF: '+236',
	CG: '+242',
	CD: '+243',
	RE: '+262',
	RW: '+250',
	SH: '+290',
	ST: '+239',
	SN: '+221',
	SC: '+248',
	SL: '+232',
	SO: '+252',
	ZA: '+27',
	SD: '+249',
	SZ: '+268',
	TZ: '+255',
	TG: '+228',
	TN: '+216',
	UG: '+256',
	ZM: '+260',
	ZW: '+263',
	AI: '+1 264',
	AR: '+54',
	AW: '+297',
	BS: '+1',
	BB: '+1',
	BZ: '+501',
	BM: '+1',
	BO: '+591',
	BR: '+55',
	CA: '+1',
	CL: '+56',
	CO: '+57',
	CR: '+506',
	CU: '+53',
	AN: '+599',
	DM: '+1',
	EC: '+593',
	SV: '+503',
	US: '+1',
	GL: '+299',
	GP: '+590',
	GT: '+502',
	GY: '+592',
	HT: '+509',
	HN: '+504',
	KY: '+1-345',
	FK: '+500 ',
	VG: '+1 284',
	JM: '+1',
	MX: '+52',
	MS: '+1 664',
	NI: '+505',
	PA: '+507',
	PY: '+595',
	PE: '+51',
	PR: '+1',
	DO: '+1',
	BL: '+590',
	KN: '+1',
	MF: '+1 599',
	PM: '+508',
	VC: '+1',
	LC: '+1',
	SR: '+597',
	TT: '+1',
	UY: '+598',
	VE: '+58',
	AF: '+93',
	SA: '+966',
	AM: '+374',
	AZ: '+994',
	BH: '+973',
	BD: '+880',
	MM: '+95',
	BN: '+673',
	BT: '+975',
	KH: '+855',
	CN: '+86',
	KP: '+850',
	KR: '+82',
	AE: '+971',
	PH: '+63',
	GE: '+995',
	HK: '+852',
	IN: '+91',
	ID: '+62',
	IQ: '+964',
	IR: '+98',
	IL: '+972',
	JP: '+81',
	JO: '+962',
	KZ: '+7',
	KG: '+996',
	KW: '+965',
	LA: '+856',
	LB: '+961',
	MO: '+853',
	MY: '+60',
	MV: '+960',
	MN: '+976',
	NP: '+977',
	OM: '+968',
	PK: '+92',
	QA: '+974',
	SG: '+65',
	SY: '+963',
	LK: '+94',
	TH: '+66',
	TW: '+886',
	TJ: '+992',
	TM: '+993',
	TR: '+90',
	UZ: '+998',
	VN: '+84',
	YE: '+967',
	AL: '+355',
	DE: '+49',
	AD: '+376',
	AT: '+43',
	BE: '+32',
	BY: '+375',
	BA: '+387',
	BG: '+359',
	CY: '+357',
	HR: '+385',
	DK: '+45',
	VA: '+39',
	SK: '+421',
	SI: '+386',
	ES: '+34',
	EE: '+372',
	FI: '+358',
	FR: '+33',
	GI: '+350',
	GR: '+30',
	NL: '+31',
	HU: '+36',
	IE: '+353',
	IS: '+354',
	FO: '+298',
	IT: '+39',
	LV: '+371',
	LI: '+423',
	LT: '+370',
	LU: '+352',
	MK: '+389',
	MT: '+356',
	MD: '+373',
	MC: '+377',
	ME: '+382',
	NO: '+47',
	PL: '+48',
	PT: '+351',
	GB: '+44',
	CZ: '+420',
	RO: '+40',
	RU: '+7',
	SM: '+378',
	RS: '+381',
	SE: '+46',
	CH: '+41',
	UA: '+380',
}
