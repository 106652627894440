import Alert from 'components/Common/Alert'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { getManagers, getQualityProblems, getQualitySolutions } from 'store/actions'
import CreateIncidenceModal from './CreateIncidenceModal'
import FilterModal from './FilterModal'
import ImagesModal from './ImagesModal'
import OrderIncidence from './OrderIncidence'

export const Incidences = ({ t, incidences, isLoading, error, getQualityProblems, getQualitySolutions, problems, solutions, managers, getManagers }) => {

	const [openedOrderIds, setOpenedOrderIds] = useState([])
	const [isCreateIncidenceModalOpen, setIsCreateIncidenceModalOpen] = useState(false)

	useEffect(() => {
		getQualityProblems()
		getQualitySolutions()
		getManagers()
	}, [])

	useEffect(() => {
		if (incidences.length > 0) {
			const orderIds = incidences.map((incidence) => incidence.order.id).slice(0, 5)
			setOpenedOrderIds(orderIds)
		}
	}, [incidences])

	const handleToggleOrder = (orderId) => {
		if (openedOrderIds.includes(orderId)) {
			setOpenedOrderIds(openedOrderIds.filter((id) => id !== orderId))
		} else {
			setOpenedOrderIds([...openedOrderIds, orderId])
		}

	}
	const getIncidencesToRender = () => {
		return incidences.map((incidence, index) => (
			<div key={`${incidence.order.id}_${index}`}>
				<OrderIncidence openedOrderIds={openedOrderIds} incidence={incidence} handleToggleOrder={handleToggleOrder} />
			</div>))
	}

	const items = useMemo(() => getIncidencesToRender(), [openedOrderIds, incidences])
	return (
		<div className='page-content marginFooter'>
			<MetaTags>
				<title>Proto&Go! | {t('incidences', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('quality', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('incidences', { ns: 'naming' }),
						link: '/incidences',
					},
				]}
			/>
			<div className='d-flex justify-content-center flex-column w-100 mb-1'>

				<div className='d-flex flex align-items-center bg-white w-100 ms-2'>
					<FilterModal />
					<button className='btn btn-primary' onClick={() => setIsCreateIncidenceModalOpen(true)}>{t('add', { ns: 'naming' })}</button>
				</div>
				{
					(isLoading && !isCreateIncidenceModalOpen) ? <Loader className='mx-auto fs-1' /> : (error && !isCreateIncidenceModalOpen) ? <Alert type={'danger'} message={error} fitContent centered /> :
						<div className='d-flex w-100 flex-column'>
							{incidences.length > 0 && problems.length > 0 && solutions.length > 0 && managers.length > 0 ?
								<>
									<div className='w-100 mx-2 sticky px-4 rounded d-flex flex-row align-items-center bg-white pb-3 mb-2'>
										<span className='col-1'><i className='bx bx-code ms-1 fs-3 mt-1' ></i></span>
										<span className='col-2'>{t('order_id', { ns: 'naming' })}</span>
										<span className='col'>{t('client', { ns: 'naming' })}</span>
										<span className='col'>{t('manager', { ns: 'naming' })}</span>
										<span className='col'>{t('shipmentDate', { ns: 'naming' })}</span>
										<span className='col-1'>{t('incidences', { ns: 'naming' })}</span>
									</div>
									{items}
								</> : 'No incidences'}
						</div>
				}
			</div>

			<ImagesModal />
			<CreateIncidenceModal
				isOpen={isCreateIncidenceModalOpen}
				closeModal={() => setIsCreateIncidenceModalOpen(false)}
				productions={[]}
				incidences={incidences}
			/>
		</div>
	)
}

Incidences.propTypes = {
	t: PropTypes.func,
	getQualityProblems: PropTypes.func,
	getQualitySolutions: PropTypes.func,
	incidences: PropTypes.array,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	problems: PropTypes.array,
	solutions: PropTypes.array,
	managers: PropTypes.array,
	getManagers: PropTypes.func,
}

const mapStateToProps = (state) => ({
	incidences: state.Orders.incidences,
	isLoading: state.Orders.isLoading,
	error: state.Orders.error,
	problems: state.Orders.qualityProblems,
	solutions: state.Orders.qualitySolutions,
	managers: state.Users.managers,

})

const mapDispatchToProps = {
	getQualityProblems,
	getQualitySolutions,
	getManagers,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Incidences))
