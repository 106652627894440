/* eslint-disable react/jsx-key */
import { PartConfigTranslations } from 'common/Utils/PartConfigTranslations'
import { getDate } from 'common/Utils/StringUtilities'
import { mapPriceToString } from 'common/Utils/mapPriceToString'
import PropTypes from 'prop-types'
import { PGOHeader } from './PGOHeader'

/**
type props = {
	t: (key) => string, // translation
	data: {
		order: {
			id: string,
			creationDate: string,
			realShipmentDate: string,
			estimatedShipmentDate: string,
			taxPercentage: number,
			shipmentPricing: number,
			orderItems: {
				part: {
					name: string,
					material: {
						id: number
					},
					imageUrl: string,
				},
				shippedQuantity: number,
				modifiedUnitPrice: number,
			}[],
			supplier: {
				officialName: string,
				address: {
					address: string,
					state: string,
					postalCode: string,
					country: string,
				},
				CIF: string,

				phoneData: {
					phone: {
						prefix: string,
						number: string,
					},
				},
			},
		},
	}
}
*/
export const CustomsPDF = (props) => {


	const {
		order,
		order: {
			supplier,
		},
		partConfigOptions,
	} = props



	return [
		(<div>{PGOHeader}</div>),
		// Titulo: "Pedido" */}
		(<h3 style={{
			width: '20%',
			height: '40px',
			backgroundColor: '#F08C01',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: 'white',
		}}>
			{'Order'}
		</h3>),
		// Cabecera */}
		(<div style={{
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: '2em',
			color: '#282828',
		}}>
			{/* Pedido */}
			<div
				className='customs-pdf-orange-table'
				style={{ width: '50%', gridTemplateColumns: 'repeat(3, 1fr)' }}
			>
				{/* row 1 */}
				<strong>{'ID'}</strong>
				<strong>{'Order date'}</strong>
				<strong>{'Shipment date'}</strong>
				{/* row 2 */}
				<div>{'#'}{order?.id}</div>
				<div>{getDate(order?.creationDate)}</div>
				<div>{getDate(order?.realShipmentDate || order?.estimatedShipmentDate)}</div>
			</div>
			{/* Dirección de envío */}
			<div style={{ width: '50%' }}>
				<h3>{'Supplier'}</h3>
				<div
					className='customs-pdf-orange-table'
					style={{ gridTemplateColumns: '1fr', textAlign: 'end' }}
				>
					<div>
						<h5><strong>{supplier?.supplierInfo?.officialName}</strong></h5>
						<div>{supplier?.supplierInfo?.address?.address}</div>
						<div>
							{supplier?.supplierInfo?.address?.state}
							{', '}
							{supplier?.supplierInfo?.address?.postalCode}
							{' '}
							{supplier?.supplierInfo?.address?.country}
						</div>
						<div>
							{'Tel. '}
							{supplier?.account?.phoneData?.phone?.prefix}
							{' '}
							{supplier?.account?.phoneData?.phone?.number?.slice(0, 2)}
							{' '}
							{supplier?.account?.phoneData?.phone?.number?.slice(2, 5)}
							{' '}
							{supplier?.account?.phoneData?.phone?.number?.slice(5, 7)}
							{' '}
							{supplier?.account?.phoneData?.phone?.number?.slice(7)}
						</div>
						<div>
							{supplier?.supplierInfo?.CIF ? `CIF: ${supplier?.supplierInfo?.CIF}` : ''}
						</div>
					</div>
				</div>
			</div>
		</div>),
		// Order Items (Headers) */}
		(<div style={{ height: '2em' }} />),
		(<div
			className='customs-pdf-orange-table customs-pdf-center-cells'
			style={{ color: '#282828', width: '100%', display: 'grid', gridTemplateColumns: 'minmax(0, 3fr) minmax(0, 1fr) minmax(0, 2fr) minmax(0, 2fr)', marginBottom: '0.8em' }}
		>
			<div>{'Part'}</div>
			<div style={{ justifyContent: 'center' }}>{'Quantity'}</div>
			<div style={{ justifyContent: 'flex-end' }}>{'Unit price'}</div>
			<div style={{ justifyContent: 'flex-end' }}>{'Total price'}</div>
		</div>),
		// Order Items */}
		order?.orderItems?.map((orderItem, index) => (
			<div
				key={index}
				className='customs-pdf-gray-table customs-pdf-center-cells'
				style={{ color: '#282828', width: '100%', display: 'grid', gridTemplateColumns: 'minmax(0, 3fr) minmax(0, 1fr) minmax(0, 2fr) minmax(0, 2fr)', marginBottom: '0.8em' }}
			>
				<div style={{ display: 'flex' }}>
					<img src={orderItem.part.imageBase64} alt="Product Image" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
					<div>
						<div style={{ overflowWrap: 'anywhere' }}>{orderItem.part?.name}</div>
						<div style={{ fontSize: '0.8em' }}>{PartConfigTranslations.getMaterialName({ part: orderItem.part, partConfigOptions, language: 'en' })}</div>
					</div>
				</div>
				<div style={{ justifyContent: 'center' }}>{orderItem.shippedQuantity}</div>
				<div style={{ justifyContent: 'flex-end' }}>{'$'}{mapPriceToString(orderItem.unitPurchasePrice)}</div>
				<div style={{ justifyContent: 'flex-end' }}>{'$'}{mapPriceToString(orderItem.unitPurchasePrice * orderItem.shippedQuantity)}</div>
			</div>
		)),
		// Total */}
		(<div
			className='customs-pdf-orange-table customs-pdf-center-cells'
			style={{ width: '25%', float: 'right', display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', color: '#282828' }}
		>
			{/* row 1 */}
			<strong>{'Total price'}</strong>
			{/* row 2 */}
			<div>{'$'}{mapPriceToString(order.partsPricing)}</div>

		</div>),
	]
}

CustomsPDF.propTypes = {
	t: PropTypes.func.isRequired,
	data: PropTypes.shape({
		order: PropTypes.shape({
			creationDate: PropTypes.string,
			id: PropTypes.number,
			realShipmentDate: PropTypes.string,
			estimatedShipmentDate: PropTypes.string,
			taxPercentage: PropTypes.number,
			shipmentPricing: PropTypes.number,
			orderItems: PropTypes.arrayOf(PropTypes.shape({
				part: PropTypes.shape({
					name: PropTypes.string.isRequired,
					material: PropTypes.shape({
						id: PropTypes.number.isRequired,
					}).isRequired,
					imageUrl: PropTypes.string.isRequired,
				}).isRequired,
				shippedQuantity: PropTypes.number.isRequired,
			})).isRequired,
		}).isRequired,
		supplierDetail: PropTypes.shape({
			supplierInfo: PropTypes.shape({
				officialName: PropTypes.string,
				address: PropTypes.shape({
					address: PropTypes.string,
					state: PropTypes.string,
					postalCode: PropTypes.string,
					country: PropTypes.string,
				}),
				CIF: PropTypes.string,
			}),
			account: PropTypes.shape({
				phoneData: PropTypes.shape({
					phone: PropTypes.shape({
						prefix: PropTypes.string,
						number: PropTypes.string,
					}),
				}),
			}),
		}).isRequired,
		partConfigOptions: PropTypes.object,
	}),
}
