import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import BillingChart from '../../../components/Common/Charts/BillingChart'
import { getChartLabels, getIndexByType } from '../utils'

export const BillingDashboard = ({ t, billingData, dateRange }) => {

	const getDashboardValues = () => {
		const ordersArray = Array.from({ length: chartLabels.labels.length }, () => 0)
		const subtotalArray = Array.from({ length: chartLabels.labels.length }, () => 0)
		if (billingData.length === 0) return { ordersArray, subtotalArray, maxBilling: 0, maxOrders: 0 } // RETURNS A EMPTY DASHBOARD
		for (const billingItem of billingData) {
			const index = getIndexByType(chartLabels.type, billingItem.order.creationDate, dateRange)
			ordersArray[index] += 1
			subtotalArray[index] += billingItem.order.subtotal != null ? Math.round(billingItem.order.subtotal, 2) : 0
		}
		const maxBilling = Math.max(...subtotalArray) * 1.1 + 10 - Math.max(...subtotalArray) * 1.1 % 10
		const maxOrders = Math.round(Math.max(...ordersArray) * 1.1 + 10 - Math.max(...ordersArray) * 1.1 % 10)
		return { ordersArray, subtotalArray, maxBilling, maxOrders }
	}

	const chartLabels = useMemo(() => getChartLabels(dateRange, t), [dateRange])
	const dashboardValues = useMemo(() => getDashboardValues(), [billingData])

	const series = [{
		name: 'Facturación',
		type: 'column',
		data: dashboardValues.subtotalArray,
	}
		, {
		name: 'Pedidos',
		type: 'line',
		data: dashboardValues.ordersArray,
	}]

	return (
		<div className='pe-4'>
			{series != null && chartLabels != null &&
				<BillingChart
					series={series}
					labels={chartLabels.labels}
					orders={dashboardValues.ordersArray}
					billing={dashboardValues.subtotalArray}
					maxOrders={dashboardValues.maxOrders}
					maxBilling={dashboardValues.maxBilling}
				/>
			}

		</div>
	)
}

BillingDashboard.propTypes = {
	t: PropTypes.func,
	billingData: PropTypes.array,
	dateRange: PropTypes.object,
}

const mapStateToProps = (state) => ({
	billingData: state.Orders.billingDashboard,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingDashboard))
