import { getCountryNameByCountryCode } from 'common/Utils/CountryUtils'
import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import CountriesPercentageChart from 'components/Common/Charts/CountriesPercentageChart'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { connect } from 'react-redux'

export const CountriesPercentageDashboard = ({ dashboardCountryList, isOffers }) => {

	const getCountriesValuesArray = () => {
		const countryValuesArray = []
		dashboardCountryList.forEach(({ country, email }) => {
			const countryName = country?.toUpperCase()
			const countryIndex = countryValuesArray.findIndex((countryValue) => countryValue.country === countryName)
			if (countryIndex === -1) {
				countryValuesArray.push({ country: countryName, count: 1, emails: [email] })
			} else {
				countryValuesArray[countryIndex].count++
				countryValuesArray[countryIndex].emails.push(email)
			}
		})
		return countryValuesArray
	}

	const getDashboardValues = () => {

		const countryValuesArray = getCountriesValuesArray()
		const totalItems = dashboardCountryList.length
		const totalEmails = [...new Set(countryValuesArray.flatMap(({ emails }) => emails.map((email) => email)))].length

		const labels = []
		const itemsSeries = []
		const emailsSeries = []

		countryValuesArray.forEach(({ country, count, emails }) => {
			const accounts = [...new Set(emails)].length
			itemsSeries.push(roundAtDecimals((count / totalItems) * 100, 2))
			emailsSeries.push(roundAtDecimals((accounts / totalEmails) * 100), 2)
			labels.push(getCountryNameByCountryCode(country) || 'Sin país asignado')
		})
		return { itemsSeries, emailsSeries, labels }
	}

	const { emailsSeries, itemsSeries, labels } = useMemo(() => getDashboardValues(), [dashboardCountryList])
	return (
		<div className='d-flex flex-row justify-content-center align-items-center'>
			<div className='w-50 mx-2'>
				<CountriesPercentageChart series={itemsSeries} labels={labels} />
			</div>
		</div >
	)
}

CountriesPercentageDashboard.propTypes = {
	dashboardCountryList: PropTypes.array,
	isOffers: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	dashboardCountryList: state.Users.dashboardCountryList,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CountriesPercentageDashboard)
