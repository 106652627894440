import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TitleWithValueColumn from '../CommonComponents/TitleWithValueColumn'
import { getLapseDivisor } from '../utils'

export const CountriesSideBar = ({ t, isOffers, countriesDashboardStats, countriesDashboardPreviousStats, dateRange }) => {

	const lapseDivisor = useMemo(() => getLapseDivisor(dateRange), [dateRange])

	return (
		<div>
			{countriesDashboardStats != null &&
				<div className="d-flex flex-column w-100 border-top">
					<div>
						<TitleWithValueColumn
							title={isOffers ? t("offers", { ns: 'naming' }) : t('orders', { ns: 'naming' })}
							value={countriesDashboardStats.totalCount || 0}
							previousValue={countriesDashboardPreviousStats?.totalCount || 0}
						/>
					</div>

					<div>
						<TitleWithValueColumn
							title={t("countries", { ns: 'naming' })}
							value={countriesDashboardStats.countries}
							previousValue={countriesDashboardPreviousStats?.countries}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title={t('companies', { 'ns': 'naming' })}
							value={countriesDashboardStats.companies}
							previousValue={countriesDashboardPreviousStats?.companies}

						/>
					</div>
					<div>
						<TitleWithValueColumn
							title={t('postalCodes', { 'ns': 'naming' })}
							value={countriesDashboardStats.postalCodes}
							previousValue={countriesDashboardPreviousStats?.postalCodes}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title={t('periodicCountries', { 'ns': 'naming' })}
							value={roundAtDecimals(countriesDashboardStats.countries / lapseDivisor, 2)}
							previousValue={roundAtDecimals(countriesDashboardPreviousStats?.countries / lapseDivisor, 2)}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title={t("periodicPostalCodes", { ns: "naming" })}
							value={roundAtDecimals(countriesDashboardStats.postalCodes / lapseDivisor, 2)}
							previousValue={roundAtDecimals(countriesDashboardPreviousStats?.postalCodes / lapseDivisor, 2)}
						/>
					</div>
				</div>

			}
		</div>
	)
}

CountriesSideBar.propTypes = {
	isOffers: PropTypes.bool,
	countriesDashboardStats: PropTypes.object,
	countriesDashboardPreviousStats: PropTypes.object,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({
	countriesDashboardStats: state.Users.countriesDashboardStats,
	countriesDashboardPreviousStats: state.Users.countriesDashboardPreviousStats,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CountriesSideBar))
