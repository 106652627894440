import {
	CREATE_NEW_CRM_LOG,
	CREATE_NEW_CRM_LOG_FAIL,
	CREATE_NEW_CRM_LOG_SUCCESS,
	CREATE_NEW_CUSTOMER,
	CREATE_NEW_CUSTOMER_FAIL,
	CREATE_NEW_CUSTOMER_SUCCESS,
	CREATE_NEW_LEAD,
	CREATE_NEW_LEAD_FAIL,
	CREATE_NEW_LEAD_SUCCESS,
	CREATE_NEW_LOG,
	CREATE_NEW_LOG_FAIL,
	CREATE_NEW_LOG_SUCCESS,
	GET_CRM_INTERACTIONS,
	GET_CRM_INTERACTIONS_FAIL,
	GET_CRM_INTERACTIONS_SUCCESS,
	GET_CRM_USERS,
	GET_CRM_USERS_DETAIL,
	GET_CRM_USERS_DETAIL_FAIL,
	GET_CRM_USERS_DETAIL_SUCCESS,
	GET_CRM_USERS_FAIL,
	GET_CRM_USERS_SUCCESS,
	GET_LOG_IN_AS_TOKEN,
	GET_LOG_IN_AS_TOKEN_FAIL,
	GET_LOG_IN_AS_TOKEN_SUCCESS,
	UPDATE_CRM_USER_DETAIL,
	UPDATE_CRM_USER_DETAIL_FAIL,
	UPDATE_CRM_USER_DETAIL_SUCCESS,
	UPDATE_MANAGER,
	UPDATE_MANAGER_FAIL,
	UPDATE_MANAGER_SUCCESS,
	UPDATE_ORGANIZATION_COMMON_DATA,
	UPDATE_ORGANIZATION_COMMON_DATA_FAIL,
	UPDATE_ORGANIZATION_COMMON_DATA_SUCCESS,
	UPDATE_ORGANIZATION_DATA,
	UPDATE_ORGANIZATION_DATA_FAIL,
	UPDATE_ORGANIZATION_DATA_SUCCESS,
	UPDATE_PAYMENT_DATA,
	UPDATE_PAYMENT_DATA_FAIL,
	UPDATE_PAYMENT_DATA_SUCCESS,
	UPDATE_SHIPPING_ADDRESS_DATA,
	UPDATE_SHIPPING_ADDRESS_DATA_FAIL,
	UPDATE_SHIPPING_ADDRESS_DATA_SUCCESS,
	UPDATE_USER_DATA,
	UPDATE_USER_DATA_FAIL,
	UPDATE_USER_DATA_SUCCESS,
} from './actionTypes'

export const getCrmUsers = () => ({
	type: GET_CRM_USERS,
})

export const getCrmUsersSuccess = ({ crmList }) => ({
	type: GET_CRM_USERS_SUCCESS,
	payload: { crmList },
})

export const getCrmUsersFail = error => ({
	type: GET_CRM_USERS_FAIL,
	payload: error,
})

export const getCrmUsersDetail = id => ({
	type: GET_CRM_USERS_DETAIL,
	payload: { id },
})

export const getCrmUsersDetailSuccess = client => {
	return {
		type: GET_CRM_USERS_DETAIL_SUCCESS,
		payload: client,
	}
}

export const getCrmUsersDetailFail = error => ({
	type: GET_CRM_USERS_DETAIL_FAIL,
	payload: error,
})

export const updateCrmUserDetail = user => ({
	type: UPDATE_CRM_USER_DETAIL,
	payload: user,
})

export const updateCrmUserDetailSuccess = user => ({
	type: UPDATE_CRM_USER_DETAIL_SUCCESS,
	payload: user,
})

export const updateCrmUserDetailFail = error => ({
	type: UPDATE_CRM_USER_DETAIL_FAIL,
	payload: error,
})

export const createNewCustomer = user => ({
	type: CREATE_NEW_CUSTOMER,
	payload: user,
})

export const createNewCustomerSuccess = user => ({
	type: CREATE_NEW_CUSTOMER_SUCCESS,
	payload: user,
})

export const createNewCustomerFail = error => ({
	type: CREATE_NEW_CUSTOMER_FAIL,
	payload: error,
})

export const createNewLog = user => ({
	type: CREATE_NEW_LOG,
	payload: user,
})

export const createNewLogSuccess = user => ({
	type: CREATE_NEW_LOG_SUCCESS,
	payload: user,
})

export const createNewLogFail = error => ({
	type: CREATE_NEW_LOG_FAIL,
	payload: error,
})

export const updateManager = data => ({
	type: UPDATE_MANAGER,
	payload: data,
})

export const updateManagerSuccess = data => ({
	type: UPDATE_MANAGER_SUCCESS,
	payload: data,
})

export const updateManagerFail = error => ({
	type: UPDATE_MANAGER_FAIL,
	payload: error,
})

export const createNewLead = user => ({
	type: CREATE_NEW_LEAD,
	payload: user,
})

export const createNewLeadSuccess = user => ({
	type: CREATE_NEW_LEAD_SUCCESS,
	payload: user,
})

export const createNewLeadFail = error => ({
	type: CREATE_NEW_LEAD_FAIL,
	payload: error,
})

export const updateUserData = user => ({
	type: UPDATE_USER_DATA,
	payload: user,
})

export const updateUserDataSuccess = user => ({
	type: UPDATE_USER_DATA_SUCCESS,
	payload: user,
})

export const updateUserDataFail = error => ({
	type: UPDATE_USER_DATA_FAIL,
	payload: error,
})

export const updateOrganizationData = organization => ({
	type: UPDATE_ORGANIZATION_DATA,
	payload: organization,
})

export const updateOrganizationDataSuccess = organization => ({
	type: UPDATE_ORGANIZATION_DATA_SUCCESS,
	payload: organization,
})

export const updateOrganizationDataFail = error => ({
	type: UPDATE_ORGANIZATION_DATA_FAIL,
	payload: error,
})

export const updatePaymentData = paymentSetting => ({
	type: UPDATE_PAYMENT_DATA,
	payload: paymentSetting,
})

export const updatePaymentDataSuccess = paymentSetting => ({
	type: UPDATE_PAYMENT_DATA_SUCCESS,
	payload: paymentSetting,
})

export const updatePaymentDataFail = error => ({
	type: UPDATE_PAYMENT_DATA_FAIL,
	payload: error,
})

export const updateShippingAddressData = shippingAddress => ({
	type: UPDATE_SHIPPING_ADDRESS_DATA,
	payload: shippingAddress,
})

export const updateShippingAddressDataSuccess = shippingAddress => ({
	type: UPDATE_SHIPPING_ADDRESS_DATA_SUCCESS,
	payload: shippingAddress,
})

export const updateShippingAddressDataFail = error => ({
	type: UPDATE_SHIPPING_ADDRESS_DATA_FAIL,
	payload: error,
})

export const getCrmInteractions = id => ({
	type: GET_CRM_INTERACTIONS,
	payload: { id },
})

export const getCrmInteractionsSuccess = client => {
	return {
		type: GET_CRM_INTERACTIONS_SUCCESS,
		payload: client,
	}
}

export const getCrmInteractionsFail = error => ({
	type: GET_CRM_INTERACTIONS_FAIL,
	payload: error,
})

export const createNewCrmLog = crmLog => {
	return {
		type: CREATE_NEW_CRM_LOG,
		payload: { crmLog },
	}
}

export const createNewCrmLogSuccess = crmInteraction => ({
	type: CREATE_NEW_CRM_LOG_SUCCESS,
	payload: crmInteraction,
})

export const createNewCrmLogFail = error => ({
	type: CREATE_NEW_CRM_LOG_FAIL,
	payload: error,
})

export const getLogInAsToken = accountId => {
	return {
		type: GET_LOG_IN_AS_TOKEN,
		payload: { accountId },
	}
}

export const getLogInAsTokenSuccess = token => ({
	type: GET_LOG_IN_AS_TOKEN_SUCCESS,
	payload: token,
})

export const getLogInAsTokenFail = error => ({
	type: GET_LOG_IN_AS_TOKEN_FAIL,
	payload: error,
})

export const updateOrganizationCommonData = organization => ({
	type: UPDATE_ORGANIZATION_COMMON_DATA,
	payload: organization,
})

export const updateOrganizationCommonDataSuccess = organization => ({
	type: UPDATE_ORGANIZATION_COMMON_DATA_SUCCESS,
	payload: organization,
})

export const updateOrganizationCommonDataFail = error => ({
	type: UPDATE_ORGANIZATION_COMMON_DATA_FAIL,
	payload: error,
})
