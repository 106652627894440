import TooltipMessage from 'components/Common/TooltipMessage'

export const OrdersNotAcceptedProductionAlert = ({ orderObject, t }) => {
	const isWarning = orderObject.isProdNotAccepted === true

	return (
		<span>
			{isWarning && (
				<div style={{ marginLeft: "30px" }}>
					<TooltipMessage message={t('production_not_accepted', { ns: 'status' })}>
						<div className='d-flex flex-row align-items-center'>
							<div style={{ height: '1em', width: '1em', color: 'red', marginLeft: "-7px" }}>
								<i className='bx bx-error fs-5'></i>
							</div>
						</div>
					</TooltipMessage>
				</div>
			)}
		</span>
	)
}
