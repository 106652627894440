import { useObjectState } from 'common/Hooks/UseObjectState'
import { getIdFromUrl } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import { OptionsInput } from 'components/Common/OptionsInput'
import TextInput from 'components/Common/TextInput'
import { getAllFlagOptions } from 'pages/Managers/RegisterManager/GetAllOptionsFlagForLanguages'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { createNewManager, getManagerDetailById, removeManagerCommissionById, updateManagerById, uploadProfileImage } from 'store/actions'

const REGISTER_PATH = '/create-manager'

export const ManagerDetail = ({ newManagerId, images, removeManagerCommissionById, uploadProfileImage, isManagerSuccessfullyUpdated, getManagerDetailById, managerDetail, isLoading, error, t, updateManagerById, errorUpdateManager, createNewManager, errorCreateManager, isCreatingManager }) => {

	const INIT_STATE = {
		firstName: '',
		lastName: '',
		languages: [],
		phoneNumber: '',
		country: '',
		prefix: '',
		email: '',
		commissions: [{ objective: '', increment: '' }],
		incentive: '',
		profileImage: null
	}

	const isCreate = location.pathname === REGISTER_PATH
	const managerId = getIdFromUrl()

	const { handleOnChange, objectData, setObjectData } = useObjectState(INIT_STATE)

	const [message, setMessage] = useState(null)
	const [isModalMessageOpen, setIsModalMessageOpen] = useState(false)


	const handleOnChangeCommissions = (newCommission, indexToChange) => {


		const commissions = objectData.commissions.map((commission, index) => {
			if (index === indexToChange) {
				return newCommission
			}
			return commission
		})
		setObjectData({ ...objectData, commissions })
	}


	useEffect(() => {
		if (isCreate) {
			setObjectData(INIT_STATE)
		}
	}, [isCreate])

	useEffect(() => {
		if (error) {
			setMessage(error)
		}
		if (errorCreateManager) {
			setMessage(t('error_create_manager', { ns: 'naming' }))
		}
		if (isCreatingManager) {
			setMessage(t('manager_created_successfully', { ns: 'naming' }))
		}
		if (errorUpdateManager) {
			setMessage(t('error_update_manager', { ns: 'naming' }))
		}
		if (isManagerSuccessfullyUpdated) {
			setMessage(t('manager_updated_successfully', { ns: 'naming' }))

		}


	}, [error, isCreatingManager, isManagerSuccessfullyUpdated])


	const [selectedFile, setSelectedFile] = useState(null)
	const [preview, setPreview] = useState(null)

	const handleFileChange = (event) => {
		const file = event.target.files[0]
		if (file) {
			setSelectedFile(file)
			const fileReader = new FileReader()
			fileReader.onload = () => {
				setPreview(fileReader.result)
			}
			fileReader.readAsDataURL(file)
		}
	}

	const handleUpload = () => {
		if (!isCreate && managerId) {

			uploadProfileImage(managerId, selectedFile)
		}
	}


	const validateValue = (value) => {
		let sanitizedValue = value.replace(/\./g, '')
		let validValue = parseFloat(sanitizedValue)
		if (isNaN(validValue)) validValue = ''
		if (validValue <= 0) validValue = ''
		if (validValue > 100) validValue = 100
		return validValue
	}

	const validateValueObjective = (value) => {
		let sanitizedValue = value.replace(/\./g, '')
		let validValue = parseFloat(sanitizedValue)
		if (isNaN(validValue) || validValue < 0) validValue = ''
		return validValue
	}


	useEffect(() => {
		if (errorUpdateManager) {
			setMessage(errorUpdateManager)
		}
	}, [errorUpdateManager])

	useEffect(() => {
		if (errorCreateManager) {
			setMessage(errorCreateManager)
		}
	}, [errorCreateManager])

	useEffect(() => {
		if (!isCreate) {
			getManagerDetailById(managerId)

		}
	}, [])

	useEffect(() => {
		if (message != null) setIsModalMessageOpen(true)
	}, [message])

	useEffect(() => {
		if (managerDetail != null && !isCreate) {
			setObjectData({
				firstName: managerDetail?.personalInformation?.firstName,
				lastName: managerDetail?.personalInformation?.lastName,
				languages: managerDetail?.languages,
				phoneNumber: managerDetail?.phoneData?.phone?.number,
				country: managerDetail?.personalInformation?.country ? managerDetail?.personalInformation?.country.toLowerCase() : '',
				prefix: managerDetail?.phoneData?.phone?.prefix,
				email: managerDetail?.loginCredentials?.email,
				commissions: managerDetail?.commissions?.length > 0 ? managerDetail.commissions : [{ objective: '', increment: '', managerId }],
				incentive: managerDetail?.commissions?.[0]?.incentive || '',
				profileImage: managerDetail?.profileImage,
			})
		}

	}, [managerDetail])

	useEffect(() => {
		if (newManagerId && selectedFile) {
			uploadProfileImage(newManagerId, selectedFile)
		}
	}, [newManagerId, selectedFile])


	const handleSaveChanges = () => {
		const filterCommissions = (commissions) => {
			const commissionsToRemove = []
			const validCommissions = []

			commissions.forEach(commission => {
				const objective = commission.objective != null ? commission.objective.toString().trim() : ''
				const increment = commission.increment != null ? commission.increment.toString().trim() : ''
				const incentive = objectData.incentive != null ? objectData.incentive.toString().trim() : ''
				if (objective === '' && increment === '' && incentive === '') {
					if (commission.id) {
						commissionsToRemove.push(commission.id)
					}
				} else {
					validCommissions.push(commission)
				}
			})
			return { validCommissions, commissionsToRemove }
		}

		const { validCommissions, commissionsToRemove } = filterCommissions(objectData.commissions)
		commissionsToRemove.forEach(commissionId => {
			removeManagerCommissionById(commissionId)
		})

		if (!isCreate) {
			const countryString = objectData.country.toString()
			const updatedManagerWithId = {
				id: managerId,
				personalInformation: {
					firstName: objectData.firstName,
					lastName: objectData.lastName,
					country: countryString,
				},
				phoneData: {
					phone: {
						number: objectData.phoneNumber,
						prefix: objectData.prefix,
					},
				},
				languages: objectData.languages,
				commissions: validCommissions.map(commission => ({
					...commission,
					incentive: objectData.incentive,
					managerId: managerId
				})),
			}
			updateManagerById(updatedManagerWithId)


		} else {
			if (objectData.email.trim() === '') {
				setMessage('El campo de correo electrónico no puede estar vacío.')
				return
			}
			const countryString = objectData.country.toString()
			const newManagerCreated = {
				personalInformation: {
					firstName: objectData.firstName,
					lastName: objectData.lastName,
					country: countryString,
					language: objectData.languages.join(','),
				},
				loginCredentials: {
					email: objectData.email,
				},
				phoneData: {
					phone: {
						number: objectData.phoneNumber,
						prefix: objectData.prefix,
					},
				},
				languages: objectData.languages,
				commissions: validCommissions.map(commission => ({
					...commission,
					incentive: objectData.incentive
				})),


			}

			createNewManager(newManagerCreated)

		}
		if (selectedFile) {
			handleUpload()
		}
	}

	const handleOnClose = () => {
		location.reload()
	}

	const handleAddCommission = () => {

		setObjectData({
			...objectData,
			commissions: [...objectData.commissions, { objective: '', increment: '' }]
		})
	}

	const handleRemoveCommission = (index, id) => {
		const newCommissions = objectData.commissions.filter((_, i) => i !== index)
		if (id) {
			removeManagerCommissionById(id)
		}

		setObjectData({ ...objectData, commissions: newCommissions })
	}

	const renderManagerDetail = () => {

		const {
			loginCredentials,
			statistics,
			customersCount,
			personalInformation,
		} = managerDetail || {}

		return (

			<div className='w-100'>
				<div className="w-100 bg-white rounded p-2">
					<div className="border-bottom m-2 pb-1">
						<h3 className="ms-3 mb-0 pt-3 pb-0">{t('personal_info', { ns: 'naming' })}</h3>
					</div>
					<Row>
						<Col>

							<div className="m-3">
								<TextInput
									title={t('name', { ns: 'naming' })}
									value={objectData.firstName}
									setValue={handleOnChange}
									objectElement='firstName'
									enabled
								/>
							</div>
							<div className='m-3'>
								<TextInput
									title={t('lastName', { ns: 'naming' })}
									value={objectData.lastName}
									setValue={handleOnChange}
									enabled
									objectElement='lastName'
								/>
							</div>
							<div className='m-3'>
								<OptionsInput
									title={t('languages', { ns: 'naming' })}
									options={getAllFlagOptions()}
									value={objectData.languages}
									objectElement='languages'
									isMulti
									automaticMappedValue
									setValue={handleOnChange}
								/>
							</div>
							<div className='m-3'>
								<TextInput
									title={t('email', { ns: 'naming' })}
									value={objectData.email}
									isRequired={isCreate}
									setValue={handleOnChange}
									enabled={isCreate}
									objectElement='email'
									disabled={!isCreate && managerDetail}
								/>
							</div>
						</Col>
						<Col>
							{isCreate ? (<div style={{ marginLeft: "250px", marginTop: "110px" }}>
								{preview ? (
									<img src={preview} alt='profile' style={{ width: '100px', height: '100px' }} />
								) : (
									!objectData?.profileImage?.src ? (
										<img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt='profile' style={{ width: '130px', height: '130px', marginLeft: "50px", marginTop: "-30px" }} />
									) : (
										<img src={objectData?.profileImage?.src} alt='profile' style={{ width: '130px', height: '130px' }} />
									)
								)}
								<div style={{ marginTop: "55px", marginLeft: "-90px", position: "absolute" }}>

									<input id="file-upload" type="file" accept='.jpg, .jpeg, .png' onChange={handleFileChange} style={{ color: "white", marginLeft: "130px" }} />

								</div>
							</div>) :

								(<div style={{ marginLeft: "270px", marginTop: "80px" }}>
									{preview ? (
										<img src={preview} alt='profile' style={{ width: '100px', height: '100px' }} />
									) : (
										!objectData?.profileImage?.src ? (
											<div style={{ marginLeft: "-45px" }}>
												<img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" alt='profile' style={{ width: '130px', height: '130px', marginLeft: "50px", marginTop: "-30px" }} />
											</div>
										) : (
											<img src={objectData?.profileImage?.src} alt='profile' style={{ width: '130px', height: '130px' }} />
										)
									)}
									<div style={{ marginTop: "55px", marginLeft: "-105px", position: "absolute" }}>
										<input id="file-upload" type="file" accept='.jpg, .jpeg, .png' onChange={handleFileChange} style={{ color: "white", marginLeft: "100px", position: "absolute" }} />
									</div>
								</div>)}


						</Col>
					</Row>
				</div>

				<div className="w-100 bg-white rounded mt-5 mb-5">
					<div className="border-bottom m-2 pb-1 d-flex">
						<h3 className="ms-3 mb-0 pt-3 pb-0">{t('commissions', { ns: 'naming' })}</h3>

					</div>
					<Row>
						<Col>
							<div className="m-2 row ps-2" style={{ position: "" }}>
								<div className="col-3">
									<TextInput
										title={t('incentive', { ns: 'naming' })}
										value={objectData.incentive}
										type='number'
										setValue={(_, value) => setObjectData({ ...objectData, incentive: validateValue(value) })}
										enabled
										objectElement='incentive'
										inputActions={<div><i>%</i></div>}
									/>
								</div>
							</div>
							{objectData.commissions.length > 0 && (
								objectData.commissions.map((commission, index) => {
									const isLastElement = index === objectData.commissions.length - 1
									return (
										<div className="m-2 row ps-2">
											<div className="col-3" key={index}>
												<TextInput
													title={t('objective', { ns: 'naming' })}
													value={commission.objective}
													type='number'
													setValue={(_, value) => handleOnChangeCommissions({ ...commission, objective: validateValueObjective(value) }, index)}
													enabled
													objectElement='commissions'
													inputActions={<div><i className='bx bx-euro'></i></div>}
												/>
											</div>
											<div className="col-3 d-flex flex-row">
												<TextInput
													title={t('increment', { ns: 'naming' })}
													value={commission.increment}
													type='number'
													setValue={(_, value) => handleOnChangeCommissions({ ...commission, increment: validateValue(value) }, index)}
													enabled
													objectElement='commissions'
													inputActions={<div><i>%</i></div>}
												/>
												<div className='d-flex flex-row align-items-end mb-2'>

													{objectData.commissions.length > 1 && (
														<div className='mx-1'>
															<button onClick={() => handleRemoveCommission(index, commission.id)} className='btn btn-danger' style={{ height: "35px", borderRadius: "8px", marginLeft: "10px" }}>-</button>
														</div>
													)}
													{isLastElement && (
														<div className='mx-1'>
															<button onClick={() => handleAddCommission()} className='btn btn-primary' style={{ height: "35px", borderRadius: "8px", marginLeft: "10px" }}>+</button>
														</div>
													)}
												</div>
											</div>
										</div>
									)
								})
							)}
						</Col>

					</Row>
				</div>
				<div className='d-flex justify-content-end mt-3 me-3'>
					<button className='btn btn-primary' onClick={handleSaveChanges}>
						{t('save', { ns: 'naming' })}
					</button>
				</div>
			</div>

		)
	}
	return (
		<div className='page-content'>
			<MetaTags>
				<title>Proto&Go! | {t('managers', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('configuration', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('managers', { ns: 'naming' }),
						link: '/managers',
					},
					{
						item: t('manager-detail', { ns: 'naming' }),
						link: `/manager-detail/${getIdFromUrl()}`,
					},
				]}
			/>
			<div className='d-flex justify-content-center'>
				{
					error ? <Alert type="danger" fitContent message={error} /> : isLoading ? <div className='fs-1 d-flex justify-content-center'><Loader /> </div> : renderManagerDetail()
				}
				<ModalMessage
					isModalOpen={isModalMessageOpen}
					message={message}
					closeModal={() => { handleOnClose() }}
				/>
			</div>
		</div>
	)
}

ManagerDetail.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	getManagerDetailById: PropTypes.func,
	managerDetail: PropTypes.object,
	newManagerId: PropTypes.number,
	error: PropTypes.object,
	errorUpdateManager: PropTypes.object,
	updateManagerById: PropTypes.func,
	createNewManager: PropTypes.func,
	errorCreateManager: PropTypes.object,
	isCreatingManager: PropTypes.bool,
	removeManagerCommissionById: PropTypes.func,
	isManagerSuccessfullyUpdated: PropTypes.bool,
	uploadProfileImage: PropTypes.func,

	images: PropTypes.array,

}

const mapStateToProps = (state) => ({
	error: state.Users.error,
	errorUpdateManager: state.Users.errorUpdateManager,
	managerDetail: state.Users.managerDetail,
	newManagerId: state.Users.newManagerId,
	isLoading: state.Users.isLoading,
	errorCreateManager: state.Users.errorCreateManager,
	isCreatingManager: state.Users.isCreatingManager,
	isManagerSuccessfullyUpdated: state.Users.isManagerSuccessfullyUpdated,
	images: state.CloudStorage.images,
})

const mapDispatchToProps = {
	getManagerDetailById,
	updateManagerById,
	createNewManager,
	removeManagerCommissionById,
	uploadProfileImage,

}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ManagerDetail))
