import PropTypes from 'prop-types'
import React, { Component } from 'react'

import brandIcon from 'assets/images/brands/protoAndGoIcon.png'
import brandIconDark from 'assets/images/brands/protoAndGoIconDark.png'
import brandLogo from 'assets/images/brands/protoAndGoLogo.png'
import brandLogoDark from 'assets/images/brands/protoAndGoLogoDark.png'

import { Col, Container, Row } from 'reactstrap'

class BrandLogo extends Component {
	render() {
		return (
			<React.Fragment>
				<Container className={this.props.className}>
					<Row>
						<Col
							className={
								(!this.props.collapsed ? 'col-2 ' : 'col-12 logo-collapsed ') +
								'mx-auto no-padding'
							}
						>
							<img
								src={!this.props.darkMode ? brandIcon : brandIconDark}
								alt=""
								className="img-fluid"
							/>
						</Col>
						{!this.props.collapsed && (
							<Col className="col-10 mx-auto">
								<img
									src={!this.props.darkMode ? brandLogo : brandLogoDark}
									alt=""
									className="img-fluid"
								/>
							</Col>
						)}
					</Row>
				</Container>
			</React.Fragment>
		)
	}
}
BrandLogo.propTypes = {
	collapsed: PropTypes.bool,
	darkMode: PropTypes.bool,
	className: PropTypes.string,
}

export default BrandLogo
