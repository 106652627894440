import { isEven } from 'common/Utils/NumberUtilities'
import { getAdaptedDate } from 'common/Utils/StringUtilities'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const InternalCommentTable = ({ t, internalComments }) => {
	internalComments.sort((a, b) => {
		return getAdaptedDate(a.date) - getAdaptedDate(b.date)
	})

	return (
		<table style={{ width: '100%' }} className="border-collapse">
			<tr>
				<th style={{ width: '15%' }} className="p-2">{t('manager', { ns: 'naming' })}</th>
				<th style={{ width: '70%' }} className="p-2">{t('message', { ns: 'naming' })}</th>
				<th style={{ width: '15%' }} className="p-2">{t('date', { ns: 'naming' })}</th>
			</tr>
			{internalComments.map((comment, i) => {
				return <tr key={`internalComment_${i}`} className={isEven(i) && 'bg-light-gray'}>
					<td className="border border-top-bottom p-2">{comment.managerFullName}</td>
					<td className="border border-top-bottom p-2">{comment.message}</td>
					<td className="border border-top-bottom p-2">{comment.date}</td>
				</tr>
			})}
		</table>
	)
}

InternalCommentTable.propTypes = {
	t: PropTypes.func,
	internalComments: PropTypes.array,
}
export default withTranslation()(InternalCommentTable)
