import { OrderStatus } from 'constants/orderStatus'
import { ProductionStatus } from 'constants/productionStatus'
import quoteStatus from 'constants/quoteStatus'
import { getStatusClassName } from './StringUtilities'
const mapStatusKeyValuePair = (statusObject, statusName, t) => {
	return Object.entries(statusObject).map((status) => {
		const statusId = status[1]
		const statusString = `${statusName}${statusId}`
		return {
			label: (<span className={getStatusClassName(statusString)}>{t(statusString, { ns: 'status' })}</span>),
			value: statusId,
		}
	})
}

export const getProductionStatusKeyValuePair = (t) => {
	return mapStatusKeyValuePair(ProductionStatus, 'production_status_', t)

}

export const getOrderStatusKeyValuePair = (t) => {
	return mapStatusKeyValuePair(OrderStatus, 'order_status_', t)
}

export const getQuotesStatusKeyValuePair = (t) => {
	return mapStatusKeyValuePair(quoteStatus, 'quote_status_', t)
}
