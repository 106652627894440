import { PropTypes } from 'prop-types'
import React, { Component } from 'react'

import FileIcon from './FileIcon.js'

class PDFFileIcon extends Component {
	render() {
		return (
			<React.Fragment>
				<FileIcon
					src={this.props.src}
					fileExtension="PDF"
					fileBackgroundColor={this.props.invertedColor ? '#FFFFFF' : '#AD0B00'}
					fontColor={this.props.invertedColor ? '#AD0B00' : '#FFFFFF'}
					tooltipMessage={this.props.tooltipMessage}
				/>
			</React.Fragment>
		)
	}
}

PDFFileIcon.propTypes = {
	tooltipMessage: PropTypes.string,
	src: PropTypes.string,
	invertedColor: PropTypes.bool,
}
export default PDFFileIcon
