import { USER_ROLE_SUPPLIER } from 'constants/userRoles'
import { getDate } from '../Utils/StringUtilities'
import { getUserValuesFromLocalStorage } from './LocalStorageUtilities'
import { ConfigTranslations } from './PartConfigTranslations'

export const parseHolidays = (holidays) => {
	if (holidays == [] || holidays == null) return null
	return holidays.map((date) => date != null && getDate(date.toString(), 'en') || '').join(', ')
}

const mapHolidays = (holidays) => {
	if (holidays == null || holidays == '') return []
	return holidays.split(', ').map((date) => new Date(date),
	)
}

export const parseWorkdays = (supplierData) => {
	return {
		Monday: supplierData.workDays.includes(1),
		Tuesday: supplierData.workDays.includes(2),
		Wednesday: supplierData.workDays.includes(3),
		Thursday: supplierData.workDays.includes(4),
		Friday: supplierData.workDays.includes(5),
		Saturday: supplierData.workDays.includes(6),
		Sunday: supplierData.workDays.includes(7),
	}
}

export const parseWeekdays = (weekdays) => {
	const workdays = []
	if (weekdays.Monday) workdays.push(1)
	if (weekdays.Tuesday) workdays.push(2)
	if (weekdays.Wednesday) workdays.push(3)
	if (weekdays.Thursday) workdays.push(4)
	if (weekdays.Friday) workdays.push(5)
	if (weekdays.Saturday) workdays.push(6)
	if (weekdays.Sunday) workdays.push(7)
	return workdays
}

export const mapWorkdays = (workdays, t) => {
	const arrayWeekdays = parseWeekdays(workdays)
	return arrayWeekdays.map((weekday) => {
		switch (weekday) {
			case 1:
				return t('monday', { ns: 'naming' })
			case 2:
				return t('tuesday', { ns: 'naming' })
			case 3:
				return t('wednesday', { ns: 'naming' })
			case 4:
				return t('thursday', { ns: 'naming' })
			case 5:
				return t('friday', { ns: 'naming' })
			case 6:
				return t('saturday', { ns: 'naming' })
			case 7:
				return t('sunday', { ns: 'naming' })
			default:
				return null
		}
	}).join(', ')
}

export const mapTechnologies = (technologies, partConfigOptions, language) => {
	if (technologies == null || technologies == []) return ''
	return technologies.map((technology) => {
		return ConfigTranslations.getTechnologyName({ id: technology, partConfigOptions, language })
	}).join(', ')
}

export const mapDataToInputDTO = (supplierData, id) => {
	return {
		data: {
			account: {
				id,
				isActive: true,
				personalInformation: {
					firstName: supplierData.firstName,
					lastName: supplierData.lastName,
					country: supplierData.country,
					language: supplierData.language,
				},
				loginCredentials: {
					email: supplierData.email,
				},
				phoneData: {
					phone: {
						prefix: supplierData.phonePrefix,
						number: supplierData.phoneNumber,
					},
					mobilePhone: {
						prefix: supplierData.mobilePhonePrefix,
						number: supplierData.mobilePhone,
					},
				},
			},
			supplierInfo: {
				accountId: id,
				officialName: supplierData.officialName,
				contactPerson: supplierData.contactPerson,
				timezone: supplierData.timeZone,
				calendar: {
					shipmentDays: supplierData.shipmentDays,
					reliabilityMarginDays: supplierData.reliabilityMarginDays,
					workdays: parseWorkdays(supplierData),
					holidays: mapHolidays(supplierData.holidays),
				},
				address: {
					address: supplierData.address,
					state: supplierData.state,
					country: supplierData.country,
					postalCode: supplierData.postalCode,
				},
				personalSettings: {
					formatDate: supplierData.formatDate,
					currency: supplierData.currency,
				},
				techTypes: supplierData.techTypes,
			},
		},
	}
}

export const mapSuppliers = (suppliers) => {
	return suppliers.map(supplier => {
		return {
			label: supplier?.personalInformation?.firstName,
			value: supplier?.id,
		}
	})
}

export const getSupplierViewId = (supplierId) => {
	const userLoggedIn = getUserValuesFromLocalStorage()
	const supplierLoggedIn = userLoggedIn.roles.includes(USER_ROLE_SUPPLIER) ? userLoggedIn.id : undefined
	return supplierId || supplierLoggedIn
}
