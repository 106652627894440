import { roundAtDecimals, roundCurrency } from 'common/Utils/NumberUtilities'
import Loader from 'components/Common/Loader'
import Switch from 'components/Common/Switch/Switch'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import { Button, Col, Row } from 'reactstrap'
import { getDate, getDateWithHour, getStatusClassName } from '../../common/Utils/StringUtilities'

class ProductionTab extends Component {
	constructor(props) {
		super(props)

		this.removeFreeDays = this.removeFreeDays.bind(this)
	}
	parseSuppliers(suppliers) {
		return suppliers.filter(supplier => supplier.id != parseInt(process.env.REACT_APP_IA_3D_SUPPLIER_ID)).map(supplier => {
			return {
				value: supplier.id,
				label: supplier.personalInformation.firstName,
			}
		})
	}
	removeFreeDays(date) {
		if (this.props.supplierWorkdays != null) {
			switch (date.getDay()) {
				case 1:
					return !this.props.supplierWorkdays.Monday
				case 2:
					return !this.props.supplierWorkdays.Tuesday
				case 3:
					return !this.props.supplierWorkdays.Wednesday
				case 4:
					return !this.props.supplierWorkdays.Thursday
				case 5:
					return !this.props.supplierWorkdays.Friday
				case 6:
					return !this.props.supplierWorkdays.Saturday
				case 0:
					return !this.props.supplierWorkdays.Sunday
			}
		}
		return true
	}

	render() {

		const CURRENCY = '€'
		const {
			t,
			i18n,
			orderDetails,
			disconformity,
			productionSimulation,
			discountComponent,
			shipmentDate,
			arrivalDate,
			isShipmentLate,
		} = this.props
		if (!orderDetails) return <Loader />

		const {
			orderId,
			status,
			productionTime,
			orderDate,
			totalPrice,
			customerShipmentDate,
			customerShipmentPrice,
			minimumOrderPrice,
		} = orderDetails

		const {
			productionDays,
			weight,
			volumetricWeight,
			shipmentCost,
			processingCost,
			totalCost,
			partialSale,
		} = productionSimulation

		const grossBenefit = totalCost ? roundCurrency(partialSale - totalCost) : 0
		const netBenefit = totalCost ? roundCurrency(partialSale - totalCost - shipmentCost - processingCost) : 0

		return (
			<React.Fragment>
				<Row className="mx-1">
					<Col className="col-3 bg-white p-3 me-3 border" >
						<div>
							<p className="fs-3 mb-2 border-bottom border-dark-subtle"><strong>{t('order', { ns: 'naming' })} {orderId}</strong> </p>
							<p><strong className="me-1">{t('status', { ns: 'naming' })}:</strong><span className={`${status && getStatusClassName('order_status_' + status.value)} font-size-medium`}>{status && t(status.text, { ns: 'status' })}</span></p>
							<p><strong className="me-1">{t('orderDate', { ns: 'naming' })}:</strong><span className="font-size-medium"> {orderDate && getDateWithHour(orderDate.getTime())}{' '}</span></p>
							<p><strong className="me-1">{t('totalPrice', { ns: 'naming' })}:</strong> <span className="font-size-medium">{totalPrice && `${roundCurrency(totalPrice)}${CURRENCY}`}</span></p>
							<p><strong className="me-1">{t('paymentMethod', { ns: 'naming' })}:</strong> <span className="font-size-medium">{`${orderDetails.paymentMethodId !== -1 ? t(`payment_method_${orderDetails.paymentMethodId}_name`, { ns: 'paymentMethods' }) : 'N/A'}`}</span></p>
							<p><strong className="me-1">{t('customerShipmentDate', { ns: 'naming' })}:</strong> <span className="font-size-medium">{customerShipmentDate && getDate(customerShipmentDate.getTime())}</span></p>
							<p><strong className="me-1">{t('orderTime', { ns: 'naming' })}:</strong> <span className="font-size-medium">{`${productionTime} ${t(productionTime === 1 ? 'workingDay' : 'workingDays', { ns: 'naming' })}`}</span></p>
						</div>
					</Col>
					<Col className="bg-white border">
						<Row>
							<Col className="p-3 me-3 ">
								<div>
									<p className="fs-3 mb-2 border-bottom border-dark-subtle"><strong>{t('timings', { ns: 'naming' })}</strong> </p>
									<p><strong className="me-1">{t('productionDays', { ns: 'naming' })}:</strong><span className={'font-size-medium'}>{productionDays}</span></p>
									<p><strong className="me-1">{t('shipmentDate', { ns: 'naming' })}:</strong>
										<Flatpickr
											id="shipmentDate"
											className="production-simulation-date"
											options={{
												disable:
													(this.props.supplierWorkdays && [
														this.removeFreeDays,
														...this.props.supplierHolidays,
													]) ||
													[],
												dateFormat: 'd/m/Y',
												locale: {
													firstDayOfWeek: 1,
												},
												clickOpens: productionDays != null ? true : false,
											}}
											value={shipmentDate}
											onChange={(e, dateStr) => {
												this.props.handleShipmentDate(e[0])
											}}
										/></p>
									<p><strong className="me-1">{t('arrivalDate', { ns: 'naming' })}:</strong><span className="font-size-medium">
										{isShipmentLate && <i className='bx bxs-error text-red' data-tip={t('late-shipment-message', { ns: 'naming' })} data-for={'isShipmentLateTooltip'}>
											<ReactTooltip
												id={'isShipmentLateTooltip'}
												place="bottom"
												type="info"
												effect="solid"
											/></i>}{' '}{arrivalDate}
									</span></p>
									<Switch
										id="disconformity"
										label={t('disconformity', { ns: 'naming' })}
										key="disconformity_btn"
										value={disconformity}
										onChange={e => {
											this.props.setDisconformity(!disconformity)
										}}
										size="lg"
										className="pt-2"
									/>

								</div>
							</Col>
							<Col className=" p-3 me-3 ">
								<div>
									<p className="fs-3 mb-2 border-bottom border-dark-subtle"><strong> {t('shipment', { ns: 'naming' })}</strong> </p>
									<p><strong className="me-1"> {t('weight', { ns: 'naming' })}:</strong><span className={'font-size-medium'}>
										{typeof weight === 'number'
											? weight > 1000
												? `${roundAtDecimals((weight / 1000), 1)} kg`
												: `${roundAtDecimals(weight, 0)} g`
											: weight}
									</span></p>
									<p><strong className="me-1">{t('volumetric-weight', { ns: 'naming' })}:</strong><span className={'font-size-medium'}>
										{typeof volumetricWeight === 'number'
											? volumetricWeight > 1
												? `${roundAtDecimals(volumetricWeight, 1)} kg`
												: `${roundAtDecimals(volumetricWeight * 1000, 0)} g`
											: volumetricWeight}
									</span></p>
									<p><strong className="me-1"> {t('shipmentCost', { ns: 'naming' })}:</strong>  <span className="font-size-medium">
										{(shipmentCost &&
											`${roundCurrency(shipmentCost)}${CURRENCY}`) || `0${CURRENCY}`}
									</span></p>
									<p><strong className="me-1">{t('processingCost', { ns: 'naming' })}:</strong> <span className="font-size-medium">
										{(processingCost &&
											`${roundCurrency(processingCost)}${CURRENCY}`) || `0${CURRENCY}`}
									</span></p>

								</div>
							</Col>
							<Col className=" p-3 me-3 ">
								<div>
									<p className="fs-3 mb-2 border-bottom border-dark-subtle"><strong>{t('costs', { ns: 'naming' })}</strong> </p>
									<p><strong className="me-1">{t('appliedDiscount', { ns: 'naming' })}:</strong><span className={'font-size-medium'}>{discountComponent}</span></p>
									<p><strong className="me-1"> {t('totalCost', { ns: 'naming' })}:</strong><span className="font-size-medium">{(typeof totalCost === 'number' &&
										`${roundCurrency(totalCost)}${CURRENCY}`) ||
										`0${CURRENCY}`}
									</span></p>
									<p><strong className="me-1">{t('partialSale', { ns: 'naming' })}:</strong><span className="font-size-medium">
										{(partialSale &&
											`${roundCurrency(partialSale)}${CURRENCY}`) ||
											`0${CURRENCY}`}
									</span></p>
									<p><strong className="me-1">{t('grossBenefit', { ns: 'naming' })}:</strong>
										<span className="font-size-medium">
											{grossBenefit < 0 && <i className='bx bxs-error text-red' data-tip={t('negative-benefit-message', { ns: 'naming' })} data-for={'isGrossBenefitNegativeTooltip'}>
												<ReactTooltip
													id={'isGrossBenefitNegativeTooltip'}
													place="bottom"
													type="info"
													effect="solid"
												/></i>}{' '}
											{((partialSale || disconformity) &&
												typeof totalCost === 'number' &&
												`${grossBenefit}${CURRENCY}`) || `0${CURRENCY}`}
										</span></p>
									<p><strong className="me-1"> {t('netBenefit', { ns: 'naming' })}:</strong><span className="font-size-medium">
										{netBenefit < 0 && <i className='bx bxs-error text-red' data-tip={t('negative-benefit-message', { ns: 'naming' })} data-for={'isNetBenefitNegativeTooltip'}>
											<ReactTooltip
												id={'isNetBenefitNegativeTooltip'}
												place="bottom"
												type="info"
												effect="solid"
											/></i>}{' '}{((partialSale || disconformity) &&
												typeof totalCost === 'number' &&
												`${netBenefit}${CURRENCY}`) ||
												`0${CURRENCY}`}
									</span></p>
									{customerShipmentPrice ? <p><strong className="me-1">{t('customerShipmentPrice', { ns: 'naming' })}:</strong><span className="font-size-medium">
										{(typeof customerShipmentPrice === 'number' &&
											`${roundCurrency(customerShipmentPrice)}${CURRENCY}`) || `0${CURRENCY}`}
									</span></p> : <span></span>}
									{minimumOrderPrice ? <p><strong className="me-1">{t('minimumOrderPrice', { ns: 'naming' })}:</strong><span className="font-size-medium">
										{(typeof minimumOrderPrice === 'number' &&
											`${roundCurrency(minimumOrderPrice)}${CURRENCY}`) || `0${CURRENCY}`}
									</span></p> : <span></span>}

								</div>
							</Col>
						</Row>
						<Row>
							<Col className="col-2 mb-3 justify-content-center">
								<Button

									color="primary"
									key="btn1"
									onClick={() => this.props.setModalOpen(true)}
									disabled={!this.props.isAnyPartSelected}
								>
									{t('launchProduction', { ns: 'naming' })}
								</Button>
							</Col>

							<Col className="col-3">
								<Select
									placeholder={t('select_a_supplier', { ns: 'naming' })}
									options={
										this.props.suppliers && this.props.suppliers.length > 0
											? this.parseSuppliers(this.props.suppliers)
											: [{ value: '', label: t('loading', { ns: 'naming' }) }]
									}
									onChange={this.props.onChangeSupplier}
									isDisabled={this.props.isAnyPartSelected}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}

ProductionTab.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
	orderDetails: PropTypes.shape({
		orderId: PropTypes.number,
		status: PropTypes.number,
		productionTime: PropTypes.number,
		orderDate: PropTypes.instanceOf(Date),
		totalPrice: PropTypes.number,
		paymentMethodId: PropTypes.number,
		customerShipmentDate: PropTypes.instanceOf(Date),
		customerShipmentPrice: PropTypes.number,
		minimumOrderPrice: PropTypes.number,
	}),
	productionSimulation: PropTypes.shape({
		productionDays: PropTypes.number,
		weight: PropTypes.number,
		volumetricWeight: PropTypes.number,
		shipmentCost: PropTypes.number,
		processingCost: PropTypes.number,
		totalCost: PropTypes.number,
		partialSale: PropTypes.number,
	}),
	handleShipmentDate: PropTypes.func,
	shipmentDate: PropTypes.string,
	arrivalDate: PropTypes.string,
	isShipmentLate: PropTypes.bool,
	disconformity: PropTypes.bool,
	setDisconformity: PropTypes.func,
	discountComponent: PropTypes.element,
	onChangeSupplier: PropTypes.func,
	suppliers: PropTypes.any,
	supplierWorkdays: PropTypes.any,
	supplierHolidays: PropTypes.any,
	setModalOpen: PropTypes.func,
	isAnyPartSelected: PropTypes.bool,
}
export default withTranslation()(ProductionTab)
