import { useHandleCancelableApi } from 'common/Hooks/UseHandleCancelableApi'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { getRegressionValueByQuantity } from 'store/actions'


export const CalculationMarginModal = ({ quoteId, getRegressionValueByQuantity, regressionValue, newMargins, t }) => {

	const [inputValue, setInputValues] = useState()
	const [isModalCalculationOpen, setIsOpenModalCalculation] = useState(false)

	const getBody = () => {
		const quantityToReturn = inputValue
		const dataInput = { data: { quantityToReturn: parseInt(quantityToReturn), quoteId, newMargins } }
		return ({ data: dataInput })
	}

	const { handleApiCall } = useHandleCancelableApi(getRegressionValueByQuantity, getBody(), 500)


	const maxQuantity = Math.max(...newMargins.map((max) => max.quantity))

	const handleInputChange = (e) => {
		let value = e.target.value
		if (value > maxQuantity) {
			value = maxQuantity
		}
		setInputValues(value)
	}


	useEffect(() => {
		if (inputValue && isModalCalculationOpen) {
			handleApiCall()
		}
	}, [inputValue, newMargins])



	return (
		<div className="modal-body mt-3">
			<button onClick={() => { setIsOpenModalCalculation(!isModalCalculationOpen) }} className='btn btn-primary'>{t('open_calculation_margin', { ns: 'naming' })}</button>
			{isModalCalculationOpen &&
				<div className="modal-body mt-3">
					<Row className="font-12-px">
						<Col className="col-3">
							<span className="w-100 bottom-text">
								{t('quantity', { ns: 'naming' })}
							</span>
						</Col>

						<Col className="col-3">
							<span className="w-100 bottom-text">
								{t('unit_cost', { ns: 'naming' })}

							</span>
						</Col>
						<Col className="col-3">
							<span className="w-100 bottom-text">
								{t('unit_price', { ns: 'naming' })}
							</span>
						</Col>
						<Col className="col-3">
							<span className="w-100 bottom-text">
								{t('totalPrice', { ns: 'naming' })}
							</span>
						</Col>

					</Row>
					<Row>
						<Col className="col-3">
							<input
								className="w-100 mb-3"
								value={inputValue}
								onChange={handleInputChange}
							/>
						</Col>
						<Col className="col-3">
							<input
								type="number"
								className="w-100 mb-3"
								disabled
								value={regressionValue.unitCost}
							/>
						</Col>
						<Col className="col-3">
							<input
								type="number"
								className="w-100 mb-3"
								disabled
								value={regressionValue.unitPrice}
							/>
						</Col>
						<Col className="col-3">
							<input
								type="number"
								className="w-100 mb-3"
								disabled
								value={regressionValue.totalPrice}
							/>
						</Col>
					</Row>
					<Row>
					</Row>
				</div>
			}
		</div>
	)
}

CalculationMarginModal.propTypes = {
	getRegressionValueByQuantity: PropTypes.func,
	quoteId: PropTypes.number,
	regressionValue: PropTypes.object,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({
	regressionValue: state.Quotes.regressionValue,
})

const mapDispatchToProps = { getRegressionValueByQuantity }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CalculationMarginModal))
