import PropTypes from 'prop-types'

export const ChatItemsSelector = ({ items, handleOnChangeItem, selectedItemId, sectionClassName }) => {

	return (
		<div className='row'>
			<section className={sectionClassName}>
				{items?.map((item) => {
					const isItemSelected = selectedItemId === item.id
					return (
						<>
							<button
								className={'btn-item'}
								onClick={() => handleOnChangeItem(item.id)}
							>
								<span className={`d-flex align-items-center flex-row p-1 px-3 ${isItemSelected ? 'border-bottom border-2 border-warning' : ''}`}>
									{item.hasMessages && <i className={`bx bxs-circle me-1 ${item.hasUnreadMessages ? 'text-danger' : 'text-secondary'}`}></i>}
									{item?.label || item?.id}
								</span>
							</button>
						</>
					)
				})}
			</section>
		</div>
	)
}

ChatItemsSelector.propTypes = {
	items: PropTypes.array,
	handleOnChangeItem: PropTypes.func,
	selectedItemId: PropTypes.number,
	sectionClassName: PropTypes.string,
}

export default ChatItemsSelector
