import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import OptionsSelector from 'components/Common/OptionsSelector'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getOffersDashboard } from 'store/actions'
import { getPreviousDateRange } from '../utils'
import CustomersDashboard from './CustomersDashboard'
import OffersDashBoard from './OffersDashBoard'
import OffersSidebar from './OffersSidebar'

export const OffersDashboardContainer = ({ getOffersDashboard, t, dateRange, filters, error, isLoading }) => {

	const OFFERS_CHART = 1
	const CUSTOMER_CHART = 2
	const CHART_OPTIONS = [{ label: 'offers', value: OFFERS_CHART, activeColorClassName: 'offer-option-selector' }, { label: 'customers', value: CUSTOMER_CHART, activeColorClassName: 'offer-option-selector' }]

	const [activeChart, setActiveChart] = useState(OFFERS_CHART)

	useEffect(() => {
		if (dateRange != null) {
			const previousDateRange = getPreviousDateRange(dateRange)
			const data = {
				previousDateRange,
				dateRange,
				filters,
			}
			getOffersDashboard(data)
		}
	}, [dateRange, filters])

	return (
		<div style={{ height: '44rem' }} className='d-flex justify-content-center w-100 align-items-center'>
			{error ? <div className='h-auto mt-4'><Alert message={error} type='danger' /></div> : isLoading ? <Loader className='font-size-50' /> :
				<div className='d-flex col justify-content-center mt-4'>
					<div className='col-10 mx-2 d-flex flex-column h-100'>
						<div className='d-flex justify-content-end me-4'>
							<OptionsSelector options={CHART_OPTIONS} setValue={setActiveChart} value={activeChart} />
						</div>
						<div>
							{activeChart === OFFERS_CHART && <OffersDashBoard dateRange={dateRange} />}
							{activeChart === CUSTOMER_CHART && <CustomersDashboard />}
						</div>
					</div>
					<div className='col-1 ms-2 d-flex align-items-center '>
						<OffersSidebar dateRange={dateRange} />
					</div>
				</div>
			}
		</div>
	)
}

OffersDashboardContainer.propTypes = {
	getOffersDashboard: PropTypes.func,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({
	error: state.Offers.error,
	isLoading: state.Offers.isLoading,
})

const mapDispatchToProps = { getOffersDashboard }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OffersDashboardContainer))
