import DatePickr from 'components/Common/DatePickr'
import Modal from 'components/Common/Modal/Modal'
import TextInput from 'components/Common/TextInput'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import ButtonsList from './ButtonsSelectionOptions'
import { getVoucherDate } from './DiscountVoucher'
import {
	AMOUNT,
	FREE_SHIPPING,
	GIFT,
	NO_MINIMUM_PRICE,
	PERCENTAGE,
	voucherTypes,
} from './VoucherTypes'

const DiscountVoucherModal = ({
	t,
	discountVoucherToUpdate,
	isOpenModal,
	setIsOpenModal,
	createVoucher,
	updateVoucher,
}) => {
	const defaultVoucher = {
		code: null,
		voucherTypeId: voucherTypes.PERCENTAGE,
		voucherValue: null,
		description: null,
		maxUsages: null,
		expirationDate: getVoucherDate(new Date(), 'es'),
		creationDate: getVoucherDate(new Date(), 'es'),
	}
	const [discountVoucher, setDiscountVoucher] = useState({ ...defaultVoucher })
	const [isAnUpdate, setIsAnUpdate] = useState(false)
	const [hasPressed, sethasPressed] = useState(false)

	useEffect(() => {
		if (discountVoucherToUpdate != null) {
			if (Object.entries(discountVoucherToUpdate).length !== 0) {
				setDiscountVoucher(parseVoucher(discountVoucherToUpdate))
				setIsAnUpdate(true)
			}
		} else {
			setDiscountVoucher(defaultVoucher)
			setIsAnUpdate(false)
		}
	}, [discountVoucherToUpdate])

	const parseVoucher = discountVoucher => {
		return {
			...discountVoucher,
			expirationDate: getVoucherDate(discountVoucher.expirationDate, 'es'),
			creationDate: getVoucherDate(discountVoucher.creationDate, 'es'),
		}
	}

	const codeTypeOptions = [
		{
			value: voucherTypes.PERCENTAGE,
			label: PERCENTAGE,
		},
		{
			value: voucherTypes.AMOUNT,
			label: AMOUNT,
		},
		{
			value: voucherTypes.FREE_SHIPPING,
			label: FREE_SHIPPING,
		},
		{
			value: voucherTypes.NO_MINIMUM_PRICE,
			label: NO_MINIMUM_PRICE,
		},
		{
			value: voucherTypes.GIFT,
			label: GIFT,
		},
	]

	const handleOnChangeElement = (element, value) => {
		switch (element) {
			case 'voucherTypeId':
				changeVoucherType(value, discountVoucher.voucherValue, element)
			case 'voucherValue':
				changeVoucherValue(discountVoucher.voucherTypeId, value, element)
				return
			case 'maxUsages':
				setDiscountVoucher({
					...discountVoucher,
					[element]: Number(value),
				})
				return
			default:
				setDiscountVoucher({
					...discountVoucher,
					[element]: value,
				})
		}
	}

	const changeVoucherType = (codeType, value, element) => {
		if (!isValueInRange(codeType, value)) {
			setDiscountVoucher({
				...discountVoucher,
				['voucherValue']: null,
				[element]: codeType,
			})
		} else {
			setDiscountVoucher({
				...discountVoucher,
				[element]: codeType,
			})
		}
	}

	const changeVoucherValue = (codeType, value, element) => {
		if (isValueInRange(codeType, value)) {
			setDiscountVoucher({
				...discountVoucher,
				[element]: Number(value),
			})
		}
	}

	const isValueInRange = (codeType, value) => {
		if (codeType == NO_MINIMUM_PRICE || codeType == FREE_SHIPPING) {
			return false
		} else {
			if (codeType == PERCENTAGE) {
				if (value > 0 && value <= 100) {
					return true
				}
			} else {
				if (value > 0) {
					return true
				}
			}
		}
		return false
	}

	const parseVoucherDate = (dateString) => {
		const dateData = dateString.split('/')
		return new Date(`${dateData[2]}-${dateData[1]}-${dateData[0]}`)
	}

	const saveData = () => {
		if (!thereAreNulls(discountVoucher)) {
			if (isAnUpdate) {
				updateVoucher({
					voucher: {
						...discountVoucher,
						creationDate: discountVoucher.creationDate && parseVoucherDate(discountVoucher.creationDate),
						expirationDate: discountVoucher.expirationDate && parseVoucherDate(discountVoucher.expirationDate),
					},
				})
				closeModal()
			} else {
				createVoucher({
					voucher: {
						...discountVoucher,
						creationDate: parseVoucherDate(discountVoucher.creationDate),
						expirationDate: parseVoucherDate(discountVoucher.expirationDate),
					},
				})
				closeModal()
			}
		}
		sethasPressed(true)
	}
	const thereAreNulls = discountVoucher => {
		const arrayVoucher = Object.entries(discountVoucher)
		const invalidFields = arrayVoucher.filter(([key, value]) => {
			if (value == '' || value == null) {
				if (
					(key == 'voucherValue' &&
						(discountVoucher.voucherTypeId == voucherTypes.FREE_SHIPPING ||
							discountVoucher.voucherTypeId == voucherTypes.NO_MINIMUM_PRICE ||
							discountVoucher.voucherTypeId == voucherTypes.GIFT)) ||
					key == 'description'
				) {
					return false
				} else {
					return true
				}
			}
			return false
		})
		return invalidFields.length > 0
	}
	const closeModal = () => {
		setIsOpenModal(false)
		sethasPressed(false)
		setDiscountVoucher(defaultVoucher)
	}

	return (
		<div>
			{
				<Modal
					isOpen={isOpenModal}
					closeModal={() => closeModal()}
					title={
						discountVoucherToUpdate
							? t('modifyVoucher', { ns: 'naming' })
							: t('createVoucher', { ns: 'naming' })
					}
					body={
						<div className="p-4">
							{isAnUpdate ? <div>
								<h6 className="m-2">{t('code', { ns: 'naming' })}</h6>
								<h6 className="m-2">{discountVoucher.code}</h6>
							</div>
								:
								<TextInput
									value={discountVoucher.code}
									isRequired={true}
									hasPressed={hasPressed}
									setValue={handleOnChangeElement}
									title={t('code', { ns: 'naming' })}
									type="text"
									objectElement={'code'}
								/>
							}
							<div className="d-flex flex-column">
								<h6 className="m-2">{t('voucherType', { ns: 'naming' })}</h6>
								<ButtonsList
									buttons={codeTypeOptions}
									handleOnChange={handleOnChangeElement}
									firstButtonActive={discountVoucher.voucherTypeId}
								/>
							</div>
							{discountVoucher.voucherTypeId != voucherTypes.NO_MINIMUM_PRICE &&
								discountVoucher.voucherTypeId != voucherTypes.FREE_SHIPPING &&
								discountVoucher.voucherTypeId != voucherTypes.GIFT && (
									<TextInput
										value={discountVoucher.voucherValue}
										setValue={handleOnChangeElement}
										isRequired={true}
										hasPressed={hasPressed}
										title={t('voucherValue', { ns: 'naming' })}
										type="number"
										objectElement={'voucherValue'}
									/>
								)}
							<DatePickr
								value={discountVoucher.creationDate}
								setValue={handleOnChangeElement}
								isRequired={true}
								hasPressed={hasPressed}
								title={t('creationDate', { ns: 'naming' })}
								format={'d/m/Y'}
								mode="single"
								objectElement={'creationDate'}
								isDateStr
							/>
							<DatePickr
								value={discountVoucher.expirationDate}
								setValue={handleOnChangeElement}
								isRequired={true}
								hasPressed={hasPressed}
								title={t('expirationDate', { ns: 'naming' })}
								format={'d/m/Y'}
								mode="single"
								objectElement={'expirationDate'}
								isDateStr
							/>
							<TextInput
								value={discountVoucher.description}
								setValue={handleOnChangeElement}
								title={t('description', { ns: 'naming' })}
								type="text"
								objectElement={'description'}
							/>
							<TextInput
								value={discountVoucher.maxUsages}
								setValue={handleOnChangeElement}
								title={t('maxUsages', { ns: 'naming' })}
								isRequired={true}
								hasPressed={hasPressed}
								type="number"
								objectElement={'maxUsages'}
							/>
						</div>
					}
					buttons={[
						<button
							type="button"
							key="btn2"
							className="btn btn-primary"
							onClick={e => saveData()}
						>
							{isAnUpdate ? t('saveChanges', { ns: 'naming' }) : t('create-voucher', { ns: 'naming' })}
						</button>,
					]}
				/>
			}
		</div>
	)
}
DiscountVoucherModal.propTypes = {
	t: PropTypes.func,
	discountVoucherToUpdate: PropTypes.object,
	isOpenModal: PropTypes.bool,
	setIsOpenModal: PropTypes.func,
	createVoucher: PropTypes.func,
	updateVoucher: PropTypes.func,
}
export default withTranslation()(DiscountVoucherModal)
