import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ChatModal from '../Modals/ChatModal'

export const PartChat = ({ t, partId, chatMessages, suppliers }) => {

	const [isChatModalOpen, setIsChatModalOpen] = useState(false)

	const partSuppliers = useMemo(() => {
		if (suppliers.length != 0) {
			const supplierIds = chatMessages.map((chat) => chat[0]?.supplierId)
			return suppliers.filter((supplier) => supplierIds.includes(supplier.id))
		}
	}, [suppliers])

	return (
		<>
			<i className={`bx ${chatMessages.length != 0 ? 'bxs-chat' : 'bx-chat'} fs-2 cursor-pointer`} onClick={() => setIsChatModalOpen(true)} ></i>
			<ChatModal
				isOpen={isChatModalOpen}
				closeModal={() => setIsChatModalOpen(false)}
				partId={partId}
				messages={chatMessages.flat()}
				suppliers={partSuppliers}
			/>
		</>
	)
}

const mapStateToProps = state => {
	return {
		suppliers: state.Users.suppliers,
	}
}

PartChat.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	partId: PropTypes.number,
	chatMessages: PropTypes.array,
	supplierIds: PropTypes.array,
	suppliers: PropTypes.array,
}

export default connect(mapStateToProps, {})(withTranslation()(PartChat))
