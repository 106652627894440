import {
	CLEAR_QUOTE_ERROR,
	CLONE_QUOTE,
	CLONE_QUOTE_FAIL,
	CLONE_QUOTE_SUCCESS,
	CREATE_QUOTE,
	CREATE_QUOTE_FAIL,
	CREATE_QUOTE_SUCCESS,
	ERASE_SUPPLIER_ID_VIEW,
	GET_CLIENT_MARGIN,
	GET_CLIENT_MARGIN_FAIL,
	GET_CLIENT_MARGIN_SUCCESS,
	GET_PART_COSTS_BY_SUPPLIER_ID,
	GET_PART_COSTS_BY_SUPPLIER_ID_FAIL,
	GET_PART_COSTS_BY_SUPPLIER_ID_SUCCESS,
	GET_PART_PRICES_BY_SUPPLIER_ID,
	GET_PART_PRICES_BY_SUPPLIER_ID_FAIL,
	GET_PART_PRICES_BY_SUPPLIER_ID_SUCCESS,
	GET_QUOTE_CHAT,
	GET_QUOTE_CHAT_FAIL,
	GET_QUOTE_CHAT_SUCCESS,
	GET_QUOTE_DETAIL,
	GET_QUOTE_DETAIL_FAIL,
	GET_QUOTE_DETAIL_SUCCESS,
	GET_QUOTE_LIST,
	GET_QUOTE_LIST_COUNT,
	GET_QUOTE_LIST_COUNT_FAIL,
	GET_QUOTE_LIST_COUNT_SUCCESS,
	GET_QUOTE_LIST_FAIL,
	GET_QUOTE_LIST_SUCCESS,
	GET_QUOTE_REGRESSIONS_BY_PART_ID,
	GET_QUOTE_REGRESSIONS_BY_PART_ID_FAIL,
	GET_QUOTE_REGRESSIONS_BY_PART_ID_SUCCESS,
	GET_REGRESSION_VALUE_BY_QUANTITY,
	GET_REGRESSION_VALUE_BY_QUANTITY_FAIL,
	GET_REGRESSION_VALUE_BY_QUANTITY_SUCCESS,
	GET_SIMILAR_QUOTE_PARTS,
	GET_SIMILAR_QUOTE_PARTS_FAIL,
	GET_SIMILAR_QUOTE_PARTS_SUCCESS,
	MARK_QUOTE_MESSAGES_AS_READ,
	MARK_QUOTE_MESSAGES_AS_READ_FAIL,
	MARK_QUOTE_MESSAGES_AS_READ_SUCCESS,
	POST_QUOTE_CHAT,
	POST_QUOTE_CHAT_FAIL,
	POST_QUOTE_CHAT_SUCCESS,
	RESET_CREATE_QUOTE_MODAL,
	SAVE_AND_CONFIRM_QUOTE,
	SAVE_AND_CONFIRM_QUOTE_FAIL,
	SAVE_AND_CONFIRM_QUOTE_SUCCESS,
	SET_SUPPLIER_ID_VIEW,
	UPDATE_QUOTE_QUANTITIES,
	UPDATE_QUOTE_QUANTITIES_FAIL,
	UPDATE_QUOTE_QUANTITIES_SUCCESS
} from './actionTypes'


export const getRegressionValueByQuantity = (data, signal) => ({
	type: GET_REGRESSION_VALUE_BY_QUANTITY,
	payload: data, signal,
})

export const getRegressionValueByQuantitySuccess = ({ unitPrice, totalPrice, unitCost, totalCost }) => {
	return {
		type: GET_REGRESSION_VALUE_BY_QUANTITY_SUCCESS,
		payload: { unitPrice, totalPrice, unitCost, totalCost }
	}

}


export const getRegressionValueByQuantityFail = error => {
	return {
		type: GET_REGRESSION_VALUE_BY_QUANTITY_FAIL,
		payload: error,
	}
}

export const getQuoteRegressionsByPartId = partId => ({
	type: GET_QUOTE_REGRESSIONS_BY_PART_ID,
	payload: { partId },
})

export const getQuoteRegressionsByPartIdSuccess = data => {
	return {
		type: GET_QUOTE_REGRESSIONS_BY_PART_ID_SUCCESS,
		payload: { regressions: { ...data } },
	}
}

export const getQuoteRegressiosnByPartIdFail = error => ({
	type: GET_QUOTE_REGRESSIONS_BY_PART_ID_FAIL,
	payload: error,
})

export const getQuoteList = input => ({
	type: GET_QUOTE_LIST,
	payload: { input },
})

export const getQuoteListSuccess = ({ quotes, totalRows }) => ({
	type: GET_QUOTE_LIST_SUCCESS,
	payload: { quotes, totalRows },
})

export const getQuoteListFail = error => ({
	type: GET_QUOTE_LIST_FAIL,
	payload: error,
})

export const getQuoteListCount = () => ({
	type: GET_QUOTE_LIST_COUNT,
})

export const getQuoteListSuccessCount = ({ data: { count } }) => ({
	type: GET_QUOTE_LIST_COUNT_SUCCESS,
	payload: { count },
})

export const getQuoteListFailCount = error => ({
	type: GET_QUOTE_LIST_COUNT_FAIL,
	payload: error,
})

export const getQuoteDetail = (quoteId, supplierId) => ({
	type: GET_QUOTE_DETAIL,
	payload: { quoteId, supplierId },
})

export const getQuoteDetailSuccess = payload => ({
	type: GET_QUOTE_DETAIL_SUCCESS,
	payload,
})

export const getQuoteDetailFail = error => ({
	type: GET_QUOTE_DETAIL_FAIL,
	payload: error,
})

export const updateQuoteQuantities = quote => {
	return {
		type: UPDATE_QUOTE_QUANTITIES,
		payload: { quote },
	}
}

export const updateQuoteQuantitiesSuccess = payload => ({
	type: UPDATE_QUOTE_QUANTITIES_SUCCESS,
	payload,
})

export const updateQuoteQuantitiesFail = error => ({
	type: UPDATE_QUOTE_QUANTITIES_FAIL,
	payload: error,
})

export const createQuote = ({ data }) => ({
	type: CREATE_QUOTE,
	payload: { data },
})

export const createQuoteSuccess = () => ({
	type: CREATE_QUOTE_SUCCESS,
})

export const createQuoteFail = () => ({
	type: CREATE_QUOTE_FAIL,
})
export const resetCreateQuoteModal = () => ({
	type: RESET_CREATE_QUOTE_MODAL,
})
export const setSupplierIdView = supplierId => ({
	type: SET_SUPPLIER_ID_VIEW,
	payload: { supplierId },
})
export const eraseSupplierIdView = () => ({
	type: ERASE_SUPPLIER_ID_VIEW,
})
export const clearQuoteError = () => ({
	type: CLEAR_QUOTE_ERROR,
})
export const getQuoteChat = (input, signal) => ({
	type: GET_QUOTE_CHAT,
	payload: { input, signal },
})
export const getQuoteChatSuccess = payload => ({
	type: GET_QUOTE_CHAT_SUCCESS,
	payload,
})
export const getQuoteChatFail = error => ({
	type: GET_QUOTE_CHAT_FAIL,
	payload: error,
})
export const postQuoteChat = (data) => ({
	type: POST_QUOTE_CHAT,
	payload: { data },
})
export const postQuoteChatSuccess = payload => ({
	type: POST_QUOTE_CHAT_SUCCESS,
	payload,
})
export const postQuoteChatFail = error => ({
	type: POST_QUOTE_CHAT_FAIL,
	payload: error,
})
export const markQuoteMessagesAsRead = (data) => ({
	type: MARK_QUOTE_MESSAGES_AS_READ,
	payload: { data },
})
export const markQuoteMessagesAsReadSuccess = payload => ({
	type: MARK_QUOTE_MESSAGES_AS_READ_SUCCESS,
	payload,
})
export const markQuoteMessagesAsReadFail = error => ({
	type: MARK_QUOTE_MESSAGES_AS_READ_FAIL,
	payload: error,
})
export const saveAndConfirmQuote = quote => ({
	type: SAVE_AND_CONFIRM_QUOTE,
	payload: { quote },
})
export const saveAndConfirmQuoteSuccess = (response) => ({
	type: SAVE_AND_CONFIRM_QUOTE_SUCCESS,
	payload: { response },
})
export const saveAndConfirmQuoteFail = error => ({
	type: SAVE_AND_CONFIRM_QUOTE_FAIL,
	payload: error,
})

export const getPartCostsBySupplierId = (partId, supplierId) => ({
	type: GET_PART_COSTS_BY_SUPPLIER_ID,
	payload: { partId, supplierId },
})

export const getPartCostsBySupplierIdSuccess = payload => ({
	type: GET_PART_COSTS_BY_SUPPLIER_ID_SUCCESS,
	payload,
})

export const getPartCostsBySupplierIdFail = error => ({
	type: GET_PART_COSTS_BY_SUPPLIER_ID_FAIL,
	payload: error,
})

export const getPartPricesBySupplierId = (partId, supplierId) => ({
	type: GET_PART_PRICES_BY_SUPPLIER_ID,
	payload: { partId, supplierId },
})

export const getPartPricesBySupplierIdSuccess = payload => ({
	type: GET_PART_PRICES_BY_SUPPLIER_ID_SUCCESS,
	payload,
})

export const getPartPricesBySupplierIdFail = error => ({
	type: GET_PART_PRICES_BY_SUPPLIER_ID_FAIL,
	payload: error,
})

export const getClientMargin = () => {
	return {
		type: GET_CLIENT_MARGIN,
	}
}

export const getClientMarginSuccess = ({ clientMargin }) => ({
	type: GET_CLIENT_MARGIN_SUCCESS,
	payload: { clientMargin },
})

export const getClientMarginFail = error => ({
	type: GET_CLIENT_MARGIN_FAIL,
	payload: error,
})

export const cloneQuote = input => ({
	type: CLONE_QUOTE,
	payload: { input },
})
export const cloneQuoteSuccess = (response) => ({
	type: CLONE_QUOTE_SUCCESS,
	payload: { response },
})
export const cloneQuoteFail = error => ({
	type: CLONE_QUOTE_FAIL,
	payload: { error },
})

export const getSimilarQuoteParts = (partIds, supplierId) => ({
	type: GET_SIMILAR_QUOTE_PARTS,
	payload: { partIds, supplierId },
})

export const getSimilarQuotePartsSuccess = (parts) => ({
	type: GET_SIMILAR_QUOTE_PARTS_SUCCESS,
	payload: parts,
})

export const getSimilarQuotePartsFail = (error) => ({
	type: GET_SIMILAR_QUOTE_PARTS_FAIL,
	payload: error,
})
