export const PACKAGING = {
	DOCUMENT: 0,
	BAG: 1,
	BOX: 2,
}
export const DEL_CLI = '0802' // agencia del cliente
export const NUM_CLI = '00654' // nº abonado nacex
export const E_NACEX = '27' // código de servicio nacex 
export const NACEX_10 = '01'
export const NACEX_12 = '02'

export const USER = 'TEST_IMAS654'
export const PASS = '297F30FAAF93D22FC930722633579F13'
