import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PartCard from 'components/Common/PartCard/PartCard'
import { PartName } from 'components/Common/PartName'
import { defaultQuantites } from 'constants/defaultQuantities'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import { createPartsQuantities } from 'store/parts/actions'

const EditQuantitiesModal = ({
	isEditQuantitiesModalOpen,
	closeEditQuantitiesModal,
	selectedRows,
	setModalMessage,
	t,
	createPartsQuantities,
	createPartsQuantitiesSuccess,
	isLoading,
	error,
}) => {

	const [newQuantities, setNewQuantities] = useState('')
	const [partIds, setPartIds] = useState([])
	const [originalQuantities, setOriginalQuantities] = useState([])
	const [partsToRender, setPartsToRender] = useState([])
	const [quantityOptions, setQuantityOptions] = useState([])

	useEffect(() => {
		if (partsToRender.length == 0) {
			setPartsToRender(mapParts())
		}
	}, [isEditQuantitiesModalOpen])

	useEffect(() => {
		if (originalQuantities.length != 0) {
			setQuantityOptions(parseQuantityOptions(defaultQuantites))
		}
	}, [originalQuantities])

	useEffect(() => {
		if (createPartsQuantitiesSuccess) {
			setModalMessage(t('success-create-quantities', { ns: 'naming' }))
		}
		if (error) {
			setModalMessage(t('error-create-quantities', { ns: 'errors' }))
		}
	}, [createPartsQuantitiesSuccess, error])

	const mapParts = () => {
		const partsToRender = []
		const originalPartQuantities = []
		const originalPartIds = []
		for (const row of selectedRows) {
			if (row.status.value.id == 10 && row.configuration.technologyTypeId != 4) {
				partsToRender.push(
					<div key={row.id.value}>
						<PartCard
							key={row.id.value}
							img={row.fileLinks.thumbnail}
							stl={row.fileLinks.stlModel}
							stlData={{
								partId: row.id.value,
								partName: row.fileName,
								size: row.size,
								weight: row.weight,
							}}
							partId={row.id.value}
							fileName={<PartName name={row.fileName} maxLength={19} />}
							quantities={row.quantities.sort((a, b) => { return a - b })}
						/>
					</div>,
				)
				originalPartIds.push(row.id.value)
				originalPartQuantities.push(...row.quantities)
			}
		}
		if (originalPartQuantities.length != 0) {
			const uniquePartQuantities = Array.from(new Set(originalPartQuantities))
			setOriginalQuantities(uniquePartQuantities)
		}
		if (originalPartIds.length != 0) {
			setPartIds(originalPartIds)
		}
		return partsToRender
	}

	const parseQuantityOptions = (values) => {
		return values.filter(value => !originalQuantities.includes(value),
		).map(value => {
			return { label: value, value: value }
		})
	}

	const parseNewQuantities = (newQuantities) => {
		let finalValue = ''
		newQuantities.sort((a, b) => { return a.value - b.value }).forEach((element, idx, array) => {
			if (idx === array.length - 1) {
				finalValue += element.value ? element.value : element
			} else {
				finalValue += element.value ? element.value + ',' : element + ','
			}
		})
		setNewQuantities(finalValue)
	}

	const parseQuantities = () => {
		const quantities = []
		newQuantities.split(',').forEach((element, idx, array) => {
			quantities.push(Number(element))
		})
		return quantities
	}

	const handleSaveQuantities = () => {
		const quantities = parseQuantities()
		createPartsQuantities({ partIds, quantities })
	}

	return <div>
		<Modal
			isOpen={isEditQuantitiesModalOpen}
			closeModal={closeEditQuantitiesModal}
			title={t('editQuantities', { ns: 'naming' })}
			className={partsToRender?.length > 3 ? 'modal-lg' : ''}
			body={
				<div>
					{partsToRender?.length > 0 ?
						<div className="d-flex flex-column p-2">
							<div className="d-flex flex-row flex-wrap justify-content-center pb-3 border-bottom border-grey ">{partsToRender}</div>
							<Select
								isMulti
								name="colors"
								options={quantityOptions}
								className="basic-multi-select"
								classNamePrefix="select"
								onChange={(e) => parseNewQuantities(e)}
							/>
						</div>
						:
						<div className="p-4"><h3>{t('at_least_one_valid_part', { ns: 'naming' })}</h3></div>
					}
				</div>
			}
			buttons={[
				<button
					type="button"
					key="saveQuantitiesButton"
					disabled={newQuantities == ''}
					className="btn btn-primary"
					onClick={() => handleSaveQuantities()}
				>
					{isLoading ? <Loader /> : t('save', { ns: 'naming' })}
				</button>,
			]}
		/>
	</div>
}

const mapStateToProps = state => {
	return {
		createPartsQuantitiesSuccess: state.Parts.createPartsQuantitiesSuccess,
		isLoading: state.Parts.isLoading,
		error: state.Parts.errorQuantities,
	}
}

EditQuantitiesModal.propTypes = {
	isEditQuantitiesModalOpen: PropTypes.bool,
	closeEditQuantitiesModal: PropTypes.func,
	selectedRows: PropTypes.array,
	setModalMessage: PropTypes.func,
	t: PropTypes.func,
	createPartsQuantities: PropTypes.func,
	createPartsQuantitiesSuccess: PropTypes.bool,
	isLoading: PropTypes.bool,
	error: PropTypes.any,
}

export default connect(mapStateToProps, { createPartsQuantities })(withTranslation()(EditQuantitiesModal))
