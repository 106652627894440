import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import Select from 'components/Common/Select/Select'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { updateProductionItem } from 'store/actions'

const useSimulateScanModalForReception = ({
	order,
	afterSuccess,
}) => {

	const { t } = useTranslation('naming')
	const updateProductionItemCallStatus = useSelector(state => state.Productions.updateProductionItemCallStatus)
	const dispatch = useDispatch()

	const [orderItem, setOrderItem] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [quantitySelected, setQuantitySelected] = useState(1)
	const [orderFragmentIdSelected, setOrderFragmentIdSelected] = useState(null)
	useEffect(() => {
		if (!isOpen) return
		if (updateProductionItemCallStatus.loading == false) {
			if (updateProductionItemCallStatus.success == true) {
				// EXITO: Cierra el modal y actualiza el front para no tener que recargar la página para ver los cambios:
				setIsOpen(false)
				afterSuccess({
					partId: orderItem.partId,
					quantity: quantitySelected,
					orderFragmentId: orderFragmentIdSelected,
				})
			} else if (updateProductionItemCallStatus.error != null) {
				// ERROR: Muestra un mensaje de error:
				alert(t('error_message'))
			}
		}
	}, [updateProductionItemCallStatus.loading])

	const productionOptions = useMemo(() => {
		if (orderItem == null || order.productions == null) return []
		const productionsOfThisOrderItemByPartId = order.productions
			.filter(p => p.productionItemList
				.find(pi => pi.part.id == orderItem.partId && pi.receivedQuantity < pi.totalQuantity))
		return Array.from({ length: productionsOfThisOrderItemByPartId.length })
			?.map((_, i) => {
				const production = productionsOfThisOrderItemByPartId[i]
				return {
					label: production.displayId,
					value: production.orderFragmentId,
				}
			})
	}, [orderItem])

	const productionItemSelected = useMemo(() => {
		if (orderItem == null || order.productions == null || orderFragmentIdSelected == null) return []
		return order.productions.find(p => p.orderFragmentId == orderFragmentIdSelected).productionItemList.find(pi => pi.part.id == orderItem.partId)
	}, [orderItem, orderFragmentIdSelected])

	const quantityOptions = useMemo(() => {
		if (order.productions == null || orderFragmentIdSelected == null) return []
		const productionItem = order.productions
			.find(p => p.orderFragmentId == orderFragmentIdSelected).productionItemList
			.find(pi => pi.part.id == orderItem.partId)

		const receivedQuantityInTotal = productionItem.receivedQuantity
		const totalQuantityInTotal = productionItem.totalQuantity
		const pendingQuantity = totalQuantityInTotal - receivedQuantityInTotal >= 0 ? totalQuantityInTotal - receivedQuantityInTotal : 0
		// Las opciones del selector serán las posibles cantidades a escanear (del 1 al numero pendiente que toque):


		const options = Array.from({
			length: pendingQuantity,
		})?.map((_, i) => ({
			label: i + 1, value: i + 1,
		}))

		return options

	}, [productionOptions, orderFragmentIdSelected, order.productions, orderItem])

	const SimulateScanModalForReception = useMemo(() => (
		<Modal
			isOpen={isOpen}
			closeModal={() => setIsOpen(false)}
			title={t('scanPart')}
			closeButtonColor="secondary"
			body={
				<div>
					<div className='px-5 my-3' style={{
						display: 'grid',
						gridTemplateColumns: 'repeat(3, 1fr)',
						gap: '1rem',
						alignItems: 'center',
						width: '100%',
					}}>
						<div>
							{t('production')}{':'}
						</div>
						<Select
							value={orderFragmentIdSelected}
							onChange={e => setOrderFragmentIdSelected(Number(e.currentTarget.value))}
							options={productionOptions}
							classNamePrefix="select2-selection"
						/>
						<div />
						<div>
							{t('quantity')}{':'}
						</div>
						<Select
							value={quantitySelected}
							onChange={e => setQuantitySelected(Number(e.currentTarget.value))}
							options={quantityOptions}
							classNamePrefix="select2-selection"
							disabled={orderFragmentIdSelected == null}
						/>
						<div />
					</div>
				</div>
			}
			buttons={[(
				<button
					key={'saveScannedQuantityButton'}
					className='btn btn-primary'
					onClick={() => {
						dispatch(updateProductionItem({
							productionItem: {
								orderId: orderItem.orderId,
								orderFragmentId: orderFragmentIdSelected,
								receivedQuantity: (productionItemSelected.receivedQuantity || 0) + quantitySelected,
								part: {
									id: orderItem.partId,
								},
							},
						}))
					}}
					disabled={updateProductionItemCallStatus.loading}
				>
					{updateProductionItemCallStatus.loading ? (
						<div className="d-flex justify-content-center align-items-center">
							<Loader />
						</div>
					) : t('save')}
				</button>
			)]}
		/>
	), [
		isOpen,
		orderItem,
		quantityOptions,
		quantitySelected,
	])

	return {
		openSimulateScanModal: (orderItem) => {
			setIsOpen(true)
			setOrderItem(orderItem)
		},
		SimulateScanModalForReception,
	}
}

export default useSimulateScanModalForReception
