import Table from 'components/Table/Table'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const ProductionPartsTable = ({ t, partsList }) => {
	const header = {
		partId: t('part-id', { ns: 'naming' }),
		part: t('part', { ns: 'naming' }),
		technology: t('technology', { ns: 'naming' }),
		files: t('files', { ns: 'naming' }),
		description: t('description', { ns: 'naming' }),
		price: t('price', { ns: 'naming' }),
		quantity: t('quantity', { ns: 'naming' }),
		total: t('total', { ns: 'naming' }),
		// chat: t('chat', { ns: 'naming' }), --> TODO ProductionChat no funciona todavía
		quoteId: t('quote-id', { ns: 'naming' }),
	}
	return (
		<div className="mb-4 px-3">
			{partsList?.length !== 0 &&
				<Table
					disableInteractions
					header={header}
					items={partsList}
					selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
					language={'en'}
					rowQuantityList={[{ text: '32', value: 32 }]}
					allowAllItemsPerPage
					paginationProps={{
						custom: true,
						sizePerPage: 200,
					}}
					defaultSort={{ dataField: 'id', order: 'asc' }}
					styleForFieldList={[
						{
							field: 'description',
							styles: [
								{
									type: 'list',
									class: 'd-block',
								},
							],
						},
					]}
				/>
			}
		</div>
	)
}
ProductionPartsTable.propTypes = {
	t: PropTypes.func,
	partsList: PropTypes.array,
}
export default withTranslation()(ProductionPartsTable)
