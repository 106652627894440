import classnames from 'classnames'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import TextInput from 'components/Common/TextInput'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { Collapse } from 'reactstrap'
import { updateOrderShippingAddress } from 'store/actions'
import CountryFlag from '../CRMUserDetail/ComponenteTablas/CountryFlag'
import PrefixFlag from '../CRMUserDetail/ComponenteTablas/prefixFlagComponent'

const OrderClientData = ({
	t,
	history,
	orderId,
	name,
	organization,
	vat,
	address,
	postalCode,
	city,
	province,
	country,
	email,
	phone,
	shippingName,
	shippingLastName,
	shippingOrganization,
	shippingAddress,
	shippingPostalCode,
	shippingCity,
	shippingProvince,
	shippingCountry,
	shippingEmail,
	shippingPrefix,
	shippingPhone,
	updateOrderShippingAddress,
	orderShippingAddress,
	isLoading,
	accountId,
	orderWeight,
}) => {

	const [col1, setCol1] = useState(true)
	const [col2, setCol2] = useState(false)
	const [isOpenShippingModal, setOpenShippingModal] = useState(false)
	const [hasPressed, setHasPressed] = useState(false)
	const [shippingData, setShippingData] = useState({
		name: shippingName,
		lastName: shippingLastName,
		organization: shippingOrganization,
		address: shippingAddress,
		postalCode: shippingPostalCode,
		city: shippingCity,
		province: shippingProvince,
		country: shippingCountry,
		email: shippingEmail,
		phonePrefix: shippingPrefix,
		phoneNumber: shippingPhone,
	})
	const [newShippingData, setNewShippingData] = useState({ ...shippingData })

	useEffect(() => {
		if (Object.keys(orderShippingAddress).length != 0) {
			history.go(0)
		}
	}, [orderShippingAddress])

	const swapCol1 = () => {
		setCol1(!col1)
		setCol2(col1)
	}
	const swapCol2 = () => {
		setCol1(col2)
		setCol2(!col2)
	}

	const saveData = () => {
		updateOrderShippingAddress({ data: { orderId, shippingAddress: parseShippingAddress(newShippingData) } })
	}

	const parseShippingAddress = (shippingAddress) => {
		return {
			firstName: shippingAddress.name,
			lastName: shippingAddress.lastName,
			company: shippingAddress.organization,
			country: shippingAddress.country,
			address1: shippingAddress.address,
			city: shippingAddress.city,
			state: shippingAddress.province,
			postalCode: shippingAddress.postalCode,
			phoneNumber: shippingAddress.phoneNumber,
			phonePrefix: shippingAddress.phonePrefix,
			email: shippingAddress.email,
		}
	}

	const handleOnChangeElement = (element, value) => {
		setNewShippingData({
			...newShippingData,
			[element]: value,
		})
	}
	const closeWithoutSaving = () => {
		setNewShippingData({ ...shippingData })
		setOpenShippingModal(false)
	}

	return (
		<div>
			<div className="accordion" id="clientDataAccordion">
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingOne">
						<button
							className={classnames(
								'accordion-button',
								'fw-medium',
								'bg-white',
								{
									collapsed: !col1,
								},
							)}
							type="button"
							onClick={swapCol1}
							style={{ cursor: 'pointer' }}
						>
							<h5>{t('billing', { ns: 'naming' })}</h5>
						</button>
					</h2>

					<Collapse isOpen={col1} className="accordion-collapse">
						<div className="accordion-body">
							<div>
								<p>
									<Link to={'/crm-user-detail/' + accountId}>
										{name}
									</Link>
									<br />
									{organization}
									<br />
									{vat}
									<br />
									{address}
									<br />
									{postalCode}, {city}
									<br />
									{province}, {country}
									<br />
								</p>
								<p>
									{email}
									<br />
									{phone}
								</p>
							</div>
						</div>
					</Collapse>
				</div>
				<div className="accordion-item">
					<h2 className="accordion-header" id="headingTwo">
						<button
							className={classnames(
								'accordion-button',
								'fw-medium',
								'bg-white',
								{
									collapsed: !col2,
								},
							)}
							type="button"
							onClick={swapCol2}
							style={{ cursor: 'pointer' }}
						>
							<h5>{t('shipment', { ns: 'naming' })}</h5>
						</button>
					</h2>
					<Collapse isOpen={col2} className="accordion-collapse">
						<div className="accordion-body">
							<div>
								<p>
									{shippingData.name} {shippingData.lastName}
									<br />
									{shippingData.organization}
									<br />
									{shippingData.address}
									<br />
									{shippingData.postalCode}, {shippingData.city}
									<br />
									{shippingData.province}, {shippingData.country}
								</p>
								<p>
									{shippingData.email}
									<br />
									{shippingData.phonePrefix} {shippingData.phoneNumber}
								</p>
								<button
									type="button"
									className="btn btn-outline-secondary"
									onClick={e => setOpenShippingModal(true)}
								>
									<i className="bx bx-id-card mx-2"></i>
									<span className="py-2">{t('edit', { ns: 'naming' })}</span>
								</button>
							</div>
						</div>
					</Collapse>
				</div>
			</div>
			<Modal
				isOpen={isOpenShippingModal}
				closeButtonText={t('close', { ns: 'naming' })}
				closeModal={() => closeWithoutSaving()}
				title={t('shippingData', { ns: 'naming' })}
				body={
					<div className="p-4">
						<TextInput
							value={newShippingData.name}
							setValue={handleOnChangeElement}
							objectElement={'name'}
							title={t('name', { ns: 'naming' })}
							hasPressed={hasPressed}
							type="text"
							isRequired={true}
						/>
						<TextInput
							value={newShippingData.lastName}
							setValue={handleOnChangeElement}
							objectElement={'lastName'}
							title={t('lastName', { ns: 'naming' })}
							hasPressed={hasPressed}
							type="text"
							isRequired={true}
						/>
						<TextInput
							value={newShippingData.organization}
							setValue={handleOnChangeElement}
							objectElement={'organization'}
							title={t('organization', { ns: 'naming' })}
							hasPressed={hasPressed}
							type="text"
							isRequired={true}
						/>
						<TextInput
							value={newShippingData.address}
							setValue={handleOnChangeElement}
							objectElement={'address'}
							title={t('address', { ns: 'naming' })}
							hasPressed={hasPressed}
							type="text"
							isRequired={true}
						/>
						<TextInput
							value={newShippingData.postalCode}
							setValue={handleOnChangeElement}
							objectElement={'postalCode'}
							title={t('postalCode', { ns: 'naming' })}
							hasPressed={hasPressed}
							type="text"
							isRequired={true}
						/>
						<TextInput
							value={newShippingData.city}
							setValue={handleOnChangeElement}
							objectElement={'city'}
							title={t('city', { ns: 'naming' })}
							hasPressed={hasPressed}
							type="text"
							isRequired={true}
						/>
						<TextInput
							value={newShippingData.province}
							setValue={handleOnChangeElement}
							objectElement={'province'}
							title={t('province', { ns: 'naming' })}
							hasPressed={hasPressed}
							type="text"
							isRequired={false}
						/>
						<div className="m-2">
							<h6><span className="text-primary me-1">✲</span>{t('country', { ns: 'naming' })}</h6>
							<CountryFlag
								isEditable
								onChange={(e) => handleOnChangeElement('country', e.value)}
								countryState={newShippingData.country}
							/>
						</div>
						<TextInput
							value={newShippingData.email}
							setValue={handleOnChangeElement}
							objectElement={'email'}
							title={t('email', { ns: 'naming' })}
							hasPressed={hasPressed}
							type="text"
							isRequired={true}
						/>
						<div className="m-2">
							<h6><span className="text-primary me-1">✲</span>{t('prefix', { ns: 'naming' })}</h6>
							<PrefixFlag
								isEditable
								onChange={(e) => handleOnChangeElement('phonePrefix', e.value)}
								prefixState={newShippingData.phonePrefix}
							/>
						</div>
						<TextInput
							value={newShippingData.phoneNumber}
							setValue={handleOnChangeElement}
							objectElement={'phoneNumber'}
							title={t('phone', { ns: 'naming' })}
							hasPressed={hasPressed}
							type="text"
							isRequired={true}
						/>
					</div>
				}
				buttons={[
					<button
						key="saveChanges"
						type="button"
						className="btn btn-warning"
						onClick={() => saveData()}
					>
						<span className="p-2">{isLoading ? <Loader /> : t('saveChanges', { ns: 'naming' })}</span>
					</button>,
				]}
			/>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		orderShippingAddress: state.Orders.orderShippingAddress,
		isLoading: state.Orders.isLoading,
	}
}
OrderClientData.propTypes = {
	t: PropTypes.func,
	history: PropTypes.object,
	orderId: PropTypes.number,
	name: PropTypes.string,
	organization: PropTypes.string,
	vat: PropTypes.string,
	address: PropTypes.string,
	postalCode: PropTypes.string,
	city: PropTypes.string,
	province: PropTypes.string,
	country: PropTypes.string,
	email: PropTypes.string,
	phone: PropTypes.string,
	shippingName: PropTypes.string,
	shippingLastName: PropTypes.string,
	shippingOrganization: PropTypes.string,
	shippingAddress: PropTypes.string,
	shippingPostalCode: PropTypes.string,
	shippingCity: PropTypes.string,
	shippingProvince: PropTypes.string,
	shippingCountry: PropTypes.string,
	shippingEmail: PropTypes.string,
	shippingPrefix: PropTypes.string,
	shippingPhone: PropTypes.string,
	updateOrderShippingAddress: PropTypes.func,
	orderShippingAddress: PropTypes.object,
	isLoading: PropTypes.bool,
	accountId: PropTypes.number,
	orderWeight: PropTypes.number,
}
export default withRouter(connect(mapStateToProps, { updateOrderShippingAddress })(
	withTranslation()(OrderClientData),
))
