import { Flag } from 'semantic-ui-react'

export const getAllFlagOptions = () => {
	const LANGUAGES = [
		['Español', 'es'],
		['Francés', 'fr'],
		['Italiano', 'it'],
		['Inglés', 'us'],
		['Alemán', 'de'],
		['Protugués', 'pt'],
		['Árabe', 'sa'],
		['Chino', 'cn'],
		['Holandés', 'nl'],
		['Ruso', 'ru'],
	]

	const renderOption = country => {
		return {
			value: country[1],
			label: (
				<span>
					<Flag name={country[1].toLocaleLowerCase()} />
					{country[0]}
				</span>
			),
		}
	}

	const allOptions = LANGUAGES.map(countryAndCountry =>
		renderOption(countryAndCountry),
	)
	return allOptions
}
