import matcapPorcelainWhite from 'assets/textures/GRIS3.jpg'
import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { getMeasure } from 'common/Utils/StringUtilities'
import { useEffect, useRef, useState } from 'react'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import Loader from '../Loader'

import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

const loader = new OBJLoader()
const textureLoader = new THREE.TextureLoader()

/**
 * @param {Object} props
 * @param {string} [props.modelSrc]
 * @param {Object} props.windowSize
 * @param {number} props.windowSize.x
 * @param {number} props.windowSize.y
 *
 * @returns {JSX.Element}
 */
export const ObjViewer = (props) => {

	const { modelSrc, windowSize } = props

	const mountRef = useRef(null)
	const [loading, setLoading] = useState(true)

	// Refs to store Three.js objects
	const sceneRef = useRef()
	const cameraRef = useRef()
	const rendererRef = useRef()
	const controlsRef = useRef()
	const modelRef = useRef()

	useEffect(() => {

		// Initialize scene
		const scene = new THREE.Scene()
		sceneRef.current = scene

		// Initialize camera
		const camera = new THREE.PerspectiveCamera(
			750,
			windowSize.x / windowSize.y,
			0.001,
			100000,
		)
		camera.position.z = 100
		cameraRef.current = camera

		// Initialize renderer
		const renderer = new THREE.WebGLRenderer({
			antialias: true,
			alpha: true,
		})
		renderer.setSize(windowSize.x, windowSize.y)
		rendererRef.current = renderer

		// Append renderer to DOM
		if (mountRef.current) {
			mountRef.current.appendChild(renderer.domElement)
		}

		// Initialize controls
		const controls = new OrbitControls(camera, renderer.domElement)
		controlsRef.current = controls

		// Add lights
		const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
		scene.add(ambientLight)

		const pointLight = new THREE.PointLight(0xffffff, 1)
		pointLight.position.set(50, 50, 50)
		scene.add(pointLight)

		// Animation loop
		const animate = () => {
			requestAnimationFrame(animate)
			controls.update()
			renderer.render(scene, camera)
		}
		animate()

		// Load model if provided
		if (modelSrc) {
			loader.manager.onLoad = () => setLoading(false)

			loader.load(
				modelSrc,
				(object) => {
					// Apply material to all meshes in the object
					object.traverse((child) => {
						if (child instanceof THREE.Mesh) {
							child.material = new THREE.MeshMatcapMaterial({
								color: 0xffffff,
								matcap: textureLoader.load(matcapPorcelainWhite),
							})
							child.geometry.computeVertexNormals()
						}
					})

					// Center the entire object
					const box = new THREE.Box3().setFromObject(object)
					const center = box.getCenter(new THREE.Vector3())
					object.position.sub(center)
					object.rotation.x = -Math.PI / 2

					modelRef.current = object
					scene.add(object)

					// Adjust camera and controls based on model size
					const boundingBox = new THREE.Box3().setFromObject(object)
					const size = boundingBox.getSize(new THREE.Vector3())
					const maxDim = Math.max(size.x, size.y, size.z)
					const fov = camera.fov * (Math.PI / 180)
					let cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2))
					cameraZ *= 1.5 // Zoom out a little so that objects fit

					camera.position.set(
						cameraZ * Math.sin(0.9), // position x
						cameraZ * Math.sin(0.7), // position y
						cameraZ, // position z
					)
					controls.maxDistance = cameraZ * 3
					controls.minDistance = cameraZ / 2
					controls.target.copy(boundingBox.getCenter(new THREE.Vector3()))
					controls.update()
				},
				undefined,
				error => {
					setLoading(false)
					// API.sendErrorReport({
					// 	data: {
					// 		error,
					// 		info: 'StlViewer Render error',
					// 		payload: { modelSrc },
					// 		user: {
					// 			email: undefined!,
					// 			id: undefined!,
					// 			role: undefined!,
					// 		},
					// 	},
					// }).catch((error: any) => {
					// 	devConsoleLog('COULD NOT SEND ERROR REPORT EMAIL: ', error)
					// })
				},
			)
		} else {
			setLoading(false)
		}

		// Cleanup on unmount
		return () => {
			if (renderer.domElement && mountRef.current) {
				mountRef.current.removeChild(renderer.domElement)
			}
			renderer.dispose()
			controls.dispose()
			scene.clear()
		}
	}, [modelSrc])

	useEffect(() => {
		// Handle window resize
		if (rendererRef.current && cameraRef.current) {
			rendererRef.current.setSize(windowSize.x, windowSize.y)
			cameraRef.current.aspect = windowSize.x / windowSize.y
			cameraRef.current.updateProjectionMatrix()
		}
	}, [windowSize])

	const { partId, size, weight } = props
	const WEIGHT_MEASURE = 'g'
	const SIZE_MEASURE = 'mm'

	return (
		<div className="mx-auto">
			{(loading || !partId || !size || !weight) ? (
				<div className="position-absolute">
					{props.hideData ? '' : <Loader className="me-4 py-4 d-flex font-size-80" />}
				</div>) :
				<div className="position-absolute">
					<div className="py-4 d-flex flex-column">
						<div>{partId && `${partId}`}</div>
						<div>{size &&
							size.x &&
							size.y &&
							size.z &&
							`${roundAtDecimals(size.x, 1)} x ${roundAtDecimals(
								size.y,
								1,
							)} x ${roundAtDecimals(size.z, 1)} ${size.measure ? size.measure : SIZE_MEASURE}`}{' '}
						</div>
						<div>{weight && getMeasure(weight.value ? weight.value : weight, weight.measure ? weight.measure : WEIGHT_MEASURE)}</div>
					</div>
				</div>
			}
			<div
				style={{ width: windowSize.x, height: windowSize.y }}
				ref={mountRef}>
			</div>
		</div>
	)
}
