import { roundCurrency } from 'common/Utils/NumberUtilities'
import { IMPRESION3D_ID, TINTED_ID } from 'pages/Offer/Offer'
import PropTypes from 'prop-types'

export const AIPrediction = ({
	part,
	quantity,
}) => {

	let prediction = part.quotePrediction && part.quotePrediction.find(prediction => prediction.quantity == quantity)?.quote
	if (prediction && part.configuration.technologyTypeId == IMPRESION3D_ID && part.configuration.finishId == TINTED_ID) {
		prediction = roundCurrency(prediction * 1.1) //if finishing is tinted price is 10% more
	}

	return (
		<div>
			{prediction || 'N/A'}
		</div>
	)

}
AIPrediction.propTypes = {
	part: PropTypes.any,
	quantity: PropTypes.number,
}

export default AIPrediction
