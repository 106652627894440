export const downloadFileAsBase64 = async function (url) {
	return new Promise((resolve, reject) => {
		const xhr = new XMLHttpRequest()
		xhr.onload = function () {
			const reader = new FileReader()
			reader.onloadend = function () {
				resolve(reader.result)
			}
			reader.readAsDataURL(xhr.response)
		}
		xhr.onerror = () => {
			reject({
				status: xhr.status,
				statusText: xhr.statusText,
			})
		}
		xhr.open('GET', url)
		xhr.responseType = 'blob'
		xhr.send()
	})
}
