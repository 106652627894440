import prefixes from 'constants/countryPhonePrefix'

export function getCountryCodeByPrefix(prefix) {
	if (!prefix) {
		return null
	}
	const prefixArray = Object.entries(prefixes)
	for (let i = 0; i < prefixArray.length; i++) {
		if (prefix.toLowerCase() === prefixArray[i][1]) {
			return prefixArray[i][0].toLocaleLowerCase()
		}
	}
}

export function getPrefixByCountryCode(countryCode) {
	const prefixArray = Object.entries(prefixes)
	for (const p in prefixArray) {
		if (countryCode.toLowerCase() === p[0]) return p[1]
	}
}
