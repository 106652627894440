/**
 * Este icono de chats con etiqueta se usa para mostrar los mensajes no leídos y muestra el modal de chat al clicar:
 * 
 * ---
 * * `QuoteChat` (va por `part_id`)
 *   - Chat entre manager y proveedor con respecto a una pieza
 *   - Se muestra en `/offer/:id`, `/quote/:id`
 * ---
 * * `ProductionChat`
 *   - Chat entre manager y proveedor con respecto a una producción
 *   - Se muestra en `/production/:id`, `/supplier-production/:id`  
 */
export const ChatIconIcon = ({ hasChats, quantityUnread, onClick }) => {
	return (
		<div
			onClick={onClick}
			className='cursor-pointer'
		>
			<i
				className={`bx ${hasChats ? 'bxs-chat' : 'bx-chat'}`}
				style={{
					fontSize: '1.3rem',
				}}
			></i>
			{quantityUnread == 0 ? '' : (
				<span
					className="badge bg-danger"
					style={{
						marginLeft: '0.2em',
						verticalAlign: 'top',
						fontSize: '0.6rem',
					}}
				>
					{quantityUnread}
				</span>
			)}
		</div>
	)
}
