export const PERCENTAGE = 'PERCENTAGE_DISCOUNT'
export const AMOUNT = 'AMOUNT_DISCOUNT'
export const FREE_SHIPPING = 'FREE_SHIPPING'
export const NO_MINIMUM_PRICE = 'NO_MINIMUM_PRICE'
export const GIFT = 'GIFT'

export const voucherTypes = {
	PERCENTAGE: 1,
	AMOUNT: 2,
	FREE_SHIPPING: 3,
	NO_MINIMUM_PRICE: 4,
	GIFT: 5,
}
