export const downloadCsv = (function () {
	var a = document.createElement('a')
	document.body.appendChild(a)
	a.style = 'display: none'

	return function (content, fileName, type) {
		const blob = new Blob(
			[
				new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
				content,
			],
			{ type: type },
		)
		const url = window.URL.createObjectURL(blob)
		a.href = url
		a.download = fileName
		a.click()
		window.URL.revokeObjectURL(url)
	}
})()
