import * as XLSX from 'xlsx'

export const downloadXLSX = (() => {

	var a = document.createElement('a')
	document.body.appendChild(a)
	a.style = 'display: none'

	return function ({ rows, fileName }) {

		const headers = Object.keys(rows[0] || {})

		const arrayOfArrays = rows
			.map(row => headers
				.map(header => `${row[header] || ''}`))

		// Create a new workbook and a new worksheet
		const wb = XLSX.utils.book_new()
		const ws = XLSX.utils.aoa_to_sheet(arrayOfArrays)

		// Append the worksheet to the workbook
		XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

		// Generate the .xlsx file
		const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })

		const blob = new Blob([wbout], { type: 'application/octet-stream' })
		const url = window.URL.createObjectURL(blob)

		a.href = url
		a.download = fileName.endsWith('.xlsx') ? fileName : `${fileName}.xlsx`
		a.click()

		window.URL.revokeObjectURL(url)
	}

})()
