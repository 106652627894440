import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
	Card,
	CardBody,
	Col,
	Container,
	Label,
	Modal,
	ModalBody,
	ModalHeader,
	Row,
} from 'reactstrap'
import * as Yup from 'yup'

import { ErrorMessage, Field, Form, Formik } from 'formik'

import FullCalendar from '@fullcalendar/react'
// importante: dejar esta linea vacía aqui
import BootstrapTheme from '@fullcalendar/bootstrap'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import '@fullcalendar/bootstrap/main.css'

import Breadcrumbs from '../../components/Common/Breadcrumb'

import DeleteModal from './DeleteModal'

import { MetaTags } from 'react-meta-tags'

import { createHoliday, deleteHolidayById, getHolidays, updateHolidayById } from 'store/actions'
class HolidaysCalendar extends Component {
	constructor(props) {
		super(props)
		this.handleDateClick = this.handleDateClick.bind(this)
		this.handleEventClick = this.handleEventClick.bind(this)
		this.holidayCalendarComponentRef = React.createRef()
		this.handleDeleteEvent = this.handleDeleteEvent.bind(this)

		this.state = {
			holidayCalendarWeekends: true,
			modal: false,
			modalcategory: false,
			startDate: null,
			endDate: null,
			isDragBind: false,
			deleteModal: false,
			events: [],
		}

		this.toggle = this.toggle.bind(this)
		this.togglecategory = this.togglecategory.bind(this)
		this.handleValidEventSubmitcategory =
			this.handleValidEventSubmitcategory.bind(this)
	}

	componentDidMount() {
		this.props.getHolidays()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		const { event, modal } = this.state;
		if (prevProps.holidaysList !== this.props.holidaysList) {
			const formattedEvents = this.props.holidaysList.map(holiday => {
				const startDate = new Date(holiday.startDate);
				const endDate = new Date(holiday.endDate);
				const endDateToPrint = new Date(endDate.setDate(endDate.getDate() + 1));
				return {
					id: holiday.id,
					title: holiday.eventName,
					start: startDate.toISOString().slice(0, 10),
					end: endDateToPrint.toISOString().slice(0, 10),
					backgroundColor: this.getBackgroundColorForEventType(holiday.eventType),
					textColor: 'white',
					category: holiday.eventType,
					borderColor: 'white',
					allDay: true,
				};
			});

			this.setState({ events: formattedEvents });

		}

		if (prevState.modal !== modal && !modal && !isEmpty(event)) {
			setTimeout(() => {
				this.setState({ isEdit: false })
			}, 500);
		}
	}

	getBackgroundColorForEventType(eventType) {

		switch (parseInt(eventType)) {
			case 1:
				return 'red';
			case 2:
				return 'blue';
			case 3:
				return '#21E638';
			case 4:
				return '#F5E02F';
			default:
				return 'gray';
		}
	}

	toggle() {
		this.setState(prevState => ({
			modal: !prevState.modal,
			endDate: null,
			startDate: null,
		}))
	}

	togglecategory() {
		this.setState(prevState => ({
			modalcategory: !prevState.modalcategory,
		}))
	}

	handleDateClick(arg) {
		const date = arg['date']
		const day = date.getDate()
		const month = date.getMonth()
		const year = date.getFullYear()

		const currectDate = new Date()
		const currentHour = currectDate.getHours()
		const currentMin = currectDate.getMinutes()
		const currentSec = currectDate.getSeconds()
		const modifiedDate = new Date(
			year,
			month,
			day,
			currentHour,
			currentMin,
			currentSec,
		)
		const modifiedData = { ...arg, date: modifiedDate }

		this.setState({ selectedDay: modifiedData })
		this.toggle()
	}

	editEvent(event) {
		const className = this.getClassNames(event.event._def.ui.classNames)
		const eventUpdated = {
			id: parseInt(event.event._def.publicId),
			title: event.event._def.title,
			start: event.event._instance.range.start,
			end: event.event._instance.range.end,
			className: className,
			category: event.event._def.extendedProps.category,
			textColor: event.event._def.ui.textColor,
			backgroundColor: event.event._def.ui.backgroundColor,
			borderColor: event.event._def.ui.borderColor,
			allDay: true,
		}


		let eventType;
		if (event.backgroundColor === "red") {
			eventType = 1;
		}
		else if (event.backgroundColor === "blue") {
			eventType = 2;
		}
		else if (event.backgroundColor === "#21E638") {
			eventType = 3;
		}
		else if (event.backgroundColor === "#F5E02F") {
			eventType = 4;
		}

		const startDate = new Date(eventUpdated.start);
		const endDate = new Date(eventUpdated.end);
		const formattedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);
		const formattedEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());


		const formattedEventUpdated = {
			data: {
				holiday: {
					id: parseInt(eventUpdated.id),
					eventName: eventUpdated.title,
					eventType: eventType,
					startDate: formattedStartDate.toISOString(),
					endDate: formattedEndDate.toISOString(),
				}
			}
		};
		this.props.updateHolidayById(formattedEventUpdated);

		this.updateEvent(eventUpdated, this.state.events)
	}


	getClassNames(array) {
		let className = ''
		array.forEach(element => {
			className += element + ' '
		})
		return className
	}

	updateEvent(eventUpdated, stateArray) {
		let eventType;
		if (eventUpdated.backgroundColor === "red") {
			eventType = 1;
		}
		else if (eventUpdated.backgroundColor === "blue") {
			eventType = 2;
		}
		else if (eventUpdated.backgroundColor === "#21E638") {
			eventType = 3;
		}
		else if (eventUpdated.backgroundColor === "#F5E02F") {
			eventType = 4;
		}

		const startDate = new Date(eventUpdated.start);
		const endDate = new Date(eventUpdated.end);
		const formattedStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 1);
		const formattedEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());


		const formattedEventUpdated = {
			data: {
				holiday: {
					id: parseInt(eventUpdated.id),
					eventName: eventUpdated.title,
					eventType: eventType,
					startDate: formattedStartDate.toISOString(),
					endDate: formattedEndDate.toISOString(),
				}
			}
		};


		const originEndDate = new Date(eventUpdated.end);
		originEndDate.setDate(originEndDate.getDate() + 1);
		const updatedArray = stateArray.map(event => {
			if (event.id === eventUpdated.id) {
				return eventUpdated;
			}
			return event;
		});

		this.props.updateHolidayById(formattedEventUpdated);
		this.changeArray(updatedArray)

	}

	deleteElement(eventToDelete, stateArray) {
		const array = [...stateArray]
		this.changeArray(
			array.filter(item => item.id !== parseInt(eventToDelete.id)),
		)


		this.props.deleteHolidayById(parseInt(eventToDelete.id));
	}
	changeArray(array) {
		this.setState({
			...this.state,
			events: array,
		})
	}
	getIndexOfEvent(id) {
		for (let i = 0; i < this.state.events.length; i++) {
			if (this.state.events[i].id == id) {
				return i
			}
		}
	}

	handleEventClick(arg) {
		const event = arg.event

		this.setState({
			event: {
				id: event.id,
				title: event.title,
				title_category: event.title_category,
				start: event.start,
				end: event._instance.range.end,
				className: event.classNames,
				category: event.extendedProps.category,
				event_category: event.classNames[0],
			},

			isEdit: true,
		})
		this.toggle()

	}
	addEvent(event) {
		let eventType;
		if (event.backgroundColor === "red") {
			eventType = 1;
		}
		else if (event.backgroundColor === "blue") {
			eventType = 2;
		}
		else if (event.backgroundColor === "#21E638") {
			eventType = 3;
		}
		else if (event.backgroundColor === "#F5E02F") {
			eventType = 4;
		}


		const startDate = event.start;
		const endDate = event.end;


		const formattedStartDate = new Date(startDate);
		formattedStartDate.setDate(formattedStartDate.getDate() + 1);
		const formattedEvent = {
			data: {
				holiday: {
					eventName: event.title,
					eventType: eventType,
					startDate: formattedStartDate.toISOString().slice(0,10),
					endDate: endDate.toISOString().slice(0,10),
				}
			}
		};

		this.setState({
			...this.state,
			events: [...this.state.events],
		})
		this.props.createHoliday(formattedEvent);
	}

	handleValidEventSubmitcategory(event, values) {

		const { onAddNewEvent } = this.props
		const newEvent = {
			id: Math.floor(Math.random() * 100),
			title: values['title_category'],
			start: new Date(),
			className: values.event_category
				? values.event_category + ' text-white'
				: 'bg-danger text-white',
		}
		onAddNewEvent(newEvent)
		this.togglecategory()
	}

	handleDeleteEvent() {
		const { event } = this.state

		this.deleteElement(event, this.state.events)
		this.setState({ deleteModal: false })
		this.toggle()
	}

	setStartAndEndDate(e) {
		this.setState({
			...this.state,
			endDate: e.end,
			startDate: e.start,
			modal: true,
		})

	}

	render() {

		const { events, categories } = this.props
		const { onAddNewEvent, onUpdateEvent } = this.props
		const { isEdit, deleteModal, event, selectedDay } = this.state
		const { t } = this.props
		return (
			<React.Fragment>
				<DeleteModal
					show={deleteModal}
					onDeleteClick={this.handleDeleteEvent}
					onCloseClick={() => this.setState({ deleteModal: false })}
				/>
				<div className="page-content">
					<MetaTags>
						<title>Proto&Go! | {t('holidays', { ns: 'naming' })}</title>
					</MetaTags>
					<Container fluid={true}>
						<Row>
							<Breadcrumbs
								title={t('configuration', { ns: 'naming' })}
								breadcrumbItems={[
									{
										item: t('holidays', { ns: 'naming' }),
										link: '/holidays',
									},
								]}
							/>
							<Col className="col-12">
								<Card>
									<CardBody>
										<Row>
											<Col className="col-12">
												<FullCalendar
													ref={this.holidayCalendarComponentRef}
													plugins={[
														BootstrapTheme,
														dayGridPlugin,
														interactionPlugin,
													]}
													locale={'Es'}
													firstDay={1}
													select={e => this.setStartAndEndDate(e)}
													slotDuration={'00:15:00'}
													handleWindowResize={true}
													themeSystem="bootstrap"
													headerToolbar={{
														left: 'prev,next today',
														center: 'title',
														right: 'dayGridMonth,dayGridWeek,dayGridDay',
													}}
													events={this.state.events}
													editable={true}
													droppable={true}
													selectable={true}
													eventClick={this.handleEventClick}
													eventChange={e => {
														this.editEvent(e)
													}}
												/>
												<Modal
													isOpen={this.state.modal}
													className={this.props.className}
													id="event-modal"
												>
													<ModalHeader toggle={this.toggle} tag="h4">
														{isEdit ? 'Editar Evento' : 'Añadir vacaciones'}
													</ModalHeader>
													<ModalBody>
														<Formik
															enableReinitialize={true}
															initialValues={{
																title:
																	(this.state.event &&
																		this.state.event.title) ||
																	'',
																category:
																	(this.state.event &&
																		this.state.event.category) ||
																	1,
															}}
															validationSchema={Yup.object().shape({
																title: Yup.string().required(
																	'Please Enter Your Event Name',
																),
																category: Yup.string().required(
																	'Please Enter Your category',
																),
															})}

															onSubmit={values => {

																const backgroundColor = this.getBackgroundColorForEventType(values.category);
																if (isEdit) {
																	event.title = values['title']
																	const eventUpdated = {
																		id: parseInt(event.id),
																		title: values['title'],
																		start: event.start,
																		end: event.end,
																		allDay: true,
																		backgroundColor: backgroundColor,
																		textColor: 'white',
																		borderColor: 'white',
																	}

																	this.updateEvent(
																		eventUpdated,
																		this.state.events,
																	)
																} else {
																	const backgroundColor = this.getBackgroundColorForEventType(values.category);

																	const newStartDate = this.state.startDate
																		? new Date(this.state.startDate)
																		: new Date();
																	newStartDate.setDate(newStartDate.getDate() + 1);
																	const newEvent = {
																		id: Math.floor(Math.random() * 100),
																		title: values['title'],
																		start: this.state.startDate
																			? this.state.startDate
																			: new Date(),
																		end: this.state.endDate
																			? this.state.endDate
																			: new Date(),
																		allDay: true,
																		backgroundColor,
																		textColor: 'white',
																		borderColor: 'white',
																	}
																	this.addEvent(newEvent)

																}
																this.setState({ selectedDay: null })
																this.toggle()
															}}
														>
															{({ errors, status, touched }) => (
																<Form>
																	<Row>
																		<Col xs={12}>
																			<div className="mb-3">
																				<Label className="form-label">
																					Nombre del evento
																				</Label>
																				<Field
																					placeholder={
																						'Escribe el nombre del evento'
																					}
																					name="title"
																					type="text"
																					className={
																						'form-control' +
																						(errors.title && touched.title
																							? ' is-invalid'
																							: '')
																					}
																				/>
																				<ErrorMessage
																					name="title"
																					component="div"
																					className="invalid-feedback"
																				/>
																			</div>
																		</Col>
																		<Col xs={12}>
																			<div className="mb-3">
																				<Label className="form-label">
																					Selecciona tipo de festivo
																				</Label>
																				<Field
																					placeholder="Seleccionar tipo de festivo"
																					name="category"
																					as="select"
																					className={
																						'form-control' +
																						(errors.category && touched.category
																							? ' is-invalid'
																							: '')
																					}
																				>
																					<option value={1}> {/* TODO ADD TRANSLATIONS t(´event_type_1´, { ns : "naming"}) */}
																						Fiesta Local
																					</option>
																					<option value={2}>
																						Fiesta autonomica
																					</option>
																					<option value={3}>
																						Fiesta Nacional
																					</option>
																					<option value={4}>
																						Otro festivo
																					</option>
																				</Field>
																				<ErrorMessage
																					name="category"
																					component="div"
																					className="invalid-feedback"
																				/>
																			</div>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<div className="text-end">
																				<button
																					type="button"
																					className="btn btn-light me-2"
																					onClick={this.toggle}
																				>
																					Cerrar
																				</button>
																				{!!isEdit && (
																					<button
																						type="button"
																						className="btn btn-danger me-2"
																						onClick={() =>
																							this.setState({
																								deleteModal: true,
																							})
																						}
																					>
																						Borrar
																					</button>
																				)}
																				<button
																					type="submit"
																					className="btn btn-success save-event"
																				>
																					Guardar
																				</button>
																			</div>
																		</Col>
																	</Row>
																</Form>
															)}
														</Formik>
													</ModalBody>
												</Modal>

											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

HolidaysCalendar.propTypes = {
	categories: PropTypes.array,
	className: PropTypes.string,
	events: PropTypes.array,
	onAddNewEvent: PropTypes.func,
	onDeleteEvent: PropTypes.func,
	onGetCategories: PropTypes.func,
	onGetEvents: PropTypes.func,
	onUpdateEvent: PropTypes.func,
	t: PropTypes.func,
	createHoliday: PropTypes.func,
	updateHolidayById: PropTypes.func,
	deleteHolidayById: PropTypes.func,
}

const mapStateToProps = (state) => ({
	error: state.Users.error,
	errorUpdateHoliday: state.Users.errorUpdateHoliday,
	isLoading: state.Users.isLoading,
	errorCreateHoliday: state.Users.errorCreateHoliday,
	isCreatingHoliday: state.Users.isCreatingHoliday,
	holidaysList: state.Users.holidaysList,


})

const mapDispatchToProps = {
	getHolidays,
	createHoliday,
	updateHolidayById,
	deleteHolidayById,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(HolidaysCalendar))
