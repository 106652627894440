import { call, put, takeEvery } from 'redux-saga/effects'

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD, VERIFY_TOKEN } from './actionTypes'
import {
	resetUserPasswordError,
	resetUserPasswordSuccess,
	sendChangePasswordEmailError,
	sendChangePasswordEmailSuccess,
	verifyTokenError,
	verifyTokenSuccess,
} from './actions'

//Include Both Helper File with needed methods

import { hashPassword } from '../../../common/Utils/HashingUtilities'
import { removeUserFromLocalStorage } from '../../../common/Utils/LocalStorageUtilities'
import {
	CHANGE_PASSWORD_ENDPOINT,
	SEND_CHANGE_PASSWORD_EMAIL_ENDPOINT,
	VERIFY_TOKEN_ENDPOINT,
} from '../../../constants/backendRoutes'
import { post } from '../../../helpers/api_helper'

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* sendChangePasswordEmail({ payload: { user, history } }) {
	try {
		const url = SEND_CHANGE_PASSWORD_EMAIL_ENDPOINT
		const data = {
			data: {
				email: user.email,
			},
		}
		const response = yield call(post, url, data)

		if (response && response.error) {
			yield put(sendChangePasswordEmailError(response.error.message))
		} else if (response) {
			yield put(
				sendChangePasswordEmailSuccess(
					'Reset link is sent to your mailbox, check there first',
				),
			)
		}
	} catch (error) {
		yield put(sendChangePasswordEmailError(error))
	}
}

function* resetPassword({ payload: { password, token } }) {
	try {
		const url = CHANGE_PASSWORD_ENDPOINT
		const data = {
			data: {
				resetPasswordToken: token,
				newPassword: hashPassword(password),
			},
		}
		const response = yield call(post, url, data)
		if (response && response.errorCode) {
			yield put(resetUserPasswordError(response.message))
		} else if (response) {
			removeUserFromLocalStorage()
			yield put(
				resetUserPasswordSuccess('Password has been changed successfully!'),
			)
		}
	} catch (error) {
		yield put(resetUserPasswordError(error))
	}
}
function* verifyToken({ payload: { token } }) {
	try {
		const url = VERIFY_TOKEN_ENDPOINT
		const data = {
			data: {
				tokenToVerify: token,
			},
		}
		const response = yield call(post, url, data)
		yield put(verifyTokenSuccess(response))
	} catch (error) {
		yield put(verifyTokenError(error))
	}
}
function* forgetPasswordSaga() {
	yield takeEvery(FORGET_PASSWORD, sendChangePasswordEmail)
	yield takeEvery(RESET_PASSWORD, resetPassword)
	yield takeEvery(VERIFY_TOKEN, verifyToken)
}

export default forgetPasswordSaga
