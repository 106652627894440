import { PGO_ADDRESS_1, PGO_ADDRESS_2, PGO_COMPANY_NAME, PGO_PHONE_NUMBER, PGO_VAT } from 'constants/protoAndGoInfo'
import logo from '../../assets/images/brands/protoAndGoLogoDark.png'

export const PGOHeader = (
	<div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', color: '#282828' }}>
		<div>
			<img style={{ width: '250px' }} src={logo} alt="Proto&GO! Logo" />
		</div>
		<div style={{
			borderLeft: '1px solid lightgray',
			paddingLeft: '1em',
		}}>
			<div>
				<strong>{PGO_COMPANY_NAME}</strong>
			</div>
			<br />
			<div>
				{PGO_ADDRESS_1}
				<br />
				{PGO_ADDRESS_2}
			</div>
			<br />
			<div>
				{PGO_VAT}
			</div>
			<br />
			<div>
				{'ES: '}<strong>{PGO_PHONE_NUMBER.ES}</strong>&nbsp;&nbsp;
				{'EN: '}<strong>{PGO_PHONE_NUMBER.EN}</strong><br />
				{'FR: '}<strong>{PGO_PHONE_NUMBER.FR}</strong>&nbsp;&nbsp;
				{'IT: '}<strong>{PGO_PHONE_NUMBER.IT}</strong>
			</div>
		</div>
	</div>
)
