import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { setSelectedExpeditionOrderId } from 'store/actions'
import Loader from '../../../components/Common/Loader'
import ReceptionExpeditionOrderSummary from './ReceptionExpeditionOrderSummary'

const OrderExpeditionSummaryList = ({ t, title, orders, isProductions, setSelectedExpeditionOrderId, isLoading }) => {
	const [ordersMapped, setOrdersMapped] = useState([])

	const mapProduction = (item) => {
		return {
			id: item.production.id,
			organizationName: item.organizationName,
			shipmentDate: item.production.shipmentDateEstimate,
			supplier: item.supplier.name,
			arrivalDate: item.production.arrivalDate,
			trackingNumber: item.production.shipmentTrackingNumber,
			supplierId: item.supplier.id,
		}
	}

	useEffect(() => {
		if (orders) {
			setOrdersMapped(
				orders.map((order) => {
					let orderMapped
					if (isProductions) {
						orderMapped = mapProduction(order)
					} else {
						orderMapped = order
					}
					return (
						<div key={order.id} onClick={() => setSelectedExpeditionOrderId(order.id)}>
							<ReceptionExpeditionOrderSummary order={orderMapped} isProductions={isProductions} />
						</div>
					)
				}),
			)
		}
	}, [orders])

	return (
		<div>
			<h3>{title}</h3>
			<div className="border-bottom">
				{!isLoading ? (
					<>
						<SimpleBar style={{
							maxHeight: '22.5rem',
							border: '0.5px solid lightgray',
							borderTopLeftRadius: '4px',
							borderTopRightRadius: '4px',
						}}>{ordersMapped}</SimpleBar>
					</>
				) : (
					<div className="d-flex justify-content-center">
						<Loader className="fs-1 p-4" />
					</div>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		isLoading: state.Orders.isLoading,
	}
}

OrderExpeditionSummaryList.propTypes = {
	title: PropTypes.string,
	t: PropTypes.func,
	orders: PropTypes.array,
	isProductions: PropTypes.bool,
	setSelectedExpeditionOrderId: PropTypes.func,
	isLoading: PropTypes.bool,
}
export default connect(mapStateToProps, { setSelectedExpeditionOrderId })(withTranslation()(OrderExpeditionSummaryList))
