import { IconSeen } from 'assets/customicons/IconSeen'
import TooltipMessage from 'components/Common/TooltipMessage'
import { OFFER_STATUS } from 'constants/offerStatus'

export const PricesSeenAlert = ({ offer, t }) => {

	// "El cliente ha visto la oferta" (icono de ojo)
	if (offer.pricesSeen) {
		return (
			<span>
				<TooltipMessage place='top' message={t('customer_has_seen_prices', { ns: 'naming' })}>
					<div style={{ height: '1em', width: '1em' }} >
						<IconSeen />
					</div>
				</TooltipMessage>
			</span>
		)
	}

	// "El cliente no ha visto la oferta y han pasado 3 dias o mas" (icono de warning amarillo)
	else if (
		offer.status == OFFER_STATUS.Uploaded &&
		offer.publishEmailResent
	) {
		return (
			<span>
				<TooltipMessage place="top" message={t('customer_has_not_seen_prices_of_3_day_old_offer', { ns: 'naming' })}>
					<div style={{ color: 'red' }}>
						<i className='bx bx-error fs-5'></i>
					</div>
				</TooltipMessage>
			</span>
		)
	}

	else {
		return <span />
	}
}
