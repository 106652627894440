import { all, fork } from 'redux-saga/effects'

//public
import ForgetSaga from './auth/forgetpwd/saga'
import AuthSaga from './auth/login/saga'
import CloudStorageSaga from './cloudStorage/saga'
import crmUsersSaga from './crm/saga'
import EmailsSaga from './emails/saga'
import LayoutSaga from './layout/saga'
import OffersSaga from './offers/saga'
import OrdersSaga from './orders/saga'
import PartsSaga from './parts/saga'
import ProductionSaga from './productions/saga'
import QuotesSaga from './quotes/saga'
import UsersSaga from './users/saga'

export default function* rootSaga() {
	yield all([
		//public
		fork(AuthSaga),
		fork(ForgetSaga),
		fork(LayoutSaga),
		fork(OffersSaga),
		fork(QuotesSaga),
		fork(OrdersSaga),
		fork(UsersSaga),
		fork(crmUsersSaga),
		fork(ProductionSaga),
		fork(CloudStorageSaga),
		fork(PartsSaga),
		fork(EmailsSaga),
	])
}
