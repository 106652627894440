import RegressionsChart from 'components/Common/Charts/RegressionsChart'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const RegressionsGraphic = ({ regressionItems, t }) => {

	const [isUnitaryValues, setIsUnitaryValues] = useState(true)

	const getSeriesByData = (unitPriceArray, unitCostArray, quantityArray, partTotalCost, totalPrice) => {

		const UNITARY_SERIES = [
			{
				name: t('unit_price', { ns: 'naming' }),
				type: 'line',
				data: unitPriceArray,
			}, {
				name: t('unit_cost', { ns: 'naming' }),
				type: 'line',
				data: unitCostArray,
			},
		]
		const TOTAL_SERIES = [
			{
				name: t('totalPrice', { ns: 'naming' }),
				type: 'line',
				data: totalPrice,
			}, {
				name: t('total-cost', { ns: 'naming' }),
				type: 'line',
				data: partTotalCost,
			},
		]

		const maxUnitary = Math.max(...unitPriceArray, ...unitCostArray) * 1.1 || 1
		const maxTotal = Math.max(...partTotalCost, ...totalPrice) * 1.1 || 1

		return {
			labels: quantityArray,
			series: isUnitaryValues ? UNITARY_SERIES : TOTAL_SERIES,
			//Esto es para mostrar los gráficos de manera que se entienda mejor. El 1.1 y 1.4 es para que las barras y lineas del gráfico no llegen al techo.
			maxPrice: isUnitaryValues ? maxUnitary : maxTotal,

		}
	}

	const unitPriceArray = regressionItems.map(item => item.unitPrice)
	const unitCostArray = regressionItems.map(item => item.partTotalUnitCost)
	const quantityArray = regressionItems.map(item => item.quantity)
	const partTotalCost = regressionItems.map(item => item.partTotalCost)
	const totalPrice = regressionItems.map(item => item.totalPrice)

	const seriesData = getSeriesByData(unitPriceArray, unitCostArray, quantityArray, partTotalCost, totalPrice)

	const clickedButton = 'cursor-pointer rounded-pill text-white w100 bg-orange'
	const unclickedButton = 'cursor-pointer rounded-pill text-white w100 bg-orange'

	return (
		<div>
			<div className='d-flex w-100 justify-content-end me-4'>
				<span className={isUnitaryValues ? unclickedButton : clickedButton} onClick={() => { setIsUnitaryValues(!isUnitaryValues) }}>{isUnitaryValues ? t('see_total', { ns: 'naming' }) : t('see_unitary', { ns: 'naming' })}</span>
			</div>

			<RegressionsChart
				labels={quantityArray}
				series={seriesData.series}
				maxPrice={seriesData.maxPrice}
				maxParts={seriesData.maxPrice}
				leftYAxisText={t('prices', { ns: 'naming' })}
			/>
		</div>

	)
}

RegressionsGraphic.propTypes = {
	t: PropTypes.func
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RegressionsGraphic))
