import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'

class SupplierContent extends Component {
	constructor(props) {
		super(props)
		this.refDiv = React.createRef()
	}

	render() {
		const { t } = this.props

		return (
			<React.Fragment>
				<li>
					<Link to="/#" className="has-arrow">
						<i className="bx bxs-truck" />
						<span>{t('suppliers', { ns: 'naming' })}</span>
					</Link>
					<ul className="sub-menu" aria-expanded="false">
						<li>
							<Link to="/quote-list">{t('quotations', { ns: 'naming' })}</Link>
						</li>
						<li>
							<Link to="/supplier-productions">
								{t('productions', { ns: 'naming' })}
							</Link>
						</li>
						{/* TODO - UNCOMMENT THIS WHEN READY
			<li>
              <li>
                <Link to="/supplier-shipments">
                  {t("shipments", { ns: "naming" })}
                </Link>
              </li>
              <Link to="/supplier-billing">
                {t("billing", { ns: "naming" })}
              </Link>
            </li>
            <li>
              <Link to="/supplier-account">
                {t("account", { ns: "naming" })}
              </Link>
            </li> */}
					</ul>
				</li>
			</React.Fragment>
		)
	}
}

SupplierContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
	type: PropTypes.string,
	initMenu: PropTypes.func,
}

export default withRouter(withTranslation()(SupplierContent))
