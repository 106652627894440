import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import * as cn from './locales/cn'
import * as de from './locales/de'
import * as en from './locales/en'
import * as es from './locales/es'
import * as fr from './locales/fr'
import * as it from './locales/it'

// the translations
const resources = {
	de,
	en,
	es,
	fr,
	it,
	cn,
}

const language = localStorage.getItem('I18N_LANGUAGE')
if (!language) {
	localStorage.setItem('I18N_LANGUAGE', 'en')
}

i18n
	.use(detector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: localStorage.getItem('I18N_LANGUAGE') || 'en',
		fallbackLng: 'en', // use en if detected lng is not available

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	})

export default i18n
