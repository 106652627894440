import Modal from 'components/Common/Modal/Modal'
import TextInput from 'components/Common/TextInput'
import CountryFlag from 'pages/CRMUserDetail/ComponenteTablas/CountryFlag'
import PrefixFlag from 'pages/CRMUserDetail/ComponenteTablas/prefixFlagComponent'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const ModalUpdateAddress = ({ address, saveData, t }) => {

	const [addressData, setAddressData] = useState({
		firstName: '',
		lastName: '',
		address1: '',
		postalCode: '',
		city: '',
		country: '',
		province: '',
		phoneNumber: '',
		phonePrefix: '',
		email: '',
	})

	const [isModalOpen, setIsModalOpen] = useState(false)

	const updateAddressDataState = (key, value) => {
		setAddressData({ ...addressData, [key]: value })
	}

	useEffect(() => {
		if (address != null) {
			setAddressData(address)
		}
	}, [address])

	const handleSaveData = () => {
		saveData(addressData)
		setIsModalOpen(false)
	}

	return (
		<div>
			<div className='cursor-pointer ' onClick={(e) => setIsModalOpen(true)}>
				<i className='text-primary fs-4 bx bx-edit-alt mt-2' ></i>
			</div>
			<Modal
				isOpen={isModalOpen}
				title={t('updateAddress', { ns: 'naming' })}
				closeModal={() => setIsModalOpen(false)}
				body={
					<div className='p-4'>
						<TextInput
							value={addressData.firstName}
							setValue={updateAddressDataState}
							title={t('firstName', { ns: 'naming' })}
							objectElement="firstName"
						/>
						<TextInput
							value={addressData.lastName}
							setValue={updateAddressDataState}
							title={t('lastName', { ns: 'naming' })}
							objectElement="lastName"
						/>
						<TextInput
							value={addressData.address1}
							setValue={updateAddressDataState}
							title={t('address1', { ns: 'naming' })}
							objectElement="address1"
						/>
						<div className='mx-2 mb-2'>
							<h6 className='my-2'>{t('country', { ns: 'naming' })}</h6>
							<CountryFlag
								isEditable
								onChange={(e) => updateAddressDataState('country', e.value)}
								countryState={addressData.country}
							/>
						</div>
						<TextInput
							value={addressData.postalCode}
							setValue={updateAddressDataState}
							title={t('postalCode', { ns: 'naming' })}
							objectElement="postalCode"
						/>
						<TextInput
							value={addressData.city}
							setValue={updateAddressDataState}
							title={t('city', { ns: 'naming' })}
							objectElement="city"
						/>
						<TextInput
							value={addressData.province}
							setValue={updateAddressDataState}
							title={t('province', { ns: 'naming' })}
							objectElement="province"
						/>
						<div className='row mx-2'>
							<div className='col-3 p-0'>
								<h6 className='my-2'>{t('prefix', { ns: 'naming' })}</h6>
								<PrefixFlag
									isEditable
									onChange={(e) => updateAddressDataState('phonePrefix', e.value)}
									prefixState={addressData.phonePrefix}
								/>
							</div>
							<div className='col pe-2'>
								<TextInput
									value={addressData.phoneNumber}
									setValue={updateAddressDataState}
									title={t('phone', { ns: 'naming' })}
									objectElement="phoneNumber"
									className='me-0 w-100'
								/>
							</div>
						</div>
						<TextInput
							value={addressData.email}
							setValue={updateAddressDataState}
							title={t('email', { ns: 'naming' })}
							objectElement="email"
						/>
					</div>
				}
				buttons={[
					<button key={'a'} onClick={(e) => handleSaveData(addressData)} className='btn btn-primary'>{t('save', { ns: 'naming' })}</button>,
				]}
			/>
		</div>
	)
}

ModalUpdateAddress.propTypes = {
	address: PropTypes.object,
	saveData: PropTypes.func,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalUpdateAddress))
