import Barchart from 'components/Common/Charts/BarChart'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const CustomersDashboard = ({ customers, t }) => {

	const mapData = () => {
		const labels = []
		const values = []
		customers.sort((a, b) => b.offersCount - a.offersCount).forEach((item, index) => {
			if (index <= 9) {
				labels.push(item.domain)
				values.push(item.offersCount)
			}
		})
		return { labels, values }
	}

	const { labels, values } = useMemo(() => mapData(), [customers])

	return (
		<div className='pe-4'>
			<div style={{ height: '42.5rem', padding: '0.5rem' }}>
				<Barchart color={'#27e372'} labels={labels} values={values} tooltipLabel={t('offers', { ns: 'naming' })} />
			</div>
		</div>
	)
}

CustomersDashboard.propTypes = {
	customers: PropTypes.array,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({
	customers: state.Offers.offerDsahboardCustomers,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomersDashboard))
