import PropTypes from 'prop-types'
import { Component } from 'react'
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from 'reactstrap'

class Dropdown extends Component {
	constructor(props) {
		super(props)
		this.state = {
			value: props.value,
		}
	}
	handleChangeOption(option) {
		this.setState({
			...this.state,
			value: option && option.text ? option.text : option,
		})
		this.props.onChange(this.props.id, option)
	}
	handleOnClick(option) {
		if (this.props.isHandlingFullObjectOnChange)
			this.props.onChange && this.handleChangeOption(option)
		else
			this.props.onChange &&
				this.handleChangeOption(option.text ? option.text : option)

		option && option.action && option.action()
	}
	render() {
		const { options } = this.props

		return (
			<UncontrolledDropdown>
				<DropdownToggle
					caret
					disabled={this.props.disabled}
					className={this.props.className}
					outline
				>
					<span
						className={this.props.spanClassName ? this.props.spanClassName : `dropdownValue ${this.props.sameWidth && 'width-7rem'}`}
					>
						<span>{this.state.value}</span>
						<span>▼</span>
					</span>
				</DropdownToggle>
				<DropdownMenu>
					{options &&
						options.map(option => (
							<DropdownItem
								disabled={option.isDisabled}
								className="dropdown"
								key={this.props.id + option.value}
								onClick={() => this.handleOnClick(option)}
							>
								{option.text}
							</DropdownItem>
						))}
				</DropdownMenu>
			</UncontrolledDropdown>
		)
	}
}
Dropdown.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
			isDisabled: PropTypes.bool,
		}),
	),
	value: PropTypes.string,
	id: PropTypes.string,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	isHandlingFullObjectOnChange: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	sameWidth: PropTypes.bool,
	spanClassName: PropTypes.string,
}

export default Dropdown
