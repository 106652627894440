import { getDate } from 'common/Utils/StringUtilities'
import LabelsPrintableContent from 'components/Common/ProductionLabels/LabelsPrintableContent'
import ProductionLabel from 'components/Common/ProductionLabels/ProductionLabelHTML'
import { BARCODE_FORMAT } from 'constants/BarcodeLabels'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { connect } from 'react-redux'

export const BoxLabelPrinter = ({ orderInfo, boxLabelDownloaded, handleGenerateBoxLabel }) => {

	const label = useMemo(() => {
		if (orderInfo == null) return null
		const totalWeight = orderInfo?.orderItems.reduce((acc, item) => acc + item.part.weight, 0)
		return <ProductionLabel
			supplierContactName={`${orderInfo?.shippingAddress?.firstName} ${orderInfo?.shippingAddress?.lastName}`}
			organizationName={orderInfo?.shippingAddress?.company}
			postalCode={orderInfo?.shippingAddress?.postalCode}
			state={orderInfo?.shippingAddress?.state}
			country={orderInfo?.shippingAddress?.country}
			orderId={orderInfo?.id}
			orderDate={getDate(orderInfo?.creationDate)}
			totalWeight={totalWeight}
			id={`OC-${orderInfo?.id}-${orderInfo?.accountId}`}
			code={`OC-${orderInfo?.id}-${orderInfo?.accountId}`}
			text={`OC-${orderInfo?.id}-${orderInfo?.accountId}`}
			format={BARCODE_FORMAT}
			className={'page'}
		/>
	}, [orderInfo])

	return (
		<LabelsPrintableContent
			key={`printable_content_${orderInfo.id}`} //required
			id={`printable_content_${orderInfo.id}`} //required
			isHidingContent
			orientation='l'
			buttonDisabled={label == null}
			filename={`box_label_${orderInfo.id}`} //Filename
			buttonClassName={`me-2 ${boxLabelDownloaded != true ? 'box_label_button_active' : 'box_label_button'}`}
			buttonContent={
				<button
					className='border text-muted d-flex w-100 justify-content-center align-items-center h-100 bg-transparent border-0'
					onClick={() => { handleGenerateBoxLabel() }}
				>
					<i className={`bx bx-box ${boxLabelDownloaded != true ? 'text-white' : 'text-grey'}`} style={{ fontSize: '15px' }} />
				</button>
			}
		>
			{label}
		</LabelsPrintableContent>

	)
}

BoxLabelPrinter.propTypes = {
	orderInfo: PropTypes.object,
	boxLabelDownloaded: PropTypes.bool,
	handleGenerateBoxLabel: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BoxLabelPrinter)
