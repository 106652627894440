import { VectorMap } from '@react-jvectormap/core'
import { worldMill } from '@react-jvectormap/world'
import { getCountryNameByCountryCode } from 'common/Utils/CountryUtils'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export const CountriesChartMap = ({ countries, labels, isOffers }) => {
	const offersColors = ['#beedd0', '#39ed6f', '#20b309', '#66ad10']
	const orderColors = ['#e7f280', '#f7cb48', '#f7940a', '#c97704']
	return (
		<div style={{ margin: 'auto', width: '100%', height: '42.5rem' }}>
			<VectorMap
				map={worldMill}
				containerStyle={{
					width: '100%',
				}}
				backgroundColor="#FFFFFF"
				markerStyle={{
					initial: {
						fill: 'red',
					},
				}}
				regionStyle={{
					initial: {
						fill: '#e0e0e0',
						stroke: '#676767',
						strokeWidth: 2.5,
						fillOpacity: 1,
					},
				}}

				color='#FAFAFA'
				series={{
					regions: [
						{
							scale: isOffers ? offersColors : orderColors,
							values: countries,
							min: 0,
							max: 100,
						},
					],
				}}
				onRegionTipShow={function reginalTip(event, label, code) {
					const countryName = getCountryNameByCountryCode(code)
					const emails = labels[code] || []
					const accountsCount = [...new Set(emails)].length
					return label.html(
						`<div className='d-flex flex-column'>
							<span>${countryName}:</span>
							<div>
								<span>${isOffers ? 'Ofertas' : 'Pedidos'}: ${countries[code] || 0}</span>
								<span>Cuentas ${accountsCount}</span>
							</div>
						</div>`,
					)
				}}
				generate
			/>
		</div >
	)

}

CountriesChartMap.propTypes = {
	series: PropTypes.object,
	labels: PropTypes.object,
	isOffers: PropTypes.bool,

}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CountriesChartMap)
