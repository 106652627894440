import { roundCurrency } from 'common/Utils/NumberUtilities'
import { getDate, getDateWithHour, getStatusClassName } from 'common/Utils/StringUtilities'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Loader from '../../components/Common/Loader'

const ProductionCustomer = ({ t, orderDetail, isLoadingOrderDetail }) => {
	const { account, order, organization } = orderDetail

	const getShipmentOrderDate = (estimatedShipmentDate, deliveryDate) => {
		if (deliveryDate) return `${getDate(deliveryDate)} (${t('real', { ns: 'naming' })})`
		if (estimatedShipmentDate) return `${getDate(estimatedShipmentDate)} (${t('estimated', { ns: 'naming' })})`
	}

	return (
		<div>
			{!isLoadingOrderDetail ?
				<div>
					<p className="fs-3 mb-2 border-bottom border-dark-subtle"><strong>{t('client', { ns: 'naming' })}</strong> </p>
					<p><strong className="me-1">{t('name', { ns: 'naming' })}:</strong>{account?.personalInformation?.firstName + ' ' + account?.personalInformation?.lastName}</p>
					<p><strong className="me-1">{t('organization', { ns: 'naming' })}:</strong>{organization?.organizationName}</p>
					<p><strong className="me-1">{t('order_id', { ns: 'naming' })}:</strong>
						<Link
							to={`/order/${order.id}`}
							target="_blank"
						>
							{order?.id}
						</Link>
					</p>
					<p><strong className="me-1">{t('order_status', { ns: 'naming' })}:</strong> <span className={getStatusClassName(order?.statusId)}>{t(`order_status_${order?.statusId}`, { ns: 'status' })} </span> </p>
					<p><strong className="me-1">{t('order_date', { ns: 'naming' })}:</strong> {getDateWithHour(order?.creationDate)} </p>
					<p><strong className="me-1">{t('total_price', { ns: 'naming' })}:</strong>{order?.totalPrice && roundCurrency(order?.subtotal)}€ </p>
					<p><strong className="me-1">{t('payment_method', { ns: 'naming' })}:</strong> {order?.paymentMethodId && t(`payment_method_${order.paymentMethodId}_name`, { ns: 'paymentMethods' })}</p>
					<p><strong className="me-1">{t('client_shipping_date', { ns: 'naming' })}:</strong> {getShipmentOrderDate(order?.estimatedShipmentDate, order?.deliveryDate)} </p>
					<p className="mb-0"><strong>{t('production_term', { ns: 'naming' })}:</strong> {`${order?.productionDays} ${t('workingDays', { ns: 'naming' })}`} </p>
				</div>
				: <div className="d-flex justify-content-center">
					<Loader className="fs-1" />
				</div>
			}
		</div>
	)
}

ProductionCustomer.propTypes = {
	t: PropTypes.func,
	orderDetail: PropTypes.object,
	isLoadingOrderDetail: PropTypes.bool,
}
export default withTranslation()(ProductionCustomer)
