import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'

class TooltipMessage extends Component {
	constructor(props) {
		super(props)

		const randomFactor = new Date().getTime()
		this.state = {
			id: 'tooltipButton' + Math.floor(Math.random() * randomFactor),
		}
	}
	render() {
		return (
			<React.Fragment>
				<span className="d-flex justify-content-center">
					<button
						className="btn bg-secondary text-white p-1 rounded w-auto"
						type="button"
						data-tip
						data-for={this.state.id}
						onClick={this.props.onClick}

					>
						<i
							className={'bx bx-sm ' + this.props.className}
						/>
						<ReactTooltip
							id={this.state.id}
							place="left"
							type="info"
							effect="solid"
						>
							{this.props.message}
						</ReactTooltip>
					</button>
				</span>
			</React.Fragment>
		)
	}
}

TooltipMessage.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	onClick: PropTypes.func,
}

export default TooltipMessage
