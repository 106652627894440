import { formatNumberWithDots } from 'common/Utils/NumberUtilities'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'

const BillingChart = ({ labels, maxBilling, maxOrders, series }) => {
	const BAR_COLOR = '#FF8300'
	const LINE_COLOR = '#ffE550'

	const BAR_BORDER = 0
	const LINE_WIDTH = 3

	const options = {
		colors: [BAR_COLOR, LINE_COLOR],
		chart: {
			type: 'line',
			stacked: false,
			height: 350,
			toolbar: { show: false },
		},
		stroke: {
			width: [BAR_BORDER, LINE_WIDTH],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				columnWidth: '81%',
			},
		},
		labels,
		markers: {
			size: 0,
		},
		yaxis: [{
			opposite: true,
			max: maxBilling || 1,
			title: {
				text: 'Facturación',

			},
		}, {

			max: maxOrders || 1,
			title: {
				text: 'Pedidos',

			},
		}],
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (y) {
					if (typeof y !== 'undefined') {
						return formatNumberWithDots(y.toFixed(0))
					}
					return y

				},
			},
		},
	}
	return (
		<div style={{ height: '42.5rem' }}>
			<ReactApexChart height={'100%'} options={options} series={series} type="line" />
		</div>
	)
}
BillingChart.propTypes = {
	labels: PropTypes.array,
	maxBilling: PropTypes.number,
	maxOrders: PropTypes.number,
	series: PropTypes.array,
}

export default BillingChart
