import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TitleWithValueColumn from '../CommonComponents/TitleWithValueColumn'
import { getLapseDivisor } from '../utils'

export const OffersSidebar = ({ dateRange, offerStats, previousOfferStats }) => {
	const lapseDivisor = useMemo(() => getLapseDivisor(dateRange), [dateRange])

	return (
		<div>
			{offerStats != null && dateRange != null &&
				<div className="d-flex flex-column w-100 border-top">
					<div>
						<TitleWithValueColumn
							title="Ofertas"
							value={offerStats.offersCount}
							previousValue={previousOfferStats?.offersCount}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Piezas"
							value={offerStats.partsCount}
							previousValue={previousOfferStats?.partsCount}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Empresas"
							value={offerStats.customersCount}
							previousValue={previousOfferStats?.customersCount}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Ofertas Periódicas"
							value={roundAtDecimals(offerStats.offersCount / lapseDivisor, 2)}
							previousValue={roundAtDecimals(previousOfferStats?.offersCount / lapseDivisor, 2)}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Piezas Periódicas"
							value={roundAtDecimals(offerStats.partsCount / lapseDivisor, 2)}
							previousValue={roundAtDecimals(previousOfferStats?.partsCount / lapseDivisor, 2)}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Piezas por oferta"
							value={roundAtDecimals(offerStats.partsCount / offerStats.offersCount, 2)}
							previousValue={roundAtDecimals(previousOfferStats?.partsCount / previousOfferStats?.offersCount, 2)}
						/>
					</div>
				</div>
			}
		</div>
	)
}

OffersSidebar.propTypes = {
	t: PropTypes.func,
	offerStats: PropTypes.object,
	dateRange: PropTypes.object,
	previousOfferStats: PropTypes.object,
}

const mapStateToProps = (state) => ({
	offerStats: state.Offers.offerStats,
	previousOfferStats: state.Offers.previousOfferStats,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OffersSidebar))
