import { getRemainingDaysFromCurrentDate } from 'common/Utils/StringUtilities'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const RemainingDays = ({ date, finalDate, t }) => {

	const getClassName = days => {
		switch (true) {
			case 1 == days || 0 == days:
				return 'bx bxs-circle text-warning margin-top-017rem me-1'
			case 0 > days:
				return 'bx bxs-circle text-danger margin-top-017rem me-1'
			case 0 < days:
				return 'bx bxs-circle text-success margin-top-017rem me-1'
		}
	}

	const remainingDays = getRemainingDaysFromCurrentDate(date, finalDate || null)

	const remainingDaysText = remainingDays === 1 ?
		t('day', { ns: 'naming' }) + ' ' + t('remaining', { ns: 'naming' }) :
		t('days', { ns: 'naming' }) + ' ' + t('remainings', { ns: 'naming' })

	return (
		<div>
			<span className='d-flex flex-row'>
				<i className={getClassName(remainingDays)}></i>
				<strong className='me-1'>{remainingDays} </strong>
				<strong>{remainingDaysText} </strong>
			</span>
		</div>
	)
}

RemainingDays.propTypes = {
	date: PropTypes.string,
	finalDate: PropTypes.string,
	t: PropTypes.func,
}

export default withTranslation()(RemainingDays)
