import { useGetPartConfigOptions } from './useGetPartConfigOptions'

export function withGetPartConfigOptions(Component) {

	return function WrappedComponent(props) {
		const {
			partConfigOptions,
			partConfigOptionsLoading,
			partConfigOptionsError,
		} = useGetPartConfigOptions()

		return (
			<Component
				{...props}
				partConfigOptions={partConfigOptions}
				partConfigOptionsLoading={partConfigOptionsLoading}
				partConfigOptionsError={partConfigOptionsError}
			/>
		)
	}

}
