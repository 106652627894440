import { ChatItemsSelector } from 'components/Chat/ChatItemSelector'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import ChatMessages from '../../components/Chat/ChatMessages'
import ChatPartInfo from '../../components/Chat/ChatPartInfo'

const QuoteChat = ({ parts, supplierId, selectedPart, handleOnChangeSelectedPart, openedChats, partConfigOptions, i18n }) => {

	const getAvailableParts = () => {
		return parts.map(part => ({
			...part,
			hasUnreadMessages: part.hasUnreadMessages && !openedChats.includes(part.id),

		}))
	}

	const availableParts = useMemo(() => getAvailableParts(), [parts, openedChats])

	return (
		<div className='row'>
			<div className='col-3'>
				<ChatItemsSelector
					items={availableParts}
					handleOnChangeItem={handleOnChangeSelectedPart}
					selectedItemId={selectedPart?.id}
					sectionClassName='sectionParts'
				/>
			</div>
			<div className='d-flex flex-column col-9'>
				{selectedPart != null && <ChatPartInfo partData={selectedPart} partConfigOptions={partConfigOptions} i18n={i18n} />}
				<ChatMessages partId={selectedPart?.id} supplierId={supplierId} />
			</div>
		</div>
	)
}

QuoteChat.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
	parts: PropTypes.array,
	supplierId: PropTypes.number,
	selectedPart: PropTypes.object,
	handleOnChangeSelectedPart: PropTypes.func,
	openedChats: PropTypes.array,
	handleOpenChat: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

export default QuoteChat
