import { call, put, takeEvery } from 'redux-saga/effects'
import { updateOrderExpeditionShipmentFiles } from 'store/actions'
import { postForm2 } from '../../helpers/api_helper'
import {
	DELETE_COMPLETE_PRODUCTION_FILE,
	DELETE_MODIFIED_BLUEPRINTS,
	DELETE_OLD_PART_FILES,
	DOWNLOAD_PART_LIST_IMAGES_IN_BASE64,
	GET_ALL_ORDER_SHIPMENT_FILES,
	GET_COMPLETE_PRODUCTION_FILES,
	GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID,
	GET_PARTS_FILES,
	GET_PART_LIST_BLUEPRINTS_AND_STP,
	GET_PART_LIST_IMAGES,
	GET_PRODUCTION_CHAT_IMAGES,
	GET_QUALITY_VERIFICATION_IMAGES,
	GET_QUOTE_CHAT_IMAGES,
	GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID,
	GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID,
	UPLOAD_COMPLETE_PRODUCTION_FILES,
	UPLOAD_MANAGER_TASK_IMAGE,
	UPLOAD_ORDER_DELIVERY_NOTE,
	UPLOAD_ORDER_SHIPMENT_FILES,
	UPLOAD_PART_FILES,
	UPLOAD_PART_IMAGE,
	UPLOAD_PRODUCTION_CHAT_IMAGE,
	UPLOAD_PROFILE_IMAGE,
	UPLOAD_QUALITY_VERIFICATION_IMAGES,
	UPLOAD_QUOTE_CHAT_IMAGE,
	UPLOAD_RESOLUTION_IMAGE,
	UPLOAD_TO_CAD,
} from './actionTypes'

import {
	deleteCompleteProductionFileFail,
	deleteCompleteProductionFileSuccess,
	deleteModifiedBlueprintsFail,
	deleteModifiedBlueprintsSuccess,
	deleteOldPartFilesFail,
	deleteOldPartFilesSuccess,
	downloadPartListImagesInBase64Fail,
	downloadPartListImagesInBase64Success,
	getAllOrderShipmentFilesFail,
	getAllOrderShipmentFilesSuccess,
	getCompleteProductionFilesFail,
	getCompleteProductionFilesSuccess,
	getManagerTaskImagesByNotificationIdFail,
	getManagerTaskImagesByNotificationIdSuccess,
	getPartListBlueprintsAndStpFail,
	getPartListBlueprintsAndStpSuccess,
	getPartListImagesFail,
	getPartListImagesSuccess,
	getPartsFilesFail,
	getPartsFilesSuccess,
	getProductionChatImagesFail,
	getProductionChatImagesSuccess,

	getQualityVerificationImagesFail,
	getQualityVerificationImagesSuccess,
	getQuoteChatImagesFail,
	getQuoteChatImagesSuccess,
	getResolutionsImagesByIncidenceIdFail,
	getResolutionsImagesByIncidenceIdSuccess,
	getSupplierQuotationFilesByOfferIdFail,
	getSupplierQuotationFilesByOfferIdSuccess,
	uploadCompleteProductionFilesFail,
	uploadCompleteProductionFilesSuccess,
	uploadManagerTaskImageFail,
	uploadManagerTaskImageSuccess,
	uploadOrderDeliveryNoteFail,
	uploadOrderDeliveryNoteSuccess,
	uploadOrderShipmentFilesFail,
	uploadOrderShipmentFilesSuccess,
	uploadPartFilesFail,
	uploadPartFilesSuccess,
	uploadPartImageFail,
	uploadPartImageSuccess,
	uploadProductionChatImageFail,
	uploadProductionChatImageSuccess,
	uploadProfileImageFail,
	uploadProfileImageSuccess,
	uploadQualityVerificationImagesFail,
	uploadQualityVerificationImagesSuccess,
	uploadQuoteChatImageFail,
	uploadQuoteChatImageSuccess,
	uploadResolutionImageFail,
	uploadResolutionImageSuccess,
	uploadToCadFail,
	uploadToCadSuccess,
} from './actions'

import {
	DELETE_COMPLETE_PRODUCTION_FILE_ENDPOINT,
	DELETE_MODIFIED_BLUEPRINTS_ENDPOINT,
	DELETE_OLD_PART_FILES_ENDPOINT,
	GET_ALL_ORDER_SHIPMENT_FILES_ENDPOINT,
	GET_COMPLETE_PRODUCTION_FILES_ENDPOINT,
	GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_ENDPOINT,
	GET_PART_LIST_BLUEPRINTS_AND_STP_ENDPOINT,
	GET_PART_LIST_FILES_ENDPOINT,
	GET_PART_LIST_IMAGES_ENDPOINT,
	GET_PRODUCTION_CHAT_IMAGES_ENDPOINT,
	GET_QUALITY_VERIFICATION_IMAGES_ENDPOINT,
	GET_QUOTE_CHAT_IMAGES_ENDPOINT,
	GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_ENDPOINT,
	GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_ENDPOINT,
	UPLOAD_COMPLETE_PRODUCTION_FILES_ENDPOINT,
	UPLOAD_MANAGER_TASK_IMAGE_ENDPOINT,
	UPLOAD_ORDER_DELIVERY_NOTE_ENDPOINT,
	UPLOAD_ORDER_SHIPMENT_FILES_ENDPOINT,
	UPLOAD_PART_FILES_ENDPOINT,
	UPLOAD_PART_IMAGE_ENDPOINT,
	UPLOAD_PRODUCTION_CHAT_IMAGE_ENDPOINT,
	UPLOAD_PROFILE_IMAGE_ENDPOINT,
	UPLOAD_QUALITY_VERIFICATION_IMAGES_ENDPOINT,
	UPLOAD_QUOTE_CHAT_IMAGE_ENDPOINT,
	UPLOAD_RESOLUTION_IMAGE_ENDPOINT,
	UPLOAD_TO_CAD_ENDPOINT,
} from '../../constants/backendRoutes'

import { get, post, postFormData, postFormLabels } from '../../helpers/api_helper'

import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import { downloadFileAsBase64 } from 'common/Utils/downloadUtils/downloadFileAsBase64'
import { saveAs } from 'file-saver'

function* uploadManagerTaskImage({ payload: { data, taskImages } }) {
	try {
		const url = UPLOAD_MANAGER_TASK_IMAGE_ENDPOINT
		const response = yield call(postFormData, url, { data, taskImages })
		yield put(uploadManagerTaskImageSuccess(response))
	} catch (error) {
		yield put(uploadManagerTaskImageFail(error))
	}
}

function* getManagerTaskImagesByNotificationId({ payload: { data } }) {
	try {
		const url = GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID_ENDPOINT
		const response = yield call(post, url, data)
		yield put(getManagerTaskImagesByNotificationIdSuccess(response))
	} catch (error) {
		yield put(getManagerTaskImagesByNotificationIdFail(error))
	}
}


function* uploadResolutionImage({ payload: { data, resolutionsImages } }) {
	try {
		const url = UPLOAD_RESOLUTION_IMAGE_ENDPOINT
		const response = yield call(postFormData, url, { data, resolutionsImages })
		yield put(uploadResolutionImageSuccess(response))
	} catch (error) {
		yield put(uploadResolutionImageFail(error))
	}
}


function* getResolutionsImagesByIncidenceId({ payload: { data } }) {
	try {
		const url = GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID_ENDPOINT
		const response = yield call(post, url, data)
		yield put(getResolutionsImagesByIncidenceIdSuccess(response))
	} catch (error) {
		yield put(getResolutionsImagesByIncidenceIdFail(error))
	}
}


function* getSupplierQuotationFilesByOfferId({ payload: { offerId, supplierId } }) {
	try {
		const url = `${GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID_ENDPOINT}/${offerId}/${supplierId}`
		const response = yield call(get, url, { responseType: 'arraybuffer' })
		const blob = new Blob([new Uint8Array(response)], { type: 'application/zip' })
		saveAs(blob, `${offerId}.zip`)

		yield put(getSupplierQuotationFilesByOfferIdSuccess(response))
	} catch (error) {
		yield put(getSupplierQuotationFilesByOfferIdFail(error))
	}
}

function* getPartListImages({ payload: { partListId } }) {
	try {
		const url = `${GET_PART_LIST_IMAGES_ENDPOINT}`
		const data = {
			data: {
				partIds: partListId,
			},
		}
		const response = yield call(post, url, data)
		yield put(getPartListImagesSuccess(response))
	} catch (error) {
		yield put(getPartListImagesFail(error))
	}
}


function* uploadProfileImage({ payload: { accountId, accountImage } }) {
	try {
		const url = `${UPLOAD_PROFILE_IMAGE_ENDPOINT}`
		const input = {
			data: {
				accountId: accountId,
			},

			files: {
				accountImage: accountImage,
			},

		}

		const response = yield call(postFormData, url, input)
		yield put(uploadProfileImageSuccess(response))
	} catch (error) {
		yield put(uploadProfileImageFail(error))
	}
}

function* uploadPartImages({ payload: { partListId, file } }) {
	try {
		const url = `${UPLOAD_PART_IMAGE_ENDPOINT}`
		const data = {
			data: {
				partId: partListId,
				files: {
					image: file,
				},
			},
		}
		const response = yield call(post, url, data)
		yield put(uploadPartImageSuccess(response))
	} catch (error) {
		yield put(uploadPartImageFail(error))
	}
}

/**
 * uploadPartFiles
 * @example
 * uploadPartFiles({
 *   body: {
 *     data: {
 *       partId, // number
 *     },
 *     files: {
 *       image, // Blob or undefined to not change it
 *       model3d, // Blob or undefined to not change it
 *       modifiedModel3d, // Blob or undefined to not change it
 *       blueprint, // Blob or undefined to not change it
 *       modifiedBlueprint, // Blob or undefined to not change it
 *       modelStl, // Blob or undefined to not change it
 *     },
 *   }
 * })
 */
function* uploadPartFiles({ payload: {
	body,
} }) {
	try {
		const url = `${UPLOAD_PART_FILES_ENDPOINT}`
		const response = yield call(postForm2, url, body)
		yield put(uploadPartFilesSuccess({ response }))
	} catch (error) {
		yield put(uploadPartFilesFail({ error }))
	}
}

/**
 * uploadToCad
 * @example
 * uploadToCad({
 *   body: {
 *     data: {
 *       partId, // number
 *     },
 *     files: {
 *       file, // Blob
 *     },
 *   }
 * })
 */
function* uploadToCad({ payload: {
	body,
} }) {
	try {
		const url = `${UPLOAD_TO_CAD_ENDPOINT}`
		const response = yield call(postForm2, url, body)
		yield put(uploadToCadSuccess({ response }))
	} catch (error) {
		yield put(uploadToCadFail({ error }))
	}
}

/**
 * deleteOldPartFiles
 * @example
 * deleteOldPartFiles({
 *   body: {
 *     data: {
 *       partId, // number
 *     },
 *   }
 * })
 */
function* deleteOldPartFiles({ payload: {
	body,
} }) {
	try {
		const url = `${DELETE_OLD_PART_FILES_ENDPOINT}`
		const response = yield call(post, url, body)
		yield put(deleteOldPartFilesSuccess({ response }))
	} catch (error) {
		yield put(deleteOldPartFilesFail({ error }))
	}
}

function* getPartListBlueprintsAndStp({ payload: { partListId } }) {
	try {
		const url = `${GET_PART_LIST_BLUEPRINTS_AND_STP_ENDPOINT}`
		const data = {
			data: {
				partIds: partListId,
			},
		}
		const response = yield call(post, url, data)
		yield put(getPartListBlueprintsAndStpSuccess(response))
	} catch (error) {
		yield put(getPartListBlueprintsAndStpFail(error))
	}
}

function* getPartsFiles({ payload: { partListId } }) {
	try {
		const url = `${GET_PART_LIST_FILES_ENDPOINT}`
		const data = {
			data: {
				partIds: partListId,
			},
		}
		const response = yield call(post, url, data)
		yield put(getPartsFilesSuccess(response))
	} catch (error) {
		yield put(getPartsFilesFail(error))
	}
}

function* uploadQuoteChatImage({ payload: { accountId, partId, supplierId, images } }) {
	try {
		const { token } = getUserValuesFromLocalStorage()
		const url = `${UPLOAD_QUOTE_CHAT_IMAGE_ENDPOINT}`
		const formData = new FormData()
		for (let i = 0; i < images.length; i++) {
			const image = images[i]
			formData.append('images', image, image.name)
		}
		formData.append('data', JSON.stringify({
			accountId: accountId,
			partId: partId,
			supplierId: supplierId,
		}))
		const response = fetch(url, {
			method: 'POST',
			body: formData,
			headers: { Authorization: token },
		})
		yield put(uploadQuoteChatImageSuccess(response))
	} catch (error) {
		yield put(uploadQuoteChatImageFail(error))
	}
}

function* getQuoteChatImage({ payload: { partId, supplierId } }) {
	try {
		const url = `${GET_QUOTE_CHAT_IMAGES_ENDPOINT}/${partId}/${supplierId}`
		const response = yield call(get, url)
		yield put(getQuoteChatImagesSuccess(response))
	} catch (error) {
		yield put(getQuoteChatImagesFail(error))
	}
}

function* getProductionChatImage({ payload: { orderId, orderFragmentId } }) {
	try {
		const url = `${GET_PRODUCTION_CHAT_IMAGES_ENDPOINT}/${orderId}/${orderFragmentId}`
		const response = yield call(get, url)
		yield put(getProductionChatImagesSuccess(response))
	} catch (error) {
		yield put(getProductionChatImagesFail(error))
	}
}

function* uploadProductionChatImage({ payload: { accountId, orderId, orderFragmentId, chatMessageId, images } }) {
	try {
		const { token } = getUserValuesFromLocalStorage()
		const url = `${UPLOAD_PRODUCTION_CHAT_IMAGE_ENDPOINT}`
		const formData = new FormData()
		for (let i = 0; i < images.length; i++) {
			const image = images[i]
			formData.append('images', image, image.name)
		}
		formData.append('data', JSON.stringify({
			accountId: accountId,
			orderId: orderId,
			orderFragmentId: orderFragmentId,
			chatMessageId: chatMessageId,
		}))
		const response = fetch(url, {
			method: 'POST',
			body: formData,
			headers: { Authorization: token },
		})
		yield put(uploadProductionChatImageSuccess(response))
	} catch (error) {
		yield put(uploadProductionChatImageFail(error))
	}
}

function* uploadCompleteProductionFiles({ payload: { data, files } }) {
	try {
		const url = UPLOAD_COMPLETE_PRODUCTION_FILES_ENDPOINT
		const response = yield call(postFormData, url, { data, files })
		yield put(uploadCompleteProductionFilesSuccess(response))
	} catch (error) {
		yield put(uploadCompleteProductionFilesFail(error))
	}
}

function* getCompleteProductionFiles({ payload }) {
	try {
		const url = GET_COMPLETE_PRODUCTION_FILES_ENDPOINT
		const response = yield call(post, url, payload)
		yield put(getCompleteProductionFilesSuccess(response))
	} catch (error) {
		yield put(getCompleteProductionFilesFail(error))
	}
}

function* deleteModifiedBlueprints({ payload: { data } }) {
	try {
		const url = DELETE_MODIFIED_BLUEPRINTS_ENDPOINT
		const response = yield call(post, url, data)
		yield put(deleteModifiedBlueprintsSuccess(response))
	} catch (error) {
		yield put(deleteModifiedBlueprintsFail(error))
	}
}

function* uploadOrderShipmentFiles({ payload: { data } }) {
	try {
		const url = UPLOAD_ORDER_SHIPMENT_FILES_ENDPOINT
		const response = yield call(postFormLabels, url, data)
		yield put(uploadOrderShipmentFilesSuccess(response))
	}
	catch (error) {
		yield put(uploadOrderShipmentFilesFail(error))
	}
}

function* uploadQualityVerificationImages({ payload: { data, images } }) {
	try {
		const url = UPLOAD_QUALITY_VERIFICATION_IMAGES_ENDPOINT
		const response = yield call(postFormData, url, { data, images })
		yield put(uploadQualityVerificationImagesSuccess(response))
	} catch (error) {
		yield put(uploadQualityVerificationImagesFail(error))
	}
}

function* downloadPartListImagesInBase64({ payload: { data } }) {
	try {

		const url = GET_PART_LIST_IMAGES_ENDPOINT

		const partListId = data.map((item) => item.id)

		const input = {
			data: {
				partIds: partListId,
			},
		}
		const { images } = yield call(post, url, input)
		for (const image of images) {
			image.imageBase64 = yield call(downloadFileAsBase64, image.src)
		}
		yield put(downloadPartListImagesInBase64Success(images))
	} catch (error) {
		yield put(downloadPartListImagesInBase64Fail(error))
	}
}

function* uploadOrderDeliveryNote({ payload: { data, deliveryNote } }) {
	try {
		const url = UPLOAD_ORDER_DELIVERY_NOTE_ENDPOINT
		const response = yield call(postFormData, url, { data, deliveryNote })
		yield put(uploadOrderDeliveryNoteSuccess(response))
	} catch (error) {
		yield put(uploadOrderDeliveryNoteFail(error))
	}
}

function* getAllOrderShipmentFiles({ payload: orderId }) {
	try {
		const url = `${GET_ALL_ORDER_SHIPMENT_FILES_ENDPOINT}/${orderId}`
		const response = yield call(get, url)

		yield put(updateOrderExpeditionShipmentFiles(response))
		yield put(getAllOrderShipmentFilesSuccess(response))
	} catch (error) {
		yield put(getAllOrderShipmentFilesFail(error))
	}
}

function* getQualityVerificationImages({ payload: { data, dontShowModalAutomatic } }) {
	try {
		const url = GET_QUALITY_VERIFICATION_IMAGES_ENDPOINT
		const response = yield call(post, url, data)
		yield put(getQualityVerificationImagesSuccess(response, dontShowModalAutomatic))
	}
	catch (error) {
		yield put(getQualityVerificationImagesFail(error))
	}
}

function* deleteCompleteProductionFile({ payload: { orderId, orderFragmentId, filename } }) {
	try {
		const url = DELETE_COMPLETE_PRODUCTION_FILE_ENDPOINT
		const input = {
			data: { orderId, orderFragmentId, filename }
		}
		const response = yield call(post, url, input)
		yield put(deleteCompleteProductionFileSuccess(response))
	} catch (error) {
		yield put(deleteCompleteProductionFileFail(error))
	}
}

function* cloudStorageSaga() {
	yield takeEvery(GET_SUPPLIER_QUOTATION_FILES_BY_OFFER_ID, getSupplierQuotationFilesByOfferId)
	yield takeEvery(GET_PART_LIST_IMAGES, getPartListImages)
	yield takeEvery(UPLOAD_PART_IMAGE, uploadPartImages)
	yield takeEvery(UPLOAD_QUOTE_CHAT_IMAGE, uploadQuoteChatImage)
	yield takeEvery(GET_QUOTE_CHAT_IMAGES, getQuoteChatImage)
	yield takeEvery(GET_PART_LIST_BLUEPRINTS_AND_STP, getPartListBlueprintsAndStp)
	yield takeEvery(GET_PARTS_FILES, getPartsFiles)
	yield takeEvery(GET_PRODUCTION_CHAT_IMAGES, getProductionChatImage)
	yield takeEvery(UPLOAD_PRODUCTION_CHAT_IMAGE, uploadProductionChatImage)
	yield takeEvery(UPLOAD_COMPLETE_PRODUCTION_FILES, uploadCompleteProductionFiles)
	yield takeEvery(GET_COMPLETE_PRODUCTION_FILES, getCompleteProductionFiles)
	yield takeEvery(DELETE_MODIFIED_BLUEPRINTS, deleteModifiedBlueprints)
	yield takeEvery(UPLOAD_ORDER_SHIPMENT_FILES, uploadOrderShipmentFiles)
	yield takeEvery(UPLOAD_QUALITY_VERIFICATION_IMAGES, uploadQualityVerificationImages)
	yield takeEvery(DOWNLOAD_PART_LIST_IMAGES_IN_BASE64, downloadPartListImagesInBase64)
	yield takeEvery(UPLOAD_ORDER_DELIVERY_NOTE, uploadOrderDeliveryNote)
	yield takeEvery(UPLOAD_PROFILE_IMAGE, uploadProfileImage)
	yield takeEvery(GET_ALL_ORDER_SHIPMENT_FILES, getAllOrderShipmentFiles)
	yield takeEvery(GET_QUALITY_VERIFICATION_IMAGES, getQualityVerificationImages)
	yield takeEvery(DELETE_COMPLETE_PRODUCTION_FILE, deleteCompleteProductionFile)
	yield takeEvery(GET_RESOLUTIONS_IMAGES_BY_INCIDENCE_ID, getResolutionsImagesByIncidenceId)
	yield takeEvery(UPLOAD_RESOLUTION_IMAGE, uploadResolutionImage)
	yield takeEvery(GET_MANAGER_TASK_IMAGES_BY_NOTIFICATION_ID, getManagerTaskImagesByNotificationId)
	yield takeEvery(UPLOAD_MANAGER_TASK_IMAGE, uploadManagerTaskImage)
	yield takeEvery(UPLOAD_PART_FILES, uploadPartFiles)
	yield takeEvery(UPLOAD_TO_CAD, uploadToCad)
	yield takeEvery(DELETE_OLD_PART_FILES, deleteOldPartFiles)
}

export default cloudStorageSaga
