import { formatNumberWithDots } from 'common/Utils/NumberUtilities'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'

export const PurchasesChart = ({ labels, maxCosts, maxPurchases, series }) => {
	const BAR_COLOR = '#ff00f7'
	const LINE_COLOR = '#8400ff'

	const BAR_BORDER = 0
	const LINE_WIDTH = 3

	const options = {
		colors: [BAR_COLOR, LINE_COLOR],
		chart: {
			type: 'line',
			stacked: false,
			height: 350,
			toolbar: { show: false },
		},
		stroke: {
			width: [BAR_BORDER, LINE_WIDTH],
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				columnWidth: '81%',
			},
		},
		labels,
		markers: {
			size: 0,
		},
		yaxis: [{
			opposite: true,
			max: maxPurchases,
			title: {
				text: 'Facturación',

			},
		}, {

			max: maxCosts,
			title: {
				text: 'Pedidos',

			},
		}],
		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (y) {
					if (typeof y !== 'undefined') {
						return formatNumberWithDots(y.toFixed(0))
					}
					return y

				},
			},
		},
	}
	return (
		<div style={{ height: '42.5rem' }}>
			<ReactApexChart height={'100%'} options={options} series={series} type="line" />
		</div>
	)

}

PurchasesChart.propTypes = {
	series: PropTypes.array,
	labels: PropTypes.array,
	maxPurchases: PropTypes.number,
	maxCosts: PropTypes.number,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasesChart)
