import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { sortByDate } from 'common/Utils/ArrayUtilities'
import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { getIdFromUrl } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import Table from 'components/Table/Table'
import appRoutes from 'constants/appRoutes'
import OfferCustomer from 'pages/OfferList/OfferCustomer'
import OfferTechnology from 'pages/OfferList/OfferTechnology'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getDomainOffers } from 'store/actions'

export const DomainOffers = ({ getDomainOffers, domainOffers, isLoading, error, t, partConfigOptions }) => {

	const [itemsToRender, setItemsToRender] = useState([])

	useEffect(() => {
		if (domainOffers.length === 0) getDomainOffers(getIdFromUrl())
	}, [])

	useEffect(() => {
		if (domainOffers.length > 0) {
			setItemsToRender(mapOffers(domainOffers))
		}
	}, [domainOffers])

	const mapOffers = (offers) => {
		const offersSorted = sortByDate([...offers], 'date')
		const offersMapped = offersSorted.map(offer => {
			return {
				id: offer.id,
				status: {
					value: {
						id: offer.status,
						text: t('offer_status_' + offer.status, { ns: 'status' }),
					},
				},
				parts: offer.parts,
				technologies: <OfferTechnology technologies={[... new Set(offer.technologies)]} partConfigOptions={partConfigOptions} />,
				message: offer.message,
				customer: <OfferCustomer organizationId={offer.customer.organizationId} title={offer.customer.title} email={offer.customer.email} crmLink={offer.customer.crmLink} isFirstUserOffer={offer.isFirstUserOffer} />,
				date: { value: new Date(offer.date) },
			}
		})

		return offersMapped
	}

	const headerToRender = {
		id: t('id', { ns: 'naming' }),
		status: t('status', { ns: 'naming' }),
		parts: t('parts', { ns: 'naming' }),
		technologies: t('technologies', { ns: 'naming' }),
		message: t('message', { ns: 'naming' }),
		customer: t('client', { ns: 'naming' }),
		date: t('date', { ns: 'naming' }),
	}

	return (
		<div>
			{isLoading ? <div className='d-flex justify-content-center'><Loader className={'fs-1'} /></div>
				: error ? <Alert
					type="danger"
					centered
					fitContent
					message={error}
				/> :
					domainOffers.length === 0 ? <div className='d-flex justify-content-center'>{t('no_offers_yet', { ns: 'naming' })}</div> :
						<div>
							{itemsToRender.length !== 0 &&
								<div>
									<Table
										header={headerToRender}
										items={itemsToRender}
										selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
										language={getLanguage()}
										showStatusFilter={true}
										showActiveColumFilter={true}
										allowAllItemsPerPage
										pageToNavigate={appRoutes.OFFER_DETAIL_ROUTE_NO_ID}
										styleForFieldList={[
											{
												field: 'id',
												styles: [
													{
														type: 'link',
													},
												],
											},
											{
												field: 'message',
												styles: [
													{
														type: 'tooltip',
														class: 'bx-chat fs-2',
													},
												],
											},
											{
												field: 'status',
												styles: [
													{
														type: 'grayBg',
														values: ['10'],
														class: 'rounded-pill bg-secondary text-white',
													},
													{
														type: 'yellowBg',
														values: ['20'],
														class: 'rounded-pill bg-yellow text-white',
													},
													{
														type: 'orangeBg',
														values: ['30'],
														class: 'rounded-pill bg-orange text-white',
													},
													{
														type: 'greenBg',
														values: ['40'],
														class: 'rounded-pill bg-green text-white',
													},
													{
														type: 'redBg',
														values: ['91'],
														class: 'rounded-pill bg-red text-white',
													},
													{
														type: 'redBg',
														values: ['92'],
														class: 'rounded-pill bg-red text-white',
													},
												],
											},
											{
												field: 'parts',
												styles: [
													{
														type: 'grayBg',
														class: 'rounded-pill bg-secondary text-white',
													},
												],
											},
											{
												field: 'date',
												styles: [
													{
														type: 'object',
														class: 'd-block',
													},
												],
											},
										]}
									/>
								</div>
							}

						</div>
			}
		</div>
	)
}

const mapStateToProps = (state) => ({
	domainOffers: state.Offers.domainOffers,
	isLoading: state.Offers.isLoading,
	error: state.Offers.error,
})

const mapDispatchToProps = {
	getDomainOffers,
}

DomainOffers.propTypes = {
	domainOffers: PropTypes.array,
	getDomainOffers: PropTypes.func,
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	error: PropTypes.object,
}

export default withGetPartConfigOptions(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DomainOffers)))
