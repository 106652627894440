import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import CalculationMarginModal from './CalculationMarginModal'

const EditMarginsModal = ({
	isEditMarginsModalOpen,
	closeEditMarginsModal,
	marginModal,
	setMargin,
	setUnitCostByMargin,
	saveAndCloseMarginsModal,
	t,

}) => {
	const newMargins = marginModal && marginModal.quotes && marginModal.quotes.map(quote =>
	({ quantity: quote.quantity, value: roundAtDecimals(quote?.margin?.value, 2) || '' }
	))

	const quotes = marginModal?.quotes?.map(quote => ({
		...quote,
		quotation: {
			...quote.quotation,
			value: parseFloat(quote.quotation.value)
		}
	})) || []
	return (

		<Modal
			className="edit-margins-modal"
			isOpen={isEditMarginsModalOpen}
			closeModal={closeEditMarginsModal}
			title={t('editMargins', { ns: 'naming' })}
			body={
				marginModal &&
					marginModal.quotes &&
					marginModal.quotes.length > 0 ? (
					<div className="modal-body">
						<Row className="font-12-px">
							<Col className="col-3">
								<span className="w-100 bottom-text">
									{t('quantity', { ns: 'naming' })}
								</span>
							</Col>
							<Col className="col-3">
								<span className="w-100 bottom-text">
									{t('unitBuying', { ns: 'naming' })}
								</span>
							</Col>
							<Col className="col-3">
								<span className="w-100 bottom-text">
									{t('marginPercentage', { ns: 'naming' })}
								</span>
							</Col>
							<Col className="col-3">
								<span className="w-100 bottom-text">
									{t('unitSelling', { ns: 'naming' })}
								</span>
							</Col>
						</Row>
						{marginModal &&
							marginModal.quotes.length > 0 &&
							marginModal.quotes.map((quote, index) => {

								return (
									<Row key={quote.quantity}>
										<Col className="col-3">
											<input
												className="w-100 mb-3"
												disabled
												value={quote.quantity || ''}
											/>
										</Col>
										<Col className="col-3">
											<input
												className="w-100 mb-3"
												disabled
												value={roundAtDecimals(quotes[index]?.quotation?.value, 2) || ''}
											/>
										</Col>
										<Col className="col-3">
											<input
												type="number"
												className="w-100 mb-3"
												value={roundAtDecimals(quote?.margin?.value, 2) || ''}
												onChange={e =>
													setMargin(
														e.target.valueAsNumber,
														marginModal,
														index,
													)
												}
											/>
										</Col>
										<Col className="col-3">
											<input
												type="number"
												className="w-100 mb-3"
												value={roundAtDecimals(quote?.unitSell?.value, 2) || ''}
												onChange={e =>
													setUnitCostByMargin(
														e.target.valueAsNumber,
														index,
													)
												}
											/>
										</Col>
									</Row>
								)
							}
							)

						}

						<CalculationMarginModal quoteId={marginModal.quoteId} newMargins={newMargins} />
					</div>
				) : (
					<div className="modal-body">
						{t('quotationNotAvailable', { ns: 'errors' })}
					</div>
				)
			}
			buttons={
				(marginModal &&
					marginModal.quotes &&
					marginModal.quotes.length > 0 && [
						<button
							type="button"
							key="btn3"
							className="btn btn-primary"
							onClick={() =>
								saveAndCloseMarginsModal(
									marginModal.partId.value,
									marginModal.supplierId,
									marginModal.quotes,
								)
							}
						>
							{t('submit', { ns: 'naming' })}
						</button>,
					]) || []
			}
		/>
	)
}

EditMarginsModal.propTypes = {
	isEditMarginsModalOpen: PropTypes.bool,
	closeEditMarginsModal: PropTypes.func,
	marginModal: PropTypes.object,
	setMargin: PropTypes.func,
	setUnitCostByMargin: PropTypes.func,
	saveAndCloseMarginsModal: PropTypes.func,
	t: PropTypes.func,
}

export default (withTranslation()(EditMarginsModal))
