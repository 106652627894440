export const QUOTE_STATUS_REQUESTED = 20
export const QUOTE_STATUS_QUOTED = 30
export const QUOTE_STATUS_REJECTED = 91
export const QUOTE_STATUS_REJECT_PENDING = 93

export default {
	QUOTE_STATUS_REQUESTED,
	QUOTE_STATUS_QUOTED,
	QUOTE_STATUS_REJECTED,
	QUOTE_STATUS_REJECT_PENDING,
}
