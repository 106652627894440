import { connect } from 'react-redux'
import CrmLogs from './CrmLogs/CrmLogs'
import LeadScoring from './LeadScoring'

export const LeadScoreAndCrmLogs = () => {
	return (
		<div className='col p-0 ms-2 align-items-start'>
			<LeadScoring />
			<CrmLogs />
		</div>
	)
}

LeadScoreAndCrmLogs.propTypes = {}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LeadScoreAndCrmLogs)
