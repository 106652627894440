import {
	addVisualizingSupplierIdFromLocalStorage,
	getUserValuesFromLocalStorage,
	getVisualizingSupplierIdFromLocalStorage,
} from 'common/Utils/LocalStorageUtilities'
import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import Alert from 'components/Common/Alert'
import BlueprintAndStp from 'components/Common/BlueprintAndStp'
import CustomModal from 'components/Common/Modal/Modal'
import { QUOTE_STATUS_QUOTED, QUOTE_STATUS_REJECTED, QUOTE_STATUS_REJECT_PENDING, QUOTE_STATUS_REQUESTED } from 'constants/quoteStatus'
import { USER_ROLE_SUPPLIER } from 'constants/userRoles'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import MetaTags from 'react-meta-tags'
import { connect } from 'react-redux'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { adaptTimerString, adaptWeekday, getDate, getS3FileName, getStatusClassName, isStringInList } from '../../common/Utils/StringUtilities'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Loader from '../../components/Common/Loader'
import Table from '../../components/Table/Table'
import {
	getPartListImages,
	getPartsFiles,
	getQuoteChat,
	getQuoteChatImages,
	getQuoteDetail,
	getSupplierQuotationFilesByOfferId,
	getSuppliers,
	markQuoteMessagesAsRead,
	postQuoteChat,
	saveAndConfirmQuote,
	updateQuoteQuantities,
	uploadQuoteChatImage,
} from '../../store/actions'
import PartInfo from './PartInfo'
// import {QuoteIcon} from "./QuoteIcon";

import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { PartConfigTranslations, getClassnameByTechnology } from 'common/Utils/PartConfigTranslations'
import { getCSVFile } from 'common/Utils/fileUtils'
import { ChatIconIcon } from 'components/ChatIconIcon'
import { PartName } from 'components/Common/PartName'
import ZipDownloader from 'components/Common/ZipDownloader'
import QuoteChat from 'pages/Quote/QuoteChat'
import SimilarPartsModal from './Similarity/SimilarPartsModal'
import SimilarPartsWarning from './Similarity/SimilarPartsWarning'

function GetOfferIdFromURL() {
	const parts = window.location.pathname.split('/')
	return parts[parts.length - 1]
}

const VACCUM_CASTING_ID = 4
const CNC_ID = 1
const PRINT_3D_ID = 2
class Quote extends Component {
	constructor(props) {
		super(props)
		this.state = {
			quotesState: [], //Array con el estado de todas Quotes de las piezas que se cotizan en la Quotation (Ver ejemplo al final del archivo)
			isSaveAndConfirmQuoteEnabled: false,
			isSaveQuoteEnabled: false,
			quotesRejected: [], //Array con los identificadores de las Quotes rechazadas
			quotesAbleToModify: [], //Array con los identificadores de las Quotes que se pueden modificar
			translatedParts: [], //Array de translatedParts. Cada elemento incluye (ver ejemplo al final del archivo)
			isTranslating: false,
			language: '',
			modal: false,
			toggle: false,
			modalChat: false,
			inputText: null,
			chatImagesToUpload: [],
			lastMessageId: null,
			isOpenModalMessage: false,
			message: null,
			currentSimilarPartIds: [],
			isOpenSimilarityModal: false,
			selectedChatPartInfo: null,
			openedChats: [],
		}

		this.updateQuantities = this.updateQuantities.bind(this)
		this.updateContruction = this.updateContruction.bind(this)
		this.handleSaveQuote = this.handleSaveQuote.bind(this)
		this.handleSaveAndConfirmQuote = this.handleSaveAndConfirmQuote.bind(this)
		this.toggle = this.toggle.bind(this)
		this.enableSaveButtonForVC = this.enableSaveButtonForVC.bind(this)
		this.handleOnChange = this.handleOnChange.bind(this)
		this.handleOnChangeFile = this.handleOnChangeFile.bind(this)
		this.toggleChat = this.toggleChat.bind(this)
		this.handleMessageId = this.handleMessageId.bind(this)
		this.handleClickSimilarity = this.handleClickSimilarity.bind(this)
		this.handleOnCloseSimilarityModal = this.handleOnCloseSimilarityModal.bind(this)
		this.handleOnChangeChatSelectedPart = this.handleOnChangeChatSelectedPart.bind(this)
	}
	checkRole(role) {
		return isStringInList(role, this.props.userRoles)
	}

	toggleChat() {
		this.setState({
			modalChat: !this.state.modalChat,
			isOpen: !this.state.isOpen,
		})
	}

	handleOnChangeChatSelectedPart(partId) {
		const part = this.props.quoteDetail.parts.find(part => part.id === partId)
		const openedChats = [...new Set([...this.state.openedChats, partId])]
		this.setState({
			...this.state,
			selectedChatPartInfo: part,
			openedChats,
		})
	}

	updateTranslatedParts(parts, quote) {
		const { t } = this.props
		const translatedParts = parts?.map(part => {
			const openChat = () => {
				const openedChats = [...new Set([...this.state.openedChats, part.id])]
				this.setState({
					...this.state,
					isOpen: true,
					selectedChatPartInfo: part,
					openedChats,
				})
			}
			const partData = {
				size: {
					...part.size,
					measure: 'mm',
				},
				weight: {
					value: part.weight,
					measure: 'g',
				},
			}
			const hasChats = part.hasMessages && !this.state.openedChats.includes(part.id)
			const hasUnReadedMessages = part.hasUnreadedMessages && !this.state.openedChats.includes(part.id)
			const blurePrintToShow = part.files.modifiedBlueprint ? part.files.modifiedBlueprint : part.files.blueprint
			const partConfigOptions = this.props.partConfigOptions
			const language = this.props.i18n.language
			const newPart = {
				...part,
				idToRender: <div className="d-flex flex-row justify-content-center align-items-center">
					<span>{part.id}</span>
					{
						part.similarParts.length > 0 && (
							<SimilarPartsWarning
								id={part.id}
								isQuoted={[QUOTE_STATUS_QUOTED, QUOTE_STATUS_REJECTED].includes(quote.statusId)}
								readSimilarity={() => { this.handleClickSimilarity(part.id) }} />)
					}
				</div>,
				status: part.quoteStatusId,
				construction: {
					value: part.constructionTypeId,
					text: t(`construction_type_${part.constructionTypeId}`, { ns: 'construction' }),
				},
				quoteInfo: {
					quoteId: part.quoteId,
					quotePpm: part.ppm,
					quoteMpm: part.mpm,
					quantities: part.costsAndMargins.map(quantity => {
						return {
							id: quantity.id,
							quantity: quantity.quantity,
							unitPrice: quantity.quotation,
							manufacturingTime: quantity.time,
							moldPrice: quantity.moldPrice,
							masterPrice: quantity.modelPrice,
						}
					}),

				},
				name: <PartName name={part.name} />,
				materialId: part.configuration.materialId,
				partInfo: <PartInfo data={partData} partId={part.id} partName={part.name} imageUrl={part.imageUrl} stlUrl={part.fileLinks.stlModel} />,
				fileInfo: <BlueprintAndStp propsBlueprintUrl={blurePrintToShow} propsModel3dUrl={part.files.model3d} />,
				material: [
					PartConfigTranslations.getMaterialName({ part, partConfigOptions, language }) + ' ' + (PartConfigTranslations.getAlloyName({ part, partConfigOptions, language })),
					PartConfigTranslations.getHardnessName({ part, partConfigOptions, language }),
				],
				technologyId: part.configuration.technologyTypeId,
				technology: (
					<div className={getClassnameByTechnology({ part })}>
						{PartConfigTranslations.getTechnologyName({ part, partConfigOptions, language })}
					</div>
				),
				finishing: [
					PartConfigTranslations.getFinishName({ part, partConfigOptions, language }),
					PartConfigTranslations.getPaletteName({ part, partConfigOptions, language }) + ' ' + PartConfigTranslations.getColorName({ part, partConfigOptions, language }),
					PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language }),
				],
				chat: <ChatIconIcon quantityUnread={hasUnReadedMessages ? '+' : undefined} onClick={openChat} hasChats={hasChats} />,
			}
			return newPart
		})
		this.setState({
			...this.state,
			translatedParts,
			isTranslating: false,
			language: this.props.i18n.language,
		})
	}
	updateQuantities(id, value, quantities) {
		const quotesState = this.state.quotesState.map(quoteState => quoteState)
		const quote = quotesState.find(quoteState => quoteState && quoteState.id === id)
		if (quote) {
			quote.areAllQuantitiesFullfilled = value
			quote.quantities = quantities
		}
		this.setState({
			...this.state,
			quotesState,
		})
	}

	updateSaveAndCorfirmQuoteButton() {
		let enableButton = true
		let someQuoteIsPending = false
		if (this.state.quotesState != null) {
			this.state.quotesState.forEach(quoteState => {
				if (quoteState.statusId === QUOTE_STATUS_REQUESTED) {
					someQuoteIsPending = true
					if (quoteState.areAllQuantitiesFullfilled === false || quoteState.isPartSimilarityReaded === false) enableButton = false
				}
				if (quoteState.statusId === QUOTE_STATUS_REJECT_PENDING) {
					someQuoteIsPending = true
					if (quoteState.isPartSimilarityReaded === false) enableButton = false
				}
			})
		} else {
			enableButton = false
		}
		this.setState((prev) => {
			return {
				...prev,
				isSaveAndConfirmQuoteEnabled: enableButton && someQuoteIsPending,
				isSaveQuoteEnabled: enableButton && someQuoteIsPending,
			}
		})
	}

	updateContruction(id, construction) {
		const quotesState = this.state.quotesState.map(quoteState => quoteState)

		const quote = quotesState.find(quoteState => quoteState.id === id)
		if (quote) {
			quote.construction = construction.value
		}
		this.setState({
			...this.state,
			quotesState,
		})
	}

	updateStatus(id) {
		let quotesRejected = [...this.state.quotesRejected]
		let quotesAbleToModify = [...this.state.quotesAbleToModify]
		const quotesState = [...this.state.quotesState]

		const quote = quotesState.find(quoteState => quoteState.id === id)
		if (quote) {
			quote.statusId = quote.statusId === QUOTE_STATUS_REJECT_PENDING ? QUOTE_STATUS_REQUESTED : QUOTE_STATUS_REJECT_PENDING
		}
		const quoteRejected = quotesRejected.find(quoteId => quoteId === id)
		if (!quoteRejected && quote && quote.statusId !== QUOTE_STATUS_REQUESTED) {
			quotesRejected.push(id)

			if (quote.statusId !== QUOTE_STATUS_REJECT_PENDING) quotesAbleToModify = quotesAbleToModify.filter(value => value != id)
		}

		if (quoteRejected && quote && quote.statusId === QUOTE_STATUS_REQUESTED) {
			quotesAbleToModify.push(id)
			quotesRejected = quotesRejected.filter(value => value != id)
		}

		this.setState({
			...this.state,
			quotesState,
			quotesRejected,
			quotesAbleToModify,
		})
	}

	getSupplierId() {
		if (this.checkRole(USER_ROLE_SUPPLIER, this.props.userRoles)) return this.props.accountId
		return this.props.supplierIdView || getVisualizingSupplierIdFromLocalStorage()
	}

	componentDidMount() {
		this.props.getSuppliers()
		if (this.props.supplierIdView) {
			addVisualizingSupplierIdFromLocalStorage(this.props.supplierIdView)
		}
		this.setState({
			...this.state,
			language: this.props.i18n.language,
		})

		const offerId = GetOfferIdFromURL()
		if (!this.checkRole(USER_ROLE_SUPPLIER, this.props.userRoles)) {
			this.props.getQuoteDetail(offerId, this.props.supplierIdView || getVisualizingSupplierIdFromLocalStorage())
		} else {
			this.props.getQuoteDetail(offerId, this.props.accountId)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const notFound = 'Not Found'
		if (this.props.i18n.language !== this.state.language) {
			location.reload()
			this.setState({
				...this.state,
				language: this.props.i18n.language,
			})
		}

		if (prevProps.quoteDetail !== this.props.quoteDetail || prevProps.quoteChats !== this.props.quoteChats || prevProps.partConfigOptions !== this.props.partConfigOptions) {
			const { quote, parts } = this.props.quoteDetail
			const { quoteChats } = this.props.quoteChats
			quote && quoteChats?.length != 0 && this.setState({ ...this.state, isTranslating: true }, () => this.updateTranslatedParts(parts, quote))
			this.getZipFiles()
		}
		if (prevState.quotesState !== this.state.quotesState) {
			this.updateSaveAndCorfirmQuoteButton()
		}
		if (prevProps.errorCloud !== this.props.errorCloud) {
			if (this.props.errorCloud === notFound) this.setMessageAndOpenModal(this.props.t('noFilesFound', { ns: 'errors' }))
		}

		if (prevProps.isQuoteUpdatedSuccessfully !== this.props.isQuoteUpdatedSuccessfully) {
			const message = this.getQuoteUpdateResponeMessage()
			this.setMessageAndOpenModal(message)
		}
		if (prevProps.quoteDetail !== this.props.quoteDetail && this.props.quoteDetail != null) {
			const { quote, parts } = this.props.quoteDetail
			if (quote && parts) {
				this.initializeRejectedAndAbleToModifyParts(parts, quote.statusId)
			}
		}
	}

	setMessageAndOpenModal(message) {
		this.setState({
			...this.state,
			message,
			isOpenModalMessage: true,
		})
	}

	getQuoteUpdateResponeMessage() {
		if (this.props.isQuoteUpdatedSuccessfully === true) {
			if (this.props.mailError === true) {
				return this.props.t('mailerror', { ns: 'errors' })
			}
			return this.props.t('quoteUpdatedSuccessfully', { ns: 'errors' })
		}
		return this.props.t('quoteUpdatedFailed', { ns: 'errors' })
	}

	getQuotesPartsIds() {
		if (this.props.quoteDetail && this.props.quoteDetail.quote && this.props.quoteDetail.parts.length !== 0) {
			return this.props.quoteDetail.parts.map(part => part.id)
		}
	}

	checkAllQuantitiesFullfilled(quantities) {
		let areAllQuantitiesFullfilled = true
		quantities.forEach(quantity => {
			areAllQuantitiesFullfilled = areAllQuantitiesFullfilled &&
				!!quantity.quotation
			quantity.quotation > 0 &&
				!!quantity.time &&
				quantity.time > 0
		})
		return areAllQuantitiesFullfilled
	}

	initializeRejectedAndAbleToModifyParts(parts, statusId) {
		const quotesRejected = []
		const quotesAbleToModify = []
		if (parts) {
			parts?.forEach(part => {
				switch (part.quoteStatusId) {
					case QUOTE_STATUS_REJECTED:
					case QUOTE_STATUS_REJECT_PENDING:
						quotesRejected.push(part.id)
						break
				}
				switch (part.quoteStatusId) {
					case QUOTE_STATUS_REQUESTED:
					case QUOTE_STATUS_REJECT_PENDING:
						quotesAbleToModify.push(part.id)
						break
				}
			})
			this.setState({
				...this.state,
				quotesState: parts?.map(part => {
					return {
						statusId: part.quoteStatusId,
						id: part.id,
						quoteId: part.quoteId,
						material: part.materialId,
						isPartSimilarityReaded: part.similarParts.length === 0,
						//quotePpm: part.quoteInfo.quotePpm,
						construction: part.constructionTypeId,
						technologyId: part.configuration.technologyTypeId,
						processId: part.process,
						quantities: part.costsAndMargins.map(quantity => {
							if (quantity.quotation == null) {
								quantity.unitPrice = ''
							} else {
								quantity.unitPrice = roundAtDecimals(quantity.quotation, 2)
							}
							if (quantity.time == null) {
								quantity.manufacturingTime = ''
							} else {
								quantity.manufacturingTime = quantity.time.toString()
							}
							return {
								...quantity,
							}
						}),
						areAllQuantitiesFullfilled: this.checkAllQuantitiesFullfilled(part.costsAndMargins),
					}
				}),
				quotesRejected,
				quotesAbleToModify,
				statusId,
			})

		}
	}

	closeModal() {
		this.setState({
			...this.state,
			isOpenModalMessage: false,
		})
		if (this.props.isQuoteUpdatedSuccessfully) {
			location.reload()
		}
	}

	enableSaveButtonForVC(id, enable, values, ppmValue, mpmValue) {
		const qs = this.state.quotesState.map(quote => {
			if (id == quote.id) {
				return {
					...quote,
					ppm: ppmValue,
					mpm: mpmValue,
					areAllQuantitiesFullfilled: true,
					quantities: values,
				}
			} else {
				return quote
			}
		})

		if (enable) {
			this.updateSaveAndCorfirmQuoteButton()
		}
		this.setState({
			...this.state,
			quotesState: qs,
		})
	}

	handleSaveQuote(quotes) {
		const mappedQuotes = this.mapQuotes(quotes)
		const input = this.formatInputDTO(mappedQuotes)
		this.props.updateQuoteQuantities(input)
	}

	handleSaveAndConfirmQuote(quotes) {
		const mappedQuotes = this.mapQuotesConfirm(quotes)
		const input = this.formatInputDTO(mappedQuotes)
		this.props.saveAndConfirmQuote(input)
	}

	mapQuantity(quantity) {
		return quantity.map(quoteQuantity => {
			return {
				quantity: quoteQuantity.quantity,
				quotation: parseFloat(quoteQuantity.unitPrice),
				time: parseInt(quoteQuantity.manufacturingTime),
			}
		})
	}

	formatInputDTO(quoteQuantities, partIds) {
		return {
			data: {
				quotes: quoteQuantities,
			},
		}
	}
	filterAlreadyQuotedParts(quotes) {
		return quotes.filter(quote => ![QUOTE_STATUS_QUOTED, QUOTE_STATUS_REJECTED].includes(quote.statusId))

	}

	mapQuotes(quotes) {
		return this.filterAlreadyQuotedParts(quotes).map(quote => {
			return this.parseQuote(quote, quote.statusId)
		})
	}
	mapQuotesConfirm(quotes) {
		return this.filterAlreadyQuotedParts(quotes).map(quote => {
			const statusConfirmed = quote.statusId === QUOTE_STATUS_REQUESTED ? QUOTE_STATUS_QUOTED : QUOTE_STATUS_REJECTED
			return this.parseQuote(quote, statusConfirmed)
		})
	}

	parseQuote(quote, status) {
		return {
			partId: quote.id,
			quoteId: quote.quoteId,
			statusId: status,
			quantities: this.isQuoteVc(quote.technologyId) ? this.mapQuantityVC(quote.quantities) : this.mapQuantity(quote.quantities),
			constructionTypeId: quote.construction,
			processId: quote.processId,
			technologyId: quote.technologyId,
			ppm: quote.ppm,
			mpm: quote.mpm,
		}
	}

	isQuoteVc(technologyId) {
		return technologyId === VACCUM_CASTING_ID
	}

	mapQuantityVC(quantity) {
		return quantity.map(quoteQuantity => {
			return {
				quantity: quoteQuantity.quantity,
				quotation: parseFloat(quoteQuantity.unitPrice),
				time: parseInt(quoteQuantity.manufacturingTime),
				moldPrice: parseFloat(quoteQuantity.moldsUnitPrice),
				modelPrice: parseFloat(quoteQuantity.mastUnitPrice),
			}
		})
	}

	toggle() {
		this.setState({
			...this.state,
			modal: !this.state.modal,
		})
	}

	IsLoadingOrUpdating() {
		return this.props.isLoading || this.props.isUpdating || this.props.partConfigOptionsLoading
	}

	handleOnChange(e) {
		e.preventDefault()
		this.setState({
			...this.state,
			inputText: e.target.value,
		})
	}

	handleOnChangeFile(uploadedImages) {
		this.setState({
			...this.state,
			chatImagesToUpload: uploadedImages,
		})
	}

	handleMessageId(id) {
		this.setState({
			...this.state,
			lastMessageId: id,
		})
	}

	getZip(quoteId) {
		if (!this.checkRole(USER_ROLE_SUPPLIER)) {
			this.props.getSupplierQuotationFilesByOfferId(quoteId, getVisualizingSupplierIdFromLocalStorage())
		} else {
			const id = getUserValuesFromLocalStorage().id
			this.props.getSupplierQuotationFilesByOfferId(quoteId, id)
		}
	}
	getZipFiles() {
		if (this.props.quoteDetail == null || this.props.quoteDetail.parts == null) return
		const { quote, parts } = this.props.quoteDetail
		const csvItems = []
		const files = []
		const { i18n: { language }, partConfigOptions } = this.props
		for (const part of parts) {
			const blurePrintToShow = part.files.modifiedBlueprint ? part.files.modifiedBlueprint : part.files.blueprint
			const stepModel = part.files.model3d
			if (stepModel != null) {
				files.push({
					file: stepModel,
					name: getS3FileName(stepModel),
				})
			}
			if (blurePrintToShow != null) {
				files.push({
					file: blurePrintToShow,
					name: getS3FileName(blurePrintToShow),
				})
			}
			const quantities = []
			for (const quantity of part.costsAndMargins) {
				quantities.push(quantity.quantity)
			}
			csvItems.push({
				partId: part.id,
				name: part.name,
				step: getS3FileName(stepModel),
				blueprint: getS3FileName(blurePrintToShow),
				technology: PartConfigTranslations.getTechnologyName({ part, partConfigOptions, language }),
				material: PartConfigTranslations.getMaterialName({ part, partConfigOptions, language }),
				alloy: PartConfigTranslations.getAlloyName({ part, partConfigOptions, language }),
				hardness: PartConfigTranslations.getHardnessName({ part, partConfigOptions, language }),
				finishing: PartConfigTranslations.getFinishName({ part, partConfigOptions, language }),
				color: PartConfigTranslations.getPaletteName({ part, partConfigOptions, language }) + ' ' + PartConfigTranslations.getColorName({ part, partConfigOptions, language }),
				colorFinishing: PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language }),

				quantities: quantities.join(', '),
			})
		}
		const csvFile = getCSVFile(csvItems)
		const csvFileName = `parts_${quote.id}.csv`
		files.push({
			file: csvFile,
			name: csvFileName,
		})
		this.setState({
			...this.state,
			files,
		})
	}

	handleClickSimilarity(partId) {
		this.setState({
			...this.state,
			isOpenSimilarityModal: true,
			currentSimilarPartIds: (this.props.quoteDetail.parts.find(part => part.id === partId).similarParts).map(el => parseInt(el)),
			quotesState: this.state.quotesState.map(quote => {
				if (quote.id === partId) return { ...quote, isPartSimilarityReaded: true }
				return quote
			}),
		})
	}

	handleOnCloseSimilarityModal() {
		this.setState({
			...this.state,
			isOpenSimilarityModal: false,
		})
	}

	render() {
		const { quote } = this.props.quoteDetail
		const header = {
			idToRender: 'id',
			partInfo: 'partInfo',
			name: 'partName',
			fileInfo: 'fileInfo',
			technology: 'technology',
			material: 'material',
			finishing: 'finishing',
			construction: 'construction',
			quoteInfo: 'quoteInfo',
			reject: 'reject',
			chat: 'chat',

		}
		if (header != null) header.chat = 'chat'
		const { t } = this.props
		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>Proto&Go! | {t('quoteDetail', { ns: 'naming' })}</title>
					</MetaTags>
					<Breadcrumbs
						title={t('suppliers', { ns: 'naming' })}
						breadcrumbItems={[
							{
								item: t('quoteList', { ns: 'naming' }),
								link: '/quote-list',
							},
							{
								item: t('quoteDetail', { ns: 'naming' }),
								link: '/quote/' + GetOfferIdFromURL(),
							},
						]}
					/>
					{this.IsLoadingOrUpdating() && <Loader className="font-size-80 mx-auto" />}
					{!this.props.isLoading && this.props.error && (
						<Alert type="danger" centered fitContent message={JSON.stringify(this.props.error)} />
					)}
					{!this.IsLoadingOrUpdating() && quote && (
						<div>
							<div className="container-fluid">
								<Row>
									<Col className="col-12 bg-white p-3 m-2">
										<table className="w-100">
											<tr>
												<th>{t('id', { ns: 'naming' })}</th>
												<th>{t('status', { ns: 'naming' })}</th>
												<th>{t('date', { ns: 'naming' })}</th>
												<th>{t('comment', { ns: 'naming' })}</th>
												<th className="float-right me-2">{t('actions', { ns: 'naming' })}</th>
											</tr>
											<tr>
												<td>{quote.id}</td>
												<td>
													<span className={getStatusClassName(`quote_status_${quote.statusId}`)}>{t(`quote_status_${quote.statusId}`, { ns: 'status' })}</span>
													{
														quote.isUrgent && <span className="rounded-pill bg-danger text-white ms-2">{t('urgent', { ns: 'naming' })}</span>
													}
												</td>
												<td>
													{adaptWeekday(new Date(quote.creationDate).getDay(), this.props.i18n.language) + ' '}
													{getDate(
														quote.creationDate,
													) + ' '}
													{adaptTimerString(new Date(quote.creationDate).getHours(), new Date(quote.creationDate).getMinutes(), new Date(quote.creationDate).getSeconds())}
												</td>
												<td> {quote.comment}</td>
												<td>
													<div className=" mt-1 d-flex flex-row justify-content-end align-items-center">
														<ZipDownloader files={this.state.files || []} zipName={quote.id} />
														<button
															disabled={!this.state.isSaveQuoteEnabled}
															className="btn btn-info d-flex flex-row mx-1"
															onClick={() => this.handleSaveQuote(this.state.quotesState)}
														>
															<i className="bx bxs-save font-size-20 align-middle" />
															{t('save', { ns: 'naming' })}
														</button>
														<button
															disabled={!this.state.isSaveAndConfirmQuoteEnabled}
															className=" btn btn-success d-flex flex-row mx-1 w-auto"
															onClick={() => this.handleSaveAndConfirmQuote(this.state.quotesState)}
														>
															<i className="bx bx-check font-size-20 align-middle" />
															{t('saveAndConfirm', { ns: 'naming' })}
														</button>
														<Modal isOpen={this.state.modal} toggle={() => this.toggle()}>
															<ModalHeader toggle={this.toggle}>{t('confirm', { ns: 'naming' })}</ModalHeader>
															<ModalBody>{t('finalPriceMessage', { ns: 'naming' })}</ModalBody>
															<ModalFooter>
																<button color="primary" onClick={() => this.handleSaveAndConfirmQuote(this.state.quotesState)}>
																	Aceptar
																</button>{' '}
															</ModalFooter>
														</Modal>
													</div>
												</td>
											</tr>
										</table>
									</Col>
								</Row>
							</div>
							<div className="col-12 bg-white m-2 p-3">
								<Table
									enableSaveButtonForVC={this.enableSaveButtonForVC}
									paginationProps={{
										custom: true,
										sizePerPage: 200,
									}}
									selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
									header={header}
									items={this.state.translatedParts}
									disableInteractions
									highlightedRows={this.state.quotesRejected}
									highlightStyle="bg-danger"
									disableStriped
									highlightTextStyle="dangerRowText"
									rowsAbleToModify={this.state.quotesAbleToModify}
									language="es"
									rowQuantityList={[{ text: '32', value: 32 }]}
									allowAllItemsPerPage
									defaultSort={{
										dataField: 'id',
										order: 'desc',
									}}
									rowActions={[
										{
											label: 'reject',
											action: id => this.updateStatus(id),
											tooltipMessage: 'Decline',
											declinedTooltipMessage: 'Readmit',
										},
										{
											label: 'quoteInfo',
											checkFields: (id, value, quantities) => this.updateQuantities(id, value, quantities),
										},
										{
											label: 'construction',
											onChange: (id, construction) => this.updateContruction(id, construction),
										},
									]}
									styleForFieldList={[
										{
											field: 'clickableImage',
											styles: [
												{
													type: 'clickableImage',
													class: 'tableImage',
												},
											],
										},
										{
											field: 'size',
											styles: [
												{
													type: 'volumetricMeasure',
													class: '',
												},
											],
										},
										{
											field: 'weight',
											styles: [
												{
													type: 'measure',
													class: '',
												},
											],
										},

										{
											field: 'file',
											styles: [
												{
													type: 'file',
													class: '',
												},
											],
										},
										{
											field: 'reject',
											styles: [
												{
													type: 'reject',
													class: 'fs-2 bx bxs-like bx-flip-vertical',
													highlightClass: 'fs-2 bx bx-rotate-left',
												},
											],
										},
										{
											field: 'construction',
											styles: [
												{
													type: 'dropdown',
												},
											],
										},
										{
											field: 'quoteInfo',
											styles: [
												{
													type: 'quoteInfo',
												},
											],
										},
										{
											field: 'finishing',
											styles: [
												{
													type: 'list',
													class: 'd-block',
												},
											],
										},
										{
											field: 'material',
											styles: [
												{
													type: 'list',
													class: 'd-block',
												},
											],
										},
									]}
								/>

							</div>
						</div>
					)}
				</div>
				<SimilarPartsModal
					handleOnCloseModal={this.handleOnCloseSimilarityModal}
					partIds={this.state.currentSimilarPartIds}
					isOpen={this.state.isOpenSimilarityModal}
					supplierId={this.getSupplierId()}
				/>
				<ModalMessage isModalOpen={this.state.isOpenModalMessage} closeModal={() => this.closeModal()} message={this.state.message} />
				<CustomModal
					isOpen={this.state.isOpen}
					closeModal={() => this.toggleChat()}
					closeButtonColor="secondary"
					title="Chat"
					size="lg"
					className="hugeModal"
					body={
						<QuoteChat
							parts={this.props.quoteDetail.parts}
							supplierId={this.getSupplierId()}
							handleOnChangeSelectedPart={this.handleOnChangeChatSelectedPart}
							selectedPart={this.state.selectedChatPartInfo}
							openedChats={this.state.openedChats}
							partConfigOptions={this.props.partConfigOptions}
							i18n={this.props.i18n}
						/>
					}
				/>

			</React.Fragment>
		)
	}
}


const mapStateToProps = state => {
	return {
		quoteDetail: state.Quotes.quoteDetail,
		isLoading: state.Quotes.isLoading,
		isUpdating: state.Quotes.isUpdating,
		isQuoteUpdatedSuccessfully: state.Quotes.isQuoteUpdatedSuccessfully,
		supplierIdView: state.Quotes.supplierIdView,
		error: state.Quotes.error,
		quoteChat: state.Quotes.quoteChat,
		quoteChats: state.Quotes.quoteChats,
		suppliers: state.Users.suppliers,
		errorCloud: state.CloudStorage.error,
		images: state.CloudStorage.images,
		chatImages: state.CloudStorage.chatImages,
		partsBlueprintsAndStp: state.CloudStorage.partsBlueprintsAndStp,
		mailError: state.Quotes.mailError,
		userRoles: state.Login.roles,
		accountId: state.Login.id,
		partsFiles: state.CloudStorage.partsFiles,
		partConfigOptionsLoading: state.Parts.partConfigOptionsLoading,
	}
}

Quote.propTypes = {
	quoteDetail: PropTypes.object,
	isLoading: PropTypes.bool,
	isUpdating: PropTypes.bool,
	getQuoteDetail: PropTypes.func,
	updateQuoteQuantities: PropTypes.func,
	saveAndConfirmQuote: PropTypes.func,
	error: PropTypes.any,
	errorCloud: PropTypes.any,
	t: PropTypes.func,
	i18n: PropTypes.object,
	history: PropTypes.object,
	mailError: PropTypes.bool,
	getPartListImages: PropTypes.func,
	images: PropTypes.any,
	partsBlueprintsAndStp: PropTypes.any,
	supplierIdView: PropTypes.number,
	getQuoteChat: PropTypes.func,
	quoteChat: PropTypes.array,
	quoteChats: PropTypes.array,
	postQuoteChat: PropTypes.func,
	getSuppliers: PropTypes.func,
	suppliers: PropTypes.array,
	getSupplierQuotationFilesByOfferId: PropTypes.func,
	getPartsFiles: PropTypes.func,
	getPartListBlueprintsAndStp: PropTypes.func,
	isQuoteUpdatedSuccessfully: PropTypes.bool,
	language: PropTypes.string,
	userRoles: PropTypes.arrayOf(PropTypes.string),
	accountId: PropTypes.number,
	getQuoteChatImages: PropTypes.func,
	uploadQuoteChatImage: PropTypes.func,
	chatImages: PropTypes.array,
	files: PropTypes.any,
	markQuoteMessagesAsRead: PropTypes.func,
	partsFiles: PropTypes.any,
	partConfigOptionsLoading: PropTypes.bool,
}

export default withGetPartConfigOptions(connect(mapStateToProps, {
	getQuoteDetail,
	updateQuoteQuantities,
	getSupplierQuotationFilesByOfferId,
	getPartsFiles,
	saveAndConfirmQuote,
	postQuoteChat,
	getQuoteChat,
	getQuoteChatImages,
	uploadQuoteChatImage,
	getSuppliers,
	getPartListImages,
	markQuoteMessagesAsRead,
})(withTranslation()(Quote)))
