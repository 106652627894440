export const variablesPriceFormula = [
	{
		A: 5.1569,
		B: 0.2148,
		C: 0.0631,
		D: -0.0166,
	},
	{
		A: -60.3382,
		B: 0.2416,
		C: 0.0556,
		D: -0.0152,
	},
	{
		A: -402.9957,
		B: -0.4871,
		C: 0.0392,
		D: 0.1693,
	},
]

export const variablesPriceMultipliers = [0.51, 0.44, 0.3]
