import ApexRadial from 'components/Common/Charts/ApexRadial'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const LeadScoring = ({ t }) => {
	return (
		<div className="bg-white w-100 m-0 p-3">
			<div className="d-flex justify-content-between mx-2 border-bottom border-light align-items-center pb-2">
				<h6>{t('Lead scoring', { ns: 'naming' })}</h6>
				<button
					type="button"
					className="btn btn-outline-secondary btn-sm"
				>
					{t('stats', { ns: 'naming' })}
				</button>
			</div>
			<ApexRadial series={25} />

		</div>
	)
}
LeadScoring.propTypes = {
	value: PropTypes.number,
	openScoreModal: PropTypes.func,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LeadScoring))
