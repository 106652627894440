import PropTypes from 'prop-types'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

export const SimilarPartsWarning = ({ isQuoted, readSimilarity, t, id }) => {

	const [readed, setReaded] = useState(false)

	const handleOnClick = () => {
		setReaded(true)
		readSimilarity()
	}

	const textColor = isQuoted ? 'text-secondary' : readed ? 'text-success' : 'text-primary'
	return (
		<div key={`similarity_key_${id}`}>
			<span className="d-flex align-items-center" data-tip={t('similar-parts', { ns: 'naming' })} data-for={'similarPartsTooltip'}>
				<i className={`bx bxs-error-alt ms-1 cursor-pointer ${textColor}`} onClick={() => handleOnClick()}></i>
				<ReactTooltip id={'similarPartsTooltip'} place="top" type="info" effect="solid" />
			</span>
		</div>
	)
}

SimilarPartsWarning.propTypes = {

	isQuoted: PropTypes.bool,
	readSimilarity: PropTypes.func,
	t: PropTypes.func,
	id: PropTypes.number,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SimilarPartsWarning))
