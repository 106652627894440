import { usePdfGenerator } from 'common/Hooks/UsePdfGenerator'
import { mapPriceToString } from 'common/Utils/mapPriceToString'
import { roundNumber } from 'common/Utils/roundNumber'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { PartName } from 'components/Common/PartName'
import { generateZipFile } from 'components/Common/ZipDownloader'
import { CustomsPDF } from 'components/PdfTemplates/CustomsPDF'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { downloadPartListImagesInBase64, getCompleteProductionFiles, getProductionsByTracking } from 'store/actions'

const ImportsCustomsModal = (props) => {

	const {
		t,
		isOpen,
		closeModal,
		trackingNumber,
		suppliers,
		downloadPartListImagesInBase64,
		imagesInBase64,
		getProductionsByTracking,
		productionsByTracking,
		getCompleteProductionFiles,
		isLoading,
	} = props


	const [modifiedTotal, setModifiedTotal] = useState(0)
	const [priceMultiplier, setPriceMultiplier] = useState(0)
	const [shipmentImportTotal, setShipmentImportTotal] = useState(0)
	const [productions, setProductions] = useState([])
	const [rawPdfs, setRawPdfs] = useState([])
	const [generatingPdf, setGeneratingPdf] = useState(false)

	useEffect(() => {
		if (trackingNumber) {
			getProductionsByTracking({ tracking: trackingNumber })
		}
	}, [trackingNumber])

	useEffect(() => {
		if (!isOpen) setModifiedTotal(null)
	}, [isOpen])

	useEffect(() => {
		if (productions.length > 0) {
			const parts = productions.flatMap(production => production.productionItemList.map(productionItem => productionItem.part))
			downloadPartListImagesInBase64(parts)
			getCompleteProductionFiles({
				data: {
					productionIds: productions.map(x => ({
						orderId: x.orderId,
						orderFragmentId: x.orderFragmentId,
					})),
				},
			})
		}
	}, [productions])


	useEffect(() => {
		if (productionsByTracking.length !== 0) {

			let shipmentImportTotal = 0
			const productions = productionsByTracking.map(production => {
				let productionTotal = 0
				const productionItemList = []
				production.productionItemList.forEach(productionItem => {
					const productionItemTotal = roundNumber(productionItem.unitPurchasePrice * productionItem.shippedQuantity)
					productionTotal += productionItemTotal
					productionItemList.push({
						...productionItem,
						productionItemTotal,
					})
				})
				shipmentImportTotal += productionTotal
				return {
					...production,
					productionTotal,
					productionItemList,
				}
			})
			const declaredValues = productions.map(production => production.declaredValue)
			const maxDeclaredValue = Math.max(...declaredValues) || 0
			setModifiedTotal(maxDeclaredValue)
			setShipmentImportTotal(shipmentImportTotal)
			setProductions(productions)
			setGeneratingPdf(false)
		}
	}, [productionsByTracking])

	useEffect(() => {
		const orderIds = [...new Set(productions.map(production => production.orderId))]
		const newRawPdfs = []
		orderIds.forEach(orderId => {
			const productionsOfOrder = productions.filter(production => production.orderId == orderId)
			const orderItems = productionsOfOrder.flatMap(productionOfOrder => productionOfOrder.productionItemList)
			const orderItemsModified = orderItems.map(orderItem => {
				const modifiedUnitPurchasePrice = (orderItem.unitPurchasePrice * priceMultiplier) || 0.01
				const modifiedTotalPurchasePrice = (modifiedUnitPurchasePrice * orderItem.shippedQuantity) || 0.01
				return {
					...orderItem,
					part: {
						...orderItem.part,
						imageBase64: imagesInBase64.find(image => image.partId == orderItem.part.id)?.imageBase64 || '',
					},
					unitPurchasePrice: modifiedUnitPurchasePrice,
					totalPruchasePrice: modifiedTotalPurchasePrice,
				}
			})
			const modifiedPartsPricing = orderItemsModified.reduce((prev, orderItem) => prev + orderItem.unitPurchasePrice * orderItem.shippedQuantity, 0)
			const modifiedShipmentPricing = roundNumber(productionsOfOrder.reduce((prev, curr) => prev + curr.shipmentPrice, 0))
			const modifiedTotal = modifiedPartsPricing + modifiedShipmentPricing
			const order = {
				id: orderId,
				partsPricing: modifiedPartsPricing,
				shipmentPricing: modifiedShipmentPricing,
				totalPrice: modifiedTotal,
				creationDate: productionsOfOrder[0].order.creationDate,
				realShipmentDate: productionsOfOrder[0].shipmentDateReal,
				estimatedShipmentDate: productionsOfOrder[0].shipmentDateEstimate,
				orderItems: orderItemsModified,
				supplier: suppliers.find(supplier => supplier.account.id == productionsOfOrder[0].supplierId),
			}
			newRawPdfs.push({
				filename: `Customs-${orderId}.pdf`,
				content: CustomsPDF({ order, t }),
			})
		})
		setRawPdfs(newRawPdfs)
	}, [productions, priceMultiplier, imagesInBase64])

	useEffect(() => {
		setPriceMultiplier(modifiedTotal / shipmentImportTotal)
	}, [modifiedTotal, shipmentImportTotal])

	const {
		elements: customsPDFElements,
		getPdfFiles,
	} = usePdfGenerator({
		contentList: rawPdfs,
	})

	const [thisProductionFiles, setThisProductionFiles] = useState(null)
	const [pdfFiles, setPdfFiles] = useState(null)
	useEffect(() => {
		if (props.productionFiles != null) {
			setThisProductionFiles(props.productionFiles)
		}
	}, [props.productionFiles])

	const [clickedOnDownload, setClickedOnDownload] = useState(false)
	useEffect(() => {
		setClickedOnDownload(false)
	}, [props.isOpen])

	useEffect(() => {
		if (!clickedOnDownload) return
		const downloadFiles = async () => {
			if (thisProductionFiles == null || pdfFiles == null) return
			const newZipFiles = []
			thisProductionFiles?.forEach(production => {
				const folderName = `${t('order')}_${production.orderId}`
				if (production.fileUrls?.invoice) {
					newZipFiles.push({
						name: `${folderName}/${production.fileUrls.invoice.name}`,
						file: production.fileUrls.invoice.src,
					})
				}
				if (production.fileUrls?.photos) {
					production.fileUrls?.photos.forEach(photo => {
						newZipFiles.push({
							name: `${folderName}/${photo.name}`,
							file: photo.src,
						})
					})
				}
			})
			pdfFiles.forEach(({ file, filename }) => {
				const orderId = filename.split('-')[1].split('.')[0]
				const folderName = `${t('order')}_${orderId}`
				newZipFiles.push({
					name: `${folderName}/${filename}`,
					file: file,
				})
			})
			generateZipFile(`${t('shipping')}_${trackingNumber}`, newZipFiles)
			setClickedOnDownload(false)
		}
		downloadFiles()
	}, [thisProductionFiles, pdfFiles])

	return (
		<Modal
			title={t('Indicates_the_declared_value_on_the_invoice')}
			size="xl"
			closeButtonText={t('close')}
			closeButtonColor="secondary"
			isOpen={isOpen}
			closeModal={closeModal}
			body={
				(<>
					{isLoading ?
						<div className='p-4 d-flex justify-content-center fs-4'>
							<Loader />
						</div>
						: (
							<>
								<div className="d-flex justify-content-between align-items-start ms-4 me-4 mt-4 mb-3">
									<h4>
										<strong>
											{t('trackingNumber')}:{' '}
											<span style={{ color: '#0066b2' }}>
												{trackingNumber}
											</span>
										</strong>
									</h4>
									<h4 style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
										<strong>{'$'}{mapPriceToString(shipmentImportTotal)}</strong>
										<strong>
											<input
												style={{ width: '90%' }}
												disabled={true}
												value={`$${mapPriceToString(shipmentImportTotal * priceMultiplier)}`}
											/>
										</strong>
									</h4>
								</div>
								{productions?.sort(
									(a, b) => a.orderFragmentId - b.orderFragmentId,
								)?.map(
									(production, productionIndex) => (
										<React.Fragment key={`production-${productionIndex}`}>
											<div className="d-flex justify-content-between align-items-start ms-4 me-4 mb-4">
												<h5>
													<strong>
														{t('production')}:{' '}
														<Link to={`/production/${production?.displayId}`}>
															{production?.displayId}
														</Link>
													</strong>
												</h5>
												<h5 style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
													<strong>{'$'}{mapPriceToString(production?.productionTotal)}</strong>
													<strong>
														<input
															style={{ width: '90%' }}
															disabled={true}
															value={`$${mapPriceToString(production?.productionTotal * priceMultiplier)}`}
														/>
													</strong>
												</h5>
											</div>
											<table className="ms-4 me-4 mb-4" key={productionIndex}>
												<thead>
													<tr>
														<th>{t('item')}</th>
														<th></th>
														<th>{t('name')}</th>
														<th>{t('quantity')}</th>
														<th>{t('total_price')}</th>
														<th>{t('unit_price')}</th>
														<th>{t('total_price')}</th>
														<th>{t('unit_price')}</th>
													</tr>
												</thead>
												<tbody>
													{production?.productionItemList?.map((productionItem, itemIndex) => {
														return (
															`$${mapPriceToString(production.productionTotal)}`,
															<tr key={itemIndex} style={{ marginTop: '20px' }}>
																<td>{productionItem?.part?.id} ({itemIndex})</td>
																<td>
																	<img src={productionItem?.part?.imageUrl} style={{ width: '50px', height: '50px', objectFit: 'contain' }} />
																</td>
																<td style={{ width: '7rem' }}>
																	<PartName name={productionItem?.part?.name} maxLength={15} />
																</td>
																<td style={{ textAlign: 'end', paddingRight: '2rem' }}>{productionItem?.shippedQuantity}</td>
																<td>{'$'}{mapPriceToString(productionItem?.productionItemTotal)}</td>
																<td>{'$'}{mapPriceToString(productionItem?.unitPurchasePrice)}</td>
																<td>
																	<input
																		style={{ width: '90%' }}
																		disabled={true}
																		value={`$${mapPriceToString(productionItem.productionItemTotal * priceMultiplier)}`}
																	/>
																</td>
																<td>
																	<input
																		style={{ width: '90%' }}
																		disabled={true}
																		value={`$${mapPriceToString(productionItem.unitPurchasePrice * priceMultiplier)}`}
																	/>
																</td>
															</tr>
														)
													})}
												</tbody>
											</table>
											<div style={{ height: '1px', backgroundColor: '#ccc' }} className="mt-2 mb-4 ms-4 me-4" />
										</React.Fragment>
									))}
								<div className="d-flex justify-content-end align-items-center ms-4 me-4 mt-4 mb-4">
									<h5 className="d-flex align-items-center">
										<p className="mb-0 me-3">
											<strong>{t('totalPrice')}{' ($)'}</strong>
										</p>
										<input
											type="number"
											value={modifiedTotal}
											defaultValue={modifiedTotal}
											onChange={(e) => { setModifiedTotal(e.target.value) }}
										/>
									</h5>
								</div>
								{customsPDFElements}
							</>
						)}
				</>

				)}
			buttons={[
				<button
					key="1"
					className="btn btn-primary"
					disabled={isLoading || generatingPdf || thisProductionFiles == null || imagesInBase64.length == 0}
					onClick={() => {
						setClickedOnDownload(true)
						setGeneratingPdf(true)
						setPdfFiles(null)
						getPdfFiles().then(newPdfFiles => {
							setGeneratingPdf(false)
							setPdfFiles(newPdfFiles)
						})
					}}
				>
					{thisProductionFiles == null ? (
						<span>{t('downloadFiles')}...</span>
					) : generatingPdf ? (
						<span>{t('generate')}...</span>
					) : (
						<span>{t('save')}</span>
					)}
				</button>,
			]}
		/>
	)
}

ImportsCustomsModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	trackingNumber: PropTypes.string,
	onOk: PropTypes.func,
	productions: PropTypes.array,
	productionFiles: PropTypes.array,
	suppliers: PropTypes.array,
	downloadPartListImagesInBase64: PropTypes.func,
	imagesInBase64: PropTypes.array,
	getProductionsByTracking: PropTypes.func,
	productionsByTracking: PropTypes.array,
	getCompleteProductionFiles: PropTypes.func,
	isLoading: PropTypes.bool,
}

const mapStateToProps = state => {
	return {
		imagesInBase64: state.CloudStorage.imagesInBase64,
		productionsByTracking: state.Productions.productionsByTracking,
		isLoading: state.Productions.isLoading,

	}
}

export default connect(mapStateToProps, {
	downloadPartListImagesInBase64, getProductionsByTracking, getCompleteProductionFiles
})(ImportsCustomsModal)
