import {
	SEND_UPLOAD_OFFER_EMAIL,
	SEND_UPLOAD_OFFER_EMAIL_FAIL,
	SEND_UPLOAD_OFFER_EMAIL_SUCCESS,
} from './actionTypes'

export const sendUploadOfferEmail = ({ customer, language, message, offerId, manager }) => ({
	type: SEND_UPLOAD_OFFER_EMAIL,
	payload: { customer, language, message, offerId, manager },
})

export const sendUploadOfferEmailSuccess = success => ({
	type: SEND_UPLOAD_OFFER_EMAIL_SUCCESS,
	payload: success,
})

export const sendUploadOfferEmailFail = error => ({
	type: SEND_UPLOAD_OFFER_EMAIL_FAIL,
	payload: error,
})
