export default {
	ES: '#e81c1c',
	FR: '#1c67e8',
	IT: '#07fa10',
	PL: '#f3f5f2',
	DE: '#000000',
	MX: '#adff73',
	CH: '#ff7077',
	BE: '#e1f50c',
	NL: '#a8f0ef',
	GB: '#07078f',
	PT: '#2a5e29',
	IE: '#0cab07',
	GE: '#f0f0f0',
}
