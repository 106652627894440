export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const API_ERROR = 'LOGIN_API_ERROR'

export const VERIFY_USER_TOKEN = 'VERIFY_USER_TOKEN'
export const VERIFY_USER_TOKEN_SUCCESS = 'VERIFY_USER_TOKEN_SUCCESS'
export const VERIFY_USER_TOKEN_FAIL = 'VERIFY_USER_TOKEN_FAIL'
