import { call, put, takeEvery } from 'redux-saga/effects'

import {
	CREATE_CRM_INTERACTION_ENDPOINT,
	CREATE_NEW_CUSTOMER_ENDPOINT,
	CREATE_NEW_LEAD_ENDPOINT,
	CREATE_NEW_LOG_ENDPOINT,
	GET_CRM_INTERACTIONS_ENDPOINT,
	GET_CRM_USERS_DETAIL_ENDPOINT,
	GET_CRM_USERS_LIST_ENDPOINT,
	GET_LOG_IN_AS_TOKEN_ENDPOINT,
	UPDATE_CRM_USER_DETAIL_ENDPOINT,
	UPDATE_MANAGER_ENDPOINT,
	UPDATE_ORGANIZATION_COMMON_DATA_ENDPOINT,
	UPDATE_ORGANIZATION_DATA_ENDPOINT,
	UPDATE_PAYMENT_DATA_ENDPOINT,
	UPDATE_SHIPPING_ADDRESS_DATA_ENDPOINT,
	UPDATE_USER_DATA_ENDPOINT,
} from '../../constants/backendRoutes'
import { get, post } from '../../helpers/api_helper'
import {
	CREATE_NEW_CRM_LOG,
	CREATE_NEW_CUSTOMER,
	CREATE_NEW_LEAD,
	CREATE_NEW_LOG,
	GET_CRM_INTERACTIONS,
	GET_CRM_USERS,
	GET_CRM_USERS_DETAIL,
	GET_LOG_IN_AS_TOKEN,
	UPDATE_CRM_USER_DETAIL,
	UPDATE_MANAGER,
	UPDATE_ORGANIZATION_COMMON_DATA,
	UPDATE_ORGANIZATION_DATA,
	UPDATE_PAYMENT_DATA,
	UPDATE_SHIPPING_ADDRESS_DATA,
	UPDATE_USER_DATA,
} from './actionTypes'
import {
	createNewCrmLogFail,
	createNewCrmLogSuccess,
	createNewCustomerFail,
	createNewCustomerSuccess,
	createNewLeadFail,
	createNewLeadSuccess,
	createNewLogFail,
	createNewLogSuccess,
	getCrmInteractionsFail,
	getCrmInteractionsSuccess,
	getCrmUsersDetailFail,
	getCrmUsersDetailSuccess,
	getCrmUsersFail,
	getCrmUsersSuccess,
	getLogInAsTokenFail,
	getLogInAsTokenSuccess,
	updateCrmUserDetailFail,
	updateCrmUserDetailSuccess,
	updateManagerFail,
	updateManagerSuccess,
	updateOrganizationCommonDataFail,
	updateOrganizationCommonDataSuccess,
	updateOrganizationDataFail,
	updateOrganizationDataSuccess,
	updatePaymentDataFail,
	updatePaymentDataSuccess,
	updateShippingAddressDataFail,
	updateShippingAddressDataSuccess,
	updateUserDataFail,
	updateUserDataSuccess,
} from './actions'

function* getCrmUsers() {
	try {

		const url = GET_CRM_USERS_LIST_ENDPOINT
		const response = yield call(get, url)
		yield put(getCrmUsersSuccess(response))
	} catch (error) {
		yield put(getCrmUsersFail(error))
	}
}

function* getCrmUsersDetail({ payload: { id } }) {
	try {
		const url = `${GET_CRM_USERS_DETAIL_ENDPOINT}/${id}`
		const response = yield call(get, url)
		yield put(getCrmUsersDetailSuccess(response))
	} catch (error) {
		yield put(getCrmUsersDetailFail(error))
	}
}
function* updateCrmUserDetail({ payload: user }) {
	const url = UPDATE_CRM_USER_DETAIL_ENDPOINT
	try {
		const response = yield call(post, url, user)
		yield put(updateCrmUserDetailSuccess(response))
	} catch (error) {
		yield put(updateCrmUserDetailFail(error))
	}
}
function* createNewCustomer({ payload: user }) {
	const url = CREATE_NEW_CUSTOMER_ENDPOINT
	try {
		const response = yield call(post, url, user)
		yield put(createNewCustomerSuccess(response))
	} catch (error) {
		yield put(createNewCustomerFail(error.response.data.message))
	}
}
function* createNewLead({ payload: user }) {
	const url = CREATE_NEW_LEAD_ENDPOINT
	try {
		const response = yield call(post, url, user)
		yield put(createNewLeadSuccess(response))
	} catch (error) {
		yield put(createNewLeadFail(error.response.data.message))
	}
}
function* createNewLog({ payload: user }) {
	const url = CREATE_NEW_LOG_ENDPOINT
	try {
		const response = yield call(post, url, user)
		yield put(createNewLogSuccess(response))
		return response
	} catch (error) {
		yield put(createNewLogFail(error))
	}
}

function* upadateManager({ payload: data }) {
	const url = UPDATE_MANAGER_ENDPOINT
	try {
		const response = yield call(post, url, data)
		yield put(updateManagerSuccess(response))
	} catch (error) {
		yield put(updateManagerFail(error))
	}
}

function* updateUserData({ payload: data }) {
	const url = UPDATE_USER_DATA_ENDPOINT
	try {
		const response = yield call(post, url, data)
		yield put(updateUserDataSuccess(response))
	} catch (error) {
		yield put(updateUserDataFail(error))
	}
}

function* updateOrganizationData({ payload: data }) {
	const url = UPDATE_ORGANIZATION_DATA_ENDPOINT
	try {
		const response = yield call(post, url, data)
		yield put(updateOrganizationDataSuccess(response))
	} catch (error) {
		yield put(updateOrganizationDataFail(error))
	}
}

function* updatePaymentData({ payload: data }) {
	const url = UPDATE_PAYMENT_DATA_ENDPOINT
	try {
		const response = yield call(post, url, data)
		yield put(updatePaymentDataSuccess(response))
	} catch (error) {
		yield put(updatePaymentDataFail(error))
	}
}

function* updateShippingAddressData({ payload: data }) {
	const url = UPDATE_SHIPPING_ADDRESS_DATA_ENDPOINT
	try {
		const response = yield call(post, url, data)
		yield put(updateShippingAddressDataSuccess(response))
	} catch (error) {
		yield put(updateShippingAddressDataFail(error))
	}
}

function* getCrmInteractions({ payload: { id } }) {
	const url = `${GET_CRM_INTERACTIONS_ENDPOINT}/${id}`
	try {
		const response = yield call(get, url)
		yield put(getCrmInteractionsSuccess(response))
	} catch (error) {
		yield put(getCrmInteractionsFail(error))
	}
}

function* createNewCrmLog({ payload: { crmLog } }) {
	const url = CREATE_CRM_INTERACTION_ENDPOINT
	try {
		const response = yield call(post, url, crmLog)
		yield put(createNewCrmLogSuccess(response))
	} catch (error) {
		yield put(createNewCrmLogFail(error))
	}
}

function* getLogInAsToken({ payload: { accountId } }) {
	const url = `${GET_LOG_IN_AS_TOKEN_ENDPOINT}/${accountId}`
	try {
		const response = yield call(get, url)
		yield put(getLogInAsTokenSuccess(response))
	} catch (error) {
		yield put(getLogInAsTokenFail(error))
	}
}

function* updateOrganizationCommonData({ payload: data }) {
	const url = UPDATE_ORGANIZATION_COMMON_DATA_ENDPOINT
	try {
		const response = yield call(post, url, data)
		yield put(updateOrganizationCommonDataSuccess(response))
	} catch (error) {
		yield put(updateOrganizationCommonDataFail(error))
	}
}

function* crmUsersSaga() {
	yield takeEvery(GET_CRM_USERS, getCrmUsers)
	yield takeEvery(GET_CRM_USERS_DETAIL, getCrmUsersDetail)
	yield takeEvery(UPDATE_CRM_USER_DETAIL, updateCrmUserDetail)
	yield takeEvery(CREATE_NEW_CUSTOMER, createNewCustomer)
	yield takeEvery(CREATE_NEW_LOG, createNewLog)
	yield takeEvery(UPDATE_MANAGER, upadateManager)
	yield takeEvery(CREATE_NEW_LEAD, createNewLead)
	yield takeEvery(UPDATE_USER_DATA, updateUserData)
	yield takeEvery(UPDATE_ORGANIZATION_DATA, updateOrganizationData)
	yield takeEvery(UPDATE_PAYMENT_DATA, updatePaymentData)
	yield takeEvery(UPDATE_SHIPPING_ADDRESS_DATA, updateShippingAddressData)
	yield takeEvery(GET_CRM_INTERACTIONS, getCrmInteractions)
	yield takeEvery(CREATE_NEW_CRM_LOG, createNewCrmLog)
	yield takeEvery(GET_LOG_IN_AS_TOKEN, getLogInAsToken)
	yield takeEvery(UPDATE_ORGANIZATION_COMMON_DATA, updateOrganizationCommonData)
}

export default crmUsersSaga
