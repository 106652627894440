import { isObjectEmpty } from 'common/Utils/ObjectUtils'
import { mapDataToInputDTO, parseHolidays, parseWeekdays } from 'common/Utils/SuppliersUtils'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import TextInput from 'components/Common/TextInput'
import PrefixFlag from 'pages/CRMUserDetail/ComponenteTablas/prefixFlagComponent'
import { FORMAT_DATE_OPTIONS, INITIAL_STATE, LANGUAGE_OPTIONS, WORKDAYS_OPTIONS } from 'pages/Suppliers/formConstants'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateSupplier } from 'store/actions'

export const UpdateSupplierModal = ({ supplier, t, updateSupplier }) => {

	const [supplierData, setSupplierData] = useState(INITIAL_STATE)
	const [showModal, setShowModal] = useState(false)

	const handleOnSave = () => {
		setShowModal(false)
		updateSupplier(mapDataToInputDTO(supplierData, supplier.account.id))
	}

	useEffect(() => {
		if (!isObjectEmpty(supplier)) {
			const { account, supplierInfo } = supplier
			const holidaysMapped = parseHolidays(supplierInfo.calendar.holidays)
			setSupplierData({
				firstName: account.personalInformation.firstName,
				officialName: supplierInfo.officialName,
				address: supplierInfo.address.address,
				country: supplierInfo.address.country,
				state: supplierInfo.address.state,
				contactPerson: supplierInfo.contactPerson,
				mobilePhone: account.phoneData.mobilePhone.number,
				mobilePhonePrefix: account.phoneData.mobilePhone.prefix,
				language: account.personalInformation.language,
				phoneNumber: account.phoneData.phone.number,
				phonePrefix: account.phoneData.phone.prefix,
				formatDate: supplierInfo.personalSettings.formatDate,
				holidays: holidaysMapped,
				techTypes: supplierInfo.techTypes,
				shipmentDays: supplierInfo.calendar.shipmentDays,
				workDays: parseWeekdays(supplierInfo.calendar.workdays),
				currency: supplierInfo.personalSettings.currency,
				timeZone: supplierInfo.timezone,
				email: account.loginCredentials.email,
				reliabilityMarginDays: supplierInfo.calendar.reliabilityMarginDays,
			})
		}
	}, [supplier])

	const handleOnChangeSupplierData = (key, value) => {
		setSupplierData({ ...supplierData, [key]: value })
	}

	return (
		<div>
			<button className='btn btn-primary' onClick={() => { setShowModal(true) }}>{t('update', { ns: 'naming' })}</button>
			<Modal
				title={t('update_account', { ns: 'naming' })}
				body={
					<div className='p-4'>
						<TextInput
							value={supplierData.contactPerson}
							setValue={handleOnChangeSupplierData}
							title={t('firstName', { ns: 'naming' })}
							objectElement={'contactPerson'}
						/>
						<TextInput
							value={supplierData.firstName}
							setValue={handleOnChangeSupplierData}
							title={t('commercial_brand', { ns: 'naming' })}
							objectElement={'firstName'}
						/>
						<TextInput
							value={supplierData.address}
							setValue={handleOnChangeSupplierData}
							title={t('address', { ns: 'naming' })}
							objectElement={'address'}
						/>
						<TextInput
							value={supplierData.officialName}
							setValue={handleOnChangeSupplierData}
							title={t('officialName', { ns: 'naming' })}
							objectElement={'officialName'}
						/>
						<div className='row mx-2'>
							<div className='col-3 p-0'>
								<h6 className='my-2'>{t('prefix', { ns: 'naming' })}</h6>
								<PrefixFlag
									isEditable
									onChange={(e) => handleOnChangeSupplierData('mobilePhonePrefix', e.value)}
									prefixState={supplierData.mobilePhonePrefix}
								/>
							</div>
							<div className='col pe-2'>
								<TextInput
									value={supplierData.mobilePhone}
									setValue={handleOnChangeSupplierData}
									title={t('phone', { ns: 'naming' })}
									objectElement="mobilePhone"
									className='me-0 w-100'
								/>
							</div>
						</div>
						<OptionsInput
							value={supplierData.language}
							setValue={handleOnChangeSupplierData}
							title={t('language', { ns: 'naming' })}
							options={LANGUAGE_OPTIONS(t)}
							objectElement="language"
							automaticMappedValue
						/>
						<OptionsInput
							value={supplierData.formatDate}
							setValue={handleOnChangeSupplierData}
							title={t('format_date', { ns: 'naming' })}
							options={FORMAT_DATE_OPTIONS}
							objectElement="formatDate"
							automaticMappedValue
						/>
						<OptionsInput
							value={supplierData.workDays}
							setValue={handleOnChangeSupplierData}
							title={t('work_days', { ns: 'naming' })}
							options={WORKDAYS_OPTIONS(t)}
							objectElement="workDays"
							isMulti
							automaticMappedValue
						/>
						<div className='m-2'>
							<h6>{t('holidays', { ns: 'naming' })}</h6>
							<Flatpickr
								className="form-control bg-white d-block"
								placeholder="dd M,yyyy"
								value={supplierData.holidays}
								options={{
									mode: 'multiple',
									dateFormat: 'Y-m-d',
								}}

								onChange={(e, dateStr) => {
									handleOnChangeSupplierData('holidays', dateStr)
								}}
							/>
						</div>
					</div>
				}
				buttons={[<button key={'save_update_account'} className='btn btn-primary' onClick={() => { handleOnSave() }}>{t('save', { ns: 'naming' })}</button>]}
				isOpen={showModal}
				closeModal={() => { setShowModal(false) }}
			/>
		</div>
	)
}

UpdateSupplierModal.propTypes = {
	supplier: PropTypes.object,
	t: PropTypes.func,
	updateSupplier: PropTypes.func,
}

const mapStateToProps = (state) => ({
	supplier: state.Users.supplierDetail,

})

const mapDispatchToProps = {
	updateSupplier,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UpdateSupplierModal))
