import { formatDateToMMDDYYYY, getDate } from 'common/Utils/StringUtilities'
import DatePickr from 'components/Common/DatePickr'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { MONTHS } from '../constants'
import DateFilterDropdown from './DateFilterDropdown'

export const DateFilter = ({ dateRange, setDateRange, t }) => {

	const getYearOptions = () => {
		const currentYear = new Date().getFullYear()
		return Array.from(
			{ length: currentYear - 2017 },
			(_, i) => {
				const optionYear = currentYear - i
				return {
					optionToRender: optionYear,
					dateRange: {
						startDate: formatDateToMMDDYYYY(new Date(optionYear, 0, 1)),
						endDate: formatDateToMMDDYYYY(new Date(optionYear, 11, 31)),
					},
					active: false,
				}
			},
		)
	}

	function getCurrentQuarter() {
		const currentMonth = new Date().getMonth() + 1 // Months in JavaScript are 0 to 11

		if (currentMonth >= 1 && currentMonth <= 3) {
			return '1T'
		} else if (currentMonth >= 4 && currentMonth <= 6) {
			return '2T'
		} else if (currentMonth >= 7 && currentMonth <= 9) {
			return '3T'
		} else {
			return '4T'
		}
	}

	function calculateDateRange(year, quarter) {
		const quarters = ['1T', '2T', '3T', '4T']
		const startDate = new Date(`${year}/${(quarters.indexOf(quarter) * 3) + 1}/01`)
		const endDate = new Date(startDate)
		endDate.setMonth(endDate.getMonth() + 3)
		endDate.setDate(endDate.getDate() - 1)

		return {
			startDate: formatDateToMMDDYYYY(startDate),
			endDate: formatDateToMMDDYYYY(endDate),
		}
	}

	function getQuarterOptions() {
		const currentYear = new Date().getFullYear()
		const currentQuarter = getCurrentQuarter()
		const quarters = ['1T', '2T', '3T', '4T']
		let year = currentYear
		let quarter = currentQuarter
		const last8Quarters = []

		for (let i = 0; i < 8; i++) {
			const dateRange = calculateDateRange(year, quarter)

			last8Quarters.push({
				optionToRender: `${quarter} - ${year}`,
				dateRange: dateRange,
			})

			// Move to the previous quarter
			if (quarter === '1T') {
				quarter = '4T'
				year--
			} else {
				quarter = quarters[quarters.indexOf(quarter) - 1]
			}
		}

		return last8Quarters
	}

	const getMonthOptions = (t) => {
		const currentDate = new Date()
		const currentYear = currentDate.getFullYear()
		const currentMonth = currentDate.getMonth()
		return Array.from(
			{ length: 12 },
			(_, i) => {
				const monthToRender = currentMonth - i >= 0 ? MONTHS[currentMonth - i] : MONTHS[12 + currentMonth - i]
				const yearToRender = currentMonth - i >= 0 ? currentYear : currentYear - 1
				return {
					optionToRender: `${t(monthToRender, { ns: 'months' })} - ${yearToRender}`,
					dateRange: {
						startDate: formatDateToMMDDYYYY(new Date(currentYear, currentMonth - i, 1)),
						endDate: formatDateToMMDDYYYY(new Date(currentYear, currentMonth - i + 1, 0)),
					},
				}
			},
		)
	}

	const YEAR_OPTIONS = getYearOptions()
	const QUARTER_OPTIONS = getQuarterOptions()
	const MONTHS_OPTIONS = getMonthOptions(t)

	const handleOnChangeDate = (dateArray) => {
		if (dateArray.length === 2) {
			setDateRange({
				startDate: formatDateToMMDDYYYY(dateArray[0]),
				endDate: formatDateToMMDDYYYY(dateArray[1]),
			})
		}
	}

	return (
		<div className='ps-4 pt-4 d-flex justify-content-start'>
			<DateFilterDropdown options={YEAR_OPTIONS} title={t('year', { ns: 'naming' })} dateRange={dateRange} handleOnChangeDate={setDateRange} />
			<DateFilterDropdown options={QUARTER_OPTIONS} title={t('quarter', { ns: 'naming' })} dateRange={dateRange} handleOnChangeDate={setDateRange} />
			<DateFilterDropdown options={MONTHS_OPTIONS} title={t('months', { ns: 'naming' })} dateRange={dateRange} handleOnChangeDate={setDateRange} />
			<div style={{ width: '210px' }}>
				<DatePickr mode={'range'}
					className='w-100'
					format={'d/m/Y'}
					value={[getDate(dateRange?.startDate), getDate(dateRange?.endDate)]}
					setValue={handleOnChangeDate}
					placeholder={t('start_end_date', { ns: 'naming' })}
				/>
			</div>
		</div>
	)
}

DateFilter.propTypes = {
	setDateRange: PropTypes.func,
	t: PropTypes.func,
	dateRange: PropTypes.object,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DateFilter))
