import { findLangueByCode, getLanguageKeyValuePair } from 'common/Utils/LanguagesUtils'
import { getIdFromUrl } from 'common/Utils/StringUtilities'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import TextInput from 'components/Common/TextInput'
import CountryFlag from 'pages/CRMUserDetail/ComponenteTablas/CountryFlag'
import PrefixFlag from 'pages/CRMUserDetail/ComponenteTablas/prefixFlagComponent'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateUserData } from 'store/actions'

export const ModalUpdateUser = ({ propsUserData, t, updateUserData }) => {

	const [userData, setUserData] = useState({
		firstName: '',
		lastName: '',
		country: '',
		mobilePhone: '',
		mobilePhonePrefix: '',
		phone: '',
		phonePrefix: '',
		phoneExtension: '',
		phone2: '',
		phone2Prefix: '',
		phone2Extension: '',
		email: '',
		observations: '',
		web: '',

	})

	const [isModalOpen, setIsModalOpen] = useState(false)

	const updateUserDataState = (key, value) => {
		setUserData({ ...userData, [key]: value })
	}

	useEffect(() => {
		if (propsUserData != null) setUserData(mapUserData(propsUserData))
	}, [propsUserData])

	const mapUserData = (userData) => {
		const { account } = userData
		const { personalInformation, loginCredentials, phoneData } = account
		return {
			firstName: personalInformation.firstName,
			lastName: personalInformation.lastName,
			country: personalInformation.country,
			language: personalInformation.language,
			mobilePhone: phoneData.mobilePhone.number,
			mobilePhonePrefix: phoneData.mobilePhone.prefix,
			phone: phoneData.phone.number,
			phonePrefix: phoneData.phone.prefix,
			phoneExtension: phoneData.phone.extension,
			phone2: phoneData.phone2.number,
			phone2Prefix: phoneData.phone2.prefix,
			phone2Extension: phoneData.phone2.extension,
			email: loginCredentials.email,
			observations: account.comments,
			web: account.web,
		}
	}

	const mapToIAccount = (userData) => {
		return {
			data: {
				account: {
					id: getIdFromUrl(),
					web: userData.web,
					personalInformation: {
						country: userData.country,
						firstName: userData.firstName,
						lastName: userData.lastName,
						language: userData.language,
					},
					comments: userData.observations,
					phoneData: {
						mobilePhone: {
							prefix: userData.mobilePhonePrefix,
							number: userData.mobilePhone,
						},
						phone: {
							prefix: userData.phonePrefix,
							number: userData.phone,
							extension: userData.phoneExtension,
						},
						phone2: {
							prefix: userData.phone2Prefix,
							number: userData.phone2,
							extension: userData.phone2Extension,
						},
					},
				},
			},

		}
	}

	const saveData = () => {
		const mappedData = mapToIAccount(userData)
		updateUserData(mappedData)
		setIsModalOpen(false)
	}

	return (
		<div>
			<div className='cursor-pointer mt-2' onClick={(e) => setIsModalOpen(true)}>
				<i className='text-primary fs-4 bx bx-edit' ></i>
			</div>
			<Modal
				isOpen={isModalOpen}
				title={t('updateUser', { ns: 'naming' })}
				body={<div className='p-4'>
					<TextInput
						value={userData.firstName}
						setValue={updateUserDataState}
						title={t('name', { ns: 'naming' })}
						objectElement="firstName"
					/>
					<TextInput
						value={userData.lastName}
						setValue={updateUserDataState}
						title={t('lastName', { ns: 'naming' })}
						objectElement="lastName"
					/>
					<div className='row mx-2'>
						<div className='col-3 p-0'>
							<h6 className='my-2'>{t('prefix', { ns: 'naming' })}</h6>
							<PrefixFlag
								isEditable
								onChange={(e) => updateUserDataState('mobilePhonePrefix', e.value)}
								prefixState={userData.mobilePhonePrefix}
							/>
						</div>
						<div className='col pe-2'>
							<TextInput
								value={userData.mobilePhone}
								setValue={updateUserDataState}
								title={t('mobilePhone', { ns: 'naming' })}
								objectElement="mobilePhone"
								className='me-0 w-100'
							/>
						</div>
					</div>
					<div className='row mx-2'>
						<div className='col-3 p-0'>
							<h6 className='my-2'>{t('prefix', { ns: 'naming' })}</h6>
							<PrefixFlag
								isEditable
								onChange={(e) => updateUserDataState('phonePrefix', e.value)}
								prefixState={userData.phonePrefix}
							/>
						</div>
						<div className='col pe-2'>
							<TextInput
								value={userData.phone}
								setValue={updateUserDataState}
								title={t('phone', { ns: 'naming' })}
								objectElement="phone"
								className='me-0 w-100'
							/>
						</div>
						<div className='col-4 pe-2'>
							<TextInput
								value={userData.phoneExtension}
								setValue={updateUserDataState}
								title={t('extension', { ns: 'naming' })}
								objectElement="phoneExtension"
								className='me-0 w-100'
							/>
						</div>
					</div>
					<div className='row mx-2'>
						<div className='col-3 p-0'>
							<h6 className='my-2'>{t('prefix', { ns: 'naming' })}</h6>
							<PrefixFlag
								isEditable
								onChange={(e) => updateUserDataState('phone2Prefix', e.value)}
								prefixState={userData.phone2Prefix}
							/>
						</div>
						<div className='col pe-2'>
							<TextInput
								value={userData.phone2}
								setValue={updateUserDataState}
								title={t('phone', { ns: 'naming' })}
								objectElement="phone2"
								className='me-0 w-100'
							/>
						</div>
						<div className='col-4 pe-2'>
							<TextInput
								value={userData.phone2Extension}
								setValue={updateUserDataState}
								title={t('extension', { ns: 'naming' })}
								objectElement="phone2Extension"
								className='me-0 w-100'
							/>
						</div>
					</div>
					<div className='mx-2 mb-2'>
						<h6 className='my-2'>{t('country', { ns: 'naming' })}</h6>
						<CountryFlag
							isEditable
							onChange={(e) => updateUserDataState('country', e.value)}
							countryState={userData.country}
						/>
					</div>
					<OptionsInput
						title={t('language', { ns: 'naming' })}
						value={findLangueByCode(userData.language)}
						options={getLanguageKeyValuePair()}
						setValue={updateUserDataState}
						objectElement="language"
					/>
					<TextInput
						value={userData.email}
						setValue={updateUserDataState}
						title={t('email', { ns: 'naming' })}
						objectElement="email"
						disabled
					/>
					<div className="m-2 d-grid">
						<label htmlFor='observations'>{t('observations', { ns: 'naming' })}</label>
						<textarea name='observations' id='observations' cols={20} rows={5} value={userData.observations} onChange={e => updateUserDataState('observations', e.target.value)}>
						</textarea>
					</div>
					<TextInput
						value={userData.web}
						setValue={updateUserDataState}
						title={t('web', { ns: 'naming' })}
						objectElement="web"
					/>

				</div>}
				buttons={[
					<button key={'a'} onClick={(e) => saveData()} className='btn btn-primary'>{t('save', { ns: 'naming' })}</button>,
				]}
				closeModal={() => setIsModalOpen(false)}
			/>
		</div>
	)
}

ModalUpdateUser.propTypes = {
	propsUserData: PropTypes.object,
	t: PropTypes.func,
	updateUserData: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { updateUserData }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalUpdateUser))

/*  */
