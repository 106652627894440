import Modal from "components/Common/Modal/Modal"

export const ProductionPhotosModal = ({ isOpen, close, t, photos }) => {
	return (
		<Modal
			title={t('production_photos', { ns: 'naming' })}
			key={`production_photos_modal`}
			className="modal-xl"
			isOpen={isOpen}
			closeModal={close}
			body={
				<div className='d-flex flex-wrap flex-column align-content-center'>
					{photos.length > 0 ? photos.map((photo, index) => (
						<img
							key={index}
							src={photo.src}
							alt={photo.name}
							className="m-2"
							style={{ maxWidth: '95%' }}
						/>
					)) : <div className='mt-2'><h5>{t('no_images', { ns: 'naming' })}</h5></div>}
				</div>
			}
		/>
	)
}
