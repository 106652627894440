import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'

export const RegressionsChart = ({ labels, series, maxPrice, maxParts, leftYAxisText, rightYAxisText }) => {
	const BAR_COLOR = '#F9B66E'
	const BIG_BAR_COLOR = '#F67E00'

	const BAR_BORDER = 2
	const BIG_BAR_BORDER = 2

	const options = {
		colors: [BAR_COLOR, BIG_BAR_COLOR], //color linea pequeña, color borde barra, color linea gorda
		chart: {
			type: 'line',
			stacked: false,
			height: 350,
			toolbar: { show: false },
		},
		stroke: {
			width: [BAR_BORDER, BIG_BAR_BORDER], // [borde barra pequeña, borde barra grande, linea gorda, linea pequeña
			curve: 'smooth',
		},
		labels,
		markers: {
			size: 0,
		},
		yaxis: [{
			max: maxPrice,
			title: {
				text: leftYAxisText,

			},
		},],

		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (y) {
					if (typeof y !== 'undefined') {
						return y.toFixed(0)
					}
					return y

				},
			},
		},
	}



	return (
		<div style={{ height: '42.5rem' }}>
			<ReactApexChart height={'100%'} options={options} series={series} type="line" />
		</div>
	)
}

RegressionsChart.propTypes = {
	series: PropTypes.array,
	labels: PropTypes.array,
	leftYAxisText: PropTypes.string,
	rightYAxisText: PropTypes.string,

}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RegressionsChart) 
