import { useUploadBlueprintModal } from './useUploadBlueprintModal'

export function withUploadBlueprintModal(Component) {

	return function WrappedComponent(props) {
		const {
			UploadBlueprintModal,
			openUploadBlueprintModal,
		} = useUploadBlueprintModal({
			offerDetail: props.offerDetail,
		})

		return (
			<Component
				{...props}
				UploadBlueprintModal={UploadBlueprintModal}
				openUploadBlueprintModal={openUploadBlueprintModal}
			/>
		)
	}

}
