import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getBoxList, getOpenedExpeditionOrders } from 'store/actions'
import OrderExpeditionSummaryList from '../ExpeditionOrders/OrderExpeditionSummaryList'

const ExpeditionOrders = ({
	t,
	getOpenedExpeditionOrders,
	expeditionOrderList,
	getBoxList,
}) => {

	const [parsedOrders, setParsedOrders] = useState([])

	function parseScannedOrders() {
		const parsedScannedOrderList = []
		expeditionOrderList.forEach((order) => {
			const orderData =
			{
				id: order?.id,
				organizationName: order?.billingInfo?.company,
				shipmentDate: (order?.estimatedShipmentDate && order?.estimatedShipmentDate.slice(0, 10)) || t('noDate', { ns: 'naming' }),
				completed: order?.completed,
				box: order?.boxCode,
				receivingCountry: order?.billingInfo?.country,
			}
			parsedScannedOrderList.push(orderData)
		})
		parsedScannedOrderList.sort((a, b) => (a.shipmentDate > b.shipmentDate) ? 1 : -1)
		setParsedOrders(parsedScannedOrderList)
	}

	useEffect(() => {
		getOpenedExpeditionOrders()
		getBoxList()
	}, [])

	useEffect(() => {
		if (expeditionOrderList?.length > 0) parseScannedOrders()
	}, [expeditionOrderList])

	return (
		<div>
			<div className="mb-3 p-4 bg-white">
				<OrderExpeditionSummaryList title={t('opened_orders', { ns: 'naming' })} orders={parsedOrders} />
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		openedExpeditonOrders: state.Orders.openedExpeditonOrders,
		expeditionOrderList: state.Orders.expeditionOrderList,
	}
}

ExpeditionOrders.propTypes = {
	t: PropTypes.func,
	getOpenedExpeditionOrders: PropTypes.func,
	openedExpeditonOrders: PropTypes.any,
	getBoxList: PropTypes.func,
	expeditionOrderList: PropTypes.any,
}

export default connect(mapStateToProps,
	{
		getOpenedExpeditionOrders,
		getBoxList,
	})(withTranslation()(ExpeditionOrders))
