import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import PartThumbnail from 'components/Common/PartThumbnail'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const GRAMS = 'g'

const PartInfo = ({ data, partId, partName, imageUrl, stlUrl }) => {

	const getPartWeight = () => {
		if (data.weight.measure === GRAMS) {
			return roundAtDecimals(data.weight.value, 0)
		}
		return roundAtDecimals(data.weight.value, 2)
	}

	const model3dViewerData = {
		partId: partId,
		partName: partName,
		size: data.size,
		weight: data.weight,
	}

	return (
		<div className='d-flex flex-column px-4'>
			<PartThumbnail stlData={model3dViewerData} propsImageUrl={imageUrl} propsStlUrl={stlUrl} />
			<div className='d-flex flex-row'>
				<p className='mb-0'> {roundAtDecimals(data.size.x, 1)} * {roundAtDecimals(data.size.y, 1)} * {roundAtDecimals(data.size.z, 1)} {data.size.measure}</p>
			</div>
			<div className='d-flex flex-row'>

				<p className='mb-0'> {getPartWeight()} {data.weight.measure}</p>
			</div>
		</div>
	)
}

PartInfo.propTypes = {
	data: PropTypes.any,
	partId: PropTypes.number,
	t: PropTypes.func,
	partName: PropTypes.string,
	imageUrl: PropTypes.string,
	stlUrl: PropTypes.string,
}
export default withTranslation()(PartInfo)
