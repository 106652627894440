import { CANCELED_CODE } from 'constants/errorsCode'
import {
	CLEAR_QUOTE_ERROR,
	CLONE_QUOTE,
	CLONE_QUOTE_FAIL,
	CLONE_QUOTE_SUCCESS,
	CREATE_QUOTE,
	CREATE_QUOTE_FAIL,
	CREATE_QUOTE_SUCCESS,
	ERASE_SUPPLIER_ID_VIEW,
	GET_CLIENT_MARGIN,
	GET_CLIENT_MARGIN_FAIL,
	GET_CLIENT_MARGIN_SUCCESS,
	GET_PART_COSTS_BY_SUPPLIER_ID,
	GET_PART_COSTS_BY_SUPPLIER_ID_FAIL,
	GET_PART_COSTS_BY_SUPPLIER_ID_SUCCESS,
	GET_PART_PRICES_BY_SUPPLIER_ID,
	GET_PART_PRICES_BY_SUPPLIER_ID_FAIL,
	GET_PART_PRICES_BY_SUPPLIER_ID_SUCCESS,
	GET_QUOTE_CHAT,
	GET_QUOTE_CHAT_FAIL,
	GET_QUOTE_CHAT_SUCCESS,
	GET_QUOTE_DETAIL,
	GET_QUOTE_DETAIL_FAIL,
	GET_QUOTE_DETAIL_SUCCESS,
	GET_QUOTE_LIST,
	GET_QUOTE_LIST_COUNT,
	GET_QUOTE_LIST_COUNT_FAIL,
	GET_QUOTE_LIST_COUNT_SUCCESS,
	GET_QUOTE_LIST_FAIL,
	GET_QUOTE_LIST_SUCCESS,
	GET_QUOTE_REGRESSIONS_BY_PART_ID,
	GET_QUOTE_REGRESSIONS_BY_PART_ID_FAIL,
	GET_QUOTE_REGRESSIONS_BY_PART_ID_SUCCESS,
	GET_REGRESSION_VALUE_BY_QUANTITY,
	GET_REGRESSION_VALUE_BY_QUANTITY_FAIL,
	GET_REGRESSION_VALUE_BY_QUANTITY_SUCCESS,
	GET_SIMILAR_QUOTE_PARTS,
	GET_SIMILAR_QUOTE_PARTS_FAIL,
	GET_SIMILAR_QUOTE_PARTS_SUCCESS,
	MARK_QUOTE_MESSAGES_AS_READ,
	MARK_QUOTE_MESSAGES_AS_READ_FAIL,
	MARK_QUOTE_MESSAGES_AS_READ_SUCCESS,
	POST_QUOTE_CHAT,
	POST_QUOTE_CHAT_FAIL,
	POST_QUOTE_CHAT_SUCCESS,
	RESET_CREATE_QUOTE_MODAL,
	SAVE_AND_CONFIRM_QUOTE,
	SAVE_AND_CONFIRM_QUOTE_FAIL,
	SAVE_AND_CONFIRM_QUOTE_SUCCESS,
	SET_SUPPLIER_ID_VIEW,
	UPDATE_QUOTE_QUANTITIES,
	UPDATE_QUOTE_QUANTITIES_FAIL,
	UPDATE_QUOTE_QUANTITIES_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
	quoteList: {},
	isLoading: false,
	isLoadingPartCosts: false,
	isLoadingPartPrices: false,
	quoteDetail: {},
	error: null,
	isQuoteCreatedSuccessfully: null,
	isQuoteUpdatedSuccessfully: null,
	mailError: null,
	supplierIdView: null,
	isLoadingChat: false,
	quoteChat: [],
	isPostingChat: false,
	partCosts: [],
	partPrices: [],
	clientMargin: [],
	quoteChats: [], //TODO remove when quote refactor is finished and quoteDetail includes chatMessages
	hasClonedSuccessfully: null,
	isLoadingSimilarQuoteParts: false,
	errorSimilarQuoteParts: null,
	similarQuoteParts: [],
	regressionValue: {},
	isLoadingRegressionValue: false,

}

const quotes = (state = INIT_STATE, action) => {
	switch (action.type) {

		case GET_REGRESSION_VALUE_BY_QUANTITY:
			return {
				...state,
				isLoadingRegressionValue: true,
				error: null,
			}
		case GET_REGRESSION_VALUE_BY_QUANTITY_SUCCESS:
			return {
				...state,
				regressionValue: {
					unitPrice: action.payload.unitPrice,
					totalPrice: action.payload.totalPrice,
					unitCost: action.payload.unitCost,
					totalCost: action.payload.totalCost,
				},
				isLoadingRegressionValue: false,
				error: null,
			}

		case GET_REGRESSION_VALUE_BY_QUANTITY_FAIL:
			return {
				...state,
				regressionValue: {},
				error: action.payload.error,
				isLoadingRegressionValue: false
			}

		case GET_QUOTE_LIST:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_QUOTE_LIST_SUCCESS:
			return {
				...state,
				quoteList: {
					count: action.payload.totalRows,
					quotes: action.payload.quotes,
				},
				error: null,
				isLoading: false,
			}

		case GET_QUOTE_LIST_FAIL:
			return {
				...state,
				quoteList: {},
				error: action.payload,
				isLoading: false,
			}

		case GET_QUOTE_LIST_COUNT:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_QUOTE_LIST_COUNT_SUCCESS:
			return {
				...state,
				quoteList: {
					...state.quoteList,
					count: action.payload.count,
				},
				error: null,
				isLoading: false,
			}

		case GET_QUOTE_LIST_COUNT_FAIL:
			return {
				...state,
				quoteList: {},
				error: action.payload,
				isLoading: false,
			}
		case GET_QUOTE_DETAIL:
			return {
				...state,
				isLoading: true,
				error: null,
			}
		case GET_QUOTE_DETAIL_SUCCESS:
			return {
				...state,
				quoteDetail: action.payload,
				error: null,
				isLoading: false,
			}

		case GET_QUOTE_DETAIL_FAIL:
			return {
				...state,
				quoteDetail: {},
				error: action.payload.message,
				isLoading: false,
			}
		case UPDATE_QUOTE_QUANTITIES:
			return {
				...state,
				isQuoteUpdatedSuccessfully: null,
				isUpdating: true,
			}
		case UPDATE_QUOTE_QUANTITIES_SUCCESS:
			return {
				...state,
				isQuoteUpdatedSuccessfully: true,
				isUpdating: false,
			}
		case UPDATE_QUOTE_QUANTITIES_FAIL:
			return {
				...state,
				isQuoteUpdatedSuccessfully: false,
				isUpdating: false,
			}
		case CREATE_QUOTE:
			return {
				...state,
				isLoading: true
			}
		case CREATE_QUOTE_SUCCESS:
			return {
				...state,
				isLoading: false,
				isQuoteCreatedSuccessfully: true,
			}
		case CREATE_QUOTE_FAIL:
			return {
				...state,
				isLoading: false,
				isQuoteCreatedSuccessfully: false,
			}
		case RESET_CREATE_QUOTE_MODAL:
			return {
				...state,
				isQuoteCreatedSuccessfully: null,
			}
		case SET_SUPPLIER_ID_VIEW:
			return {
				...state,
				supplierIdView: action.payload.supplierId,
			}
		case ERASE_SUPPLIER_ID_VIEW:
			return {
				...state,
				supplierIdView: null,
			}
		case CLEAR_QUOTE_ERROR:
			return {
				...state,
				error: null,
			}
		case GET_QUOTE_REGRESSIONS_BY_PART_ID:
			return {
				...state,
				isLoadingRegressionsByPartId: true,
				quoteRegressionsByPartId: null,
			}
		case GET_QUOTE_REGRESSIONS_BY_PART_ID_SUCCESS:
			return {
				...state,
				isLoadingRegressionsByPartId: false,
				quoteRegressionsByPartId: action.payload.regressions,
			}
		case GET_QUOTE_REGRESSIONS_BY_PART_ID_FAIL:
			return {
				...state,
				isLoadingRegressionsByPartId: false,
				error: action.payload,
			}
		case GET_QUOTE_CHAT:
			return {
				...state,
				isLoadingChat: true,
				quoteChat: null,
				error: null,
			}
		case GET_QUOTE_CHAT_SUCCESS:
			return {
				...state,
				isLoadingChat: false,
				error: null,
				quoteChat: action.payload.quoteChatMessages,
				quoteChats: [...state.quoteChats, ...action.payload.quoteChatMessages], //TODO remove when quote refactor is finished and quoteDetail includes chatMessages
			}
		case GET_QUOTE_CHAT_FAIL:
			return {
				...state,
				isLoadingChat: action.payload.code === CANCELED_CODE,
				error: action.payload.code === CANCELED_CODE ? null : action.payload,
			}
		case POST_QUOTE_CHAT:
			return {
				...state,
				isPostingChat: true,
			}
		case POST_QUOTE_CHAT_SUCCESS:
			return {
				...state,
				isPostingChat: false,
			}
		case POST_QUOTE_CHAT_FAIL:
			return {
				...state,
				isPostingChat: false,
				error: action.payload,
			}
		case MARK_QUOTE_MESSAGES_AS_READ:
			return {
				...state,
				error: null,
				isLoading: true,
			}
		case MARK_QUOTE_MESSAGES_AS_READ_SUCCESS:
			return {
				...state,
				error: null,
				isLoading: false,
			}
		case MARK_QUOTE_MESSAGES_AS_READ_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.payload,
			}
		case SAVE_AND_CONFIRM_QUOTE:
			return {
				...state,
				isQuoteUpdatedSuccessfully: null,
				isUpdating: true,
				mailError: null,
			}
		case SAVE_AND_CONFIRM_QUOTE_SUCCESS:
			return {
				...state,
				isQuoteUpdatedSuccessfully: true,
				isUpdating: false,
				mailError: action.payload.response.mailError,
			}
		case SAVE_AND_CONFIRM_QUOTE_FAIL:
			return {
				...state,
				isQuoteUpdatedSuccessfully: false,
				isUpdating: false,
			}
		case GET_PART_COSTS_BY_SUPPLIER_ID:
			return {
				...state,
				isLoadingPartCosts: true,
			}
		case GET_PART_COSTS_BY_SUPPLIER_ID_SUCCESS:
			return {
				...state,
				partCosts: [...state.partCosts, action.payload],
				error: null,
				isLoadingPartCosts: false,
			}
		case GET_PART_COSTS_BY_SUPPLIER_ID_FAIL:
			return {
				...state,
				error: action.payload,
				isLoadingPartCosts: false,
			}
		case GET_PART_PRICES_BY_SUPPLIER_ID:
			return {
				...state,
				isLoadingPartPrices: true,
			}
		case GET_PART_PRICES_BY_SUPPLIER_ID_SUCCESS:
			return {
				...state,
				partPrices: [...state.partPrices, action.payload],
				error: null,
				isLoadingPartPrices: false,
			}
		case GET_PART_PRICES_BY_SUPPLIER_ID_FAIL:
			return {
				...state,
				error: action.payload,
				isLoadingPartPrices: false,
			}

		case GET_CLIENT_MARGIN:
			return {
				...state,
				isLoading: true,
			}
		case GET_CLIENT_MARGIN_SUCCESS:
			return {
				...state,
				clientMargin: action.payload.clientMargin,
				error: null,
				isLoading: false,
			}
		case GET_CLIENT_MARGIN_FAIL:
			return {
				...state,
				error: action.payload,
				isLoading: false,
			}
		case CLONE_QUOTE:
			return {
				...state,
				isLoading: true,
			}
		case CLONE_QUOTE_SUCCESS:
			return {
				...state,
				error: null,
				isLoading: false,
				hasClonedSuccessfully: true,
			}

		case CLONE_QUOTE_FAIL:
			return {
				...state,
				error: action.payload.error.message,
				isLoading: false,
			}

		case GET_SIMILAR_QUOTE_PARTS:
			return {
				...state,
				isLoadingSimilarQuoteParts: true,
				errorSimilarQuoteParts: null,
			}

		case GET_SIMILAR_QUOTE_PARTS_SUCCESS:
			return {
				...state,
				similarQuoteParts: action.payload.parts,
				isLoadingSimilarQuoteParts: false,

			}
		case GET_SIMILAR_QUOTE_PARTS_FAIL:
			return {
				...state,
				error: action.payload.message,
				isLoadingSimilarQuoteParts: false,

			}
		default:
			return state
	}
}

export default quotes
