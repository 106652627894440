import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DomainUsers from './DomainUsers'

import classnames from 'classnames'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
	Card,
	CardBody,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from 'reactstrap'
import UserTables from './UserTables/UserTables'

export const UserData = ({ t }) => {
	const [activeTab, setActiveTab] = useState(1)
	return (
		<div className='p-0 bg-white ms-2'>
			<Card>
				<CardBody>
					<ul className="nav nav-tabs nav-tabs-custom" role="tablist">
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === 1,
								})}
								onClick={() => {
									setActiveTab(1)
								}}
							>
								{t('user_data', { ns: 'naming' })}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classnames({
									active: activeTab === 2,
								})}
								onClick={() => {
									setActiveTab(2)
								}}
							>
								{t('company_users', { ns: 'naming' })}
							</NavLink>
						</NavItem>
					</ul>

					<TabContent
						activeTab={activeTab}
					>
						<TabPane tabId={1} id="all-order">
							<UserTables />
						</TabPane>
						<TabPane tabId={2} id="processing">
							<DomainUsers />
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
		</div>
	)
}

UserData.propTypes = {
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserData))
