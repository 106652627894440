import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { footerWebsite } from 'constants/footer'
import PropTypes from 'prop-types'
import Barcode from '../Barcode/Barcode'
/// I T
const URL_PROTOANDGO = footerWebsite
const ProductionLabel = ({
	supplierContactName,
	organizationName,
	postalCode,
	state,
	country,
	orderId,
	orderDate,
	totalWeight,
	id,
	code,
	text,
	format,
	className,
}) => {

	// THIS IS THE FIRST PAGE OF THE LABELS GENERATIOSN, THE LABEL PART ITMES IS PART LABEL
	return (
		<div className={`labelSize page label partlabel position-relative ${className}`}>
			<div className="mx-3">
				<p className="m-0">
					<small>{supplierContactName}</small>
				</p>
				<p className="m-0">
					<small>
						<strong>{organizationName}</strong>
					</small>
				</p>
				<p className="m-0">
					<small>
						{postalCode} - {state} - {country}
					</small>
				</p>
				<p className="m-0">
					<small>
						Order #<strong>{orderId}</strong> - {orderDate}
					</small>
				</p>
				<p className="m-0 mb-n2">
					<small>
						Total Weight:{' '}
						{totalWeight < 1000
							? `${roundAtDecimals(totalWeight, 2)} g.`
							: `${roundAtDecimals(totalWeight / 1000, 2)} kg.`}
					</small>
				</p>
			</div>
			<div className="d-flex">
				<small className="text-white d-flex me-1 mb-0 p-0">_</small>
				<Barcode
					className="mx-auto labelBarcode"
					id={id}
					code={code}
					text={text}
					format={format}
				/>
			</div>
			<div className="d-flex">
				<small className="mx-auto">{code}</small>
			</div>
		</div>
	)
}

ProductionLabel.propTypes = {
	id: PropTypes.string.isRequired,
	code: PropTypes.string,
	text: PropTypes.string,
	format: PropTypes.string,
	className: PropTypes.string,
	supplierContactName: PropTypes.string,
	organizationName: PropTypes.string,
	postalCode: PropTypes.string,
	state: PropTypes.string,
	country: PropTypes.string,
	orderId: PropTypes.number,
	orderDate: PropTypes.string,
	totalWeight: PropTypes.number,
}

export default ProductionLabel
