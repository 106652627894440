import PropTypes from 'prop-types'
import React, { Component } from 'react'

//Simple bar
import SimpleBar from 'simplebar-react'
import CRMContent from './CRMContent'
import ConfigurationContent from './ConfigurationContent'
import LeadershipContent from './LeadershipContent'
import LogisticsContent from './LogisticsContent'
import ManagementContent from './ManagementContent'
import QualityContent from './QualityContent'
import SupplierContent from './SupplierContent'

// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'

import { isStringInList } from '../../common/Utils/StringUtilities'
import {
	USER_ROLE_BOSS,
	USER_ROLE_MANAGER,
	USER_ROLE_QUALITY,
	USER_ROLE_SECUAZ,
	USER_ROLE_SUPPLIER,
} from '../../constants/userRoles'

import { navigationMenu } from 'constants/navigationMenu'
import HomepageContent from './HomepageContent'
import SecuazContent from './SecuazContent'
class SidebarContent extends Component {
	constructor(props) {
		super(props)
		this.refDiv = React.createRef()
	}

	componentDidMount() {
		this.initMenu()
	}

	// eslint-disable-next-line no-unused-vars
	componentDidUpdate(prevProps, prevState, ss) {
		if (this.props.type !== prevProps.type) {
			this.initMenu()
		}
	}

	initMenu() {
		new MetisMenu('#side-menu')

		let matchingMenuItem = null
		const ul = document.getElementById('side-menu')
		const items = ul.getElementsByTagName('a')

		const navigationWrapper = Object.entries(navigationMenu).find(offerItem => {
			const found = offerItem[1].find(item =>
				this.props.location.pathname.includes(item),
			)
			if (found) return offerItem
		})

		for (let i = 0; i < items.length; ++i) {
			if (
				this.props.location.pathname === items[i].pathname ||
				(navigationWrapper && navigationWrapper[0] === items[i].pathname)
			) {
				matchingMenuItem = items[i]
				break
			}
		}
		if (matchingMenuItem) {
			this.activateParentDropdown(matchingMenuItem)
		}
	}

	// componentDidUpdate() {}

	scrollElement(item) {
		setTimeout(() => {
			if (this.refDiv.current !== null) {
				if (item) {
					const currentPosition = item.offsetTop
					if (currentPosition > window.innerHeight) {
						if (this.refDiv.current)
							this.refDiv.current.getScrollElement().scrollTop =
								currentPosition - 300
					}
				}
			}
		}, 300)
	}

	activateParentDropdown(item) {
		item.classList.add('active')
		const parent = item.parentElement

		const parent2El = parent.childNodes[1]
		if (parent2El && parent2El.id !== 'side-menu') {
			parent2El.classList.add('mm-show')
		}

		if (parent) {
			parent.classList.add('mm-active')
			const parent2 = parent.parentElement

			if (parent2) {
				parent2.classList.add('mm-show') // ul tag

				const parent3 = parent2.parentElement // li tag

				if (parent3) {
					parent3.classList.add('mm-active') // li
					parent3.childNodes[0].classList.add('mm-active') //a
					const parent4 = parent3.parentElement // ul
					if (parent4) {
						parent4.classList.add('mm-show') // ul
						const parent5 = parent4.parentElement
						if (parent5) {
							parent5.classList.add('mm-show') // li
							parent5.childNodes[0].classList.add('mm-active') // a tag
						}
					}
				}
			}
			this.scrollElement(item)
			return false
		}
		this.scrollElement(item)
		return false
	}

	checkRole(role) {
		return isStringInList(role, this.props.roles)
	}

	render() {
		return (
			<React.Fragment>
				<SimpleBar className="h-100" ref={this.refDiv}>
					<div id="sidebar-menu">
						<ul className="metismenu list-unstyled" id="side-menu">
							{(this.checkRole(USER_ROLE_MANAGER) ||
								this.checkRole(USER_ROLE_QUALITY)) && <HomepageContent />}
							{(this.checkRole(USER_ROLE_MANAGER) ||
								this.checkRole(USER_ROLE_QUALITY)) && <ManagementContent />}
							{(this.checkRole(USER_ROLE_SUPPLIER) ||
								this.checkRole(USER_ROLE_MANAGER) ||
								this.checkRole(USER_ROLE_QUALITY)) && <SupplierContent />}
							{(this.checkRole(USER_ROLE_MANAGER) ||
								this.checkRole(USER_ROLE_QUALITY)) && <CRMContent />}
							{(this.checkRole(USER_ROLE_MANAGER) ||
								this.checkRole(USER_ROLE_QUALITY)) && <LogisticsContent />}
							{(this.checkRole(USER_ROLE_MANAGER) ||
								this.checkRole(USER_ROLE_QUALITY)) && <QualityContent />}
							{(this.checkRole(USER_ROLE_MANAGER) ||
								this.checkRole(USER_ROLE_QUALITY)) && <ConfigurationContent />}
							{this.checkRole(USER_ROLE_BOSS) && <LeadershipContent />}
							{this.checkRole(USER_ROLE_SECUAZ) && <SecuazContent />}
						</ul>
					</div>
				</SimpleBar>
			</React.Fragment>
		)
	}
}

SidebarContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
	type: PropTypes.string,
	roles: PropTypes.arrayOf(PropTypes.string),
}

export default withRouter(withTranslation()(SidebarContent))
