import Productions from 'pages/Productions/Productions'

const SupplierProductions = () => {
	return (
		<Productions showSupplierProductions />
	)

}

export default (SupplierProductions)
