import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { withTranslation } from 'react-i18next'
import BrandLogo from './BrandLogo'

class Footer extends Component {
	render() {
		const { t } = this.props
		return (
			<React.Fragment>
				<p className="text-center">
					© {new Date().getFullYear()} –{' '}
					{t('allRightsReserved', { ns: 'naming' })} | PROTO&GO!
				</p>
				<BrandLogo className="w-50" darkMode />
			</React.Fragment>
		)
	}
}

Footer.propTypes = {
	t: PropTypes.func,
}
export default withTranslation()(Footer)
