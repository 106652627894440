export const RELATED_OPTIONS = ['general', 'quote', 'order']

export const INTERACTION_TYPE_OPTIONS = ['customer_service', 'comercial']

export const INTERACTION_OPTIONS = ['email', 'call', 'visit', 'chat', 'internal_note']

export const INTERACTION_TYPE_COLOR = {
	customer_service: 'green',
	comercial: 'yellow',
}


export const RELATED_COLOR = {
	general: 'blue',
	quote: 'orange',
	order: 'yellow',
}

export const INTERACTION_COLOR = {
	email: 'info',
	call: 'olive',
	visit: 'cyan',
	chat: 'pink',
	internal_note: 'purple'
}
