import Barcode from 'components/Common/Barcode/Barcode'
import { DEFAULT_BARCODE_FORMAT } from 'components/Common/PrintableContent/PrintableContent'
import PropTypes from 'prop-types'

const BoxLabel = ({ idLabel, box }) => {

	const { barCode, ref, id } = box
	return (
		<div className={'labelSize page label partlabel position-relative'}>
			<div className='w-100 h-100 d-flex justify-content-center p-2 align-items-center flex-column mt-2'>
				<div className='box_ref_container'>
					<span className='box_ref'>{ref}</span>
				</div>

				<Barcode
					className="labelBarcode"
					id={idLabel}
					code={barCode}
					text={barCode}
					format={DEFAULT_BARCODE_FORMAT}
					width={1.5}
				/>
				<small className='d-flex justify-content-center'>{barCode}</small>

			</div>
		</div>
	)
}

BoxLabel.propTypes = {
	boxItem: PropTypes.shape({
		id: PropTypes.string,
		ref: PropTypes.string,
		barCode: PropTypes.string,
	}),
}

export default BoxLabel
