import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import Loader from 'components/Common/Loader'
import SimpleTable from 'components/Common/SimpleTable'
import { NOTIFICATIONTYPE_COLOR, NOTIFICATION_TYPE } from 'constants/notificationType'
import { USER_ROLE_BOSS, USER_ROLE_MANAGER } from 'constants/userRoles'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap'
import { Checkbox } from 'semantic-ui-react'
import { getNotificationsByAccountId, updateNotifications } from 'store/actions'
import ModalCreateTaskManager from './ModalCreateTaskManager'


const ARCHIVED_FILTER = "Archivado"
const IMPORTANT_FILTER = "Importante"
const PENDING_FILTER = "Pendiente"
const ALL_FILTER = "Todas"


export const Notifications = ({ t, getNotificationsByAccountId, notifications, error, isLoading, selectedManagersIds, updateNotifications }) => {
	const filterOptions = [ARCHIVED_FILTER, IMPORTANT_FILTER, PENDING_FILTER, ALL_FILTER]
	const [selectedCheckboxes, setSelectedCheckboxes] = useState([])
	const [filter, setFilter] = useState('')
	const [isOpenModalCreateTaskManager, setIsOpenModalCreateTaskManager] = useState(false)
	const [selectedNotification, setSelectedNotification] = useState(null)
	const [allNotifications, setAllNotifications] = useState([])
	const user = getUserValuesFromLocalStorage()
	const [showNoNotifications, setShowNoNotifications] = useState(false)
	const hasManagerBossRole = user.roles.includes(USER_ROLE_BOSS) || user.roles.includes(USER_ROLE_MANAGER)
	const [isLoadingNotifications, setIsLoadingNotifications] = useState(false)


	useEffect(() => {
		if (selectedManagersIds.length > 0) {
			const promises = selectedManagersIds.map(id => getNotificationsByAccountId(id))
			Promise.all(promises).then((results) => {
				const accumulatedNotifications = results.flat()
				setAllNotifications((prevNotifications) => {
					const filteredPrevNotifications = prevNotifications.filter((notification) =>
						selectedManagersIds.includes(notification.managerId)
					)
					const newNotifications = accumulatedNotifications.filter(
						(newNotif) => newNotif.id && !filteredPrevNotifications.some(
							(existingNotif) => existingNotif.id === newNotif.id
						)
					)
					return [...filteredPrevNotifications, ...newNotifications]
				})
			})
		} else {
			setAllNotifications([])
		}

	}, [selectedManagersIds])

	const { i18n: { language } } = useTranslation()

	useEffect(() => {
		if (notifications && notifications.length > 0) {
			setAllNotifications((prevNotifications) => {
				const newNotifications = notifications.filter(
					(newNotif) =>
						newNotif &&
						newNotif.id &&
						!prevNotifications.some(
							(existingNotif) =>
								existingNotif &&
								existingNotif.id &&
								existingNotif.id === newNotif.id
						)
				)

				return [...prevNotifications, ...newNotifications]
			})
		}
	}, [notifications])


	useEffect(() => {
		setShowNoNotifications(false)
		setIsLoadingNotifications(true)
		const timeoutId = setTimeout(() => {
			if (!allNotifications || allNotifications.length === 0) {
				setShowNoNotifications(true)
			} setIsLoadingNotifications(false)
		}, 950)

		return () => {
			clearTimeout(timeoutId)
			setIsLoadingNotifications(false)
		}
	}, [allNotifications])

	const handleCheckBox = (id) => {
		if (selectedCheckboxes.includes(id)) {
			setSelectedCheckboxes(selectedCheckboxes.filter(item => item !== id))

		} else {
			setSelectedCheckboxes([...selectedCheckboxes, id])

		}
	}

	const handleTaskClick = (notification) => {
		setSelectedNotification(notification)
		setIsOpenModalCreateTaskManager(true)
		handleReadNotifications([notification.id])
	}

	const handleStarClick = (id) => {
		const notificationToUpdate = allNotifications.find(notification => notification.id === id)

		if (!notificationToUpdate) {
			console.error("Notification not found with the given id:", id)
			return
		}

		const updatedNotification = {
			...notificationToUpdate,
			important: !notificationToUpdate.important
		}

		setAllNotifications(prevNotifications =>
			prevNotifications.map(notification =>
				notification.id === id ? updatedNotification : notification
			)
		)

		updateNotifications([updatedNotification])
	}


	const handleCompleteNotifications = async () => {
		const updatedNotifications = selectedCheckboxes.map(id => {

			const notification = allNotifications.find(notification => notification.id === id)
			if (!notification) return null

			return {
				...notification,
				completed: !notification.completed,
				read: true,
				readDate: new Date().toISOString(),
			}
		}).filter(Boolean)


		await updateNotifications(updatedNotifications)


		setAllNotifications(prevNotifications =>
			prevNotifications.map(notification =>

				updatedNotifications.find(updated => updated.id === notification.id) || notification
			)
		)
	}


	const handleReadNotifications = (ids) => {
		const updatedNotifications = ids.map(id => {
			const notification = allNotifications.find(notification => notification.id === id)
			if (!notification) return null

			return {
				...notification,
				readDate: new Date().toISOString(),
				read: true,
			}
		}).filter(Boolean)

		setAllNotifications(prevNotifications =>
			prevNotifications.map(notification =>
				updatedNotifications.find(updated => updated.id === notification.id) || notification
			)
		)

		updateNotifications(updatedNotifications)
	}

	const handleLinkClick = (id, url) => {
		handleReadNotifications([id])
	}

	const getTableBody = () => {

		if (showNoNotifications) {
			return (
				<tr>
					<td colSpan="10">{t('notifications_not_available', { ns: 'naming' })}</td>
				</tr>
			)
		}


		let filteredNotifications = allNotifications
		switch (filter) {
			case ARCHIVED_FILTER:
				filteredNotifications = allNotifications.filter(notification => notification.completed)
				break
			case IMPORTANT_FILTER:
				filteredNotifications = allNotifications.filter(notification => notification.important)
				break
			case PENDING_FILTER:
				filteredNotifications = allNotifications.filter(notification => !notification.read)
				break
			case ALL_FILTER:
				filteredNotifications = allNotifications
				break
			default:
				filteredNotifications = allNotifications
		}

		filteredNotifications.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))

		const getNotificationText = (type_id, t) => {
			switch (type_id) {
				case NOTIFICATION_TYPE.OfferPublished:
					return t('offer', { ns: 'naming' })
				case NOTIFICATION_TYPE.OrderPublished:
					return t('order', { ns: 'naming' })
				case NOTIFICATION_TYPE.QuotePublished:
					return t('quote', { ns: 'naming' })
				case NOTIFICATION_TYPE.ProdRejected:
					return t('production', { ns: 'naming' })
				case NOTIFICATION_TYPE.QualityIncidence:
					return t('quality_incidence', { ns: 'naming' })
				case NOTIFICATION_TYPE.ErrorPayment:
					return t('new_order', { ns: 'naming' })
				case NOTIFICATION_TYPE.QualityIncidence:
					return t('incidence', { ns: 'naming' })
				case NOTIFICATION_TYPE.ErrorPayment:
					return t('order', { ns: 'naming' })
				case NOTIFICATION_TYPE.ProdDelayedNotified:
					return t('production', { ns: 'naming' })
				case NOTIFICATION_TYPE.ProdDelayed:
					return t('production', { ns: 'naming' })
				case NOTIFICATION_TYPE.ProdRetention:
					return t('production', { ns: 'naming' })
				case NOTIFICATION_TYPE.OfferNoOpened:
					return t('offer', { ns: 'naming' })
				case NOTIFICATION_TYPE.CrmNotification:
					return t('CRM', { ns: 'naming' })
				case NOTIFICATION_TYPE.ProdNotAccepted:
					return t('production', { ns: 'naming' })
				case NOTIFICATION_TYPE.NewOffer:
					return t('offer', { ns: 'naming' })
				case NOTIFICATION_TYPE.GenericTask:
				case NOTIFICATION_TYPE.CustomerTask:
				case NOTIFICATION_TYPE.OfferTask:
				case NOTIFICATION_TYPE.OrderTask:
					return t('task', { ns: 'naming' })
				default:
					return t('unknown_notification', { ns: 'naming' })
			}
		}

		const getNotificationLink = (notification, t, handleLinkClick) => {
			const { type_id, data_id, involvedAccount, subject } = notification

			let accountName = ''

			if (involvedAccount) {
				accountName = `${involvedAccount.personalInformation.firstName} ${involvedAccount.personalInformation.lastName}`
			}
			switch (type_id) {
				case NOTIFICATION_TYPE.OfferPublished:
					return (
						<Link to={`offer/${data_id}`}>
							{t('offer_published', { ns: 'naming' })}!
						</Link>
					)
				case NOTIFICATION_TYPE.NewOffer:
					return (
						<Link to={`offer/${data_id}`}>
							{t('offer_notification', { ns: 'naming' })} {accountName}!
						</Link>
					)
				case NOTIFICATION_TYPE.OrderPublished:

					return (
						<div onClick={() => handleLinkClick(notification.id, `/order/${data_id}`)}>
							<Link to={`order/${data_id}`}>
								{t('order_notification', { ns: 'naming' })} {accountName}!
							</Link>
						</div>

					)
				case NOTIFICATION_TYPE.QuotePublished:
					return (
						<div onClick={() => handleLinkClick(notification.id, `/quote/${data_id}`)}>
							<Link to={`quote/${data_id}`}>
								{t('quote_notification', { ns: 'naming' })} {accountName}!
							</Link>
						</div>
					)

				case NOTIFICATION_TYPE.ProdRejected:
					return (
						<div onClick={() => handleLinkClick(notification.id, `/order/${data_id}`)}>
							<Link to={`order/${data_id}`}>
								{t('prod_rejected_notification', { ns: 'naming' })} {accountName}!
							</Link>
						</div>
					)

				case NOTIFICATION_TYPE.QualityIncidence:
					return (
						<div onClick={() => handleLinkClick(notification.id, `/order/${data_id}`)}>
							<Link to={`order/${data_id}`}>
								{t('quality_incidence_notification', { ns: 'naming' })}
							</Link>
						</div>
					)
				case NOTIFICATION_TYPE.ErrorPayment:
					return (
						<div onClick={() => handleLinkClick(notification.id, `/order/${data_id}`)}>
							<Link to={`order/${data_id}`}>
								{t('error_payment', { ns: 'naming' })} {data_id}!
							</Link>
						</div>
					)
				case NOTIFICATION_TYPE.ProdDelayedNotified:
					return (
						<div onClick={() => handleLinkClick(notification.id, `/supplier-production/${data_id}`)}>
							<Link to={`supplier-production/${data_id}`}>
								{t('delay_production_notified', { ns: 'naming' })} {accountName}!
							</Link>
						</div>
					)
				case NOTIFICATION_TYPE.ProdDelayed:
					return (
						<div onClick={() => handleLinkClick(notification.id, `/supplier-production/${data_id}`)}>
							<Link to={`supplier-production/${data_id}`}>
								{t('delay_production', { ns: 'naming' })}!
							</Link>
						</div>
					)

				case NOTIFICATION_TYPE.ProdRetention:
					return (
						<div onClick={() => handleLinkClick(notification.id, `/supplier-production/${data_id}`)}>
							<Link to={`supplier-production/${data_id}`}>
								{t('retention_production', { ns: 'naming' })}!
							</Link>
						</div>
					)
				case NOTIFICATION_TYPE.OfferNoOpened:
					return (
						<div onClick={() => handleLinkClick(notification.id, `/offer/${data_id}`)}>
							<Link to={`offer/${data_id}`}>
								{t('offer_no_opened', { ns: 'naming' })}!
							</Link>
						</div>
					)
				case NOTIFICATION_TYPE.CrmNotification:
					return (
						<div onClick={() => handleLinkClick(notification.id, `/crm-user-detail/${involvedAccount.id}`)}>
							<Link to={`crm-user-detail/${involvedAccount.id}`}>
								{t('reminder_crm', { ns: 'naming' })}!
							</Link>
						</div>
					)
				case NOTIFICATION_TYPE.ProdNotAccepted:
					return (
						<div onClick={() => handleLinkClick(notification.id, `/supplier-production/${data_id}`)}>
							<Link to={`supplier-production/${data_id}`}>
								{t('prod_not_accepted', { ns: 'naming' })}{accountName}!
							</Link>
						</div>
					)
				case NOTIFICATION_TYPE.GenericTask:
				case NOTIFICATION_TYPE.CustomerTask:
				case NOTIFICATION_TYPE.OfferTask:
				case NOTIFICATION_TYPE.OrderTask:
					return (
						<div onClick={() => handleTaskClick(notification)} style={{ cursor: 'pointer' }}>
							<p style={{ color: '#556EE6' }}>{notification.subject}</p>
						</div>
					)

				default:
					return <p>{data_id}</p>
			}
		}

		const formatCreationDate = (creationDate) => {
			const now = new Date()
			const creationDateObj = new Date(creationDate)
			if (now.toDateString() === creationDateObj.toDateString()) {
				return creationDateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
			} else {
				return creationDateObj.toLocaleDateString([], { day: '2-digit', month: 'long' })
			}
		}


		return filteredNotifications.map(notification => {
			if (!notification) {
				return null
			}
			const rowBackgroundColor = notification.read ? '#ededed' : 'white'

			return (
				<tr key={notification.id} style={{ backgroundColor: "red" }}>
					<td style={{ backgroundColor: rowBackgroundColor, maxWidth: "10px" }}>
						<div className='d-flex flex-row align-items-center'>
							<Checkbox
								checked={selectedCheckboxes.includes(notification.id)}
								onChange={() => handleCheckBox(notification.id)}
							/>
							<button
								style={{ border: "0px", backgroundColor: "transparent", marginLeft: "15px" }}
								onClick={() => handleStarClick(notification.id)}

							>
								{notification.important ? (
									<i className='bx bxs-star' style={{ color: "#fcd303", fontSize: "16px" }}></i>
								) : (
									<i className='bx bx-star' style={{ fontSize: "16px" }}></i>
								)}
							</button>
							{notification.completed && (
								<i className='bx bx-archive-in' style={{ fontSize: "18px", marginLeft: "15px" }}></i>
							)}
						</div>
					</td>
					<td style={{ backgroundColor: rowBackgroundColor, maxWidth: "10px" }}>

					</td>
					<td style={{ backgroundColor: rowBackgroundColor, maxWidth: "10px" }}>

					</td>
					<td style={{ backgroundColor: rowBackgroundColor }}>
						{notification.data_id}
					</td>

					<td style={{ backgroundColor: rowBackgroundColor }}>
						<span className={`rounded-pill end-ellipsis text-white bg-${NOTIFICATIONTYPE_COLOR[notification.type_id]}`}>
							{getNotificationText(notification.type_id, t)}
						</span>
						{[
							NOTIFICATION_TYPE.ProdRejected,
							NOTIFICATION_TYPE.QualityIncidence,
							NOTIFICATION_TYPE.ErrorPayment,
							NOTIFICATION_TYPE.ProdDelayedNotified,
							NOTIFICATION_TYPE.ProdDelayed,
							NOTIFICATION_TYPE.ProdRetention,
							NOTIFICATION_TYPE.OfferNoOpened,
							NOTIFICATION_TYPE.ProdNotAccepted
						].includes(notification.type_id) && (
								<div style={{ color: "red", marginLeft: "-21px", marginTop: "-17px" }}>
									<i className='bx bx-error fs-5'></i>
								</div>
							)}
					</td>
					<td style={{ backgroundColor: rowBackgroundColor, maxWidth: "140px" }}>
						{getNotificationLink(notification, t, handleLinkClick)}
					</td>
					<td style={{ backgroundColor: rowBackgroundColor }}>
						{(() => {
							const creationDate = new Date(notification.creationDate)
							const dayName = creationDate.toLocaleDateString(language, { weekday: 'long' })
							const date = creationDate.toLocaleDateString(language, { day: '2-digit', month: '2-digit', year: 'numeric' })
							const time = creationDate.toLocaleTimeString(language, { hour: '2-digit', minute: '2-digit', second: '2-digit' })

							return (
								<>
									{`${dayName.charAt(0).toUpperCase() + dayName.slice(1)}`}<br />
									{date}<br />
									{time}
								</>
							)
						})()}
					</td>
				</tr>
			)
		})
	}

	const reloadNotifications = () => {
		if (selectedManagersIds.length > 0) {
			setIsLoadingNotifications(true)
			const promises = selectedManagersIds.map(id => getNotificationsByAccountId(id))

			Promise.all(promises)
				.then((results) => {
					const accumulatedNotifications = results.flat()
					setAllNotifications((prevNotifications) => {
						const filteredPrevNotifications = prevNotifications.filter((notification) =>
							selectedManagersIds.includes(notification.managerId)
						)
						const newNotifications = accumulatedNotifications.filter(
							(newNotif) => newNotif.id && !filteredPrevNotifications.some(
								(existingNotif) => existingNotif.id === newNotif.id
							)
						)
						return [...filteredPrevNotifications, ...newNotifications]
					})
				})
				.catch((error) => {
					console.error("Error loading notifications: ", error)
				})
				.finally(() => {
					setIsLoadingNotifications(false)
				})
		} else {
			setAllNotifications([])
		}
	}

	const header = ['', '', '', 'Id', 'Tipo', 'Mensaje', 'Fecha']

	return (
		<div>
			<div className='d-flex flex-wrap justify-content-start mb-3'>
				<div className='d-inline-flex position-relative align-middle me-2 mb-2 mb-sm-0'>
					<button style={{ borderRadius: "3px", border: "0px", width: "60px", height: "35px" }} className='btn btn-secondary' onClick={handleCompleteNotifications}>
						<i class='bx bx-archive-in' style={{ fontSize: "20px" }}></i>
					</button>
					<UncontrolledDropdown>
						<DropdownToggle
							style={{ borderRadius: "3px", marginLeft: "9px", border: "0px", width: "80px" }}
							className='btn btn-secondary'
							caret
						>
							{t('filter', { ns: 'naming' })}
						</DropdownToggle>
						<DropdownMenu
							size="lg"
							className="w-100 h-85">
							{
								filterOptions.map((option, index) => {
									const isActive = filter === option
									return (
										<div
											key={index} className="p-3 list-status-item-filter cursor-pointer d-flex justify-content-center"
											onClick={() => { setFilter(option) }}
										>
											<div
												className={isActive ? 'border-bottom border-warning' : 'border-bottom border-white'}>
												<div className="mx-2">
													<span className="">{option}</span>
												</div>
											</div>
										</div>
									)
								})
							}
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
				{hasManagerBossRole && (
					<button style={{ borderRadius: "3px", border: "0px", width: "60px", height: "35px" }} className='btn btn-secondary' onClick={() => {
						setIsOpenModalCreateTaskManager(true)
						selectedNotification && setSelectedNotification(null)
					}}>
						<i className='bx bx-plus' style={{ fontSize: "20px" }}></i>
					</button>
				)}
				<button style={{ borderRadius: "3px", border: "0px", width: "60px", height: "35px", marginLeft: "9px" }} className='btn btn-secondary' onClick={reloadNotifications}>
					<i class='bx bx-sync' style={{ fontSize: "20px" }}></i>
				</button>
			</div>
			<div style={{ maxHeight: '600px', overflowY: 'auto', marginTop: "30px" }}>
				{isLoading || isLoadingNotifications ? <Loader /> : <SimpleTable getTableBody={getTableBody} header={header} striped={false} />}

			</div>
			{isOpenModalCreateTaskManager && (
				<ModalCreateTaskManager selectedManagersIds={selectedManagersIds} isOpen={isOpenModalCreateTaskManager} closeModal={() => setIsOpenModalCreateTaskManager(false)} selectedNotification={selectedNotification} />
			)}
		</div>
	)
}

Notifications.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	getNotificationsByAccountId: PropTypes.func,
	updateNotifications: PropTypes.func,
	notifications: PropTypes.object,
}

const mapStateToProps = (state) => ({
	notifications: state.Users.notifications,
	isLoading: state.Users.isLoading,
	error: state.Users.error,
})

const mapDispatchToProps = { getNotificationsByAccountId, updateNotifications }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Notifications))
