import { PropTypes } from 'prop-types'
import React, { Component } from 'react'

import FileIcon from './FileIcon.js'

class STPFileIcon extends Component {
	render() {
		return (
			<React.Fragment>
				<FileIcon
					src={this.props.src}
					fileExtension="STL"
					fileBackgroundColor="#7146CD"
					tooltipMessage={this.props.tooltipMessage}
				/>
			</React.Fragment>
		)
	}
}
STPFileIcon.propTypes = {
	tooltipMessage: PropTypes.string,
	src: PropTypes.string,
}
export default STPFileIcon
