import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getPurchasesDashboard, getSuppliersInfo } from 'store/actions'
import { getPreviousDateRange } from '../utils'
import PurchasesDashboard from './PurchasesDashboard'
import PurchasesDashboardSideBar from './PurchasesDashboardSideBar'

export const PurchasesDashboardContainer = ({ t, dateRange, filters, getPurchasesDashboard, getSuppliersInfo, error, isLoading }) => {

	useEffect(() => {
		if (dateRange != null) {
			const previousDateRange = getPreviousDateRange(dateRange)
			const data = {
				previousDateRange,
				dateRange,
				filters,
			}
			getPurchasesDashboard(data)
		}
	}, [dateRange, filters])

	useEffect(() => {
		getSuppliersInfo()
	}, [])

	return (
		<div style={{ height: '44rem' }} className='d-flex align-items-center justify-content-center'>
			{
				error ? <Alert message={error} type='danger' /> : isLoading ? <Loader className='font-size-50' /> :
					<div className='d-flex col justify-content-center mt-4'>
						<div className='col-10 mx-4 d-flex flex-column h-100'>
							<PurchasesDashboard />
						</div>
						<div className='col-1 d-flex justify-content-center align-items-start'>
							<PurchasesDashboardSideBar dateRange={dateRange} />
						</div>
					</div>
			}
		</div>
	)
}

PurchasesDashboardContainer.propTypes = {
	t: PropTypes.func,
	dateRange: PropTypes.object,
	filters: PropTypes.object,
	getPurchasesDashboard: PropTypes.func,
	getSuppliersInfo: PropTypes.func,
	error: PropTypes.string,
	isLoading: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	error: state.Productions.error,
	isLoading: state.Productions.isLoading,
})

const mapDispatchToProps = { getPurchasesDashboard, getSuppliersInfo }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PurchasesDashboardContainer))
