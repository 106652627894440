import { PartConfigTranslations } from './PartConfigTranslations'

export const FIELD_NAMES = [
	'technologyType',
	'materialType',
	'material',
	'alloy',
	'hardness',
	'finish',
	'palette',
	'color',
	'colorFinish',
]

export const updateVisibility = (props) => {
	const FIELD_NAMES = [
		'technologyType',
		'materialType',
		'material',
		'alloy',
		'finish',
		'palette',
		'color',
		'colorFinish',
		'hardness',
	]

	const checkOptionIsVisible = (
		fieldId,
		optionId,
		vis,
	) => {
		return (
			!vis[fieldId] ||
			(vis[fieldId].length > 0 && vis[fieldId].includes(optionId))
		)
	}
	{
		const {
			optionsSelected,
			fieldsVisibilityConditions,
		} = props
		const fields = props.fields
		const fieldsVisible = {
			technologyType: false,
			materialType: false,
			material: false,
			alloy: false,
			finish: false,
			palette: false,
			color: false,
			colorFinish: false,
			hardness: false,
		}
		const fieldsNewVisibility = {
			technologyType: [],
			materialType: [],
			material: [],
			alloy: [],
			finish: [],
			palette: [],
			color: [],
			colorFinish: [],
			hardness: [],
		}
		fieldsNewVisibility.technologyType = Object.keys(fields.technologyType.options)
			.map(optionId => parseInt(optionId))
		fieldsVisible.technologyType = true
		Object.keys(optionsSelected).forEach((fieldName) => {
			const optSel = optionsSelected[fieldName]
			if (optSel && fieldsNewVisibility[fieldName].includes(optSel)) {
				const makesTheseVisible = fieldsVisibilityConditions[fieldName]?.filter((x) => x.oneOfTheseIsSelected.includes(optSel))
				makesTheseVisible?.forEach((b) => {
					Object.keys(b.theseAreVisible).forEach((y) => {
						const these = b.theseAreVisible[y]
						if (these) {
							fieldsNewVisibility[y] = [...fieldsNewVisibility[y], ...these]
							fieldsVisible[y] = true
						}
					})
				})
			}
		})
		const fieldIsVisibleButNotSpecified = {
			technologyType: false,
			materialType: false,
			material: false,
			alloy: false,
			finish: false,
			palette: false,
			color: false,
			colorFinish: false,
			hardness: false,
		}
		const allFieldsSpecified = !Object.keys(fieldsVisible).find((fieldName) => {
			const optSel = optionsSelected[fieldName]
			fieldIsVisibleButNotSpecified[fieldName] = (
				fieldsVisible[fieldName] === true &&
				(optSel == null ||
					optSel === null ||
					!checkOptionIsVisible(fieldName, optSel, fieldsNewVisibility))
			)
			return fieldIsVisibleButNotSpecified[fieldName]
		})
		const configurationSelectedReal = {}
		Object.keys(fieldsNewVisibility).map((fieldName) => {
			const optSelected = optionsSelected[fieldName]
			if (optSelected && fieldsNewVisibility && fieldsNewVisibility[fieldName].includes(optSelected)) {
				configurationSelectedReal[fieldName] = optSelected
			}
		})
		let hideAllFieldsAfterThisOne = false
		for (const key of FIELD_NAMES) {
			if (hideAllFieldsAfterThisOne) fieldsNewVisibility[key] = []
			else if (fieldIsVisibleButNotSpecified[key]) hideAllFieldsAfterThisOne = true
		}
		const finalFieldsVisible = []
		Object.keys(fieldsNewVisibility).forEach(fieldName => {
			if (fieldsNewVisibility[fieldName].length > 0) {
				finalFieldsVisible.push({
					fieldName: fieldName,
					optionIds: fieldsNewVisibility[fieldName],
				})
			}
		})
		return {
			fieldsVisible: finalFieldsVisible,
			allFieldsSpecified,
			configurationSelectedReal,
		}
	}
}

export const getPartMaterial = ({ part, partConfigOptions, language }) => {
	return (<div className='d-flex flex-column'>
		<span>{
			PartConfigTranslations.getMaterialName({ part, partConfigOptions, language })
		}</span>
		<span>
			{PartConfigTranslations.getAlloyName({ part, partConfigOptions, language })
			}</span>
		<span>
			{PartConfigTranslations.getHardnessName({ part, partConfigOptions })}
		</span>
	</div>)
}

export const getPartFinish = ({ part, partConfigOptions, language }) => {
	return (<div className="d-flex flex-column">
		<span>
			{PartConfigTranslations.getFinishName({ part, partConfigOptions, language })}
		</span>
		<span>
			{PartConfigTranslations.getPaletteName({ part, partConfigOptions })}
		</span>
		<span>
			{PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language })}
		</span>
	</div>)
}

export const getFullPartDescription = (part, partConfigOptions, language) => {
	return `${PartConfigTranslations.getMaterialName({ part, partConfigOptions, language })}
	${PartConfigTranslations.getAlloyName({ part, partConfigOptions, language })}
	${PartConfigTranslations.getHardnessName({ part, partConfigOptions })}
	${PartConfigTranslations.getFinishName({ part, partConfigOptions, language })}
	${PartConfigTranslations.getPaletteName({ part, partConfigOptions })}
	${PartConfigTranslations.getColorName({ part, partConfigOptions, language })}
	${PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language })}`

}
