import { findPostponedPaymentMethod, getPostponedPaymentMethodsTranslated } from 'common/Utils/PaymentMethodUtils'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import Switch from 'components/Common/Switch/Switch'
import TextInput from 'components/Common/TextInput'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updatePaymentData } from 'store/actions'

const ibanFields = [
	{ name: 'cCountry', length: 2 },
	{ name: 'dctrl', length: 2 },
	{ name: 'entity', length: 4 },
	{ name: 'office', length: 4 },
	{ name: 'dctrl2', length: 2 },
	{ name: 'accountNumber', length: 10 }]

export const ModalUpdatePaymentMethod = ({ t, propsUserData, propsOrganizationData, updatePaymentData }) => {

	const [paymentData, setPaymentData] = useState({
		paymentMethod: null,
		paymentPostponed: false,
		amountAwarded: '',
		postponement: null,
		firstPaymentDay: '',
		secondPaymentDay: '',
		ownerName: '',
		administrationName: '',
		administrationEmail: '',
		cCountry: '',
		dctrl: '',
		entity: '',
		office: '',
		dctrl2: '',
		accountNumber: '',
	})

	const [isModalOpen, setIsModalOpen] = useState(false)

	const updatePaymentDataState = (key, value) => {

		setPaymentData({ ...paymentData, [key]: value })
	}

	const getAllPostponementOptions = () => {
		return [{
			label: `15  ${t('days', { ns: 'naming' })}`,
			value: 15,
		}, {
			label: `30 ${t('days', { ns: 'naming' })}`,
			value: 30,
		},
		{
			label: `60 ${t('days', { ns: 'naming' })}`,
			value: 60,
		},
		{
			label: `90 ${t('days', { ns: 'naming' })}`,
			value: 90,
		},
		]
	}

	const findPostponement = () => {
		const options = getAllPostponementOptions()
		const option = options.find((option) => option.value == paymentData.postponement)
		return option
	}

	const getFirstPaymentDayOptions = () => {
		const array = Array.from({ length: 30 }, (_, i) => { return { label: i + 1, value: i + 1 } })
		return [{ label: t('billing_date', { ns: 'naming' }), value: 'billing' }, ...array]
	}

	const findFirstPaymentDay = () => {
		const options = getFirstPaymentDayOptions()
		const option = options.find((option) => option.value == paymentData.firstPaymentDay)
		return option
	}

	const getSecondPaymentDayOptions = () => {
		const array = Array.from({ length: 30 }, (_, i) => { return { label: i + 1, value: i + 1 } })
		return [
			{
				label: t('not_pertain', { ns: 'naming' }),
				value: 'not_pertain',
			},
			{
				label: t('billing_date', { ns: 'naming' }),
				value: 'billing',
			}, ...array,
		]
	}

	const findSecondPaymentDay = () => {
		const options = getSecondPaymentDayOptions()
		const option = options.find((option) => option.value == paymentData.secondPaymentDay)
		return option
	}

	useEffect(() => {
		if (propsUserData.paymentMethod != null) {
			setPaymentData(mapPropsToState())
		}
	}, [])

	const saveData = () => {
		if (propsUserData.paymentMethod.id != null) {
			updatePaymentData(mapDataToInputDTO())
			setIsModalOpen(false)
		}
	}

	const mapDataToInputDTO = () => {
		const paymentSettings = {
			id: propsUserData.paymentMethod.id,
			organizationId: propsUserData.organization.id,
			paymentPostponed: paymentData.paymentPostponed,
			paymentMethod: paymentData.paymentMethod,
			contactAdministration: {
				fullName: paymentData.administrationName,
				email: paymentData.administrationEmail,
			},
			postponement: {
				daysPostponed: paymentData.postponement,
				firstPaymentDay: paymentData.firstPaymentDay,
				secondPaymentDay: paymentData.secondPaymentDay,
			},
			directDebitAccount: {
				ownerName: paymentData.ownerName,
				cCountry: paymentData.cCountry,
				dCtrl: paymentData.dctrl,
				entity: paymentData.entity,
				office: paymentData.office,
				dCtrl2: paymentData.dctrl2,
				nAccount: paymentData.accountNumber,
			},
			amountAwarded: paymentData.amountAwarded,
		}
		return {
			data: {
				paymentSettings,
				dataForEmail: {
					organization: propsUserData.organization,
					account: propsUserData.account,
					postponedPaymentSettings: paymentSettings,
					postponedPaymentSettings_translated: {
						postponedPaymentMethod: t(`postponed_payment_type_${paymentSettings.paymentMethod}`, { ns: 'postponedPaymentType' }),
						postponement: {
							daysPostponed: `${paymentSettings.postponement.daysPostponed}  ${t('days', { ns: 'naming' })}`,
							firstPaymentDay: t(paymentSettings.postponement.firstPaymentDay, { ns: 'naming' }),
							secondPaymentDay: t(paymentSettings.postponement.secondPaymentDay, { ns: 'naming' }),
						},
					},
				},
			},
		}
	}

	const mapPropsToState = () => {
		const { paymentMethod, paymentPostponed, postponement, amountAwarded, contactAdministration, directDebitAccount } = propsUserData.paymentMethod
		return {
			paymentMethod,
			paymentPostponed,
			amountAwarded,
			postponement: postponement.daysPostponed,
			firstPaymentDay: postponement.firstPaymentDay,
			secondPaymentDay: postponement.secondPaymentDay,
			ownerName: directDebitAccount.ownerName,
			administrationName: contactAdministration.fullName,
			administrationEmail: contactAdministration.email,
			cCountry: directDebitAccount.cCountry,
			dctrl: directDebitAccount.dCtrl,
			entity: directDebitAccount.entity,
			office: directDebitAccount.office,
			dctrl2: directDebitAccount.dCtrl2,
			accountNumber: directDebitAccount.nAccount,
		}
	}

	return (
		<div>
			<div className='cursor-pointer mt-2' onClick={(e) => setIsModalOpen(true)}>
				<i className='text-primary fs-4 bx bx-edit' ></i>
			</div>
			<Modal
				isOpen={isModalOpen}
				title={t('updateUser', { ns: 'naming' })}
				body={
					<div className='p-4'>
						<OptionsInput
							title={t('paymentMethod', { ns: 'naming' })}
							value={findPostponedPaymentMethod(t, paymentData.paymentMethod)}
							options={getPostponedPaymentMethodsTranslated(t)}
							setValue={updatePaymentDataState}
							objectElement="paymentMethod"
						/>
						<div className='m-2'>
							<h6>{t('postponedPayment', { ns: 'naming' })}</h6>
							<div className='d-flex'>
								<i className='mt-2 fs-1 text-red bx bx-x'></i>
								<Switch
									value={paymentData.paymentPostponed}
									className="fs-2 mt-1 ms-2"
									onChange={e => updatePaymentDataState('paymentPostponed', !paymentData.paymentPostponed)}
								></Switch>
								<i className='mt-2 fs-1 text-success bx bx-check'></i>
							</div>
						</div>
						<TextInput
							value={paymentData.amountAwarded}
							setValue={updatePaymentDataState}
							title={t('amountAwarded', { ns: 'naming' })}
							objectElement="amountAwarded"
							type='number'
						/>
						<div className='my-2'>
							<OptionsInput
								title={t('postponement', { ns: 'naming' })}
								value={findPostponement(paymentData.postponement)}
								options={getAllPostponementOptions()}
								setValue={updatePaymentDataState}
								objectElement="postponement"
							/>
						</div>
						<div className='my-2'>
							<OptionsInput
								title={t('firstPaymentDay', { ns: 'naming' })}
								value={findFirstPaymentDay(paymentData.firstPaymentDay)}
								options={getFirstPaymentDayOptions()}
								setValue={updatePaymentDataState}
								objectElement="firstPaymentDay"
							/>
						</div>
						<div className='my-2'>
							<OptionsInput
								title={t('secondPaymentDay', { ns: 'naming' })}
								value={findSecondPaymentDay(paymentData.secondPaymentDay)}
								options={getSecondPaymentDayOptions()}
								setValue={updatePaymentDataState}
								objectElement="secondPaymentDay"
							/>
						</div>
						<TextInput
							value={paymentData.ownerName}
							setValue={updatePaymentDataState}
							title={t('titularName', { ns: 'naming' })}
							objectElement="ownerName"
						/>
						<div>
							<h6 className='mx-2'>{t('iban', { ns: 'naming' })}</h6>
							<div className='d-flex flex-row'>
								<div className='d-flex justify-content-start'>
									<TextInput
										value={paymentData.cCountry}
										setValue={updatePaymentDataState}
										title={t('cCountry', { ns: 'naming' })}
										objectElement="cCountry"

									/>
								</div>
								<div className='me-1'>
									<TextInput
										value={paymentData.dctrl}
										setValue={updatePaymentDataState}
										title={t('dctrl', { ns: 'naming' })}
										objectElement="dctrl"

									/>
								</div>
								<div className='me-1'>
									<TextInput
										value={paymentData.entity}
										setValue={updatePaymentDataState}
										title={t('entity', { ns: 'naming' })}
										objectElement="entity"

									/>
								</div>
								<div className='me-1'>
									<TextInput
										value={paymentData.office}
										setValue={updatePaymentDataState}
										title={t('office', { ns: 'naming' })}
										objectElement="office"

									/>
								</div>
								<div className='me-1'>
									<TextInput
										value={paymentData.dctrl2}
										setValue={updatePaymentDataState}
										title={t('dctrl2', { ns: 'naming' })}
										objectElement="dctrl2"

									/>
								</div>
							</div>
						</div>

						<TextInput
							value={paymentData.accountNumber}
							setValue={updatePaymentDataState}
							title={t('accountNumber', { ns: 'naming' })}
							objectElement="accountNumber"

						/>

						<TextInput
							value={paymentData.administrationName}
							setValue={updatePaymentDataState}
							title={t('administrationPerson', { ns: 'naming' })}
							objectElement="administrationName"
						/>
						<TextInput
							value={paymentData.administrationEmail}
							setValue={updatePaymentDataState}
							title={t('administrationEmail', { ns: 'naming' })}
							objectElement="administrationEmail"
						/>
					</div>}
				buttons={[
					<button key={'a'} onClick={(e) => saveData()} className='btn btn-primary'>{t('save', { ns: 'naming' })}</button>,
				]}
				closeModal={() => setIsModalOpen(false)}
			/>
		</div>
	)
}

ModalUpdatePaymentMethod.propTypes = {
	propsUserData: PropTypes.object,
	propsOrganizationData: PropTypes.object,
	t: PropTypes.func,
	updatePaymentData: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = { updatePaymentData }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalUpdatePaymentMethod))
