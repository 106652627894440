import StlViewerModal from 'pages/Offer/Modals/StlViewerModal'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'

export const PartThumbnail = ({ stlData, partsFiles, propsImageUrl, propsStlUrl }) => {

	const partId = stlData?.partId

	const [isSTLViewerModalOpen, setIsSTLViewerModalOpen] = useState(false)

	const [imageUrl, setImageUrl] = useState(null)
	const [stlInfo, setStlInfo] = useState({
		modelSrc: null,
		partId: null,
		size: null,
		weight: null,
		partName: null,
	})
	useEffect(() => {
		if (propsImageUrl == null && propsStlUrl == null) {
			if (partsFiles && partsFiles.parts != null && partsFiles.parts.length > 0) {
				const fileData = getFilesData(partId)
				if (fileData != null) {
					setImageUrl(fileData.fileLinks.thumbnail)
					setStlInfo(getStlInfo(fileData.fileLinks.stlModel))
				}
			}
		} else {
			setImageUrl(propsImageUrl)
			setStlInfo(getStlInfo(propsStlUrl))
		}
	}, [partsFiles])

	const getStlInfo = (stlUrl) => {
		return {
			...stlData,
			modelSrc: stlUrl,
		}
	}

	const getFilesData = partId => {
		return partsFiles.parts.find(part => parseInt(part.id) === partId)
	}

	const closeModal = () => {
		setIsSTLViewerModalOpen(false)
	}

	const openStlViewerModal = () => {
		if (stlInfo.modelSrc != null) {
			setIsSTLViewerModalOpen(true)
		}
	}

	return (
		<div className='d-flex justify-content-start cursor-pointer' onClick={(e) => openStlViewerModal()}>
			<StlViewerModal
				isSTLViewerModalOpen={isSTLViewerModalOpen}
				closeSTLViewerModal={closeModal}
				stlViewerData={stlInfo} />
			{imageUrl != null ?
				<img src={imageUrl} className='' height={100} width={75} />
				: <p className='fs-2 m-0'><i className='bx bx-camera-off'></i></p>}
		</div>
	)

}

const mapStateToProps = state => {
	return {
		errorCloud: state.CloudStorage.error,
		partsFiles: state.CloudStorage.partsFiles,
		isLoadingCloud: state.CloudStorage.isLoading,
	}
}

PartThumbnail.propTypes = {
	stlData: PropTypes.object,
	partsFiles: PropTypes.array,
	propsImageUrl: PropTypes.string,
	propsStlUrl: PropTypes.string,
}

export default connect(mapStateToProps, {})(PartThumbnail)
