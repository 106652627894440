import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getManagers, getOrderDetailById, getQualityProblems, getQualityQuantities, getQualitySolutions, getSuppliers } from 'store/actions'
import barcodeScanner from '../../../assets/images/logistic/barcodeScanner.png'
import Loader from '../../../components/Common/Loader'
import SelectedOrder from './SelectedOrder'

const OrderInfo = ({
	t,
	selectedOrderId,
	isLoadingOrders,
	scannedOrderList,
	getQualityQuantities,
	getQualityProblems,
	getQualitySolutions,
	getSuppliers,
	getManagers,
	partConfigOptions,
}) => {
	const [orderInfo, setOrderInfo] = useState({})

	useEffect(() => {
		if (Object.keys(scannedOrderList).length > 0) {
			const selectedScannedOrder = scannedOrderList?.find(scannedOrder => scannedOrder.order?.id == selectedOrderId)
			if (!selectedScannedOrder) return
			setOrderInfo(selectedScannedOrder)
		}
	}, [selectedOrderId, scannedOrderList])

	useEffect(() => {
		getQualityQuantities()
		getQualityProblems()
		getQualitySolutions()
		getSuppliers()
		getManagers()
	}, [])

	return (
		<>
			{!selectedOrderId ? (
				<div
					className="d-flex flex-column align-content-center text-center bg-transparent"
					style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}
				>
					<h3 className="mt-4">{t('scanPiece', { ns: 'naming' })}</h3>
					<img
						src={barcodeScanner}
						alt="barcodeScanner"
						style={{ height: '500px', width: '100%', objectFit: 'contain', background: 'transparent' }}
					/>
				</div>
			) : (
				<>
					{isLoadingOrders ? (
						<div className="d-flex justify-content-center">
							<Loader className="fs-1 p-4" />
						</div>
					) : (
						<SelectedOrder orderInfo={orderInfo} partConfigOptions={partConfigOptions} />
					)}
				</>
			)}
		</>
	)
}

const mapStateToProps = state => {
	return {
		orderDetail: state.Orders.orderDetail,
		isLoadingOrders: state.Orders.isLoading,
		scannedOrderList: state.Orders.scannedVerificationOrderList,
		partConfigOptions: PropTypes.object,
	}
}

OrderInfo.propTypes = {
	t: PropTypes.func,
	selectedOrderId: PropTypes.number,
	orderDetail: PropTypes.object,
	getOrderDetailById: PropTypes.func,
	isLoadingOrders: PropTypes.bool,
	scannedOrderList: PropTypes.array,
	getQualityQuantities: PropTypes.func,
	getQualityProblems: PropTypes.func,
	getQualitySolutions: PropTypes.func,
	getSuppliers: PropTypes.func,
	getManagers: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

export default withGetPartConfigOptions(connect(mapStateToProps, { getOrderDetailById, getQualityQuantities, getQualityProblems, getQualitySolutions, getSuppliers, getManagers })(withTranslation()(OrderInfo)))
