import PropTypes from 'prop-types'

export const ProfileItem = ({
	iconClassname,
	label,
	value,

}) => {
	return (
		<div className="d-flex my-4 ">
			<i className={iconClassname + ' m-2 mt-1 fs-1'} />
			<div className="d-flex flex-column w-75">
				<strong>{label}</strong>
				<span className="m-0 p-0 end-ellipsis w-100">
					{value}
				</span>
			</div>
		</div>
	)
}

ProfileItem.propTypes = {
	iconClassname: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.string,

}
