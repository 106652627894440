import TooltipMessage from 'components/Common/TooltipMessage'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
const ActionButtons = ({
	t,
	orderId,
	handleClickModal,
}) => {
	return (
		<div>
			<TooltipMessage message={t('printDeliveryNote', { ns: 'naming' })}>
				<i
					className="bx bxs-truck fs-4 cursor-pointer
				 p-1 mx-3"
					onClick={handleClickModal}
				></i>
			</TooltipMessage>
		</div>
	)
}

ActionButtons.propTypes = {
	t: PropTypes.func,
	orderId: PropTypes.number,
	handleClickModal: PropTypes.func,
}

export default withTranslation()(ActionButtons)
