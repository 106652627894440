import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import OptionsSelector from 'components/Common/OptionsSelector'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getUsersDashboard } from 'store/actions'
import { getPreviousDateRange } from '../utils'
import UsersDashboard from './UsersDashboard'
import UsersSideBar from './UsersSideBar'

export const UsersDashboardContainer = ({ dateRange, filters, t, getUsersDashboard, error, isLoading }) => {

	const CHART_OPTIONS = [{ label: 'offers', value: true, activeColorClassName: 'offer-option-selector' }, { label: 'orders', value: false }]

	const [isOffers, setIsOffers] = useState(true)

	useEffect(() => {
		if (dateRange != null) {
			const previousDateRange = getPreviousDateRange(dateRange)
			const data = {
				previousDateRange,
				dateRange,
				filters,
				isOffers,
			}
			getUsersDashboard(data)
		}
	}, [dateRange, filters, isOffers])

	return (
		<div style={{ height: '44rem' }} className='d-flex justify-content-center w-100 align-items-center'>
			{error ? <div className='h-auto mt-4'><Alert message={error} type='danger' /></div> : isLoading ? <Loader className='font-size-50' /> :
				<div className='d-flex col justify-content-center mt-4'>
					<div className='col-10 mx-2 d-flex flex-column h-100'>
						<div className='d-flex justify-content-end me-4'>
							<OptionsSelector options={CHART_OPTIONS} setValue={setIsOffers} value={isOffers} activeColorClassName='user-option-selector' />
						</div>
						<div>
							<UsersDashboard dateRange={dateRange} isOffers={isOffers} />
						</div>
					</div>
					<div className='col-1 ms-2 d-flex align-items-center '>
						<UsersSideBar dateRange={dateRange} isOffers={isOffers} />
					</div>
				</div>
			}
		</div>
	)
}

UsersDashboardContainer.propTypes = {
	dateRange: PropTypes.object,
	filters: PropTypes.object,
	error: PropTypes.string,
	getUsersDashboard: PropTypes.func,
	isLoading: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	error: state.Users.error,
	isLoading: state.Users.isLoadingDashboard,
})

const mapDispatchToProps = { getUsersDashboard }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UsersDashboardContainer))
