import { getSectorKeyValuePair, getSectorOptionsTranslated } from 'common/Utils/ActivitySectorUtils'
import { findManager, mapManagers } from 'common/Utils/ManagersUtils'
import { getClientMarginKeyValuePair, getClientMarginOptions } from 'common/Utils/clientMarginUtils'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import TextInput from 'components/Common/TextInput'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateOrganizationCommonData } from 'store/actions'

export const ModalUpdateOrganization = ({ propsUserData, t, clientMargin, managers, updateOrganizationCommonData }) => {

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [organizationData, setOrganizationData] = useState({
		organizationName: '',
		vat: '',
		managerId: null,
		clientMargin: null,
		activitySector: null,
	})

	const updateOrganizationDataState = (key, value) => {
		setOrganizationData({ ...organizationData, [key]: value })
	}

	useEffect(() => {
		if (propsUserData.organization != null) {
			setOrganizationData({
				organizationName: propsUserData.organization.organizationName,
				vat: propsUserData.organization.vat,
				managerId: propsUserData.organization.managerId,
				clientMargin: propsUserData.organization.customerRate,
				activitySector: propsUserData.organization.activitySector,
			})
		}
	}, [propsUserData])

	const saveData = () => {
		if (propsUserData.organization.id != null) {
			updateOrganizationCommonData(mapData())
			setIsModalOpen(false)
		}
	}

	const mapData = () => {
		return {
			data: {
				organization: {
					id: propsUserData.organization.id,
					vat: organizationData.vat,
					organizationName: organizationData.organizationName,
					customerRate: organizationData.clientMargin,
					managerId: organizationData.managerId,
					activitySector: organizationData.activitySector,
				},
				email: propsUserData.account.loginCredentials.email,
			},
		}
	}

	return (
		<div>
			<div className='cursor-pointer mt-2' onClick={(e) => setIsModalOpen(true)}>
				<i className='text-primary fs-4 bx bx-edit' ></i>
			</div>
			<Modal
				isOpen={isModalOpen}
				title={t('updateOrganization', { ns: 'naming' })}
				closeModal={() => setIsModalOpen(false)}
				body={
					<div className='p-4'>
						<TextInput
							value={organizationData.vat}
							setValue={updateOrganizationDataState}
							title={t('vat', { ns: 'naming' })}
							objectElement="vat"
						/>
						<TextInput
							value={organizationData.organizationName}
							setValue={updateOrganizationDataState}
							title={t('organizationName', { ns: 'naming' })}
							objectElement="organizationName"
						/>
						<div className='my-2'>
							{
								managers.length > 0 &&
								<OptionsInput
									title={t('manager', { ns: 'naming' })}
									value={findManager(managers, organizationData.managerId)}
									options={mapManagers(managers)}
									setValue={updateOrganizationDataState}
									objectElement="managerId"
								/>
							}
						</div>
						<div className='my-2'>
							{
								clientMargin.length > 0 &&
								<OptionsInput
									title={t('classificationCommercial', { ns: 'naming' })}
									value={getClientMarginKeyValuePair(clientMargin, organizationData.clientMargin)}
									options={getClientMarginOptions(clientMargin)}
									setValue={updateOrganizationDataState}
									objectElement="clientMargin"
								/>
							}
						</div>
						<div className='my-2'>
							<OptionsInput
								title={t('activity_sector', { ns: 'naming' })}
								value={getSectorKeyValuePair(t, organizationData.activitySector)}
								options={getSectorOptionsTranslated(t)}
								setValue={updateOrganizationDataState}
								objectElement="activitySector"
							/>
						</div>
					</div>
				}
				buttons={[
					<button key={'a'} onClick={(e) => saveData()} className='btn btn-primary'>{t('save', { ns: 'naming' })}</button>,
				]}
			/>
		</div>
	)
}

ModalUpdateOrganization.propTypes = {
	propsUserData: PropTypes.object,
	t: PropTypes.func,
	getClientMargin: PropTypes.func,
	clientMargin: PropTypes.array,
	managers: PropTypes.array,
	updateOrganizationCommonData: PropTypes.func,
}

const mapStateToProps = (state) => ({
	clientMargin: state.Quotes.clientMargin,
	managers: state.Users.managers,
})

const mapDispatchToProps = {
	updateOrganizationCommonData,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalUpdateOrganization))
