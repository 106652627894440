import { roundCurrency } from 'common/Utils/NumberUtilities'
import { createCSV, getDate } from 'common/Utils/StringUtilities'
import { downloadCsv } from 'common/Utils/downloadUtils/downloadCsv'
import Alert from 'components/Common/Alert'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import Table from 'components/Table/Table'
import AppRoutes from 'constants/appRoutes'
import i18n from 'i18n'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { Flag } from 'semantic-ui-react'
import { getCrmUsers, getManagers } from 'store/actions'
import FilterModal from './FilterModal'
import ModalCreateUser from './ModalCreateUser'

const UserList = ({ userList, managers, getCrmUsers, getManagers, isLoading, t, error }) => {
	//TODO REVIEW THIS PAGE
	const PAGE_SIZE_LIST = [
		{ value: 50, text: '50' },
		{ value: 100, text: '100' },
		{ value: 200, text: '200' },
		{ value: 500, text: '500' },
		{ value: 1000, text: '1000' },
	]

	const headerToRender = {
		id: 'ID',
		firstName: 'Nombre',
		lastName: 'Apellido',
		email: 'Email',
		ranking: 'Ranking',
		country: 'País',
		domain: 'Dominio',
		role: 'Rol',
		organizationName: 'Organización',
		manager: 'Manager',
		paymentPostponement: 'Pago aplazado',
		province: 'Provincia',
		sector: 'Sector',
		registerDate: 'Fecha de registro',
		lastLogin: 'Último login',
		totalOffers: 'Ofertas totales',
		last12MonthsOffers: 'Ofertas últimos 12 meses',
		totalBilling: 'Facturación total',
		last12MonthsBilling: 'Facturación últimos 12 meses',
		totalOrders: 'Pedidos totales',
		last12MonthsOrders: 'Pedidos últimos 12 meses',
		phoneNumber: 'Teléfono',
	}

	const [itemsToRender, setItemsToRender] = useState([])

	const getCustomersAndDomainCount = () => {
		if (itemsToRender.length === 0) return { count: 0, domainsCount: 0 }
		const diferentDomains = new Set(itemsToRender.map((item) => item.domain))
		return { count: itemsToRender.length, domainsCount: diferentDomains.size }
	}

	const { count, domainsCount } = useMemo(() => getCustomersAndDomainCount(), [itemsToRender])

	useEffect(() => {
		if (userList.length === 0) getCrmUsers()
		if (managers.length === 0) getManagers()
	}, [])

	useEffect(() => {
		if (userList.length > 0) {
			const listSorted = sortUserList(userList)
			setItemsToRender(mapItemsToRender(listSorted))
		}
	}, [userList, i18n.language])

	const sortUserList = (userList) => {
		return userList.sort((a, b) => {
			if (a.registerDate > b.registerDate) return -1
			if (a.registerDate < b.registerDate) return 1
			return 0
		})
	}

	const getManagerName = (manager) => {
		if (manager) {
			return manager?.personalInformation?.firstName + ' ' + manager?.personalInformation?.lastName
		}
		return ''
	}

	const mapItemsToRender = (users) => {
		return users.map((user) => {
			const manager = managers.find((manager) => manager.id === user.managerId)
			const managerName = getManagerName(manager)
			return {
				id: user.id,
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				ranking: roundCurrency(user.purchaseStats?.stats?.ranking, 2),
				domain: user.purchaseStats?.domain || user.email.split('@')[1],
				role: t('account_user_role_' + user.role, { ns: 'userRoles' }),
				organizationName: user.organizationName,
				manager: managerName,
				paymentPostponement: user.paymentPostponement ? <i className='fs-4 text-success bx bx-check'></i> : <i className='fs-4 text-red bx bx-x'></i>,
				province: user.province,
				country: <Flag name={user.country?.toLowerCase()} />,
				sector: user.sectorId && t('activity_sector_' + user.sectorId, { ns: 'activitySectors' }),
				registerDate: getDate(user.registerDate),
				lastLogin: getDate(user.lastLoginDate),
				totalOffers: (user.purchaseStats?.stats?.totalOffers).toString(),
				last12MonthsOffers: (user.purchaseStats?.stats?.last12MonthsOffers).toString(),
				totalBilling: roundCurrency(user.purchaseStats?.stats?.totalBilling),
				last12MonthsBilling: roundCurrency(user.purchaseStats?.stats?.last12MonthsBilling),
				totalOrders: (user.purchaseStats?.stats?.totalOrders).toString(),
				last12MonthsOrders: (user.purchaseStats?.stats?.last12MonthsOrders).toString(),
				phoneNumber: user.phoneNumber,
			}
		})
	}

	const applyFilters = (userList) => {
		setItemsToRender(mapItemsToRender(userList))
	}
	return (
		<div className='page-content margin-footer'>
			<MetaTags>
				<title>Proto&Go! | {t('clients', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title="CRM"
				breadcrumbItems={[
					{
						item: 'Clientes',
						link: '/crm-user-list',
					},
				]}
			/>
			<div className='bg-white p-4'>

				{isLoading ? <div className='d-flex justify-content-center'><Loader className={'fs-1'} /></div>
					: error ? <Alert
						type="danger"
						centered
						fitContent
						message={error.message}
					/> :
						<div className='pb-4'>
							<div className='w-100 mb-2'>{!isLoading &&
								<div className='d-flex justify-content-between'>
									<div className='d-flex flex-row'>
										<div className='w-auto'>
											<FilterModal userList={userList} t={t} applyFilters={applyFilters} />
										</div>
										<div className='mx-2 '>
											<button
												className="btn btn-secondary"
												onClick={() =>
													downloadCsv(
														createCSV(
															headerToRender,
															itemsToRender,
														),
														'file.csv',
														'text/plain;charset=utf-8',
													)
												}
											>
												Exportar
											</button>
										</div>
									</div>
									<div className='d-flex flew-row'>
										<div>
											<ModalCreateUser isCustomer userList={userList} />
										</div>
										<div>
											<ModalCreateUser userList={userList} />
										</div>
									</div>
								</div>
							}   </div>
							{itemsToRender.length > 0 &&
								<div>
									<Table
										counters={[{ label: 'users', value: count }, { label: 'companies', value: domainsCount }]}
										hiddenFields={['phoneNumber']}
										header={headerToRender}
										items={itemsToRender}
										selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
										showActiveColumFilter={true}
										language="es"
										allowAllItemsPerPage
										pageToNavigate={AppRoutes.CRM_USER_DETAIL_ROUTE_NO_ID}
										styleForFieldList={[
											{
												field: 'id',
												styles: [
													{
														type: 'link',
													},
												],
											},
										]}
									/>
								</div>}
						</div>
				}
			</div>
		</div>
	)
}

UserList.propTypes = {
	userList: PropTypes.object,
	isLoading: PropTypes.bool,
	error: PropTypes.any,
	managers: PropTypes.array,
	getCrmUsers: PropTypes.func,
	getManagers: PropTypes.func,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({
	userList: state.Crm.userList,
	isLoading: state.Crm.isLoading,
	managers: state.Users.managers,
	error: state.Crm.error,
})

const mapDispatchToProps = {
	getCrmUsers,
	getManagers,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserList))
