import AppRoutes from 'constants/appRoutes'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert, Card, CardBody, Col, Container, Label, Row } from 'reactstrap'

// Redux
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

// actions
import { apiError } from '../../store/actions'

import { resetUserPassword, verifyToken } from '../../store/actions'

import BrandLogo from '../../components/Common/BrandLogo'
import Footer from '../../components/Common/Footer'
import Loader from '../../components/Common/Loader'

import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'

class ResetPassword extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isShowingPassword1: false,
			isShowingPassword2: false,
			token: '',
			email: '',
		}
	}

	componentDidMount() {
		this.getParamsFromURL(() => {
			this.props.apiError('')
			this.setToken()

		})
	}
	getParamsFromURL(callback) {
		const params = new URLSearchParams(window.location.search)

		const token = params.get('token')

		this.setState({ ...this.state, token }, () => callback())
	}
	setToken() {
		if (this.state.token != null) localStorage.setItem('authUser', JSON.stringify({ 'token': this.state.token }))
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.message !== this.props.message) {
			this.redirectToLogin(3)
		}
		if (this.state.token !== prevState.token) {
			this.props.verifyToken(this.state.token)
		}
	}

	redirectToLogin(secondsToAwait) {
		if (secondsToAwait <= 0) this.props.history.push(AppRoutes.LOGIN_ROUTE)

		setTimeout(
			() => this.props.history.push(AppRoutes.LOGIN_ROUTE),
			secondsToAwait * 1000,
		)
	}
	getForm() {
		return (
			<span>
				<h1 className="text-primary">Set new password</h1>
				{this.props.error && (
					<Alert color="danger">
						{this.props.error && this.props.error.message
							? this.props.error.message
							: JSON.stringify(this.props.error)}
					</Alert>
				)}
				{this.props.message && (
					<Alert color="success">{this.props.message}</Alert>
				)}

				<Formik
					enableReinitialize={true}
					initialValues={{
						password1: (this.state && this.state.password1) || '',
						password2: (this.state && this.state.password2) || '',
					}}
					validationSchema={Yup.object().shape({
						password1: Yup.string()
							.required('Please Enter Valid Password')
							.min(8, 'Password is too short')
							.max(25, 'Password is too long'),
						password2: Yup.string()
							.required('Please Enter Valid Password')
							.equals(
								[Yup.ref('password1')],
								'Password has been repeated wrongly',
							),
					})}
					onSubmit={values => {
						this.props.resetUserPassword(
							this.state.email,
							values.password1,
							this.state.token,
						)
					}}
				>
					{({ errors, status, touched }) => (
						<Form className="form-horizontal">
							<div className="mb-3">
								<Label for="password1" className="form-label">
									Password
								</Label>
								<div className="input-group auth-pass-inputgroup">
									<Field
										name="password1"
										type={!this.state.isShowingPassword1 ? 'password' : 'text'}
										autoComplete="true"
										className={
											'h-75 form-control' +
											(errors.password1 && touched.password1
												? ' is-invalid'
												: '')
										}
									/>
									<button
										className="w-auto btn btn-light "
										type="button"
										id="password-addon"
										onClick={() =>
											this.setState({
												...this.state,
												isShowingPassword1: !this.state.isShowingPassword1,
											})
										}
									>
										<i className="mdi mdi-eye-outline"></i>
									</button>
								</div>
								<ErrorMessage
									name="password1"
									component="div"
									className="invalid-feedback"
								/>
							</div>
							<div className="mb-3">
								<Label for="password2" className="form-label">
									Repeat Password
								</Label>
								<div className=" input-group auth-pass-inputgroup">
									<Field
										name="password2"
										type={!this.state.isShowingPassword2 ? 'password' : 'text'}
										autoComplete="true"
										className={
											'h-75 form-control' +
											(errors.password2 && touched.password2
												? ' is-invalid'
												: '')
										}
									/>
									<button
										className="w-auto btn btn-light "
										type="button"
										id="password-addon"
										onClick={() =>
											this.setState({
												...this.state,
												isShowingPassword2: !this.state.isShowingPassword2,
											})
										}
									>
										<i className="mdi mdi-eye-outline"></i>
									</button>
								</div>
								<ErrorMessage
									name="password2"
									component="div"
									className="invalid-feedback"
								/>
							</div>

							<div className="text-end">
								<button
									className="btn btn-primary w-md"
									type="submit"
									disabled={
										this.props.isLoading ||
										errors.password1 ||
										!touched.password1 ||
										errors.password2 ||
										!touched.password2 ||
										this.props.message
									}
								>
									{this.props.isLoading ? (
										<Loader className="font-size-18" />
									) : (
										'Set Password'
									)}
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</span>
		)
	}

	getError() {
		this.redirectToLogin(3)
		return <h1 className="text-primary">Invalid token provided.</h1>
	}

	render() {
		return (
			<React.Fragment>
				<MetaTags>
					<title>Proto&Go! | {this.props.t('reset-password', { ns: 'naming' })}</title>
				</MetaTags>
				<div className="home-btn d-none d-sm-block">
					<Link to="/" className="text-dark">
						<i className="bx bx-home h2" />
					</Link>
				</div>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<Card className="overflow-hidden">
									<div className="bg-primary bg-black">
										<BrandLogo />
									</div>
									<CardBody className="pt-0 bg-black text-light">
										{this.props.isVerifyingToken ? (
											<div className="mx-auto width-by-content">
												<Loader className="font-size-80" />
												<p>Checking credentials...</p>
											</div>
										) :
											this.props.error == null ? <div>{this.getForm()}</div> : this.getError()}
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									<Footer />
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		)
	}
}

ResetPassword.propTypes = {
	apiError: PropTypes.any,
	userResetPassword: PropTypes.func,
	verifyToken: PropTypes.func,
	resetUserPassword: PropTypes.func,
	isVerifyingToken: PropTypes.bool,
	errorId: PropTypes.number,
	isLoading: PropTypes.bool,
	error: PropTypes.any,
	message: PropTypes.string,
	history: PropTypes.object,
	t: PropTypes.func,
}

const mapStateToProps = state => {
	return {
		errorId: state.ForgetPassword.errorId,
		isVerifyingToken: state.ForgetPassword.isVerifyingToken,
		isLoading: state.ForgetPassword.isLoading,
		error: state.ForgetPassword.error,
		message: state.ForgetPassword.message,
	}
}

export default withRouter(
	connect(mapStateToProps, { resetUserPassword, verifyToken, apiError })(
		withTranslation()(ResetPassword),
	),
)
