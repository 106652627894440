import PropTypes from 'prop-types'
import { useState } from 'react'
import { connect } from 'react-redux'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

export const DateFilterDropdown = ({ options, handleOnChangeDate, dateRange, title }) => {

	const [isOpen, setIsOpen] = useState(false)

	const isButtonActive = options.find(el => dateRange.startDate === el.dateRange.startDate && dateRange.endDate === el.dateRange.endDate) != null
	const renderInput = option => {
		const isActive = dateRange.startDate === option.dateRange.startDate && dateRange.endDate === option.dateRange.endDate
		return (
			<div
				key={option.optionToRender} className="p-3 list-status-item-filter cursor-pointer d-flex justify-content-center"
				onClick={() => { handleOnChangeDate(option.dateRange) }}
			>
				<div
					className={isActive ? 'border-bottom border-warning' : 'border-bottom border-white'}>
					<div className="mx-2">
						<span className="">{option.optionToRender}</span>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="m-1 d-flex">
			<Dropdown
				isOpen={isOpen}
				size="lg"
				className="w-100"
				toggle={e => setIsOpen(!isOpen)}
			>
				<DropdownToggle color="white" className={
					isButtonActive ? 'btn btn-secondary w-auto' : 'w-auto'
				}>
					{title}
				</DropdownToggle>
				<DropdownMenu data-popper-placement="left-start">
					<div className="my-3 mb-0">
						<div className="d-flex flex-column justify-content-start filter-width">
							{options.map(el => renderInput(el))}
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
		</div>

	)
}

DateFilterDropdown.propTypes = {
	options: PropTypes.array,
	title: PropTypes.string,
	handleOnChangeDate: PropTypes.func,
	dateRange: PropTypes.object,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DateFilterDropdown)
