import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import TextInput from 'components/Common/TextInput'
import Table from 'components/Table/Table'
import { Couriers } from 'constants/couriers'
import { ProductionStatus } from 'constants/productionStatus'
import RealShipmentDate from 'pages/Orders/RealShipmentDate'
import ProductionStatusComponent from 'pages/SupplierProduction/ProductionStatus'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import ReactTooltip from 'react-tooltip'
import { updateProduction } from 'store/actions'

const ProductionTable = ({
	t,
	id,
	status,
	disconformity,
	partialShipment,
	productionDate,
	shipmentDate,
	delayShipmentDate,
	realShipmentDate,
	// chat, --> TODO ProductionChat no funciona todavía
	weight,
	shipmentTracking,
	setShipmentTracking,
	shippingCompany,
	setShippingCompany,
	totalPrice,
	discount,
	discountedPrice,
	updateProduction,
	production,
	productionItem,
	supplierCountry,
	isLoading
}) => {
	const [isOpenShipmentModal, setOpenShipmentModal] = useState(false)
	const [isOpenShipmentDateModal, setOpenShipmentDateModal] = useState(false)
	const [delayedShipmentDate, setDelayedShipmentDate] = useState(delayShipmentDate)
	const [newDelayedShipmentDate, setNewDelayedShipmentDate] = useState(delayShipmentDate)
	const [newShippingCompany, setNewShippingCompany] = useState(shippingCompany)
	const [newShipmentTracking, setNewShipmentTracking] = useState(shipmentTracking)
	const internationalSupplier = supplierCountry.toLowerCase() != 'es'

	useEffect(() => {
		if (!isLoading && window.location.href.includes('modifyDelayShipmentDate')) setOpenShipmentDateModal(true)
	}, [isLoading])


	const header = {
		id: t('id', { ns: 'naming' }).toUpperCase(),
		status: t('status', { ns: 'naming' }).toUpperCase(),
		productionDate: t('productionCreationDate', { ns: 'naming' }).toUpperCase(),
		shipmentDate: t('shipmentDate', { ns: 'naming' }).toUpperCase(),
		// chat: t("chat", { ns: "naming" }).toUpperCase(), --> TODO ProductionChat no funciona todavía
		weight: t('weight', { ns: 'naming' }).toUpperCase(),
		shipmentTracking: t('shipment-tracking', { ns: 'naming' }).toUpperCase(),
		totalPrice: t('total-price', { ns: 'naming' }).toUpperCase(),
	}
	const shippingCompanies = [
		{
			value: Couriers.DHL,
			label: 'DHL',
			maxCharacters: 10,
		},
		{
			value: Couriers.NACEX,
			label: 'NACEX',
			maxCharacters: 8,
		},
		{
			value: Couriers.HAND_DELIVERY,
			label: t('hand-delivery', { ns: 'naming' }),
			maxCharacters: 0,
		},
	]
	const getShippingCompanies = () => {
		if (internationalSupplier) {
			return [shippingCompanies[0]]
		}
		return shippingCompanies
	}
	const NACEX_URL = 'https://www.nacex.es/seguimientoDetalle.do?agencia_origen=0802&numero_albaran='
	const DHL_URL = 'https://mydhl.express.dhl/es/en/tracking.html#/results?id='
	const getTrackingLink = () => {
		switch (shippingCompany) {
			case Couriers.DHL:
				return DHL_URL + shipmentTracking
			case Couriers.NACEX:
				return NACEX_URL + shipmentTracking
			default:
				return ''
		}
	}
	const DENIED_CANCELLED_STATUS = [ProductionStatus.CANCELLED, ProductionStatus.DENIED]
	const items = [
		{
			id: { value: id },
			status: (
				<ProductionStatusComponent
					status={status}
					disconformity={disconformity}
					partialShipment={partialShipment}
				/>
			),
			productionDate: productionDate,
			shipmentDate: (!DENIED_CANCELLED_STATUS.includes(status) &&
				<div className="d-flex flex-row">
					<RealShipmentDate
						date={shipmentDate}
						realDate={realShipmentDate}
						delayDate={delayedShipmentDate}
					/>
					{!realShipmentDate && status !== ProductionStatus.ACCEPTANCE_PENDING && (
						<div>
							<button
								id="1"
								className="btn btn-light mb-2 mx-2"
								data-tip="Click to modify or delay current shipment date"
								data-for="button-tooltip"
								onClick={() => setOpenShipmentDateModal(true)}
							>
								<i className="bx bxs-edit"></i>
								<ReactTooltip
									id="button-tooltip"
									place="top"
									type="info"
									effect="solid"
									backgroundColor="gray"
								/>
							</button>
						</div>
					)}
				</div>
			),
			// chat: <ChatIcon quantityUnread={chat} production={production} />, --> TODO ProductionChat no funciona todavía
			weight: weight > 1000 ? `${roundAtDecimals((weight / 1000), 1)} kg` : `${roundAtDecimals(weight, 0)} g`,
			shipmentTracking: status > ProductionStatus.ACCEPTANCE_PENDING && status != ProductionStatus.DENIED && (
				<div className="d-flex flex-row align-items-center">
					<div className="px-1">
						{shippingCompanies.find(option => option.value === shippingCompany)?.label}
					</div>
					{shipmentTracking && shippingCompany != Couriers.HAND_DELIVERY && <div className="px-1">{shipmentTracking}</div>}
					{(shipmentTracking || shippingCompany) && (
						<div
							className="btn btn-light my-1 mx-1"
							onClick={() => setOpenShipmentModal(true)}
						>
							<i className="bx bxs-edit"></i>
						</div>
					)}
					{shipmentTracking && shippingCompany != Couriers.HAND_DELIVERY && (
						<a
							href={getTrackingLink()}
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-light my-1 mx-1"
							role="button"
						>
							<i className="bx bxs-truck"></i>
						</a>
					)}
				</div>
			),
			totalPrice: discount ? (
				<div>
					<div className="d-flex flex-row">
						<div className="text-decoration-line-through">{totalPrice}</div>
						<div className="mx-2 px-2 bg-red rounded-1 text-white">
							{discount} {t('discount', { ns: 'naming' })}
						</div>
					</div>
					{discountedPrice}
				</div>
			) : (
				totalPrice
			),
		},
	]
	const getSplitProductionId = (id) => {
		const splitId = id.split('-')
		return { orderId: splitId[0], orderFragmentId: splitId[1] }
	}
	const saveTracking = () => {
		setShipmentTracking(newShipmentTracking)
		setShippingCompany(newShippingCompany)
		const { orderId, orderFragmentId } = getSplitProductionId(id)
		const production = {
			orderId: Number(orderId),
			orderFragmentId: Number(orderFragmentId),
			shipmentTrackingNumber: newShipmentTracking,
			courier: newShippingCompany,
		}
		updateProduction({ production })
		setOpenShipmentModal(false)
	}
	const closeWithoutSavingTracking = () => {
		setNewShipmentTracking(shipmentTracking)
		setNewShippingCompany(shippingCompany)
		setOpenShipmentModal(false)
	}
	const saveDelayedShipment = () => {
		setDelayedShipmentDate(newDelayedShipmentDate)
		const { orderId, orderFragmentId } = getSplitProductionId(id)
		const production = {
			orderId: Number(orderId),
			orderFragmentId: Number(orderFragmentId),
			shipmentDateDelay: newDelayedShipmentDate,
		}
		updateProduction({ production })
		setOpenShipmentDateModal(false)
	}
	const closeWithoutSavingDelayedShipment = () => {
		setNewDelayedShipmentDate(delayedShipmentDate)
		setOpenShipmentDateModal(false)
	}
	return (
		<div>
			<Table
				disableInteractions
				header={header}
				items={items}
				selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
				language={'en'}
				rowQuantityList={[{ text: '1', value: 1 }]}
				allowAllItemsPerPage
				defaultSort={{ dataField: 'id', order: 'asc' }}
			/>
			<Modal
				isOpen={isOpenShipmentDateModal}
				closeModal={() => closeWithoutSavingDelayedShipment()}
				title={t('delay_shipment_date', { ns: 'naming' })}
				body={
					<div className="mx-2">
						<Flatpickr
							className="form-control"
							placeholder={'New shipment date'}
							options={{
								dateFormat: 'd/m/Y',
								locale: {
									firstDayOfWeek: 1,
								},
							}}
							value={newDelayedShipmentDate}
							onChange={(e, dateStr) => {
								setNewDelayedShipmentDate(e[0])
							}}
						/>
					</div>
				}
				buttons={[
					<button
						key="newDelayedShipmentDateButton"
						type="button"
						className="btn btn-primary"
						onClick={() => saveDelayedShipment()}
					>
						<span className="p-2">{t('save', { ns: 'naming' })}</span>
					</button>,
				]}
			/>
			<Modal
				isOpen={isOpenShipmentModal}
				closeModal={() => closeWithoutSavingTracking()}
				title={'Shipment Tracking'}
				body={
					<div className="d-flex flex-row justify-content-center">
						<div className="width-7rem my-2">
							<OptionsInput
								isMulti={false}
								value={shippingCompanies.find(option => option.value === newShippingCompany)}
								setValue={setNewShippingCompany}
								options={getShippingCompanies()}
								initialValue={shippingCompanies.find(option => option.value === newShippingCompany)}
							/>
						</div>
						{newShippingCompany != Couriers.HAND_DELIVERY && <div className="my-2">
							<TextInput
								maxLength={
									shippingCompanies.find(option => option.value === newShippingCompany)?.maxCharacters || 0
								}
								placeholder="Tracking ID"
								value={newShipmentTracking}
								setValue={setNewShipmentTracking}
							/>
						</div>}
					</div>
				}
				buttons={[
					<button
						key="newShipmentTrackingButton"
						type="button"
						className="btn btn-primary"
						onClick={() => saveTracking()}
					>
						<span className="p-2">{t('save', { ns: 'naming' })}</span>
					</button>,
				]}
			/>
		</div>
	)
}
const mapStateToProps = state => {
	return {
		isLoading: state.Productions.isLoading,
		partConfigOptions: state.Parts.partConfigOptions,
	}
}
ProductionTable.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	id: PropTypes.string,
	status: PropTypes.number,
	disconformity: PropTypes.bool,
	partialShipment: PropTypes.bool,
	productionDate: PropTypes.string,
	shipmentDate: PropTypes.string,
	delayShipmentDate: PropTypes.string,
	realShipmentDate: PropTypes.string,
	// chat: PropTypes.number, --> TODO ProductionChat no funciona todavía
	weight: PropTypes.number,
	shipmentTracking: PropTypes.string,
	setShipmentTracking: PropTypes.func,
	shippingCompany: PropTypes.string,
	setShippingCompany: PropTypes.func,
	totalPrice: PropTypes.string,
	discount: PropTypes.string,
	discountedPrice: PropTypes.string,
	updateProduction: PropTypes.func,
	production: PropTypes.object,
	productionItem: PropTypes.object,
	supplierCountry: PropTypes.string,
	partConfigOptions: PropTypes.object,
}
export default withRouter(
	connect(mapStateToProps, { updateProduction })(
		withTranslation()(ProductionTable),
	),
)
