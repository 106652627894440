import PropTypes from 'prop-types'
import { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import { hashPassword } from '../../common/Utils/HashingUtilities'

class FileIconWrapper extends Component {
	render() {
		return this.props.src ? (
			<a href={this.props.src} target="_blank" rel="noopener noreferrer">
				{' '}
				{this.props.children}{' '}
			</a>
		) : (
			<span> {this.props.children} </span>
		)
	}
}
class FileIcon extends Component {
	render() {
		const {
			fileExtension,
			fontColor,
			fileBackgroundColor,
			tooltipMessage,
			src,
		} = this.props
		return (
			<FileIconWrapper src={src}>
				<svg
					className="fileIcon"
					viewBox="0 0 210 297"
					version="1.1"
					id="svg5"
					data-tip
					data-for={
						'iconTooltip' +
						hashPassword(
							fileExtension + fontColor + fileBackgroundColor + tooltipMessage,
						)
					}
				>
					<defs id="defs276">
						<rect
							x="-1728.9414"
							y="1988.2826"
							width="2644.4945"
							height="1563.9061"
							id="rect18566"
						/>
						<rect
							x="-385.0824"
							y="2157.2473"
							width="475.45889"
							height="310.42357"
							id="rect13698"
						/>
						<rect
							x="-1595.3414"
							y="1394.9414"
							width="1218.1178"
							height="781.95304"
							id="rect5780"
						/>
						<rect
							x="-1119.8825"
							y="911.62365"
							width="1292.7766"
							height="758.37657"
							id="rect2408"
						/>
					</defs>

					<path
						d="M 127.30128,42.673162 H 41.968036 c -11.776,0 -21.33325,9.55725 -21.33325,21.33325 V 234.67316 c 0,11.776 9.55725,21.33325 21.33325,21.33325 H 169.96803 c 11.776,0 21.33325,-9.55725 21.33325,-21.33325 v -128 z m 0,74.666748 H 116.63453 V 64.006662 l 53.33325,53.333248 h -42.66675 z"
						id="path271"
						style={{
							strokeWidth: '5px',
							stroke: fontColor,
							fill: fileBackgroundColor ? fileBackgroundColor : '#000000',
						}}
					/>

					{fileExtension && (
						<text
							transform="matrix(0.08980162,0,0,0.08980162,175.17973,-23.716385)"
							id="text18564"
							style={{
								fontStyle: 'normal',
								fontWeight: 'normal',
								fontSize: '933.333px',
								lineHeight: '1.25',
								fontFamily: 'sans-serif',
								letterSpacing: '0px',
								whiteSpace: 'pre',
								shapeInside: 'url(#rect18566)',
								fill: '#000000',
								fillOpacity: '1',
								stroke: 'none',
							}}
						>
							<tspan x="-1620" y="2839.7912" id="tspan52221">
								<tspan
									id="tspan52219"
									style={{
										fontFamily: 'poppins, sans-serif',
										fill: fontColor ? fontColor : '#ffffff',
									}}
								>
									{fileExtension.toUpperCase()}
								</tspan>
							</tspan>
						</text>
					)}
				</svg>
				{tooltipMessage && (
					<ReactTooltip
						id={
							'iconTooltip' +
							hashPassword(
								fileExtension + fontColor + fileBackgroundColor + tooltipMessage,
							)
						}
						place="top"
						type="info"
						effect="solid"
					>
						{tooltipMessage}
					</ReactTooltip>
				)}
			</FileIconWrapper>
		)
	}
}

FileIcon.propTypes = {
	fileExtension: PropTypes.string,
	fontColor: PropTypes.string,
	fileBackgroundColor: PropTypes.string,
	tooltipMessage: PropTypes.string,
	src: PropTypes.string,
}

FileIconWrapper.propTypes = {
	children: PropTypes.any,
	src: PropTypes.string,
}

export default FileIcon
