import {
	SEND_UPLOAD_OFFER_EMAIL,
	SEND_UPLOAD_OFFER_EMAIL_FAIL,
	SEND_UPLOAD_OFFER_EMAIL_SUCCESS,
} from './actionTypes'

const INIT_STATE = {
	success: null,
	error: null,
	isLoading: false,
}

const emails = (state = INIT_STATE, action) => {
	switch (action.type) {

		case SEND_UPLOAD_OFFER_EMAIL:
			return {
				...state,
				success: null,
				isLoading: true,
				error: null,
			}
		case SEND_UPLOAD_OFFER_EMAIL_SUCCESS:
			return {
				...state,
				success: true,
				error: null,
				isLoading: false,
			}
		case SEND_UPLOAD_OFFER_EMAIL_FAIL:
			return {
				...state,
				success: false,
				error: action.payload,
				isLoading: false,
			}

		default:
			return state
	}
}

export default emails
