import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'

const DatePickr = ({
	className,
	value,
	setValue,
	title,
	hasPressed,
	format,
	isRequired,
	objectElement,
	mode,
	placeholder,
	cleanData,
	t,
	isDateStr,
}) => {
	const isNullArray = value && Array.isArray(value) && value.filter(x => x != null).length === 0
	return (
		<>
			<div className={`m-2 ${cleanData && 'inside-input-div'}`}>
				{title && (
					<h6>
						{isRequired && <span className="text-primary me-1">✲</span>}
						{title}
					</h6>
				)}

				<Flatpickr
					className={`${hasPressed && isRequired && !value
						? 'form-control is-invalid  bg-white d-block'
						: 'form-control  bg-white d-block'
						} ${className}`}
					placeholder={placeholder}
					options={{
						mode,
						dateFormat: format,
						locale: {
							firstDayOfWeek: 1,
						},
					}}
					value={value}
					onChange={(e, dateStr) => {
						const newValue = isDateStr ? dateStr : e
						objectElement ? setValue(objectElement, newValue) : setValue(newValue)
					}
					}
				/>
				{
					cleanData && value != null && value != [] && !isNullArray &&
					<div className="inside-input-span d-flex align-items-center mb-01 text-secondary">
						<i onClick={() => { objectElement ? cleanData(objectElement) : cleanData() }} className='fs-4 bx bx-x cursor-pointer'></i>
					</div>

				}
				{hasPressed && isRequired && !value && (
					<div>
						<div className="invalid-feedback">
							{t('required_field', { ns: 'naming' })}
						</div>
					</div>
				)}
			</div>
		</>
	)
}

DatePickr.propTypes = {
	className: PropTypes.string,
	format: PropTypes.string,
	isRequired: PropTypes.bool,
	value: PropTypes.any,
	setValue: PropTypes.func,
	title: PropTypes.string,
	hasPressed: PropTypes.bool,
	objectElement: PropTypes.string,
	t: PropTypes.func,
	mode: PropTypes.string,
	cleanData: PropTypes.func,
	placeholder: PropTypes.string,
	isDateStr: PropTypes.bool,
}

export default withTranslation()(DatePickr)
