export const isObjectEmpty = (objectName) => {
	return (
		objectName &&
		Object.keys(objectName).length === 0 &&
		objectName.constructor === Object
	)
}

export const hasMandatoryFields = (mandatoryFields, data) => {
	const arrayData = Object.entries(data)
	const emptyMandatoryFields = arrayData.filter(([key, value]) => mandatoryFields.includes(key) && !value)
	return emptyMandatoryFields.length === 0
}

export const areObjectsEqual = (objA, objB) => {
	return Object.keys(objA).every(key => objA[key] === objB[key])
}

export const removeEmptyProperties = (obj) => {
	const result = {}
	for (const key in obj) {
		if (obj[key] != null) {
			result[key] = obj[key]
		}
	}
	return result
}

export const allFiltersAreEmpty = (keyword, objectData) => {
	const keywordEmpty = keyword === ''
	const filterObject = Object.entries(objectData)

	for (const [key, value] of filterObject) {
		if (typeof value === 'object') {
			if (Array.isArray(value) && value.filter(el => el != null).length > 0) {
				return false
			} else {
				const elementObject = Object.entries(value)
				if (elementObject.some(([_, itemValue]) => itemValue != null)) {
					return false
				}
			}
		} else if (value != null) {
			return false
		}
	}

	return keywordEmpty
}
