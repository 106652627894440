import { getIdFromUrl } from 'common/Utils/StringUtilities'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { getLogInAsToken } from 'store/actions'

export const LogInAs = ({ token, getLogInAsToken, t, history }) => {
	useEffect(() => {
		if (token != null) {
			window.location.href = process.env.REACT_APP_LOGINAS_URL + token
		}
	}, [token])
	return (
		<div className='cursor-pointer' onClick={() => { getLogInAsToken(getIdFromUrl()) }}>
			<span className='text-blue'>
				{t('logInAs', { ns: 'naming' })}
			</span>
		</div>
	)
}

LogInAs.propTypes = {
	token: PropTypes.string,
	getLogInAsToken: PropTypes.func,
	t: PropTypes.func,
	history: PropTypes.func,
}

const mapStateToProps = (state) => ({
	token: state.Crm.logInAsToken,
})

const mapDispatchToProps = {
	getLogInAsToken,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LogInAs)))
