import ApexRadial from 'components/Common/Charts/ApexRadial'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getManagersStatisticsByIds } from 'store/actions'




export const MonthlyInfo = ({ getManagersStatisticsByIds, selectedManagersIds, managerDetail, error, t, isLoading, managersStatisticts }) => {


	const [managerDetails, setManagerDetails] = useState({})

	useEffect(() => {
		if (selectedManagersIds.length > 0) {
			getManagersStatisticsByIds(selectedManagersIds)
		}
		setManagerDetails((prevDetails) => {
			const filteredDetails = {}
			selectedManagersIds.forEach((id) => {
				if (prevDetails[id]) {
					filteredDetails[id] = prevDetails[id]
				}
			})
			return filteredDetails
		})

	}, [selectedManagersIds])

	useEffect(() => {
		if (managerDetail) {
			setManagerDetails((prevDetails) => ({
				...prevDetails,
				[managerDetail.id]: managerDetail
			}))
		}
	}, [managerDetail])


	const monthlySales = managersStatisticts && managersStatisticts.monthlySales ? managersStatisticts.monthlySales : 0
	const previousMonthSales = managersStatisticts && managersStatisticts.previousMonthSales ? managersStatisticts.previousMonthSales : 0
	const evolution = previousMonthSales !== 0 ? ((monthlySales - previousMonthSales) / previousMonthSales) * 100 : 0
	const evolutionFormatted = evolution.toFixed()


	const getObjective = () => {
		let selectedObjective = 0
		let selectedIncrement = 0

		selectedManagersIds.forEach((id) => {
			const managerStats = managersStatisticts && managersStatisticts.subtotalByManager
				? managersStatisticts.subtotalByManager.find(stat => stat.managerId === id)
				: null
			const managerMonthlySales = managerStats ? managerStats.monthlySales : 0
			const details = managerDetails[id]

			if (details && details.commissions) {
				let foundObjective = false
				let maxObjective = 0
				let incrementForMaxObjective = 0

				details.commissions.forEach((commission) => {
					if (!foundObjective && commission.objective > managerMonthlySales) {
						selectedObjective += commission.objective
						selectedIncrement += commission.increment
						foundObjective = true
					}
					if (commission.objective > maxObjective) {
						maxObjective = commission.objective
						incrementForMaxObjective = commission.increment
					}
				})

				if (!foundObjective) {
					selectedObjective += maxObjective
					selectedIncrement += incrementForMaxObjective
				}
			}
		})

		return { selectedObjective, selectedIncrement }
	}

	const { selectedObjective, selectedIncrement } = getObjective()


	const objectivePercentage = selectedObjective !== 0 ? (monthlySales / selectedObjective) * 100 : 0
	const objectivePercentageFormatted = parseInt(objectivePercentage.toFixed())

	return (
		<div>
			<div>
				<h3>Ventas Mensuales</h3>
				<div style={{ marginTop: "40px" }}>
					<p>Este mes</p>
					<h4>{monthlySales} € / {selectedObjective !== null ? selectedObjective : 0} €</h4>
				</div>
				<div style={{ display: "flex" }} >
					<p><span style={{ color: evolution >= 0 ? "#4de366" : "#e34d4d" }}>
						{evolutionFormatted}%
					</span></p>
					<i className={`bx bx-chevrons-${evolution >= 0 ? 'up' : 'down'}`} style={{ fontSize: "19px", color: evolution >= 0 ? "#4de366" : "#e34d4d" }}></i>
					<p>Evolución respecto al período anterior</p>
					<div style={{ marginTop: "-70px" }}>
						<ApexRadial series={objectivePercentageFormatted} />
					</div>
				</div>
			</div>
		</div>
	)
}




MonthlyInfo.propTypes = {
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	managerDetail: PropTypes.object,
	managersStatisticts: PropTypes.object,
	error: PropTypes.object,
}

const mapStateToProps = (state) => ({
	managerDetail: state.Users.managerDetail,
	error: state.Users.error,
	isLoading: state.Users.isLoading,
	managersStatisticts: state.Users.managersStatisticts,
})

const mapDispatchToProps = { getManagersStatisticsByIds }

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyInfo)
