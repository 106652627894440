import { combineReducers } from 'redux'

// Front
import Layout from './layout/reducer'

// Authentication
import ForgetPassword from './auth/forgetpwd/reducer'
import Login from './auth/login/reducer'

import CloudStorage from './cloudStorage/reducer'
import Offers from './offers/reducer'
import Orders from './orders/reducer'
import Productions from './productions/reducer'
import Quotes from './quotes/reducer'
import Users from './users/reducer'

//crm
import Crm from './crm/reducer'
import Parts from './parts/reducer'

//Emails
import Emails from './emails/reducer'

const rootReducer = combineReducers({
	Layout,
	Login,
	ForgetPassword,
	Offers,
	Quotes,
	Users,
	Crm,
	Orders,
	Productions,
	CloudStorage,
	Parts,
	Emails,
})

export default rootReducer
