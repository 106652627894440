import { getCountryCodeByPrefix } from 'common/Utils/phonePrefixByCountry'
import prefixCountry from 'constants/countryPhonePrefix'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Flag } from 'semantic-ui-react'

const PrefixFlag = ({ prefix, isEditable, onChange, prefixState }) => {
	const prefixes = Object.entries(prefixCountry)
	const name = prefix && getCountryCodeByPrefix(prefix)
	const renderOption = prefix => {
		return {
			value: prefix[1],
			label: (
				<span>
					<Flag name={prefix[0].toLocaleLowerCase()} />
					{prefix[1]}{' '}
				</span>
			),
		}
	}

	const allOptions = prefixes.map(prefixAndCountry =>
		renderOption(prefixAndCountry),
	)

	const withoutPrefixOption = { value: ' ', label: 'Sin Prefijo' }
	allOptions.unshift(withoutPrefixOption)
	return (
		<span>
			{isEditable ? (
				<Select
					className={'d-block zindex-modal'}
					options={allOptions}
					onChange={e => onChange(e)}
					value={{
						value: prefixState,
						label: (
							<span>
								<Flag name={getCountryCodeByPrefix(prefixState)} />{' '}
								{prefixState}{' '}
							</span>
						),
					}}
				/>
			) : (
				name && (
					<span>
						<span className="mx-1">{prefix}</span>
						<Flag name={name.toLocaleLowerCase()} />
					</span>
				)
			)}
		</span>
	)
}
PrefixFlag.propTypes = {
	prefix: PropTypes.number,
	isEditable: PropTypes.bool,
	onChange: PropTypes.func,
	prefixState: PropTypes.number,
}
export default PrefixFlag
