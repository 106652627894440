import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Flag } from 'semantic-ui-react'

const OrganizationFlag = ({
	country,
	organizationName,
	firstName,
	lastName,
	userId,
	isNewCustomer,
	t,
}) => {
	return (
		<div>
			{organizationName && (
				<div>
					<div className="d-flex flex-row">
						<span>
							<Flag name={country && country.toLowerCase()} />
						</span>
						<span>{organizationName}</span>
					</div>

					<Link to={`/crm-user-detail/${userId}`}>
						{firstName !== undefined && firstName} {lastName !== undefined && lastName}

					</Link>
					{isNewCustomer && <span className="ms-1 rounded-pill bg-cyan text-white width-by-content" >{t('new-organization', { ns: 'naming' })}</span>}

				</div>

			)}
		</div>
	)
}

OrganizationFlag.propTypes = {
	country: PropTypes.string,
	organizationName: PropTypes.string,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	userId: PropTypes.number,
	isNewCustomer: PropTypes.bool,
	t: PropTypes.func,
}
export default (
	withTranslation()(OrganizationFlag)
)
