import { calculateVolumetricWeight, roundAtDecimals, roundCurrency } from 'common/Utils/NumberUtilities'
import { getMeasure, getStatusClassName } from 'common/Utils/StringUtilities'
import Loader from 'components/Common/Loader'
import PrintLabelsModal from 'components/Common/ProductionLabels/PrintLabelsModal'
import { ProductionStatus } from 'constants/productionStatus'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Select from 'react-select'
import { updateProduction } from 'store/actions'

const ProductionSummary = ({
	t,
	production,
	updateProduction,
	updateSuccess,
	productionDetail,
	orderDetail,
	isLoadingOrderDetail,
}) => {

	const STATUS_OPTIONS = Object.entries(ProductionStatus).map(status => {
		const value = status[1]
		return {
			value,
			label: (
				<div>
					<span className={getStatusClassName(`production_status_${value}`)}>
						{t(`production_status_${value}`, { ns: 'status' })}
					</span>
				</div>
			),
		}
	})

	const [newStatus, setNewStatus] = useState(1)
	const [productionData, setProductionData] = useState({})
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [isOpenModalMessage, setIsOpenModalMessage] = useState(false)

	useEffect(() => {
		if (production && production.statusId) {
			const status = STATUS_OPTIONS.find(status => status.value === production.statusId)
			setNewStatus(status)
		}
	}, [production])

	useEffect(() => {
		if (updateSuccess === true) {
			setIsOpenModalMessage(true)
		}
	}, [updateSuccess])

	useEffect(() => {
		if (production?.productionItemList?.length > 0) {
			const totalSellingPrice = production?.totalSales
			const totalCostPrice = production?.totalPriceWithDiscount
			const totalProfit = totalSellingPrice - totalCostPrice
			const shipmentCost = roundAtDecimals(production.shipmentPrice, 2)
			const totalWeight = production.productionItemList.reduce((acc, item) => acc + item.part.weight * item.totalQuantity, 0)
			const volumetricWeight = production.productionItemList.reduce(
				(acc, item) => acc + calculateVolumetricWeight(item.part.size.x, item.part.size.y, item.part.size.z) * item.totalQuantity,
				0,
			)
			const netProfit = roundAtDecimals(totalProfit - shipmentCost, 2)

			setProductionData({
				totalSellingPrice,
				totalCostPrice,
				totalProfit,
				shipmentCost,
				totalWeight,
				volumetricWeight,
				netProfit,
			})
		}
	}, [production])

	const handleChangeStatus = newStatusValue => {
		setNewStatus(newStatusValue)
	}

	const handleSaveStatus = () => {
		const productionParsed = {
			production: {
				orderId: production.orderId,
				orderFragmentId: production.orderFragmentId,
				statusId: newStatus.value,
			},
		}
		updateProduction(productionParsed)
	}

	return isLoadingOrderDetail ?
		<div className="d-flex justify-content-center">
			<Loader className="fs-1" />
		</div> : (
			<div>
				<p className="fs-3 mb-2 border-bottom border-dark-subtle">
					<strong>{t('production', { ns: 'naming' })}</strong>
				</p>
				<p>
					<strong className="me-2">{t('id', { ns: 'naming' })}:</strong>
					{production?.displayId}
				</p>
				<div className="d-flex flex-row mb-2">
					<Select className="w-75 me-2" options={STATUS_OPTIONS} onChange={e => handleChangeStatus(e)} value={newStatus} />
					<button type="button" className="btn btn-light" onClick={() => handleSaveStatus()}>
						<i className="bx bxs-save fs-4"></i>
					</button>
				</div>
				<p>
					<strong className="me-2">{t('partial_sale', { ns: 'naming' })}:</strong>
					{roundCurrency(productionData.totalSellingPrice)}€{' '}
				</p>{' '}
				{/* suma de los totalSellingPrice */}
				<p>
					<strong className="me-2">{t('total_cost', { ns: 'naming' })}:</strong>
					{roundCurrency(productionData.totalCostPrice)}€{' '}
				</p>{' '}
				{/* suma de los totalPurchasePrice */}
				<p>
					<strong className="me-2">{t('gross_profit', { ns: 'naming' })}:</strong>
					{roundCurrency(productionData.totalProfit)}€{' '}
				</p>{' '}
				{/*   - total_cost  - partial_sale*/}
				<p>
					<strong className="me-2">{t('shipment_cost', { ns: 'naming' })}:</strong>
					{roundCurrency(productionData.shipmentCost)}€{' '}
				</p>
				<p>
					<strong className="me-2">{t('total_weight', { ns: 'naming' })}:</strong>
					{getMeasure(productionData.totalWeight, 'g')}
				</p>
				<p>
					<strong className="me-2">{t('volumetric_weight', { ns: 'naming' })}:</strong>
					{getMeasure(productionData.volumetricWeight * 1000, 'g')}
				</p>{' '}
				{/* suma de los volumetricWeight */}
				<p>
					<strong className="me-2">{t('net_profit', { ns: 'naming' })}:</strong>
					{roundCurrency(productionData.netProfit)} €{' '}
				</p>{' '}
				{/* gross_profit - shipment_cost */}
				<div className="d-flex flex-row justify-content-start align-items-center gap-5">

					<PrintLabelsModal
						orderFragmentId={productionDetail.production.orderFragmentId}
						orderId={productionDetail.production.orderId}
						addCustomerInfoLabel
					/>
					<Link to={`/logistics-shipments?search=${production.shipmentTrackingNumber}`}>
						{t('shipment', { ns: 'naming' })}
					</Link>
					{production.disconformity && <div className="bg-red text-white rounded-pill mx-1 p-1 px-2">{t('production_status_9', { ns: 'status' })}</div>}
					{production.partialShipment && <div className="bg-pink text-white rounded-pill mx-1 p-1 px-2">{t('production_status_10', { ns: 'status' })}</div>}
				</div>
				<ModalMessage
					isModalOpen={isOpenModalMessage}
					closeModal={() => location.reload(false)}
					message={t('update_success', { ns: 'messages' })}
				/>
			</div>
		)
}

const mapStateToProps = state => {
	return {
		productionDetail: state.Productions.productionDetail,
		isLoading: state.Productions.isLoading,
		error: state.error,
		suppliers: state.Users.suppliers,
		updateSuccess: state.Productions.updateSuccess,
	}
}

ProductionSummary.propTypes = {
	t: PropTypes.func,
	production: PropTypes.object,
	updateProduction: PropTypes.func,
	history: PropTypes.func,
	updateSuccess: PropTypes.bool,
	productionDetail: PropTypes.object,
	orderDetail: PropTypes.object,
	isLoadingOrderDetail: PropTypes.bool,
}
export default withRouter(connect(mapStateToProps, { updateProduction })(withTranslation()(ProductionSummary)))
