import STPFileIcon from 'components/Icons/STPFileIcon'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'

const LINK_ELEMENT = 'a'

export const StpDownloader = ({ url, partId, t }) => {
	const saveStp = () => {
		const link = document.createElement(LINK_ELEMENT)
		link.setAttribute('href', url)
		link.style.visibility = 'hidden'
		document.body.appendChild(link)
		link.click()
	}
	const tooltipId = partId + '_stp'

	return (
		<div>
			{url ?
				<div className="cursor" onClick={e => saveStp()} data-tip={t('download_step', { ns: 'naming' })} data-for={tooltipId}>
					<STPFileIcon tooltipMessage={t('download_step', { ns: 'naming' })} />
				</div> :
				<div>

				</div>
			}
			<ReactTooltip
				id={tooltipId}
				place="top"
				type="info"
				effect="solid"
				multiline={false}
			/>
		</div>)
}

StpDownloader.propTypes = {
	url: PropTypes.string,
	partId: PropTypes.number,
	t: PropTypes.func,
}

export default withTranslation()(StpDownloader)
