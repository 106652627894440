import Modal from 'components/Common/Modal/Modal'
import TooltipMessage from 'components/Common/TooltipMessage'
import { useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Input } from 'reactstrap'
import { uploadBluePrintOffer } from 'store/actions'

// Used in Offer.js
export const useUploadBlueprintModal = (props) => {

	const {
		offerDetail,
	} = props

	const { t } = useTranslation('naming')
	const dispatch = useDispatch()

	const [isOpen, setIsOpen] = useState(false)
	const [fileNameAndPartIds, setFileNameAndPartIds] = useState([])
	const [selectedFiles, setSelectedFiles] = useState([])
	const [loading, setLoading] = useState(false)

	const isButtonEnabled = fileNameAndPartIds?.find(x => x.fileName != null && x.fileName != '')

	const UploadBlueprintModal = useMemo(() => (
		<Modal
			isOpen={isOpen}
			closeModal={() => setIsOpen(false)}
			// Título: "Subir Plano"
			title={t('uploadBlueprint')}
			size="lg"
			body={
				<>
					{/* Sección superior: Cajita para arrastrar archivos. "Haz click o arrastra un archivo" */}
					<Dropzone
						// Al soltar archivos: Guardarlos en state para poder seleccionarlos
						onDrop={files => {
							const pushElements = (files) => {
								// Poner los últimos archivos subidos al principio del dropdown en vez de al final:
								if (selectedFiles) {
									selectedFiles.forEach(file => {
										files.unshift(file)
									})
								}
								return files
							}
							const fileArrayUpdated = pushElements(files)
							setSelectedFiles(fileArrayUpdated)
							const newFileNameAndPartIds = []
							// Si un PDF tiene el mismo nombre que un archivo, se auto-asigna:
							files.forEach(file => {
								offerDetail.offer.parts.forEach(part => {
									if (file.name == part.blueprints[0]?.name) {
										newFileNameAndPartIds.push({ fileName: file.name, partId: part.id })
									}
								})
							})
							setFileNameAndPartIds(newFileNameAndPartIds)
						}}>
						{({ getRootProps, getInputProps }) => (
							<div className="dz-message needsclick" {...getRootProps()}>
								<input multiple {...getInputProps()} />
								<div className="d-flex flex-row  p-2 align-items-center" style={{ border: '1px solid lightgray', borderRadius: '5px' }} >
									{selectedFiles?.length ? (
										// Si has subido un archivo o más, mostrar un check verde y el numero de archivos subidos
										<>
											<i className={'display-4 text-success bx bx-check me-3'} />
											<h4 className="mt-1">{selectedFiles?.length} {t('files')}</h4>
										</>
									) : (
										// Si NO hay archivos subidos, mostrar un icono de una caja y el texto "Haz click o arrastra un archivo" 
										<>
											<i className={'display-4 text-primary bx bxs-package me-3'} />
											<h4 className="mt-1">{t('clickOrDrop')}</h4>
										</>
									)}
								</div>
							</div>
						)}
					</Dropzone>
					{/* Sección principal: */}
					<div className="d-flex justify-content-between">
						<div className="row mt-2 mb-5 mx-4" style={{ width: '100%' }}>
							{/* Por cada pieza... */}
							{props.offerDetail?.offer?.parts?.map((part, index) => (
								<div
									key={`offers_page_part_${part.id}`}
									style={{ marginBottom: '60px' }}
								>
									{/* Tarjeta con información de la pieza: */}
									<div
										className="mt-2"
										style={{ border: '1.5px solid lightgray', borderRadius: '5px', height: '100%' }}
									>
										{/* Imagen de la pieza */}
										<div
											style={{ flex: 1 }}
											className="d-flex justify-content-center align-items-center bg-gray p-3"
										>
											<img src={part.imageUrl} alt={part.name} width="80" height="80" />
										</div>
										{/* Información de la pieza */}
										<div className="d-flex flex-column p-3">
											{/* ID de la pieza */}
											<h5>{part.id}</h5>
											{/* Nombre de la pieza */}
											<p style={{ wordBreak: 'break-all', color: 'gray' }}>{part.name}.stp</p>
											{/* Nombre del PDF inicial (si tiene uno) */}
											{part?.blueprints[0]?.name && (
												<p style={{ wordBreak: 'break-all', color: 'gray' }}>
													<i className="mdi mdi-file-pdf align-middle me-2" style={{ color: 'red' }} />{part?.blueprints[0]?.name}
												</p>
											)}
											{/* Nombre del PDF modificado (si tiene uno) */}
											{part?.blueprints[1]?.name && (
												<p style={{ wordBreak: 'break-all', color: 'gray' }}>
													<i className="mdi mdi-file-pdf-outline align-middle me-2" style={{ color: 'red' }} />{part?.blueprints[1]?.name}
												</p>
											)}
										</div>
									</div>
									{/* Dropdown para asignar uno de los archivos PDF subidos (Input de tipo select): */}
									{selectedFiles?.length > 0 && (
										<div className="mt-2 mb-2">
											<Input
												key={`selector_${index}`}
												type="select"
												name="select"
												id="exampleSelect"
												value={fileNameAndPartIds?.find(f => f.partId === part.id)?.fileName || ''}
												onChange={(e) => {
													const partId = part.id
													const file = e.target.value
													const partExists = fileNameAndPartIds.find(part => part.partId === partId)
													let newFileNameAndPartIds
													if (!partExists) {
														newFileNameAndPartIds = [...fileNameAndPartIds, { partId: partId, fileName: file }]
													}
													else {
														newFileNameAndPartIds = fileNameAndPartIds.map(part => {
															if (part.partId === partId) {
																return { partId: partId, fileName: file }
															}
															else return part
														})
													}
													setFileNameAndPartIds(newFileNameAndPartIds)
												}}
											>
												<option value="">{t('selectFile')}</option>
												{selectedFiles?.map((file, index) => (
													<option
														key={`select_file_option_${index}_${file.name}`}
														value={file.name}													>
														{file.name}
													</option>
												))}
											</Input>
										</div>
									)}
								</div>
							))}
						</div>
					</div>
				</>
			}
			buttons={[
				<TooltipMessage
					key="btn3"
					place="top"
					message={!isButtonEnabled
						? t('select_a_blueprint_to_continue')
						: ''}>
					<button
						type="button"
						className="btn btn-secondary"
						onClick={async () => {
							setLoading(true)
							try {
								const uploadPromises = fileNameAndPartIds.map((fileNameAndPartId) => {
									const file = selectedFiles.find(file => file.name === fileNameAndPartId.fileName)
									if (file) {
										const data = {
											partId: fileNameAndPartId.partId,
											files: {
												modifiedBlueprint: file,
											},
										}
										return new Promise((resolve, reject) => {
											dispatch(uploadBluePrintOffer(data, (error, result) => {
												if (error) {
													reject(error)
												} else {
													resolve(result)
												}
											}))
										})
									}
								})

								await Promise.all(uploadPromises)
								setSelectedFiles([])
								setFileNameAndPartIds([])
								setTimeout(() => {
									window.location.reload()
								}, 500)
							} catch (error) {
								console.error("Error uploading blueprints:", error)
							} finally {
								setLoading(false)
							}
						}}
						disabled={!isButtonEnabled}
					>
						{loading ? (
							<i className="bx bx-loader bx-spin" />
						) : (
							t('submit')
						)}
					</button>
				</TooltipMessage>,
			]
			}
		/>
	), [
		offerDetail,
		isOpen,
		fileNameAndPartIds,
		selectedFiles,
		loading,
		isButtonEnabled
	])

	return {
		UploadBlueprintModal,
		openUploadBlueprintModal: () => setIsOpen(true),
	}
}
