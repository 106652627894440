import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { findUniqueNumber } from 'common/Utils/ArrayUtilities'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import MultiSelect from 'components/Common/Select/MultiSelect'
import Select from 'components/Common/Select/Select'
import Switch from 'components/Common/Switch/Switch'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'

const QuotationModal = ({
	isQuotationModalOpen,
	closeQuotationModal,
	selectedRows,
	managers,
	suppliers,
	quotationModalData,
	parseManagers,
	parseSuppliers,
	onChangeManager,
	onChangeSupplier,
	onChangeUrgent,
	onChangeComment,
	onChangeAutomatic,
	submitQuote,
	isLoading,
	t,
	managerDefault
}) => {

	const [partsDifferentSuppliers, setPartsDifferentSuppliers] = useState(false)
	const [availableSuppliers, setAvailableSuppliers] = useState([])

	useEffect(() => {
		const suppliers = getFiltredSuppliers()
		setAvailableSuppliers(suppliers)
	}, [selectedRows.length])







	useEffect(() => {
		checkIfPartsHaveDifferentSuppliers()
	}, [selectedRows.length])

	const getSelectedSuppliers = () => {
		return selectedRows.flatMap(row =>
			row.costsAndMargins.suppliers.length > 0
				? row.costsAndMargins.suppliers.map(supplier => supplier.id)
				: [],
		)
	}

	const getFiltredSuppliers = () => {
		const suppliersRequested = getSelectedSuppliers()
		const noRepetedSuppliers = new Set(suppliersRequested)
		return suppliers.filter(supplier => !noRepetedSuppliers.has(supplier.id))
	}

	const checkIfPartsHaveDifferentSuppliers = () => {
		const suppliersRequested = getSelectedSuppliers()
		if (selectedRows.length === 1) {
			setPartsDifferentSuppliers(false)
		} else {
			setPartsDifferentSuppliers(findUniqueNumber(suppliersRequested) !== null)

		}
	}

	const isAutomaticSupplierSelected = useMemo(() => {
		const suppliersSelected = quotationModalData.suppliers.map((supplier) => `${supplier.value}`)
		const automaticSuppliers = process.env.REACT_APP_AUTOMATIC_SUPPLIERS.split(",")
		return automaticSuppliers.some((e) => suppliersSelected.includes(e))
	}, [quotationModalData.suppliers])

	return <Modal
		isOpen={isQuotationModalOpen}
		closeModal={closeQuotationModal}
		title="Solicitar Cotización"
		body={
			selectedRows.length > 0 ? (
				<div className="modal-body">
					<Select
						placeholder="Selecciona un agente para el remitente y firma del correo"
						options={parseManagers(managers)}
						className="col-md-12 mt-3"
						onChange={e => onChangeManager(e)}
						value={managerDefault}
					/>
					<MultiSelect
						placeholder="Selecciona un Proveedor"
						options={parseSuppliers(availableSuppliers)}
						className="col-md-12 mt-3"
						onChange={e => onChangeSupplier(e)}
					/>
					{partsDifferentSuppliers &&
						<div className="ms-1 ">
							<small className="text-red"><i className='bx bx-error'></i>{t('different_supplier_requested', { ns: 'naming' })}</small>
						</div>
					}
					<div className='d-flex flex-row justify-content-between'>
						<Switch
							id="urgent"
							label="Urgente"
							value={quotationModalData.isUrgent}
							onChange={e => onChangeUrgent(e)}
							size="lg"
							className="mt-3 mb-3"
						/>
						{isAutomaticSupplierSelected && <Switch
							id="automatic"
							label="Automático"
							value={quotationModalData.isAutomatic}
							onChange={e => onChangeAutomatic(e)}
							size="lg"
							className="mt-3 mb-3"
						/>}
					</div>
					<CKEditor
						editor={ClassicEditor}
						data="<p></p>"
						onReady={editor => {
							// You can store the "editor" and use when it is needed.
						}}
						onChange={(event, editor) => {
							const data = editor.getData()
							const textWithoutHTML = new DOMParser().parseFromString(data, 'text/html').body.textContent || ''
							onChangeComment(textWithoutHTML)
						}}
					/>
				</div>
			) : (
				<div className="modal-body">
					Por favor, seleccione al menos una pieza para solicitar la
					cotización.
				</div>
			)
		}
		buttons={
			selectedRows.length <= 0 ? [] : [
				<button
					disabled={
						!quotationModalData.manager ||
						quotationModalData.suppliers.length === 0 ||
						isLoading
					}
					type="button"
					key="btn2"
					className="btn btn-primary"
					onClick={() => submitQuote()}
				>
					{isLoading ? <Loader className="mx-auto" /> : "Enviar"}
				</button>,
			]
		}
	/>
}

QuotationModal.propTypes = {
	isQuotationModalOpen: PropTypes.bool,
	closeQuotationModal: PropTypes.func,
	selectedRows: PropTypes.array,
	managers: PropTypes.array,
	suppliers: PropTypes.array,
	quotationModalData: PropTypes.object,
	parseManagers: PropTypes.func,
	parseSuppliers: PropTypes.func,
	onChangeManager: PropTypes.func,
	onChangeSupplier: PropTypes.func,
	onChangeUrgent: PropTypes.func,
	onChangeComment: PropTypes.func,
	onChangeAutomatic: PropTypes.func,
	submitQuote: PropTypes.func,
	isLoading: PropTypes.bool,
	t: PropTypes.func,
}

export default QuotationModal
