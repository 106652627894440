import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import TitleWithValueColumn from '../CommonComponents/TitleWithValueColumn'
import { getLapseDivisor } from '../utils'

export const UsersSideBar = ({ dateRange, dashboardStats, previousDashboardStats, isOffers }) => {

	const lapseDivisor = useMemo(() => getLapseDivisor(dateRange), [dateRange])

	return (
		<div>
			{dashboardStats != null && dateRange != null &&
				<div className="d-flex flex-column w-100 border-top">
					<div>
						<TitleWithValueColumn
							title="Empresas"
							value={dashboardStats.domainsCount}
							previousValue={previousDashboardStats?.domainsCount}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Clientes"
							value={dashboardStats.accountsCount}
							previousValue={previousDashboardStats?.accountsCount}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title={isOffers ? 'Nuevos usuarios' : 'Nuevas empresas'}
							value={dashboardStats.newUsersCount}
							previousValue={previousDashboardStats?.newUsersCount}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Países"
							value={dashboardStats.countriesCount}
							previousValue={previousDashboardStats?.countriesCount}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Empresas Periódicos"
							value={roundAtDecimals(dashboardStats.domainsCount / lapseDivisor, 2)}
							previousValue={roundAtDecimals(previousDashboardStats?.domainsCount / lapseDivisor, 2)}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Cuentas Periódicos"
							value={roundAtDecimals(dashboardStats.domainsCount / lapseDivisor, 2)}
							previousValue={roundAtDecimals(previousDashboardStats?.domainsCount / lapseDivisor, 2)}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title={isOffers ? 'Nuevos usuarios Periódicos' : 'Nuevas empresas Periódicas'}
							value={roundAtDecimals(dashboardStats.newUsersCount / lapseDivisor, 2)}
							previousValue={roundAtDecimals(previousDashboardStats?.newUsersCount / lapseDivisor, 2)}
						/>
					</div>
				</div>
			}
		</div>
	)
}

UsersSideBar.propTypes = {
	dateRange: PropTypes.object,
	dashboardStats: PropTypes.object,
	previousDashboardStats: PropTypes.object,
	isOffers: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	dashboardStats: state.Users.dashboardStats,
	previousDashboardStats: state.Users.previousDashboardStats,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UsersSideBar)
