import { STATUS } from './status'

export const PART_STATUS = {
	NEW: STATUS.NEW,
	REQUESTED: STATUS.REQUESTED,
	QUOTED: STATUS.QUOTED,
	UPLOADED: STATUS.UPLOADED,
	REJECTED: STATUS.REJECTED,
	HIDDEN: STATUS.HIDDEN,
	DRAFT_ORDER: STATUS.DRAFT_ORDER,
	ACCEPTANCE_PENDING: STATUS.ACCEPTANCE_PENDING,
	IN_PROGRESS: STATUS.IN_PROGRESS,
	SHIPPED: STATUS.SHIPPED,
	RECEIVED: STATUS.RECEIVED,
	CANCELLED: STATUS.CANCELLED,
	REJECTED: STATUS.REJECTED,
	REJECTED_PENDING: STATUS.REJECT_PENDING,
}




export const STATUS_OPTIONS = {
	[PART_STATUS.NEW]: 'new',
	[PART_STATUS.REQUESTED]: 'requested',
	[PART_STATUS.QUOTED]: 'quoted',
	[PART_STATUS.UPLOADED]: 'uploaded',
	[PART_STATUS.DRAFT_ORDER]: 'draft_order',
	[PART_STATUS.ACCEPTANCE_PENDING]: 'acceptance_pending',
	[PART_STATUS.IN_PROGRESS]: 'in_progress',
	[PART_STATUS.SHIPPED]: 'shipped',
	[PART_STATUS.RECEIVED]: 'received',
	[PART_STATUS.CANCELLED]: 'cancelled',
	[PART_STATUS.REJECTED]: 'rejected',
	[PART_STATUS.HIDDEN]: 'dropped',
	[PART_STATUS.REJECTED_PENDING]: 'reject_pending',
};



