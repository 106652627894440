import { getActivitySectors } from 'common/Utils/ActivitySectorUtils'
import { mapManagers } from 'common/Utils/ManagersUtils'
import MultiRangeSlider from 'components/Common/MultiRangeSlider/MultiRangeSlider'
import OptionsButtons from 'components/Common/OptionsButtons'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Select from 'react-select'
import { FormGroup, InputGroup, Label } from 'reactstrap'
import { Flag } from 'semantic-ui-react'
import { getManagers } from 'store/actions'
import Modal from '../../components/Common/Modal/Modal'

export const FilterModal = ({ userList, applyFilters, t, managers }) => {

	const [isOpenFilterModal, setIsOpenFilterModal] = useState(false)
	const [registerDate, setRegisterDate] = useState(null)
	const [lastLogin, setLastLogin] = useState(null)
	const [country, setCountry] = useState(null)
	const [last12MonthBilling, setLast12MonthBilling] = useState(null)
	const [sector, setSector] = useState(null)
	const [paymentPostponed, setPaymentPostponed] = useState(null)
	const [maxBilling, setMaxBilling] = useState(null)
	const [ranking, setRanking] = useState(null)
	const [manager, setManager] = useState(null)
	const [roles, setRoles] = useState([])

	const closeModal = () => { setIsOpenFilterModal(false) }

	const resetFilters = () => {
		setRegisterDate(null)
		setLastLogin(null)
		setCountry(null)
		setLast12MonthBilling(null)
		setSector(null)
		setPaymentPostponed(null)
		setRanking(null)
		setManager(null)
		applyFilters(userList)
		setIsOpenFilterModal(false)
	}

	const paymentMethodOptions = [{
		value: true,
		text: t('payment_postponement', { ns: 'naming' }),
		color: 'primary',
	}, {
		value: false,
		text: t('no_payment_postponement', { ns: 'naming' }),
		color: 'primary',
	}]

	const userOptions = [{
		value: 5,
		text: t('account_user_role_5', { ns: 'userRoles' }),
		color: 'primary',
	}, {
		value: 6,
		text: t('account_user_role_6', { ns: 'userRoles' }),
		color: 'primary',
	}, {
		value: 7,
		text: t('account_user_role_7', { ns: 'userRoles' }),
		color: 'primary',
	}]

	useEffect(() => {
		if (userList && userList.length > 0) {
			const maxBilling = Math.max(...userList.map(user => user.purchaseStats?.stats?.last12MonthsBilling))
			setMaxBilling(Math.floor(maxBilling) + 1)
		}
	}, [])

	useEffect(() => {
		if (managers.length === 0) getManagers()
	}, [managers])

	const getActivitySectorsTranslated = () => {
		return getActivitySectors().map((sector) => {
			return {
				...sector,
				label: t('activity_sector_' + sector.value, { ns: 'activitySectors' }),
			}
		})
	}

	const getAllCountries = () => {
		const noAsignedCountry = [null, '', 'undefined']
		const countries = [...new Set(userList.map(user => user.country && user.country.toLowerCase()))]
		return countries.map(country => {
			if (noAsignedCountry.includes(country)) return {
				label: t('no_country', { ns: 'naming' }),
				value: null,
			}
			return {
				label: <div><Flag name={country && country.toLowerCase()} /> {country}</div>,
				value: country,
			}
		})
	}

	const filterByBoolean = (userList, key, value) => {
		return userList.filter(user => {
			if (value) {
				return user[key] === true
			} else {
				return !user[key]
			}
		})
	}

	const filterByDate = (userList, key, minDate, maxDate) => {
		return userList.filter(user => {
			const userDate = new Date(user[key])
			return userDate >= minDate && userDate <= maxDate
		})
	}

	const filterByRange = (userList, key, min, max) => {
		return (userList.filter(user => {
			return user.purchaseStats.stats[key] >= min && user.purchaseStats.stats[key] <= max
		}))
	}

	const filterByString = (userList, key, value) => {
		return (userList.filter(user => {
			if (value == null) return user[key] == null
			return user[key] && user[key].toLowerCase() === value.toLowerCase()
		}))
	}

	const filterByNumber = (userList, key, value) => {
		return (userList.filter(user => {
			return user[key] === value
		}))
	}

	const filterByArray = (userList, key, values) => {
		return (userList.filter(user => {
			return values.includes(user[key])
		}))
	}

	const applyAllFilters = (userList) => {
		let arrayToFilter = [...userList]
		if (registerDate != null) arrayToFilter = filterByDate(arrayToFilter, 'registerDate', registerDate[0], registerDate[1])
		if (lastLogin != null) arrayToFilter = filterByDate(arrayToFilter, 'lastLoginDate', lastLogin[0], lastLogin[1])
		if (country != null) arrayToFilter = filterByString(arrayToFilter, 'country', country.value)
		if (paymentPostponed != null) arrayToFilter = filterByBoolean(arrayToFilter, 'paymentPostponement', paymentPostponed)
		if (sector != null) arrayToFilter = filterByNumber(arrayToFilter, 'sectorId', sector.value)
		if (last12MonthBilling != null) arrayToFilter = filterByRange(arrayToFilter, 'last12MonthsBilling', last12MonthBilling[0], last12MonthBilling[1])
		if (ranking != null) arrayToFilter = filterByRange(arrayToFilter, 'ranking', ranking[0], ranking[1])
		if (manager != null) arrayToFilter = filterByNumber(arrayToFilter, 'managerId', manager.value)
		if (roles.length > 0) arrayToFilter = filterByArray(arrayToFilter, 'role', roles)
		applyFilters(arrayToFilter)
		setIsOpenFilterModal(false)
	}

	const handleSetRoles = (value) => {
		const rolesFiltred = roles.filter(role => role !== value)
		rolesFiltred.length === roles.length ? setRoles([...roles, value]) : setRoles(rolesFiltred)
	}

	return (
		<div>
			<button className='btn btn-secondary' onClick={(e) => setIsOpenFilterModal(true)}>
				{t('filter', { ns: 'naming' })}
			</button>

			<Modal
				isOpen={isOpenFilterModal}
				closeModal={closeModal}
				title="Filtro de búsqueda"
				body={<form className="m-4">
					<div className="">
						<div>
							<label className="form-label">
								{t('managers', { ns: 'naming' })}
							</label>
							{<Select
								value={manager}
								onChange={e => {
									setManager(e)
								}}
								options={[{ label: t('no_assigned_manager', { ns: "naming" }), value: null }, ...mapManagers(managers)]}
								isSearchable={true}
							/>}
						</div>
						<hr className=""></hr>
						<FormGroup className="mb-4">
							<Label>{t('sign_up_date', { ns: 'naming' })}</Label>
							<InputGroup>
								<Flatpickr
									value={registerDate}
									onChange={e => {
										setRegisterDate(e)
									}}
									onClose={e => {
										e && filterByDate(userList, 'registerDate', e[0], e[1])
									}}
									id={'registerDate_input'}
									className="form-control d-block"
									placeholder={'Fecha inicial - Fecha final'}
									options={{
										mode: 'range',
										dateFormat: 'Y-m-d',
									}}
								/>
							</InputGroup>
						</FormGroup>
					</div>
					<hr></hr>
					<div className="">
						<FormGroup className="mb-4">

							<Label>{t('last_log_in', { ns: 'naming' })}</Label>
							<InputGroup>
								<Flatpickr
									value={lastLogin}
									onChange={e => {
										setLastLogin(e)
									}}
									id={'lastLogin_input'}
									className="form-control d-block"
									placeholder="Fecha inicial - Fecha final"
									options={{
										mode: 'range',
										dateFormat: 'Y-m-d',
									}}
								/>
							</InputGroup>
						</FormGroup>
					</div>
					<hr></hr>
					<div>
						<label className="form-label">{t('country', { ns: 'naming' })}</label>
						<Select
							value={country}
							onChange={e => { setCountry(e) }}
							options={getAllCountries()}
							menuPortalTarget={document.body}
							menuPosition={'fixed'}
							styles={{
								menuPortal: base => ({ ...base, zIndex: 9999 }),
							}}
							className="zindex-modal"
							isSearchable={true}
						/>
					</div>
					<hr></hr>
					<div>
						<label
							htmlFor="customRange1"
							className="form-label"
						>
							{t('last_12_months_billing', { ns: 'naming' })}
						</label>
						<div className="px-3 pb-5 pt-4">
							<MultiRangeSlider
								min={0}
								max={maxBilling}
								onChange={({ minVal, maxVal }) => setLast12MonthBilling([minVal, maxVal])}
							/>
						</div>
					</div>
					<hr></hr>
					<div>
						<label
							htmlFor="customRange1"
							className="form-label"
						>
							{t('ranking', { ns: 'naming' })}
						</label>
						<div className="px-3 pb-5 pt-4">
							<MultiRangeSlider
								min={0}
								max={10}
								onChange={({ minVal, maxVal }) => setRanking([minVal, maxVal])}
							/>
						</div>
					</div>
					<hr></hr>
					<div>
						<label className="form-label">
							{t('activity_sector', { ns: 'naming' })}
						</label>
						{<Select
							value={sector}
							onChange={e => {
								setSector(e)
							}}
							options={getActivitySectorsTranslated()}
							isSearchable={true}
						/>}
					</div>

					<hr className=""></hr>
					<div className='pb-2'>
						<label>{t('payment_postponement', { ns: 'naming' })}</label>
						<div className='w-100 my-3 d-flex justify-content-center'>
							<OptionsButtons options={paymentMethodOptions} handleOnChange={setPaymentPostponed} valueSelected={paymentPostponed} />
						</div>
					</div>
					<hr className=""></hr>
					<div>
						<label>{t('user_role', { ns: 'naming' })}</label>
						<div className='w-100 mt-3 d-flex justify-content-center'>
							<OptionsButtons options={userOptions} handleOnChange={handleSetRoles} valueSelected={roles} isMultiple />
						</div>
					</div>

				</form>}
				buttons={[
					<button
						type="button"
						key="btn2"
						className="btn btn-primary"
						onClick={e => { resetFilters() }}
					>
						{t('reset', { ns: 'naming' })}
					</button>,
					<button
						type="button"
						key="btn2"
						className="btn btn-primary"
						onClick={e => { applyAllFilters(userList) }}
					>
						{t('apply', { ns: 'naming' })}
					</button>,
				]}
			/>
		</div>
	)
}

FilterModal.propTypes = {
	userList: PropTypes.array,
	applyFilters: PropTypes.func,
	t: PropTypes.func,
	managers: PropTypes.array,
	getManagers: PropTypes.func,
}

const mapStateToProps = (state) => ({
	managers: state.Users.managers,
})

const mapDispatchToProps = {
	getManagers,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FilterModal))
