import { isEven } from 'common/Utils/NumberUtilities'
import { getAdaptedDate } from 'common/Utils/StringUtilities'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const QualityIssuesTable = ({ t, qualityIssues }) => {
	qualityIssues.sort((a, b) => {
		return getAdaptedDate(a.date) - getAdaptedDate(b.date)
	})

	return (
		<table style={{ width: '100%' }} className="border-collapse">
			<tr>
				<th style={{ width: '50%' }} className="p-2">{t('subject', { ns: 'naming' })}</th>
				<th style={{ width: '50%' }} className="p-2">{t('date', { ns: 'naming' })}</th>
			</tr>
			{qualityIssues.map((qualityIssue, i) => {
				return <tr key={`qualityIssue_${i}`} className={isEven(i) && 'bg-light-gray'}>
					<td className="border border-top-bottom p-2">{qualityIssue.subject}</td>
					<td className="border border-top-bottom p-2">{qualityIssue.date}</td>
				</tr>
			})}
		</table>
	)
}

QualityIssuesTable.propTypes = {
	t: PropTypes.func,
	qualityIssues: PropTypes.array,
}
export default withTranslation()(QualityIssuesTable)
