/* QUOTES */
export const GET_QUOTE_LIST = 'GET_QUOTE_LIST'
export const GET_QUOTE_LIST_SUCCESS = 'GET_QUOTE_LIST_SUCCESS'
export const GET_QUOTE_LIST_FAIL = 'GET_QUOTE_LIST_FAIL'
export const GET_QUOTE_LIST_COUNT = 'GET_QUOTE_LIST_COUNT'
export const GET_QUOTE_LIST_COUNT_SUCCESS = 'GET_QUOTE_LIST_COUNT_SUCCESS'
export const GET_QUOTE_LIST_COUNT_FAIL = 'GET_QUOTE_LIST_COUNT_FAIL'
export const GET_QUOTE_DETAIL = 'GET_QUOTE_DETAIL'
export const GET_QUOTE_DETAIL_SUCCESS = 'GET_QUOTE_DETAIL_SUCCESS'
export const GET_QUOTE_DETAIL_FAIL = 'GET_QUOTE_DETAIL_FAIL'
export const UPDATE_QUOTE_QUANTITIES = 'UPDATE_QUOTE_QUANTITIES'
export const UPDATE_QUOTE_QUANTITIES_FAIL = 'UPDATE_QUOTE_QUANTITIES_FAIL'
export const UPDATE_QUOTE_QUANTITIES_SUCCESS = 'UPDATE_QUOTE_QUANTITIES_SUCCESS'
export const CREATE_QUOTE = 'CREATE_QUOTE'
export const CREATE_QUOTE_SUCCESS = 'CREATE_QUOTE_SUCCESS'
export const CREATE_QUOTE_FAIL = 'CREATE_QUOTE_FAIL'
export const RESET_CREATE_QUOTE_MODAL = 'RESET_CREATE_QUOTE_MODAL'
export const SET_SUPPLIER_ID_VIEW = 'SET_SUPPLIER_ID_VIEW'
export const ERASE_SUPPLIER_ID_VIEW = 'ERASE_SUPPLIER_ID_VIEW'
export const CLEAR_QUOTE_ERROR = 'CLEAR_QUOTE_ERROR'
export const GET_QUOTE_REGRESSIONS_BY_PART_ID =
	'GET_QUOTE_REGRESSIONS_BY_PART_ID'
export const GET_QUOTE_REGRESSIONS_BY_PART_ID_SUCCESS =
	'GET_QUOTE_REGRESSIONS_BY_PART_ID_SUCCESS'
export const GET_QUOTE_REGRESSIONS_BY_PART_ID_FAIL =
	'GET_QUOTE_REGRESSIONS_BY_PART_ID_FAIL'
export const GET_QUOTE_CHAT = 'GET_QUOTE_CHAT'
export const GET_QUOTE_CHAT_SUCCESS = 'GET_QUOTE_CHAT_SUCCESS'
export const GET_QUOTE_CHAT_FAIL = 'GET_QUOTE_CHAT_FAIL'
export const POST_QUOTE_CHAT = 'POST_QUOTE_CHAT'
export const POST_QUOTE_CHAT_SUCCESS = 'POST_QUOTE_CHAT_SUCCESS'
export const POST_QUOTE_CHAT_FAIL = 'POST_QUOTE_CHAT_FAIL'
export const MARK_QUOTE_MESSAGES_AS_READ = 'MARK_QUOTE_MESSAGES_AS_READ'
export const MARK_QUOTE_MESSAGES_AS_READ_SUCCESS = 'MARK_QUOTE_MESSAGES_AS_READ_SUCCESS'
export const MARK_QUOTE_MESSAGES_AS_READ_FAIL = 'MARK_QUOTE_MESSAGES_AS_READ_FAIL'
export const SAVE_AND_CONFIRM_QUOTE = 'SAVE_AND_CONFIRM_QUOTE'
export const SAVE_AND_CONFIRM_QUOTE_SUCCESS = 'SAVE_AND_CONFIRM_QUOTE_SUCCESS'
export const SAVE_AND_CONFIRM_QUOTE_FAIL = 'SAVE_AND_CONFIRM_QUOTE_FAIL'
export const GET_PART_COSTS_BY_SUPPLIER_ID = 'GET_PART_COSTS_BY_SUPPLIER_ID'
export const GET_PART_COSTS_BY_SUPPLIER_ID_SUCCESS = 'GET_PART_COSTS_BY_SUPPLIER_ID_SUCCESS'
export const GET_PART_COSTS_BY_SUPPLIER_ID_FAIL = 'GET_PART_COSTS_BY_SUPPLIER_ID_FAIL'
export const GET_PART_PRICES_BY_SUPPLIER_ID = 'GET_PART_PRICES_BY_SUPPLIER_ID'
export const GET_PART_PRICES_BY_SUPPLIER_ID_SUCCESS = 'GET_PART_PRICES_BY_SUPPLIER_ID_SUCCESS'
export const GET_PART_PRICES_BY_SUPPLIER_ID_FAIL = 'GET_PART_PRICES_BY_SUPPLIER_ID_FAIL'
export const GET_CLIENT_MARGIN = 'GET_CLIENT_MARGIN'
export const GET_CLIENT_MARGIN_SUCCESS = 'GET_CLIENT_MARGIN_SUCCESS'
export const GET_CLIENT_MARGIN_FAIL = 'GET_CLIENT_MARGIN_FAIL'
export const CLONE_QUOTE = 'CLONE_QUOTE'
export const CLONE_QUOTE_SUCCESS = 'CLONE_QUOTE_SUCCESS'
export const CLONE_QUOTE_FAIL = 'CLONE_QUOTE_FAIL'
export const GET_SIMILAR_QUOTE_PARTS = 'GET_SIMILAR_QUOTE_PARTS'
export const GET_SIMILAR_QUOTE_PARTS_SUCCESS = 'GET_SIMILAR_QUOTE_PARTS_SUCCESS'
export const GET_SIMILAR_QUOTE_PARTS_FAIL = 'GET_SIMILAR_QUOTE_PARTS_FAIL'
export const GET_REGRESSION_VALUE_BY_QUANTITY = 'GET_REGRESSION_VALUE_BY_QUANTITY'
export const GET_REGRESSION_VALUE_BY_QUANTITY_SUCCESS = 'GET_REGRESSION_VALUE_BY_QUANTITY_SUCCESS'
export const GET_REGRESSION_VALUE_BY_QUANTITY_FAIL = 'GET_REGRESSION_VALUE_BY_QUANTITY_FAIL'
