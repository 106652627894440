import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap'

class Breadcrumbs extends Component {
	render() {
		return (
			<React.Fragment>
				<Row>
					<Col xs="12">
						<div className="page-title-box d-sm-flex align-items-center justify-content-between">
							<h4 className="mb-0 font-size-18">
								{this.props.breadcrumbItems &&
									this.props.breadcrumbItems[
										this.props.breadcrumbItems.length - 1
									].item}
							</h4>
							<div className="page-title-right">
								<Breadcrumb listClassName="m-0">
									<BreadcrumbItem>
										<Link to="#">{this.props.title}</Link>
									</BreadcrumbItem>
									{this.props.breadcrumbItems &&
										this.props.breadcrumbItems.map(
											(breadcrumb, index, array) => {
												return (
													<BreadcrumbItem
														key={`breadcrumb${index}`}
														active={index === array.length - 1}
													>
														<Link to={breadcrumb.link}>{breadcrumb.item}</Link>
													</BreadcrumbItem>
												)
											},
										)}
								</Breadcrumb>
							</div>
						</div>
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}

Breadcrumbs.propTypes = {
	breadcrumbItems: PropTypes.array,
	title: PropTypes.string,
}

export default Breadcrumbs
