import { PartConfigTranslations } from './PartConfigTranslations'

export const FIELD_NAMES = [
	'technologyType',
	'materialType',
	'material',
	'alloy',
	'hardness',
	'finish',
	'palette',
	'color',
	'colorFinish',
]

/**
 * @typedef {'technologyType'|'materialType'|'material'|'alloy'|'hardness'|'finish'|'palette'|'color'|'colorFinish'} FieldName
 * @param {{
*   conditions: Array<{
*     if_these_selected: {[k: FieldName]: number},
*     these_visible: {[k: FieldName]: Array<number>}
*   }>,
*   currentlySelectedOptions: {[k: FieldName]: number},
*   fields: {
*     [k: FieldName]: {
*       name: FieldName, 
*       options: {[id: string]: {
*         active?: boolean,
*         name?: string,
*         abbreviation?: string,
*         position?: number,
*         density?: number,
*         ral?: string,
*         ral_rgb?: string,
*         ral_hex?: string,
*         palette?: Array<number>,
*         img?: string,
*         [key: string]: string|number|boolean|Array<number>|undefined
*       }}, 
*       optionsIdsInOrder: Array<number>
*     }
*   }|undefined
* }} args
* @returns {{
*   fieldsVisible: Array<{
*     fieldName: FieldName,
*     optionIds: Array<number>
*   }>,
*   allFieldsSpecified: boolean,
*   configurationSelectedReal: {[k: FieldName]: number}
* }}
*/
export const updateVisibility = (args) => {
	const {
		optionsSelected: currentlySelectedOptions,
		fieldsVisibilityConditions: conditions,
	} = args

	const visibleOptions = {
		technologyType: args.fields?.technologyType?.optionsIdsInOrder || [],
		materialType: [],
		material: [],
		alloy: [],
		hardness: [],
		finish: [],
		palette: [],
		color: [],
		colorFinish: [],
	}

	for (const condition of conditions) {
		const isConditionMet = Object.entries(condition.if_these_selected).every(
			([fieldName, optionId]) =>
				currentlySelectedOptions[fieldName] === optionId,
		)

		if (isConditionMet) {
			Object.entries(condition.these_visible).forEach(([fieldName, optionIds]) => {
				if (!visibleOptions[fieldName]) {
					visibleOptions[fieldName] = []
				}

				visibleOptions[fieldName] = [
					...(visibleOptions[fieldName] || []),
					...optionIds,
				]
			})
		}
	}

	let allFieldsSpecified = true

	// Remove duplicates
	for (const fieldName of Object.keys(visibleOptions)) {
		if (visibleOptions[fieldName].length == 0) continue
		visibleOptions[fieldName] = Array.from(new Set(visibleOptions[fieldName]))
		if (currentlySelectedOptions[fieldName] == null) {
			allFieldsSpecified = false
		}
	}

	const configurationSelectedReal = {
		technologyType: visibleOptions.technologyType.length > 0 ? currentlySelectedOptions.technologyType : undefined,
		materialType: visibleOptions.materialType.length > 0 ? currentlySelectedOptions.materialType : undefined,
		material: visibleOptions.material.length > 0 ? currentlySelectedOptions.material : undefined,
		alloy: visibleOptions.alloy.length > 0 ? currentlySelectedOptions.alloy : undefined,
		hardness: visibleOptions.hardness.length > 0 ? currentlySelectedOptions.hardness : undefined,
		finish: visibleOptions.finish.length > 0 ? currentlySelectedOptions.finish : undefined,
		palette: visibleOptions.palette.length > 0 ? currentlySelectedOptions.palette : undefined,
		color: visibleOptions.color.length > 0 ? currentlySelectedOptions.color : undefined,
		colorFinish: visibleOptions.colorFinish.length > 0 ? currentlySelectedOptions.colorFinish : undefined,
	}

	const fieldsVisible = []

	Object.keys(visibleOptions).forEach(fieldName => {
		if (visibleOptions[fieldName].length > 0) {
			const index = fieldsVisible.find(f => f.fieldName == fieldName)
			if (fieldsVisible[index] != null) {
				fieldsVisible[index].optionIds.push(...visibleOptions[fieldName])
			} else {
				fieldsVisible.push({ fieldName, optionIds: visibleOptions[fieldName] })
			}
			if (!visibleOptions[fieldName].includes(configurationSelectedReal[fieldName])) {
				configurationSelectedReal[fieldName] = undefined
			}
		} else {
			configurationSelectedReal[fieldName] = undefined
		}
	})

	return {
		fieldsVisible,
		allFieldsSpecified,
		configurationSelectedReal,
	}
}

export const getPartMaterial = ({ part, partConfigOptions, language }) => {
	return (<div className='d-flex flex-column'>
		<span>{
			PartConfigTranslations.getMaterialName({ part, partConfigOptions, language })
		}</span>
		<span>
			{PartConfigTranslations.getAlloyName({ part, partConfigOptions, language })
			}</span>
		<span>
			{PartConfigTranslations.getHardnessName({ part, partConfigOptions })}
		</span>
	</div>)
}

export const getPartFinish = ({ part, partConfigOptions, language }) => {
	return (<div className="d-flex flex-column">
		<span>
			{PartConfigTranslations.getFinishName({ part, partConfigOptions, language })}
		</span>
		<span>
			{PartConfigTranslations.getPaletteName({ part, partConfigOptions })}
		</span>
		<span>
			{PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language })}
		</span>
	</div>)
}

export const getFullPartDescription = (part, partConfigOptions, language) => {
	return `${PartConfigTranslations.getMaterialName({ part, partConfigOptions, language })}
	${PartConfigTranslations.getAlloyName({ part, partConfigOptions, language })}
	${PartConfigTranslations.getHardnessName({ part, partConfigOptions })}
	${PartConfigTranslations.getFinishName({ part, partConfigOptions, language })}
	${PartConfigTranslations.getPaletteName({ part, partConfigOptions })}
	${PartConfigTranslations.getColorName({ part, partConfigOptions, language })}
	${PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language })}`

}
