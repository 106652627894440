import { areObjectsEqual } from 'common/Utils/ObjectUtils'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { connect } from 'react-redux'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

export const OptionsDropdown = ({ options, selectedOptions, setSelectedOptions }) => {

	const [isToggleOpened, setToggle] = useState(null)

	const handleOnChangeSelectedOptions = (option, isActive) => {
		if (isActive) {
			if (selectedOptions.length === 1) return
			setSelectedOptions([...selectedOptions.filter(selectedOption => !areObjectsEqual(selectedOption, option.value))])
		} else {
			setSelectedOptions([...selectedOptions, option.value])
		}

	}

	const renderOption = (option) => {
		const isActive = selectedOptions.some(obj => areObjectsEqual(obj, option.value))
		const className = isActive ? 'text-primary' : undefined
		return (
			<div onClick={() => handleOnChangeSelectedOptions(option, isActive)} key={option.value} className={className}>
				{option.label}
			</div>
		)
	}

	return (
		<div>
			<Dropdown
				className='d-flex justify-content-center align-items-center'
				isOpen={isToggleOpened}
				direction='down'
				size="sm"
				toggle={e => setToggle(!isToggleOpened)}
			>
				<i onClick={() => setToggle(!isToggleOpened)} className='bx bx-cog fs-3'></i>
				<DropdownToggle className='d-none'>

				</DropdownToggle>
				<DropdownMenu data-popper-placement="left-start">
					<div className="my-3 mb-0">
						<div className="d-flex flex-column justify-content-center">
							{options && options.length !== 0 && options.map(option => renderOption(option))}
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
		</div>
	)
}

OptionsDropdown.propTypes = {
	options: PropTypes.array,
	selectedOptions: PropTypes.array,
	setSelectedOptions: PropTypes.func,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OptionsDropdown)
