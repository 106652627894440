import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './i18n'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'

import store from './store'

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>
)

ReactDOM.render(app, document.getElementById('root'))
serviceWorker.unregister()
