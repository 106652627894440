import { formatCurrencyWithSeparators } from "common/Utils/NumberUtilities"
import { getDeliveryNoteSerial } from "common/Utils/StringUtilities"
import { IconTooltip } from "components/Common/IconTooltip"
import Loader from "components/Common/Loader"
import Modal from "components/Common/Modal/Modal"
import { getPdfBlob } from "components/Common/PrintableContent/GetPdfBlob"
import { htmlToPdfInvoice } from "components/PdfTemplates/InvoicePDFTemplate"
import PropTypes from "prop-types"
import { useEffect, useMemo, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom/cjs/react-router-dom.min"
import { createInvoice, uploadOrderDeliveryNote } from "store/actions"

const NewInvoiceModal = ({ t, orderId, orderItems, orderInfo, isOpen, close, createInvoice, invoice, organization, uploadOrderDeliveryNote }) => {

	const [isLoading, setIsLoading] = useState(false)
	const [customInvoiceNumber, setCustomInvoiceNumber] = useState(undefined)

	const width = 210
	const height = 297
	const orientation = 'p'
	const id = 'invoicePrint'
	const filename = useMemo(() => {
		if (invoice == null) return undefined
		return `Fra_PG_${getDeliveryNoteSerial(invoice.creationDate, invoice.serialNumber)}_${orderInfo?.billingInfo?.companyName}`
	}, [invoice])

	const options = {
		height,
		width,
		printPageCounter: true,
		printDocumentFooter: true,
		jsPDF: {
			format: [width, height],
			unit: 'mm',
			orientation,
			hotfixes: ['px_scaling'],
		},
		margin: 0,
		html2canvas: { scale: 8 },
		filename: filename,
	}

	const generateHtmlToPrint = () => {
		if (invoice == null) return null
		const invoiceItems = orderItems.map((orderItem) => {
			const unitPrice = orderItem.total / orderItem.quantity
			return {
				name: orderItem.part.name,
				units: orderItem.quantity,
				unitPrice: formatCurrencyWithSeparators(unitPrice),
				totalPrice: formatCurrencyWithSeparators(orderItem.total)
			}
		})
		if (orderInfo.feeApplied && orderInfo.feeApplied != 0) {
			invoiceItems.push({
				name: "Minimum Fee",
				units: 1,
				unitPrice: formatCurrencyWithSeparators(orderInfo.feeApplied),
				totalPrice: formatCurrencyWithSeparators(orderInfo.feeApplied)
			})
		}
		if (orderInfo.shipmentPricing && orderInfo.shipmentPricing != 0) {
			invoiceItems.push({
				name: "Shipment Price",
				units: 1,
				unitPrice: formatCurrencyWithSeparators(orderInfo.shipmentPricing),
				totalPrice: formatCurrencyWithSeparators(orderInfo.shipmentPricing)
			})
		}
		if (orderInfo.discountApplied && orderInfo.discountApplied != 0) {
			invoiceItems.push({
				name: "Discount",
				units: 1,
				unitPrice: `-${formatCurrencyWithSeparators(orderInfo.discountApplied)}`,
				totalPrice: `-${formatCurrencyWithSeparators(orderInfo.discountApplied)}`
			})
		}
		return htmlToPdfInvoice(invoiceItems, { organization: { id: orderInfo.organizationId }, order: { ...orderInfo } }, invoice, organization, t)
	}

	useEffect(async () => {
		if (invoice) {
			const pdf = await getPdfBlob(id, options)
			const url = URL.createObjectURL(pdf)
			const link = document.createElement('a')
			link.href = url
			link.download = filename + '.pdf'
			link.click()
			setTimeout(() => {
				URL.revokeObjectURL(url)
			}, 100)
			setIsLoading(false)
			uploadOrderDeliveryNote({ data: { orderId }, deliveryNote: pdf })
		}
	}, [invoice])

	const generateInvoice = () => {
		setIsLoading(true)
		const data = {
			orderId,
			customNumber: customInvoiceNumber,
		}
		createInvoice(data)
	}

	const invoiceHtmlToPrint = useMemo(() => generateHtmlToPrint(), [invoice])

	return <>
		<div>
			<div id={id} className='d-none'>
				{invoiceHtmlToPrint}
			</div>
		</div>
		<Modal
			title={`${t("new-invoice", { ns: "naming" })} - ${t("order", { ns: "naming" })} ${orderId}`}
			isOpen={isOpen}
			closeModal={() => close()}
			className="modal-dialog-centered"
			size="xs"
			body={
				<div className="m-2 d-flex flex-row align-items-center">
					<div className="w-auto">{`${t("custom-number", { ns: "naming" })}:`}</div>
					<IconTooltip icon="bx bxs-info-circle me-2 text-primary" message={t('autogenerated-value', { ns: 'naming' })} position="top" name={"InvoiceCustomSerialNumber"} />
					<input
						id={`customInvoiceNumber${orderId}`}
						className="form-control w-auto"
						type="number"
						value={customInvoiceNumber}
						onChange={(e) => {
							e.target.value = e.target.value.split('.')[0]
							let customNumber = parseInt(e.target.value)
							if (isNaN(customNumber)) {
								e.target.value = ''
								customNumber = undefined
							}
							setCustomInvoiceNumber(customNumber)
						}}
					/>
				</div>
			}
			buttons={[
				<button
					type="button"
					key="btnInvoice"
					className="btn btn-primary"
					onClick={() => generateInvoice()}
				>
					{isLoading ? <Loader className="mx-auto" /> : t('generate', { ns: 'naming' })}
				</button>
			]}
		/>
	</>
}

const mapStateToProps = state => {
	return {
		invoice: state.Orders.invoice,
		organization: state.Users.organization
	}
}

NewInvoiceModal.propTypes = {
	t: PropTypes.func,
	orderId: PropTypes.number,
	orderItems: PropTypes.array,
	orderInfo: PropTypes.object,
	isOpen: PropTypes.bool,
	close: PropTypes.func,
	createInvoice: PropTypes.func,
	invoice: PropTypes.object,
	organization: PropTypes.object,
	uploadOrderDeliveryNote: PropTypes.func,
}

export default withRouter(connect(mapStateToProps, { createInvoice, uploadOrderDeliveryNote })(
	withTranslation()(NewInvoiceModal),
))
