import appRoutes from 'constants/appRoutes'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const OfferCustomer = ({ organizationId, title, crmLink, email, isFirstUserOffer, t }) => {
	return (
		<div>
			<p className="mb-0">{organizationId}</p>
			<p className="my-0"><strong>{title}</strong></p>
			<p className="my-0">
				<a
					href={`${appRoutes.CRM_USER_DETAIL_ROUTE_NO_ID}${organizationId}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{crmLink} {isFirstUserOffer && <span className="rounded-pill bg-cyan text-white end-ellipsis">{t('new-user', { ns: 'naming' })}</span>}
				</a>
			</p>
			<p className="mt-0">
				<a href={'mailto:' + email}>
					{email}
				</a>
			</p>
		</div>
	)
}

OfferCustomer.propTypes = {
	organizationId: PropTypes.number,
	title: PropTypes.string,
	crmLink: PropTypes.string,
	email: PropTypes.string,
	t: PropTypes.func,
	isFirstUserOffer: PropTypes.bool,
}

export default withTranslation()(OfferCustomer)
