export const GET_ORDER_DETAIL = 'GET_ORDER_DETAIL'
export const GET_ORDER_DETAIL_SUCCESS = 'GET_ORDER_DETAIL_SUCCESS'
export const GET_ORDER_DETAIL_FAIL = 'GET_ORDER_DETAIL_FAIL'
export const UPDATE_ORDER_SHIPPING_ADDRESS = 'UPDATE_ORDER_SHIPPING_ADDRESS'
export const UPDATE_ORDER_SHIPPING_ADDRESS_SUCCESS = 'UPDATE_ORDER_SHIPPING_ADDRESS_SUCCESS'
export const UPDATE_ORDER_SHIPPING_ADDRESS_FAIL = 'UPDATE_ORDER_SHIPPING_ADDRESS_FAIL'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL'
export const CREATE_INTERNAL_COMMENT = 'CREATE_INTERNAL_COMMENT'
export const CREATE_INTERNAL_COMMENT_SUCCESS = 'CREATE_INTERNAL_COMMENT_SUCCESS'
export const CREATE_INTERNAL_COMMENT_FAIL = 'CREATE_INTERNAL_COMMENT_FAIL'
export const GET_ORDER_DETAIL_BY_ID = 'GET_ORDER_DETAIL_BY_ID'
export const GET_ORDER_DETAIL_BY_ID_SUCCESS = 'GET_ORDER_DETAIL_BY_ID_SUCCESS'
export const GET_ORDER_DETAIL_BY_ID_FAIL = 'GET_ORDER_DETAIL_BY_ID_FAIL'
export const GET_ORDER_LIST = 'GET_ORDER_LIST'
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS'
export const GET_ORDER_LIST_FAIL = 'GET_ORDER_LIST_FAIL'
export const GET_BILLING_DASHBOARD = 'GET_BILLING_DASHBOARD'
export const GET_BILLING_DASHBOARD_SUCCESS = 'GET_BILLING_DASHBOARD_SUCCESS'
export const GET_BILLING_DASHBOARD_FAIL = 'GET_BILLING_DASHBOARD_FAIL'
export const HANDLE_SUCCESSFUL_ORDER = 'HANDLE_SUCCESSFUL_ORDER'
export const HANDLE_SUCCESSFUL_ORDER_SUCCESS = 'HANDLE_SUCCESSFUL_ORDER_SUCCESS'
export const HANDLE_SUCCESSFUL_ORDER_FAIL = 'HANDLE_SUCCESSFUL_ORDER_FAIL'
export const HANDLE_CANCELLED_ORDER = 'HANDLE_CANCELLED_ORDER'
export const HANDLE_CANCELLED_ORDER_SUCCESS = 'HANDLE_CANCELLED_ORDER_SUCCESS'
export const HANDLE_CANCELLED_ORDER_FAIL = 'HANDLE_CANCELLED_ORDER_FAIL'
export const SET_SELECTED_ORDER_ID = 'SET_SELECTED_ORDER_ID'
export const SET_SELECTED_VERIFICATION_ORDER_ID = 'SET_SELECTED_VERIFICATION_ORDER_ID'
export const SET_SELECTED_EXPEDITION_ORDER_ID = 'SET_SELECTED_EXPEDITION_ORDER_ID'
export const ADD_SCANNED_ORDER = 'ADD_SCANNED_ORDER'
export const SET_LAST_SCANNED_PART = 'SET_LAST_SCANNED_PART'
export const SET_ORDER_BOXCODE = 'SET_ORDER_BOXCODE'
export const ADD_SCANNED_PIECE_TO_ORDER = 'ADD_PIECE_TO_ORDER'
export const RESET_SCANNED_PIECES_FROM_PIECE = 'RESET_SCANNED_PIECES_FROM_PIECE'
export const RESET_SCANNED_PIECES_FROM_ALL_PIECES = 'RESET_SCANNED_PIECES_FROM_ALL_PIECES'
export const RESET_ORDER_BOXCODE = 'RESET_ORDER_BOXCODE'
export const ADD_EXPEDITION_ORDER = 'ADD_EXPEDITION_ORDER'
export const ADD_SCANNED_PIECE_TO_EXPEDITION_ORDER = 'ADD_SCANNED_PIECE_TO_EXPEDITION_ORDER'
export const RESET_SCANNED_PIECES_FROM_EXPEDITION_PIECE = 'RESET_SCANNED_PIECES_FROM_EXPEDITION_PIECE'
export const RESET_SCANNED_PIECES_FROM_ALL_EXPEDITION_PIECES = 'RESET_SCANNED_PIECES_FROM_ALL_EXPEDITION_PIECES'
export const SET_EXPEDITION_ORDER_BOXCODE = 'SET_EXPEDITION_ORDER_BOXCODE'
export const CREATE_NEW_NACEX_EXPEDITION = 'CREATE_NEW_EXPEDITION'
export const CREATE_NEW_NACEX_EXPEDITION_SUCCESS = 'CREATE_NEW_EXPEDITION_SUCCESS'
export const CREATE_NEW_NACEX_EXPEDITION_FAIL = 'CREATE_NEW_EXPEDITION_FAIL'
export const SET_COMPLETED_EXPEDITION_ORDER = 'SET_COMPLETED_EXPEDITION_ORDER'
export const MANAGE_SCANNED_QUANTITY_ON_DELIVERY = 'MANAGE_SCANNED_QUANTITY_ON_DELIVERY'
export const ADD_EXPEDITION_TO_ORDER = 'ADD_EXPEDITION_TO_ORDER'
export const GET_VOUCHER_LIST = 'GET_VOUCHER_LIST'
export const GET_VOUCHER_LIST_SUCCESS = 'GET_VOUCHER_LIST_SUCCESS'
export const GET_VOUCHER_LIST_FAIL = 'GET_VOUCHER_LIST_FAIL'
export const CREATE_VOUCHER = 'CREATE_VOUCHER'
export const CREATE_VOUCHER_SUCCESS = 'CREATE_VOUCHER_SUCCESS'
export const CREATE_VOUCHER_FAIL = 'CREATE_VOUCHER_FAIL'
export const UPDATE_VOUCHER_BY_C0DE = 'UPDATE_VOUCHER_BY_C0DE'
export const UPDATE_VOUCHER_BY_C0DE_SUCCESS = 'UPDATE_VOUCHER_BY_C0DE_SUCCESS'
export const UPDATE_VOUCHER_BY_C0DE_FAIL = 'UPDATE_VOUCHER_BY_C0DE_FAIL'
export const GET_DOMAIN_ORDERS = 'GET_DOMAIN_ORDERS'
export const GET_DOMAIN_ORDERS_SUCCESS = 'GET_DOMAIN_ORDERS_SUCCESS'
export const GET_DOMAIN_ORDERS_FAIL = 'GET_DOMAIN_ORDERS_FAIL'
export const RESET_ORDER_DETAIL = 'RESET_ORDER_DETAIL'
export const GET_BOX_LIST = 'GET_BOX_LIST'
export const GET_BOX_LIST_SUCCESS = 'GET_BOX_LIST_SUCCESS'
export const GET_BOX_LIST_FAIL = 'GET_BOX_LIST_FAIL'
export const UPDATE_BOXES_BY_REF = 'UPDATE_BOXES_BY_REF'
export const UPDATE_BOXES_BY_REF_SUCCESS = 'UPDATE_BOXES_BY_REF_SUCCESS'
export const UPDATE_BOXES_BY_REF_FAIL = 'UPDATE_BOXES_BY_REF_FAIL'
export const GET_BILLING_LIST = 'GET_BILLING_LIST'
export const GET_BILLING_LIST_SUCCESS = 'GET_BILLING_LIST_SUCCESS'
export const GET_BILLING_LIST_FAIL = 'GET_BILLING_LIST_FAIL'
export const GET_EXPORTED_PART_BY_DATE_LIST = 'GET_EXPORTED_PART_BY_DATE_LIST'
export const GET_EXPORTED_PART_BY_DATE_LIST_SUCCESS = 'GET_EXPORTED_PART_BY_DATE_LIST_SUCCESS'
export const GET_EXPORTED_PART_BY_DATE_LIST_NO_PAGINATED_SUCCESS = 'GET_EXPORTED_PART_BY_DATE_LIST_NO_PAGINATED_SUCCESS'
export const GET_EXPORTED_PART_BY_DATE_LIST_FAIL = 'GET_EXPORTED_PART_LIST_FAIL'
export const GET_FILTERED_ORDERS = 'GET_FILTERED_ORDERS'
export const GET_FILTERED_ORDERS_SUCCESS = 'GET_FILTERED_ORDERS_SUCCESS'
export const GET_FILTERED_ORDERS_FAIL = 'GET_FILTERED_ORDERS_FAIL'
export const GET_OPEPENED_ORDERS = 'GET_OPEPENED_ORDERS'
export const GET_OPEPENED_ORDERS_SUCCESS = 'GET_OPEPENED_ORDERS_SUCCESS'
export const GET_OPEPENED_ORDERS_FAIL = 'GET_OPEPENED_ORDERS_FAIL'
export const GET_OPENED_VERIFICATION_ORDERS = 'GET_OPENED_VERIFICATION_ORDERS'
export const GET_OPENED_VERIFICATION_ORDERS_SUCCESS = 'GET_OPENED_VERIFICATION_ORDERS_SUCCESS'
export const GET_OPENED_VERIFICATION_ORDERS_FAIL = 'GET_OPENED_VERIFICATION_ORDERS_FAIL'
export const UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY = 'UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY'
export const UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_SUCCESS = 'UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_SUCCESS'
export const UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_FAIL = 'UPDATE_PRODUCTION_ITEM_RECEIVED_QUANTITY_FAIL'
export const GET_OPENED_EXPEDITION_ORDERS = 'GET_OPENED_EXPEDITION_ORDERS'
export const GET_OPENED_EXPEDITION_ORDERS_SUCCESS = 'GET_OPENED_EXPEDITION_ORDERS_SUCCESS'
export const GET_OPENED_EXPEDITION_ORDERS_FAIL = 'GET_OPENED_EXPEDITION_ORDERS_FAIL'
export const UPDATE_ORDER_EXPEDITION_INFO = 'UPDATE_ORDER_EXPEDITION_INFO'
export const UPDATE_ORDER_EXPEDITION_INFO_SUCCESS = 'UPDATE_ORDER_EXPEDITION_INFO_SUCCESS'
export const UPDATE_ORDER_EXPEDITION_INFO_FAIL = 'UPDATE_ORDER_EXPEDITION_INFO_FAIL'
export const UPDATE_ORDER_ITEM = 'UPDATE_ORDER_ITEM'
export const UPDATE_ORDER_ITEM_SUCCESS = 'UPDATE_ORDER_ITEM_SUCCESS'
export const UPDATE_ORDER_ITEM_FAIL = 'UPDATE_ORDER_ITEM_FAIL'
export const SCAN_ORDER_EXPEDITION_PART = 'SCAN_ORDER_EXPEDITION_PART'
export const SCAN_ORDER_EXPEDITION_PART_SUCCESS = 'SCAN_ORDER_EXPEDITION_PART_SUCCESS'
export const SCAN_ORDER_EXPEDITION_PART_FAIL = 'SCAN_ORDER_EXPEDITION_PART_FAIL'
export const SET_EXPEDITION_COMMENT_READED = 'SET_EXPEDITION_COMMENT_READED'
export const SET_EXPEDITION_BOX_LABEL_GENERATED = 'SET_EXPEDITION_BOX_LABEL_GENERATED'
export const SET_EXPEDITION_DELIVERY_NOTE_PRINTED = 'SET_EXPEDITION_DELIVERY_NOTE_PRINTED'
export const CREATE_SHIPMENT_EXPORT = 'CREATE_SHIPMENT_EXPORT'
export const CREATE_SHIPMENT_EXPORT_SUCCESS = 'CREATE_SHIPMENT_EXPORT_SUCCESS'
export const CREATE_SHIPMENT_EXPORT_FAIL = 'CREATE_SHIPMENT_EXPORT_FAIL'
export const GET_LAST_THREE_DAYS_SHIPMENTS = 'GET_LAST_THREE_DAYS_SHIPMENTS'
export const GET_LAST_THREE_DAYS_SHIPMENTS_SUCCESS = 'GET_LAST_THREE_DAYS_SHIPMENTS_SUCCESS'
export const GET_LAST_THREE_DAYS_SHIPMENTS_FAIL = 'GET_LAST_THREE_DAYS_SHIPMENTS_FAIL'
export const RESET_EXPEDITION_ORDER_BOXCODE = 'RESET_EXPEDITION_ORDER_BOXCODE'
export const RESET_NACEX_INFO = 'RESET_NACEX_INFO'
export const GET_QUALITY_QUANTITIES = 'GET_QUALITY_QUANTITIES'
export const GET_QUALITY_QUANTITIES_SUCCESS = 'GET_QUALITY_QUANTITIES_SUCCESS'
export const GET_QUALITY_QUANTITIES_FAIL = 'GET_QUALITY_QUANTITIES_FAIL'
export const GET_QUALITY_PROBLEMS = 'GET_QUALITY_PROBLEMS'
export const GET_QUALITY_PROBLEMS_SUCCESS = 'GET_QUALITY_PROBLEMS_SUCCESS'
export const GET_QUALITY_PROBLEMS_FAIL = 'GET_QUALITY_PROBLEMS_FAIL'
export const GET_QUALITY_SOLUTIONS = 'GET_QUALITY_SOLUTIONS'
export const GET_QUALITY_SOLUTIONS_SUCCESS = 'GET_QUALITY_SOLUTIONS_SUCCESS'
export const GET_QUALITY_SOLUTIONS_FAIL = 'GET_QUALITY_SOLUTIONS_FAIL'
export const CREATE_PART_VERIFICATION = 'CREATE_PART_VERIFICATION'
export const CREATE_PART_VERIFICATION_SUCCESS = 'CREATE_PART_VERIFICATION_SUCCESS'
export const CREATE_PART_VERIFICATION_FAIL = 'CREATE_PART_VERIFICATION_FAIL'
export const CREATE_NEW_UPS_EXPEDITION = 'CREATE_NEW_UPS_EXPEDITION'
export const CREATE_NEW_UPS_EXPEDITION_SUCCESS = 'CREATE_NEW_UPS_EXPEDITION_SUCCESS'
export const CREATE_NEW_UPS_EXPEDITION_FAIL = 'CREATE_NEW_UPS_EXPEDITION_FAIL'
export const GET_EXPEDITION_UPS_LABELS = 'GET_EXPEDITION_UPS_LABELS'
export const GET_EXPEDITION_UPS_LABELS_SUCCESS = 'GET_EXPEDITION_UPS_LABELS_SUCCESS'
export const GET_EXPEDITION_UPS_LABELS_FAIL = 'GET_EXPEDITION_UPS_LABELS_FAIL'
export const GET_EXPEDITION_NACEX_LABELS = 'GET_EXPEDITION_NACEX_LABELS'
export const GET_EXPEDITION_NACEX_LABELS_SUCCESS = 'GET_EXPEDITION_NACEX_LABELS_SUCCESS'
export const GET_EXPEDITION_NACEX_LABELS_FAIL = 'GET_EXPEDITION_NACEX_LABELS_FAIL'
export const SAVE_GENERATED_PDF = 'SAVE_GENERATED_PDF'
export const ADD_QUALITY_VERIFICATION_IMAGES = 'ADD_QUALITY_VERIFICATION_IMAGES'
export const ADD_SCANNED_VERIFICATION_ORDER = 'ADD_SCANNED_VERIFICATION_ORDER'
export const ADD_INTERNAL_COMMENT = 'ADD_INTERNAL_COMMENT'
export const CREATE_REMIT = 'CREATE_REMIT'
export const CREATE_REMIT_SUCCESS = 'CREATE_REMIT_SUCCESS'
export const CREATE_REMIT_FAIL = 'CREATE_REMIT_FAIL'
export const CREATE_INVOICE = 'CREATE_INVOICE'
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS'
export const CREATE_INVOICE_FAIL = 'CREATE_INVOICE_FAIL'
export const GET_PENDING_INVOICE_ORDERS = 'GET_PENDING_INVOICE_ORDERS'
export const GET_PENDING_INVOICE_ORDERS_SUCCESS = 'GET_PENDING_INVOICE_ORDERS_SUCCESS'
export const GET_PENDING_INVOICE_ORDERS_FAIL = 'GET_PENDING_INVOICE_ORDERS_FAIL'
export const UPDATE_ORDER_EXPEDITION_SHIPMENT = 'UPDATE_ORDER_EXPEDITION_SHIPMENT'
export const UPDATE_ORDER_EXPEDITION_SHIPMENT_FILES = 'UPDATE_ORDER_EXPEDITION_SHIPMENT_FILES'
export const GET_INCIDENCES_LIST = 'GET_INCIDENCES_LIST'
export const GET_INCIDENCES_LIST_SUCCESS = 'GET_INCIDENCES_LIST_SUCCESS'
export const GET_INCIDENCES_LIST_FAIL = 'GET_INCIDENCES_LIST_FAIL'
export const HANDLE_SENT_ORDER = 'HANDLE_SENT_ORDER'
export const HANDLE_SENT_ORDER_SUCCESS = 'HANDLE_SENT_ORDER_SUCCESS'
export const HANDLE_SENT_ORDER_FAIL = 'HANDLE_SENT_ORDER_FAIL'
export const UPDATE_QUALITY_VERIFICATION = 'UPDATE_QUALITY_VERIFICATION'
export const UPDATE_QUALITY_VERIFICATION_SUCCESS = 'UPDATE_QUALITY_VERIFICATION_SUCCESS'
export const UPDATE_QUALITY_VERIFICATION_FAIL = 'UPDATE_QUALITY_VERIFICATION_FAIL'
export const GET_ORDER_ITEM_BY_ORDER_ID_SEARCH = 'GET_ORDER_ITEM_BY_ORDER_ID_SEARCH'
export const GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_SUCCESS = 'GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_SUCCESS'
export const GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_FAIL = 'GET_ORDER_ITEM_BY_ORDER_ID_SEARCH_FAIL'
