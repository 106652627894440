import { isInDateRange } from 'common/Utils/DateUtils'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import OffersChart from '../../../components/Common/Charts/OffersChart'
import { getChartLabels, getIndexByType, getPreviousDateRange } from '../utils'

export const OffersDashBoard = ({ dateRange, offersDashboard, t }) => {

	const getSeriesByData = (offersArray, previousOffersArray, partsArray, previousPartsArray) => {
		return {
			series: [
				{
					name: 'Ofertas Pasadas',
					type: 'column',
					data: previousOffersArray,
				},
				{
					name: 'Ofertas',
					type: 'column',
					data: offersArray,
				}
				, {
					name: 'Piezas',
					type: 'line',
					data: partsArray,
				}, {
					name: 'Piezas pasadas',
					type: 'line',
					data: previousPartsArray,
				},
			],
			//Esto es para mostrar los gráficos de manera que se entienda mejor. El 1.1 y 1.4 es para que las barras y lineas del gráfico no llegen al techo.
			maxOffers: Math.max(...offersArray, ...previousOffersArray) * 1.1 || 1,
			maxParts: Math.max(...partsArray, ...previousPartsArray) * 1.4 || 1,
		}
	}

	const getDashboardValues = () => {
		const offersArray = Array.from({ length: labels.length }, () => 0)
		const previousOffersArray = Array.from({ length: labels.length }, () => 0)
		const partsArray = Array.from({ length: labels.length }, () => 0)
		const previousPartsArray = Array.from({ length: labels.length }, () => 0)
		if (offersDashboard.length === 0) return getSeriesByData(offersArray, previousOffersArray, partsArray, previousPartsArray) // RETURNS A EMPTY DASHBOARD
		for (const { offer, partsCount } of offersDashboard) {
			const index = getIndexByType(type, offer.creationDate, dateRange)
			if (isInDateRange(offer.creationDate, dateRange.startDate, dateRange.endDate)) {
				offersArray[index] += 1
				partsArray[index] += partsCount
			} else if (previousDateRange && isInDateRange(offer.creationDate, previousDateRange.startDate, previousDateRange.endDate)) {
				previousOffersArray[index] += 1
				previousPartsArray[index] += partsCount
			}
		}
		return getSeriesByData(offersArray, previousOffersArray, partsArray, previousPartsArray)
	}

	const previousDateRange = useMemo(() => getPreviousDateRange(dateRange), [dateRange])
	const { labels, type } = useMemo(() => getChartLabels(dateRange, t), [dateRange])
	const { series, maxOffers, maxParts } = useMemo(() => getDashboardValues(), [offersDashboard])
	return (
		<div><OffersChart series={series} labels={labels} maxOffers={maxOffers} maxParts={maxParts} /></div>
	)
}

OffersDashBoard.propTypes = {
	dateRange: PropTypes.object,
	t: PropTypes.func,
	offersDashboard: PropTypes.array,
}

const mapStateToProps = (state) => ({
	offersDashboard: state.Offers.offersDashboard,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OffersDashBoard))
