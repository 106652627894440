import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'

//TODO , MAKE A CHART COMPONENT THAT CAN BE USED IN BOTH DASHBOARDS
export const UsersChart = ({ labels, series, maxValue, leftYAxisText, isOffers }) => {

	const OFFER_BAR_COLOR = '#c0fae3'
	const OFFER_BIG_BAR_COLOR = '#02bd08'

	const ORDER_BAR_COLOR = '#fad6af'
	const ORDER_BIG_BAR_COLOR = '#FF8300'

	const BAR_COLOR = isOffers ? OFFER_BAR_COLOR : ORDER_BAR_COLOR
	const BIG_BAR_COLOR = isOffers ? OFFER_BIG_BAR_COLOR : ORDER_BIG_BAR_COLOR

	const LINE_COLOR = '#c5d3fa'
	const BIG_LINE_COLOR = '#00ffcc'

	const BAR_BORDER = 0
	const BIG_BAR_BORDER = 1
	const LINE_WIDTH = 3
	const BIG_LINE_WIDTH = 3

	const options = {
		colors: [BAR_COLOR, BIG_BAR_COLOR, BIG_LINE_COLOR, LINE_COLOR], //color linea pequeña, color borde barra, color linea gorda
		chart: {
			type: 'line',
			stacked: false,
			height: 350,
			toolbar: { show: false },
		},
		stroke: {
			width: [BAR_BORDER, BIG_BAR_BORDER, BIG_LINE_WIDTH, LINE_WIDTH], // [borde barra pequeña, borde barra grande, linea gorda, linea pequeña
			curve: 'smooth',
		},
		plotOptions: {
			bar: {
				columnWidth: '81%',
			},
		},
		labels,
		markers: {
			size: 0,
		},
		yaxis: [{
			max: maxValue,
			title: {
				text: leftYAxisText,

			},
		}, {
			max: maxValue,
			show: false,
		}, {
			opposite: true,
			max: maxValue,
			show: false,
		},
		{
			show: false,
			max: maxValue,
		}],

		tooltip: {
			shared: true,
			intersect: false,
			y: {
				formatter: function (y) {
					if (typeof y !== 'undefined') {
						return y.toFixed(0)
					}
					return y

				},
			},
		},
	}

	return (
		<div style={{ height: '42.5rem' }}>
			<ReactApexChart height={'100%'} options={options} series={series} type="line" />

		</div>
	)
}

UsersChart.propTypes = {
	series: PropTypes.array,
	maxValue: PropTypes.number,
	labels: PropTypes.array,
	isOffers: PropTypes.bool,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UsersChart) 
