import CountriesChartMap from 'components/Common/Charts/CountriesChartMap'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const CountriesDashboard = ({ t, dashboardCountryList, isOffers }) => {

	const getMappedCountries = () => {
		if (!dashboardCountryList || dashboardCountryList.length === 0) return { countries: {}, labels: {} }

		const countries = {}
		const labels = {}
		dashboardCountryList.forEach(({ country, email }) => {
			const countryName = country?.toUpperCase()
			countries[countryName] = (countries[country] || 0) + 1
			labels[countryName] = labels[countryName] ? [...labels[countryName], email] : [email]
		})

		return { countries, labels }
	}

	const { countries, labels } = useMemo(() => getMappedCountries(), [dashboardCountryList])

	return (
		<CountriesChartMap countries={countries} labels={labels} isOffers={isOffers} />
	)
}

CountriesDashboard.propTypes = {
	t: PropTypes.func,
	dashboardCountryList: PropTypes.array,
	isOffers: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	dashboardCountryList: state.Users.dashboardCountryList,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CountriesDashboard))
