import { getDate } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import { NoteTooltip } from 'components/NoteTooltip'
import Table from 'components/Table/Table'
import AppRoutes from 'constants/appRoutes'
import { INTERACTION_COLOR, INTERACTION_TYPE_COLOR, RELATED_COLOR } from "constants/colorsInteractions"
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import { getCrmInteractionsList, getManagerList } from 'store/actions'

import OfferCustomer from 'pages/OfferList/OfferCustomer'
import CrmInteractionsFiltersModal from './CrmInteractionsFiltersModal'


const PAGE_SIZE_LIST = [
	{ value: 50, text: '50' },
	{ value: 100, text: '100' },
	{ value: 200, text: '200' },
	{ value: 500, text: '500' },
	{ value: 1000, text: '1000' },
]


export const InteractionsList = ({ t, isLoading, error, crmInteractions, totalCrmInteractionsRows, getManagerList, isLoadingManagers }) => {


	useEffect(() => {
		getManagerList()
	}, [])

	const sizePerPageOptionRenderer = ({ text, page, onSizePerPageChange }) => (
		<li key={text} role="presentation" className="d-flex align-items-center">
			<a
				href="#"
				tabIndex="-1"
				role="menuitem"
				data-page={page}
				onMouseDown={e => {
					e.preventDefault()
					onSizePerPageChange(page)
				}}
				className="btn btn-default btn-primary dropdown-toggle text-white width-3rem"
			>
				{text}
			</a>
		</li>
	)

	const headerToRender = {
		id: t('id', { ns: 'naming' }),
		interactionType: t('interactionType', { ns: 'naming' }),
		subject: t('subject', { ns: 'naming' }),
		related: t('related', { ns: 'naming' }),
		message: t('message', { ns: 'naming' }),
		client: t('client', { ns: 'naming' }),
		interaction: t('interaction', { ns: 'naming' }),
		manager: t('manager', { ns: 'naming' }),
		notifyAt: t('notifyAt', { ns: 'naming' }),
		date: t('date', { ns: 'naming' }),
	}


	const formatInteraction = interactionObject => {
		const nameConcat = `${interactionObject.accountFirstName} ${interactionObject.accountLastName}`
		return {
			id: interactionObject.id,
			subject: interactionObject.subject,
			manager: interactionObject.managerFullName,
			interactionType: (
				<span
					className={`rounded-pill my-2 text-white w100 bg-${INTERACTION_TYPE_COLOR[interactionObject.interactionType] || 'gray'}`}
				>
					{interactionObject.interactionType === 'comercial'
						? t('comercial_tracking', { ns: 'naming' }) // Usa 'comercial_tracking' para comercial
						: t(interactionObject.interactionType, { ns: 'naming' }) // Traduce otros valores de interactionType
					}
				</span>
			),
			interaction: (
				<span className={`rounded-pill my-2 text-white w100 bg-${INTERACTION_COLOR[interactionObject.interaction] || 'gray'}`}>
					{t(interactionObject.interaction, { ns: 'naming' })} {/* Traduce valores de interaction */}
				</span>
			),
			related: (
				<span
					className={`rounded-pill my-2 text-white w100 bg-${RELATED_COLOR[interactionObject.related] || 'gray'}`}
				>
					{interactionObject.related === 'quote'
						? t('offer', { ns: 'naming' }) // Traduce 'quote' a 'offer'
						: t(interactionObject.related, { ns: 'naming' }) // Traduce otros valores de 'related'
					}
				</span>
			),
			client: <OfferCustomer organizationId={interactionObject.accountId} title={nameConcat} crmLink={interactionObject.organizationName} email={interactionObject.email} isFirstUserOffer={interactionObject.newUser} />,
			message: <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
				<NoteTooltip note={interactionObject.message} t={t} />
			</div>,
			date: getDate(interactionObject.date && interactionObject.date),
			notifyAt: getDate(interactionObject.notifyAt && interactionObject.notifyAt),
		}
	}

	const parseData = data => {
		if (!data) return []
		return data.map(interaction => formatInteraction(interaction))
	}


	const [currentPage, setCurrentPage] = useState(1)
	const [pageSize, setPageSize] = useState(50)


	const handlePageChange = (page, pageSize) => {
		setCurrentPage(page)
		setPageSize(pageSize)
	}


	const itemsToRender = useMemo(() => {
		if (crmInteractions?.length > 0) return (parseData(crmInteractions))
		return []
	}, [crmInteractions])

	return (
		<div className="page-content marginFooter">
			<MetaTags>
				<title>Proto&Go! | {t('interactions', { ns: 'naming' })}</title>
			</MetaTags>
			<div>
				<Breadcrumbs
					title='crm'
					breadcrumbItems={[
						{
							item: t('interactions-list-route', { ns: 'naming' }),
							link: '/crm-interactions-list',
						},
					]}
				/>
			</div>
			<div className="bg-white">
				<div className="bg-white p-4 pb-0 d-flex justify-content-between">
					<div className="d-flex justify-content-between align-items-center">
						<CrmInteractionsFiltersModal pageSize={pageSize} currentPage={currentPage}
							setCurrentPage={setCurrentPage} />
					</div>
				</div>
				{isLoading ? (
					<Loader className="font-size-80 mx-auto" />
				) : error ? <div className="d-flex justify-content-center">{<Alert type={'danger'} message={error} />}</div> :
					(<div className="bg-white mb-4 px-4">
						<div className="py-2">
							{itemsToRender?.length !== 0 ? (
								<Table
									remote
									customFilter
									header={headerToRender}
									items={itemsToRender}
									selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
									pageToNavigate={AppRoutes.CRM_INTERACTIONS_LIST_ROUTE}
									counters={[{ label: 'interactions', value: totalCrmInteractionsRows }]}
									paginationProps={{
										custom: true,
										totalSize: totalCrmInteractionsRows,
										page: currentPage || 1,
										sizePerPage: pageSize,
										sizePerPageList: PAGE_SIZE_LIST,
										sizePerPageOptionRenderer,
										onPageChange: (page, pageSize) => {
											handlePageChange(page, pageSize)
										},
										onSizePerPageChange: (pageSize, page) => {
											handlePageChange(page, pageSize)
										},
									}}
									rowQuantityList={[
										{ text: '100', value: 100 },
										{ text: '150', value: 150 },
										{ text: '200', value: 200 },
										{ text: '500', value: 500 },
										{ text: '1000', value: 1000 },
									]}
								/>
							) : <div className="d-flex justify-content-center">
								<h2>{t('no_interactions_to_show', { ns: 'naming' })}</h2>
							</div>}
						</div>

					</div>

					)}
			</div>
		</div>
	)
}

InteractionsList.propTypes = {
	t: PropTypes.func,
	getCrmInteractionsList: PropTypes.func,
	error: PropTypes.object,
	isLoading: PropTypes.bool,
	crmInteractions: PropTypes.array,
	totalCrmInteractionsRows: PropTypes.number,
	getManagerList: PropTypes.func,
	managerList: PropTypes.array,
	isLoadingManagers: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	error: state.Users.error,
	isLoading: state.Users.isLoading,
	crmInteractions: state.Users.crmInteractions,
	totalCrmInteractionsRows: state.Users.totalCrmInteractionsRows,
	managerList: state.Users.managerList,
	isLoadingManagers: state.Users.isLoadingManagers,
})

const mapDispatchToProps = { getCrmInteractionsList, getManagerList }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InteractionsList)))
