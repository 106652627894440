import { isObjectEmpty } from 'common/Utils/ObjectUtils'
import { getIdFromUrl } from 'common/Utils/StringUtilities'
import { mapDataToInputDTO, parseHolidays, parseWeekdays } from 'common/Utils/SuppliersUtils'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import { OptionsInput } from 'components/Common/OptionsInput'
import TextInput from 'components/Common/TextInput'
import { timeZones } from 'constants/timezones'
import CountryFlag from 'pages/CRMUserDetail/ComponenteTablas/CountryFlag'
import PrefixFlag from 'pages/CRMUserDetail/ComponenteTablas/prefixFlagComponent'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { createSupplier, getSupplierDetail, updateSupplier } from 'store/actions'
import { CURRENCY_OPTIONS, FORMAT_DATE_OPTIONS, INITIAL_STATE, LANGUAGE_OPTIONS, REQUIRED_FIELDS, TECHNOLOGY_OPTIONS, WORKDAYS_OPTIONS } from './formConstants'

const REGISTER_PATH = '/supplier-register'
const UPDATE_PATH = '/supplier-detail'

export const SupplierDetail = ({ t, updateSupplier, createSupplier, error, history, isSupplierCreated, getSupplierDetail, supplierDetail, isSupplierSuccessfullyUpdated, isLoading, isLoadingDetail }) => {

	const isCreate = location.pathname === REGISTER_PATH

	const [supplierDataState, setSupplierData] = useState(INITIAL_STATE)
	const [hasPressed, setHasPressed] = useState(false)
	const [message, setMessage] = useState(null)
	const [isModalMessageOpen, setIsModalMessageOpen] = useState(false)

	const handleOnChangeSupplierData = (key, value) => {
		setSupplierData({ ...supplierDataState, [key]: value })
	}

	useEffect(() => {
		if (isCreate) setSupplierData(INITIAL_STATE)
	}, [isCreate])

	useEffect(() => {
		if (error) {
			setMessage(error)
		}
		if (isSupplierCreated) {
			setMessage(t('supplier_created_successfully', { ns: 'naming' }))
		}

	}, [error, isSupplierCreated])

	useEffect(() => {
		if (isSupplierSuccessfullyUpdated === true) setMessage(t('supplier_updated_successfully', { ns: 'naming' }))
	}, [isSupplierSuccessfullyUpdated])

	useEffect(() => {
		if (!isCreate) {
			const supplierId = getIdFromUrl()
			getSupplierDetail(supplierId)
		}
	}, [])

	useEffect(() => {
		if (message != null) setIsModalMessageOpen(true)
	}, [message])

	useEffect(() => {
		if (!isObjectEmpty(supplierDetail) && !isCreate) {
			const { account, supplierInfo } = supplierDetail
			const holidaysMapped = parseHolidays(supplierInfo.calendar.holidays)
			setSupplierData({
				firstName: account.personalInformation.firstName,
				officialName: supplierInfo.officialName,
				address: supplierInfo.address.address,
				country: supplierInfo.address.country,
				state: supplierInfo.address.state,
				contactPerson: supplierInfo.contactPerson,
				mobilePhone: account.phoneData.mobilePhone.number,
				mobilePhonePrefix: account.phoneData.mobilePhone.prefix,
				language: account.personalInformation.language,
				phoneNumber: account.phoneData.phone.number,
				phonePrefix: account.phoneData.phone.prefix,
				formatDate: supplierInfo.personalSettings.formatDate,
				holidays: holidaysMapped,
				techTypes: supplierInfo.techTypes,
				shipmentDays: supplierInfo.calendar.shipmentDays,
				workDays: parseWeekdays(supplierInfo.calendar.workdays),
				currency: supplierInfo.personalSettings.currency,
				timeZone: supplierInfo.timezone,
				email: account.loginCredentials.email,
				reliabilityMarginDays: supplierInfo.calendar.reliabilityMarginDays,
				qualityRating: supplierInfo.qualityRating,
				deliveryPeriodRating: supplierInfo.deliveryPeriodRating,
				responseTimeRating: supplierInfo.responseTimeRating,
			})
		}
	}, [supplierDetail])

	const checkRequiredData = () => {
		const arrayObject = Object.entries(supplierDataState)
		for (const item of arrayObject) {
			if (REQUIRED_FIELDS.includes(item[0])) {
				if (item[1] == null || item[1] === '' || item[1].length === 0) {
					setHasPressed(true)
					return false
				}
			}
		}
		return true
	}

	const saveData = () => {
		const allRequiredDataIsFilled = checkRequiredData()
		if (allRequiredDataIsFilled) {
			if (isCreate) {
				createSupplier(mapDataToInputDTO(supplierDataState, undefined))
			} else {
				updateSupplier(mapDataToInputDTO(supplierDataState, getIdFromUrl()))
			}

		}
	}

	const handleOnClose = () => {
		setIsModalMessageOpen(false)
		history.go(0)
	}

	const renderStarRating = (rating) => {
		const stars = []
		const fullStars = Math.floor(rating)
		const hasHalfStar = rating % 1 >= 0.5

		for (let i = 1; i <= 5; i++) {
			if (i <= fullStars) {
				stars.push(<i key={i} className='bx bxs-star' style={{ color: 'orange' }}></i>)
			} else if (i === fullStars + 1 && hasHalfStar) {
				stars.push(<i key={i} className='bx bxs-star-half' style={{ color: 'orange' }}></i>)
			} else {
				stars.push(<i key={i} className='bx bx-star'></i>)
			}
		}

		return stars
	}

	return (
		<div className="page-content">
			<MetaTags>
				<title>Proto&Go! | {t('supplierDetail', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('configuration', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('supplierList', { ns: 'naming' }),
						link: '/supplier-list',
					},
					{
						item: t('supplierDetail', { ns: 'naming' }),
						link: '/supplier-detail',
					},
				]}
			/>
			<div>
				{isLoadingDetail ? <div className='fs-1 d-flex justify-content-center'><Loader /> </div> :
					<div>
						<div className="bg-white rounded">
							<div className="border-bottom m-2 pb-1">
								<h3 className="ms-3 mb-0 pt-3 pb-0">{t('organization', { ns: 'naming' })}</h3>
							</div>
							<Row>
								<Col>
									<div className="pb-3">
										<div className="m-4">
											<TextInput
												isRequired={true}
												value={supplierDataState.firstName}
												setValue={handleOnChangeSupplierData}
												title={t('commercial_brand', { ns: 'naming' })}
												hasPressed={hasPressed}
												objectElement={'firstName'}
											/>
										</div>
										<div className="m-4">
											<TextInput
												isRequired={true}
												value={supplierDataState.address}
												setValue={handleOnChangeSupplierData}
												title={t('address', { ns: 'naming' })}
												hasPressed={hasPressed}
												objectElement={'address'}
											/>
										</div>
										<div className="m-4">
											<TextInput
												isRequired={true}
												value={supplierDataState.state}
												setValue={handleOnChangeSupplierData}
												title={t('province', { ns: 'naming' })}
												hasPressed={hasPressed}
												objectElement={'state'}
											/>
										</div>
									</div>
								</Col>
								<Col>
									<div className="m-4">
										<TextInput
											isRequired={true}
											value={supplierDataState.officialName}
											setValue={handleOnChangeSupplierData}
											title={t('officialName', { ns: 'naming' })}
											hasPressed={hasPressed}
											objectElement={'officialName'}
										/>
									</div>
									<div className="m-4">
										<div className='mx-2'>
											<h6><span className="text-primary me-1">✲</span>{t('country', { ns: 'naming' })}</h6>
											<CountryFlag
												isEditable
												onChange={(e) => handleOnChangeSupplierData('country', e.value)}
												countryState={supplierDataState.country}
												hasPressed={hasPressed}
												className='mx-2'
											/>
											{hasPressed && supplierDataState.country == null && (
												<div>
													<div className="invalid-feedback">
														{t('required_field', { ns: 'naming' })}
													</div>

												</div>)}
										</div>
									</div>
								</Col>
							</Row>
						</div>
						<div className="bg-white rounded">
							<div className="border-bottom m-2 pb-1">
								<h3 className="ms-3 mb-0 pt-3 pb-0">{t('rating', { ns: 'naming' })}</h3>
							</div>
							<Row>
								<Col>
									<div className="pb-3">
										<div className="m-4">
											<h5>
												{t('quality_rating', { ns: 'naming' })}:
												<span style={{ marginLeft: "3px", marginRight: "2px" }}>
													{renderStarRating(isNaN(parseFloat(supplierDataState.qualityRating))
														? 0
														: Math.min(5, parseFloat(supplierDataState.qualityRating).toFixed(2))
													)}
												</span>
												{isNaN(parseFloat(supplierDataState.qualityRating))
													? 0
													: Math.min(5, parseFloat(supplierDataState.qualityRating).toFixed(2))
												}
											</h5>
										</div>
										<div className="m-4">
											<h5>
												{t('delivery_period_rating', { ns: 'naming' })}:
												<span style={{ marginLeft: "3px", marginRight: "2px" }}>
													{renderStarRating(isNaN(parseFloat(supplierDataState.deliveryPeriodRating))
														? 0
														: Math.min(5, parseFloat(supplierDataState.deliveryPeriodRating).toFixed(2))
													)}
												</span>
												{isNaN(parseFloat(supplierDataState.deliveryPeriodRating))
													? 0
													: Math.min(5, parseFloat(supplierDataState.deliveryPeriodRating).toFixed(2))
												}
											</h5>
										</div>
										<div className="m-4">
											<h5>
												{t('response_time_rating', { ns: 'naming' })}:
												<span style={{ marginLeft: "3px", marginRight: "2px" }}>
													{renderStarRating(isNaN(parseFloat(supplierDataState.responseTimeRating))
														? 0
														: Math.min(5, parseFloat(supplierDataState.responseTimeRating).toFixed(2))
													)}
												</span>
												{isNaN(parseFloat(supplierDataState.responseTimeRating))
													? 0
													: Math.min(5, parseFloat(supplierDataState.responseTimeRating).toFixed(2))
												}
											</h5>
										</div>
									</div>

								</Col>

							</Row>
						</div>
						<div className="bg-white rounded mt-4">
							<div className="border-bottom m-2 pb-1">
								<h3 className="ms-3 mb-0 pt-3 pb-0">{t('contact_person', { ns: 'naming' })}</h3>
							</div>
							<Row>
								<Col>
									<div className="pb-3">
										<div className="m-4">
											<TextInput
												isRequired={true}
												value={supplierDataState.contactPerson}
												setValue={handleOnChangeSupplierData}
												title={t('contact_person', { ns: 'naming' })}
												hasPressed={hasPressed}
												objectElement={'contactPerson'}
											/>
										</div>
									</div>
								</Col>
								<Col>
									<div className="m-4 row ps-2">
										<div className="col-4 ps-0">
											<h6>Prefijo</h6>
											<PrefixFlag
												isEditable
												onChange={(e) => handleOnChangeSupplierData('mobilePhonePrefix', e.value)}
												prefixState={supplierDataState.mobilePhonePrefix}
											/>
										</div>
										<div className="col-8">
											<TextInput
												value={supplierDataState.mobilePhone}
												setValue={handleOnChangeSupplierData}
												title={t('mobilePhone', { ns: 'naming' })}
												className='my-0 mx-0 w-100'
												objectElement={'mobilePhone'}
											/>
										</div>
									</div>
								</Col>
							</Row>
						</div>
						<div className="bg-white rounded mt-4">
							<div className="border-bottom m-2 pb-1">
								<h3 className="ms-3 mb-0 pt-3 pb-0">{t('general_information', { ns: 'naming' })}</h3>
							</div>
							<Row>
								<Col>
									<div className="pb-3">
										<div className="m-4">
											<OptionsInput
												value={supplierDataState.language}
												setValue={handleOnChangeSupplierData}
												title={t('language', { ns: 'naming' })}
												options={LANGUAGE_OPTIONS(t)}
												objectElement="language"
												automaticMappedValue
												hasPressed={hasPressed}
												isRequired
											/>
										</div>
										<div className="m-4">
											<div className='row mx-2'>
												<div className="col-4 ps-0">
													<h6>Prefijo</h6>
													<PrefixFlag
														isEditable
														onChange={(e) => handleOnChangeSupplierData('phonePrefix', e.value)}
														prefixState={supplierDataState.phonePrefix}
													/>
												</div>
												<div className="col-8 p-0 pb-1">
													<TextInput
														value={supplierDataState.phoneNumber}
														setValue={handleOnChangeSupplierData}
														title={t('phoneNumber', { ns: 'naming' })}
														objectElement={'phoneNumber'}
														className='my-0 me-0'
													/>
												</div>
											</div>
										</div>
										<div className="m-4">
											<OptionsInput
												value={supplierDataState.formatDate}
												setValue={handleOnChangeSupplierData}
												title={t('format_date', { ns: 'naming' })}
												options={FORMAT_DATE_OPTIONS}
												objectElement="formatDate"
												automaticMappedValue
												hasPressed={hasPressed}
												isRequired
											/>
										</div>
										<div className="m-4">
											<div className='mx-2'>
												<label>{t('holidays', { ns: 'naming' })}</label>
												<div className="input-group">
													<Flatpickr
														className="form-control bg-white d-block"
														placeholder="dd M,yyyy"
														value={supplierDataState.holidays}
														options={{
															mode: 'multiple',
															dateFormat: 'Y-m-d',
														}}

														onChange={(e, dateStr) => {
															handleOnChangeSupplierData('holidays', dateStr)
														}}
													/>
												</div>
											</div>
										</div>
										<div className="m-4">
											<OptionsInput
												value={supplierDataState.techTypes}
												setValue={handleOnChangeSupplierData}
												title={t('tech_types', { ns: 'naming' })}
												options={TECHNOLOGY_OPTIONS}
												objectElement="techTypes"
												isMulti
												automaticMappedValue
												hasPressed={hasPressed}
												isRequired
											/>
										</div>
									</div>
								</Col>
								<Col>
									<div className="pb-3">
										<div className="m-4">
											<TextInput
												isRequired={true}
												value={supplierDataState.shipmentDays}
												setValue={handleOnChangeSupplierData}
												title={t('shipment_days', { ns: 'naming' })}
												hasPressed={hasPressed}
												objectElement={'shipmentDays'}
												type='number'
											/>
										</div>
										<div className="m-4">
											<OptionsInput
												value={supplierDataState.workDays}
												setValue={handleOnChangeSupplierData}
												title={t('work_days', { ns: 'naming' })}
												options={WORKDAYS_OPTIONS(t)}
												objectElement="workDays"
												isMulti
												automaticMappedValue
												hasPressed={hasPressed}
												isRequired
											/>
										</div>
										<div className="m-4">
											<OptionsInput
												value={supplierDataState.currency}
												setValue={handleOnChangeSupplierData}
												title={t('currency', { ns: 'naming' })}
												options={CURRENCY_OPTIONS}
												objectElement="currency"
												automaticMappedValue
											/>
										</div>
										<div className="m-4">
											<OptionsInput
												options={timeZones}
												setValue={handleOnChangeSupplierData}
												objectElement="timeZone"
												value={supplierDataState.timeZone}
												title={t('time_zone', { ns: 'naming' })}
												automaticMappedValue
												isRequired
												showLogs
												hasPressed={hasPressed}
											/>
										</div>
										<div className='m-4'>
											<TextInput
												value={supplierDataState.reliabilityMarginDays}
												setValue={handleOnChangeSupplierData}
												title={t('extra_days', { ns: 'naming' })}
												hasPressed={hasPressed}
												objectElement={'reliabilityMarginDays'}
												type='number'
											/>
										</div>
									</div>
								</Col>
							</Row>
						</div>
						<div className="bg-white rounded mt-4 mb-4 marginFooter">
							<div className="border-bottom m-2 pb-1">
								<h3 className="ms-3 mb-0 pt-3 pb-0">{t('account', { ns: 'naming' })}</h3>
							</div>
							<Row>
								<Col>
									<div className="pb-3">
										<div className="m-4 mb-0 w-50">
											<TextInput
												isRequired={true}
												value={supplierDataState.email}
												setValue={handleOnChangeSupplierData}
												title={t('email', { ns: 'naming' })}
												hasPressed={hasPressed}
												objectElement={'email'}
												disabled={!isCreate}
												type='email'
											/>
										</div>
									</div>
									<div className="m-4 mt-1">
										<button
											type="button"
											className="btn btn-warning"
											disabled={isLoading}
											onClick={e => { saveData() }}
										>
											{isLoading ? <div><Loader /></div> : t('save', { ns: 'naming' })}
										</button>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				}
			</div>
			<ModalMessage
				isModalOpen={isModalMessageOpen}
				message={message}
				closeModal={() => { handleOnClose() }}
			/>
		</div>
	)
}

SupplierDetail.propTypes = {
	t: PropTypes.func,
	supplierData: PropTypes.object,
	createSupplier: PropTypes.func,
	error: PropTypes.string,
	history: PropTypes.object,
	isSupplierCreated: PropTypes.bool,
	getSupplierDetail: PropTypes.func,
	supplierDetail: PropTypes.object,
	updateSupplier: PropTypes.func,
	isSupplierSuccessfullyUpdated: PropTypes.bool,
	isLoading: PropTypes.bool,
	isLoadingDetail: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	error: state.Users.error,
	isSupplierCreated: state.Users.isSupplierCreated,
	supplierDetail: state.Users.supplierDetail,
	isSupplierSuccessfullyUpdated: state.Users.isSupplierSuccessfullyUpdated,
	isLoading: state.Users.isLoading,
	isLoadingDetail: state.Users.isLoadingDetail,

})

const mapDispatchToProps = {
	createSupplier,
	getSupplierDetail,
	updateSupplier,
}

export default withRouter(connect(
	mapStateToProps, mapDispatchToProps)(
		withTranslation()(SupplierDetail)))
