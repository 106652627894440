import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import TooltipMessage from 'components/Common/TooltipMessage'
import { getManagersStatisticsByIds } from 'store/actions'

export const StaticsInfo = ({ selectedManagersIds, getManagersStatisticsByIds, t, managerDetail, managersStatisticts }) => {

	useEffect(() => {
		if (selectedManagersIds.length > 0) {
			getManagersStatisticsByIds([...selectedManagersIds])
		}
	}, [selectedManagersIds])

	const inProgressOrdersCount = managersStatisticts && managersStatisticts.inProgressOrdersCount ? managersStatisticts.inProgressOrdersCount.length : 0
	const inProgressOffersCount = managersStatisticts && managersStatisticts.inProgressOffersCount ? managersStatisticts.inProgressOffersCount.length : 0

	const [managerDetails, setManagerDetails] = useState({})

	useEffect(() => {


		setManagerDetails((prevDetails) => {
			const filteredDetails = {}
			selectedManagersIds.forEach((id) => {
				if (prevDetails[id]) {
					filteredDetails[id] = prevDetails[id]
				}
			})
			return filteredDetails
		})

	}, [selectedManagersIds])

	useEffect(() => {
		if (managerDetail) {
			setManagerDetails((prevDetails) => ({
				...prevDetails,
				[managerDetail.id]: managerDetail
			}))
		}
	}, [managerDetail])

	let totalCustomersCount = 0
	let totalActiveCustomersCount = 0

	Object.values(managerDetails).forEach((manager) => {
		totalCustomersCount += manager.customersCount || 0
		totalActiveCustomersCount += manager.activeCustomersCount || 0
	})

	const globalEfficiency = totalCustomersCount > 0 ? Math.round((totalActiveCustomersCount / totalCustomersCount) * 100) : 0

	const getStatisticToRender = (label, values, icon, percentage) => {
		const isCustomers = label[0] === "clients"
		return (
			<div className='d-flex flex-row w-100 bg-white align-items-center p-4 justify-content-between'>
				{isCustomers && <div className='panel-control-percentage'><i class='bx bxs-user' style={{ fontSize: "25px", color: "white" }}></i></div>}
				<div className='d-flex flex-column'>
					<h3>{t(label, { ns: "naming" })}</h3>
					<h3 className="d-flex w-100  justify-content-center">
						{isCustomers ? (
							<div className='d-flex'>
								<TooltipMessage message="Clientes activos" place="top">
									<div style={{ fontFamily: "sans-serif" }}>
										<h2>{values[0]}</h2>
									</div>
								</TooltipMessage>
								/
								<TooltipMessage message="Clientes asignados" place="top">
									<div style={{ fontFamily: "sans-serif" }}>
										<h2>{values[1]}</h2>
									</div>
								</TooltipMessage>

							</div>
						) : (
							values[0]
						)}
					</h3>
				</div>
				{
					percentage ?
						<div className='panel-control-percentage'>
							<p className='p-panel-control-percentage'>{percentage}%</p>
						</div>
						: <i className={icon} style={{ fontSize: "55px" }}></i>}
			</div>)
	}

	return (
		<div>
			<div className='row pt-2'>
				<div className='col-6 pe-2'>
					{getStatisticToRender(["orders"], [inProgressOrdersCount], "bx bxs-package text-primary")}
				</div>
				<div className='col-6 ps-0'>
					{getStatisticToRender(["offers"], [inProgressOffersCount], "bx bxs-offer text-primary")}
				</div>
			</div>
			<div className='row'>
				<div className='col mt-2'>
					{getStatisticToRender(["clients"], [totalActiveCustomersCount, totalCustomersCount], null, globalEfficiency)}
				</div>
			</div>
		</div>
	)
}

StaticsInfo.propTypes = {
	t: PropTypes.func,
	selectedManagersIds: PropTypes.array,
	getManagersStatisticsByIds: PropTypes.func,
	managerDetail: PropTypes.object,
	managersStatisticts: PropTypes.object,

}

const mapStateToProps = (state) => ({
	managerDetail: state.Users.managerDetail,
	managersStatisticts: state.Users.managersStatisticts,
})

const mapDispatchToProps = { getManagersStatisticsByIds }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StaticsInfo))
