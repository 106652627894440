import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

// Import Routes
import { authProtectedRoutes, publicRoutes, supplierProtectedRoutes } from './routes/'
import AppRoute from './routes/route'

// layouts
import NonAuthLayout from './components/NonAuthLayout'
import Sidebar from './components/Sidebar/'

// Import scss
import Pages404 from 'pages/Utility/pages-404'
import './assets/scss/colors.scss'
import './assets/scss/customClasses.scss'
import './assets/scss/flags.scss'
import './assets/scss/theme.scss'

import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import ErrorBoundary from 'components/ErrorBoundary'
import {
	USER_ROLE_BOSS,
	USER_ROLE_MANAGER,
	USER_ROLE_QUALITY,
	USER_ROLE_SECUAZ,
	USER_ROLE_SUPPLIER
} from 'constants/userRoles'
import {
	getCrmUsers,
	getManagers,
	getSuppliers,
	verifyUserToken,
} from 'store/actions'

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {}
		this.getLayout = this.getLayout.bind(this)
	}

	verifyToken() {
		const authUser = getUserValuesFromLocalStorage()
		if (authUser) {
			this.props.verifyUserToken({
				token: authUser.token,
			})
		}
	}
	componentDidMount() {
		this.verifyToken()
	}

	/**
	 * Returns the layout
	 */
	getLayout() {
		let layoutCls = Sidebar

		switch (this.props.layout.layoutType) {
			default:
				layoutCls = Sidebar
				break
		}
		return layoutCls
	}

	render() {
		const Layout = this.getLayout()

		return (
			<React.Fragment>
				<Router>
					<ErrorBoundary>
						<Switch>
							{publicRoutes.map((route, index) => (
								<AppRoute
									path={route.path}
									layout={NonAuthLayout}
									component={route.component}
									key={index}
									isAuthProtected={false}
								/>
							))}
							{supplierProtectedRoutes.map((route, index) => (
								<AppRoute
									path={route.path}
									layout={Layout}
									component={route.component}
									key={index}
									isAuthProtected={true}
									rolesAllowed={[USER_ROLE_BOSS, USER_ROLE_MANAGER, USER_ROLE_SUPPLIER, USER_ROLE_QUALITY]}
									exact
								/>
							))}
							{authProtectedRoutes.map((route, index) => (
								<AppRoute
									path={route.path}
									layout={Layout}
									component={route.component}
									key={index}
									isAuthProtected={true}
									rolesAllowed={[USER_ROLE_BOSS, USER_ROLE_MANAGER, USER_ROLE_SECUAZ, USER_ROLE_QUALITY]}
									exact
								/>
							))}

							<Route component={Pages404} />
						</Switch>
					</ErrorBoundary>
				</Router>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		layout: state.Layout,
		token: state.Login.token,
	}
}

App.propTypes = {
	layout: PropTypes.object,
	verifyUserToken: PropTypes.func,
	token: PropTypes.string,
	getManagers: PropTypes.func,
	getSuppliers: PropTypes.func,
	getCrmUsers: PropTypes.func,
}

export default connect(mapStateToProps, {
	verifyUserToken,
	getManagers,
	getSuppliers,
	getCrmUsers,
})(App)
