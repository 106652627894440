import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PrintableContent from 'components/Common/PrintableContent/PrintableContent'
import { htmlToPdfDeliveryNote } from 'components/PdfTemplates/DeliveryNotePDFTemplate'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const ModalGenerateDeliveryNote = ({
	isOpenModal,
	orderParts,
	i18n: { language },
	t,
	isLoading,
	setIsOpenModal,
	partConfigOptions,
}) => {

	const [deliveryNote, setDeliveryNote] = useState(null)
	const [orderItemsState, setOrderItemsState] = useState([])

	const handleOnChangeOrderItemsQuantity = (id, newQuantity) => {
		setOrderItemsState(orderItemsState.map(orderItem => {
			if (orderItem.partId === id) {
				// eslint-disable-next-line use-isnan
				const intQuantity = parseInt(newQuantity) !== NaN ? parseInt(newQuantity) : 0
				return {
					...orderItem,
					quantity: intQuantity,
				}
			}
			return orderItem
		}))
	}

	useEffect(() => {
		if (orderParts?.order != null && orderParts?.order?.orderItems?.length !== 0) {
			setOrderItemsState(orderParts.order.orderItems)
		}
	}, [orderParts])

	useEffect(() => {
		if (orderItemsState?.length !== 0) {
			const document = generateDeliveryNote(
				orderItemsState.filter(orderItem => orderItem.quantity > 0),
				orderParts,
			)
			setDeliveryNote(document)
		}
	}, [language, orderItemsState])

	function generateDeliveryNote(products, orderInfo) {
		const result = products ? htmlToPdfDeliveryNote(products, orderInfo, t, partConfigOptions, language) : undefined
		return result
	}

	return (
		<Modal
			title={t('printDeliveryNote', { ns: 'naming' })}
			size="lg"
			closeButtonText={t('close', { ns: 'naming' })}
			closeButtonColor="secondary"
			isOpen={isOpenModal}
			closeModal={() => setIsOpenModal(false)}
			body={
				isLoading ? (
					<div className="d-flex justify-content-center align-items-center">
						<Loader className="m-4 d-flex font-size-80" />
					</div>
				) : (
					<table className="m-4">
						<thead>
							<th></th>
							<th>{t('name', { ns: 'naming' })}</th>
							<th>{t('pending', { ns: 'naming' })}</th>
							<th>{t('current_shipment', { ns: 'naming' })}</th>
						</thead>
						<tbody>
							{orderParts?.order?.orderItems.map((item, index) => {
								return (
									<tr key={index}>
										<td>{<img src={item.part?.imageUrl} style={{ width: '50px', height: '50px', objectFit: 'contain' }} />}</td>
										<td>{item.part?.name}</td>
										<td>{item.quantity}</td>
										<td>
											<input
												type="number"
												defaultValue={item.quantity}
												onChange={(e) => {
													handleOnChangeOrderItemsQuantity(item.part?.id, e.target.value)
												}}>
											</input>
										</td>
									</tr>
								)
							},
							)}
						</tbody>

					</table>
				)}
			buttons={[
				!isLoading ? <PrintableContent
					key="deliveryNotePrint"
					id="deliveryNotePrint" //required
					printPageCounter
					printDocumentFooter
					width={210} //Width page size
					height={297} //Height page size
					orientation="p" //Orientation. "l" = landscape. "p" = portrait
					isHidingContent //use it to hide content on webBrowser
					filename={`DeliveryNote_${orderParts?.order?.id}`} //Filename
					buttonClassName="btn btn-primary" //Class for button
					buttonContent={
						<div className="d-flex flex-row px-2 justify-content-center align-items-center mt-0">
							{t('print', { ns: 'naming' })}
							<i className="bx bxs-printer font-size-20 align-middle ms-2" />
						</div>
					}
				>
					{deliveryNote}
				</PrintableContent>
					: null,
			]}
		/>
	)
}

ModalGenerateDeliveryNote.propTypes = {
	isOpenModal: PropTypes.bool,
	orderParts: PropTypes.object,
	i18n: PropTypes.object,
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	setIsOpenModal: PropTypes.func,
}

const mapStateToProps = (state) => ({
	orderParts: state.Orders.orderDetail.order,
	isLoading: state.Orders.isLoading,
	partConfigOptions: state.Parts.partConfigOptions,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalGenerateDeliveryNote))
