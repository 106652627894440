import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import TechnologyPercentageChart from 'components/Common/Charts/TechnologyPercentageChart'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const TechnologiesPercentageDashboard = ({ t, isOffers, technologiesDashboardStats }) => {

	const valueTitle = isOffers ? 'Valor ofertado' : 'Subtotal'

	const { technology3DStats, technologyCNCStats, technologyVCStats } = technologiesDashboardStats || {}

	const totalReferences = technology3DStats?.currentStat?.totalParts + technologyCNCStats?.currentStat?.totalParts + technologyVCStats?.currentStat?.totalParts
	const totalValue = technology3DStats?.currentStat?.totalSellingPrice + technologyCNCStats?.currentStat?.totalSellingPrice + technologyVCStats?.currentStat?.totalSellingPrice

	const calculatePercentage = (value, total) => roundAtDecimals((value / total) * 100, 2)

	const referencesSeries = [
		calculatePercentage(technology3DStats?.currentStat?.totalParts, totalReferences),
		calculatePercentage(technologyCNCStats?.currentStat?.totalParts, totalReferences),
		calculatePercentage(technologyVCStats?.currentStat?.totalParts, totalReferences),
	]

	const valueSeries = [
		calculatePercentage(technology3DStats?.currentStat?.totalSellingPrice, totalValue),
		calculatePercentage(technologyCNCStats?.currentStat?.totalSellingPrice, totalValue),
		calculatePercentage(technologyVCStats?.currentStat?.totalSellingPrice, totalValue),
	]

	return (
		<div className='d-flex flex-row justify-content-center align-items-center'>
			<div className='w-50 mx-2'>
				<TechnologyPercentageChart series={referencesSeries} title={t('references', { ns: 'naming' })} />
			</div>
			<div className='w-50 mx-2'>
				<TechnologyPercentageChart series={valueSeries} title={valueTitle} />
			</div>
		</div>
	)
}

TechnologiesPercentageDashboard.propTypes = {
	t: PropTypes.func,
	technologiesDashboardStats: PropTypes.object,
}

const mapStateToProps = (state) => ({
	technologiesDashboardStats: state.Parts.technologiesDashboardStats,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TechnologiesPercentageDashboard))
