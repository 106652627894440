export function removeUserFromLocalStorage() {
	localStorage.setItem('authUser', '')
}
export function getUserValuesFromLocalStorage() {
	const authUser = localStorage.getItem('authUser')
	if (authUser) {
		return JSON.parse(authUser)
	}
	return null
}
export function getLanguage() {
	return localStorage.getItem('I18N_LANGUAGE')
}

export function saveNotFoundVisitedInARow(number) {
	localStorage.setItem('notFoundVisitedInARow', number)
}

export function getNotFoundVisitedInARow() {
	return localStorage.getItem('notFoundVisitedInARow')
}

export function removeVisualizingSupplierIdFromLocalStorage() {
	delete localStorage.visualizingSupplierId
}
export function getVisualizingSupplierIdFromLocalStorage() {
	const supplierId = localStorage.getItem('visualizingSupplierId')
	if (supplierId) {
		return JSON.parse(supplierId)
	}
	return null
}

export function addVisualizingSupplierIdFromLocalStorage(id) {
	localStorage.setItem('visualizingSupplierId', id)
}

export function addVerificationInternalMessageStateToLocalStorage(orderId, state) {
	const currentValue = localStorage.getItem('verificationInternalMessageState')
	if (!currentValue) {
		localStorage.setItem('verificationInternalMessageState', JSON.stringify({ [orderId]: state }))
	} else {
		const currentState = JSON.parse(currentValue)
		localStorage.setItem('verificationInternalMessageState', JSON.stringify({ ...currentState, [orderId]: state }))
	}
}
export function getVerificationInternalMessageStateFromLocalStorage(orderId) {
	const currentValue = localStorage.getItem('verificationInternalMessageState')
	if (currentValue) {
		const currentState = JSON.parse(currentValue)
		return currentState[orderId]
	}
	return null
}

export function addVerificationBlueprintSeenStateToLocalStorage(orderId, state) {
	const currentValue = localStorage.getItem('verificationBlueprintSeenState')
	if (!currentValue) {
		localStorage.setItem('verificationBlueprintSeenState', JSON.stringify({ [orderId]: state }))
	} else {
		const currentState = JSON.parse(currentValue)
		localStorage.setItem('verificationBlueprintSeenState', JSON.stringify({ ...currentState, [orderId]: state }))
	}
}
export function getVerificationBlueprintSeenStateFromLocalStorage(orderId) {
	const currentValue = localStorage.getItem('verificationBlueprintSeenState')
	if (currentValue) {
		const currentState = JSON.parse(currentValue)
		return currentState[orderId]
	}
	return null
}
