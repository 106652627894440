// TODO get from database?
export const BINDING_NOTE_TYPE = {
	CUT_AND_GLUED_OR_WELDED: 1,
	SIZE_OR_WEIGHT: 2,
	GEOMETRY: 3,
	INTERNAL_RADII: 4,
	THREADS_OR_PITCHES: 5,
	DRILL_DIAMETER: 6,
	MATERIAL: 7,
	TOLERANCES: 8,
	UNDERCUTS: 9,
	FINISH: 10,
	OTHER: 11,
}

export const BINDING_NOTE_TYPES = [
	BINDING_NOTE_TYPE.CUT_AND_GLUED_OR_WELDED,
	BINDING_NOTE_TYPE.SIZE_OR_WEIGHT,
	BINDING_NOTE_TYPE.GEOMETRY,
	BINDING_NOTE_TYPE.INTERNAL_RADII,
	BINDING_NOTE_TYPE.THREADS_OR_PITCHES,
	BINDING_NOTE_TYPE.DRILL_DIAMETER,
	BINDING_NOTE_TYPE.MATERIAL,
	BINDING_NOTE_TYPE.TOLERANCES,
	BINDING_NOTE_TYPE.UNDERCUTS,
	BINDING_NOTE_TYPE.FINISH,
	BINDING_NOTE_TYPE.OTHER,
]
