import { getDate } from '../Utils/StringUtilities'

export const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000

export const checkIsWorkingDay = (day, calendar) => {
	const checkingDate = new Date(day)
	let isWorkingDay = false
	switch (checkingDate.getDay()) {
		case 0:
			isWorkingDay = calendar.workdays.Sunday
			break
		case 1:
			isWorkingDay = calendar.workdays.Monday
			break
		case 2:
			isWorkingDay = calendar.workdays.Tuesday
			break
		case 3:
			isWorkingDay = calendar.workdays.Wednesday
			break
		case 4:
			isWorkingDay = calendar.workdays.Thursday
			break
		case 5:
			isWorkingDay = calendar.workdays.Friday
			break
		case 6:
			isWorkingDay = calendar.workdays.Saturday
			break
	}
	if (isWorkingDay) {
		for (const holiday of calendar.holidays) {
			const holidayDate = new Date(holiday)
			if (
				checkingDate.getFullYear() === holidayDate.getFullYear() &&
				checkingDate.getMonth() === holidayDate.getMonth() &&
				checkingDate.getDate() === holidayDate.getDate()
			)
				isWorkingDay = false
			continue
		}
	}
	return isWorkingDay
}

export const chooseRealDate = (realDate, estimatedDate, t) => {
	if (realDate != null) return `${getDate(realDate)} (${t('real', { ns: 'naming' })})`
	if (estimatedDate != null) return `${getDate(estimatedDate)} (${t('estimated', { ns: 'naming' })})`
	return ''
}

export const checkIsFebruary29 = (date) => {
	return date.getDate() === 29 && date.getMonth() === 1
}

export const substractDates = (startDate, endDate) => {
	const differenceInMilliseconds = endDate - startDate
	const diasDeDiferencia = differenceInMilliseconds / DAY_IN_MILLISECONDS
	return Math.round(diasDeDiferencia) + 1
}

export const showDate = (dateString) => {
	return dateString == null ? '' : (new Date(dateString)).toLocaleDateString()
}

export const getFirstDayOfCurrentMonth = () => {
	const date = new Date()
	return new Date(date.getFullYear(), date.getMonth(), 1)
}

export const getLastDayCurrentMonth = () => {
	const date = new Date()
	return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}

const milisecondsToDays = (miliseconds) => {
	return miliseconds / (1000 * 60 * 60 * 24)
}

export const isDateOlderThan = ({ date, days }) => {
	const today = new Date()
	const oldDate = new Date(date)
	const differenceInMiliseconds = today - oldDate
	const daysOld = milisecondsToDays(differenceInMiliseconds)
	return daysOld >= days
}

/**
 * @returns true if date1 is older than date2
 * @returns false if date1 is newer than date2
 */
export const isDateOlder = (date1, date2) => {
	if (date1 == null || date2 == null) return false
	return new Date(date1) < new Date(date2)
}

export const getPaymentDueDate = (date, daysPostponed, paymentDay1, paymentDay2) => {
	const startingDate = new Date(date)
	let paymentDueDate
	if (daysPostponed == 30) {
		startingDate.setMonth(startingDate.getMonth() + 1)
	} else {
		startingDate.setDate(startingDate.getDate() + daysPostponed)
	}
	paymentDueDate = new Date(startingDate)
	if (paymentDay1 == 'billing' || paymentDay2 == 'billing') {
		return paymentDueDate
	}
	if (paymentDay2 == 'not_pertain') {
		if (paymentDueDate.getDate() > paymentDay1) {
			paymentDueDate.setDate(paymentDay1)
			paymentDueDate.setMonth(paymentDueDate.getMonth() + 1)
		} else {
			paymentDueDate.setDate(paymentDay1)
		}
		return paymentDueDate
	}
	const diffDay1 = Number(paymentDay1) - paymentDueDate.getDate()
	const diffDay2 = Number(paymentDay2) - paymentDueDate.getDate()
	if (diffDay1 >= 0) {
		if (diffDay2 >= 0) {
			paymentDueDate.setDate(Math.min(Number(paymentDay1), Number(paymentDay2)))
		} else {
			paymentDueDate.setDate(paymentDay1)
		}
	} else {
		if (diffDay2 >= 0) {
			paymentDueDate.setDate(paymentDay2)
		} else {
			paymentDueDate.setDate(Math.min(Number(paymentDay1), Number(paymentDay2)))
			paymentDueDate.setMonth(paymentDueDate.getMonth() + 1)
		}
	}
	return paymentDueDate
}

export const isInDateRange = (date, startDate, endDate) => {
	const END_DATE_TIME = '23:59:59'
	return new Date(date) > new Date(startDate) && new Date(date) <= new Date(`${endDate} ${END_DATE_TIME}`)
}
