import { Flag } from 'semantic-ui-react'

export const INITIAL_STATE = {
	firstName: null,
	officialName: null,
	address: null,
	country: null,
	state: null,
	contactPerson: null,
	mobilePhone: null,
	mobilePhonePrefix: null,
	language: null,
	phoneNumber: null,
	phonePrefix: null,
	formatDate: null,
	holidays: [],
	techTypes: null,
	shipmentDays: null,
	workDays: [],
	currency: null,
	timeZone: null,
	email: null,
	reliabilityMarginDays: null,
}

export const LANGUAGE_OPTIONS = (t) => {
	return [
		{
			value: 'es',
			label: (
				<span>
					<Flag name="es" />{t('es', { ns: 'languages' })}
				</span>
			),
		},
		{
			value: 'us',
			label: (
				<span>
					<Flag name="us" />{t('en', { ns: 'languages' })}
				</span>
			),
		},
		{
			value: 'cn',
			label: (
				<span>
					<Flag name="cn" /> {t('cn', { ns: 'languages' })}
				</span>
			),
		},
	]
}
export const FORMAT_DATE_OPTIONS = [
	{ value: 'Y-m-d', label: 'Y-m-d' },
	{ value: 'd-m-Y', label: 'd-m-Y' },
]

export const TECHNOLOGY_OPTIONS = [
	{ value: 1, label: 'CNC' },
	{ value: 2, label: '3D' },
	{ value: 3, label: 'LASER' },
	{ value: 4, label: 'VC' },
	{ value: 5, label: 'IM' },
]

export const WORKDAYS_OPTIONS = (t) => [
	{ value: 1, label: t('monday', { ns: 'naming' }) },
	{ value: 2, label: t('tuesday', { ns: 'naming' }) },
	{ value: 3, label: t('wednesday', { ns: 'naming' }) },
	{ value: 4, label: t('thursday', { ns: 'naming' }) },
	{ value: 5, label: t('friday', { ns: 'naming' }) },
	{ value: 6, label: t('saturday', { ns: 'naming' }) },
	{ value: 7, label: t('sunday', { ns: 'naming' }) },
]

export const CURRENCY_OPTIONS = [
	{
		value: 'Euro',
		label: (
			<div className="d-flex flex-row align-items-center">
				<i className="bx bx-euro mx-2 fs-4"></i>
				Euro
			</div>
		),
	},
	{
		value: 'Dollar',
		label: (
			<div className="d-flex flex-row align-items-center">
				<i className="bx bx-dollar mx-2 fs-4"></i>
				Dollar
			</div>
		),
	},
	{
		value: 'Pound',
		label: (
			<div className="d-flex flex-row align-items-center">
				<i className="bx bx-pound mx-2 fs-4"></i>
				Pound
			</div>
		),
	},
]

export const REQUIRED_FIELDS = [
	'firstName',
	'officialName',
	'address',
	'country',
	'state',
	'contactPerson',
	'language',
	'formatDate',
	'techTypes',
	'shipmentDays',
	'workDays',
	'timeZone',
	'email',
]
