import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { getPartFinish, getPartMaterial } from 'common/Utils/PartUtils'
import { getDate } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PartThumbnail from 'components/Common/PartThumbnail'
import SimpleTable from 'components/Common/SimpleTable'
import { QUOTE_DETAIL_ROUTE_NO_ID } from 'constants/appRoutes'
import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getSimilarQuoteParts } from 'store/actions'
import OfferTechnologies from '../../OfferList/OfferTechnology'

export const SimilarPartsModal = ({
	partIds,
	isOpen,
	handleOnCloseModal,
	supplierId,
	getSimilarQuoteParts,
	isLoading,
	error,
	parts,
	t,
	i18n: { language },
	partConfigOptions,
}) => {

	useEffect(() => {
		if (partIds && partIds.length > 0) {
			getSimilarQuoteParts(partIds, supplierId)
		}
	}, [partIds])

	const getTableBody = () => {
		return parts.map(quotePartInfo => {
			const { part, quote } = quotePartInfo
			const partIdTD = <div className='d-flex flex-column'>
				<span>{part.id}</span>
				<a href={`${QUOTE_DETAIL_ROUTE_NO_ID}${part.offerId}`} target="_blank" rel='noreferrer' >{part.offerId}</a>
			</div>

			const partMaterial = getPartMaterial({ part, partConfigOptions, language })

			const partFinish = getPartFinish({ part, partConfigOptions, language })
			const stlData = {
				partId: part.id,
				partName: part.name,
				size: part.size,
				weight: part.weight,
			}

			return (
				<tr key={`similar_parts_table_${part.id}`}>
					<td>{partIdTD}</td>
					<td>{getDate(quote.creationDate)}</td>
					<td><PartThumbnail stlData={stlData} propsStlUrl={part.fileLinks?.stlModel} propsImageUrl={part?.files?.image} /></td>
					<td>{<OfferTechnologies technologies={[part.configuration.technologyTypeId]} partConfigOptions={partConfigOptions} />}</td>
					<td>{partMaterial}</td>
					<td>{partFinish}</td>
					<td>{roundAtDecimals(part.size.x, 1)}</td>
					<td>{roundAtDecimals(part.size.y, 1)}</td>
					<td>{roundAtDecimals(part.size.z, 1)}</td>
					<td>{roundAtDecimals(part.volume, 1)}</td>

				</tr>
			)
		})
	}

	const renderPartsTable = () => {
		return (
			<SimpleTable
				header={['IDs', 'date_qotation', 'part', 'technology', 'material', 'finish', 'x', 'y', 'z', 'v']}
				getTableBody={getTableBody}
			/>
		)
	}

	const partsTableMemo = useMemo(() => {
		return parts.length > 0 ? renderPartsTable() : <div>{t('no-parts')}</div>
	}, [parts])

	return (
		<Modal
			isOpen={isOpen}
			title={t('similar-parts')}
			className="hugeModal"
			closeModal={() => { handleOnCloseModal() }}
			body={<div className='m-4'>{
				error ? <Alert type="danger" message={error} /> : isLoading ? <Loader /> : partsTableMemo
			}</div>}
		/>
	)
}

SimilarPartsModal.propTypes = {
	isOpen: PropTypes.bool,
	partIds: PropTypes.array,
	handleOnCloseModal: PropTypes.func,
	supplierId: PropTypes.number,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	getSimilarQuoteParts: PropTypes.func,
	parts: PropTypes.array,
	t: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

const mapStateToProps = (state) => ({
	isLoading: state.Quotes.isLoadingSimilarQuoteParts,
	error: state.Quotes.errorSimilarQuoteParts,
	parts: state.Quotes.similarQuoteParts,
	partConfigOptions: state.Parts.partConfigOptions,
})

const mapDispatchToProps = { getSimilarQuoteParts }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withTranslation()(SimilarPartsModal)))
