import { getDate, getStatusClassName } from 'common/Utils/StringUtilities'
import Modal from 'components/Common/Modal/Modal'
import OfferTechnologies from 'pages/OfferList/OfferTechnology'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getFiltredParts } from 'store/actions'
import Alert from './Alert'
import Loader from './Loader'
import OptionsDropdown from './OptionsDropdown'
import { PartName } from './PartName'
import TextInput from './TextInput'

let controller = new AbortController()
let timeoutId = null

export const PartsFinder = ({
	filtredParts,
	getFiltredParts,
	error,
	isLoading,
	t,
	partConfigOptions,
}) => {
	const defaultClassName = 'm-2 d-flex align-items-center justify-content-start fs-6'
	const filteredFieldsOptions = [
		{
			label: (
				<div className={defaultClassName}>
					<i className="bx bx-circle me-1"></i>
					<span>{t('offer_id', { ns: 'naming' })}</span>
				</div>
			),
			value: { table: 'Offers', column: 'id' },
		},
		{
			label: (
				<div className={defaultClassName}>
					<i className="bx bx-circle me-1"></i>
					<span>{t('part_id', { ns: 'naming' })}</span>
				</div>
			),
			value: { table: 'Parts', column: 'id' },
		},
		{
			label: (
				<div className={defaultClassName}>
					<i className="bx bx-circle me-1"></i>
					<span>{t('part_name', { ns: 'naming' })}</span>
				</div>
			),
			value: { table: 'Parts', column: 'file3d' },
		},
		{
			label: (
				<div className={defaultClassName}>
					<i className="bx bx-circle me-1"></i>
					<span>{t('blueprint', { ns: 'naming' })}</span>
				</div>
			),
			value: { table: 'Parts', column: 'blueprint' },
		},
		{
			label: (
				<div className={defaultClassName}>
					<i className="bx bx-circle me-1"></i>
					<span>{t('organization', { ns: 'naming' })}</span>
				</div>
			),
			value: { table: 'Organization', column: 'organizationName' },
		},
		{
			label: (
				<div className={defaultClassName}>
					<i className="bx bx-circle me-1"></i>
					<span>{t('email', { ns: 'naming' })}</span>
				</div>
			),
			value: { table: 'Account', column: 'email' },
		},
		{
			label: (
				<div className={defaultClassName}>
					<i className="bx bx-circle me-1"></i>
					<span>{t('firstName', { ns: 'naming' })}</span>
				</div>
			),
			value: { table: 'Account', column: 'firstName' },
		},
		{
			label: (
				<div className={defaultClassName}>
					<i className="bx bx-circle me-1"></i>
					<span>{t('lastName', { ns: 'naming' })}</span>
				</div>
			),
			value: { table: 'Account', column: 'lastName' },
		},

	]

	const defaultFilteredFields = filteredFieldsOptions.map(option => option.value)

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [keyword, setKeyword] = useState('')
	const [filteredFields, setFilteredFields] = useState(defaultFilteredFields)
	const [dateRange, setDateRange] = useState(null)

	useEffect(() => {
		handleGetParts()
	}, [keyword, dateRange])

	const handleGetParts = () => {
		if (keyword.length > 0) {
			timeoutId && clearTimeout(timeoutId)
			timeoutId = setTimeout(() => {
				callGetFiltredParts()
			}, 500)
		}
	}

	const callGetFiltredParts = () => {
		controller.abort()
		controller = new AbortController()
		getFiltredParts({ keyword, filteredFields, dateRange }, controller.signal)
	}

	const handleOnChangeDates = dateStr => {
		const SEPARATOR = 'to'
		const DEFAULT_INITIAL_DATE_HOUR = '00:00:00'
		const DEFAULT_END_DATE_HOUR = '23:59:59'
		if (!dateStr.includes(SEPARATOR)) setDateRange(null)
		const [startDate, endDate] = dateStr.split(SEPARATOR).map(date => date.trim())
		if (!startDate || !endDate) return
		setDateRange({
			startDate: `${startDate} ${DEFAULT_INITIAL_DATE_HOUR}`,
			endDate: `${endDate} ${DEFAULT_END_DATE_HOUR}`,
		})
	}

	const parseDateRange = () => {
		const { startDate, endDate } = dateRange
		return `${getDate(startDate)} - ${getDate(endDate)}`
	}

	const mapPart = part => {
		const offerHref = `/offer/${part.offerId}`
		const crmHref = `/crm-user-detail/${part.account.accountId}`

		return (
			<div key={part.partId} className="h-100 w-100 d-flex flex-row my-4 justify-content-center">
				<div className="d-flex flex-column justify-content-center align-items-center col-3">
					<img width={100} src={part.signedImageUrl}></img>
				</div>
				<div className="d-flex flex-column ms-4 align-items-center col-9 ms-2">
					<div className="d-flex flex-row w-100">
						<div className="d-flex flex-column col-5 me-3 justify-content-start" style={{ width: '9rem', maxWidth: '9rem' }}>
							<div className="d-flex flex-column">
								{part.statusId && (
									<div>
										<span className={'me-2 ' + getStatusClassName(part.statusId)}>
											{t(`offer_status_${part.statusId}`, { ns: 'status' })}
										</span>
									</div>
								)}
								{part.technologyId &&
									<OfferTechnologies className="m-0" technologies={[part.technologyId]} t={t} partConfigOptions={partConfigOptions} />
								}
							</div>
							<div className="d-flex flex-column ms-1">
								<span>{part.partId}</span>
								<strong>
									<a className="me-1 cursor-pointer" href={offerHref}>
										{part.offerId}
									</a>
								</strong>
								<span>{getDate(part.creationDate)}</span>
							</div>
						</div>
						<div className="d-flex flex-column ms-4 w-100 col-7 ">
							<div className="d-flex align-items-center">
								<i className='bx bxs-file-doc me-1'></i>
								<PartName name={part.partName} maxLength={30} />
							</div>
							<div className="d-flex align-items-center">
								<i className='bx bxs-file-pdf me-1'></i>
								<PartName name={part.blueprint} maxLength={30} />
							</div>

							<div>
								<strong className="d-flex align-items-center">
									<i className='bx bx-buildings me-1' ></i>
									<PartName name={part.account.organizationName} />
								</strong>
							</div>

							<div className="d-flex align-items-center">
								<i className='bx bx-envelope me-1' ></i>
								<PartName name={part.account.email} />
							</div>
							<a href={crmHref}>

								<div className="text-primary d-flex d-flex align-items-center">
									<i className="bx bxs-user me-1"></i>
									<span className="me-1">{part.account.firstName}</span>
									<PartName name={part.account.lastName} />
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const inputActions = (
		<div className="d-flex align-items-center">
			<OptionsDropdown options={filteredFieldsOptions} selectedOptions={filteredFields} setSelectedOptions={setFilteredFields} />
			<div className="ms-1">
				<Flatpickr
					options={{
						mode: 'range',
						dateFormat: 'Y-m-d',
						wrap: true,
					}}
					onChange={(e, dateStr) => handleOnChangeDates(dateStr)}
				>
					<div className="flatpickr" data-input>
						<input type="text" className="d-none" placeholder="Select Date.." />
						<a className="input-button d-flex align-items-center" title="toggle" data-toggle>
							<i className="bx bx-calendar fs-3 text-secondary"></i>
						</a>
					</div>
				</Flatpickr>
			</div>
		</div>
	)

	return (
		<div>
			<div onClick={() => setIsModalOpen(true)} className="btn btn-secondary">{t('search_parts', { ns: 'naming' })}</div>
			<Modal
				isOpen={isModalOpen}
				closeModal={() => setIsModalOpen(false)}
				className="modal-dialog-centered"
				size="lg"
				body={
					<div className="p-4 d-flex flex-column mt-3">

						<h5 className="w-100 d-flex justify-content-center align-items-center">
							<i className="bx bx-search-alt me-1"></i> {t('search_parts', { ns: 'naming' })}
						</h5>
						<div className="d-flex justify-content-center align-items-center">
							<TextInput value={keyword} setValue={setKeyword} className="w-75 m-0" inputActions={inputActions} />
						</div>
						<div className="d-flex justify-content-center mt-4">
							{isLoading ? (
								<Loader className="fs-1" />
							) : error ? (
								<Alert type="danger" fitContent message={error} />
							) : (
								<div>

									{dateRange && (
										<div className="d-flex justify-content-center align-items-center">
											<span className="me-1">{parseDateRange()}</span>
											<div onClick={() => { setDateRange(null) }} className="btn btn-outline-danger p-0 m-0 d-flex align-items-center h-auto"><i className='m-0 p-0 bx bx-x'></i> </div>
										</div>
									)}

									{filtredParts != null && keyword.length !== 0 && (
										<div>
											{filtredParts.length == 0 ? (
												<p className="text-center">{t('no_results_found', { ns: 'naming' })}</p>
											) : (
												<div className="h-100 w-100 d-flex justify-content-center flex-column">
													{filtredParts.map(part => mapPart(part))}
												</div>
											)}
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				}
			/>
		</div>
	)
}

PartsFinder.propTypes = {
	filtredParts: PropTypes.array,
	getFiltredParts: PropTypes.func,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	t: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

const mapStateToProps = state => ({
	filtredParts: state.Parts.filtredParts,
	isLoading: state.Parts.isLoading,
	error: state.Parts.error,
	partConfigOptions: state.Parts.partConfigOptions,
})

const mapDispatchToProps = {
	getFiltredParts,
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PartsFinder))
