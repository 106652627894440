import { getCountryCodeByCountryName, getCountryColorByCountryCode } from 'common/Utils/CountryUtils'
import PropTypes from 'prop-types'
import ReactApexChart from 'react-apexcharts'
import { connect } from 'react-redux'

export const CountriesPercentageChart = ({ series, title, labels }) => {


	const options = {
		chart: {
			width: 380,
			type: 'pie',
		},
		colors: labels.map((label) => getCountryColorByCountryCode(getCountryCodeByCountryName(label))),
		labels,
		legend: {
			show: true,
		},
		responsive: [{
			breakpoint: 480,
			options: {
				chart: {
					width: 200,
				},

			},
		}],
	}

	return (
		<div style={{ height: '42.5rem', paddingTop: '5rem' }}>
			<ReactApexChart options={options} series={series} type="pie" />
			<div className='d-flex justify-content-center w-100'>
				<span>{title}</span>
			</div>
		</div >
	)
}

CountriesPercentageChart.propTypes = {
	series: PropTypes.array,
	title: PropTypes.string,
	labels: PropTypes.array,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CountriesPercentageChart)
