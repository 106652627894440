function getShipmentCostByWeight(weight, shipmentPricingList) {
	shipmentPricingList.sort((a, b) => a.weight - b.weight)
	for (const shipmentPricing of shipmentPricingList) {
		if (weight <= shipmentPricing.weight) return Number(shipmentPricing.price)
	}
	return Number(shipmentPricingList.at(-1).price)
}
export function calculateShipmentCosts(
	idSupplier,
	supplierCountry,
	partsWeightInGrams,
	volumetricWeight,
	shipmentPricingList,
) {
	const COST_FROM_SPAIN = 15
	const FUEL_OVERCOST_MULTIPLIER = 1.19

	if (idSupplier === process.env.REACT_APP_IMAS_WORKSHOP_SUPPLIER_ID) return 0
	if (supplierCountry === 'ES') return COST_FROM_SPAIN

	const costByWeight = getShipmentCostByWeight(
		partsWeightInGrams / 1000,
		shipmentPricingList,
	)

	const costByVolumetricWeight = getShipmentCostByWeight(
		volumetricWeight,
		shipmentPricingList,
	)

	return ((costByWeight > costByVolumetricWeight ? costByWeight : costByVolumetricWeight) * FUEL_OVERCOST_MULTIPLIER)
}

export const getEstimateArriveDate = (shipmentDateEstimate, shipmentDateReal, shipmentDateDelay, supplierId, suppliers) => {
	const IMAS_ID = process.env.REACT_APP_IMAS_WORKSHOP_SUPPLIER_ID
	const supplier = getSupplierById(supplierId, suppliers)
	const holidays = getSupplierById(IMAS_ID, suppliers) && getSupplierById(IMAS_ID, suppliers).holidays
	const shipmentDays = supplier && supplier.shipmentDays
	const shipmentDate = getShipmentDate(shipmentDateEstimate, shipmentDateReal, shipmentDateDelay)
	return calculateShipmentDate(shipmentDate, shipmentDays, holidays)
}

export const getSupplierById = (supplierId, suppliers) => {
	return suppliers.find(supplier => supplier.accountId === supplierId)
}

export const getShipmentDate = (shipmentDateEstimate, shipmentDateReal, shipmentDateDelay) => {
	if (shipmentDateReal != null) {
		return shipmentDateReal
	} else if (shipmentDateDelay != null) {
		return shipmentDateDelay
	} else {
		return shipmentDateEstimate
	}
}

export const calculateShipmentDate = (shipmentDate, supplierDays, holidays) => {
	const date = new Date(shipmentDate)
	const day = date.getDate()
	const month = date.getMonth()
	const year = date.getFullYear()
	let extraDays = supplierDays || 0
	if (holidays != null && typeof holidays === 'string') {
		holidays = holidays
			.split(',')
			.map(holiday => new Date(holiday))
			.sort((a, b) => a - b)
	}
	if (holidays && holidays.length !== 0) {
		for (const holiday of holidays) {
			while (isWorkDate(new Date(year, month, day + extraDays))) {
				extraDays += 1
			}
			if (checkSameDate(new Date(year, month, day + extraDays), new Date(holiday.getFullYear(), holiday.getMonth(), holiday.getDate()))) {
				extraDays += 1
			}
		}
	}
	while (isWorkDate(new Date(year, month, day + extraDays))) {
		extraDays += 1
	}

	return new Date(year, month, day + extraDays)
}

export const checkSameDate = (date1, date2) => {
	return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()
}

export const isWorkDate = date => {
	return date.getDay() === 0 || date.getDay() === 6
}
