import { bannedDomains } from '../../constants/bannedDomains'
import { weekdays } from '../../constants/dates'
import { getLanguage } from './LocalStorageUtilities'
import { roundAtDecimals } from './NumberUtilities'

const GREEN = 1
const YELLOW = 2
const RED = 3
const DELIVERED = 4

export function capitalizeFirstCharacter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export function addSpacesOnCamelcase(string) {
	return string.replace(/([a-z])([A-Z])/g, '$1 $2')
}

export function isStringInList(searchTerm, stringArray) {
	if (!stringArray) return false

	return stringArray.find(element => element == searchTerm)
}

export function adaptWeekday(weekdayNumber, language) {
	if (!language) language = 'en'
	const weekday = weekdays[weekdayNumber]
	if (weekday) return weekday[language]
	return undefined

}
export function adaptTimerString(hours, minutes, seconds) {
	if (hours < 10) hours = '0' + hours.toString()
	if (minutes < 10) minutes = '0' + minutes.toString()
	if (seconds < 10) seconds = '0' + seconds.toString()

	return hours + ':' + minutes + ':' + seconds
}

export function adaptDateOnLocale(year, month, day, locale) {

	const separator = locale === 'en' ? '-' : '/'
	month++

	if (month < 10) month = `0${month}`

	if (day < 10) day = `0${day}`

	if (locale == 'en') return year + separator + month + separator + day
	return day + separator + month + separator + year
}

export function getDateFormatOnLocale(locale) {
	let separator
	switch (locale) {
		case 'es':
		case 'fr':
		case 'it':
		case 'de':
			separator = '/'
			return 'd' + separator + 'm' + separator + 'Y'

		default:
			separator = '-'
			return 'Y' + separator + 'm' + separator + 'd'
	}
}

export function createCSV(header, data) {
	const dataToString = data
		.map(obj => {
			return Object.entries(obj)
				.map(entry => {
					return `${entry[1]}`
				})
				.join(';')
		})
		.join('\n')

	const headerLine = Object.entries(header)
		.map(entry => {
			return `${entry[1]}`
		})
		.join(';')

	return headerLine + '\n' + dataToString
}

export function dateArrayToObject(date) {
	const START_DATE_POSITION = 0
	const END_DATE_POSITION = 1

	const startDate = date[START_DATE_POSITION]
	const endDate = date[END_DATE_POSITION]

	return { startingDate: startDate, endingDate: endDate }
}

// From "yyyy-mm-dd to yyyy-mm-dd" to { startingDate: startDate, endingDate: endDate }
export function objectToDateString(dateArray) {
	const START_DATE_POSITION = 0
	const END_DATE_POSITION = 2

	const dateRangeSplit = dateArray.split(/\s+/)

	const startDate = new Date(dateRangeSplit[START_DATE_POSITION])
	const endDate = new Date(dateRangeSplit[END_DATE_POSITION])

	return [startDate, endDate]
}

// From "Fri Jul 01 2022 00:00:00 GMT+0200 (hora de verano de Europa central)" to 2022/07/01
export function dateStringToStandard(date) {
	const newDate = new Date(date)

	const transformedDate = newDate.toISOString().slice(0, 10).replace(/-/g, '/')

	return transformedDate
}

export function mapDateToSqlFormat(date) {
	const dateSQL = date.toISOString().replace(/T|Z/g, ' ')
	return dateSQL
}

export const getDate = (dateStr, language) => {
	if (dateStr == null) return
	const date = new Date(dateStr)
	const day = date.getDate()
	const month = date.getMonth()
	const year = date.getFullYear()
	const localStoragelanguage = getLanguage()
	return adaptDateOnLocale(
		year,
		month,
		day,
		language ? language : localStoragelanguage,
	)
}

export const getDateWithHour = (dateStr, language) => {
	if (dateStr == null) return
	const date = new Date(dateStr)
	const day = date.getDate()
	const month = date.getMonth()
	const year = date.getFullYear()
	const hour = date.getHours()
	const minutes = date.getMinutes()
	const seconds = date.getSeconds()
	const localStoragelanguage = getLanguage()
	const dateOnLocale = adaptDateOnLocale(
		year,
		month,
		day,
		language ? language : localStoragelanguage,
	)
	const time = adaptTimerString(hour, minutes, seconds)

	return dateOnLocale + ' ' + time
}

export const getHour = dateStr => {
	const date = new Date(dateStr)
	const hours = date.getHours()
	const minutes = date.getMinutes()
	const seconds = date.getSeconds()
	return adaptTimerString(hours, minutes, seconds)
}

export const getWeekDay = (dateStr, language) => {
	if (!dateStr) return
	const date = new Date(dateStr)
	const days = date.getDay()
	return weekdays[days][language]
}

export const getFilterShipmentOrderDate = (date, realShipmentDate) => {
	if (realShipmentDate) {
		return DELIVERED
	}
	const shipmentDate = getDateWithoutHour(new Date(date))
	const currentDate = getDateWithoutHour(new Date())
	const remainingDays = Math.floor((shipmentDate - currentDate) / 86400000)
	switch (true) {
		case 1 == remainingDays || 0 == remainingDays:
			return YELLOW
		case 0 > remainingDays:
			return RED
		case 0 < remainingDays:
			return GREEN
	}
}

export const GetStatusClass = value => {
	const NEW = 10
	const REQUESTED = 20
	const QUOTED = 30
	const UPLOADED = 40
	const REJECTED = 91
	const RECEIVED = 80

	switch (value) {
		case NEW:
			return 'rounded-pill bg-secondary text-white'
		case UPLOADED:
			return 'rounded-pill bg-green text-white'
		case REQUESTED:
			return 'rounded-pill bg-yellow text-white'
		case REJECTED:
			return 'rounded-pill bg-red text-white'
		case RECEIVED:
			return 'rounded-pill bg-blue text-white'
		case QUOTED:
			return 'rounded-pill bg-orange text-white'
	}
}

export const getFilterArriveShipingOrderDate = (
	estArrivedate,
	estShipmentDate,
	realArriveDate,
	realShipmentDate,
) => {
	if (realShipmentDate) {
		return DELIVERED
	}
	const definitiveArriveDate = realArriveDate ? realArriveDate : estArrivedate
	const definitiveShipmentDate = realShipmentDate ? realShipmentDate : estShipmentDate
	const arriveDate = new Date(definitiveArriveDate)
	const shipingDate = new Date(definitiveShipmentDate)
	const remainingDays = Math.floor((shipingDate - arriveDate) / 86400000)
	switch (true) {
		case 1 == remainingDays || 0 == remainingDays:
			return YELLOW
		case 0 > remainingDays:
			return RED
		case 0 < remainingDays:
			return GREEN
	}
}

export const getFileName = fileName => {
	if (!fileName)
		return ''
	const extension = getFileExtension(fileName)
	const splittedName = fileName.split(`.${extension}`)
	return splittedName[0]
}
export const getFileExtension = fileName => {
	if (!fileName)
		return ''
	const extension = fileName.split('.').slice(-1)[0]
	return extension
}

export const getDateWithoutHour = (date) => {
	const day = date.getDate()
	const month = date.getMonth()
	const year = date.getFullYear()
	return new Date(year, month, day)
}

export const getMeasure = (number, measure) => {
	if (number >= 1000) {
		return `${roundAtDecimals(number / 1000, 1)} k${measure}.`
	}
	return `${roundAtDecimals(number, 0)} ${measure}.`
}

export const getDateWithDay = (dateStr) => {
	const date = new Date(dateStr)
	if (date == 'Invalid Date') return
	const language = getLanguage()
	return `${getWeekDay(dateStr, language || 'es')} ${getDate(dateStr, language || 'es')}`
}

export const getStatusClassName = status => {
	const statusDefault = 'rounded-pill text-white w100 '
	const UPLOADED = 40
	const REQUESTED = 20
	const NEW = 10
	const QUOTED = 30
	const REJECTED = 91
	const HIDDEN = 92
	const REJECTED_PENDING = 93

	const ORDER_PAYMENT_PENDING = 'order_status_10'
	const ORDER_PREPARING = 'order_status_20'
	const ORDER_PAYMENT_ERROR = 'order_status_30'
	const ORDER_ON_PRODUCTION = 'order_status_40'
	const ORDER_SENT = 'order_status_60'
	const ORDER_DELIVERED = 'order_status_70'
	const ORDER_CANCELLED = 'order_status_80'
	const ORDER_REFUNDED = 'order_status_90'

	const PRODUCTION_ACCEPTANCE_PENDING = 'production_status_1'
	const PRODUCTION_IN_PROCESS = 'production_status_2'
	const PRODUCTION_PICKED_UP_BY_COURIER = 'production_status_3'
	const PRODUCTION_DELIVERING = 'production_status_4'
	const PRODUCTION_CANCELLED = 'production_status_5'
	const PRODUCTION_COMPLETED = 'production_status_6'
	const PRODUCTION_DENIED = 'production_status_7'
	const PRODUCTION_QUALITY_CONTROL = 'production_status_8'
	const PRODUCTION_REFUNDED = 'production_status_9'

	const QUOTE_STATUS_REQUESTED = 'quote_status_20'
	const QUOTE_STATUS_QUOTED = 'quote_status_30'
	const QUOTE_STATUS_REJECTED = 'quote_status_91'
	const QUOTE_STATUS_REJECT_PENDING = 'quote_status_93'

	switch (status) {
		case NEW:
			return statusDefault + 'bg-secondary'
		case REQUESTED:
			return statusDefault + 'bg-yellow'
		case QUOTED:
			return statusDefault + 'bg-orange'
		case UPLOADED:
			return statusDefault + 'bg-green'
		case REJECTED:
			return statusDefault + 'bg-red'
		case HIDDEN:
			return statusDefault + 'bg-red'
		case ORDER_PAYMENT_PENDING:
			return statusDefault + 'bg-secondary'
		case ORDER_PREPARING:
			return statusDefault + 'bg-yellow'
		case ORDER_PAYMENT_ERROR:
			return statusDefault + 'bg-red'
		case ORDER_ON_PRODUCTION:
			return statusDefault + 'bg-orange'
		case ORDER_SENT:
			return statusDefault + 'bg-green'
		case ORDER_DELIVERED:
			return statusDefault + 'bg-blue'
		case ORDER_CANCELLED:
			return statusDefault + 'bg-red'
		case ORDER_REFUNDED:
			return statusDefault + 'bg-red'
		case REJECTED_PENDING:
			return statusDefault + 'bg-danger'
		case PRODUCTION_ACCEPTANCE_PENDING:
			return statusDefault + 'bg-orange'
		case PRODUCTION_IN_PROCESS:
			return statusDefault + 'bg-cyan'
		case PRODUCTION_PICKED_UP_BY_COURIER:
			return statusDefault + 'bg-purple'
		case PRODUCTION_DELIVERING:
			return statusDefault + 'bg-yellow'
		case PRODUCTION_CANCELLED:
			return statusDefault + 'bg-red'
		case PRODUCTION_COMPLETED:
			return statusDefault + 'bg-green'
		case PRODUCTION_DENIED:
			return statusDefault + 'bg-danger'
		case PRODUCTION_QUALITY_CONTROL:
			return statusDefault + 'bg-blue'
		case PRODUCTION_REFUNDED:
			return statusDefault + 'bg-red'
		case QUOTE_STATUS_REQUESTED:
			return statusDefault + 'bg-yellow'
		case QUOTE_STATUS_QUOTED:
			return statusDefault + 'bg-secondary'
		case QUOTE_STATUS_REJECTED:
			return statusDefault + 'bg-red'
		case QUOTE_STATUS_REJECT_PENDING:
			return statusDefault + 'bg-danger'
		case 10:
			return statusDefault + 'bg-pink'
		case 20:
			return statusDefault + 'bg-orange'
		case 30:
			return statusDefault + 'bg-orange'
		case 40:
			return statusDefault + 'bg-green'
		case 50:
			return statusDefault + 'bg-blue'
		case 60:
			return statusDefault + 'bg-success'
		case 70:
			return statusDefault + 'bg-green'
		case 80:
			return statusDefault + 'bg-red'
		case 90:
			return statusDefault + 'bg-blue'
		case 92:
			return statusDefault + 'bg-red'
		default:
			return statusDefault + 'bg-blue'
	}
}

export const getTechnologyClassName = technologyId => {

	const CNC = 1
	const PRINT_3D = 2
	const CUTTING_AND_BENDING = 3
	const VACCUM_CASTING = 4
	const INJECTION = 5

	switch (technologyId) {
		case CNC:
			return 'rounded-pill text-white w100 bg-olive'
		case PRINT_3D:
			return 'rounded-pill text-white w100 bg-turquoise'
		case CUTTING_AND_BENDING:
			return 'rounded-pill text-white w100 bg-purple'
		case VACCUM_CASTING:
			return 'rounded-pill text-white w100 bg-pink'
		case INJECTION:
			return 'rounded-pill text-white w100 bg-orange'

		default:
			return statusDefault + 'bg-olive'
	}
}

export const getRemainingDaysFromCurrentDate = (date, final) => {
	const initialDate = getDateWithoutHour(new Date(date))
	const finalDate = final ? getDateWithoutHour(new Date(final)) : getDateWithoutHour(new Date())
	return Math.floor((initialDate - finalDate) / 86400000)
}

export const getAdaptedDate = (date) => {
	const splitDate = date.split(' ')
	const dateData = splitDate[0].split('/')
	return new Date(`${dateData[2]}/${dateData[1]}/${dateData[0]} ${splitDate[1]}`)
}

export const getS3FileName = (fileLink) => {
	if (!fileLink) return
	const splitFileLink = fileLink.split('/')
	const lastSplit = splitFileLink[splitFileLink.length - 1]
	const fileName = lastSplit.split('?')[0]
	return decodeURIComponent(fileName)
}

export const getDomainByEmail = (email) => {
	if (!email) return ''
	const splitDomainCom = email.split('@')
	const splitDomain = splitDomainCom[1].split('.')
	return splitDomain[0]
}

export const checkIsPublicDomain = (email) => {
	const domain = getDomainByEmail(email)
	return bannedDomains.includes(domain)
}

export const getIdFromUrl = () => {
	const id = window.location.pathname.split('/')
	return parseInt(id[id.length - 1])
}

export const formatDateToMMDDYYYY = (inputDate) => {
	if (!(inputDate instanceof Date)) {
		throw new Error('Invalid date object')
	}

	const month = (inputDate.getMonth() + 1).toString().padStart(2, '0')
	const day = inputDate.getDate().toString().padStart(2, '0')
	const year = inputDate.getFullYear()

	return `${month}/${day}/${year}`
}

export const middleEllipsis = (string, maxLength) => {
	if (string && string.length > (maxLength || 33)) {
		return `${string.substring(0, (maxLength / 2 || 15))}...${string.substring(string.length - (maxLength / 2 || 15), string.length)}`
	}
	return string
}

export const getDeliveryNoteSerial = (date, serialNumber) => {
	return date.slice(2, 4) + new Array(5 - String(serialNumber).length).join('0') + serialNumber
}
