import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { calculateVolumetricWeight, roundAtDecimals, roundCurrency } from 'common/Utils/NumberUtilities'
import { PartConfigTranslations } from 'common/Utils/PartConfigTranslations'
import { calculateShipmentCosts } from 'common/Utils/ShipmentUtils'
import { getDate } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import DownloadFilesButtons from 'components/Common/DownloadFilesButtons'
import Modal from 'components/Common/Modal/Modal'
import PartThumbnail from 'components/Common/PartThumbnail'
import appRoutes from 'constants/appRoutes'
import { OrderStatus } from 'constants/orderStatus'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import MetaTags from 'react-meta-tags'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import Loader from '../../components/Common/Loader'
import {
	getOrderDetail,
	getProductionItemsByOrderId,
	getQuoteRegressionsByPartId,
	getSupplierInfo,
	getSupplierShipmentPricingList,
	getSuppliersByOrderId,
	launchProduction,
	updateOrder,
} from '../../store/actions'
import PartsTable from './PartsTable'
import ProductionComment from './ProductionComment'
import ProductionTab from './ProductionTab'

function GetOrderIdFromURL() {
	const parts = window.location.pathname.split('/')
	return parts[parts.length - 1]
}

const NewProduction = ({
	t,
	history,
	i18n: { language },
	getOrderDetail,
	getSuppliersByOrderId,
	getQuoteRegressionsByPartId,
	newProductionSuppliers,
	orderDetail,
	quoteRegressionsByPartId,
	getProductionItemsByOrderId,
	productionItemsByOrderId,
	supplierShipmentPricingList,
	getSupplierShipmentPricingList,
	getSupplierInfo,
	supplierShippingTiming,
	launchProduction,
	launchProductionSuccess,
	launchProductionFail,
	isLaunchingProduction,
	production,
	error,
	updateOrder,
	partConfigOptions,
}) => {
	const [order, setOrder] = useState(undefined)
	const [orderItems, setOrderItems] = useState([])
	const [orderRegressions, setOrderRegressions] = useState([])
	const [supplierId, setSupplierId] = useState(0)
	const [partsOnProduction, setPartsOnProduction] = useState([])
	const [selectedParts, setSelectedParts] = useState([])
	const [productionSimulation, setProductionSimulation] = useState([])
	const [isRefreshing, setIsRefreshing] = useState(false)
	const [disconformity, setDisconformity] = useState(false)
	const [shipmentDate, setShipmentDate] = useState(null)
	const [isShipmentDateManual, setIsShipmentDateManual] = useState(false)
	const [arrivalDate, setArrivalDate] = useState(null)
	const [isShipmentLate, setIsShipmentLate] = useState(false)
	const [isModalOpen, setModalOpen] = useState(false)
	const [isErrorModalOpen, setErrorModalOpen] = useState(false)
	const [launchProductionComment, setLaunchProductionComment] = useState(null)
	const [supplierItems, setSupplierItems] = useState([])
	const [isModalReminderMessageOpen, setIsModalReminderMessageOpen] = useState(false)
	const [partsIdsWithBlueprints, setPartsIdsWithBlueprints] = useState([])
	const [partIdsAlreadyClicked, setPartIdsAlreadyClicked] = useState([])
	const [countReloadOrder, setCountReloadOrder] = useState(1)


	useEffect(() => {
		const orderId = GetOrderIdFromURL()
		getOrderDetail(orderId)
		getProductionItemsByOrderId({ orderId })
		getSuppliersByOrderId(orderId)
		getSupplierShipmentPricingList()
	}, [])

	useEffect(() => {
		if (launchProductionSuccess) {
			if (production != undefined) {
				history.go()
			}
		}
		if (launchProductionFail) {
			setModalOpen(false)
			setErrorModalOpen(true)
		}
	}, [launchProductionSuccess, launchProductionFail, production])

	useEffect(() => {
		if (productionItemsByOrderId) {
			setPartsOnProduction([...partsOnProduction, ...productionItemsByOrderId])
		}

	}, [productionItemsByOrderId])

	useEffect(() => {
		setOrderItems(() => {
			const items = []
			orderDetail.order &&
				orderDetail.order.orderItems.forEach(orderItem => {
					if (orderItem.part) {
						items.push(
							formatOrderItem(
								{
									...orderItem,
									quantity: undefined,
									totalPrice: undefined,
									part: {
										...orderItem.part,
									},
									customerQuantities: [orderItem.quantity],
								},
								items.length,
							),
						)
					}
				})
			return items
		})
	}, [partsOnProduction])

	useEffect(() => {
		const { items } = getSuppliersParts()
		supplierId !== 0 && getSupplierInfo(supplierId)
		setSupplierItems(items)
	}, [supplierId])

	useEffect(() => {
		const order = formatOrder(orderDetail)
		setOrder(order)
		setOrderItems(() => {
			const items = []
			orderDetail.order &&
				orderDetail.order.orderItems.forEach(orderItem => {
					if (orderItem.part) {
						getQuoteRegressionsByPartId(orderItem.part.id)
						items.push(
							formatOrderItem(
								{
									...orderItem,
									total: undefined,
									quantity: undefined,
									totalPrice: undefined,
									part: {
										...orderItem.part,
									},
									customerQuantities: [orderItem.quantity],
								},
								items.length,
							),
						)
					}
				})
			return items
		})
	}, [orderDetail])


	useEffect(() => {
		if (orderDetail && newProductionSuppliers) {
			if (newProductionSuppliers.length > 0) {
				if (Object.keys(orderDetail).length > 0) {
					const partsWithBlueprints = orderDetail.order.orderItems
						.filter(item => {
							const blueprint = item.part?.files?.blueprint
							const bluePrintModified = item.part?.files?.modifiedBlueprint
							return blueprint || bluePrintModified
						})
						.map(item => item.part.id)

					setPartsIdsWithBlueprints(partsWithBlueprints)

					if (partsWithBlueprints.length > 0 && countReloadOrder == 1) {
						setIsModalReminderMessageOpen(true)
					}
				}
			}
		}

	}, [orderDetail, newProductionSuppliers])

	useEffect(() => {
		if (quoteRegressionsByPartId) {
			let regressionFound = false
			for (const orderRegression of orderRegressions) {
				if (orderRegression.partId == quoteRegressionsByPartId.partId) regressionFound = true
			}
			if (!regressionFound) setOrderRegressions([...orderRegressions, quoteRegressionsByPartId])
		}
	}, [quoteRegressionsByPartId])

	useEffect(() => {
		if (!shipmentDate) {
			setIsShipmentLate(false)
			setIsShipmentDateManual(false)
		}
		setProductionSimulation(getProductionSimulation())
		setIsRefreshing(false)
	}, [selectedParts, isRefreshing, shipmentDate])

	const headerToRender = {
		partId: t('part', { ns: 'naming' }),
		partsRequested: t('partsRequested', { ns: 'naming' }),
		image: t('image', { ns: 'naming' }),
		files: t('files', { ns: 'naming' }),
		description: t('description', { ns: 'naming' }),
		unitPrice: t('unitPrice', { ns: 'naming' }),
		quantity: t('quantity', { ns: 'naming' }),
		totalPrice: t('totalPrice', { ns: 'naming' }),
		comment: t('comment', { ns: 'naming' }),
		offerId: t('offer', { ns: 'naming' }),
	}

	const handleShipmentDate = value => {
		setIsShipmentDateManual(true)
		setShipmentDate(getDate(new Date(value), 'es'))
	}

	const getProductionSimulation = () => {
		const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000
		const checkIsWorkingDay = (day, calendar) => {
			const checkingDate = new Date(day)
			let isWorkingDay = false
			switch (checkingDate.getDay()) {
				case 0:
					isWorkingDay = calendar.workdays.Sunday
					break
				case 1:
					isWorkingDay = calendar.workdays.Monday
					break
				case 2:
					isWorkingDay = calendar.workdays.Tuesday
					break
				case 3:
					isWorkingDay = calendar.workdays.Wednesday
					break
				case 4:
					isWorkingDay = calendar.workdays.Thursday
					break
				case 5:
					isWorkingDay = calendar.workdays.Friday
					break
				case 6:
					isWorkingDay = calendar.workdays.Saturday
					break
			}
			if (isWorkingDay) {
				for (const holiday of calendar.holidays) {
					const holidayDate = new Date(holiday)
					if (
						checkingDate.getFullYear() === holidayDate.getFullYear() &&
						checkingDate.getMonth() === holidayDate.getMonth() &&
						checkingDate.getDate() === holidayDate.getDate()
					)
						isWorkingDay = false
					continue
				}
			}
			return isWorkingDay
		}

		const getAdaptedShipmentDate = () => {
			const shipmentDateData = shipmentDate.split('/')
			const adaptedShipmentDate = `${shipmentDateData[2]}-${shipmentDateData[1]}-${shipmentDateData[0]}`
			return new Date(adaptedShipmentDate)
		}

		const getCalculatedShipmentDate = (productionDays, calendar) => {
			let extraDays = 1
			let i = 0
			let checkingDate = new Date()
			let isWorkingDay

			while (i < productionDays) {
				checkingDate = new Date(new Date().getTime() + (i + extraDays) * DAY_IN_MILLISECONDS)
				isWorkingDay = checkIsWorkingDay(checkingDate, calendar)
				if (isWorkingDay) {
					++i
				} else {
					++extraDays
				}
			}
			return checkingDate
		}

		const calculateArrivalDate = (shipmentDays, calendar) => {
			const adaptedShipmentDate = getAdaptedShipmentDate()
			let checkingDate = new Date(adaptedShipmentDate.getTime() + shipmentDays * DAY_IN_MILLISECONDS)
			let isWorkingDay = checkIsWorkingDay(checkingDate, calendar)
			while (!isWorkingDay) {
				checkingDate = new Date(checkingDate.getTime() + DAY_IN_MILLISECONDS)
				isWorkingDay = checkIsWorkingDay(checkingDate, calendar)
			}
			if (orderDetail.order?.estimatedShipmentDate && checkingDate > new Date(orderDetail.order.estimatedShipmentDate)) {
				setIsShipmentLate(true)
			} else {
				setIsShipmentLate(false)
			}
			setArrivalDate(getDate(checkingDate, 'es'))
		}

		const items = []
		for (const regression of orderRegressions) {
			if (!regression) continue
			for (const item of regression.supplierRegressions) {
				if (item.supplierId !== parseInt(supplierId)) continue
				if (item.regressionItems?.length == 0) continue
				items.push({
					regressionItems: item.regressionItems,
					partId: regression.partId,
				})
			}
		}
		if (items.length < 1) {
			return {}
		}

		let productionDays = 0
		let totalCost = 0
		let weight = 0
		let volumetricWeight = 0
		let partialSale = 0
		const supplierCountry = newProductionSuppliers.find(supplier => supplier.id === supplierId).personalInformation.country || 'N/A'

		for (const item of items) {
			const found = selectedParts.find(part => part.partId === item.partId)
			if (found) {
				const quantity = parseInt(document.getElementById(`quantity_${item.partId}`)?.value) || 1
				if (item.regressionItems[quantity - 1]?.time && item.regressionItems[quantity - 1]?.time > productionDays) {
					productionDays = item.regressionItems[quantity - 1]?.time
				}

				const price = parseFloat(document.getElementById(`totalPrice_${item.partId}`)?.innerHTML.split('€')[0])
				totalCost += price

				if (
					orderDetail.order?.orderItems &&
					orderDetail.order?.orderItems.length > 0
				) {
					for (const orderItem of orderDetail.order.orderItems) {
						if (orderItem.part && orderItem.part.id === item.partId) {
							weight += orderItem.part.weight * quantity
							volumetricWeight += calculateVolumetricWeight(orderItem.part.size.x, orderItem.part.size.y, orderItem.part.size.z) * quantity
							partialSale += roundAtDecimals((orderItem.total / orderItem.quantity) * Math.min(quantity, orderItem.quantity), 2)
						}
					}
					continue
				}
			}
		}
		productionDays += Math.floor(selectedParts.length / 8)

		if (selectedParts.length > 0) {
			if (!isShipmentDateManual) {
				setShipmentDate(getDate(getCalculatedShipmentDate(productionDays, supplierShippingTiming.supplierInfo.calendar), 'es'))
			}
			if (shipmentDate) calculateArrivalDate(supplierShippingTiming.supplierInfo.calendar.shipmentDays, supplierShippingTiming.imasWorkshopInfo?.calendar || supplierShippingTiming.supplierInfo.calendar)
		} else {
			setShipmentDate(null)
			setIsShipmentDateManual(false)
			setArrivalDate(null)
		}

		const discountPercentage = parseFloat(document.getElementById('discountComponent')?.value) / 100 || 0

		return {
			productionDays: productionDays,
			weight: weight,
			volumetricWeight: volumetricWeight,
			shipmentCost: calculateShipmentCosts(
				supplierId,
				supplierCountry,
				weight,
				volumetricWeight,
				supplierShipmentPricingList,
			),
			processingCost: supplierCountry === 'ES' ? 0 : 15,
			totalCost: totalCost * (1 - discountPercentage),
			partialSale: disconformity ? 0 : partialSale,
		}
	}

	const formatOrder = orderObject => {
		const order = {
			orderId: orderObject.order?.id || '',
			status: orderObject.order && {
				value: orderObject.order.statusId,
				text: t(`order_status_${orderObject.order.statusId}`, { ns: 'naming' }),
			},
			orderDate: orderObject.order && orderObject.order.creationDate && new Date(orderObject.order.creationDate),
			totalPrice: orderObject.order && orderObject.order.subtotal,
			productionTime: orderObject.order?.productionDays,
			paymentMethodId: orderObject.order?.paymentMethodId || -1,
			customerShipmentDate: orderObject.order && orderObject.order.estimatedShipmentDate && new Date(orderObject.order?.estimatedShipmentDate),
			customerShipmentPrice: orderObject.order?.shipmentPricing,
			minimumOrderPrice: orderObject.order?.feeApplied,
		}

		return order
	}

	const savePartIdClicked = (partId) => {
		setPartIdsAlreadyClicked(prevIds => {
			const newIds = !prevIds.includes(partId) ? [...prevIds, partId] : prevIds
			return newIds
		})
	}
	const formatOrderItem = (orderItem, index) => {
		if (!orderItem.part) return
		let partsRequested = 0
		for (const partItem of partsOnProduction) {
			if (partItem && partItem.part && partItem.part.id === orderItem.part.id)
				partsRequested += partItem.totalQuantity
		}
		const material = PartConfigTranslations.getMaterialName({ part: orderItem.part, partConfigOptions, language })
		const alloy = PartConfigTranslations.getAlloyName({ part: orderItem.part, partConfigOptions, language })
		const hardness = PartConfigTranslations.getHardnessName({ part: orderItem.part, partConfigOptions, language })
		const finishing = PartConfigTranslations.getFinishName({ part: orderItem.part, partConfigOptions, language })
		const palette = PartConfigTranslations.getPaletteName({ part: orderItem.part, partConfigOptions, language })
		const color = PartConfigTranslations.getColorName({ part: orderItem.part, partConfigOptions, language })
		const colorFinishing = PartConfigTranslations.getColorFinishName({ part: orderItem.part, partConfigOptions, language })
		return {
			id: orderItem.part.id,
			partsRequested: `${partsRequested} / ${orderItem.customerQuantities[0]}`,
			partId: orderItem.part.id,
			image: <PartThumbnail propsImageUrl={orderItem.part.imageUrl} propsGeometryUrl={orderItem.part.fileLinks?.stlModel || orderItem.part.fileLinks?.objModel} geometryData={{ partId: orderItem.part.id, size: orderItem.part.size, weight: orderItem.part.weight, partName: orderItem.part.name }} />,
			files: orderItem.part.files.blueprint || orderItem.part.files.modifiedBlueprint ? <button style={{
				background: 'none',
				border: 'none',
				padding: 0,
				margin: 0,
				font: 'inherit',
				color: 'inherit',
				cursor: 'pointer',
				outline: 'none',
			}} onClick={() => savePartIdClicked(orderItem.part.id)}><DownloadFilesButtons part={orderItem.part} /></button> : <DownloadFilesButtons part={orderItem.part} />,
			description: [`${material} ${alloy} ${hardness}`, `${finishing} ${palette} ${color} ${colorFinishing}`],
			unitPrice: orderItem.quantity && orderItem.total && (
				<span id={`unitPrice_${orderItem.part.id}`}>
					{roundCurrency(orderItem.total / orderItem.quantity)}€
				</span>
			),
			quantity: orderItem.quantity && (
				<input
					style={{ width: '100%' }}
					id={`quantity_${orderItem.part.id}`}
					type="number"
					defaultValue={orderItem.quantity}
					min="1"
					max={orderItem.maxQuantity}
					onChange={e => {
						e.target.value = e.target.value.split('.')[0]
						let quantity = parseInt(e.target.value)
						if (isNaN(quantity)) {
							e.target.value = ''
							quantity = 1
						} else if (isNaN(quantity) || quantity > orderItem.maxQuantity) {
							e.target.value = orderItem.maxQuantity
							quantity = orderItem.maxQuantity
						} else if (quantity === 0) {
							e.target.value = '1'
							quantity = 1
						}
						onChangeQuantity(quantity, orderItem, index)
					}}
					onBlur={() => {
						const value = document.getElementById(`quantity_${orderItem.part.id}`)?.value
						if (!value) {
							document.getElementById(`quantity_${orderItem.part.id}`).value = '1'
						}
					}}
				/>
			),
			totalPrice: orderItem.total && (
				<span id={`totalPrice_${orderItem.part.id}`}>
					{roundCurrency(orderItem.total)}€
				</span>
			),
			offerId: <a
				href={`${appRoutes.OFFER_DETAIL_ROUTE_NO_ID}${orderItem.part.offerId}`}
				target="_blank"
				rel="noopener noreferrer"
			>{orderItem.part.offerId}</a>,
		}
	}

	const onChangeQuantity = (quantity, orderItem, index) => {
		if (quantity) {
			const partId = orderItem.part.id
			let totalPrice
			let unitPrice
			for (const regression of orderRegressions) {
				if (!regression) continue
				if (regression.partId !== partId) continue
				for (const supplier of regression.supplierRegressions) {
					if (supplier.supplierId !== parseInt(supplierId)) continue

					const item = orderDetail.order &&
						orderDetail.order.orderItems.find(
							element => element.part && element.part.id === partId,
						)
					if (item.regressionItems?.length == 0) continue

					if (item?.part && item.part.id) {
						unitPrice = quantity > 0 ? roundCurrency(supplier.regressionItems[quantity - 1]?.partCost) : 0
						totalPrice = quantity > 0 ? roundCurrency(supplier.regressionItems[quantity - 1]?.totalCost) : 0
						document.getElementById(`totalPrice_${item.part.id}`).innerHTML = `${totalPrice}€`
						document.getElementById(`unitPrice_${item.part.id}`).innerHTML = `${unitPrice}€`
					}
				}
			}
		}
		setIsRefreshing(true)
	}

	const getSuppliersParts = () => {
		const items = []

		for (const regression of orderRegressions) {
			if (!regression) continue
			for (const supplier of regression.supplierRegressions) {
				if (supplier.regressionItems?.length == 0) continue
				if (supplier.supplierId === parseInt(supplierId)) {
					orderDetail.order && orderDetail.order.orderItems.find(orderItem => {
						if (orderItem.part && orderItem.part.id === regression.partId) {
							items.push(
								formatOrderItem(
									{
										...orderItem,
										total: supplier.regressionItems[orderItem.quantity - 1]?.totalCost,
										totalPrice: undefined,
										quantity: orderItem.quantity,
										part: {
											...orderItem.part,
										},
										customerQuantities: [orderItem.quantity],
										maxQuantity: supplier.regressionItems[supplier.regressionItems.length - 1]?.quantity,
										isSelectable: true,
									},
									items.length,
								),
							)
						}
					})
				}
			}
		}
		return { items }
	}

	const onChangeSupplier = e => {
		if (!orderRegressions || orderRegressions.length === 0) {
			return
		}
		const retrievedSupplierId = parseInt(e.value)
		setSupplierId(retrievedSupplierId)
	}

	const onSelectTableItem = (partId) => {
		const foundPart = selectedParts.find(
			part => part && part.partId === partId,
		)
		let newSelectedParts = []
		if (foundPart) {
			selectedParts.forEach(part => {
				if (part && part !== foundPart) newSelectedParts.push(part)
			})
		} else {
			newSelectedParts = selectedParts.map(part => part)
			newSelectedParts.push({
				partId: partId,
			})
		}

		setSelectedParts(newSelectedParts)
	}

	const onSelectAllTableItems = (isSelected, partIds) => {
		if (!isSelected) {
			setSelectedParts([])
			return
		}

		const newSelectedParts = partIds.map((partId, index) => {
			return { partId: partId }
		})
		setSelectedParts(newSelectedParts)
	}

	const setDisconformityHandler = value => {
		let disconformityValue = 0
		if (value) {
			disconformityValue = 100
		}
		document.getElementById('discountComponent').value = disconformityValue
		setDisconformity(value)
		setSelectedParts([...selectedParts])
	}

	const mapLaunchProductionData = () => {
		const discount = parseFloat(document.getElementById('discountComponent')?.value) / 100 || 0
		const shipmentDateData = shipmentDate.split('/')
		const adaptedShipmentDate = `${shipmentDateData[2]}-${shipmentDateData[1]}-${shipmentDateData[0]}`
		const arrivalDateData = arrivalDate.split('/')
		const adaptedArrivaltDate = `${arrivalDateData[2]}-${arrivalDateData[1]}-${arrivalDateData[0]}`
		return {
			data: {
				production: {
					comment: launchProductionComment,
					disconformity: disconformity,
					orderId: parseInt(GetOrderIdFromURL()) || 0,
					supplierId: supplierId,
					totalPrice: roundAtDecimals(productionSimulation.totalCost / (1 - discount), 2),
					discount: discount,
					totalPriceWithDiscount: productionSimulation.totalCost,
					salePrice: productionSimulation.partialSale,
					grossProfits: roundAtDecimals(productionSimulation.partialSale - productionSimulation.totalCost, 2),
					netProfits: roundAtDecimals(productionSimulation.partialSale - productionSimulation.totalCost - productionSimulation.shipmentCost - productionSimulation.processingCost, 2),
					productionDays: productionSimulation.productionDays,
					shipmentDateEstimate: adaptedShipmentDate,
					arrivalDateEstimated: adaptedArrivaltDate,
					weight: productionSimulation.weight,
					shipmentPrice: productionSimulation.shipmentCost + productionSimulation.processingCost,
					productionItemList: selectedParts.map(selectedPart => {
						const partId = selectedPart.partId
						const totalQuantity = parseInt(document.getElementById(`quantity_${partId}`).value) || 1
						const unitPurchasePrice = parseFloat(document.getElementById(`unitPrice_${partId}`)?.innerHTML.split('€')[0])
						const totalPurchasePrice = parseFloat(document.getElementById(`totalPrice_${partId}`)?.innerHTML.split('€')[0])
						let unitSellingPrice
						let totalSellingPrice
						for (const orderItem of orderDetail.order.orderItems) {
							if (orderItem.part && orderItem.part.id === partId) {
								unitSellingPrice = roundAtDecimals(orderItem.total / orderItem.quantity, 2)
								totalSellingPrice = roundAtDecimals(unitSellingPrice * Math.min(totalQuantity, orderItem.quantity), 2)
							}
						}
						return {
							part: {
								id: partId,
							},
							totalQuantity,
							unitPurchasePrice,
							totalPurchasePrice,
							unitSellingPrice,
							totalSellingPrice,
						}
					}),
				},
			},
		}
	}



	const handleLaunchProduction = () => {
		const productionData = mapLaunchProductionData()
		if (haveToUpdateOrderStatus(productionData, orderItems)) {
			const order = {
				id: orderDetail.order.id,
				statusId: OrderStatus.IN_PRODUCTION,
			}
			updateOrder({ data: { order } })
			setCountReloadOrder(2)
		}
		launchProduction(productionData)
	}

	const haveToUpdateOrderStatus = (newProductionData, orderItems) => {
		for (const orderItem of orderItems) {
			const requestedParts = orderItem.partsRequested.split(' / ')
			const partsToProduce = newProductionData.data.production.productionItemList.find(productionItem => productionItem.part.id === orderItem.partId)?.totalQuantity || 0
			const partsToComplete = parseInt(requestedParts[1])
			const partsOnProduction = parseInt(requestedParts[0]) + partsToProduce
			if (partsToComplete > partsOnProduction) return false
		}
		return true
	}

	const discountComponent = (
		<span>
			<input
				id="discountComponent"
				className="width-3rem"
				type="number"
				min="0"
				max="100"
				defaultValue={0}
				onChange={() => {
					setSelectedParts([...selectedParts])
				}}
				onBlur={() => {
					const value = document.getElementById('discountComponent')?.value
					if (!value) {
						document.getElementById('discountComponent').value = '0'
					}
				}}
			/>{' '}
			%
		</span>
	)

	const arraysHaveSameElements = partsIdsWithBlueprints.length === partIdsAlreadyClicked.length &&
		partsIdsWithBlueprints.every(number => partIdsAlreadyClicked.includes(number))

	return (
		<React.Fragment>
			<div className="page-content">
				<MetaTags>
					<title>Proto&Go! | {t('new-production', { ns: 'naming' })}</title>
				</MetaTags>
				<Breadcrumbs
					title={t('management', { ns: 'naming' })}
					breadcrumbItems={[
						{
							item: t('productions', { ns: 'naming' }),
							link: '/productions',
						},
						{
							item: t('new-production', { ns: 'naming' }),
						},
					]}
				/>
				<Modal
					title={<div><i class='bx bxs-error fs-4 text-warning'></i> {t('reminder', { ns: 'naming' })}</div>}
					isOpen={isModalReminderMessageOpen}
					closeModal={() => setIsModalReminderMessageOpen(false)}
					body={
						<div className="m-3">
							<div>{t('modal_before_launch_production_message', { ns: 'naming' })}<strong>{t('blueprints_modal_launch_production', { ns: 'naming' })}</strong> {t('verify_the_next', { ns: 'naming' })}</div>
							<ul className='mt-2'>
								<li>{t('translate_to_english_annotations', { ns: 'naming' })}</li>
								<li>{t('to_remark_specific_tolerances', { ns: 'naming' })}</li>
								<li>{t('remarking_threads', { ns: 'naming' })}</li>
							</ul>
							<div>{t('Apart_from_the_above_remember_to_leave_as_a_comment', { ns: 'naming' })} <strong>{t('internal_modal_launch_production', { ns: 'naming' })}</strong></div>
							<ul className='mt-2'>
								<li>{t('any_message_with_customer', { ns: 'naming' })}</li>
								<li>{t('any_message_with_supplier', { ns: 'naming' })}</li>
							</ul>

						</div>
					}
				/>
				{(orderDetail &&
					newProductionSuppliers.length > 0 && (
						<div>
							<ProductionTab
								orderDetails={order}
								productionSimulation={productionSimulation}
								disconformity={disconformity}
								setDisconformity={setDisconformityHandler}
								supplierWorkdays={
									(supplierShippingTiming && supplierShippingTiming.supplierInfo && supplierShippingTiming.supplierInfo.calendar && supplierShippingTiming.supplierInfo.calendar.workdays)
								}
								supplierHolidays={
									(supplierShippingTiming && supplierShippingTiming.supplierInfo && supplierShippingTiming.supplierInfo.calendar && supplierShippingTiming.supplierInfo.calendar.holidays)
								}
								discountComponent={discountComponent}
								suppliers={newProductionSuppliers}
								onChangeSupplier={onChangeSupplier}
								handleShipmentDate={handleShipmentDate}
								shipmentDate={shipmentDate}
								arrivalDate={arrivalDate}
								isShipmentLate={isShipmentLate}
								setModalOpen={setModalOpen}
								isAnyPartSelected={selectedParts.length > 0}
								isDisabledButton={!arraysHaveSameElements}
							/>
							<ProductionComment clientComment={orderDetail.order?.comment} />
							<div className="mt-4 bg-white">
								<PartsTable
									parts={orderItems}
									selectableParts={supplierItems}
									supplierSelected={supplierId}
									onSelect={onSelectTableItem}
									onSelectAll={onSelectAllTableItems}
								/>
							</div>
							<Modal
								title={t('confirm-production', { ns: 'naming' })}
								isOpen={isModalOpen}
								closeModal={() => setModalOpen(false)}
								body={
									<div className="m-3">
										<strong>{t('confirm-production-comment', { ns: 'naming' })}</strong>
										<textarea
											className="form-control"
											rows={5}
											value={launchProductionComment}
											onChange={e => setLaunchProductionComment(e.target.value)}
										></textarea>
									</div>
								}
								buttons={[
									<button id="productionModalIDButton" type="button" key="launchProductionButton" className="btn btn-primary" onClick={e => handleLaunchProduction()}>
										{!isLaunchingProduction ? t('launchProduction', { ns: 'naming' }) : <Loader />}
									</button>,
								]}
							/>
							<Modal
								title={t('error-production', { ns: 'naming' })}
								isOpen={isErrorModalOpen}
								closeModal={() => setErrorModalOpen(false)}
								body={
									<div className="m-3">
										{t('error-production', { ns: 'naming' })}
									</div>
								}
							/>
						</div>
					)) || (!error ?
						<div className="mx-auto width-by-content">
							<Loader className="font-size-80" />
						</div>
						:
						<Alert
							type="danger"
							centered
							fitContent
							message={t('unknownMessage', { ns: 'errors' })}
						/>
					)}
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = state => {
	return {
		newProductionSuppliers: state.Users.newProductionSuppliers,
		supplierShippingTiming: state.Users.supplierShippingTiming,
		orderDetail: state.Orders.orderDetail,
		quoteRegressionsByPartId: state.Quotes.quoteRegressionsByPartId,
		productionItemsByOrderId: state.Productions.productionItemsByOrderId,
		supplierShipmentPricingList: state.Productions.supplierShipmentPricingList,
		launchProductionSuccess: state.Productions.launchProductionSuccess,
		launchProductionFail: state.Productions.launchProductionFail,
		isLaunchingProduction: state.Productions.isLaunchingProduction,
		production: state.Productions.production,
		error: state.Users.error || state.Orders.error || state.Quotes.error || state.Productions.error,
		partConfigOptions: state.Parts.partConfigOptions,
	}
}

NewProduction.propTypes = {
	t: PropTypes.func,
	history: PropTypes.object,
	i18n: PropTypes.object,
	newProductionSuppliers: PropTypes.any,
	orderDetail: PropTypes.any,
	getSuppliersByOrderId: PropTypes.func,
	getOrderDetail: PropTypes.func,
	getQuoteRegressionsByPartId: PropTypes.func,
	quoteRegressionsByPartId: PropTypes.any,
	getProductionItemsByOrderId: PropTypes.func,
	productionItemsByOrderId: PropTypes.any,
	getSupplierShipmentPricingList: PropTypes.func,
	supplierShipmentPricingList: PropTypes.any,
	getSupplierInfo: PropTypes.func,
	supplierShippingTiming: PropTypes.any,
	launchProduction: PropTypes.func,
	isLaunchingProduction: PropTypes.bool,
	launchProductionSuccess: PropTypes.bool,
	launchProductionFail: PropTypes.bool,
	production: PropTypes.object,
	error: PropTypes.any,
	updateOrder: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

export default withGetPartConfigOptions(withRouter(
	connect(mapStateToProps, {
		getSuppliersByOrderId,
		getOrderDetail,
		getProductionItemsByOrderId,
		getQuoteRegressionsByPartId,
		getSupplierShipmentPricingList,
		getSupplierInfo,
		launchProduction,
		updateOrder,
	})(withTranslation()(NewProduction))))
