import { roundCurrency } from 'common/Utils/NumberUtilities'
import { isObjectEmpty } from 'common/Utils/ObjectUtils'
import { getIdFromUrl } from 'common/Utils/StringUtilities'
import Alert from 'components/Common/Alert'
import Knob from 'components/Common/Charts/knob'
import Loader from 'components/Common/Loader'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getCrmUsers } from 'store/actions'
import LogInAs from './LogInAs'
export const Sidebar = ({ userList, t, getCrmUsers, isLoading, error }) => {

	const [userFound, setUserFound] = useState({})
	const [rankingPosition, setRankingPosition] = useState({})

	useEffect(() => {
		if (userList.length === 0) getCrmUsers()
	}, [userList])

	useEffect(() => {
		if (userList.length > 0) setUserFound(userList.find(user => user.id === getIdFromUrl()))
	}, [userList])

	useEffect(() => {
		getAllDomainsCountAndPosition()
	}, [userFound])

	const { email, organizationName, province, purchaseStats, web, role, loginCount } = userFound ?? {}
	const { ranking, last12MonthsBilling, totalBilling, last12MonthsOrders, totalOrders, last12MonthsOffers, totalOffers } = purchaseStats?.stats ?? {}

	const name = `${userFound.firstName} ${userFound.lastName}`

	const getAllDomainsCountAndPosition = () => {
		const allRankings = userList.map(user => user.purchaseStats?.stats?.ranking)
		const uniqueRankings = [...new Set(allRankings)]
		uniqueRankings.sort((a, b) => b - a)
		const position = uniqueRankings.findIndex(ranking => userFound.purchaseStats?.stats?.ranking === ranking)
		setRankingPosition({ domainCount: uniqueRankings.length, position: position + 1 })
	}

	return (

		<div className='bg-white p-1 px-3 me-2' style={{ height: '62.4rem' }}>
			{isLoading || (!isLoading && isObjectEmpty(userFound)) ? <div className='d-flex justify-content-center'><Loader className={'fs-1'} /></div>
				: error ? <Alert
					type="danger"
					centered
					fitContent
					message={error.message}
				/> : !isObjectEmpty(userFound) &&
				<div className="mt-3" >
					<div className="text-center mb-3">
						<h4 className="font-weight-bold ">{name}</h4>
						<p className="text-primary m-0 ">{email}</p>
						<p className="m-0"><LogInAs /></p>
					</div>
					<div className="border-bottom border-light mb-4 pb-4 ps-1 p-2">
						<h6 className='end-ellipsis'>
							<i className=" bx bxs-briefcase"></i> {organizationName}
						</h6>
						<h6 className='end-ellipsis'>
							<i className="bx bxs-map"></i> {province}
						</h6>
						<a href={web ? (web.startsWith('http') ? web : 'https://' + web) : `http://www.${email?.split('@')?.[1]}`} target="_blank" rel="noreferrer">
							<h6 className="text-primary d-flex">
								<i className="bx bx-sitemap me-1" color="#0000"></i>
								<p className='end-ellipsis p-0 m-0'>{web?.replace('http://', '').replace('https://', '') || `www.${email?.split('@')?.[1]}`}</p>
							</h6>
						</a>
						<h6 className='d-flex'>
							<i className='bx bxs-user-account me-1'></i>
							<p className='end-ellipsis p-0 m-0'>{t('account_user_role_' + role, { ns: 'userRoles' })}</p>
						</h6>
						<h6 className='d-flex '>
							<i className='bx bx-log-in-circle me-1'></i>
							<p className='end-ellipsis p-0 m-0'>{loginCount}</p>
						</h6>
					</div>
					<div className="border-bottom border-light mb-4 pb-3 mx-2 text-center">
						<h5 className="font-size-14 mb-3">{t('selling_ranking', { ns: 'naming' })}</h5>
						<Knob
							value={roundCurrency(ranking)}
							fgColor="#F78E1E"
							thickness={0.12}
							readOnly={true}
							height={100}
							width={100}
							max={10}
							onChange={() => { }}
						/>
						<p>
							nº {rankingPosition.position} {t('of', { ns: 'naming' })} {rankingPosition.domainCount} {t('customers', { ns: 'naming' })}
						</p>
					</div>
					<div className="border-bottom border-light mb-3 py-3 mx-2 text-center">
						<h6>{t('company_amount', { ns: 'naming' })}</h6>
						<h4 className="mb-0">{roundCurrency(last12MonthsBilling)} €</h4>
						<p>
							<small>{t('last_12_months', { ns: 'naming' })}</small>
						</p>
						<h4 className="mb-0">{roundCurrency(totalBilling)} €</h4>
						<p>
							<small>{t('total', { ns: 'naming' })}</small>
						</p>
					</div>
					<div className="border-bottom border-light mb-3 py-3 text-center">
						<h5>{t('orders', { ns: 'naming' })}</h5>
						<div className="d-flex justify-content-around ">
							<h2>{last12MonthsOrders}</h2>
							<h2>{totalOrders}</h2>
						</div>
						<div className="d-flex justify-content-around ">
							<p className="pe-2">{t('last_12_months', { ns: 'naming' })}</p>
							<p className="pe-4">{t('total', { ns: 'naming' })}</p>
						</div>
					</div>
					<div className="text-center pt-2">
						<h5>{t('offers', { ns: 'naming' })}</h5>
						<div className="d-flex justify-content-around">
							<h2>{last12MonthsOffers}</h2>
							<h2>{totalOffers}</h2>
						</div>
						<div className="d-flex justify-content-around">
							<p className="pe-2">{t('last_12_months', { ns: 'naming' })}</p>
							<p className="pe-4">{t('total', { ns: 'naming' })}</p>
						</div>
					</div>
				</div>
			}

		</div>

	)
}

Sidebar.propTypes = {
	getCrmUsers: PropTypes.func,
	t: PropTypes.func,
	userList: PropTypes.array,
	isLoading: PropTypes.bool,
	error: PropTypes.object,
}

const mapStateToProps = (state) => ({
	userList: state.Crm.userList,
	isLoading: state.Crm.isLoading,
	error: state.Crm.error,
})

const mapDispatchToProps = { getCrmUsers }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Sidebar))
