import TooltipMessage from 'components/Common/TooltipMessage'
import { PAYMENT_METHOD } from 'constants/paymentMethods'

export const OrdersNoDatePaymentAlert = ({ orderObject, t }) => {
	const isWarning = orderObject.order.paymentMethodId === PAYMENT_METHOD.WIRE_TRANSFER && orderObject.order.paymentDate == null

	return (
		<span>
			{isWarning && (
				<div style={{ marginLeft: '10px' }}>
					<TooltipMessage message={t('order_pending_payment', { ns: 'status' })}>
						<div className='d-flex flex-row align-items-center'>
							<div style={{ height: '1em', width: '1em', color: 'red', marginLeft: "-5px" }}>
								<i className='bx bx-error fs-5'></i>
							</div>
						</div>
					</TooltipMessage>
				</div>
			)}
		</span>
	)
}
