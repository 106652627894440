import { useObjectState } from 'common/Hooks/UseObjectState'
import { getCountryLabelValue } from 'common/Utils/CountryUtils'
import { mapManagers } from 'common/Utils/ManagersUtils'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getManagers } from 'store/actions'
import { COUNTRIES_CHART, INITIAL_FILTERS, INTRASTAT, USERS_CHART } from '../constants'

export const DashboardFiltersModal = ({ t, filters, setFilters, managers, getManagers, countryList, activeTab }) => {

	const MODAL_DISABLED_TABS = [INTRASTAT]

	const [isOpenModal, setIsOpenModal] = useState(false)
	const { handleOnChange, objectData, resetState, setObjectData } = useObjectState(INITIAL_FILTERS)

	useEffect(() => {
		if (managers.length === 0 || managers == null) getManagers()
	}, [managers])

	const handleOnCloseModal = () => {
		setObjectData(filters)
		setIsOpenModal(false)
	}

	const handleOnSaveFilters = () => {
		setFilters(objectData)
		setIsOpenModal(false)
	}

	const handleResetFilters = () => {
		setFilters(INITIAL_FILTERS)
		resetState()
		setIsOpenModal(false)
	}

	const billingSourceOptions = [{
		label: 'interna',
		value: true,
	}, {
		value: false,
		label: 'externa',
	}]

	const handleOnChangeBillingSource = (valueArray) => {
		setObjectData((prevValue) => {
			if (valueArray.length !== 1) return { ...prevValue, isOnlyInternal: null }
			return { ...prevValue, isOnlyInternal: valueArray[0].value }
		})
	}

	const getBillingSourceValue = () => {
		if (objectData.isOnlyInternal == null) return billingSourceOptions
		return billingSourceOptions.find(option => option.value === objectData.isOnlyInternal)
	}

	return (
		<div className='ms-2 pt-4 d-flex'>
			<button className='btn btn-secondary' disabled={MODAL_DISABLED_TABS.includes(activeTab)} onClick={() => { setIsOpenModal(true) }}>{t('filter', { ns: 'naming' })}</button>
			<Modal
				title={t('filter', { ns: 'naming' })}
				body={<div className='p-4 d-flex flex-column'>
					<OptionsInput
						value={objectData.managerIds}
						setValue={handleOnChange}
						automaticMappedValue
						objectElement='managerIds'
						isMulti
						title={t('managers', { ns: 'naming' })}
						options={mapManagers(managers)}
					/>
					{activeTab !== COUNTRIES_CHART &&
						<OptionsInput
							value={objectData.countries}
							setValue={handleOnChange}
							automaticMappedValue
							objectElement='countries'
							isMulti
							title={t('countries', { ns: 'naming' })}
							options={getCountryLabelValue(countryList)}
						/>
					}
					{activeTab !== USERS_CHART &&
						<OptionsInput
							value={getBillingSourceValue()}
							setValue={handleOnChangeBillingSource}
							isMulti
							title={t('companies', { ns: 'naming' })}
							options={billingSourceOptions}
						/>
					}

				</div>}
				buttons={[
					<button onClick={() => handleResetFilters()} key='reset-filter-modal-data' className='btn btn-secondary'>
						{t('reset', { ns: 'naming' })}
					</button>,
					<button onClick={() => handleOnSaveFilters()} key='save-filter-modal-data' className='btn btn-primary'>
						{t('save', { ns: 'naming' })}
					</button>,

				]}
				isOpen={isOpenModal}
				closeModal={() => { handleOnCloseModal() }}
			/>
		</div>
	)
}

DashboardFiltersModal.propTypes = {
	t: PropTypes.func,
	filters: PropTypes.object,
	setFilters: PropTypes.func,
	managers: PropTypes.array,
	getManagers: PropTypes.func,
	countryList: PropTypes.array,
	activeTab: PropTypes.number,
}

const mapStateToProps = (state) => ({
	managers: state.Users.managers,
	countryList: state.Users.countryList,
})

const mapDispatchToProps = { getManagers }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DashboardFiltersModal))
