import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import AppRoutes from 'constants/appRoutes'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'

const checkRole = (rolesAllowed) => {
	const currentRoles = getUserValuesFromLocalStorage()?.roles
	if (currentRoles) {
		return rolesAllowed.some((role) => currentRoles.includes(role))
	}

	return false
}

const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	rolesAllowed,
	...rest
}) => (
	<Route
		{...rest}
		render={props => {
			if (isAuthProtected && !checkRole(rolesAllowed)) {
				return (
					<Redirect
						to={{
							pathname: AppRoutes.LOGIN_ROUTE,
							state: { from: props.location },
						}}
					/>
				)
			}

			return (
				<Layout>
					<Component {...props} />
				</Layout>
			)
		}}
	/>
)

AppRoute.propTypes = {
	isAuthProtected: PropTypes.bool,
	rolesAllowed: PropTypes.array,
	component: PropTypes.any,
	location: PropTypes.object,
	layout: PropTypes.any,
}

export default AppRoute
