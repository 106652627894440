import PropTypes from 'prop-types'
import React, { Component } from 'react'

class Loader extends Component {
	render() {
		return (
			<React.Fragment>
				<div className={'width-by-content ' + this.props.className}>
					<i className="bx bx-loader bx-spin align-middle me-2" />
				</div>
			</React.Fragment>
		)
	}
}

Loader.propTypes = {
	className: PropTypes.string,
}

export default Loader
