import { getCountryCodeByCountryName } from 'common/Utils/CountryUtils'
import { OptionsInput } from 'components/Common/OptionsInput'
import { countries } from 'locales/es'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { Flag } from 'semantic-ui-react'

const CountryFlag = ({ country, isEditable, onChange, countryState, t, isMulti }) => {
	const countryCode = getCountryCodeByCountryName(country)
	const countryArray = Object.entries(countries)
	const renderOption = country => {
		return {
			value: country[0] && country[0].toLocaleLowerCase(),
			label: (
				<span>
					<Flag name={country[0] && country[0].toLocaleLowerCase()} /> {t(country[1], { ns: 'countries' })}
				</span>
			),
			countryName: country[1],
		}
	}

	const allOptions = countryArray.map(countryAndCountry =>
		renderOption(countryAndCountry),
	)

	const withoutCountryOption = { value: ' ', label: 'Sin País' }
	allOptions.unshift(withoutCountryOption)


	const filterFunction = (option, inputValue) => {
		if (inputValue === '') return true

		const countrySearch = option.data.countryName && option.data.countryName.toLowerCase()

		const lowerInput = inputValue.toLowerCase()
		return countrySearch ? countrySearch.includes(lowerInput) : false
	}
	return (
		<span>
			{isEditable ? isMulti ?
				<OptionsInput
					isMulti
					setValue={(e) => { onChange(e) }}
					options={allOptions}
					automaticMappedValue
					value={countryState}
					filterOption={(option, inputValue) => filterFunction(option, inputValue)}
				/>

				: (
					<Select
						options={allOptions}
						onChange={e => {
							onChange(e)
						}}
						filterOption={(option, inputValue) => filterFunction(option, inputValue)}
						value={countryState && {
							value: countryState,
							label: (
								<span>
									<Flag name={getCountryCodeByCountryName(countryState)} />
									{countryState}
								</span>
							),
						}}

					/>
				) : (
				<div className="d-flex flex-row">
					<span>
						{' '}
						<Flag name={countryCode} />
					</span>
					<span>{t(country && country.toLocaleLowerCase(), { ns: 'countries' })}</span>
				</div>
			)}
		</span>
	)
}
CountryFlag.propTypes = {
	country: PropTypes.any,
	isEditable: PropTypes.bool,
	onChange: PropTypes.func,
	countryState: PropTypes.number,
	t: PropTypes.func,
	isMulti: PropTypes.bool,
	objectElement: PropTypes.string,
}
export default withTranslation()(CountryFlag)
