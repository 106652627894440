import { getBarcode } from 'common/Utils/LabelUtils'
import { getDate } from 'common/Utils/StringUtilities'
import { BARCODE_FORMAT } from 'constants/BarcodeLabels'
import SelectedQuantity from 'pages/Productions/SelectedQuantity'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { getProductionItemsLabels } from 'store/actions'
import Alert from '../Alert'
import Loader from '../Loader'
import Modal from '../Modal/Modal'
import PartLabel from '../PartLabel/PartLabel'
import { PartName } from '../PartName'
import SimpleTable from '../SimpleTable'
import TooltipButton from '../TooltipButton'
import LabelsPrintableContent from './LabelsPrintableContent'
import ProductionLabel from './ProductionLabelHTML'

export const PrintLabelsModal = ({
	orderId,
	orderFragmentId,
	addCustomerInfoLabel,
	t,
	i18n: { language },
	getProductionItemsLabels,
	productionLabels,
	isLoading,
	error,
	partConfigOptions,
}) => {

	const MINIMUM_BOUNDARY_BOX_VOLUME = 20

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [partQuantities, setPartQuantities] = useState([])
	const [productionLablesHTMLToPrint, setProductionLabelsHTMLToPrint] = useState(null)

	const handleOnChangePartQuantites = (newQuantity, partId) => {
		setPartQuantities(partQuantities.map(el => (el.partId === partId ? { partId, value: newQuantity } : el)))
	}

	useEffect(() => {
		if (productionLabels?.productionItemList?.length > 0) {
			setPartQuantities(productionLabels.productionItemList.map(el => ({ partId: el.part.id, value: el.totalQuantity })))
			setProductionLabelsHTMLToPrint(null)
		}
	}, [productionLabels])

	useEffect(() => {
		if (partQuantities.length !== 0) {
			setProductionLabelsHTMLToPrint(getLabelsHtml())
		}
	}, [partQuantities])

	const handleOpenModal = () => {
		setIsModalOpen(true)
		const alreadyHaveData = orderId === productionLabels?.order?.id && orderFragmentId === productionLabels?.orderFragmentId
		if (productionLabels == null || !alreadyHaveData) {
			getProductionItemsLabels({ orderId, orderFragmentId })
		}
	}

	const getTableBody = () => {
		return productionLabels.productionItemList.map(item => {
			return (<tr key={`body_print_labels_table_${orderId}_${orderFragmentId}_${item.part.id}`}>
				<th>{item.part.id}</th>
				<th><img src={item.part.imageUrl} height={100} width={75}></img></th>
				<th>{<PartName name={item.part.name} maxLength={15}></PartName>}</th>
				<th>{item.totalQuantity}</th>
				<th><SelectedQuantity value={partQuantities.find(el => el.partId === item.part.id).value} setValue={(number) => handleOnChangePartQuantites(number, item.part.id)} min={0} max={item.totalQuantity} /></th>
			</tr>)
		},
		)
	}

	const renderPartsTable = () => {
		return (
			<SimpleBar style={{ maxHeight: '40rem', borderTop: '1px solid #E5E5E5' }}>
				<SimpleTable
					header={['part-id', 'part', 'name', 'pending', 'shipping']}
					getTableBody={getTableBody}
				/>
			</SimpleBar>)
	}

	const partsTableMemo = useMemo(() => {
		return partQuantities.length > 0 && renderPartsTable()
	}, [partQuantities])

	const getFirstLabel = () => {
		const totalWeight = productionLabels?.productionItemList.reduce((acc, item) => acc + item.part.weight * partQuantities.find(el => el.partId === item.part.id).value || 1, 0)
		return <ProductionLabel
			supplierContactName={
				`${productionLabels?.order?.shippingAddress?.firstName} ${productionLabels?.order?.shippingAddress?.lastName}`
			}
			organizationName={productionLabels?.order?.shippingAddress?.company}
			postalCode={productionLabels?.order?.shippingAddress?.postalCode}
			state={productionLabels?.order?.shippingAddress?.state}
			country={productionLabels?.order?.shippingAddress?.country}
			orderId={productionLabels?.order?.id}
			orderDate={getDate(productionLabels?.order?.creationDate)}
			totalWeight={totalWeight}
			id={`OC-${productionLabels?.order?.id}-${productionLabels?.order?.accountId}`}
			code={`OC-${productionLabels?.order?.id}-${productionLabels?.order?.accountId}`}
			text={`OC-${productionLabels?.order?.id}-${productionLabels?.order?.accountId}`}
			format={BARCODE_FORMAT}
			className={'page'}
		/>
	}

	const getLabelsHtml = () => {
		const labels = []
		addCustomerInfoLabel && labels.push(getFirstLabel());

		[...productionLabels.productionItemList].sort((a, b) => a.orderItemNumber - b.orderItemNumber).forEach((item, index) => {
			const selectedQuantity = partQuantities.find(part => part.partId === item.part.id)?.value
			const barcode = getBarcode(orderId, orderFragmentId, item.part.id, selectedQuantity)
			const partConfiguration = {
				alloy: item.part.material.alloy.id,
				finishing: item.part.finishing.id,
				material: item.part.material.id,
				color: item.part.finishing.color.id,
				hardnessId: item.part.configuration.hardnessId,
				colorFinishId: item.part.finishing.color.colorFinish.id,
			}
			if (selectedQuantity > 0) {
				const label = (
					<PartLabel
						itemNumber={item.orderItemNumber}
						id={`${barcode}_${index}`} //required
						code={barcode} //Barcode
						fileName={item.part.name}
						format={BARCODE_FORMAT} //Format. P&G uses CODE128B
						supplierContactName={barcode}
						partConfiguration={partConfiguration}
						size={item.part.size}
						quantity={selectedQuantity}
						weight={item.part.weight * selectedQuantity}
						orderId={orderId}
						partConfigOptions={partConfigOptions}
						language={language}
					/>
				)
				labels.push(label)
				// If the BBV is less than 20, then the parts are in a bag, and we only need to print the label with all quantities.
				if (selectedQuantity > 1 && item.part.boundaryBoxVolume > MINIMUM_BOUNDARY_BOX_VOLUME) {
					for (let i = 0; i < selectedQuantity; i++) {
						const barcodeOne = getBarcode(orderId, orderFragmentId, item.part.id, 1)
						const label = (
							<PartLabel
								itemNumber={item.orderItemNumber}
								id={`${barcodeOne}_${i}`} //required
								code={barcodeOne} //Barcode
								fileName={item.part.name}
								format={BARCODE_FORMAT} //Format. P&G uses CODE128B
								supplierContactName={barcodeOne}
								partConfiguration={partConfiguration}
								size={item.part.size}
								quantity={1}
								weight={item.part.weight}
								orderId={orderId}
								partConfigOptions={partConfigOptions}
								language={language}
							/>
						)
						labels.push(label)
					}
				}
			}
		})
		return labels
	}

	return (
		<div>
			<TooltipButton
				className={'bxs-printer fs-4'}
				message={t('printLabels', { ns: 'naming' })}
				key={`TOOLTIP_BUTTON_${orderId}_${orderFragmentId}`}
				onClick={() => { handleOpenModal() }} />
			<Modal
				title={t('print_labels_modal', { ns: 'naming' })}
				key={`MODAL_${orderId}_${orderFragmentId}`}
				body={
					<div className='p-4'>
						{error && <Alert
							type="danger"
							centered
							fitContent
							message={error}
						/>}
						{(isLoading && error == null) ? <div className='d-flex justify-content-center'><Loader className='fs-2' /></div> :
							<div>{partsTableMemo}</div>
						}
					</div>
				}
				className="modal-lg"
				buttons={
					[<LabelsPrintableContent
						key={`printable_content_${orderId}_${orderFragmentId}`} //required
						id={`printable_content_${orderId}_${orderFragmentId}`} //required
						isHidingContent
						orientation='l'
						buttonDisabled={isLoading}
						filename={`Production_labels_${orderId}_${orderFragmentId}`} //Filename
						buttonClassName="btn btn-primary"
						buttonContent={
							<div className="d-flex flex-row justify-content-center align-items-center m-0">
								{t('print', { ns: 'naming' })}
								<i className="bx bxs-printer font-size-20 align-middle ms-2" />
							</div>
						}
					>
						{productionLablesHTMLToPrint}
					</LabelsPrintableContent>]}
				isOpen={isModalOpen}
				closeModal={() => { setIsModalOpen(false) }}
			/>

		</div>
	)
}

PrintLabelsModal.propTypes = {
	orderId: PropTypes.number,
	orderFragmentId: PropTypes.number,
	t: PropTypes.func,
	getProductionItemsLabels: PropTypes.func,
	productionLabels: PropTypes.object,
	error: PropTypes.string,
	isLoading: PropTypes.bool,
	addCustomerInfoLabel: PropTypes.bool,
	partConfigOptions: PropTypes.object,
}

const mapStateToProps = (state) => ({
	productionLabels: state.Productions.productionLabels,
	isLoading: state.Productions.isLoadingProductonLabels,
	error: state.Productions.errorProductionLabels,
	partConfigOptions: state.Parts.partConfigOptions,
})

const mapDispatchToProps = { getProductionItemsLabels }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PrintLabelsModal))
