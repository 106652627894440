import DatePickr from 'components/Common/DatePickr'
import Modal from 'components/Common/Modal/Modal'
import { InputMinMaxNumbers } from 'components/InputMinMaxNumbers'
import { Couriers } from 'constants/couriers'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import Select from 'react-select'

export const ImportsFiltersModal = (props) => {

	const {
		t,
		isOpen,
		closeModal,
		currentFilters,
		setFilters,
		suppliers,
	} = props

	const courierOptions = useMemo(() => [
		{
			label: 'DHL',
			value: Couriers.DHL,
		},
		{
			label: 'NACEX',
			value: Couriers.NACEX,
		},
		{
			label: t('hand-delivery'),
			value: Couriers.HAND_DELIVERY,
		},
		{
			label: 'UPS',
			value: Couriers.UPS,
		},
	])

	const supplierOptions = useMemo(() => suppliers.map(supplier => ({
		label: supplier?.account?.personalInformation?.firstName,
		value: supplier?.account?.id,
	})))

	const [courierIds, setCourierIds] = useState(currentFilters.courierIds) // number[]
	const [shipmentDateRange, setShipmentDateRange] = useState(currentFilters.shipmentDateRange) // { startDate: string, endDate: string }
	const [arrivalDateRange, setArrivalDateRange] = useState(currentFilters.arrivalDateRange) // { startDate: string, endDate: string }
	const [transitDaysRange, setTransitDaysRange] = useState(currentFilters.transitDaysRange) // { moreThan: number, lessThan: number }
	const [supplierIds, setSupplierIds] = useState(currentFilters.supplierIds) // number[]
	const [totalWeightRange, setTotalWeightRange] = useState(currentFilters.totalWeightRange) // { moreThan: number, lessThan: number }
	const [shipmentCostRange, setShipmentCostRange] = useState(currentFilters.shipmentCostRange) // { moreThan: number, lessThan: number }
	const [tariffDutyRange, setTariffDutyRange] = useState(currentFilters.tariffDutyRange) // { moreThan: number, lessThan: number }
	const [customsDutyRange, setCustomsDutyRange] = useState(currentFilters.customsDutyRange) // { moreThan: number, lessThan: number }
	const [completed, setCompleted] = useState(currentFilters.completed) // boolean

	const setDateRange = (dates, setter) => setter({
		startDate: new Date(dates[0]),
		endDate: new Date(new Date(dates[1]).setHours(23, 59, 59)),
	})
	const resetDateRange = (setter) => setter({
		startDate: null,
		endDate: null,
	})

	return (
		<Modal
			isOpen={isOpen}
			closeModal={closeModal}
			title={t('filter')}
			className="modal-lg"
			body={(
				<div>
					<div className="d-felx flex-column justify-content-start mt-4 mb-4 p-2">
						<div className="mx-3 d-flex flex-column gap-2">
							{/* --- Transportista --- */}
							<h6>{t('courier')}</h6>
							<Select
								isMulti
								onChange={optionsSelected => setCourierIds(optionsSelected.map(option => option.value))}
								options={courierOptions}
								value={courierOptions.filter(x => courierIds?.includes(x.value))}
							/>
							{/* --- Fecha de envío --- */}
							<DatePickr
								title={t('shipmentDate', { ns: 'naming' })}
								mode={'range'}
								format={'Y-m-d'}
								value={[shipmentDateRange?.startDate, shipmentDateRange?.endDate]}
								setValue={(...dates) => setDateRange(...dates, setShipmentDateRange)}
								placeholder={t('start_end_date', { ns: 'naming' })}
								cleanData={() => resetDateRange(setShipmentDateRange)}
							/>
							{/* --- Fecha de llegada --- */}
							<DatePickr
								title={t('arrivalDate', { ns: 'naming' })}
								mode={'range'}
								format={'Y-m-d'}
								value={[arrivalDateRange?.startDate, arrivalDateRange?.endDate]}
								setValue={(...dates) => setDateRange(...dates, setArrivalDateRange)}
								placeholder={t('start_end_date', { ns: 'naming' })}
								cleanData={() => resetDateRange(setArrivalDateRange)}
							/>
							{/* --- Días de tránsito --- */}
							<InputMinMaxNumbers
								t={t}
								name={t('transitDays')}
								range={transitDaysRange}
								setRange={setTransitDaysRange}
							/>
							{/* --- Proveedores --- */}
							<h6>{t('supplier')}</h6>
							<Select
								isMulti
								onChange={optionsSelected => setSupplierIds(optionsSelected.map(option => option.value))}
								options={supplierOptions}
								value={supplierOptions.filter(x => supplierIds?.includes(x.value))}
							/>
							{/* --- Peso total --- */}
							<InputMinMaxNumbers
								t={t}
								name={t('totalWeight')}
								range={totalWeightRange}
								setRange={setTotalWeightRange}
							/>
							{/* --- Coste de envío --- */}
							<InputMinMaxNumbers
								t={t}
								name={t('shipmentCost')}
								range={shipmentCostRange}
								setRange={setShipmentCostRange}
							/>
							{/* --- Coste aranceles (tariffDuty) --- */}
							{/* --- Coste aduanas (customsDuty) --- */}
							{/* --- Datos completados --- */}
							<h6>{t('complete')}</h6>
							<div style={{ alignItems: 'start' }}>
								<button className={`btn ${completed === true ? 'btn-success' : 'btn-outline-light'}`} onClick={() => setCompleted(true)}>{t('yes')}</button>
								<button className={`btn ${completed === false ? 'btn-danger' : 'btn-outline-light'}`} onClick={() => setCompleted(false)}>{t('no')}</button>
								<button className="btn btn-light" onClick={() => setCompleted(null)}>{t('reset')}</button>
							</div>
						</div>
					</div>
				</div>
			)}
			buttons={[
				<button
					type="button"
					key="clearButton"
					className="btn btn-danger"
					onClick={e => {
						e.preventDefault()
						setCourierIds(null)
						setShipmentDateRange(null)
						setArrivalDateRange(null)
						setTransitDaysRange(null)
						setSupplierIds(null)
						setTotalWeightRange(null)
						setShipmentCostRange(null)
						setTariffDutyRange(null)
						setCustomsDutyRange(null)
						setCompleted(null)
					}}
				>
					{t('reset')}
				</button>,
				<button
					type="button"
					key="setFiltersButton"
					className="btn btn-primary"
					onClick={e => {
						e.preventDefault()
						setFilters({
							courierIds,
							shipmentDateRange,
							arrivalDateRange,
							transitDaysRange,
							supplierIds,
							totalWeightRange,
							shipmentCostRange,
							tariffDutyRange,
							customsDutyRange,
							completed,
						})
						closeModal()
					}}
				>
					{t('save')}
				</button>,
			]}
		/>
	)
}

ImportsFiltersModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	currentFilters: PropTypes.object,
	setFilters: PropTypes.func,
	suppliers: PropTypes.array,
}
