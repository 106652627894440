import { useHandleCancelableApi } from 'common/Hooks/UseHandleCancelableApi'
import { useObjectState } from 'common/Hooks/UseObjectState'
import { sortByDate } from 'common/Utils/ArrayUtilities'
import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import { mapManagers } from 'common/Utils/ManagersUtils'
import { getDate, getStatusClassName } from 'common/Utils/StringUtilities'
import FileInput from 'components/Common/FileInput'
import Modal from 'components/Common/Modal/Modal'
import OptionsButtons from 'components/Common/OptionsButtons'
import { OptionsInput } from 'components/Common/OptionsInput'
import TextInput from 'components/Common/TextInput'
import { NOTIFICATION_TYPE } from 'constants/notificationType'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { createNewCrmLog, createNotification, getCustomersOrdersOffersByManagerId, getFilteredCustomersList, getFilteredOffers, getFilteredOrders, getManagerTaskImagesByNotificationId, getManagers, getOrderDetailById, getOrderItemByOrderIdSearch, resetNotificationCreated, updateNotifications, uploadManagerTaskImage } from 'store/actions'


export const ModalCreateTaskManager = ({
	getManagerTaskImagesByNotificationId,
	managerTaskImages,
	getCustomersOrdersOffersByManagerId,
	managerCustomersOrdersOffers,
	t,
	managers,
	isLoading,
	selectedManagersIds,
	isOpen,
	closeModal,
	getManagers,
	createNotification,
	notificationCreated,
	errorNotificationCreated,
	resetNotificationCreated,
	orderDetail,
	getFilteredOrders,
	orderList,
	getFilteredOffers,
	offerList,
	customersList,
	getFilteredCustomersList,
	uploadManagerTaskImage,
	selectedNotification,
	updateNotifications,
	notificationsUpdated
}) => {

	const INIT_STATE = {
		id: null,
		type_id: null,
		data_id: null,
		account_target_id: null,
		read: false,
		readDate: null,
		creationDate: new Date(),
		completed: null,
		important: null,
		subject: null,
		notes: null,
		taskImages: [],
		keyWord: "",
	}


	const { handleOnChange, objectData, resetState, setObjectData } = useObjectState(INIT_STATE)
	const [selectedOptionRelated, setSelectedOptionRelated] = useState()
	const [isOnlyOneManager, setIsOnlyOneManager] = useState(false)
	const [isModalMessageOpen, setIsModalMessageOpen] = useState(false)
	const [modalMessage, setModalMessage] = useState('')
	const [imageFiles, setImageFiles] = useState()
	const [isUpdateModal, setIsUpdateModal] = useState(false)
	const [isUploadImage, setIsUploadImage] = useState(false)

	useEffect(() => {
		if (notificationCreated) {
			setIsModalMessageOpen(true)
			setModalMessage(t("task_created_successfully", { ns: "naming" }))
		} else if (errorNotificationCreated) {
			setIsModalMessageOpen(true)
			setModalMessage(t("error_creating_task", { ns: "naming" }))
		}
	}, [notificationCreated, errorNotificationCreated])

	const filteredByTypeIdAndDataId = (type_id, data_id) => {
		const inputBody = getInputBody(data_id)
		switch (type_id) {
			case NOTIFICATION_TYPE.OrderTask:
				getFilteredOrders(inputBody)
				break
			case NOTIFICATION_TYPE.OfferTask:
				getFilteredOffers(inputBody.data)
				break
			case NOTIFICATION_TYPE.CustomerTask:
				getFilteredCustomersList(inputBody)
				break
			default:
				break
		}
	}


	useEffect(() => {
		if (selectedNotification) {
			setIsUpdateModal(true)
			setObjectData({
				...INIT_STATE,
				id: selectedNotification.id,
				type_id: selectedNotification.type_id,
				data_id: parseInt(selectedNotification.data_id),
				account_target_id: selectedNotification.account_target_id,
				subject: selectedNotification.subject,
				notes: selectedNotification.notes,
			})
			filteredByTypeIdAndDataId(selectedNotification.type_id, selectedNotification.data_id)
			setSelectedOptionRelated(selectedNotification.type_id)
			getManagerTaskImagesByNotificationId({
				data: {
					notificationId: selectedNotification.id,
					managerId: selectedNotification.account_target_id
				}
			})
		}
	}, [selectedNotification])



	useEffect(() => {
		if (managers && managers.length <= 0) {
			getManagers()
		}
		if (!selectedNotification) {
			const currentUser = getUserValuesFromLocalStorage()
			const isManager = currentUser?.roles.includes('manager') && !currentUser?.roles.includes('boss')
			const isBoss = currentUser?.roles.includes('boss') && currentUser?.roles.includes('manager')

			if (selectedManagersIds.length === 0 && isManager) {
				handleOnChange('account_target_id', currentUser.id)
				setIsOnlyOneManager(true)
			}

			else if (selectedManagersIds.length === 1) {
				handleOnChange('account_target_id', selectedManagersIds[0])
				setIsOnlyOneManager(true)
			} else if (isBoss && selectedManagersIds.length === 0) {
				setIsOnlyOneManager(false)
			}


		}

	}, [])

	const pagination = {
		page: 1,
		pageSize: 20,
	}

	const inputBody = {
		data: {
			keyword: objectData && objectData.data_id ? objectData.data_id.toString() : '',
			pagination
		},
	}

	const { handleApiCall: getHandledFiltredCustomerList } = useHandleCancelableApi(getFilteredCustomersList, inputBody, 500)
	const { handleApiCall: getHandledFiltredOffer } = useHandleCancelableApi(getFilteredOffers, inputBody.data, 500)
	const { handleApiCall: getHandledFiltredOrder } = useHandleCancelableApi(getFilteredOrders, inputBody, 500)





	const getInputBody = (data_id) => {
		return {
			data: {
				keyword: data_id.toString(),
				pagination
			}
		}
	}


	useEffect(() => {
		if (!objectData.data_id) return
		switch (selectedOptionRelated) {
			case NOTIFICATION_TYPE.OrderTask:
				getHandledFiltredOrder(inputBody)
				break
			case NOTIFICATION_TYPE.OfferTask:
				getHandledFiltredOffer(inputBody.data)
				break
			case NOTIFICATION_TYPE.CustomerTask:
				getHandledFiltredCustomerList(inputBody)
				break
			default:
				break
		}
	}, [selectedOptionRelated, objectData.data_id])


	const mapOffers = () => {
		if (!offerList || !offerList.offers || offerList.offers.length === 0) return []
		return sortByDate(offerList.offers, 'date.value').map(offer => {
			return {
				value: offer.id,
				label: (
					<div className='d-flex flex-row justify-content-start'>
						<span className='mx-2'>{offer.id} </span>
						<span className='mx-2'>{getDate(offer.date.value)}</span>
					</div>
				)
			}
		})
	}

	const mapOrders = () => {
		if (!orderList || orderList.length === 0) return []
		return orderList.map(({ order }) => ({
			value: order.id,
			label: (
				<div className='d-flex flex-row justify-content-start'>
					<span className='mx-2'>{order.id} </span>
					<span className='mx-2'>{getDate(order.creationDate)}</span>
					<span className={'mx-2 ' + getStatusClassName(order.statusId)}>
						{t(`order_status_${order.statusId}`, { ns: 'status' })}
					</span>
				</div>
			)
		}))
	}



	const mapCustomers = () => {
		if (!customersList || customersList.length === 0 || customersList.length < 0) return []
		return customersList.map(({ account }) => ({
			value: account.id,
			label: `${account.personalInformation.firstName} ${account.personalInformation.lastName}`,
			customLabel: (
				<div className='d-flex flex-column justify-content-start'>
					{account.organizationName && (
						<span className='mx-2 my-1' style={{ fontWeight: "bolder" }}>
							{account.organizationName}
						</span>
					)}
					<span className='mx-2'>
						{account.personalInformation.firstName} {account.personalInformation.lastName}
					</span>
				</div>
			)
		}))
	}
	const getOptions = () => {
		switch (selectedOptionRelated) {
			case NOTIFICATION_TYPE.OrderTask:
				return mapOrders()
			case NOTIFICATION_TYPE.OfferTask:
				return mapOffers()
			case NOTIFICATION_TYPE.CustomerTask:
				return mapCustomers()
			default:
				return null
		}
	}
	const options = useMemo(() => getOptions(), [orderList, offerList, customersList])

	const handleSave = () => {
		setHasPressed(true)

		if (isUpdateModal) {
			const { taskImages, keyWord, ...updatedData } = objectData
			const updatedNotification = {
				...updatedData,
				read: true
			}
			updateNotifications([updatedNotification])
			setIsModalMessageOpen(true)
			setModalMessage(t("task_updated_successfully", { ns: "naming" }))
		} else {
			createNotification({
				data: {
					notification: objectData
				}
			})
		}

		setIsUploadImage(true)

	}




	useEffect(() => {
		if (notificationCreated) {
			if (imageFiles != null) {
				const notificationId = notificationCreated.id
				const imagesInput = {
					data: {
						notificationId: notificationId,
						managerId: objectData.account_target_id,
					},
					taskImages: imageFiles,
				}
				uploadManagerTaskImage(imagesInput)
			}
		}
	}, [notificationCreated, notificationsUpdated, isUpdateModal])


	useEffect(() => {
		if (isUploadImage) {
			if (isUpdateModal) {
				if (imageFiles != null) {
					if (imageFiles.length > 0) {
						const imagesInput = {
							data: {
								notificationId: selectedNotification.id,
								managerId: objectData.account_target_id,
							},
							taskImages: imageFiles,
						}
						uploadManagerTaskImage(imagesInput)
					}

				}
			}
		}

	}, [imageFiles, isUpdateModal, isUploadImage])



	useEffect(() => { })

	const [hasPressed, setHasPressed] = useState(false)
	const managerOptions = useMemo(() => managers && mapManagers(managers), [managers])

	const relatedWithOptions = [{
		text: t('general', { ns: 'naming' }),
		value: NOTIFICATION_TYPE.GenericTask,
		color: 'warning',
	},
	{
		text: t('order', { ns: 'naming' }),
		value: NOTIFICATION_TYPE.OrderTask,
		color: 'warning',
	}, {
		text: t('offer', { ns: 'naming' }),
		value: NOTIFICATION_TYPE.OfferTask,
		color: 'warning',
	}, {
		text: t('client', { ns: 'naming' }),
		value: NOTIFICATION_TYPE.CustomerTask,
		color: 'warning',
	}]

	const close = () => {

		resetState()
		closeModal()
	}

	const closeModalMessage = () => {
		setIsModalMessageOpen(false)
		resetNotificationCreated()
		closeModal()
	}

	return (
		<div>
			<Modal
				isOpen={isOpen}
				title={isUpdateModal ? t('edit_task_manager', { ns: 'naming' }) : t('add_task_manager', { ns: 'naming' })}
				closeModal={close}
				body={
					<div className='p-4'>
						<div className='my-1 mb-3'>
							<h6 className='p-2'>{t('related_with', { ns: 'naming' })}</h6>
							<div>
								<OptionsButtons
									options={relatedWithOptions}
									valueSelected={objectData.type_id}
									handleOnChange={value => {
										setSelectedOptionRelated(value)
										handleOnChange('type_id', value)
									}}
								/>
							</div>
						</div>

						{selectedOptionRelated !== NOTIFICATION_TYPE.GenericTask && (
							<OptionsInput
								className="w-100"
								value={objectData.data_id}
								objectElement="data_id"
								setValue={handleOnChange}
								options={getOptions()}
								automaticMappedValue
								title={t('related_id', { ns: 'naming' })}
							/>
						)}
						<TextInput
							value={objectData.subject}
							isRequired={true}
							setValue={handleOnChange}
							title={t('subject', { ns: 'naming' })}
							objectElement="subject"
						/>
						<div className="m-2">
							<label htmlFor='message'>
								<span className="text-primary me-1"></span>{t('observations', { ns: 'naming' })}
							</label>
							<textarea
								name='message'
								id='message'
								cols={20}
								className="form-control"
								value={objectData.notes}
								onChange={e => handleOnChange('notes', e.target.value)}
							/>
						</div>

						{isUpdateModal && Array.isArray(managerTaskImages) && managerTaskImages.length > 0 && (
							<div className="m-3">
								<h6>{t('Task_images', { ns: 'naming' })}</h6>
								<div className="d-flex flex-wrap">
									{managerTaskImages.map((image, index) => (
										<div key={index} className="me-2 mb-2">
											<img src={image.src} alt={image.name} className="img-thumbnail" style={{ maxWidth: '150px', maxHeight: '150px' }} />
										</div>
									))}
								</div>
							</div>
						)}
						<div className="d-flex flex-row mt-3">
							<FileInput
								className="w-50"
								value={objectData.taskImages}
								objectElement="taskImages"
								setValue={handleOnChange}
								setFiles={setImageFiles}
								title={t('images', { ns: 'naming' })}
								multiple
								accept="image/*"
							/>
						</div>
						{
							managers.length > 0 &&
							<OptionsInput
								isRequired
								hasPressed={hasPressed}
								title={t('manager', { ns: 'naming' })}
								value={objectData.account_target_id}
								options={managerOptions}
								setValue={handleOnChange}
								objectElement="account_target_id"
								automaticMappedValue
								disabled={isOnlyOneManager}
							/>
						}
					</div>
				}
				buttons={[<button key={'save'} className='btn btn-primary' disabled={
					objectData.type_id === null ||
					(objectData.type_id !== NOTIFICATION_TYPE.GenericTask && objectData.data_id === null) ||
					(objectData.subject === null && objectData.subject === "")
				} onClick={e => handleSave()}>{t('save', { ns: 'naming' })}</button>]}
			/>
			<ModalMessage
				closeModal={closeModalMessage}
				isModalOpen={isModalMessageOpen}
				message={modalMessage}
			/>
		</div>
	)
}

ModalCreateTaskManager.propTypes = {
	t: PropTypes.func,
	managers: PropTypes.array,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	createNewCrmLog: PropTypes.func,
	isLoading: PropTypes.bool,
	managerTaskImages: PropTypes.array,
	managerCustomersOrdersOffers: PropTypes.object,
	getManagerTaskImagesByNotificationId: PropTypes.func,
	getCustomersOrdersOffersByManagerId: PropTypes.func,
	getManagers: PropTypes.func,
	createNotification: PropTypes.func,
	notificationCreated: PropTypes.object,
	errorNotificationCreated: PropTypes.object,
	resetNotificationCreated: PropTypes.func,
	getOrderItemByOrderIdSearch: PropTypes.func,
	orderDetail: PropTypes.object,
	getOrderDetailById: PropTypes.func,
	getFilteredOrders: PropTypes.func,
	orderList: PropTypes.array,
	getFilteredOffers: PropTypes.func,
	offerList: PropTypes.object,
	customersList: PropTypes.array,
	getFilteredCustomersList: PropTypes.func,
	uploadManagerTaskImage: PropTypes.func,
	updateNotifications: PropTypes.func,
	notificationsUpdated: PropTypes.bool
}

const mapStateToProps = (state) => ({
	managers: state.Users.managers,
	isLoading: state.Users.isCreatingCrmLog,
	createNewCrmLog_Status: state.Crm.createNewCrmLog_Status,
	managerTaskImages: state.CloudStorage.managerTaskImages,
	managerCustomersOrdersOffers: state.Users.managerCustomersOrdersOffers,
	notificationCreated: state.Users.notificationCreated,
	errorNotificationCreated: state.Users.errorNotificationCreated,
	orderDetail: state.Orders.orderDetail,
	orderList: state.Orders.orderList,
	offerList: state.Offers.offerList,
	customersList: state.Users.customersList,
	notificationsUpdated: state.Users.notificationsUpdated,
})

const mapDispatchToProps = {
	getManagers,
	createNewCrmLog,
	getManagerTaskImagesByNotificationId,
	getCustomersOrdersOffersByManagerId,
	createNotification,
	resetNotificationCreated,
	getOrderItemByOrderIdSearch,
	getOrderDetailById,
	getFilteredOrders,
	getFilteredOffers,
	getFilteredCustomersList,
	uploadManagerTaskImage,
	updateNotifications
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalCreateTaskManager))
