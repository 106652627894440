import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const ProductionNote = ({ t, note }) => {
	return (
		<div>
			<div className="p-2  px-3">
				<h4>{t('production-note', { ns: 'naming' })}:</h4>
				<div>{note}</div>
			</div>
		</div>
	)
}
ProductionNote.propTypes = {
	t: PropTypes.func,
	note: PropTypes.string,
}
export default withTranslation()(ProductionNote)
