import { addVerificationBlueprintSeenStateToLocalStorage, addVerificationInternalMessageStateToLocalStorage, getUserValuesFromLocalStorage, getVerificationBlueprintSeenStateFromLocalStorage, getVerificationInternalMessageStateFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import { getFullPartDescription } from 'common/Utils/PartUtils'
import { IconTooltip } from 'components/Common/IconTooltip'
import { PartName } from 'components/Common/PartName'
import PartThumbnail from 'components/Common/PartThumbnail'
import SimpleTable from 'components/Common/SimpleTable'
import VerificationFiles from 'components/Common/VerificationFiles'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { getAccountByEmail, getBoxList } from '../../../store/actions'
import BlueprintSeenModal from '../Modals/BlueprintSeenModal'
import IncidencesModal from '../Modals/IncidencesModal'
import InternalMessageModal from '../Modals/InternalMessageModal'
import QuantitiesModal from '../Modals/QuantitiesModal'
import ReadInternalMessageModal from '../Modals/ReadInternalMessageModal'
import RegisterIncidenceModal from '../Modals/RegisterIncidenceModal'
import RegisterVerificationModal from '../Modals/RegisterVerificationModal'
import UnreceivedPartModal from '../Modals/UnreceivedPartModal'
import PartChat from './PartChat'

const SelectedOrder = ({
	orderInfo,
	selectedOrderId,
	boxList,
	getBoxList,
	getBoxListStatus = {},
	lastScannedPart,
	getAccountByEmail,
	account,
	partConfigOptions,
}) => {
	const { t, i18n: { language } } = useTranslation()
	const tableHeaders = ['item', 'image', 'id', 'name', 'specifications', 'files', 'comment', 'chat', 'parts', 'verification']
	const [totalPieces, setTotalPieces] = useState(0)
	const [isInternalMessageModalOpen, setIsInternalMessageModalOpen] = useState(false)
	const [isIncidencesModalOpen, setIsIncidencesModalOpen] = useState(false)
	const [isRegisterIncidenceModalOpen, setIsRegisterIncidenceModalOpen] = useState(false)
	const [isRegisterVerificationModalOpen, setIsRegisterVerificationModalOpen] = useState(false)
	const [isQuantitiesModalOpen, setIsQuantitiesModalOpen] = useState(false)
	const [isReadInternalMessageModalOpen, setIsReadInternalMessageModalOpen] = useState(false)
	const [isBlueprintSeenModalOpen, setIsBlueprintSeenModalOpen] = useState(false)
	const [isUnreceivedPartModalOpen, setIsUnreceivedPartModalOpen] = useState(false)
	const [isInternalMessageRead, setIsInternalMessageRead] = useState(false)
	const [isBlueprintSeen, setIsBlueprintSeen] = useState({})
	const [incidencePartId, setIncidencePartId] = useState(null)
	const [partQuantity, setPartQuantity] = useState(null)
	const [partScannedQuantity, setPartScannedQuantity] = useState(null)
	const [partShippedQuantity, setPartShippedQuantity] = useState(null)
	const [partQuantityList, setPartQuantityList] = useState([])
	const [internalMessages, setInternalMessages] = useState([])
	const [isOrderLoaded, setIsOrderLoaded] = useState(false)
	const [isCommentViewed, setIsCommentViewed] = useState({})
	const [savedComment, setSavedComment] = useState('')
	const [isNotCommentViewedModalOpen, setIsNotCommentViewedModalOpen] = useState(false)
	const [isCommentModalOpen, setIsCommentModalOpen] = useState(false)

	const handleCommentClick = (comment, partId) => {
		if (comment) {
			setIsCommentViewed(prev => ({
				...prev,
				[partId]: true
			}))
			setSavedComment(comment)
			setIsCommentModalOpen(true)
		}
	}

	useEffect(() => {
		setIsCommentViewed({})
		setSavedComment('')
	}, [selectedOrderId])


	const userEmail = useMemo(() => {
		return getUserValuesFromLocalStorage().email
	}, [])

	setTimeout(() => {
		const orderInfoTable = document.getElementById('orderInfoTable')
		if (orderInfoTable) {
			const rows = orderInfoTable.getElementsByTagName('tr')
			if (rows) {
				const rowsArray = Array.from(rows)
				rowsArray.shift()
				rowsArray.forEach(row => {
					const tds = row.getElementsByTagName('td')
					if (tds) {
						const tdArray = Array.from(tds)
						const tdPartId = tdArray[2]?.innerText.split('NO')[0] //When a part is verified as wrong this is id + NO OK, this gets the id either if there's tag or not
						if (tdPartId == lastScannedPart) {
							tdArray.forEach(td => {
								td.style.backgroundColor = 'lightblue'
							})
							tdArray[2].scrollIntoView({ behavior: 'smooth', block: 'center' })
						} else {
							tdArray.forEach(td => {
								td.style.backgroundColor = getRowColor(tdPartId)
							})
						}
					}
				})
			}
		}
	}, 0)

	const getTotalPieces = items => {
		let total = 0
		items?.forEach(item => {
			total += item.quantity
		})
		setTotalPieces(total)
	}

	const getRowColor = partId => {
		const partState = orderInfo?.qualityPartStates?.find(e => e.partId == partId)?.state
		if (partState === null) return 'white'
		if (partState === true) return '#C4D79B'
		if (partState === false) return '#F08080'
	}

	const calculatePartQuantityList = (maxQuantity) => {
		return [0, ...Array(maxQuantity).fill().map((element, index) => index + 1)]
	}

	const calculateShippedQuantity = (partId) => {
		const productionItems = orderInfo?.order?.productions.flatMap((production) => production.productionItemList).filter(((productionItem) => productionItem.part.id == partId))
		const shippedQuantities = productionItems.map((productionItem) => productionItem.shippedQuantity)
		return shippedQuantities.reduce((a, b) => a + b, 0)
	}

	const calculateScannedQuantity = (partId) => {
		const productionItems = orderInfo?.order?.productions.flatMap((production) => production.productionItemList).filter(((productionItem) => productionItem.part.id == partId))
		const scannedQuantities = productionItems.map((productionItem) => productionItem.receivedQuantity)
		return scannedQuantities.reduce((a, b) => a + b, 0)
	}

	const setInternalMessageAsRead = (value) => {
		setIsInternalMessageRead(value)
		addVerificationInternalMessageStateToLocalStorage(orderInfo?.order?.id, value)
	}

	const setBlueprintAsSeen = (value) => {
		setIsBlueprintSeen(value)
		addVerificationBlueprintSeenStateToLocalStorage(orderInfo?.order?.id, value)
	}

	const openInternalMessageModal = () => {
		setIsInternalMessageModalOpen(true)
	}

	const closeInternalMessageModal = () => {
		setIsInternalMessageModalOpen(false)
		setInternalMessageAsRead(true)
	}

	const openIncidencesModal = partId => {
		setIsIncidencesModalOpen(true)
		setIncidencePartId(partId)
	}

	const closeIncidencesModal = () => {
		setIsIncidencesModalOpen(false)
		setIncidencePartId(null)
	}

	const openRegisterIncidenceModal = (partId, maxQuantity, comment) => {
		if (!isInternalMessageRead) {
			setIsReadInternalMessageModalOpen(true)
		} else if (!isBlueprintSeen[partId]) {
			setIsBlueprintSeenModalOpen(true)
		} else if (comment != null && !isCommentViewed[partId] && comment != "") {
			setIsNotCommentViewedModalOpen(true)
		}
		else {
			setIsRegisterIncidenceModalOpen(true)
			setIncidencePartId(partId)
			setPartQuantityList(calculatePartQuantityList(maxQuantity))
		}
	}

	const closeRegisterIncidenceModal = () => {
		setIsRegisterIncidenceModalOpen(false)
		setIncidencePartId(null)
	}

	const openRegisterVerificationModal = (partId, maxQuantity, currentQuantity, comment) => {
		if (!isInternalMessageRead) {
			setIsReadInternalMessageModalOpen(true)
		} else if (!isBlueprintSeen[partId]) {
			setIsBlueprintSeenModalOpen(true)
		} else if (comment != null && !isCommentViewed[partId] && comment != "") {
			setIsNotCommentViewedModalOpen(true)
		} else {
			setIsRegisterVerificationModalOpen(true)
			setIncidencePartId(partId)
			setPartQuantityList(calculatePartQuantityList(maxQuantity))
			setPartScannedQuantity(currentQuantity)
			setPartShippedQuantity(calculateShippedQuantity(partId))
		}
	}
	const openRegisterVerificationModalOrIncidencesModal = (partId, maxQuantity, currentQuantity, comment) => {
		const qualityVerifications = orderInfo?.qualityVerifications?.filter(x => x.partId == partId)

		if (qualityVerifications && !areAllIncidencesSolved(qualityVerifications)) {
			openIncidencesModal(partId)
		} else {
			openRegisterVerificationModal(partId, maxQuantity, currentQuantity, comment)
		}
	}

	const closeRegisterVerificationModal = () => {
		setIsRegisterVerificationModalOpen(false)
		setIncidencePartId(null)
	}

	const openQuantitiesModal = quantity => {
		setIsQuantitiesModalOpen(true)
		setPartQuantity(quantity)
	}

	const closeQuantitiesModal = () => {
		setIsQuantitiesModalOpen(false)
		setPartQuantity(null)
	}

	const closeReadInternalMessageModal = () => {
		setIsReadInternalMessageModalOpen(false)
	}

	const closeBlueprintSeenModal = () => {
		setIsBlueprintSeenModalOpen(false)
	}

	const closeUnreceivedPartModal = () => {
		setIsUnreceivedPartModalOpen(false)
	}

	const areAllIncidencesSolved = (qualityVerifications) => {
		return qualityVerifications.every(incidence => incidence.solved === true)
	}

	const parseOrder = (orderDetails) => {
		return orderInfo?.order?.orderItems.map((element, index) => {
			const stlData = {
				partId: element.part.id,
				partName: element.part.name,
				size: element.part.size,
				weight: element.part.weight,
			}
			const qualityVerification = orderDetails.qualityVerifications?.filter(x => x.partId == element.part.id)
			const isOK = orderDetails.qualityPartStates?.find(x => x.partId == element.part.id)?.state
			const chatMessages = element.part.chatMessages
			const scannedQuantity = calculateScannedQuantity(element.part.id)
			return (
				<tr key={`part_${element.part.id}`} valign="middle">
					<td>{(index + 1).toString() + '.'}</td>
					<td>
						<PartThumbnail stlData={stlData} propsStlUrl={element.part.fileLinks?.stlModel} propsImageUrl={element.part?.imageUrl} />
					</td>
					<td>
						<div className="d-flex flex-column align-items-center">
							<div>{element.part.id}</div>
							{isOK == false && (
								<div className="bg-red rounded p-1 px-2 text-white ms-1">
									<strong>NO OK</strong>
								</div>
							)}
						</div>
					</td>
					<td>
						<PartName name={element.part.name} maxLength={20} />
					</td>
					<td>
						<div className="d-flex flex-column verification-part-config">
							{getFullPartDescription(element.part, partConfigOptions, language)}
						</div>
					</td>
					<td>
						<VerificationFiles
							blueprintUrl={element.part.fileLinks?.originalBlueprint || element.part.fileLinks?.modifiedBlueprint}
							model3dUrl={element.part.fileLinks?.stepModel}
							isBlueprintSeen={isBlueprintSeen[element.part.id]}
							setBlueprintAsSeen={() => setBlueprintAsSeen({ ...isBlueprintSeen, [element.part.id]: true })}
						/>
					</td>
					<td align="center">{element.part.comment && <IconTooltip icon={`bx bx-message-dots cursor-pointer fs-2 ${isCommentViewed[element.part.id] ? '' : 'text-warning'}`} message={element.part.comment} onClick={() => handleCommentClick(element.part.comment, element.part.id)}
						position="left" name={`Comment_${element.part.id}`} />}</td>
					<td align="center"><PartChat partId={element.part.id} chatMessages={chatMessages} /></td>
					<td>
						<div className="d-flex flex-column align-items-center">
							<div>
								{scannedQuantity + ' ' + t('of', { ns: 'naming' }) + ' ' + element.quantity + ' ' + t('pieces', { ns: 'naming' })}
							</div>
							{scannedQuantity < element.quantity && <IconTooltip icon="bx bxs-error ms-2 text-primary" message={t('missing-reception-parts', { ns: 'naming' })} position="bottom" name={`MissingParts_${element.part.id}`} />}
						</div>
					</td>
					<td>
						<div className="d-flex flex-row">
							<button
								//Si tiene incidencias de calidad se tendrá que abrir el openIncidencesModal
								onClick={() => openRegisterVerificationModalOrIncidencesModal(element.part.id, element.quantity, scannedQuantity, element.part.comment)}
								className="btn btn-sm btn-success me-2 fs-6 w-auto d-flex align-items-center justify-content-center"
							>
								<i className="bx bx-check"></i>
							</button>
							<button
								onClick={() => openRegisterIncidenceModal(element.part.id, element.quantity, element.part.comment)}
								className="btn btn-sm btn-danger me-2 fs-6 w-auto d-flex align-items-center justify-content-center"
							>
								<i className="bx bx-block"></i>
							</button>
							<button
								disabled={qualityVerification == null || qualityVerification.length == 0}
								className={`btn btn-sm ${qualityVerification == null || qualityVerification.length == 0 ? 'btn-outline-dark' : 'btn-dark'} me-2 fs-6 w-auto d-flex align-items-center justify-content-center`}
								onClick={() => openIncidencesModal(element.part.id)}
							>
								<i className="bx bx-history"></i>
							</button>
						</div>
					</td>
				</tr>
			)
		})
	}

	const parsedOrderInfo = useMemo(() => {
		if (orderInfo && Object.keys(orderInfo).length !== 0) {
			return parseOrder(orderInfo)
		} else {
			return []
		}
	}, [isInternalMessageRead, orderInfo, isBlueprintSeen, isCommentViewed])

	const checkBlueprintsToSee = (orderItems) => {
		const blueprintSeenState = getVerificationBlueprintSeenStateFromLocalStorage(orderInfo?.order?.id)
		if (blueprintSeenState) {
			setIsBlueprintSeen(blueprintSeenState)
		} else {
			let isBlueprintSeenToUpdate = {}
			orderItems != null && orderItems.forEach((item) => {
				const partId = item.part.id
				const blueprint = item.part.fileLinks?.originalBlueprint
				isBlueprintSeenToUpdate = {
					...isBlueprintSeenToUpdate,
					[partId]: blueprint == null,
				}
			})
			setIsBlueprintSeen({
				...isBlueprintSeen,
				...isBlueprintSeenToUpdate,
			})
		}
	}

	useEffect(() => {
		if (orderInfo && Object.keys(orderInfo).length !== 0) {
			getTotalPieces(orderInfo.order?.orderItems)
			checkBlueprintsToSee(orderInfo.order?.orderItems)
			const internalMessageState = getVerificationInternalMessageStateFromLocalStorage(orderInfo?.order?.id)
			if (!internalMessageState) {
				if (orderInfo.order?.internalComment && orderInfo.order?.internalComment?.length != 0) {
					setIsInternalMessageRead(false)
				} else {
					setIsInternalMessageRead(true)
				}
			} else {
				setIsInternalMessageRead(internalMessageState)
			}
			setInternalMessages(orderInfo.order?.internalComment)
		}
	}, [orderInfo])

	useEffect(() => {
		if (!getBoxListStatus?.isLoading && (boxList == null || boxList.length == 0)) {
			getBoxList()
		}
	}, [])

	useEffect(() => {
		if (userEmail != null) {
			getAccountByEmail({ data: { email: userEmail } })
		}
	}, [userEmail])

	useEffect(() => {
		if (orderInfo) setIsOrderLoaded(true) //TODO revise this
	}, [orderInfo])

	useEffect(() => {
		if (lastScannedPart && orderInfo) {
			const part = orderInfo?.order?.orderItems.find(e => e.part.id == lastScannedPart)
			if (part) {
				if (calculateScannedQuantity(part.partId) == 0) {
					setIsUnreceivedPartModalOpen(true)
				} else {
					openQuantitiesModal(part.quantity)
				}
			}
		}
	}, [lastScannedPart, isOrderLoaded])

	return (
		<>
			<div>
				<div
					className="w-100 p-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
				>
					<h5 className="pt-2">
						<strong>
							{t('activeOrder', { ns: 'naming' })}
							{' '}
							<Link to={`/order/${selectedOrderId}`}>
								#{selectedOrderId}
							</Link>
							{' | '}
							{orderInfo?.order?.billingInfo?.companyName}
						</strong>
					</h5>
				</div>
				<div className="w-100 p-2 d-flex align-items-center" style={{ border: '0.5px solid lightgray', height: '100%' }}>
					<div className="col-11">
						<strong className="pt-3">{t('orderElements', { ns: 'naming' })}</strong>
					</div>
					<div className="col-1 d-flex flex-row justify-content-end align-items-center">
						<button
							className={`btn btn-sm ${isInternalMessageRead ? 'btn-light' : 'btn-primary'
								} fs-6 w-auto d-flex align-items-center justify-content-center`}
							onClick={() => openInternalMessageModal()}
						>
							<i className="bx bxs-envelope"></i>
						</button>
					</div>
				</div>
				<div className="w-100" style={{ border: '0.5px solid lightgray', height: '100%' }}>
					{Object.keys(parsedOrderInfo).length !== 0 && (
						<>
							<div id={'orderInfoTable'}>
								<SimpleTable key={'orderInfoTable'} header={tableHeaders} getTableBody={() => parsedOrderInfo} striped={false} />
							</div>

						</>
					)}
				</div>
				<div
					className="w-100 ps-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
				>
					<p className="pt-3">
						{t('thisOrdersContains', { ns: 'naming' })} {orderInfo?.order?.orderItems?.length} {t('referencesFromATotalOf', { ns: 'naming' })}{' '}
						{totalPieces} {t('pieces', { ns: 'naming' })}
					</p>
				</div>
			</div>
			<InternalMessageModal
				isOpen={isInternalMessageModalOpen}
				closeModal={closeInternalMessageModal}
				messages={internalMessages}
				account={account}
				orderId={selectedOrderId}
			/>
			<IncidencesModal
				isOpen={isIncidencesModalOpen}
				closeModal={closeIncidencesModal}
				incidences={orderInfo?.qualityVerifications?.filter(e => e.partId == incidencePartId)}
				partId={incidencePartId}
				orderId={selectedOrderId}
			/>
			<RegisterIncidenceModal
				isOpen={isRegisterIncidenceModalOpen}
				closeModal={closeRegisterIncidenceModal}
				partId={incidencePartId}
				orderId={selectedOrderId}
				accountId={account?.id}
				quantityList={partQuantityList}
				productions={orderInfo?.order?.productions}
			/>
			<RegisterVerificationModal
				isOpen={isRegisterVerificationModalOpen}
				closeModal={closeRegisterVerificationModal}
				partId={incidencePartId}
				orderId={selectedOrderId}
				quantityList={partQuantityList}
				scannedQuantity={partScannedQuantity}
				shippedQuantity={partShippedQuantity}
				incidences={orderInfo?.qualityVerifications?.filter(e => e.partId == incidencePartId)}
			/>
			<QuantitiesModal
				isOpen={isQuantitiesModalOpen}
				closeModal={closeQuantitiesModal}
				partId={lastScannedPart}
				quantity={partQuantity}
			/>
			<ReadInternalMessageModal
				isOpen={isReadInternalMessageModalOpen}
				closeModal={closeReadInternalMessageModal}
			/>
			<BlueprintSeenModal
				isOpen={isBlueprintSeenModalOpen}
				closeModal={closeBlueprintSeenModal}
			/>
			<UnreceivedPartModal
				isOpen={isUnreceivedPartModalOpen}
				closeModal={closeUnreceivedPartModal}
			/>

			<ModalMessage isModalOpen={isCommentModalOpen} message={savedComment} closeModal={() => { setIsCommentModalOpen(false) }} />
			<ModalMessage isModalOpen={isNotCommentViewedModalOpen} message={t('You_must_read_the_note', { ns: 'naming' })} closeModal={() => { setIsNotCommentViewedModalOpen(false) }} />
		</>
	)
}

const mapStateToProps = state => {
	return {
		selectedOrderId: state.Orders.selectedVerificationOrderId?.orderId,
		boxList: state.Orders.boxList,
		getBoxListStatus: state.Orders.getBoxListStatus,
		account: state.Users.account,
		lastScannedPart: state.Orders.lastScannedPart?.partId,
		partConfigOptions: state.Parts.partConfigOptions,
	}
}

SelectedOrder.propTypes = {
	orderInfo: PropTypes.object,
	selectedOrderId: PropTypes.any,
	boxList: PropTypes.array,
	getBoxList: PropTypes.func,
	getBoxListStatus: PropTypes.shape({
		isLoading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.string,
	}),
	lastScannedPart: PropTypes.number,
	getAccountByEmail: PropTypes.func,
	account: PropTypes.object,
	partConfigOptions: PropTypes.object,
}

export default connect(mapStateToProps, {
	getBoxList,
	getAccountByEmail,
})(withTranslation()(SelectedOrder))
