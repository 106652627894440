export const weekdays = [
	{
		es: 'Domingo',
		en: 'Sunday',
		cn: '星期日',
		it: 'Domenica',
		fr: 'Dimanche',
		de: 'Sonntag',
	},
	{
		es: 'Lunes',
		en: 'Monday',
		cn: '周一',
		it: 'Lunedi',
		fr: 'Lundi',
		de: 'Montag',
	},
	{
		es: 'Martes',
		en: 'Tuesday',
		cn: '周二',
		it: 'Martedì',
		fr: 'Mardi',
		de: 'Dienstag',
	},
	{
		es: 'Miércoles',
		en: 'Wednesday',
		cn: '周三',
		it: 'Mercoledì',
		fr: 'Mercredi',
		de: 'Mittwoch',
	},
	{
		es: 'Jueves',
		en: 'Thursday',
		cn: '周四',
		it: 'Giovedì',
		fr: 'Jeudi',
		de: 'Donnerstag',
	},
	{
		es: 'Viernes',
		en: 'Friday',
		cn: '星期五',
		it: 'Venerdì',
		fr: 'Vendredi',
		de: 'Freitag',
	},
	{
		es: 'Sábado',
		en: 'Saturday',
		cn: '周六',
		it: 'Sabato',
		fr: 'Samedi',
		de: 'Samstag',
	},
]
