import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

class Tabs extends Component {
	constructor(props) {
		super(props)
		this.state = {
			activeTab: props.defaultSelected || 0,
		}
		this.toggle = this.toggle.bind(this)
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			})
			this.props.onToggle && this.props.onToggle(this.props.id, tab)
		}
	}

	render() {
		return (
			<React.Fragment>
				<Nav className={`icon-tab nav-justified nav-tabs-custom ${this.props.className}`}>
					{this.props.sections &&
						this.props.sections.map((section, index) => {
							return (
								<NavItem key={`Tab${this.props.id}${index}`}>
									<NavLink
										style={{ cursor: 'pointer' }}
										className={`
                                        ${classnames({
											active:
												this.state.activeTab === index,
											'bg-transparent':
												this.state.activeTab === index,
											'text-primary':
												this.state.activeTab === index,
										})} ${section.titleClassName}
                                        `}
										onClick={() => {
											this.toggle(index)
										}}
									>
										<div className="width-by-content d-flex">
											<span>{section.title}</span>{' '}
											{section.titleIcons && section.titleIcons}
										</div>
									</NavLink>
								</NavItem>
							)
						})}
				</Nav>
				<TabContent
					activeTab={this.state.activeTab}
					className="text-muted"
				>
					{this.props.sections &&
						this.props.sections.map((section, index) => {
							return (
								<TabPane tabId={index} key={`TabPane${this.props.id}${index}`}>
									{section.content}
								</TabPane>
							)
						})}
				</TabContent>
			</React.Fragment>
		)
	}
}

Tabs.propTypes = {
	id: PropTypes.string,
	defaultSelected: PropTypes.number,
	className: PropTypes.string,
	sections: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.any,
			titleIcons: PropTypes.any,
			titleClassName: PropTypes.string,
			content: PropTypes.any,
		}),
	),
	onToggle: PropTypes.func,
}
export default Tabs
