import avatarClient from 'assets/images/chat/avatarclient.svg'
import avatarSupplier from 'assets/images/chat/avatarsupplier.svg'
import { useHandleCancelableApi } from 'common/Hooks/UseHandleCancelableApi'
import { getUserValuesFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import { getDateWithHour } from 'common/Utils/StringUtilities'
import Loader from 'components/Common/Loader'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getQuoteChat, markQuoteMessagesAsRead, postQuoteChat, uploadQuoteChatImage } from 'store/actions'

export const ChatMessages = ({ t, supplierId, partId, getQuoteChat, chatElements, uploadQuoteChatImage, postQuoteChat, isLoadingChat, markQuoteMessagesAsRead }) => {

	const EMPTY_MESSAGE_TO_INCLUDE_IMAGE = 'emptyMessageToIncludeImage'

	const { handleApiCall } = useHandleCancelableApi(getQuoteChat, { partId, supplierId }, 0)

	const loggedUserId = getUserValuesFromLocalStorage()
	const { id } = loggedUserId

	const [messagesToRender, setMessagesToRender] = useState([])
	const [uploadedFiles, setUploadedFiles] = useState([])
	const [textMessage, setTextMessage] = useState('')

	const textareaRef = useRef(null)
	const messagesRef = useRef(null)

	useEffect(() => {
		if (partId && supplierId) {
			handleApiCall(partId, supplierId)
		}
	}, [partId, supplierId])

	useEffect(() => {
		if (Array.isArray(chatElements)) {
			setMessagesToRender(chatElements)
		}
	}, [chatElements])

	useEffect(() => {
		if (messagesRef.current) {
			messagesRef.current.scrollTop = messagesRef.current.scrollHeight
		}
	}, [messagesToRender])

	useEffect(() => {
		if (Array.isArray(chatElements)) {
			const unreadMessages = chatElements.filter((message) => message.accountId !== id && message.readDate == null)
			if (unreadMessages.length > 0) {
				const messageIds = unreadMessages.map((message) => message.id)
				markQuoteMessagesAsRead({ messageIds, readDate: new Date() })
			}
		}
	}, [chatElements])

	const onDrop = (files) => {
		files.forEach((file) => {
			Object.assign(file, {
				preview: URL.createObjectURL(file),
			})
		})
		setUploadedFiles([...uploadedFiles, ...files])
		textareaRef.current.value = ''
	}

	const { getRootProps, getInputProps, open } = useDropzone({
		onDrop,
		noClick: true,
		noKeyboard: true,
	})

	const fileList = uploadedFiles.map((file) => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	))

	const handleClickSend = () => {
		const newMessageIsText = uploadedFiles.length === 0
		if (newMessageIsText) {
			const newMessage = {
				content: textMessage,
				creationDate: new Date(),
				accountId: id,
				partId,
				supplierId,
			}
			setTextMessage('')
			setMessagesToRender((prevMessages) => [...prevMessages, newMessage])
			postQuoteChat({ quoteChatMessage: newMessage })
		}
		else {
			const newFilesMessages = uploadedFiles.map((file) => ({
				content: EMPTY_MESSAGE_TO_INCLUDE_IMAGE,
				creationDate: new Date(),
				accountId: id,
				image: {
					name: file.name,
					src: file.preview,
				},
			}))
			setMessagesToRender((prevMessages) => [...prevMessages, ...newFilesMessages])
			setUploadedFiles([])
			uploadQuoteChatImage(id, partId, supplierId, uploadedFiles)
		}

	}

	const getRenderedMessages = () => {
		return messagesToRender?.map(({ creationDate, content, accountId, image, account, supplierId }) => {
			const isCurrentUser = accountId === id
			const isMessageUserSupplier = supplierId === accountId
			const displayName = isCurrentUser ? t('you', { ns: 'naming' }) : isMessageUserSupplier ? account?.firstName : `${account?.firstName} ${account?.lastName}`
			return (
				<>
					{image != null ? (
						<section className={`section-${isCurrentUser ? 'client' : 'imas'}`}>
							<text className={`text-${isCurrentUser ? 'client' : 'imas'}`}>
								{displayName}
								<br />
								<a href={image.src} target="_blank" rel="noopener noreferrer">
									<img src={image.src} className='img-chat' />
								</a>
								<br />
								<span style={{ fontWeight: 'normal', marginTop: '20px', color: 'grey' }}>{getDateWithHour(creationDate)}</span>

							</text>
						</section>
					) : (
						<section className={`section-${isCurrentUser ? 'client' : 'imas'}`}>
							<text className={`text-${isCurrentUser ? 'client' : 'imas'}`}>
								{displayName}
								<br />
								<span style={{ fontWeight: 'normal', marginTop: '4px' }}>{content}</span>
								<br />
								<span style={{ fontWeight: 'normal', marginTop: '15px', color: 'grey' }}>{getDateWithHour(creationDate)}</span>

							</text>
							<img src={isCurrentUser ? avatarClient : avatarSupplier} className={`img-${isCurrentUser ? 'client' : 'imas'}`} />
						</section>
					)}
				</>
			)
		})
	}

	const renderedMessagesMemoized = useMemo(() => getRenderedMessages(), [messagesToRender])

	return (
		<div className='sectionChat'>
			<section className="sectionMessages" ref={messagesRef}>
				{
					isLoadingChat ?
						<div className='d-flex align-items-center h-100 fs-4 justify-content-center '><Loader /></div> :
						renderedMessagesMemoized
				}
			</section>
			<section className="d-flex align-items-center align-items-center">
				<textarea
					ref={textareaRef}
					className="input-textarea"
					placeholder={t('writeMessage', { ns: 'naming' })}
					disabled={uploadedFiles.length > 0}
					value={textMessage}
					onChange={(e) => setTextMessage(e.target.value)}
				/>

				<div className="w-20 d-flex align-items-center justify-content-center">
					<div {...getRootProps()}>
						<input {...getInputProps()} accept="image/*" multiple={true} />
						<button
							type="button"
							className="d-flex justify-content-center align-items-center bg-lightgray border-0 rounded me-3 p-2 btimg"
							onClick={open}
							style={{ width: 'fit-content', height: 'fit-content', backgroundColor: 'transparent' }}
						>
							<i className="bx bx-image-add" style={{ fontSize: '30px', marginLeft: '60px', marginRight: '-10px' }} />
						</button>
					</div>
					<button
						className="btn btn-primary btn-send mt-1"
						onClick={() => { handleClickSend() }}
						disabled={uploadedFiles.length == 0 && textMessage == ''}

					>
						{t('send', { ns: 'naming' })}
					</button>
				</div>
			</section>
			<aside>
				<ul>{fileList}</ul>
			</aside>
		</div>
	)
}

ChatMessages.propTypes = {
	t: PropTypes.func,
	getQuoteChat: PropTypes.func,
	supplierId: PropTypes.number,
	partId: PropTypes.number,
	chatElements: PropTypes.array,
	uploadQuoteChatImage: PropTypes.func,
	postQuoteChat: PropTypes.func,
	isLoadingChat: PropTypes.bool,
	markQuoteMessagesAsRead: PropTypes.func,
}

const mapStateToProps = (state) => ({
	chatElements: state.Quotes.quoteChat,
	isLoadingChat: state.Quotes.isLoadingChat,
})

const mapDispatchToProps = { getQuoteChat, uploadQuoteChatImage, postQuoteChat, markQuoteMessagesAsRead }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatMessages))
