import classNames from 'classnames'
import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Exports from 'pages/LogisticsShipments/components/Exports'
import Imports from 'pages/LogisticsShipments/components/Imports'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { Card, CardBody, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'

const TAB = {
	IMPORTS: 'IMPORTS',
	EXPORTS: 'EXPORTS',
}

export const LogisticsShipments = (props) => {

	const {
		t,
		i18n: { language },
	} = props

	const [currentTab, setCurrentTab] = useState(TAB.IMPORTS)

	const renderTop = useMemo(() => (
		<>

			<MetaTags>
				<title>Proto&Go! | {t('shipments')}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('management')}
				breadcrumbItems={[
					{
						item: t('shipments'),
						link: '/logistics-shipments',
					},
				]}
			/>
		</>
	), [language])

	const renderTabButtons = useMemo(() => {
		return (
			<ul className="nav nav-tabs nav-tabs-custom" role="tablist">
				<NavItem>
					<NavLink
						className={classNames({ active: currentTab === TAB.IMPORTS })}
						onClick={() => setCurrentTab(TAB.IMPORTS)}
					>
						{t('imports')}
					</NavLink>
				</NavItem>
				{/* TODO exports */}
				{/* <NavItem>
					<NavLink
						className={classNames({ active: currentTab === TAB.EXPORTS })}
						onClick={() => setCurrentTab(TAB.EXPORTS)}
					>
						{t("exports")}
					</NavLink>
				</NavItem> */}
			</ul>
		)
	}, [currentTab, language])

	const renderContent = useMemo(() => (
		<>
			<TabContent activeTab={currentTab}>
				<TabPane tabId={TAB.IMPORTS}>
					<Imports />
				</TabPane>
			</TabContent>
			<TabContent activeTab={currentTab}>
				<TabPane tabId={TAB.EXPORTS}>
					<Exports />
				</TabPane>
			</TabContent>
		</>
	), [currentTab])

	return (
		<div className="page-content marginFooter">
			{renderTop}
			<Card>
				<CardBody>
					{renderTabButtons}
					{renderContent}
				</CardBody>
			</Card>
		</div>
	)

}

LogisticsShipments.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.object,
}

const mapStateToProps = state => {
	return {}
}

export default withGetPartConfigOptions(connect(mapStateToProps, {
})(withTranslation('naming')(LogisticsShipments)))
