import { getDate, getDateWithHour } from 'common/Utils/StringUtilities'
import { Tracking } from 'pages/SupplierProduction/Tracking'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getSuppliersInfo } from 'store/actions'

const ProductionSupplier = ({ t, production, getSuppliersInfo, suppliers }) => {

	useEffect(() => {
		if (suppliers == null || suppliers.length === 0) {
			getSuppliersInfo()
		}
	}, [suppliers])

	const getShipmentDate = () => {
		if (production?.shipmentDateReal) {
			return getDate(production?.shipmentDateReal) + ' (real)'
		}
		return production?.shipmentDateEstimate && getDate(production?.shipmentDateEstimate) + ' (estimated)'
	}

	const getClassName = () => {
		if (production?.shipmentDateDelay) {
			return 'bg-red text-white px-2 rounded-3 m-0'
		}

		return 'w-auto p-0 m-0'
	}

	const getArrivalDate = () => {
		if (production?.arrivalDate) return `${getDate(production?.arrivalDate)} (${t('real', { ns: 'naming' })})`
		if (production?.arrivalDateEstimated) return `${getDate(production?.arrivalDateEstimated)} (${t('estimated', { ns: 'naming' })})`
	}

	const supplier = suppliers.find(supplier => supplier.account.id === production?.supplierId)

	return (
		<div>
			<p className="fs-3 mb-2 border-bottom border-dark-subtle"><strong>{t('supplier', { ns: 'naming' })}</strong> </p>
			<p><strong className="me-1">{t('name', { ns: 'naming' })}:</strong> {supplier?.account?.personalInformation?.firstName} </p>
			<p><strong className="me-1">{t('production_date', { ns: 'naming' })}:</strong> {production?.orderDate && getDateWithHour(production?.orderDate)}</p>
			<p><strong className="me-1">{t('production_days', { ns: 'naming' })}:</strong>{production?.productionDays}</p>
			<p><strong className="me-1 ">{t('shipment_date', { ns: 'naming' })}:</strong>{getShipmentDate()}</p>
			<p className="d-flex flex-row"><strong className="me-1">{t('delay_date', { ns: 'naming' })}:</strong><div className={getClassName()}> {production?.shipmentDateDelay && getDate(production?.shipmentDateDelay)}</div></p>
			<p><strong className="me-1">{t('arrival_date', { ns: 'naming' })}:</strong>{suppliers && supplier && getArrivalDate()}</p>
			{production.shipmentTrackingNumber && <p className="d-flex flex-row"><strong className="me-1 pt-2">{t('tracking', { ns: 'naming' })}:</strong><Tracking trackingId={production.shipmentTrackingNumber} courier={production.courier} estimatedArrivalDate={production?.shipmentDateEstimate} status={production.status} /></p>}
			{production?.denyDetails &&
				<p><strong className="me-1">{t('denyDetails', { ns: 'naming' })}:</strong>{production?.denyDetails}</p>
			}
			{/* <ChatIcon quantityUnread={2} production={production?.production} /> --> TODO ProductionChat no funciona todavía */}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		productionDetail: state.Users.supplier,
		isLoading: state.Productions.isLoading,
		orderDetail: state.Orders.orderDetail,
		error: state.error,
		suppliers: state.Users.supplierDetailList,
	}
}

ProductionSupplier.propTypes = {
	t: PropTypes.func,
	production: PropTypes.object,
	suppliers: PropTypes.object,
	getSuppliersInfo: PropTypes.func,
}
export default connect(mapStateToProps, { getSuppliersInfo })(withTranslation()(ProductionSupplier))
