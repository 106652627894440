export const OrderStatus = {
	PAYMENT_PENDING: 10,
	PREPARING: 20,
	PAYMENT_ERROR: 30,
	IN_PRODUCTION: 40,
	IN_QUALITY: 50,
	SENT: 60,
	DELIVERED: 70,
	CANCELLED: 80,
	REFUNDED: 90,
}
