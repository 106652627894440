import { footerString, staticProtoandgoWebsite } from 'constants/protoAndGoInfo'
import PropTypes from 'prop-types'
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

/**
 * @param width Width page size  
 * @param height Height page size
 * @param orientation Orientation. "l" = landscape. "p" = portrait
 * @param isHidingContent use it to hide content on webBrowser
 * @returns 
 */
export const usePdfGenerator = (props) => {

	const {
		contentList,
		width = 210,
		height = 297,
		orientation = 'p',
		printPageCounter = false,
		printDocumentFooter = true,
		isHidingContent = true,
	} = props

	const [loading, setLoading] = useState(false)

	const getPdfFile = async (filename) => {
		const setPageNumberOnDocument = (pdf) => {
			const x = pdf.internal.pageSize.getWidth() - 10
			const y = pdf.internal.pageSize.getHeight() - 8
			const totalPages = pdf.internal.getNumberOfPages()
			for (let i = 1; i <= totalPages; i++) {
				const str = `${i} / ${totalPages}`
				const options = {
					align: 'right',
				}
				pdf.setPage(i)
				pdf.setFontSize(8).setFont('helvetica')
				pdf.text(str, x, y, options)
			}
		}
		const setDocumentFooter = (str, website, pdf) => {
			const setFooterLabel = (str, pdf) => {
				const yPosition = pdf.internal.pageSize.getHeight() - 20
				const totalPages = pdf.internal.getNumberOfPages()
				const x = pdf.internal.pageSize.getWidth() / 2
				const y = yPosition
				const options = {
					align: 'center',
					maxWidth: 130,
				}
				pdf.setPage(totalPages)
				pdf.setFontSize(8).setFont('helvetica')
				pdf.text(str, x, y, options)
			}
			const setFooterWebsite = (str, pdf) => {
				const yPosition = pdf.internal.pageSize.getHeight() - 20
				const totalPages = pdf.internal.getNumberOfPages()
				const x = pdf.internal.pageSize.getWidth() / 2
				const y = yPosition + 10
				const options = {
					align: 'center',
				}
				pdf.setPage(totalPages)
				pdf.setFontSize(12).setFont('helvetica', 'bold')
				pdf.text(str, x, y, options)
			}
			setFooterLabel(str, pdf)
			setFooterWebsite(website, pdf)
		}
		const element = document.getElementById(filename)
		if (!element) return

		const pages = element.getElementsByClassName(`page-${filename}`)
		const html3pdf = require('html3pdf')
		const options = {
			jsPDF: {
				format: [width, height],
				orientation,
				hotfixes: ['px_scaling'],
			},
			margin: 10,
			html2canvas: { scale: 4 },
			filename: filename,
		}
		const blob = await html3pdf().from('').set(options).toPdf().get('pdf').then(async (pdf) => {
			for (const page of pages) {
				pdf.addPage()
				pdf.addImage(
					await html3pdf().from(page).set(options).toImg().get('img').then((img) => img),
					'JPEG',
					0,
					2,
					width,
					height - 4,
				)
			}
			if (pdf.getNumberOfPages() > 1) pdf.deletePage(1)
			if (printPageCounter)
				setPageNumberOnDocument(pdf)
			if (printDocumentFooter)
				setDocumentFooter(footerString, staticProtoandgoWebsite, pdf)
			setLoading(false)
		}).output('blob')
		return blob
	}

	const elements = useMemo(() => (
		<>
			{contentList.map(({ content, filename }, i) => (
				<div
					key={filename}
					style={(isHidingContent || process.env.NODE_ENV == 'production') ? {
						visibility: 'hidden',
						position: 'absolute',
						overflow: 'hidden',
						left: '-9999px',
						top: '-9999px',
					} : {}}
				>
					<div id={filename}>
						<SeparateElementsIntoPages filename={filename}>
							{content}
						</SeparateElementsIntoPages>
					</div>
				</div>
			))}
		</>
	), [contentList])

	return {
		loading: loading,
		getPdfFiles: async () => {
			const results = contentList.map(async ({ filename }) => ({
				filename: filename,
				file: await getPdfFile(filename),
			}))
			return Promise.all(results)
		},
		elements: elements,
	}
}

const Page = ({ children, filename }) => {
	const pageStyle = {
		color: '#000000',
		fontSize: '12px',
		width: 'calc(4em + 87%)',
		height: 'calc(2em + 100%)',
		marginTop: '2em',
		marginRight: '2em',
		marginBottom: '2em',
		marginLeft: '2em',
	}
	return (
		<div className={`page page-${filename}`} style={pageStyle}>
			{children}
		</div>
	)
}
Page.propTypes = {
	children: PropTypes.any,
	filename: PropTypes.string,
}

const SeparateElementsIntoPages = ({ children, filename }) => {

	const PAGE_HEIGHT = 980
	const PAGE_HEIGHT_MINUS_FOOTER = 890

	const refs = useRef([])
	const [heights, setHeights] = useState([])
	const [pages, setPages] = useState([])

	useLayoutEffect(() => {
		setHeights(refs.current.map(ref => ref?.offsetHeight || 0))
	}, [children])

	const childrenWithRefs = children ? React.Children.map(children, (child, index) => {
		return React.cloneElement(child, {
			ref: (el) => refs.current[index] = el,
		})
	}) : null

	useEffect(() => {
		let heightUsed = 0
		let currentPageElements = []
		const allPages = []
		let firstElementOfLastPageIndex = 0

		// iterate through children, keeping track of the total height being used.
		// when the total height exceeds the page max height, push 1 page and put that child in the next page.
		// (this is all ignoring the footer)
		React.Children.forEach(children, (child, i) => {
			const childHeight = heights[i]
			if (heightUsed + childHeight <= PAGE_HEIGHT) {
				currentPageElements.push(child)
				heightUsed += childHeight
			} else {
				allPages.push(currentPageElements)
				currentPageElements = [child]
				heightUsed = childHeight
				firstElementOfLastPageIndex = i
			}
		})

		// after this is done, discard the last page (currentPageElements = []) because it will be calculated differently because of the footer.
		heightUsed = 0
		currentPageElements = []

		// iterate through children, starting with the first one of the page that was discarded (skip all until firstElementOfLastPageIndex)
		// when the total height exceeds the page max height (taking the footer's height into account), push 1 page and put that child in the next page.
		React.Children.forEach(children, (child, i) => {
			if (i < firstElementOfLastPageIndex) return
			const childHeight = heights[i]
			if (heightUsed + childHeight <= PAGE_HEIGHT_MINUS_FOOTER) {
				currentPageElements.push(child)
				heightUsed += childHeight
			} else {
				allPages.push(currentPageElements)
				currentPageElements = [child]
				heightUsed = childHeight
			}
		})
		if (currentPageElements.length) {
			allPages.push(currentPageElements)
		}
		setPages(allPages)
	}, [children, PAGE_HEIGHT_MINUS_FOOTER, heights])

	return (
		<div>
			{pages.map((pageElements, index) => (
				<Page
					key={index}
					filename={filename}
				>
					{pageElements}
				</Page>
			))}
			{childrenWithRefs}
		</div>
	)
}
SeparateElementsIntoPages.propTypes = {
	children: PropTypes.any,
	filename: PropTypes.string,
}
