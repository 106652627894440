import { PAYMENT_METHOD } from '../../constants/paymentMethods'
import * as postponed_payment_type from '../../locales/es/postponed_payment_type.json'

export const getPostponedPaymentMethods = () => {
	const objectPaymentMethods = JSON.parse(JSON.stringify(postponed_payment_type))
	return Object.entries(objectPaymentMethods.default).map((el, index) => {
		return {
			label: el[0],
			value: index + 1,
		}
	})
}

export const getPostponedPaymentMethodsTranslated = (t) => {
	const paymentMethods = getPostponedPaymentMethods()
	return paymentMethods.map(paymentMethod => {
		return {
			...paymentMethod,
			label: t(paymentMethod.label, { ns: 'postponedPaymentType' }),
		}
	})
}

export const findPostponedPaymentMethod = (t, paymentMethod) => {
	if (paymentMethod == null) return null
	const paymentMethods = getPostponedPaymentMethods()
	const paymentMethodFound = paymentMethods.find((currentPaymentMethod) => currentPaymentMethod.value === paymentMethod)
	return {
		...paymentMethodFound,
		label: t(paymentMethodFound?.label, { ns: 'postponedPaymentType' }),
	}
}

export const getPostponedPaymentMethodsKeyValuePair = (t) => {
	return Object.entries(PAYMENT_METHOD).map((paymentMethod) => {
		return {
			label: t(`payment_method_${paymentMethod[1]}_name`, { ns: 'paymentMethods' }),
			value: paymentMethod[1],
		}
	})
} 
