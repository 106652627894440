import { call, put, takeEvery } from 'redux-saga/effects'

// Crypto Redux States
import {
	CREATE_NEW_HOLIDAY,
	CREATE_NEW_MANAGER,
	CREATE_NEW_SHIPPING_ADDRESS,
	CREATE_NEW_SUPPLIER,
	CREATE_NOTIFICATION,
	DELETE_HOLIDAY_BY_ID,
	GET_ACCOUNT_BY_EMAIL,
	GET_COUNTRIES_DASHBOARD,
	GET_CRM_INTERACTIONS_LIST,
	GET_CUSTOMERS,
	GET_CUSTOMERS_ORDERS_OFFERS_BY_MANAGER_ID,
	GET_DOMAIN_USERS,
	GET_FILTERED_CUSTOMERS_LIST,
	GET_HOLIDAYS,
	GET_MANAGERS,
	GET_MANAGERS_STATISTICS_BY_IDS,
	GET_MANAGER_DETAIL_BY_ID,
	GET_MANAGER_LIST,
	GET_NOTIFICATIONS_BY_ACCOUNT_ID,
	GET_ORGANIZATION_BY_ID,
	GET_SUPPLIERS,
	GET_SUPPLIERS_BY_ORDER_ID,
	GET_SUPPLIERS_INFO,
	GET_SUPPLIER_DETAIL,
	GET_SUPPLIER_INFO,
	GET_USERS_DASHBOARD,
	REMOVE_MANAGER_COMMISSION_BY_ID,
	UPDATE_ACTIVE_SUPPLIER,
	UPDATE_HOLIDAY_BY_ID,
	UPDATE_MANAGER_BY_ID,
	UPDATE_NOTIFICATIONS,
	UPDATE_PAYMENT_POSTPONEMENT,
	UPDATE_SHIPPING_ADDRESS,
	UPDATE_SUPPLIER_DETAIL,
} from './actionTypes'
import {
	createHolidayFail,
	createHolidaySuccess,
	createNewManagerFail,
	createNewManagerSuccess,
	createNewShippingAddressFail,
	createNewShippingAddressSuccess,
	createNotificationFail,
	createNotificationSuccess,
	createSupplierFail,
	createSupplierSuccess,
	deleteHolidayByIdFail,
	deleteHolidayByIdSuccess,
	getAccountByEmailFail,
	getAccountByEmailSuccess,
	getCountriesDashboardFail,
	getCountriesDashboardSuccess,
	getCrmInteractionsListFail,
	getCrmInteractionsListSuccess,
	getCustomersFail,
	getCustomersOrdersOffersByManagerIdFail,
	getCustomersOrdersOffersByManagerIdSuccess,
	getCustomersSuccess,
	getDomainUsersFail,
	getDomainUsersSuccess,
	getFilteredCustomersListFail,
	getFilteredCustomersListSuccess,
	getHolidaysFail,
	getHolidaysSuccess,
	getManagerDetailFail,
	getManagerDetailSuccess,
	getManagerListFail,
	getManagerListSuccess,
	getManagersFail,
	getManagersStatisticsByIdsFail,
	getManagersStatisticsByIdsSuccess,
	getManagersSuccess,
	getNotificationsByAccountIdFail,
	getNotificationsByAccountIdSuccess,
	getOrganizationByIdFail,
	getOrganizationByIdSuccess,
	getSupplierDetailFail,
	getSupplierDetailSuccess,
	getSupplierInfoFail,
	getSupplierInfoSuccess,
	getSuppliersByOrderIdFail,
	getSuppliersByOrderIdSuccess,
	getSuppliersFail,
	getSuppliersInfoFail,
	getSuppliersInfoSuccess,
	getSuppliersSuccess,
	getUsersDashboardFail,
	getUsersDashboardSuccess,
	removeManagerCommissionByIdFail,
	removeManagerCommissionByIdSuccess,
	updateActiveSupplierFail,
	updateActiveSupplierSuccess,
	updateHolidayByIdFail,
	updateHolidayByIdSuccess,
	updateManagerByIdFail,
	updateManagerByIdSuccess,
	updateNotificationsFail,
	updateNotificationsSuccess,
	updatePaymentPostponementFail,
	updatePaymentPostponementSuccess,
	updateShippingAddressFail,
	updateShippingAddressSuccess,
	updateSupplierFail,
	updateSupplierSuccess,
} from './actions'

import {
	CREATE_NEW_HOLIDAY_ENDPOINT,
	CREATE_NEW_MANAGER_ENDPOINT,
	CREATE_NEW_SHIPPING_ADDRESS_ENDPOINT,
	CREATE_NEW_SUPPLIER_ENDPOINT,
	CREATE_NOTIFICATION_ENDPOINT,
	DELETE_HOLIDAY_BY_ID_ENDPOINT,
	GET_ACCOUNT_BY_EMAIL_ENDPOINT,
	GET_COUNTRIES_DASHBOARD_ENDPOINT,
	GET_CRM_INTERACTIONS_LIST_ENDPOINT,
	GET_CUSTOMERS_ENDPOINT,
	GET_CUSTOMERS_ORDERS_OFFERS_BY_MANAGER_ID_ENDPOINT,
	GET_DOMAIN_USERS_ENDPOINT,
	GET_FILTERED_CUSTOMERS_LIST_ENDPOINT,
	GET_HOLIDAYS_ENDPOINT,
	GET_MANAGERS_ENDPOINT,
	GET_MANAGERS_STATISTICS_BY_IDS_ENDPOINT,
	GET_MANAGER_DETAIL_BY_ID_ENDPOINT,
	GET_MANAGER_LIST_ENDPOINT,
	GET_NOTIFICATIONS_BY_ACCOUNT_ID_ENDPOINT,
	GET_ORGANIZATION_BY_ID_ENDPOINT,
	GET_SUPPLIERS_BY_ORDER_ID_ENDPOINT,
	GET_SUPPLIERS_ENDPOINT,
	GET_SUPPLIERS_INFO_ENDPOINT,
	GET_SUPPLIER_DETAIL_ENDPOINT,
	GET_SUPPLIER_INFO_ENDPOINT,
	GET_USERS_DASHBOARD_ENDPOINT,
	REMOVE_MANAGER_COMMISSION_BY_ID_ENDPOINT,
	UPDATE_HOLIDAY_BY_ID_ENDPOINT,
	UPDATE_MANAGER_BY_ID_ENDPOINT,
	UPDATE_NOTIFICATIONS_ENDPOINT,
	UPDATE_PAYMENT_POSTPONEMENT_ENDPOINT,
	UPDATE_SHIPPING_ADDRESS_ENDPOINT,
	UPDATE_SUPPLIER_DETAIL_ENDPOINT,
	UPDATE_USER_DATA_ENDPOINT,
} from '../../constants/backendRoutes'
import ApiHelper, { get, post } from '../../helpers/api_helper'


function* getFilteredCustomersList({ payload: { data } }) {
	try {
		const url = GET_FILTERED_CUSTOMERS_LIST_ENDPOINT
		const response = yield call(post, url, data)
		yield put(getFilteredCustomersListSuccess(response))
	} catch (error) {
		yield put(getFilteredCustomersListFail(error))
	}
}


function* createNotification({ payload: notification }) {
	const url = CREATE_NOTIFICATION_ENDPOINT
	try {
		const response = yield call(post, url, notification)
		yield put(createNotificationSuccess(response))
	} catch (error) {
		yield put(createNotificationFail(error))
	}
}


function* getCustomersOrdersOffersByManagerId({ payload: { managerId } }) {
	try {
		const url = `${GET_CUSTOMERS_ORDERS_OFFERS_BY_MANAGER_ID_ENDPOINT}`
		const input = { data: { managerId } }
		const response = yield call(post, url, input)
		yield put(getCustomersOrdersOffersByManagerIdSuccess(response))
	} catch (error) {
		yield put(getCustomersOrdersOffersByManagerIdFail(error))
	}
}

function* getCrmInteractionsList({ payload: { data, signal } }) {
	try {
		const url = GET_CRM_INTERACTIONS_LIST_ENDPOINT
		const response = yield call(post, url, data, { signal })
		yield put(getCrmInteractionsListSuccess(response))
	} catch (error) {
		yield put(getCrmInteractionsListFail(error))
	}
}

function* updateNotifications({ payload: { notifications } }) {
	try {
		const url = UPDATE_NOTIFICATIONS_ENDPOINT
		const input = { data: { notifications } }
		const response = yield call(post, url, input)
		yield put(updateNotificationsSuccess(response))
	} catch (error) {
		yield put(updateNotificationsFail(error))
	}

}

function* getNotificationsByAccountId({ payload: { accountId } }) {
	try {
		const url = `${GET_NOTIFICATIONS_BY_ACCOUNT_ID_ENDPOINT}/${accountId}`
		const response = yield call(get, url)
		yield put(getNotificationsByAccountIdSuccess(response))
	} catch (error) {
		yield put(getNotificationsByAccountIdFail(error))
	}
}

function* getManagersStatisticsByIds({ payload: { managersIds } }) {
	try {
		const url = `${GET_MANAGERS_STATISTICS_BY_IDS_ENDPOINT}`
		const input = { data: { managersIds } }
		const response = yield call(post, url, input)
		yield put(getManagersStatisticsByIdsSuccess(response))
	} catch (error) {
		yield put(getManagersStatisticsByIdsFail(error))
	}
}

function* getHolidays() {
	try {
		const url = GET_HOLIDAYS_ENDPOINT
		const response = yield call(get, url)
		yield put(getHolidaysSuccess(response))
	} catch (error) {
		yield put(getHolidaysFail(error))
	}

}

function* createNewHoliday({ payload: holiday }) {
	const url = CREATE_NEW_HOLIDAY_ENDPOINT
	try {
		const response = yield call(post, url, holiday)
		yield put(createHolidaySuccess(response))
	} catch (error) {
		yield put(createHolidayFail(error))
	}
}

function* updateHolidayById({ payload: holiday }) {
	const url = UPDATE_HOLIDAY_BY_ID_ENDPOINT
	try {
		const response = yield call(ApiHelper.put, url, holiday)
		yield put(updateHolidayByIdSuccess(response))
	} catch (error) {
		yield put(updateHolidayByIdFail(error))
	}

}

function* removeManagerCommissionById({ payload: commisionId }) {
	const url = REMOVE_MANAGER_COMMISSION_BY_ID_ENDPOINT + '/' + commisionId
	try {
		const response = yield call(ApiHelper.del, url)
		yield put(removeManagerCommissionByIdSuccess(response))
	} catch (error) {
		yield put(removeManagerCommissionByIdFail(error))
	}
}

function* deleteHolidayById({ payload: holidayId }) {
	const url = DELETE_HOLIDAY_BY_ID_ENDPOINT + '/' + holidayId
	try {
		const response = yield call(ApiHelper.del, url)
		yield put(deleteHolidayByIdSuccess(response))
	} catch (error) {
		yield put(deleteHolidayByIdFail(error))
	}

}

function* updateManagerById({ payload: user }) {
	const url = UPDATE_MANAGER_BY_ID_ENDPOINT

	try {
		const response = yield call(ApiHelper.put, url, user)

		yield put(updateManagerByIdSuccess(response))
		return response
	} catch (error) {
		yield put(updateManagerByIdFail(error))
	}
}

function* getManagers() {
	try {
		const url = GET_MANAGERS_ENDPOINT
		const response = yield call(get, url)
		yield put(getManagersSuccess(response))
	} catch (error) {
		yield put(getManagersFail(error))
	}
}

function* getSuppliers() {
	try {
		const url = GET_SUPPLIERS_ENDPOINT
		const response = yield call(get, url)
		yield put(getSuppliersSuccess(response))
	} catch (error) {
		yield put(getSuppliersFail(error))
	}
}
function* getSuppliersByOrderId({ payload: { orderId } }) {
	try {
		const url = GET_SUPPLIERS_BY_ORDER_ID_ENDPOINT + '/' + orderId
		const response = yield call(get, url)
		yield put(getSuppliersByOrderIdSuccess(response))
	} catch (error) {
		yield put(getSuppliersByOrderIdFail(error))
	}
}

function* createNewManager({ payload: user }) {
	const url = CREATE_NEW_MANAGER_ENDPOINT
	try {
		const response = yield call(post, url, { data: { account: user } })
		yield put(createNewManagerSuccess(response))
		return response
	} catch (error) {
		yield put(createNewManagerFail(error))
	}
}

function* createNewSupplier({ payload: user }) {
	const url = CREATE_NEW_SUPPLIER_ENDPOINT
	try {
		const response = yield call(post, url, user)
		yield put(createSupplierSuccess(response))
		return response
	} catch (error) {
		yield put(createSupplierFail(error))
	}
}
function* getSuppliersInfo() {
	try {
		const url = GET_SUPPLIERS_INFO_ENDPOINT
		const response = yield call(get, url)
		yield put(getSuppliersInfoSuccess(response))
	} catch (error) {
		yield put(getSuppliersInfoFail(error))
	}
}

function* getSupplierDetail({ payload: { id } }) {
	try {
		const url = `${GET_SUPPLIER_DETAIL_ENDPOINT}/${id}`
		const response = yield call(get, url)
		yield put(getSupplierDetailSuccess(response))
	} catch (error) {
		yield put(getSupplierDetailFail(error))
	}
}

function* updateSupplierDetail({ payload: user }) {
	const url = UPDATE_SUPPLIER_DETAIL_ENDPOINT
	try {
		const response = yield call(post, url, user)
		yield put(updateSupplierSuccess(response))
		return response
	} catch (error) {
		yield put(updateSupplierFail(error))
	}
}

function* getAccountByEmail({ payload: { email } }) {
	try {
		const url = `${GET_ACCOUNT_BY_EMAIL_ENDPOINT}/${email}`
		const response = yield call(get, url)
		yield put(getAccountByEmailSuccess(response))
	} catch (error) {
		yield put(getAccountByEmailFail(error))
	}
}

function* getSupplierInfo({ payload: supplierId }) {
	const url = GET_SUPPLIER_INFO_ENDPOINT + '/' + supplierId
	try {
		const response = yield call(get, url)
		yield put(getSupplierInfoSuccess(response))
	} catch (error) {
		yield put(getSupplierInfoFail(error))
	}
}
function* getCustomers() {
	const url = GET_CUSTOMERS_ENDPOINT
	try {
		const response = yield call(get, url)
		yield put(getCustomersSuccess(response))
	} catch (error) {
		yield put(getCustomersFail(error))
	}
}

function* createNewShippingAddress({ payload: shippingAddres }) {
	const url = CREATE_NEW_SHIPPING_ADDRESS_ENDPOINT
	try {
		const response = yield call(post, url, shippingAddres)
		yield put(createNewShippingAddressSuccess(response))
	} catch (error) {
		yield put(createNewShippingAddressFail(error))
	}
}

function* updateShippingAddress({ payload: shippingAddres }) {
	const url = UPDATE_SHIPPING_ADDRESS_ENDPOINT
	try {
		const response = yield call(post, url, shippingAddres)
		yield put(updateShippingAddressSuccess(response))
	} catch (error) {
		yield put(updateShippingAddressFail(error))
	}
}

function* updatePaymentPostponement({ payload: data }) {
	const url = UPDATE_PAYMENT_POSTPONEMENT_ENDPOINT
	try {
		const response = yield call(post, url, data)
		yield put(updatePaymentPostponementSuccess(response))
	} catch (error) {
		yield put(updatePaymentPostponementFail(error))
	}
}

function* getDomainUsers({ payload: email }) {
	const url = GET_DOMAIN_USERS_ENDPOINT + '/' + email
	try {
		const response = yield call(get, url)
		yield put(getDomainUsersSuccess(response))
	} catch (error) {
		yield put(getDomainUsersFail(error))
	}
}

function* updateActiveSupplier({ payload: data }) {
	const url = UPDATE_USER_DATA_ENDPOINT
	try {
		const response = yield call(post, url, data)
		yield put(updateActiveSupplierSuccess(response))
	} catch (error) {
		yield put(updateActiveSupplierFail(error))
	}
}

function* getManagerList() {
	const url = GET_MANAGER_LIST_ENDPOINT
	try {
		const response = yield call(get, url)
		yield put(getManagerListSuccess(response))
	} catch (error) {
		yield put(getManagerListFail(error))
	}
}

function* getManagerDetailById({ payload: { id } }) {
	try {
		const url = `${GET_MANAGER_DETAIL_BY_ID_ENDPOINT}/${id}`
		const response = yield call(get, url)

		yield put(getManagerDetailSuccess(response))
	} catch (error) {
		yield put(getManagerDetailFail(error))
	}
}

function* getOrganizationById({ payload: { id } }) {
	try {
		const url = `${GET_ORGANIZATION_BY_ID_ENDPOINT}/${id}`
		const response = yield call(get, url)
		yield put(getOrganizationByIdSuccess(response))
	} catch (error) {
		yield put(getOrganizationByIdFail(error))
	}
}

function* getUsersDashboard({ payload: data }) {
	try {
		const url = `${GET_USERS_DASHBOARD_ENDPOINT}`
		const response = yield call(post, url, data)
		yield put(getUsersDashboardSuccess(response))
	} catch (error) {
		yield put(getUsersDashboardFail(error))
	}
}

function* getCountriesDashboard({ payload: data }) {
	try {
		const url = `${GET_COUNTRIES_DASHBOARD_ENDPOINT}`
		const response = yield call(post, url, data)
		yield put(getCountriesDashboardSuccess(response))
	} catch (error) {
		yield put(getCountriesDashboardFail(error))
	}
}

function* usersSaga() {
	yield takeEvery(GET_MANAGERS, getManagers)
	yield takeEvery(GET_SUPPLIERS, getSuppliers)
	yield takeEvery(GET_SUPPLIERS_BY_ORDER_ID, getSuppliersByOrderId)
	yield takeEvery(CREATE_NEW_SUPPLIER, createNewSupplier)
	yield takeEvery(GET_SUPPLIERS_INFO, getSuppliersInfo)
	yield takeEvery(GET_SUPPLIER_DETAIL, getSupplierDetail)
	yield takeEvery(UPDATE_SUPPLIER_DETAIL, updateSupplierDetail)
	yield takeEvery(GET_ACCOUNT_BY_EMAIL, getAccountByEmail)
	yield takeEvery(GET_SUPPLIER_INFO, getSupplierInfo)
	yield takeEvery(GET_CUSTOMERS, getCustomers)
	yield takeEvery(CREATE_NEW_SHIPPING_ADDRESS, createNewShippingAddress)
	yield takeEvery(UPDATE_SHIPPING_ADDRESS, updateShippingAddress)
	yield takeEvery(UPDATE_PAYMENT_POSTPONEMENT, updatePaymentPostponement)
	yield takeEvery(GET_DOMAIN_USERS, getDomainUsers)
	yield takeEvery(UPDATE_ACTIVE_SUPPLIER, updateActiveSupplier)
	yield takeEvery(GET_MANAGER_LIST, getManagerList)
	yield takeEvery(GET_MANAGER_DETAIL_BY_ID, getManagerDetailById)
	yield takeEvery(UPDATE_MANAGER_BY_ID, updateManagerById)
	yield takeEvery(CREATE_NEW_MANAGER, createNewManager)
	yield takeEvery(GET_ORGANIZATION_BY_ID, getOrganizationById)
	yield takeEvery(GET_HOLIDAYS, getHolidays)
	yield takeEvery(UPDATE_HOLIDAY_BY_ID, updateHolidayById)
	yield takeEvery(DELETE_HOLIDAY_BY_ID, deleteHolidayById)
	yield takeEvery(CREATE_NEW_HOLIDAY, createNewHoliday)
	yield takeEvery(GET_USERS_DASHBOARD, getUsersDashboard)
	yield takeEvery(REMOVE_MANAGER_COMMISSION_BY_ID, removeManagerCommissionById)
	yield takeEvery(GET_COUNTRIES_DASHBOARD, getCountriesDashboard)
	yield takeEvery(GET_MANAGERS_STATISTICS_BY_IDS, getManagersStatisticsByIds)
	yield takeEvery(GET_NOTIFICATIONS_BY_ACCOUNT_ID, getNotificationsByAccountId)
	yield takeEvery(UPDATE_NOTIFICATIONS, updateNotifications)
	yield takeEvery(GET_CRM_INTERACTIONS_LIST, getCrmInteractionsList)
	yield takeEvery(GET_CUSTOMERS_ORDERS_OFFERS_BY_MANAGER_ID, getCustomersOrdersOffersByManagerId)
	yield takeEvery(CREATE_NOTIFICATION, createNotification)
	yield takeEvery(GET_FILTERED_CUSTOMERS_LIST, getFilteredCustomersList)
}

export default usersSaga
