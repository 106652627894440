import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { adaptDateOnLocale } from 'common/Utils/StringUtilities'
import Breadcrumbs from 'components/Common/Breadcrumb'
import Table from 'components/Table/Table'
import i18n from 'i18n'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { createVoucher, getVoucherList, updateVoucherByCode } from 'store/actions'
import DiscountVoucherModal from './DiscountVoucherModal'
import VoucherErrorModal from './VoucherErrorModal'
import { AMOUNT, FREE_SHIPPING, GIFT, NO_MINIMUM_PRICE, PERCENTAGE, voucherTypes } from './VoucherTypes'

const DiscountVoucher = ({ t, voucherList, getVoucherList, voucher, voucherError, createVoucher, updateVoucherByCode }) => {

	const [isVoucherModalOpen, setVoucherModalOpen] = useState(false)
	const [itemsToRender, setItemsToRender] = useState([])
	const [discountVoucherToUpdate, setDiscountVoucherToUpdate] = useState({})
	const [isVoucherErrorModalOpen, setVoucherErrorModalOpen] = useState(false)

	useEffect(() => {
		getVoucherList()
	}, [])

	useEffect(() => {
		if (voucher) {
			getVoucherList()
		}
	}, [voucher])

	useEffect(() => {
		if (voucherError) {
			setVoucherErrorModalOpen(true)
		}
	}, [voucherError])

	useEffect(() => {
		if (voucherList && voucherList.length != 0) {
			setItemsToRender(voucherList.sort((a, b) => {
				return new Date(b.creationDate) - new Date(a.creationDate)
			}).map(element => parseVoucherData(element)))
		}
	}, [voucherList, i18n.language])

	const parseVoucherData = voucher => {
		return {
			id: voucher.id,
			code: { value: voucher.code },
			voucherType: getVoucherType(voucher.voucherTypeId),
			voucherValue: getVoucherValue(voucher.voucherValue, voucher.voucherTypeId),
			description: voucher.description,
			maxUsages: voucher.maxUsages,
			creationDate: getVoucherDate(voucher.creationDate),
			exprationDate: getVoucherDate(voucher.expirationDate),
			searchableCode: voucher.code
		}
	}

	const getVoucherType = (voucherTypeId) => {
		switch (voucherTypeId) {
			case voucherTypes.PERCENTAGE:
				return t(PERCENTAGE, { ns: 'naming' })
			case voucherTypes.AMOUNT:
				return t(AMOUNT, { ns: 'naming' })
			case voucherTypes.FREE_SHIPPING:
				return t(FREE_SHIPPING, { ns: 'naming' })
			case voucherTypes.NO_MINIMUM_PRICE:
				return t(NO_MINIMUM_PRICE, { ns: 'naming' })
			case voucherTypes.GIFT:
				return t(GIFT, { ns: 'naming' })
			default:
				return ''
		}
	}

	const getVoucherValue = (voucherValue, voucherType) => {
		const str = voucherValue
		switch (voucherType) {
			case voucherTypes.PERCENTAGE:
				return str + '%'
			case voucherTypes.AMOUNT:
				return str + '€'
			default:
				return 'N/A'
		}
	}

	const headerToRender = {
		code: t('code', { ns: 'naming' }),
		voucherType: t('voucherType', { ns: 'naming' }),
		voucherValue: t('voucherValue', { ns: 'naming' }),
		description: t('description', { ns: 'naming' }),
		maxUsages: t('maxUsages', { ns: 'naming' }),
		creationDate: t('creationDate', { ns: 'naming' }),
		exprationDate: t('expirationDate', { ns: 'naming' }),
		searchableCode: "searchableCode"
	}

	const openModalToCreate = () => {
		setDiscountVoucherToUpdate(null)
		setVoucherModalOpen(true)
	}
	const openModalWithDiscountVoucher = id => {
		const discountVoucher = voucherList.find(element => element.id == id)
		setDiscountVoucherToUpdate(discountVoucher)
		setVoucherModalOpen(true)
	}

	return (
		<div className="page-content">
			<div>
				<MetaTags>
					<title>Proto&Go! | {t('discountVoucher', { ns: 'naming' })}</title>
				</MetaTags>
				<Breadcrumbs
					title={t('configuration', { ns: 'naming' })}
					breadcrumbItems={[
						{
							item: t('discountVoucher', { ns: 'naming' }),
							link: '/discount-voucher',
						},
					]}
				/>
			</div>
			<div className="bg-white my-4 p-4">
				<div className="d-flex">
					<h2>{t('codes', { ns: 'naming' })}</h2>
					<button
						type="button"
						className="btn btn-primary btn-sm mx-4"
						onClick={e => openModalToCreate()}
					>
						{t('createVoucher', { ns: 'naming' })}
					</button>
				</div>
				<DiscountVoucherModal
					isOpenModal={isVoucherModalOpen}
					setIsOpenModal={setVoucherModalOpen}
					discountVoucherToUpdate={discountVoucherToUpdate}
					createVoucher={createVoucher}
					updateVoucher={updateVoucherByCode}
				/>
				<VoucherErrorModal
					isErrorModalOpen={isVoucherErrorModalOpen}
					setErrorModalOpen={setVoucherErrorModalOpen}
					error={voucherError?.response?.data?.message}
				/>
				<div className="py-2">
					{itemsToRender && (
						<Table
							hiddenFields={['searchableCode']}
							header={headerToRender}
							items={itemsToRender}
							selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
							language={getLanguage()}
							rowActions={[
								{
									label: 'code',
									action: id => openModalWithDiscountVoucher(id),
								},
							]}
							styleForFieldList={[
								{
									field: 'code',
									styles: [
										{
											type: 'text',
											class: 'text-primary cursor-pointer',
										},
									],
								},
							]}
							rowQuantityList={[
								{ text: '10', value: 10 },
								{ text: '20', value: 20 },
								{ text: '50', value: 50 },
								{ text: '200', value: 200 },
								{ text: '500', value: 500 },
								{ text: '1000', value: 1000 },
							]}
							allowAllItemsPerPage
						/>
					)}
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		voucherList: state.Orders.voucherList,
		voucher: state.Orders.voucher,
		voucherError: state.Orders.error,
	}
}

DiscountVoucher.propTypes = {
	t: PropTypes.func,
	i18n: PropTypes.func,
	voucherList: PropTypes.array,
	getVoucherList: PropTypes.func,
	voucher: PropTypes.object,
	voucherError: PropTypes.object,
	createVoucher: PropTypes.func,
	updateVoucherByCode: PropTypes.func,
}
export default connect(mapStateToProps, {
	getVoucherList,
	createVoucher,
	updateVoucherByCode,
})(withTranslation()(DiscountVoucher))

export const getVoucherDate = (dateStr, language) => {
	const date = new Date(dateStr)
	const day = date.getDate()
	const month = date.getMonth()
	const year = date.getFullYear()
	const localStoragelanguage = getLanguage()
	return adaptDateOnLocale(
		year,
		month,
		day,
		language ? language : localStoragelanguage,
	)
}
