import PropTypes from 'prop-types'
import { Component } from 'react'

class Switch extends Component {
	render() {
		return (
			<div
				className={`form-check form-switch form-switch-${this.props.size} ${this.props.className}`}
			>
				<input
					type="checkbox"
					className="form-check-input"
					id={this.props.id}
					checked={this.props.value}
					onChange={e => this.props.onChange && this.props.onChange(e)}
				/>
				<label className="form-check-label" htmlFor={this.props.id}>
					{this.props.label}
				</label>
			</div>
		)
	}
}
Switch.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	value: PropTypes.bool,
	size: PropTypes.oneOf(['sm', 'md', 'lg']),
}

export default Switch
