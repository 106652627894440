import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

const PartsTable = ({ t, parts, selectableParts, supplierSelected, onSelect, onSelectAll }) => {

	const [partsToRender, setPartsToRender] = useState([])
	const [selectablePartsToRender, setSelectablePartsToRender] = useState([])

	useEffect(() => {
		setSelectablePartsToRender(deleteDuplicatedParts(selectableParts))
	}, [selectableParts])

	useEffect(() => {
		setPartsToRender(deleteDuplicatedParts(parts))
	}, [parts])

	const deleteDuplicatedParts = (parts) => {
		const selectableParts = []
		for (const part of parts) {
			if (!selectableParts.some((partInArray) => partInArray.partId === part.partId)) {
				selectableParts.push(part)
			}
		}
		return selectableParts
	}

	const selectablePartIds = []
	selectableParts.forEach((part) => {
		selectablePartIds.push(part.partId)
	})

	const onChangeCheckboxAll = () => {
		const checkbox = document.getElementById('checkbox_all')
		if (checkbox.checked) {
			selectablePartIds.forEach(partId => {
				document.getElementById(`checkbox_${partId}`).checked = true
			})
		} else {
			selectablePartIds.forEach(partId => {
				document.getElementById(`checkbox_${partId}`).checked = false
			})
		}
		onSelectAll(checkbox.checked, selectablePartIds)
	}

	const onChangeCheckbox = (partId) => {
		onSelect(partId)
		const checkbox = document.getElementById(`checkbox_${partId}`)
		let allChecked = true
		selectablePartIds.forEach(partId => {
			if (document.getElementById(`checkbox_${partId}`).checked != checkbox.checked) {
				allChecked = false
			}
		})
		if (allChecked) {
			document.getElementById('checkbox_all').checked = checkbox.checked
		}
	}

	return (
		<div className="bg-white p-4 border">
			{partsToRender && parts.length > 0 ? (
				<table style={{ width: '100%' }} className="border-collapse">
					<tr>
						{supplierSelected != null && <th className="p-2"><input type="checkbox" name="allPartSelector" id="checkbox_all" onChange={() => onChangeCheckboxAll()} /></th>}
						<th className="p-2">{t('part', { ns: 'naming' })}</th>
						<th className="p-2">{t('partsRequested', { ns: 'naming' })}</th>
						<th className="p-2">{t('image', { ns: 'naming' })}</th>
						<th className="p-2">{t('files', { ns: 'naming' })}</th>
						<th className="p-2">{t('description', { ns: 'naming' })}</th>
						<th className="p-2">{t('unitPrice', { ns: 'naming' })}</th>
						<th style={{ width: '10rem' }} className="p-2">{t('quantity', { ns: 'naming' })}</th>
						<th className="p-2">{t('totalPrice', { ns: 'naming' })}</th>
						<th style={{ width: '15rem' }} className="p-2">{t('comment', { ns: 'naming' })}</th>
						<th className="p-2">{t('offer', { ns: 'naming' })}</th>
					</tr>
					{selectablePartsToRender.map((part, i) => {
						return <tr key={`part_${part.partId}`} className={'bg-gray'}>
							<td className="border border-top-bottom p-2"><input type="checkbox" name="partSelector" id={`checkbox_${part.partId}`} onChange={() => onChangeCheckbox(part.partId)} /></td>
							<td className="border border-top-bottom p-2">{part.partId}</td>
							<td className="border border-top-bottom p-2">{part.partsRequested}</td>
							<td className="border border-top-bottom p-2">{part.image}</td>
							<td className="border border-top-bottom p-2">{part.files}</td>
							<td className="border border-top-bottom p-2">
								<p className="my-1">{part.description && part.description[0]}</p>
								<p className="my-1">{part.description && part.description[1]}</p>
							</td>
							<td key={`unitPrice_${part.partId}`} className="border border-top-bottom p-2">{part.unitPrice}</td>
							<td key={`quantity_${part.partId}`} className="border border-top-bottom p-2">{part.quantity}</td>
							<td key={`totalPrice_${part.partId}`} className="border border-top-bottom p-2">{part.totalPrice}</td>
							<td className="border border-top-bottom p-2">{part.comment}</td>
							<td className="border border-top-bottom p-2">{part.offerId}</td>
						</tr>
					})}
					{partsToRender.map((part, i) => {
						if (!selectablePartsToRender.some((partInArray) => partInArray.partId === part.partId)) {
							return <tr key={`part_${part.partId}`}>
								<td className="border border-top-bottom p-2"></td>
								<td className="border border-top-bottom p-2">{part.partId}</td>
								<td className="border border-top-bottom p-2">{part.partsRequested}</td>
								<td className="border border-top-bottom p-2">{part.image}</td>
								<td className="border border-top-bottom p-2">{part.files}</td>
								<td className="border border-top-bottom p-2">
									<p className="my-1">{part.description && part.description[0]}</p>
									<p className="my-1">{part.description && part.description[1]}</p>
								</td>
								<td className="border border-top-bottom p-2"></td>
								<td className="border border-top-bottom p-2"></td>
								<td className="border border-top-bottom p-2"></td>
								<td className="border border-top-bottom p-2">{part.comment}</td>
								<td className="border border-top-bottom p-2">{part.offerId}</td>
							</tr>
						}
					})}
				</table>
			)
				:
				(
					<div>{t('no-parts-to-show', { ns: 'naming' })}</div>
				)
			}
		</div>
	)
}

PartsTable.propTypes = {
	t: PropTypes.func,
	parts: PropTypes.array,
	selectableParts: PropTypes.array,
	supplierSelected: PropTypes.bool,
	onSelect: PropTypes.func,
	onSelectAll: PropTypes.func,
}

export default withTranslation()(PartsTable)
