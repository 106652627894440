import Breadcrumbs from 'components/Common/Breadcrumb'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import { loginUser } from 'store/actions'

const OrderInvoiceGeneratorLogin = ({ t, loginUser, id }) => {

	useEffect(() => {
		const params = window.location.search.split('&')
		const email = params[0].split('=')[1]
		const password = params[1].split('=')[1]
		loginUser({ email, password })
	}, [])

	useEffect(() => {
		if (id) {
			window.location.assign('/generate-order-pdfs')
		}
	}, [id])

	return (
		<div className="page-content">
			<MetaTags>
				<title>Proto&Go!</title>
			</MetaTags>
			<Breadcrumbs
				breadcrumbItems={[
					{
						item: t('order-pdf-generation-login', { ns: 'naming' }),
						link: '/generate-order-pdfs-login-login',
					},
				]}
			/>
			<div>{id ? 'logged in' : 'logging in'}</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		id: state.Login.id,
	}
}

OrderInvoiceGeneratorLogin.propTypes = {
	t: PropTypes.func,
	loginUser: PropTypes.func,
	id: PropTypes.string,
}

export default withRouter(connect(mapStateToProps, { loginUser })(
	withTranslation()(OrderInvoiceGeneratorLogin),
))
