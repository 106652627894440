import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { PartConfigTranslations } from 'common/Utils/PartConfigTranslations'
import { middleEllipsis } from 'common/Utils/StringUtilities'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import Barcode from '../Barcode/Barcode'

const PartLabel = ({
	id,
	code,
	text,
	format,
	className,
	fileName,
	weight,
	size,
	quantity,
	partConfiguration,
	orderId,
	itemNumber,
	partConfigOptions,
	language,
}) => {
	const part = { configuration: partConfiguration }
	const partConfigurationStr = []
	const partExtraConfigStr = []
	const materialStr = PartConfigTranslations.getMaterialName({ part, partConfigOptions, language })
	const alloyStr = PartConfigTranslations.getAlloyName({ part, partConfigOptions, language })
	const colorStr = PartConfigTranslations.getColorName({ part, partConfigOptions, language })
	const finishStr = PartConfigTranslations.getFinishName({ part, partConfigOptions, language })
	const hardnessStr = PartConfigTranslations.getHardnessName({ part, partConfigOptions, language })
	const colorFinishStr = PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language })

	materialStr !== '' && partConfigurationStr.push(materialStr)
	alloyStr !== '' && partConfigurationStr.push(alloyStr)
	colorStr !== '' && partConfigurationStr.push(colorStr)
	finishStr !== '' && partConfigurationStr.push(finishStr)
	hardnessStr !== '' && partExtraConfigStr.push(hardnessStr)
	colorFinishStr !== '' && partExtraConfigStr.push(colorFinishStr)

	return (
		<div className={`labelSize page label partlabel position-relative ${className}`}>
			<div className="mx-3">
				<p className="m-0 ">
					<small>
						{itemNumber && `Item ${itemNumber} -`} Order #
						<strong>{orderId}</strong>
					</small>
				</p>
				<p className="m-0">
					<strong><small>{middleEllipsis(fileName, 50)}</small></strong>
				</p>
				<span className="m-0 p-0">
					<p className={'m-0 p-0 d-flex h-100'}>
						{partConfigurationStr.map(el => <small className="me-1 mb-0 pb-0" key={'config_' + el}>{el}</small>)}
					</p>
					<p className="m-0 p-0 d-flex h-100 mt-n1 min-h-100">

						{partExtraConfigStr.map(el => <small className="me-1 mb-0 p-0" key={'finish' + el}>{el}</small>)}

					</p>
				</span>
				<p className="m-0">
					<small>
						{roundAtDecimals(size.x, 1)} x {roundAtDecimals(size.y, 1)} x{' '}
						{roundAtDecimals(size.z, 1)} mm -{' '}
						{weight < 1000
							? `${roundAtDecimals(weight, 0)} g.`
							: `${roundAtDecimals(weight / 1000, 2)} kg.`}
					</small>
				</p>

				<p className="m-0 mb-n3">
					<small>Units: {quantity}</small>
				</p>
			</div>
			<div>
				{partExtraConfigStr.length === 0 && <small className="text-white d-flex me-1 mb-0 p-0">_</small>}
				<Barcode
					className="mx-auto labelBarcode"
					id={id}
					code={code}
					text={text}
					format={format}
				/>
			</div>
			<div className="d-flex justify-content-center">
				<small>{code}</small>
			</div>
		</div>
	)
}

PartLabel.propTypes = {
	id: PropTypes.string.isRequired,
	code: PropTypes.string,
	text: PropTypes.string,
	format: PropTypes.string,
	className: PropTypes.string,
	orderId: PropTypes.number,
	fileName: PropTypes.string,
	weight: PropTypes.number,
	partConfiguration: PropTypes.shape({
		material: PropTypes.number,
		alloy: PropTypes.number,
		color: PropTypes.number,
		finishing: PropTypes.number,
		hardnessId: PropTypes.number,
		colorFinishId: PropTypes.number,
	}),
	size: PropTypes.shape({
		x: PropTypes.number,
		y: PropTypes.number,
		z: PropTypes.number,
	}),
	quantity: PropTypes.number,
	itemNumber: PropTypes.number,
	partConfigOptions: PropTypes.object,
	language: PropTypes.string,
}

const mapStateToProps = state => {
	return {
		partConfigOptions: state.Parts.partConfigOptions,
	}
}

export default connect(mapStateToProps, {

})(withGetPartConfigOptions(withTranslation()(PartLabel)))
