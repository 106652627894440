import useSimulateScanModalForReception from 'common/Hooks/useSimulateScanModalForReception'
import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { PartConfigTranslations } from 'common/Utils/PartConfigTranslations'
import TooltipMessage from 'components/Common/TooltipMessage'
import RealShipmentDate from 'pages/Orders/RealShipmentDate'
import ProductionStatusComponent from 'pages/SupplierProduction/ProductionStatus'
import { Tracking } from 'pages/SupplierProduction/Tracking'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Table from '../../../components/Table/Table'
import {
	getBoxList,
	getProductionItemsLabels,
	getSuppliers,
	resetOrderBoxCode,
	resetScannedPiecesFromAllPieces,
	resetScannedPiecesFromPiece,
	setOrderBoxCode,
	updateBoxesByRef,
	updateProductionItemReceivedQuantity,
} from '../../../store/actions'
import LabelsModal from '../LabelsModal'

const SelectedOrder = ({
	i18n: { language },
	orderInfo,
	setOrderInfo,
	productionItems,
	setProductionItems,
	getSuppliers,
	suppliers,
	resetScannedPiecesFromPiece,
	selectedOrderId,
	resetOrderBoxCode,
	resetScannedPiecesFromAllPieces,
	boxList,
	getBoxList,
	getBoxListStatus = {},
	lastScannedPart,
	updateBoxesByRef,
	updateProductionItemReceivedQuantity,
	setIsOpenAssignBoxModal,
	partConfigOptions,
}) => {
	const { t } = useTranslation('naming')

	const [parsedOrderInfo, setParsedOrderInfo] = useState([])
	const [parsedProductionInfo, setParsedProductionInfo] = useState([])
	const [totalPieces, setTotalPieces] = useState(0)
	const [completedRows, setCompletedRows] = useState([])
	const [printablePartId, setPrintablePartId] = useState(undefined)

	setTimeout(() => {
		const miElemento = document.getElementById('tablaOrderInfo')
		if (miElemento) {
			const rows = miElemento.getElementsByTagName('tr')
			if (rows) {
				const rowsArray = Array.from(rows)
				rowsArray.shift()
				rowsArray.forEach((row) => {
					const tds = row.getElementsByTagName('td')
					if (tds) {
						const tdArray = Array.from(tds)
						const tdPartId = tdArray[2]?.innerText
						if (tdPartId == lastScannedPart?.partId) {
							tdArray.forEach((td) => {
								td.style.backgroundColor = 'lightblue'
							})
							tdArray[2].scrollIntoView({ behavior: 'smooth', block: 'center' })
						} else {
							tdArray.forEach(td => {
								td.style.backgroundColor = 'white'
							})
						}
					}
				})
			}
		}
	}, 0)

	const resetAllScannedPieces = () => {
		updateProductionItemReceivedQuantity({
			data: {
				orderId: orderInfo.id,
			},
		})

		const newProductionItems = productionItems.map(item => ({
			...item,
			receivedQuantity: 0,
		}))
		setProductionItems(newProductionItems)
		const newOrderInfo = {
			...orderInfo,
			orderItems: orderInfo.orderItems.map(item => ({
				...item,
				receivedQuantity: 0,
			})),
		}
		setOrderInfo(newOrderInfo)
		setCompletedRows([])
	}

	const resetScannedPiece = (partId) => {
		updateProductionItemReceivedQuantity({
			data: {
				orderId: orderInfo.id,
				partId: partId,
				quantity: -(orderInfo?.orderItems?.find(orderItem => partId == orderItem.part.id)?.receivedQuantity),
			},
		})
		setCompletedRows((prev) => prev.filter((row) => row != partId))
		const newProductionItems = productionItems.map(item => ({
			...item,
			receivedQuantity: item.part.id == partId ? 0 : item.receivedQuantity,
		}))
		setProductionItems(newProductionItems)
		const newOrderInfo = {
			...orderInfo,
			orderItems: orderInfo.orderItems.map(item => ({
				...item,
				receivedQuantity: item.part.id == partId ? 0 : item.receivedQuantity,
			})),
		}
		setOrderInfo(newOrderInfo)
	}

	function handleUnassignOrderBoxes() {
		const boxes = orderInfo?.boxCode?.split(',')
		if (boxes) {
			updateBoxesByRef({
				data: {
					boxes: boxes.map((box) => ({
						ref: box.trim(),
						orderId: null,
					})),
				},
			})
		}
	}

	const getSentAndRequiredValues = (fragmentId, productionItems) => {
		let required = 0
		let sent = 0
		productionItems?.forEach(productionItem => {
			if (productionItem.orderFragmentId == fragmentId) {
				required += productionItem.totalQuantity
				sent += productionItem.shippedQuantity
			}
		})
		return sent + '/' + required
	}

	const {
		openSimulateScanModal,
		SimulateScanModalForReception,
	} = useSimulateScanModalForReception({
		order: {
			...orderInfo,
			productions: orderInfo.productions?.map(production => ({
				...production,
				productionItemList: productionItems.filter(p => p.orderId == production.orderId && p.orderFragmentId == production.orderFragmentId),
			})),
		},
		afterSuccess: ({ partId, quantity, orderFragmentId }) => {
			const newProductionItems = [...productionItems]
			newProductionItems.find(x => x.part.id == partId && x.orderFragmentId == orderFragmentId).receivedQuantity += quantity
			setProductionItems(newProductionItems)
			const newOrderInfo = { ...orderInfo }
			newOrderInfo.orderItems.find(orderItem => orderItem.part.id == partId).receivedQuantity += quantity
			setOrderInfo(newOrderInfo)
		},
	})

	const parseOrder = (orderItems, orderDetails) => {
		const parsedElements = []
		orderItems?.forEach((orderItem, index) => {
			const parsedElement = {
				item: (index + 1).toString() + '.',
				image: <img src={orderItem.part.imageUrl} alt="PartImg" height={50} width={50} />,
				id: orderItem.part.id,
				name: orderItem.part.modelFiles[0]?.name,
				specifications: PartConfigTranslations.getMaterialName({ part: orderItem.part, partConfigOptions, language }) + ' ' +
					PartConfigTranslations.getFinishName({ part: orderItem.part, partConfigOptions, language }),
				pieces: orderItem.receivedQuantity == orderItem.quantity ? (
					// Si la cantidad recibida es igual a la cantidad demandada: Muestra "N de N piezas" tachado
					<div className="d-flex align-items-center">
						<span style={{ textDecoration: 'line-through' }}>
							{orderItem.receivedQuantity + ' ' + t('of') + ' ' + orderItem.quantity + ' ' + t('pieces')}
						</span>
						<i className="mdi mdi-check-circle ms-2" style={{ color: 'green' }} />
					</div>
				) : orderItem.receivedQuantity >= orderItem.quantity ? (
					// Si la cantidad recibida es MAYOR a la cantidad demandada: Muestra "N de N piezas" con un warning
					<div className="d-flex align-items-center">
						<span>
							{orderItem.receivedQuantity + ' ' + t('of') + ' ' + orderItem.quantity + ' ' + t('pieces')}
						</span>
						<i className="bx bxs-error text-danger ms-2" />
					</div>
				) : (
					// Si la cantidad recibida es MENOR a la cantidad demandada: Muestra "N de N piezas" sin más
					<div>
						{orderItem.receivedQuantity + ' ' + t('of') + ' ' + orderItem.quantity + ' ' + t('pieces')}
					</div>
				),
				actions: (
					<div className='d-flex gap-2 justify-content-end'>
						{orderItem.receivedQuantity < orderItem.quantity &&
							<TooltipMessage place="top" message={t('scanPart')}>
								<button
									style={{ backgroundColor: 'transparent', border: '1px solid black', borderRadius: '4px', color: 'black', width: '30px', height: '30px' }}
									className="btn btn-sm align-self-center mt-2"
									onClick={() => openSimulateScanModal(orderItem)}
								>
									<i className='bx bx-station'></i>
								</button>
							</TooltipMessage>
						}
						<button
							onClick={() => { setPrintablePartId(orderItem.part.id) }}
							style={{ backgroundColor: 'transparent', border: '1px solid black', borderRadius: '4px', color: 'black', width: '30px', height: '30px' }}
							className="btn btn-sm align-self-center mt-2"
						>
							<i className="mdi mdi-printer" />
						</button>
						<button
							style={{ backgroundColor: 'transparent', border: '1px solid black', borderRadius: '4px', color: 'black', width: '30px', height: '30px' }}
							className="btn btn-sm align-self-center mt-2"
							onClick={() => {
								resetScannedPiecesFromPiece(orderItem.part.id, orderDetails.id)
								resetScannedPiece(orderItem.part.id)
							}}
						>
							<i className="mdi mdi-refresh" />
						</button>
					</div>
				),
			}
			parsedElements.push(parsedElement)
			if (orderItem.receivedQuantity >= orderItem.quantity) {
				setCompletedRows((prev) => [...prev, orderItem.part.id])
			}
			else {
				setCompletedRows((prev) => prev.filter((row) => row != orderItem.part.id))
			}
		})
		setParsedOrderInfo(parsedElements)
	}

	const parseProductions = (productions) => {
		const parsedProductions = []
		productions.forEach((production) => {
			const parsedProduction = {
				id: production.displayId,
				provider: suppliers?.find(supplier => supplier.id === production.supplierId)?.personalInformation?.firstName,
				status: (
					<ProductionStatusComponent
						status={production.statusId}
						disconformity={production.disconformity}
						partialShipment={production.partialShipment}
					/>
				),
				sentAndRequired: getSentAndRequiredValues(production.orderFragmentId, productionItems),
				courier: (
					<Tracking
						trackingId={production?.shipmentTrackingNumber}
						courier={production?.courier}
						estimatedArrivalDate={production?.arrivalDateEstimated}
						status={production?.statusId} />
				),
				date: (
					<RealShipmentDate
						date={production.shipmentDateEstimate}
						realDate={production.shipmentDateReal}
						delayDate={production.shipmentDateDelay}
					/>
				),
			}
			parsedProductions.push(parsedProduction)
		})
		setParsedProductionInfo(parsedProductions)
	}

	useEffect(() => {
		if (Object.keys(suppliers).length == 0) {
			getSuppliers()
		}
		if (orderInfo) {
			parseOrder(orderInfo?.orderItems, orderInfo)
			const total = orderInfo?.orderItems?.reduce((total, orderItem) => total + orderItem.quantity, 0) || 0
			setTotalPieces(total)
		}
	}, [orderInfo, productionItems])

	useEffect(() => {
		if (Object.keys(suppliers).length !== 0 && Object.keys(orderInfo).length !== 0) {
			parseProductions(orderInfo?.productions)
		}
	}, [suppliers, orderInfo])

	useEffect(() => {
		if (!getBoxListStatus?.isLoading && (boxList == null || boxList.length == 0)) {
			getBoxList()
		}
	}, [])

	return (
		<>
			<div>
				<div
					className="w-100 p-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
				>
					<h5 className="pt-2">
						<strong>
							{t('activeOrder')}
							{' '}
							<Link to={`/order/${orderInfo?.id}`}>
								#{orderInfo?.id}
							</Link>
							{' | '}
							{orderInfo?.billingInfo?.companyName}
						</strong>
					</h5>
				</div>
				<div className="w-100 p-2 d-flex align-items-center" style={{ border: '0.5px solid lightgray' }}>
					<div className="col-8 d-flex d-row">
						<h4 className="pt-2">
							<i className="bx bx-box me-2" />
							{t('assignedBoxes')}: <strong>{orderInfo?.boxCode || t('noBoxes')} </strong>
						</h4>
						<button
							style={{ border: '0px', borderRadius: '4px', color: 'gray', width: '30px', height: '30px' }}
							className="ms-2 align-self-center d-flex justify-content-center align-items-center"
							onClick={() => {
								resetOrderBoxCode(selectedOrderId.orderId)
								handleUnassignOrderBoxes()
							}}
						>
							<i className="mdi mdi-refresh" />
						</button>
					</div>
					<div className="col-4 d-flex justify-content-end">
						<button
							style={{ border: '0px', borderRadius: '4px', color: 'gray', width: 'fit-content' }}
							className="p-2 d-flex justify-content-center align-items-center"
						>
							<p className="m-0" onClick={() => setIsOpenAssignBoxModal(true)}>
								{t('addExtraBox')}
								<i className="mdi mdi-arrow-right ms-2" />
							</p>
						</button>
					</div>
				</div>
				<div className="w-100 p-2 d-flex align-items-center" style={{ border: '0.5px solid lightgray', height: '100%' }}>
					<div className="col-8">
						<p className="pt-3">{t('orderElements')}</p>
					</div>
					<div className="col-4 d-flex d-row justify-content-end">
						<button
							style={{ border: '0px', borderRadius: '4px', color: 'gray', width: '30px', height: '30px' }}
							className="ms-2 align-self-center d-flex justify-content-center align-items-center"
							onClick={() => setPrintablePartId(null)}
						>
							<i className="bx bxs-printer" />
						</button>
						<button
							style={{ border: '0px', borderRadius: '4px', color: 'gray', width: '30px', height: '30px' }}
							className="ms-2 align-self-center d-flex justify-content-center align-items-center"
							onClick={() => {
								resetAllScannedPieces()
								resetScannedPiecesFromAllPieces(selectedOrderId.orderId)
								// Cuando se le da reset a una pieza después de haberle escaneado una cantidad de piezas, en verificacion aparece en negativo.
							}}
						>
							<i className="mdi mdi-refresh" />
						</button>

					</div>
				</div>
				<div className="w-100" style={{ border: '0.5px solid lightgray', height: '100%' }}>
					{Object.keys(parsedOrderInfo).length !== 0 && (
						<div id="tablaOrderInfo">
							<Table
								header={{
									item: t('item'),
									image: t('image'),
									id: 'Id',
									name: t('name'),
									specifications: t('specifications'),
									pieces: t('parts'),
									actions: '',
								}}
								items={parsedOrderInfo}
								language={getLanguage()}
								showStatusFilter={false}
								disableInteractions={true}
								selectRow={{ hideSelectColumn: true }}
								disableStriped
								paginationProps={{
									custom: true,
									sizePerPage: 200,
								}}
								highlightedRows={completedRows}
								highlightStyle="green-logistic-bg-color"
							/>
						</div>
					)}
				</div>
				<div
					className="w-100 ps-2 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
				>
					<p className="pt-3">
						{t('thisOrdersContains')}
						{' '}
						{orderInfo?.orderItems?.length}
						{' '}
						{t('referencesFromATotalOf')}
						{' '}
						{totalPieces}
						{' '}
						{t('pieces')}
					</p>
				</div>
			</div>
			<div>
				<div
					className="w-100 p-2 mt-4 d-flex align-items-center"
					style={{ border: '0.5px solid lightgray', borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}
				>
					<h5 className="pt-2">{t('productionsFromOrder')}</h5>
				</div>
				<div
					className="w-100"
					style={{ border: '0.5px solid lightgray', height: '100%', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
				>
					{Object.keys(parsedProductionInfo).length !== 0 && Object.keys(suppliers).length !== 0 &&
						<Table
							header={{
								id: 'Id',
								provider: t('provider'),
								status: t('status'),
								sentAndRequired: t('sent/required'),
								courier: t('courier'),
								date: t('date'),
							}}
							items={parsedProductionInfo}
							language={getLanguage()}
							showStatusFilter={false}
							disableInteractions={true}
							selectRow={{ hideSelectColumn: true }}
							paginationProps={{
								custom: true,
								sizePerPage: 200,
							}}
						/>
					}
					<LabelsModal
						partId={printablePartId}
						closeModal={() => setPrintablePartId(undefined)} />
					{SimulateScanModalForReception}
				</div>
			</div>
		</>
	)
}

SelectedOrder.propTypes = {
	orderInfo: PropTypes.object,
	getSuppliers: PropTypes.func,
	suppliers: PropTypes.array,
	getProductionItemsLabels: PropTypes.func,
	productionLabels: PropTypes.array,
	productionItems: PropTypes.array,
	resetScannedPiecesFromPiece: PropTypes.func,
	setOrderBoxCode: PropTypes.func,
	selectedOrderId: PropTypes.any,
	resetOrderBoxCode: PropTypes.func,
	resetScannedPiecesFromAllPieces: PropTypes.func,
	boxList: PropTypes.array,
	getBoxList: PropTypes.func,
	getBoxListStatus: PropTypes.shape({
		isLoading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.string,
	}),
	lastScannedPart: PropTypes.number,
	updateBoxesByRef: PropTypes.func,
	updateProductionItemReceivedQuantity: PropTypes.func,
	handleOnChangeAsignBoxModal: PropTypes.func,
	selectedOrderBoxList: PropTypes.array,
	setIsOpenAssignBoxModal: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

const mapStateToProps = (state) => ({
	suppliers: state.Users.suppliers,
	productionLabels: state.Productions.productionLabels,
	selectedOrderId: state.Orders.selectedOrderId,
	boxList: state.Orders.boxList,
	getBoxListStatus: state.Orders.getBoxListStatus,
	lastScannedPart: state.Orders.lastScannedPart,
	partConfigOptions: state.Parts.partConfigOptions,
})

const mapDispatchToProps = {
	getSuppliers,
	getProductionItemsLabels,
	resetScannedPiecesFromPiece,
	setOrderBoxCode,
	resetOrderBoxCode,
	resetScannedPiecesFromAllPieces,
	getBoxList,
	updateBoxesByRef,
	updateProductionItemReceivedQuantity,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(
	withTranslation()(
		SelectedOrder,
	),
)
