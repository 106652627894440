import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const OptionsSelector = ({ t, options, value, setValue, isMulti }) => {

	const handleOnChangeValue = (newValue) => {
		if (isMulti) {
			if (value.includes(newValue)) {
				setValue(value.filter(el => el !== newValue))
			} else {
				setValue([...value, newValue])
			}
		} else {
			setValue(newValue)
		}
	}

	const isActiveOption = (option) => {
		if (isMulti) value.includes(option)
		return value === option
	}

	const mappedOptions = options.map(({ label, value, activeColorClassName }) => {

		const className = isActiveOption(value) ? `bx bxs-circle me-1 ${activeColorClassName || 'text-primary'}` : 'bx bx-circle me-1 text-secondary'
		return (
			<div onClick={() => { handleOnChangeValue(value) }} className={`d-flex flex-row cursor-pointer align-items-center mx-2 ${(activeColorClassName && activeColorClassName + '_hoover') || 'option-selector'}`} key={value}>
				<i className={className}></i>
				{t(label, { ns: 'naming' })}
			</div>
		)
	})

	return mappedOptions
}

OptionsSelector.propTypes = {
	t: PropTypes.func,
	options: PropTypes.array,
	value: PropTypes.any,
	setValue: PropTypes.func,
	isMulti: PropTypes.bool,
	activeColorClassName: PropTypes.string,
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OptionsSelector))
