import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export const VerificationFiles = ({ blueprintUrl, model3dUrl, isBlueprintSeen, setBlueprintAsSeen }) => {
	const saveStp = () => {
		const linkStp = document.createElement('a')
		linkStp.href = model3dUrl
		document.body.appendChild(linkStp)
		linkStp.click()
	}
	const openPdf = () => {
		const linkPdf = document.createElement('a')
		linkPdf.href = blueprintUrl
		linkPdf.target = '_blank'
		document.body.appendChild(linkPdf)
		linkPdf.click()
		setBlueprintAsSeen()
	}
	const baseClassName = 'rounded px-1 cursor-pointer'
	return (
		<div className="d-flex flex-column mb-2">
			{model3dUrl && <div className={`${baseClassName} mb-1 bg-gray`} onClick={() => saveStp()}><i className='bx bxs-file-blank me-1 mt-1'></i>3D</div>}
			{blueprintUrl && <div className={`${baseClassName} ${isBlueprintSeen ? 'bg-gray' : 'bg-orange text-white'}`} onClick={() => openPdf()}><i className='bx bxs-file-blank me-1 mt-1'></i>2D</div>}
		</div>
	)
}

const mapStateToProps = state => {
	return {}
}

VerificationFiles.propTypes = {
	blueprintUrl: PropTypes.string,
	model3dUrl: PropTypes.string,
	isBlueprintSeen: PropTypes.bool,
	setBlueprintAsSeen: PropTypes.func,
}

export default connect(mapStateToProps, {})(VerificationFiles)
