import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import OptionsSelector from 'components/Common/OptionsSelector'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getCountriesDashboard } from 'store/actions'
import { getPreviousDateRange } from '../utils'
import CountriesDashboard from './CountriesDashboard'
import CountriesPercentageDashboard from './CountriesPercentageDashboard'
import CountriesSideBar from './CountriesSideBar'

export const CountriesDashboardContainer = ({ t, dateRange, error, isLoading, filters, getCountriesDashboard, dashboardCountryList }) => {

	const IS_OFFERS_OPTIONS = [{ label: 'offers', value: true, activeColorClassName: 'offer-option-selector' }, { label: 'orders', value: false }]
	const CHART_OPTIONS = [{ label: 'map', value: true, activeColorClassName: 'map-option-selector' }, { label: 'percentage', value: false, activeColorClassName: 'percentage-option-selector' }]

	const [isOffers, setIsOffers] = useState(true)
	const [isShowingMap, setIsShowingMap] = useState(true)

	useEffect(() => {
		if (dateRange != null) {
			const previousDateRange = getPreviousDateRange(dateRange)
			const data = {
				previousDateRange,
				dateRange,
				filters,
				isOffers,
			}
			getCountriesDashboard(data)
		}
	}, [dateRange, filters, isOffers])

	return (
		<div style={{ height: '44rem' }} className='d-flex justify-content-center w-100 align-items-center'>
			{error ? <div className='h-auto mt-4'><Alert message={error} type='danger' /></div> : isLoading ? <Loader className='font-size-50' /> :
				<div className='d-flex w-100 flex-column justify-content-center mt-4 '>
					<div className='w-100 d-flex justify-content-between mt-2'>
						<div className='d-flex'>
							<OptionsSelector options={CHART_OPTIONS} setValue={setIsShowingMap} value={isShowingMap} />
						</div>
						<div className='d-flex'>
							<OptionsSelector options={IS_OFFERS_OPTIONS} setValue={setIsOffers} value={isOffers} />
						</div>
					</div>
					<div>
						{
							isShowingMap ?
								<CountriesDashboard isOffers={isOffers} />
								:
								<div className='d-flex flex-row row align-items-center'>
									<div className='col-10 mx-2'>
										<CountriesPercentageDashboard />
									</div>
									<div className='col-1 ms-2 d-flex align-items-center'>
										<CountriesSideBar dateRange={dateRange} isOffers={isOffers} />
									</div>
								</div>
						}
					</div>
				</div>
			}
		</div>
	)
}

CountriesDashboardContainer.propTypes = {
	t: PropTypes.func,
	dateRange: PropTypes.object,
	error: PropTypes.string,
	isLoading: PropTypes.bool,
	getCountriesDashboard: PropTypes.func,

}

const mapStateToProps = (state) => ({
	isLoading: state.Users.isLoading,
	error: state.Users.error,
})

const mapDispatchToProps = { getCountriesDashboard }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CountriesDashboardContainer))
