export const NOTIFICATION_TYPE_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]

export const NOTIFICATION_TYPE = {
	OfferPublished: 1,
	OrderDelayed: 2,
	OrderSent: 3,
	OrderPublished: 4,
	ErrorPayment: 5,
	QuotePublished: 6,
	ProdRejected: 7,
	QualityIncidence: 8,
	ProdDelayedNotified: 9,
	ProdDelayed: 10,
	ProdRetention: 11,
	OfferNoOpened: 12,
	CrmNotification: 13,
	NewOffer: 14,
	ProdNotAccepted: 15,
	GenericTask: 16,
	OrderTask: 17,
	OfferTask: 18,
	CustomerTask: 19,
}

export const NOTIFICATIONTYPE_COLOR = {
	[NOTIFICATION_TYPE.OfferPublished]: 'secondary',
	[NOTIFICATION_TYPE.OrderPublished]: 'yellow',
	[NOTIFICATION_TYPE.ErrorPayment]: 'yellow',
	[NOTIFICATION_TYPE.QuotePublished]: 'orange',
	[NOTIFICATION_TYPE.ProdRejected]: 'cyan',
	[NOTIFICATION_TYPE.QualityIncidence]: 'blue',
	[NOTIFICATION_TYPE.ProdDelayedNotified]: 'cyan',
	[NOTIFICATION_TYPE.ProdDelayed]: 'cyan',
	[NOTIFICATION_TYPE.ProdRetention]: 'cyan',
	[NOTIFICATION_TYPE.OfferNoOpened]: 'secondary',
	[NOTIFICATION_TYPE.CrmNotification]: 'green',
	[NOTIFICATION_TYPE.NewOffer]: 'secondary',
	[NOTIFICATION_TYPE.ProdNotAccepted]: 'cyan',
	[NOTIFICATION_TYPE.GenericTask]: 'pink',
	[NOTIFICATION_TYPE.OrderTask]: 'pink',
	[NOTIFICATION_TYPE.OfferTask]: 'pink',
	[NOTIFICATION_TYPE.CustomerTask]: 'pink',
}
