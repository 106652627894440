import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import OptionsSelector from 'components/Common/OptionsSelector'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getTechnologiesDashboard } from 'store/actions'
import { getPreviousDateRange } from '../utils'
import TechnologiesDashboard from './TechnologiesDashboard'
import TechnologiesPercentageDashboard from './TechnologiesPercentageDashboard'
import TechnologiesSideBar from './TechnologiesSideBar'

export const TechnologiesDashboardContainer = ({ getTechnologiesDashboard, t, dateRange, filters, error, isLoading }) => {

	const IS_OFFERS_OPTIONS = [{ label: 'offers', value: true, activeColorClassName: 'offer-option-selector' }, { label: 'orders', value: false }]
	const CHART_OPTIONS = [{ label: 'graph', value: true, activeColorClassName: 'chart-option-selector' }, { label: 'percentage', value: false, activeColorClassName: 'percentage-option-selector' }]

	const [isOffers, setIsOffers] = useState(true)
	const [isBarChart, setIsBarChart] = useState(true)

	useEffect(() => {
		if (dateRange != null) {
			const previousDateRange = getPreviousDateRange(dateRange)
			const data = {
				previousDateRange,
				dateRange,
				filters,
				isOffers,
			}
			getTechnologiesDashboard(data)
		}
	}, [dateRange, filters, isOffers])

	return (
		<div style={{ height: '44rem' }} className='d-flex justify-content-center w-100 align-items-center'>
			{error ? <div className='h-auto mt-4'><Alert message={error} type='danger' /></div> : isLoading ? <Loader className='font-size-50' /> :
				<div className='d-flex col justify-content-center mt-4'>
					<div className='col-10 mx-2 d-flex flex-column h-100'>
						<div className='d-flex justify-content-between'>
							<div className='d-flex'>
								<OptionsSelector options={CHART_OPTIONS} setValue={setIsBarChart} value={isBarChart} />
							</div>
							<div className='d-flex'>
								<OptionsSelector options={IS_OFFERS_OPTIONS} setValue={setIsOffers} value={isOffers} />
							</div>
						</div>
						<div className='d-flex flex-column'>
							{isBarChart ? <TechnologiesDashboard dateRange={dateRange} /> : <TechnologiesPercentageDashboard isOffers={isOffers} />}
						</div>
					</div>
					<div className='col-1 ms-2 d-flex align-items-center '>
						<TechnologiesSideBar dateRange={dateRange} isOffers={isOffers} />
					</div>
				</div>
			}
		</div>
	)
}

TechnologiesDashboardContainer.propTypes = {
	getTechnologiesDashboard: PropTypes.func,
	t: PropTypes.func,
	isLoading: PropTypes.bool,
	dateRange: PropTypes.object,
	filters: PropTypes.object,
	error: PropTypes.string,
}

const mapStateToProps = (state) => ({
	isLoading: state.Parts.isLoading,
	error: state.Parts.error,

})

const mapDispatchToProps = { getTechnologiesDashboard }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TechnologiesDashboardContainer))
