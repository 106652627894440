import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import OrderInfo from './VerificationOrderInfo/OrderInfo'
import VerificationOrders from './VerificationOrders/VerificationOrders'
import SearchAndClientInfo from './VerificationSearchClientInfo/SearchAndClientInfo'

const Verification = ({ t, selectedOrderId }) => {
	const [selectedOrder, setSelectedOrder] = useState(null)
	const [manualScanned, setManualScanned] = useState(null)

	useEffect(() => {
		if (selectedOrderId) {
			setSelectedOrder(selectedOrderId)
		}
	}, [selectedOrderId])

	return (
		<div className="page-content marginFooter">
			<MetaTags>
				<title>Proto&Go! | {t('verification', { ns: 'naming' })}</title>
			</MetaTags>
			<Breadcrumbs
				title={t('quality', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('verification', { ns: 'naming' }),
						link: '/verification',
					},
				]}
			/>
			<div className="d-flex flex-row align-items-start">
				<div className="col-3 d-flex flex-column">
					<VerificationOrders manualScanned={manualScanned} setManualScanned={setManualScanned} />
					<SearchAndClientInfo setManualScanned={setManualScanned} />
				</div>
				<div className="col-9 p-4 mb-3 mx-2 bg-white">
					<OrderInfo selectedOrderId={selectedOrder} />
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		selectedOrderId: state.Orders.selectedVerificationOrderId?.orderId,
	}
}

Verification.propTypes = {
	t: PropTypes.func,
	selectedOrderId: PropTypes.any,
}

export default withGetPartConfigOptions(connect(mapStateToProps)(withTranslation()(Verification)))
