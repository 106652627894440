/* eslint-disable react/jsx-key */
import { PartConfigTranslations } from 'common/Utils/PartConfigTranslations'
import { getDate } from 'common/Utils/StringUtilities'
import { mapPriceToString } from 'common/Utils/mapPriceToString'
import PropTypes from 'prop-types'
import { PGOHeader } from './PGOHeader'

export const SupplierInvoicePDF = ({ production, supplier, t, partConfigOptions }) => {

	return [
		(<div>{PGOHeader}</div>),
		(<h3 style={{
			width: '20%',
			height: '40px',
			backgroundColor: '#F08C01',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: 'white',
		}}>
			{'Production'}
		</h3>),
		(<div style={{
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: '2em',
			color: '#282828',
		}}>
			<div
				className='customs-pdf-orange-table'
				style={{ width: '50%', gridTemplateColumns: 'repeat(3, 1fr)' }}
			>
				<strong>{'ID'}</strong>
				<strong>{'Order date'}</strong>
				<strong>{'Shipment date'}</strong>
				<div>{'#'}{production?.displayId}</div>
				<div>{getDate(production?.orderDate)}</div>
				<div>{getDate(production?.shipmentDateReal || production?.shipmentDateEstimate)}</div>
			</div>
			<div style={{ width: '50%' }}>
				<h3>{'Supplier'}</h3>
				<div
					className='customs-pdf-orange-table'
					style={{ gridTemplateColumns: '1fr', textAlign: 'end' }}
				>
					<div>
						<h5><strong>{supplier?.supplierInfo?.officialName}</strong></h5>
						<div>{supplier?.supplierInfo?.address?.address}</div>
						<div>
							{supplier?.supplierInfo?.address?.state}
							{', '}
							{supplier?.supplierInfo?.address?.postalCode}
							{' '}
							{supplier?.supplierInfo?.address?.country}
						</div>
						<div>
							{'Tel. '}
							{supplier?.account?.phoneData?.phone?.prefix}
							{' '}
							{supplier?.account?.phoneData?.phone?.number?.slice(0, 2)}
							{' '}
							{supplier?.account?.phoneData?.phone?.number?.slice(2, 5)}
							{' '}
							{supplier?.account?.phoneData?.phone?.number?.slice(5, 7)}
							{' '}
							{supplier?.account?.phoneData?.phone?.number?.slice(7)}
						</div>
						<div>
							{supplier?.supplierInfo?.CIF ? `CIF: ${supplier?.supplierInfo?.CIF}` : ''}
						</div>
					</div>
				</div>
			</div>
		</div>),
		(<div style={{ height: '2em' }} />),
		(<div
			className='customs-pdf-orange-table customs-pdf-center-cells'
			style={{ color: '#282828', width: '100%', display: 'grid', gridTemplateColumns: 'minmax(0, 4.5fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1.5fr)', marginBottom: '0.8em' }}
		>
			<div>{'Part'}</div>
			<div style={{ justifyContent: 'center' }}>{'Quantity'}</div>
			<div style={{ justifyContent: 'flex-end' }}>{'Unit price'}</div>
			<div style={{ justifyContent: 'flex-end' }}>{'Total price'}</div>
		</div>),
		production?.productionItemList?.map((productionItem, index) => (
			<div
				key={index}
				className='customs-pdf-gray-table customs-pdf-center-cells'
				style={{ color: '#282828', width: '100%', display: 'grid', gridTemplateColumns: 'minmax(0, 4.5fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1.5fr)', marginBottom: '0.8em' }}
			>
				<div style={{ display: 'flex' }}>
					<img src={productionItem.part.imageBase64} alt="Product Image" style={{ width: '50px', height: '50px', marginRight: '10px' }} />
					<div>
						<div style={{ overflowWrap: 'anywhere' }}>{productionItem.part?.name}</div>
						<div style={{ fontSize: '0.8em' }}>
							{PartConfigTranslations.getTechnologyName({ part: productionItem.part, partConfigOptions, language: 'en' })}
						</div>
						<div style={{ fontSize: '0.8em' }}>
							{[
								PartConfigTranslations.getMaterialName({ part: productionItem.part, partConfigOptions, language: 'en' }),
								PartConfigTranslations.getAlloyName({ part: productionItem.part, partConfigOptions, language: 'en' }),
								PartConfigTranslations.getHardnessName({ part: productionItem.part, partConfigOptions, language: 'en' }),
							].join(' ')}
						</div>
						<div style={{ fontSize: '0.8em' }}>
							{[
								PartConfigTranslations.getFinishName({ part: productionItem.part, partConfigOptions, language: 'en' }),
								PartConfigTranslations.getColorName({ part: productionItem.part, partConfigOptions, language: 'en' }),
								PartConfigTranslations.getPaletteName({ part: productionItem.part, partConfigOptions, language: 'en' }),
								PartConfigTranslations.getColorFinishName({ part: productionItem.part, partConfigOptions, language: 'en' }),
							].join(' ')}
						</div>
					</div>
				</div>
				<div style={{ justifyContent: 'center' }}>{productionItem.totalQuantity}</div>
				<div style={{ justifyContent: 'flex-end' }}>{'$'}{mapPriceToString(productionItem.unitPurchasePrice)}</div>
				<div style={{ justifyContent: 'flex-end' }}>{'$'}{mapPriceToString(productionItem.totalPurchasePrice)}</div>
			</div>
		)),
		(<div
			className='customs-pdf-orange-table customs-pdf-center-cells'
			style={{ width: '25%', float: 'right', display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', color: '#282828' }}
		>
			<strong>{'Total price'}</strong>
			<div>{'$'}{mapPriceToString(production.purchasePrice)}</div>
		</div>),
	]
}

SupplierInvoicePDF.propTypes = {
	production: PropTypes.object,
	supplier: PropTypes.object,
	t: PropTypes.func,
}
