import Modal from 'components/Common/Modal/Modal'
import { OrderStatus } from 'constants/orderStatus'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { updateOrder } from 'store/actions'

export const ReOpenOrderModal = ({t, updateOrder, isOpenModal, closeModal, orderId, isOrderUpdatedSuccessfully}) => {

	useEffect(() => {
		if (isOrderUpdatedSuccessfully) {
			location.reload()
		}
	}, [isOrderUpdatedSuccessfully])

	const handleReOpenOrder = () => {
		updateOrder({data : { order : {id: orderId, statusId: OrderStatus.IN_QUALITY}}})
	
	}

  return (
	<Modal 
		title={t('reOpenOrder', { ns : 'naming'})}
		body={<div className='p-4'>{t('reOpenOrderBody', {ns : 'naming'})}</div>}
		buttons={[<button className='btn btn-primary' key={'reopenmodal_button'} onClick={handleReOpenOrder}>{t('saveAndConfirm', { ns : 'naming'})}</button>]}
		isOpen={isOpenModal}
		closeModal={closeModal}
	>
	</Modal>
  )
}

ReOpenOrderModal.propTypes = {
  t: PropTypes.func,
  updateOrder: PropTypes.func,
  isOpenModal: PropTypes.bool,
  closeModal: PropTypes.func,
  isOrderUpdatedSuccessfully: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	isOrderUpdatedSuccessfully: state.Orders.isOrderUpdatedSuccessfully,
})

const mapDispatchToProps = { updateOrder }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReOpenOrderModal))
