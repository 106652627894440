import { getDateWithDay } from 'common/Utils/StringUtilities'
import { IconTooltip } from 'components/Common/IconTooltip'
import { PartName } from 'components/Common/PartName'
import RemainingDays from 'components/Common/RemainingDays'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

const ReceptionExpeditionOrderSummary = ({ order, t, isProductions, selectedExpeditionOrderId, openedOrders }) => {
	const {
		id,
		organizationName,
		references,
		totalPieces,
		completed,
		shipmentDate,
		box,
		supplier,
		arrivalDate,
		trackingNumber,
		supplierId,
		receivingCountry,
		disconformity,
	} = order
	const defaultPClassName = 'm-0 p-0 me-1 '
	const flexRowClassName = 'd-flex flex-row '
	const flexColumnClassName = 'd-flex flex-column '

	const getStatusText = (completed, isProductions) => {
		if (isProductions) return t('pending', { ns: 'naming' })
		return completed ? t('complete', { ns: 'naming' }) : t('pending', { ns: 'naming' })
	}

	const getCompletedClassName = (completed, isProductions) => {
		if (isProductions) return 'bx bxs-circle text-warning margin-top-017rem ms-1'
		return completed ? 'bx bxs-circle text-success margin-top-017rem ms-1' : 'bx bxs-circle text-secondary margin-top-017rem ms-1'
	}

	const isSameClient = useMemo(() => {
		if (selectedExpeditionOrderId?.orderId) {
			const selectedOrderAccount = openedOrders.find((order) => order.id == selectedExpeditionOrderId?.orderId)?.organizationId
			const currentOrderAccount = openedOrders.find((order) => order.id == id)?.organizationId
			return selectedOrderAccount == currentOrderAccount
		}
	}, [selectedExpeditionOrderId?.orderId])

	return (
		<div className="d-flex justify-content-between border p-2 pb-3 cursor-pointer" style={{ backgroundColor: selectedExpeditionOrderId?.orderId == id ? '#E3F2FD' : isSameClient ? '#bf94e4' : '' }}>
			<div className={flexColumnClassName}>
				<div>
					<strong>{organizationName}</strong>
					{isSameClient && <IconTooltip key="same_client" icon='bx bxs-user ms-2 text-green' message={t('same-client', { ns: 'naming' })} name='same_client' />}
				</div>

				<div className="d-flex flex-row">
					<p className={defaultPClassName + 'me-1'}>ID: </p>
					<strong>{id}</strong>
				</div>
				{isProductions && arrivalDate && (
					<div className={flexRowClassName}>
						<p className={defaultPClassName}>{t('arrival', { ns: 'naming' }) + ':'}</p>
						<p className={defaultPClassName}>{getDateWithDay(arrivalDate)}</p>
					</div>
				)}

				<div className={flexRowClassName}>
					<p className={defaultPClassName}>{t('shipment', { ns: 'naming' }) + ':'}</p>
					<p className={defaultPClassName}>{getDateWithDay(shipmentDate)}</p>
				</div>
				<p className={defaultPClassName}>{<RemainingDays date={shipmentDate} finalDate={arrivalDate} />}</p>
				<div className="mt-2">
					{disconformity && (
						<span className="bg-red rounded p-1 px-2 text-white">
							<strong>DISCONFORMITY</strong>
						</span>
					)}
				</div>

			</div>
			<div>
				<div className={flexColumnClassName}>

					<span className={flexRowClassName + 'justify-content-end'}>
						{getStatusText(completed, isProductions)}
						<i className={getCompletedClassName(completed, isProductions)}></i>
					</span>

					{isProductions && (
						<a
							href={`https://mydhl.express.dhl/es/en/tracking.html#/results?id=${trackingNumber}&brand=DH`}
						>
							<span className={'justify-content-end ' + flexRowClassName}>
								<strong className="text-nowrap">Tracking Nº:</strong>
								<p className={defaultPClassName + 'ms-1 text-blue'}>{trackingNumber}</p>
							</span>
						</a>
					)}

					{isProductions && (
						<Link to={`supplier-detail/${supplierId}`}>
							<span className={'justify-content-end ' + flexRowClassName}>
								<strong className="text-nowrap">{t('supplier', { ns: 'naming' })}</strong>
								<p className={defaultPClassName + 'ms-1 text-blue'}>{<PartName name={supplier} maxLenght={15} />}</p>
							</span>
						</Link>
					)}

					{!isProductions && <strong className="text-end">{box || t('no_box', { ns: 'naming' })}</strong>}
					<div className="d-flex justify-content-end">
						{receivingCountry !== 'ES' && !isProductions && (
							<span className="bg-yellow rounded p-1 px-2 text-white text-end">
								<strong>UPS</strong>
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		selectedExpeditionOrderId: state.Orders.selectedExpeditionOrderId,
		openedOrders: state.Orders.openedExpeditonOrders,
	}
}

ReceptionExpeditionOrderSummary.propTypes = {
	order: PropTypes.object,
	t: PropTypes.func,
	isProductions: PropTypes.bool,
	selectedExpeditionOrderId: PropTypes.any,
	openedOrders: PropTypes.array,
}

export default connect(mapStateToProps)(
	withTranslation()(ReceptionExpeditionOrderSummary),
)
