export const PGO_COMPANY_NAME = 'Ingeniería de Procesos y Productos I-MAS, S.L.'

export const PGO_ADDRESS_1 = 'C/ Castelló, 19,'

export const PGO_ADDRESS_2 = '08110, Montcada i Reixac, Barcelona (Spain)'

export const PGO_VAT = 'VAT: ES63729495'

export const PGO_PHONE_NUMBER = {
	ES: '(+34) 932 20 71 04',
	EN: '(+44) 20 3318 1736',
	FR: '(+33) 176 44 09 62',
	IT: '(+39) 011 1962 0263',
}

export const footerString = 'Ingeniería de Procesos y Producto, I-MAS S.L. / VAT: ESB63729495 /\nR.M. Barcelona, Tomo 37211, Sección general, Folio 0001, Hoja/Dup. 302906'

export const staticProtoandgoWebsite = 'https://www.protoandgo.com'
