import {
	FORGET_PASSWORD,
	FORGET_PASSWORD_ERROR,
	FORGET_PASSWORD_SUCCESS,
	RESET_FORGET_PASSWORD_EMAIL_STATE,
	RESET_PASSWORD,
	RESET_PASSWORD_ERROR,
	RESET_PASSWORD_SUCCESS,
	VERIFY_TOKEN,
	VERIFY_TOKEN_ERROR,
	VERIFY_TOKEN_SUCCESS,
} from './actionTypes'

export const sendChangePasswordEmail = (user, history) => {
	return {
		type: FORGET_PASSWORD,
		payload: { user, history },
	}
}

export const resetChangePasswordEmailState = () => {
	return {
		type: RESET_FORGET_PASSWORD_EMAIL_STATE,
	}
}

export const resetUserPassword = (email, password, token) => {
	return {
		type: RESET_PASSWORD,
		payload: { email, password, token },
	}
}
export const resetUserPasswordSuccess = message => {
	return {
		type: RESET_PASSWORD_SUCCESS,
		payload: { message },
	}
}
export const resetUserPasswordError = error => {
	return {
		type: RESET_PASSWORD_ERROR,
		payload: { error },
	}
}
export const verifyToken = token => {
	return {
		type: VERIFY_TOKEN,
		payload: { token },
	}
}
export const verifyTokenSuccess = response => {
	return {
		type: VERIFY_TOKEN_SUCCESS,
		payload: { ...response },
	}
}
export const verifyTokenError = response => {
	return {
		type: VERIFY_TOKEN_ERROR,
		payload: { response },
	}
}

export const sendChangePasswordEmailSuccess = message => {
	return {
		type: FORGET_PASSWORD_SUCCESS,
		payload: message,
	}
}

export const sendChangePasswordEmailError = message => {
	return {
		type: FORGET_PASSWORD_ERROR,
		payload: message,
	}
}
