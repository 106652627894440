import { useCouriers } from 'common/Hooks/UseTranslatedCouriers'
import { formatToThousands } from 'common/Utils/FormatToThousands'
import { getDate } from 'common/Utils/StringUtilities'
import Modal from 'components/Common/Modal/Modal'
import { InputNumber } from 'components/InputNumber'
import { ProductionStatus } from 'constants/productionStatus'
import { Tracking } from 'pages/SupplierProduction/Tracking'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Input } from 'reactstrap'

export const ImportsEditModal = (props) => {

	const {
		t,
		isOpen,
		closeModal,
		updateShipmentImport,
		suppliers,
	} = props

	const {
		shipmentImport,
		computedColumns,
	} = props.item || {}

	const {
		transitDays,
		complete,
		shipmentDate,
		arrivalDate,
		courier,
	} = computedColumns || {}

	const supplier = useMemo(() => {
		if (!isOpen) return null
		return suppliers.find(supplier => supplier.account.id == shipmentImport.accountId)
	}, [isOpen, suppliers])

	const { COURIER } = useCouriers(t)

	const [tracking, setTracking] = useState()
	const [shippingInvoice, setShippingInvoice] = useState()
	const [customsInvoice, setCustomsInvoice] = useState()
	const [shippingCost, setShippingCost] = useState()
	const [tariffDuty, setTariffDuty] = useState()
	const [customsDuty, setCustomsDuty] = useState()

	const resetData = () => {
		if (props.item == null) return
		setTracking(shipmentImport?.tracking)
		setShippingInvoice(shipmentImport?.shippingInvoice || '')
		setCustomsInvoice(shipmentImport?.customsInvoice || '')
		setShippingCost(shipmentImport?.shippingCost)
		setTariffDuty(shipmentImport?.tariffDuty)
		setCustomsDuty(shipmentImport?.customsDuty)
	}

	useEffect(() => {
		resetData()
	}, [props.item])

	if (props.item == null) return <React.Fragment />

	return (
		<Modal
			isOpen={isOpen}
			closeModal={closeModal}
			title={t('edit')}
			className="modal-lg"
			body={(
				<div>
					<div className="d-felx flex-column justify-content-start mt-4 mb-4 p-2">
						{/* --------------------------------- Sección: Mostrar campos */}
						<div className="mx-3 mb-2">
							<strong>{t('supplier')}</strong>
							{': '}
							<span>{supplier?.supplierInfo?.officialName}</span>
						</div>
						<div style={{ width: '95%' }} className="ms-3 d-flex text-start justify-content-between">
							<div className="flex-column">
								<Tracking
									trackingId={shipmentImport.tracking}
									courier={shipmentImport?.transporter_id}
									status={ProductionStatus.PICKED_UP_BY_COURIER}
								/>
								<div>
									<strong>{t('courier')}</strong>
									{': '}
									<span>{COURIER[courier]}</span>
								</div>
								<div>
									<strong>{t('totalWeight')}</strong>
									{': '}
									<span>{formatToThousands(shipmentImport.weight)}{' g'}</span>
								</div>
							</div>
							<div className="flex-column">
								<div>
									<strong>{t('shipmentDate')}</strong>
									{': '}
									<span>{getDate(shipmentDate)}</span>
								</div>
								<div>
									<strong>{t('arrivalDate')}</strong>
									{': '}
									<span>{getDate(arrivalDate)}</span>
								</div>
								<div>
									<strong>{t('transitDays')}</strong>
									{': '}
									<span>{transitDays}</span>
								</div>
								<div>
									<strong>{t('shipmentInvoice')}</strong>
									{': '}
									<span>{shipmentImport.shippingInvoice}</span>
								</div>
								<div>
									<strong>{t('customsInvoice')}</strong>
									{': '}
									<span>{shipmentImport.customsInvoice}</span>
								</div>
							</div>
							<div className="flex-column flex-shrink-1">
								<div>
									<strong>{t('shipmentCost')}</strong>
									{': '}
									<span>{formatToThousands(shipmentImport.shippingCost) || '-'}{' €'}</span>
								</div>
								<div>
									<strong>{t('customsDuty')}</strong>
									{': '}
									<span>{formatToThousands(shipmentImport.customsDuty) || '-'}{' €'}</span>
								</div>
								<div>
									<strong>{t('tariffDuty')}</strong>
									{': '}
									<span>{formatToThousands(shipmentImport.tariffDuty) || '-'}{' €'}</span>
								</div>
								<div>
									<strong>{t('totalCost')}</strong>
									{': '}
									<span>{formatToThousands(
										(shipmentImport.shippingCost || 0) +
										(shipmentImport.customsDuty || 0) +
										(shipmentImport.tariffDuty || 0),
									)}{' €'}</span>
								</div>
							</div>
						</div>
						<div style={{ height: '2rem' }} />
						{/* --------------------------------- Sección: Editar campos */}
						<div className="mx-3 d-flex flex-column gap-2">
							{/* --- Numero de seguimiento --- */}
							<div className="d-flex align-items-center">
								<h6 style={{ width: '25%' }}>
									{t('tracking')}
								</h6>
								<Input
									value={tracking}
									onChange={e => setTracking(e.currentTarget.value)}
								/>
							</div>
							{/* --- Factura envío --- */}
							<div className="d-flex align-items-center">
								<h6 style={{ width: '25%' }}>
									{t('shipmentInvoice')}
								</h6>
								<Input
									value={shippingInvoice}
									onChange={e => setShippingInvoice(e.currentTarget.value)}
								/>
							</div>
							{/* --- Factura aduana --- */}
							<div className="d-flex align-items-center">
								<h6 style={{ width: '25%' }}>
									{t('customsInvoice')}
								</h6>
								<Input
									value={customsInvoice}
									onChange={e => setCustomsInvoice(e.currentTarget.value)}
								/>
							</div>
							{/* --- Coste envío --- */}
							<div className="d-flex align-items-center">
								<h6 style={{ width: '25%' }}>
									{t('shipmentCost')}
								</h6>
								<InputNumber
									value={shippingCost}
									setValue={setShippingCost}
								/>
							</div>
							{/* --- Coste aduana --- */}
							<div className="d-flex align-items-center">
								<h6 style={{ width: '25%' }}>
									{t('customsDuty')}
								</h6>
								<InputNumber
									value={customsDuty}
									setValue={setCustomsDuty}
								/>
							</div>
							{/* --- Coste aranceles --- */}
							<div className="d-flex align-items-center">
								<h6 style={{ width: '25%' }}>
									{t('tariffDuty')}
								</h6>
								<InputNumber
									value={tariffDuty}
									setValue={setTariffDuty}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
			buttons={[
				<button
					type="button"
					key="clearButton"
					className="btn btn-danger"
					onClick={e => {
						e.preventDefault()
						resetData()
					}}
				>
					{t('reset')}
				</button>,
				<button
					type="button"
					key="setFiltersButton"
					className="btn btn-primary"
					onClick={e => {
						e.preventDefault()
						updateShipmentImport({
							data: {
								shipmentImport: {
									id: shipmentImport.id,
									tracking,
									shippingInvoice,
									customsInvoice,
									shippingCost,
									tariffDuty,
									customsDuty,
								},
							},
						})
					}}
				>
					{t('save')}
				</button>,
			]}
		/>
	)
}

ImportsEditModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	item: PropTypes.object,
	updateShipmentImport: PropTypes.func,
	suppliers: PropTypes.array,
}
