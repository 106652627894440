import { connect } from 'react-redux'

export const Sandbox = ({ }) => {
	return (
		<div className='p-4'>
			Sandbox
		</div>
	)

}

Sandbox.propTypes = {}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Sandbox)
