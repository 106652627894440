import { getDate, getHour, getStatusClassName, isStringInList } from 'common/Utils/StringUtilities'
import AppRoutes from 'constants/appRoutes'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import MetaTags from 'react-meta-tags'
import { connect } from 'react-redux'
import Alert from '../../components/Common/Alert'
import Loader from '../../components/Common/Loader'
import Table from '../../components/Table/Table'
import {
	clearQuoteError,
	getQuoteList,
	getQuoteListCount,
	getSuppliers,
	setSupplierIdView,
} from '../../store/actions'

import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { addVisualizingSupplierIdFromLocalStorage, removeVisualizingSupplierIdFromLocalStorage } from 'common/Utils/LocalStorageUtilities'
import { mapSuppliers } from 'common/Utils/SuppliersUtils'
import Select from 'components/Common/Select/Select'
import { NoteTooltip } from 'components/NoteTooltip'
import { USER_ROLE_MANAGER, USER_ROLE_QUALITY } from 'constants/userRoles'
import OfferTechnology from 'pages/OfferList/OfferTechnology'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import QuoteListFilters from './QuoteListFilters'

class QuoteList extends Component {

	constructor(props) {
		super(props)
		this.state = {
			pagination: {
				page: 1,
				pageSize: 50,
			},
		}
	}

	sizePerPageRenderer({
		options,
		currSizePerPage,
		onSizePerPageChange,
	}) {
		return (
			<div className="btn-group" role="group">
				<button
					key={this.state.pageSize}
					type="button"
					onClick={() => onSizePerPageChange(options.page)}
					className={'btn btn-secondary width-3rem'}
				>
					{currSizePerPage}
				</button>
				{
					options.map(option =>
						this.sizePerPageOptionRenderer({
							...option,
							onSizePerPageChange,
						}),
					)
				}
			</div>
		)
	}

	sizePerPageOptionRenderer({
		text,
		page,
		onSizePerPageChange,
	}) {
		return (
			<li
				key={text}
				role="presentation"
				className="d-flex align-items-center"
			>
				<a
					href="#"
					tabIndex="-1"
					role="menuitem"
					data-page={page}
					onMouseDown={(e) => {
						e.preventDefault()
						onSizePerPageChange(page)
					}}
					className="btn btn-default btn-primary dropdown-toggle text-white width-3rem"
				>
					{text}
				</a>
			</li>
		)
	}

	handleOnChangePagination(pagination) {
		this.setState({ pagination })
	}

	componentDidMount() {
		removeVisualizingSupplierIdFromLocalStorage()
		if (this.checkRole(USER_ROLE_MANAGER) || this.checkRole(USER_ROLE_QUALITY)) this.props.getSuppliers()
	}

	getStatusClassName(status) {
		if (status === 20) return 'rounded-pill bg-yellow text-white'
		return 'rounded-pill bg-secondary text-white'
	}

	onChangeSupplier(supplierId) {
		addVisualizingSupplierIdFromLocalStorage(supplierId)
		this.props.setSupplierIdView(supplierId)
	}
	checkRole(role) {
		return isStringInList(role, this.props.userRoles)
	}

	render() {
		const { t } = this.props
		const header = {
			id: 'id',
			statusToRender: 'status',
			parts: 'parts',
			technologies: 'technologies',
			message: 'message',
			date: 'date',
		}

		const getItemsToRender = () => {
			if (this.props.quoteList.quotes == null || this.props.quoteList.quotes.length === 0) return []
			return this.props.quoteList.quotes.map(quote => {
				const quoteStatus = `quote_status_${quote.statusId}`
				return {
					id: quote.offerId,
					statusToRender: <div className="w-auto">
						<span className={getStatusClassName(quoteStatus)}>
							{t(quoteStatus, { ns: 'status' })}
						</span>
						{
							quote.urgent && <span className="bg-danger rounded-pill ms-2 text-white">{t('urgent', { ns: 'naming' })}</span>
						}
					</div>,
					parts: quote.partsCount,
					technologies: <OfferTechnology technologies={quote.techTypeIds} partConfigOptions={this.props.partConfigOptions} />,
					message: <NoteTooltip note={quote.comment} t={t} />,
					date: [getDate(quote.dateQuotation), getHour(quote.dateQuotation)],
				}
			})
		}

		const itemsToRender = getItemsToRender()

		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>Proto&Go! | {t('quotes', { ns: 'naming' })}</title>
					</MetaTags>
					<Breadcrumbs
						title={t('suppliers', { ns: 'naming' })}
						breadcrumbItems={[
							{
								item: t('quoteList', { ns: 'naming' }),
								link: '/quote-list',
							},
						]}
					/>

					{(this.checkRole(USER_ROLE_QUALITY) || this.checkRole(USER_ROLE_MANAGER)) &&
						this.props.suppliers &&
						this.props.suppliers.length > 0 && (
							<div className="d-flex mb-4 bg-white my-4 p-4 py-3 ps-2">
								<span className="center-vertically me-3">
									Visualización cómo proveedor
								</span>
								<Select
									placeholder="Selecciona un Proveedor"
									options={mapSuppliers(this.props.suppliers)}
									className="width-by-content "
									onChange={e => this.onChangeSupplier(e.target.value)}
								/>
							</div>
						)}
					{
						this.props.error ? <Alert
							type="danger"
							centered
							fitContent
							message={this.props.error.message}
						/> :
							<div className="p-4 bg-white">
								<QuoteListFilters handleOnChangePagination={this.handleOnChangePagination} page={this.state.pagination.page} pageSize={this.state.pagination.pageSize} />
								{this.props.isLoading || this.props.partConfigOptionsLoading ?
									<Loader className="font-size-80 mx-auto" /> :
									<div>
										{itemsToRender.length > 0 ?
											<Table
												remote
												customFilter
												header={header}
												items={itemsToRender}
												selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
												language={this.props.i18n.language}
												paginationProps={{
													custom: true,
													totalSize: this.props.quoteList.count,
													page: this.state.pagination.page,
													sizePerPage: this.state.pagination.pageSize,
													onPageChange: (page, sizePerPage) => {
														this.handleOnChangePagination({ page, pageSize: sizePerPage })
													},
													onSizePerPageChange: (sizePerPage, page) => {
														this.handleOnChangePagination({ page, pageSize: sizePerPage })
													},
												}}
												styleForFieldList={[
													{
														field: 'date',
														styles: [{ type: 'list', class: 'd-block' }],
													},
													{
														field: 'id',
														styles: [
															{
																type: 'link',
															},
														],
													}, {
														field: 'parts',
														styles: [
															{
																type: 'grayBg',
																class: 'rounded-pill bg-secondary text-white',
															},
														],
													}]}
												pageToNavigate={AppRoutes.QUOTE_DETAIL_ROUTE_NO_ID}
												partConfigOptions={this.props.partConfigOptions}
											/>
											:
											<div className="d-flex justify-content-center p-4">
												<h2> No quotes to show</h2>
											</div>
										}
									</div>

								}
							</div>
					}</div>

			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		quoteList: state.Quotes.quoteList,
		totalRows: state.Quotes.totalRows,
		isLoading: state.Quotes.isLoading,
		supplierIdView: state.Quotes.supplierIdView,
		suppliers: state.Users.suppliers,
		userRoles: state.Login.roles,
		error: state.Quotes.error,
		accountId: state.Login.id,
		partConfigOptionsLoading: state.Parts.partConfigOptionsLoading,
	}
}

QuoteList.propTypes = {
	quoteList: PropTypes.object,
	isLoading: PropTypes.bool,
	getQuoteList: PropTypes.func,
	getQuoteListCount: PropTypes.func,
	error: PropTypes.any,
	t: PropTypes.func,
	i18n: PropTypes.func,
	getSuppliers: PropTypes.func,
	suppliers: PropTypes.array,
	userRoles: PropTypes.arrayOf(PropTypes.string),
	supplierIdView: PropTypes.number,
	setSupplierIdView: PropTypes.func,
	clearQuoteError: PropTypes.func,
	accountId: PropTypes.number,
	partConfigOptionsLoading: PropTypes.bool,
}

export default withGetPartConfigOptions(connect(mapStateToProps, {
	getQuoteList,
	getQuoteListCount,
	getSuppliers,
	setSupplierIdView,
	clearQuoteError,
})(withTranslation()(QuoteList)))
