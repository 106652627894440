import Modal from 'components/Common/Modal/Modal'
import { getPdfBlob } from 'components/Common/PrintableContent/GetPdfBlob'
import PrintableContent from 'components/Common/PrintableContent/PrintableContent'
import { htmlToPdfDeliveryNote } from 'components/PdfTemplates/DeliveryNotePDFTemplate'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { saveGeneratedPdf } from 'store/actions'

import { downloadPartListImagesInBase64, uploadOrderShipmentFiles } from 'store/actions'

export const ModalPrintDeliveryNote = ({
	t,
	i18n: { language },
	saveGeneratedPdf,
	isSavingPdfInS3,
	isOpenDeliveryNoteModal,
	setSaveDeliveryNoteInS3,
	setIsOpenDeliveryNoteModal,
	orderInfo,
	imagesInBase64,
	downloadPartListImagesInBase64,
	partConfigOptions,
}) => {

	const width = 210
	const height = 297
	const orientation = 'p'
	const id = 'deliveryExpeditionNotePrint'
	const filename = 'DeliveryNote_' + (orderInfo?.id)

	const options = {
		height,
		width,
		printPageCounter: true,
		printDocumentFooter: true,
		jsPDF: {
			format: [width, height],
			unit: 'mm',
			orientation,
			hotfixes: ['px_scaling'],
		},
		margin: 0,
		html2canvas: { scale: 8 },
		filename: filename,
	}

	useEffect(() => {
		if (orderInfo != null && (isOpenDeliveryNoteModal == true || isSavingPdfInS3 == true)) {
			const orderItemsToPrint = orderInfo.orderItems
			const data = orderItemsToPrint.map((item) => ({
				id: item.part.id,
				imageUrl: item.part.imageUrl,
			}))
			downloadPartListImagesInBase64(data)
		}
	}, [isOpenDeliveryNoteModal, isSavingPdfInS3])


	const [newScannedQuantities, setNewScannedQuantities] = useState([])
	useEffect(() => {
		setNewScannedQuantities(orderInfo?.orderItems?.map(item => ({
			id: item.id,
			quantity: item.scannedQuantity,
		})))
	}, [orderInfo])

	const generateHtmlToPrint = () => {
		if (imagesInBase64 == null || imagesInBase64.length == 0) return null
		const productsGroup = orderInfo.orderItems.filter((item, i) => newScannedQuantities[i]?.quantity > 0).map((item, i) => {
			const imageBase64 = imagesInBase64.find((image) => image.partId == item.part.id)?.imageBase64
			const newScannedQuantity = newScannedQuantities.find(x => x.id == item.id).quantity
			return {
				...item,
				quantity: newScannedQuantity,
				part: {
					...item.part,
					files: {
						...item.part.files,
						imageBase64,
					},
				},
			}
		})
		return htmlToPdfDeliveryNote(productsGroup, { customerOrderNumber: { id: orderInfo.customerOrderNumber }, order: { ...orderInfo } }, t, partConfigOptions, language)
	}

	const deliveryNoteHtmlToPrint = useMemo(() => generateHtmlToPrint(), [imagesInBase64, newScannedQuantities])

	useEffect(() => {
		if (deliveryNoteHtmlToPrint != null && isSavingPdfInS3 === true) {
			getPdfBlobPromise(id, options)
		}
	}, [deliveryNoteHtmlToPrint])

	const getPdfBlobPromise = async (id, options) => {
		saveGeneratedPdf({ pdf: await getPdfBlob(id, options), filename: filename + '.pdf' })
		setSaveDeliveryNoteInS3(false)
	}


	return (
		<div>
			<div>
				<div id={id} className='d-none'>
					{deliveryNoteHtmlToPrint}
				</div>
			</div>

			<Modal
				title={t('printDeliveryNote', { ns: 'naming' })}
				size="xl"
				closeButtonText={t('close', { ns: 'naming' })}
				closeButtonColor="secondary"
				isOpen={isOpenDeliveryNoteModal}
				closeModal={() => setIsOpenDeliveryNoteModal(false)}
				body={
					<table className="m-5 w-100">
						<tr>
							<th></th>
							<th>{t('item', { ns: 'naming' })}</th>
							<th>{t('name', { ns: 'naming' })}</th>
							<th>{t('pending', { ns: 'naming' })}</th>
							<th>{t('current_shipment', { ns: 'naming' })}</th>
						</tr>
						{orderInfo?.orderItems?.map((item, index) => {
							const shippedQuantity = item.shippedQuantity
							const elementQuantity = item.quantity
							return (
								<tr key={index}>
									<td>{<img src={item.part?.imageUrl} style={{ width: '50px', height: '50px', objectFit: 'contain' }} />}</td>
									<td>{index}</td>
									<td>{item.part?.name}</td>
									<td>{(elementQuantity - shippedQuantity)}</td>
									<td>
										<input
											type="number"
											defaultValue={item.scannedQuantity}
											min={0}
											max={elementQuantity - shippedQuantity}
											className='w-50'
											onChange={e => {
												if (e.currentTarget.valueAsNumber < 0) {
													e.currentTarget.valueAsNumber = 0
												}
												else if (e.currentTarget.valueAsNumber > elementQuantity - shippedQuantity) {
													e.currentTarget.valueAsNumber = elementQuantity - shippedQuantity
												}
												const index = newScannedQuantities.findIndex(x => x.id == item.id)
												const newValue = [...newScannedQuantities]
												newValue[index].quantity = e.currentTarget.value == '' ? 0 : e.currentTarget.valueAsNumber
												setNewScannedQuantities(newValue)
											}}
											onBlur={e => {
												if (e.currentTarget.value == '') {
													e.currentTarget.value = 0
												}
											}}
										/>
									</td>
								</tr>
							)
						},
						)}
					</table>
				}
				buttons={[
					<PrintableContent
						key={'deliveryExpeditionNotePrints'}
						id={'deliveryExpeditionNotePrint'} //required
						width={210} //Width page size
						height={297} //Height page size
						orientation="p" //Orientation. "l" = landscape. "p" = portrait
						printDocumentFooter
						printPageCounter
						isPdfReadyToBeSaved={true}
						isHidingContent//use it to hide content on webBrowser
						filename={'DeliveryNote_' + (orderInfo?.id)} //Filename
						buttonClassName={orderInfo?.orderItems?.some((item) => item.part.scannedQuantity != 0) ? 'btn btn-primary' : 'btn btn-primary disabled'}
						isButtonDisabled={deliveryNoteHtmlToPrint == null}
						openInNewTab={true}
						buttonContent={
							<div>
								{t('print', { ns: 'naming' })}
							</div>
						}
					>
						{deliveryNoteHtmlToPrint}
					</PrintableContent>,
				]}
			/>
		</div>
	)
}

ModalPrintDeliveryNote.propTypes = {
	isOpenDeliveryNoteModal: PropTypes.bool,
	orderInfo: PropTypes.object,
	setIsOpenDeliveryNoteModal: PropTypes.func,
	imagesInBase64: PropTypes.array,
	t: PropTypes.func,
	downloadPartListImagesInBase64: PropTypes.func,
	isSavingPdfInS3: PropTypes.func,
	uploadOrderShipmentFiles: PropTypes.func,
	setSaveDeliveryNoteInS3: PropTypes.func,
	saveGeneratedPdf: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

const mapStateToProps = (state) => ({
	imagesInBase64: state.CloudStorage.imagesInBase64,
	partConfigOptions: state.Parts.partConfigOptions,
})

const mapDispatchToProps = { downloadPartListImagesInBase64, uploadOrderShipmentFiles, saveGeneratedPdf }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ModalPrintDeliveryNote))
