import { PartConfigTranslations } from 'common/Utils/PartConfigTranslations'
import { getDate } from 'common/Utils/StringUtilities'
import logo from '../../assets/images/brands/protoAndGoLogoDark.png'

export function htmlToPdfDeliveryNote(products, orderInfo, t, partConfigOptions, language) {
	const ITEMS_ON_FIRST_PAGE = 5
	const ITEMS_PER_PAGE = 11
	const FOOTER_SIZE_AS_ITEM = 2
	const pages = []
	const header = (<div>
		<div className="row" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
			<div
				className="col"
				style={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					width: '40%',
					height: '100%',
				}}
			>
				<img
					src={logo}
					alt="DeliveryNote"
					width="250px"
					style={{ alignSelf: 'flex-start', paddingTop: '3%' }}
				/>
			</div>

			<div className="col" style={{ marginTop: '2%', marginLeft: '5%' }}>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div
						style={{
							height: '155px',
							border: '1px solid lightgray',
							borderLeft: '0px',
							marginRight: '15px',
							alignSelf: 'center',
						}}
					/>
					<div style={{ flexDirection: 'column' }}>
						<p style={{ color: '#282828' }}>
							<strong>Ingeniería de Procesos y Producto, I-MAS S.L.</strong>
						</p>
						<p style={{ color: 'dimgray' }}>
							C/Castelló, 19,<br />08110 - Montcada i Reixac (Barcelona)
						</p>
						<p style={{ color: 'dimgray' }}>VAT: ES63729495</p>
						<p style={{ color: 'dimgray' }}>
							ES: <strong style={{ color: '#282828' }}>(+34) 932 20 71 04</strong>&nbsp;&nbsp;
							EN: <strong style={{ color: '#282828' }}>(+44) 20 3318 1736</strong>
							<br />
							FR: <strong style={{ color: '#282828' }}>(+33) 176 44 09 62</strong>
							&nbsp;&nbsp; IT: <strong style={{ color: '#282828' }}>(+39) 011 1962 0263</strong>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div
			style={{
				width: 'fit-content',
				height: '40px',
				backgroundColor: '#F08C01',
				display: 'flex',
			}}
		>
			<div
				style={{
					color: 'white',
					fontSize: '24px',
					paddingLeft: '2em',
					paddingRight: '2em',
				}}
			>
				Delivery note
			</div>
		</div>

		<div
			style={{
				width: 100 + '%',
				minHeight: 15 + '%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				flexDirection: 'row',
				marginTop: 2 + '%',
			}}>
			<div style={{ height: '100%', width: '50%' }}>
				<table style={{ borderCollapse: 'collapse', width: '100%' }}>
					<tr>
						<th style={{
							border: '1px solid #F08C01',
							borderRight: '0px',
							padding: '8px',
							textAlign: 'center',
							color: 'dimgray',
						}}
						>
							Series
						</th>
						<th style={{
							border: '1px solid #F08C01',
							borderRight: '0px',
							padding: '8px',
							textAlign: 'center',
							color: 'dimgray',
						}}
						>
							Number
						</th>
						<th style={{
							border: '1px solid #F08C01',
							padding: '8px',
							textAlign: 'center',
							color: 'dimgray',
						}}>
							Date
						</th>
					</tr>
					<tr>
						<td
							style={{
								borderBottom: '1px solid #F08C01',
								borderLeft: '1px solid #F08C01',
								padding: '8px',
								color: 'dimgray',
								textAlign: 'center',
							}}
						>
							PG
						</td>
						<td style={{
							borderBottom: '1px solid #F08C01',
							borderLeft: '1px solid #F08C01',
							padding: '8px',
							color: 'dimgray',
							textAlign: 'center',
						}}>
							{orderInfo?.order?.creationDate.slice(2, 4)}{orderInfo?.order?.id.toString().slice(orderInfo?.order?.id.toString().length - 4)}
						</td>
						<td style={{
							borderBottom: '1px solid #F08C01',
							borderLeft: '1px solid #F08C01',
							borderRight: '1px solid #F08C01',
							padding: '8px',
							color: 'dimgray',
							textAlign: 'center',
						}}>
							{getDate(new Date())}
						</td>
					</tr>
				</table>

				<div style={{
					width: '20%',
					borderBottom: '1px solid #F08C01',
					marginTop: '5%',
					marginBottom: '5%',
				}} />
				<table style={{ borderCollapse: 'collapse', width: '100%' }}>
					<tr>
						<th style={{
							border: '1px solid #F08C01',
							borderRight: '0px',
							padding: '8px',
							textAlign: 'center',
							color: 'dimgray',
						}}
						>
							Order number
						</th>
						<th style={{
							border: '1px solid #F08C01',
							padding: '8px',
							textAlign: 'center',
							color: 'dimgray',
						}}>
							Client ref
						</th>
					</tr>
					<td style={{
						borderBottom: '1px solid #F08C01',
						borderLeft: '1px solid #F08C01',
						padding: '8px',
						color: 'dimgray',
						textAlign: 'center',
					}}>
						#{orderInfo?.order?.id}
					</td>
					<td style={{
						borderBottom: '1px solid #F08C01',
						borderLeft: '1px solid #F08C01',
						borderRight: '1px solid #F08C01',
						padding: '8px',
						color: 'dimgray',
						textAlign: 'center',
					}}>
						{orderInfo?.order?.customerOrderNumber}
					</td>
				</table>
			</div>
			<div style={{
				height: '100%',
				width: '50%',
				marginLeft: '50px',
				textAlign: 'right',
			}}
			>
				<h4 style={{
					color: 'dimgray',
					margin: '0',
					marginBottom: '5px',
					marginTop: '-4%',
					textAlign: 'left',
				}}>
					Shipment address
				</h4>
				<div style={{ width: '100%', alignItems: 'center', border: '1px solid #F08C01' }}>
					<p style={{
						paddingLeft: '5%',
						paddingRight: '5%',
						paddingTop: '5%',
						marginBottom: '0',
						lineHeight: '2',
						color: '#282828',
						fontSize: '17px',
					}}>
						<strong>{orderInfo?.order?.shippingAddress?.company}</strong>
					</p>
					<p style={{
						paddingLeft: '5%',
						paddingRight: '5%',
						paddingTop: '0%',
						marginBottom: '0',
						lineHeight: '2',
						color: '#282828',
						fontSize: '13px',
					}}>
						<strong>{`${orderInfo?.order?.shippingAddress?.firstName} ${orderInfo?.order?.shippingAddress?.lastName}`}</strong>
					</p>
					<p style={{
						paddingLeft: '5%',
						paddingRight: '5%',
						marginBottom: '0',
						lineHeight: '2',
						fontSize: '13px',
					}}>
						{orderInfo?.order?.shippingAddress?.address1}, {orderInfo?.order?.shippingAddress?.postalCode}, {orderInfo?.order?.shippingAddress?.city}
					</p>
					<p style={{
						paddingLeft: '5%',
						paddingRight: '5%',
						marginBottom: '0',
						lineHeight: '2',
						fontSize: '13px',
					}}>
						{orderInfo?.order?.shippingAddress?.state}({orderInfo?.order?.shippingAddress?.country})
					</p>
					<p style={{
						paddingLeft: '5%',
						paddingRight: '5%',
						paddingBottom: '5%',
						lineHeight: '3',
						fontSize: '13px',
					}}>
						Tel. {orderInfo?.order?.shippingAddress?.phonePrefix} {orderInfo?.order?.shippingAddress?.phoneNumber?.slice(0, 2)} {orderInfo?.order?.shippingAddress?.phoneNumber?.slice(2, 5)} {orderInfo?.order?.shippingAddress?.phoneNumber?.slice(5, 7)} {orderInfo?.order?.shippingAddress?.phoneNumber?.slice(7)}
					</p>
				</div>
			</div>
		</div>
	</div>
	)

	const genearateProductTable = (products) => {
		return (
			<table style={{
				borderCollapse: 'separate',
				borderSpacing: '0 10px',
				width: '100%',
				marginTop: '2%',
				tableLayout: 'fixed',
			}}>

				<tr>
					<th style={{
						border: '1px solid #F08C01',
						borderRight: '0px',
						padding: '8px',
						textAlign: 'left',
						color: 'dimgray',
						width: '40%',
					}}>
						Product
					</th><th style={{
						border: '1px solid #F08C01',
						borderRight: '0px',
						padding: '8px',
						textAlign: 'center',
						color: 'dimgray',
						width: '20%',
					}}>
						Description
					</th>
					<th style={{
						border: '1px solid #F08C01',
						borderRight: '0px',
						padding: '8px',
						textAlign: 'center',
						color: 'dimgray',
						width: '30%',
					}}>
						Drawing
					</th>
					<th style={{
						border: '1px solid #F08C01',
						padding: '8px',
						textAlign: 'center',
						color: 'dimgray',
						width: '10%',
					}}>
						Quantity
					</th>
				</tr>

				{products.map((product, i) => {
					return <tr key={i}>
						<td style={{
							border: '1px solid lightgray',
							borderRight: '0px',
							padding: '8px',
							color: 'dimgray',
							textAlign: 'left',
						}}>
							<img
								src={product?.part?.files?.imageBase64}
								alt="Product Image"
								style={{
									float: 'left',
									marginRight: '10px',
									width: '50px',
									height: '40px',
									objectFit: 'contain',
								}}
							/>
							<div style={{
								maxWidth: '100%',
								marginLeft: '10px',
								height: '40px',
								overflow: 'hidden',
								wordWrap: 'break-word',

							}}>

								<strong>{product?.part?.name}</strong>

							</div>
						</td>
						<td style={{
							border: '1px solid lightgray',
							borderRight: '0px',
							padding: '8px',
							color: 'dimgray',
							textAlign: 'left',
						}}>
							{product?.part?.material?.id && (
								<p style={{ fontSize: '12px', marginBottom: '0px' }}>
									{PartConfigTranslations.getMaterialName({ part: product.part, partConfigOptions, language })}
								</p>
							)}

							{product?.part?.configuration?.alloyId && (
								<p style={{ fontSize: '12px', marginBottom: '0px' }}>
									{PartConfigTranslations.getAlloyName({ part: product.part, partConfigOptions, language })}
								</p>
							)}

							{product?.part?.configuration?.finishId && (
								<p style={{ fontSize: '12px', marginBottom: '0px' }}>
									{PartConfigTranslations.getFinishName({ part: product.part, partConfigOptions, language })}
								</p>
							)}

							{product?.part?.configuration?.colorId && (
								<p style={{ fontSize: '12px', marginBottom: '0px' }}>
									{PartConfigTranslations.getColorName({ part: product.part, partConfigOptions, language })}
								</p>
							)}

							{product?.part?.configuration?.colorFinishId && (
								<p style={{ fontSize: '12px', marginBottom: '0px' }}>
									{PartConfigTranslations.getColorFinishName({ part: product.part, partConfigOptions, language })}
								</p>
							)}

							<p style={{ fontSize: '12px', marginBottom: '0px' }}>
								{PartConfigTranslations.getHardnessName({ part: product.part, partConfigOptions })}
							</p>

						</td>

						<td style={{
							border: '1px solid lightgray',
							borderRight: '0px',
							padding: '8px',
							color: 'dimgray',
							textAlign: 'center',

						}}>
							<div style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								alignItems: 'center',
								height: '40px',
								overflow: 'hidden',
								wordWrap: 'break-word',

							}}>

								<strong>{product?.part?.blueprints[0]?.name || 'N/A'}</strong>

							</div>

						</td>
						<td style={{
							border: '1px solid lightgray',
							padding: '8px',
							color: 'dimgray',
							textAlign: 'center',
							width: '100px',
						}}>
							<span id={`quantity_${i}`}>{product?.quantity}</span>
						</td>
					</tr>
				})}
			</table>
		)
	}
	const footer = (
		<div style={{ marginTop: '7%', marginBottom: '15%' }}>
			<p style={{ fontSize: '10px', lineHeight: '0' }}><strong>CERTIFICATE OF CONFORMITY</strong></p>
			<p style={{ fontSize: '10px', textAlign: 'justify' }}>
				Proto&Go! certifies that the aforementioned products have been manufactured in compliance with the technical specifications provided
				with the order and / or contract and that all quality controls, such as dimensional control of parts, treatments carried out and materials
				used, correspond to the requirements. required by the plans, standards and specifications applicable and in force.
			</p>
			<p style={{ fontSize: '10px', lineHeight: '0', marginTop: '2%' }}><strong>Jesús Fernandéz</strong></p>
			<p style={{ fontSize: '10px' }}>Quality Manager</p>
		</div>
	)

	let isFooterPrintedOnLastPage = false

	const firstPage = <div>
		{header}
		{genearateProductTable(products.slice(0, ITEMS_ON_FIRST_PAGE))}
		{products.length <= ITEMS_ON_FIRST_PAGE - FOOTER_SIZE_AS_ITEM ? footer : null}
	</div>
	if (products.length <= ITEMS_ON_FIRST_PAGE - FOOTER_SIZE_AS_ITEM) {
		isFooterPrintedOnLastPage = true
	}
	pages.push(firstPage)

	for (let i = ITEMS_ON_FIRST_PAGE; i < products.length; i++) {

		if ((i - ITEMS_ON_FIRST_PAGE) % ITEMS_PER_PAGE === 0) {
			const slicedProducts = products.slice(i, i + ITEMS_PER_PAGE)
			const table = genearateProductTable(slicedProducts)
			const page = <div>
				{table}
				{slicedProducts.length <= ITEMS_PER_PAGE - FOOTER_SIZE_AS_ITEM ? footer : null}
			</div>
			if (slicedProducts.length <= ITEMS_PER_PAGE - FOOTER_SIZE_AS_ITEM) {
				isFooterPrintedOnLastPage = true
			}
			pages.push(page)
		}
	}
	if (!isFooterPrintedOnLastPage)
		pages.push(footer)

	const pagesRendered = pages.map((page, i) => {
		return (
			<div key={i} className="page" style={{
				width: 'calc(210mm - 4em)',
				height: 'calc(297mm - 2em)',
				maxWidth: '210mm',
				maxHeight: '297mm',
				marginLeft: '2em',
				marginRight: '2em',
			}}>
				{page}
			</div>
		)
	})
	return pagesRendered
}
