import PropTypes from 'prop-types'
import { Component } from 'react'

class Select extends Component {
	render() {
		return (
			<div className={this.props.className} style={this.props.style}>
				<select
					required
					id={this.props.placeholder + ' Select'}
					className={`form-select ${this.props.selectClassName}`}
					onChange={e => this.props.onChange(e)}
					value={this.props.value || ''}
					placeholder={this.props.placeholder}
				>
					<option value="" disabled selected hidden>
						{this.props.placeholder}
					</option>
					{this.props.options.map(option => (
						<option
							key={option.value}
							value={option.value}
							disabled={option.isDisabled}
						>
							{option.label}
						</option>
					))}
				</select>
			</div>
		)
	}
}
Select.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
			isDisabled: PropTypes.bool,
		}),
	),
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	className: PropTypes.string,
	selectClassName: PropTypes.string,
}

export default Select
