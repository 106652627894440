import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import Select from 'components/Common/Select/Select'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addScannedPieceToExpeditionOrder, scanOrderExpeditionPart } from 'store/actions'

const useSimulateScanModalForExpedition = () => {

	const { t } = useTranslation('naming')
	const scanOrderExpeditionPart_Status = useSelector(state => state.Orders.scanOrderExpeditionPart_Status)
	const dispatch = useDispatch()

	const [orderItem, setOrderItem] = useState(null)
	const [isOpen, setIsOpen] = useState(false)
	const [quantitySelected, setQuantitySelected] = useState(1)

	useEffect(() => {
		if (!isOpen) return
		if (scanOrderExpeditionPart_Status.loading == false) {
			if (scanOrderExpeditionPart_Status.success == true) {
				// EXITO: Actualiza el front para no tener que recargar la página para ver los cambios:
				setIsOpen(false)
				dispatch(addScannedPieceToExpeditionOrder(orderItem.partId, orderItem.orderId, quantitySelected))
			} else if (scanOrderExpeditionPart_Status.error != null) {
				// ERROR: Muestra un mensaje de error:
				alert(t('error_message'))
			}
		}
	}, [scanOrderExpeditionPart_Status.loading])

	const quantityOptions = useMemo(() => {
		// Las opciones del selector serán las posibles cantidades a escanear (del 1 al numero pendiente que toque):
		if (orderItem == null) return []
		const shippedQuantity = orderItem.shippedQuantity
		const elementQuantity = orderItem.quantity
		const pendingQuantity = elementQuantity - shippedQuantity >= 0 ? elementQuantity - shippedQuantity : 0
		return Array.from({
			length: pendingQuantity - orderItem?.scannedQuantity,
		})?.map((_, i) => ({
			label: i + 1, value: i + 1,
		}))
	}, [orderItem])

	const SimulateScanModalForExpedition = useMemo(() => (
		<Modal
			isOpen={isOpen}
			closeModal={() => setIsOpen(false)}
			title={t('scanPart')}
			closeButtonColor="secondary"
			body={
				<div className="d-flex mt-5 mb-5 mx-auto align-items-center gap-2">
					{t('quantity')}{':'}
					<br />
					<Select
						style={{ width: '8em' }}
						value={quantitySelected}
						onChange={e => setQuantitySelected(Number(e.currentTarget.value))}
						options={quantityOptions}
						classNamePrefix="select2-selection"
					/>
				</div>
			}
			buttons={[(
				<button
					key={'saveScannedQuantityButton'}
					className='btn btn-primary'
					onClick={() => {
						dispatch(scanOrderExpeditionPart({
							data: {
								orderId: orderItem.orderId,
								partId: orderItem.part.id,
								scannedQuantity: quantitySelected,
							},
						}))
					}}
					disabled={scanOrderExpeditionPart_Status.loading}
				>
					{scanOrderExpeditionPart_Status.loading ? (
						<div className="d-flex justify-content-center align-items-center">
							<Loader />
						</div>
					) : t('save')}
				</button>
			)]}
		/>
	), [
		isOpen,
		orderItem,
		quantityOptions,
		quantitySelected,
	])

	return {
		openSimulateScanModal: (orderItem) => {
			setIsOpen(true)
			setOrderItem(orderItem)
		},
		SimulateScanModalForExpedition,
	}
}

export default useSimulateScanModalForExpedition
