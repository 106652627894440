import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useHandleSearchOrderItems = (orderId, getOrderItemByOrderIdSearch) => {
	const [orderItems, setOrderItems] = useState([])
	const { searchedOrderItems } = useSelector((state) => state.Orders)
	const dispatch = useDispatch()

	useEffect(() => {
		if (orderId) {
			dispatch(getOrderItemByOrderIdSearch(orderId))
		}
	}, [orderId])

	useEffect(() => {
		setOrderItems(searchedOrderItems)
	}, [searchedOrderItems])

	return {
		orderItems,
	}
}
