import countryColors from 'constants/countryColors'
import { countries } from 'locales/es'

import CountryFlag from 'pages/CRMUserDetail/ComponenteTablas/CountryFlag'


export const getCountryCodeByCountryName = countryName => {
	if (countryName != null) {
		const countryArray = Object.entries(countries)
		for (let i = 0; i < countryArray.length; i++) {
			if (countryName && countryArray[i][1] && countryName?.toLowerCase() === countryArray[i][1]?.toLocaleLowerCase()) {
				return countryArray[i][0]?.toLocaleLowerCase()
			}
		}
		return countryName?.toLocaleLowerCase()
	}
	return ''
}

export const getCountryNameByCountryCode = countryCode => {
	if (countryCode == null) return ''
	return countries[countryCode.toLowerCase()] || ''
}

export const getCountryOptions = () => {
	const countryArray = Object.entries(countries)
	return countryArray.map((country) => {
		return {
			label: <CountryFlag country={country[0]} />,
			value: country[0],
		}
	})
}

export const getCountryLabelValue = (countryList) => {
	if (countryList == null || countryList.length === 0) return []
	return (countryList.filter(country => country != null && country != '').map((country, index) => {
		return {
			label: <CountryFlag country={country} />,
			value: country,
		}
	}))
}

export const getCountryColorByCountryCode = (countryCode) => {
	const randomColors = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6']
	const colorIndex = Math.floor(Math.random() * randomColors.length)
	return countryColors[countryCode?.toUpperCase()] || randomColors[colorIndex]
}
