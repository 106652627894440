import PropTypes from 'prop-types'
import React, { Component } from 'react'

class Alert extends Component {
	render() {
		const { type, centered, fitContent, message } = this.props
		return (
			<React.Fragment>
				<div
					className={`alert alert-${type} fade show ${centered && 'mx-auto'} ${fitContent && 'width-by-content'
						}`}
					role="alert"
				>
					{message}
				</div>
			</React.Fragment>
		)
	}
}
Alert.propTypes = {
	message: PropTypes.string,
	centered: PropTypes.bool,
	fitContent: PropTypes.bool,
	type: PropTypes.oneOf([
		'primary',
		'secondary',
		'success',
		'danger',
		'warning',
		'info',
		'light',
		'dark',
	]),
}

export default Alert
