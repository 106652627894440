import { InputNumber } from 'components/InputNumber'
import PropTypes from 'prop-types'

export const InputMinMaxNumbers = ({ t, name, range, setRange }) => {
	const isInvalid = range && range.moreThan && range.lessThan && range.moreThan > range.lessThan
	return (
		<div className='m-2'>
			<h6>{name}</h6>
			<div className="d-flex gap-3 align-items-center">
				{t('date_from')}
				<InputNumber
					value={range?.moreThan}
					setValue={(v) => setRange({ ...range, moreThan: v })}
					isInvalid={isInvalid}
				/>
				{t('date_to')}
				<InputNumber
					value={range?.lessThan}
					setValue={(v) => setRange({ ...range, lessThan: v })}
					isInvalid={isInvalid}
				/>
			</div>
		</div>
	)
}

InputMinMaxNumbers.propTypes = {
	t: PropTypes.func,
	name: PropTypes.string,
	range: PropTypes.object,
	setRange: PropTypes.func,
}
