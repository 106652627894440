import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'

class LeadershipContent extends Component {
	constructor(props) {
		super(props)
		this.refDiv = React.createRef()
	}

	render() {
		const { t } = this.props

		return (
			<React.Fragment>
				<li>
					<Link to="/#" className="has-arrow">
						<i className="bx bxs-flag-alt"></i>
						<span>{t('leadership', { ns: 'naming' })}</span>
					</Link>
					<ul className="sub-menu" aria-expanded="false">
						<li>
							<Link to="/sales-goals">
								{t('saleObjectives', { ns: 'naming' })}
							</Link>
						</li>
						<li>
							<Link to="/prices">{t('prices', { ns: 'naming' })}</Link>
						</li>
					</ul>
				</li>
			</React.Fragment>
		)
	}
}

LeadershipContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
	type: PropTypes.string,
	initMenu: PropTypes.func,
}

export default withRouter(withTranslation()(LeadershipContent))
