import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'

const ProductionComment = ({ t, clientComment }) => {
	return (
		<div className="mt-4 bg-white p-2 border px-3">
			<Row>
				<Col className="col-12">
					<h3 className="mt-2">{t('supplierNote', { ns: 'naming' })} {clientComment && <i className="bx bxs-error"></i>}</h3>
					{clientComment ? <p className="mb-2">{clientComment}</p> : <i className="mb-2">{t('no-comments', { ns: 'naming' })}</i>}
				</Col>
			</Row>
		</div>
	)
}

ProductionComment.propTypes = {
	t: PropTypes.func,
	clientComment: PropTypes.string,
}

export default withTranslation()(ProductionComment)
