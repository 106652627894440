import PropTypes from 'prop-types'
import React, { Component } from 'react'
import ReactApexChart from 'react-apexcharts'
class Barchart extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<React.Fragment>
				<ReactApexChart
					options={{
						chart: {
							toolbar: {
								show: false,
							},
						},
						plotOptions: {
							bar: {
								horizontal: true,
							},
						},
						dataLabels: {
							enabled: false,
						},

						colors: [(this.props.color || '#FF8300')],
						grid: {
							borderColor: '#f1f1f1',
						},
						xaxis: {
							categories: this.props.labels,
						},
					}}
					series={[
						{
							name: this.props.tooltipLabel,
							data: this.props.values,
						},
					]}
					type="bar"
					height='650'
					className="apex-charts"
				/>
			</React.Fragment>
		)
	}
}

export default Barchart
Barchart.propTypes = {
	labels: PropTypes.array,
	values: PropTypes.array,
	tooltipLabel: PropTypes.string,
}
