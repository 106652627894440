import * as constructionType from './partConfig/construction_type.json'
import * as naming from './naming.json'
import * as errors from './errors.json'
import * as countries from './countries.json'
import * as status from './status.json'
import * as process from './partConfig/process.json'
import * as paymentMethods from './payment_methods.json'
import * as activitySectors from './activity_sectors.json'
import * as userRoles from './user_roles.json'
import * as languages from './languages.json'
import * as postponedPaymentType from './postponed_payment_type.json'
import * as quality from './quality.json'

export {
  constructionType,
  naming,
  errors,
  countries,
  status,
  process,
  paymentMethods,
  activitySectors,
  userRoles,
  languages,
  postponedPaymentType,
  quality,
}
