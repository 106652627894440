import PropTypes from 'prop-types'
import React, { Component } from 'react'
import MetaTags from 'react-meta-tags'
import { connect } from 'react-redux'
import Alert from '../../components/Common/Alert'
import Loader from '../../components/Common/Loader'
import Table from '../../components/Table/Table'
import { getFilteredOffers, getManagers, getOfferList } from '../../store/actions'

import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { findManager } from 'common/Utils/ManagersUtils'
import { isObjectEmpty } from 'common/Utils/ObjectUtils'
import PartsFinder from 'components/Common/PartsFinder'
import { NoteTooltip } from 'components/NoteTooltip'
import appRoutes from 'constants/appRoutes'
import { STATUS_COLOR } from 'constants/offerStatus'
import { withTranslation } from 'react-i18next'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import OfferCustomer from './OfferCustomer'
import OfferTechnology from './OfferTechnology'
import OffersFilterModal from './OffersFilterModal'
import { PricesSeenAlert } from './PricesSeenAlert'

const OFFER_TABLE_HEADER = {
	id: 'Id',
	searchableId: 'searchableId',
	statusToRender: 'Estado',
	parts: 'Piezas',
	technologies: 'Tecnologías',
	message: 'Mensaje',
	customer: 'Cliente',
	manager: 'Manager',
	country: 'País',
	language: 'Idioma',
	date: 'Fecha',
}

const PAGE_SIZE_LIST = [
	{ value: 50, text: '50' },
	{ value: 100, text: '100' },
	{ value: 200, text: '200' },
	{ value: 500, text: '500' },
	{ value: 1000, text: '1000' },
]

let controller = new AbortController()
let timeoutId = null
class OfferList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			translatedOffers: [],
			isTranslating: false,
			language: '',
			pageSize: PAGE_SIZE_LIST[0].value,
			currentPage: 1,
			keyword: '',
			activeFilters: {},
		}

		this.handlePageChange = this.handlePageChange.bind(this)
		this.sizePerPageOptionRenderer = this.sizePerPageOptionRenderer.bind(this)
		this.sizePerPageRenderer = this.sizePerPageRenderer.bind(this)
		this.handleOnChangeKeyword = this.handleOnChangeKeyword.bind(this)
		this.handleOnChangeFilters = this.handleOnChangeFilters.bind(this)
	}

	isSomeFilterActive() {
		return Object.values(this.state.activeFilters).some(filter => filter.length > 0 || !isObjectEmpty(filter))
	}

	handleOnChangeFilters(value) {
		this.setState({
			...this.state,
			activeFilters: value,
		})
	}

	componentDidMount() {
		this.handleGetOffers()
		this.props.getManagers()
		this.setState({
			...this.state,
			language: this.props.i18n.language,
		})


	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.i18n.language !== this.state.language) {
			history.go(0)
		}
		if (this.state.pageSize !== prevState.pageSize || this.state.currentPage !== prevState.currentPage) {
			this.handleGetOffers()
		}
		if (this.props.offerList !== prevProps.offerList) {
			this.setState({ isTranslating: true }, () => {
				this.updateTranslatedOffers(this.props.offerList.offers)
			})
		}
		if (this.state.keyword !== prevState.keyword || this.state.activeFilters != prevState.activeFilters) {
			if (this.state.currentPage === 1) {
				this.handleGetOffers()
			}
			else { this.setState({ currentPage: 1 }) }
		}
	}

	updateTranslatedOffers(offerList) {
		const { t } = this.props
		const translatedOffers =
			offerList &&
			offerList.map &&
			offerList.map(offer => {
				const technologies = []
				for (const technology of offer.technologies) {
					if (!technologies.includes(technology)) technologies.push(technology)
				}
				const newOffer = {
					...offer,
					searchableId: offer.id,
					id: (
						<a
							href={`${appRoutes.OFFER_DETAIL_ROUTE_NO_ID}${offer.id}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{offer.id}
						</a>
					),
					status: {
						value: {
							id: offer.status,
							text: t(`offer_status_${offer.status}`, { ns: 'status' }),

						},
					},
					statusToRender: (
						<div className='my-2 d-flex flex-row'>
							<div className='me-2'>
								<span className={`rounded-pill end-ellipsis text-white bg-${STATUS_COLOR[offer.status]}`}>
									{t(`offer_status_${offer.status}`, { ns: 'status' })}
								</span>
							</div>
							<PricesSeenAlert offer={offer} t={t} />
						</div>
					),
					technologies: {
						value: {
							technologies: (
								<OfferTechnology technologies={technologies} partConfigOptions={this.props.partConfigOptions} />
							),
						},
					},
					message: (
						<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
							<NoteTooltip note={offer.message} t={t} />
						</div>
					),
					customer: {
						value: {
							customer: (
								<OfferCustomer
									organizationId={offer.customer.value.organizationId}
									title={offer.customer.value.title}
									crmLink={offer.customer.value.crmLink}
									email={offer.customer.value.email}
									isFirstUserOffer={offer.isFirstUserOffer}
								/>
							),
						},
					},
					manager: findManager(this.props.managers, parseInt(offer.manager))?.label,
				}
				return newOffer
			})

		this.setState({
			...this.state,
			translatedOffers,
			isTranslating: false,
			language: this.props.i18n.language,
		})
	}

	handlePageChange(page, pageSize) {
		this.setState({ currentPage: page, pageSize: pageSize }, () => {
		})
	}

	sizePerPageRenderer({
		options,
		currSizePerPage,
		onSizePerPageChange,
	}) {
		return (
			<div className="btn-group" role="group">

				<button
					key={this.state.pageSize}
					type="button"
					onClick={() => onSizePerPageChange(options.page)}
					className={'btn btn-secondary width-3rem'}
				>
					{currSizePerPage}
				</button>
				{
					options.map(option =>
						this.sizePerPageOptionRenderer({
							...option,
							onSizePerPageChange,
						}),
					)
				}
			</div>
		)
	}

	sizePerPageOptionRenderer({
		text,
		page,
		onSizePerPageChange,
	}) {
		return (
			<li
				key={text}
				role="presentation"
				className="d-flex align-items-center"
			>
				<a
					href="#"
					tabIndex="-1"
					role="menuitem"
					data-page={page}
					onMouseDown={(e) => {
						e.preventDefault()
						onSizePerPageChange(page)
					}}
					className="btn btn-default btn-primary dropdown-toggle text-white width-3rem"
				>
					{text}
				</a>
			</li>
		)
	}

	handleGetOffers() {
		if (this.state.keyword.length !== 0 || this.isSomeFilterActive()) {
			timeoutId && clearTimeout(timeoutId)
			timeoutId = setTimeout(() => {
				this.callGetFilteredOffers()
			}, 500)
		} else {
			this.callGetFilteredOffers()
		}
	}

	callGetFilteredOffers() {
		controller.abort()
		controller = new AbortController()
		this.props.getFilteredOffers({
			keyword: this.state.keyword,
			filterData: this.state.activeFilters,
			pagination: { page: this.state.currentPage, pageSize: this.state.pageSize },
		},
			controller.signal)
	}

	handleOnChangeKeyword(keyword) {
		this.setState({
			...this.state,
			keyword,
		})
	}

	render() {
		const { t } = this.props
		return (
			<React.Fragment>
				<div className="page-content">
					<MetaTags>
						<title>Proto&Go! | {t('offers', { ns: 'naming' })}</title>
					</MetaTags>
					<Breadcrumbs
						title={t('management', { ns: 'naming' })}
						breadcrumbItems={[
							{
								item: t('offerList', { ns: 'naming' }),
								link: '/offer-list',
							},
						]}
					/>
					<div className="bg-white p-4">
						<div className="d-flex justify-content-between">
							<div className="d-flex justify-content-between align-items-center">
								<OffersFilterModal handleOnChangeKeyword={this.handleOnChangeKeyword} handleOnChangeFilters={this.handleOnChangeFilters} />
							</div>

							<PartsFinder />
						</div>
						{this.props.isLoading && <Loader className="font-size-80 mx-auto" />}
						{this.props.error && !this.props.isLoading && (
							<Alert
								type="danger"
								centered
								fitContent
								message={this.props.error.message}
							/>
						)}
						{!this.props.isLoading &&
							!this.props.error &&
							this.state.translatedOffers != null && (
								<div>
									{this.state.translatedOffers.length > 0 ?
										<Table
											hiddenFields={['searchableId']}
											showStatusFilter={true}
											customFilter={true}
											remote
											header={OFFER_TABLE_HEADER}
											items={this.state.translatedOffers}
											selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
											language={this.props.i18n.language}
											rowQuantityList={PAGE_SIZE_LIST}
											counters={[{ label: 'offers', value: this.props.count }]}
											paginationProps={{
												custom: true,
												totalSize: this.props.count,
												// pageStartIndex: 1,
												page: this.state.currentPage || 1,
												sizePerPage: this.state.pageSize,
												sizePerPageList: PAGE_SIZE_LIST,
												// sizePerPageRenderer: this.sizePerPageRenderer,
												sizePerPageOptionRenderer: this.sizePerPageOptionRenderer,
												onPageChange: (page, sizePerPage) => {
													this.handlePageChange(page, sizePerPage)
												},
												onSizePerPageChange: (sizePerPage, page) => {
													this.handlePageChange(page, sizePerPage)
												},
											}}
											styleForFieldList={[
												{
													field: 'parts',
													styles: [
														{
															type: 'grayBg',
															class: 'rounded-pill bg-secondary text-white',
														},
													],
												},
												{
													field: 'date',
													styles: [
														{
															type: 'list',
															class: 'd-block',
														},
													],
												},
												{
													field: 'country',
													styles: [
														{
															type: 'flag',
															class: 'mx-auto',
														},
													],
												},
												{
													field: 'language',
													styles: [
														{
															type: 'flag',
															class: 'mx-auto',
														},
													],
												},
											]}
										/> : <div className="d-flex justify-content-center">{this.props.t('no_results_found', { ns: 'naming' })}</div>}
								</div>
							)}

					</div>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		offerList: state.Offers.offerList,
		count: state.Offers.count,
		isLoading: state.Offers.isLoading,
		error: state.Offers.error,
		managers: state.Users.managers,
	}
}

OfferList.propTypes = {
	offerList: PropTypes.object,
	count: PropTypes.number,
	isLoading: PropTypes.bool,
	getOfferList: PropTypes.func,
	error: PropTypes.any,
	t: PropTypes.func,
	i18n: PropTypes.object,
	getFilteredOffers: PropTypes.func,
	getManagers: PropTypes.func,
	managers: PropTypes.array,
}

export default withGetPartConfigOptions(connect(mapStateToProps, { getOfferList, getFilteredOffers, getManagers })(
	withTranslation()(OfferList),
))
