import { footerString, footerWebsite } from 'constants/footer'

export const getPdfBlob = async (id, options) => {
	const html2pdf = require('html2pdf.js')

	const { height, width, filename } = options

	const downloadPdf = async () => {
		const element = document.getElementById(id)
		const pages = element.getElementsByClassName('page')
		return await html2pdf().from('').set(options).toPdf().get('pdf').then(async (pdf) => {
			for (const page of pages) {
				pdf.addPage()
				pdf.addImage(
					await html2pdf().from(page).set(options).toImg().get('img').then((img) => img),
					'JPEG',
					0,
					2,
					width,
					height - 4,
				)
			}

			pdf.deletePage(1)
			setPageNumberOnDocument(pdf)
			setDocumentFooter(footerString, footerWebsite, pdf)
			return new File([pdf.output('blob')], filename + '.pdf', {
				type: 'application/pdf',
			})
		})
	}
	const setPageNumberOnDocument = (pdf) => {

		const x = pdf.internal.pageSize.getWidth() - 10
		const y = pdf.internal.pageSize.getHeight() - 8
		const totalPages = pdf.internal.getNumberOfPages()
		for (let i = 1; i <= totalPages; i++) {
			const str = `${i} / ${totalPages}`
			const options = {
				align: 'right',
			}

			pdf.setPage(i)
			pdf.setFontSize(8).setFont('helvetica')
			pdf.text(str, x, y, options)
		}
	}

	const setDocumentFooter = (str, website, pdf) => {
		setFooterLabel(str, pdf)
		setFooterWebsite(website, pdf)
	}

	const setFooterLabel = (str, pdf) => {
		const yPosition = pdf.internal.pageSize.getHeight() - 20
		const totalPages = pdf.internal.getNumberOfPages()

		const x = pdf.internal.pageSize.getWidth() / 2
		const y = yPosition
		const options = {
			align: 'center',
			maxWidth: 130,

		}

		pdf.setPage(totalPages)
		pdf.setFontSize(8).setFont('helvetica')
		pdf.text(str, x, y, options)

	}
	const setFooterWebsite = (str, pdf) => {
		const yPosition = pdf.internal.pageSize.getHeight() - 20
		const totalPages = pdf.internal.getNumberOfPages()

		const x = pdf.internal.pageSize.getWidth() / 2
		const y = yPosition + 10
		const options = {
			align: 'center',
		}

		pdf.setPage(totalPages)
		pdf.setFontSize(12).setFont('helvetica', 'bold')
		pdf.text(str, x, y, options)

	}
	return await downloadPdf()

}
