export const getClientMarginValueById = (clientMargin, id) => {
	if (id != null && clientMargin != null) {
		const value = clientMargin.find((clientMargin) => clientMargin.id === id).value || 1
		return Math.round((value - 1) * 100)
	}
	return null
}

export const getClientMarginOptions = (clientMargin) => {
	return clientMargin.map((currentClientMargin) => {
		return getClientMarginKeyValuePair(clientMargin, currentClientMargin.id)
	})
}

export const getClientMarginKeyValuePair = (clientMargin, id) => {
	if (id == '' || id == null) return null
	return {
		label: `${id}, ${getClientMarginValueById(clientMargin, id)}%`,
		value: id,
	}
}
