export const defaultQuantites = [
	1,
	4,
	10,
	20,
	40,
	60,
	80,
	100,
	120,
	140,
	200,
	300,
	500,
	750,
	1000,
	1500,
	2000,
	2500,
	3000,
	4000,
	5000,
]

export const approximateQuantity = (quantity) => {
	for (const defaultQuantity of defaultQuantites) {
		if (quantity < defaultQuantity) return defaultQuantity
	}
}
