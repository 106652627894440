import { Couriers } from 'constants/couriers'
import { ProductionStatus } from 'constants/productionStatus'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

export const Tracking = ({ trackingId, courier, estimatedArrivalDate, status, overrideOnClick }) => {
	const date = new Date()
	const arrivalDate = new Date(estimatedArrivalDate)
	const NACEX_URL = 'https://www.nacex.es/seguimientoDetalle.do?agencia_origen=0802&numero_albaran='
	const DHL_URL = 'https://mydhl.express.dhl/es/en/tracking.html#/results?id='

	const content = (
		<div className="d-flex">
			<span className="m-0 bg-light p-2 rounded text-black tracking-width">
				{trackingId}
			</span>
			<i className="bg-secondary p-2 rounded" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<i className="bx bxs-truck text-light" />
			</i>
		</div>
	)

	return (
		<div className="d-flex flex-row" style={{ cursor: 'pointer' }}>
			{trackingId && (
				<div>
					{overrideOnClick ? (
						<div onClick={overrideOnClick}>
							{content}
						</div>
					) : (
						<a
							href={`${courier == Couriers.DHL ? DHL_URL : courier == Couriers.NACEX ? NACEX_URL : ''}${trackingId}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{content}
						</a>
					)}
					{estimatedArrivalDate && status == ProductionStatus.PICKED_UP_BY_COURIER && date >= arrivalDate && (
						<div className="d-flex flex-row justify-content-between align-items-center mt-1">
							<i
								className="bx bxs-error text-danger"
								data-tip="Incidencias en el reparto. Haz click para ver la información en DHL"
								data-for={'trackingId'}
							></i>
							<ReactTooltip
								id="trackingId"
								place="top"
								type="info"
								effect="solid"
								backgroundColor="orange"
								multiline={true}
								offset={{ left: -43 }}
							/>

						</div>
					)}
				</div>
			)}
		</div>
	)
}

Tracking.propTypes = {
	trackingId: PropTypes.string,
	courier: PropTypes.number,
	estimatedArrivalDate: PropTypes.string,
	status: PropTypes.number,
}
