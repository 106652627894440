import PDFFileIcon from 'components/Icons/PDFFileIcon'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
const LINK_ELEMENT = 'a'

export const BlueprintOpener = ({ url, partId, t, isModifiedBluePrint }) => {
	const openPdf = () => {
		const link = document.createElement(LINK_ELEMENT)
		link.href = url
		link.target = '_blank'
		link.click()
	}

	const tooltipMessage = isModifiedBluePrint ? t('open_modified_pdf', { ns: 'naming' }) : t('open_pdf', { ns: 'naming' })

	return (
		<div>
			{url ?
				<div className="cursor" onClick={e => openPdf()}>
					<PDFFileIcon invertedColor={isModifiedBluePrint} tooltipMessage={tooltipMessage} />
				</div>
				:
				<div>
				</div>
			}
		</div>
	)
}

BlueprintOpener.propTypes = {
	url: PropTypes.string,
	partId: PropTypes.number,
	t: PropTypes.func,
	isModifiedBluePrint: PropTypes.bool,
}

export default withTranslation()(BlueprintOpener)
