import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { Input } from 'reactstrap'
import { setLastScannedPart, setSelectedExpeditionOrderId } from 'store/actions'
import { getScanPartData } from 'utils/getScanPartData'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import ExpeditionOrders from './ExpeditionOrders/ExpeditionOrders'
import ExpeditionSearchAndClientinfo from './ExpeditionsSearchAndClientInfo/ExpeditionSearchAndClientInfo'
import ExpeditionOrderInfo from './OrderInfo/ExpeditionOrderInfo'

const Expeditions = (props) => {

	const {
		t,
		selectedExpeditionOrderId,
		expeditionOrderList,
		setLastScannedPart,
		setSelectedExpeditionOrderId,
	} = props

	const [selectedOrder, setSelectedOrder] = useState(null)
	const [manualScanned, setManualScanned] = useState(null)
	const [codeInput, setCodeInput] = useState(null)
	const [codeInputInvalid, setCodeInputInvalid] = useState(null)
	const [scannedPart, setScannedPart] = useState(null)
	const [scannedQuantity, setScannedQuantity] = useState(null)
	const [scannedOrder, setScannedOrder] = useState(null)
	const [isBoxModalOpen, setIsBoxModalOpen] = useState(false)

	// Seleccionar un pedido si su ID está en la URL:
	useEffect(() => {
		if (selectedExpeditionOrderId) {
			setSelectedOrder(selectedExpeditionOrderId)
		}
	}, [selectedExpeditionOrderId])

	const scanPart = (code) => {
		const { orderId, partId, quantity } = getScanPartData(code)
		const orderExists = expeditionOrderList.some((order) => order.id == orderId)
		if (!orderExists) {
			setCodeInputInvalid(true)
			return
		}
		setScannedOrder(orderId)
		setScannedPart(partId)
		setLastScannedPart(partId)
		setScannedQuantity(quantity)
		setSelectedExpeditionOrderId(orderId)
		setIsBoxModalOpen(true)
	}

	return (
		<div className="page-content marginFooter">
			{/* Nombre de la pestaña en el navegador */}
			<MetaTags>
				<title>Proto&Go! | {t('expeditions', { ns: 'naming' })}</title>
			</MetaTags>
			{/* Título de la página y breadcrumbs */}
			<Breadcrumbs
				title={t('logistics', { ns: 'naming' })}
				breadcrumbItems={[
					{
						item: t('expeditions', { ns: 'naming' }),
						link: '/expeditions',
					},
				]}
			/>
			<div className="d-flex flex-row align-items-start">
				{/* Columna a la izquierda: Pedidos abiertos, input para escanear código manualmente, y datos de envío del pedido seleccionado: */}
				<div className="col-3 d-flex flex-column">
					{/* Pedidos abiertos: */}
					<ExpeditionOrders />
					{/* Input para escanear código manualmente */}
					<div className='bg-white p-4'>
						{/* Texto "N pedidos en la lista": */}
						<div
							className="w-100 p-2 d-flex align-items-center"
							style={{ border: '0.5px solid lightgray', borderTopLeftRadius: '4px', borderTopRightRadius: '4px', backgroundColor: 'whitesmoke' }}
						>
							{expeditionOrderList?.length} {t('ordersInTheList', { ns: 'naming' })}
						</div>
						{/* Input: */}
						<div
							className="w-100 p-2 d-flex align-items-center"
							style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
						>
							<Input
								placeholder={t('enter_code_manually', { ns: 'naming' })}
								onChange={(e) => {
									const code = e.currentTarget.value
									const { orderId, partId, quantity } = getScanPartData(code)
									if (orderId != null && partId != null && quantity != null) {
										setCodeInput(code)
										setCodeInputInvalid(false)
									} else {
										setCodeInputInvalid(true)
									}
								}}
								onKeyDown={(e) => {
									if (e.key === 'Enter' && !codeInputInvalid) {
										scanPart(codeInput)
									}
								}}
								invalid={codeInputInvalid}
							/>
						</div>
					</div>
					{/* Información del cliente y dirección de envío del pedido seleccionado: */}
					<ExpeditionSearchAndClientinfo />
				</div>
				{/* Sección principal: Texto "Escanear pieza para comenzar" o información del pedido seleccionado: */}
				<div className="col-9 p-4 mb-3 mx-2 bg-white">
					<ExpeditionOrderInfo
						selectedExpeditionOrderId={selectedOrder}
						setManualScanned={setManualScanned}
						manualScanned={manualScanned}
						scanPart={scanPart}
						scannedPart={scannedPart}
						setScannedPart={setScannedPart}
						scannedQuantity={scannedQuantity}
						setScannedQuantity={setScannedQuantity}
						scannedOrder={scannedOrder}
						setScannedOrder={setScannedOrder}
						isBoxModalOpen={isBoxModalOpen}
						setIsBoxModalOpen={setIsBoxModalOpen}
					/>
				</div>
			</div>

		</div>
	)
}

Expeditions.propTypes = {
	// from connect HOC - mapStateToProps:
	selectedExpeditionOrderId: PropTypes.number,
	expeditionOrderList: PropTypes.array,

	// from connect HOC - mapDispatchToProps:
	setLastScannedPart: PropTypes.func,
	setSelectedExpeditionOrderId: PropTypes.func,

	// from withTranslation HOC:
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({
	selectedExpeditionOrderId: state.Orders.selectedExpeditionOrderId,
	expeditionOrderList: state.Orders.expeditionOrderList,
})

const mapDispatchToProps = {
	setLastScannedPart,
	setSelectedExpeditionOrderId,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(
	withGetPartConfigOptions(
		withTranslation()(
			Expeditions,
		),
	),
)
