import {
	ADD_PRODUCTION_ITEMS_FROM_SCANNED_ORDER,
	COMPLETE_PRODUCTION,
	COMPLETE_PRODUCTION_FAIL,
	COMPLETE_PRODUCTION_SUCCESS,
	GET_FILTERED_PRODUCTIONS,
	GET_FILTERED_PRODUCTIONS_FAIL,
	GET_FILTERED_PRODUCTIONS_SUCCESS,
	GET_PRODUCTIONS_BY_TRACKING,
	GET_PRODUCTIONS_BY_TRACKING_FAIL,
	GET_PRODUCTIONS_BY_TRACKING_SUCCESS,
	GET_PRODUCTIONS_LIST_FROM_ORDER_ID,
	GET_PRODUCTIONS_LIST_FROM_ORDER_ID_FAIL,
	GET_PRODUCTIONS_LIST_FROM_ORDER_ID_SUCCESS,
	GET_PRODUCTIONS_PICKED_UP_BY_COURIER,
	GET_PRODUCTIONS_PICKED_UP_BY_COURIER_FAIL,
	GET_PRODUCTIONS_PICKED_UP_BY_COURIER_SUCCESS,
	GET_PRODUCTION_CHAT,
	GET_PRODUCTION_CHAT_FAIL,
	GET_PRODUCTION_CHAT_SUCCESS,
	GET_PRODUCTION_DETAIL,
	GET_PRODUCTION_DETAIL_FAIL,
	GET_PRODUCTION_DETAIL_SUCCESS,
	GET_PRODUCTION_ITEMS_BY_ORDER_ID,
	GET_PRODUCTION_ITEMS_BY_ORDER_ID_FAIL,
	GET_PRODUCTION_ITEMS_BY_ORDER_ID_SUCCESS,
	GET_PRODUCTION_ITEMS_BY_PART_ID,
	GET_PRODUCTION_ITEMS_BY_PART_ID_FAIL,
	GET_PRODUCTION_ITEMS_BY_PART_ID_SUCCESS,
	GET_PRODUCTION_ITEMS_LABELS,
	GET_PRODUCTION_ITEMS_LABELS_FAIL,
	GET_PRODUCTION_ITEMS_LABELS_SUCCESS,
	GET_PRODUCTION_LIST,
	GET_PRODUCTION_LIST_BY_SUPPLIER,
	GET_PRODUCTION_LIST_BY_SUPPLIER_FAIL,
	GET_PRODUCTION_LIST_BY_SUPPLIER_SUCCESS,
	GET_PRODUCTION_LIST_FAIL,
	GET_PRODUCTION_LIST_SUCCESS,
	GET_PURCHASES_DASHBOARD,
	GET_PURCHASES_DASHBOARD_FAIL,
	GET_PURCHASES_DASHBOARD_SUCCESS,
	GET_SHIPMENTS_IMPORTS,
	GET_SHIPMENTS_IMPORTS_FAIL,
	GET_SHIPMENTS_IMPORTS_SUCCESS,
	GET_SUPPLIER_SHIPMENT_PRICING_LIST,
	GET_SUPPLIER_SHIPMENT_PRICING_LIST_FAIL,
	GET_SUPPLIER_SHIPMENT_PRICING_LIST_SUCCESS,
	GET_TRACKING_NUMBERS_FROM_PROVIDER,
	GET_TRACKING_NUMBERS_FROM_PROVIDER_FAIL,
	GET_TRACKING_NUMBERS_FROM_PROVIDER_SUCCESS,
	HANDLE_COMPLETE_PRODUCTION,
	HANDLE_COMPLETE_PRODUCTION_FAIL,
	HANDLE_COMPLETE_PRODUCTION_SUCCESS,
	LAUNCH_PRODUCTION,
	LAUNCH_PRODUCTION_FAIL,
	LAUNCH_PRODUCTION_SUCCESS,
	POST_PRODUCITON_CHAT,
	POST_PRODUCITON_CHAT_FAIL,
	POST_PRODUCITON_CHAT_SUCCESS,
	REJECT_PRODUCTION,
	REJECT_PRODUCTION_FAIL,
	REJECT_PRODUCTION_SUCCESS,
	UPDATE_PRODUCTION,
	UPDATE_PRODUCTION_FAIL,
	UPDATE_PRODUCTION_ITEM,
	UPDATE_PRODUCTION_ITEM_FAIL,
	UPDATE_PRODUCTION_ITEM_SUCCESS,
	UPDATE_PRODUCTION_SUCCESS,
	UPDATE_SHIPMENT_IMPORT,
	UPDATE_SHIPMENT_IMPORT_FAIL,
	UPDATE_SHIPMENT_IMPORT_SUCCESS,
} from './actionTypes'

export const getSupplierShipmentPricingList = () => ({
	type: GET_SUPPLIER_SHIPMENT_PRICING_LIST,
})
export const getSupplierShipmentPricingListSuccess = ({ pricings }) => ({
	type: GET_SUPPLIER_SHIPMENT_PRICING_LIST_SUCCESS,
	payload: { pricings },
})
export const getSupplierShipmentPricingListFail = error => ({
	type: GET_SUPPLIER_SHIPMENT_PRICING_LIST_FAIL,
	payload: error,
})
export const getProductionList = ({ page, pageSize }) => ({
	type: GET_PRODUCTION_LIST,
	payload: { page, pageSize },

})
export const getProductionListSuccess = ({ productionList, count }) => ({
	type: GET_PRODUCTION_LIST_SUCCESS,
	payload: { productionList, count },
})
export const getProductionListFail = error => ({
	type: GET_PRODUCTION_LIST_FAIL,
	payload: error,
})
export const getProductionItemsByPartId = partId => ({
	type: GET_PRODUCTION_ITEMS_BY_PART_ID,
	payload: { partId },
})
export const getProductionItemsByPartIdSuccess = ({ parts }) => ({
	type: GET_PRODUCTION_ITEMS_BY_PART_ID_SUCCESS,
	payload: { parts },
})
export const getProductionItemsByPartIdFail = error => ({
	type: GET_PRODUCTION_ITEMS_BY_PART_ID_FAIL,
	payload: error,
})
export const launchProduction = ({ data }) => ({
	type: LAUNCH_PRODUCTION,
	payload: { data },
})
export const launchProductionSuccess = production => ({
	type: LAUNCH_PRODUCTION_SUCCESS,
	payload: production,
})
export const launchProductionFail = error => ({
	type: LAUNCH_PRODUCTION_FAIL,
	payload: error,
})

export const getProductionDetail = (id, fragId) => ({
	type: GET_PRODUCTION_DETAIL,
	payload: { id, fragId },
})

export const getProductionDetailSuccess = production => ({
	type: GET_PRODUCTION_DETAIL_SUCCESS,
	payload: production,
})

export const getProductionDetailFail = error => ({
	type: GET_PRODUCTION_DETAIL_FAIL,
	payload: error,
})

export const getProductionListBySupplier = ({ id, page, pageSize }) => ({
	type: GET_PRODUCTION_LIST_BY_SUPPLIER,
	payload: { id, page, pageSize },
})

export const getProductionListBySupplierSuccess = productions => ({
	type: GET_PRODUCTION_LIST_BY_SUPPLIER_SUCCESS,
	payload: productions,
})

export const getProductionListBySupplierFail = error => ({
	type: GET_PRODUCTION_LIST_BY_SUPPLIER_FAIL,
	payload: error,
})

export const getProductionChat = (orderId, orderFragmentId) => ({
	type: GET_PRODUCTION_CHAT,
	payload: { orderId, orderFragmentId },
})

export const getProductionChatSuccess = chat => ({
	type: GET_PRODUCTION_CHAT_SUCCESS,
	payload: chat,
})

export const getProductionChatFail = error => ({
	type: GET_PRODUCTION_CHAT_FAIL,
	payload: error,
})

export const postProductionChat = (data) => ({
	type: POST_PRODUCITON_CHAT,
	payload: { data },
})

export const postProductionChatSuccess = (chat) => ({
	type: POST_PRODUCITON_CHAT_SUCCESS,
	payload: chat,
})

export const postProductionChatFail = error => ({
	type: POST_PRODUCITON_CHAT_FAIL,
	payload: error,
})

export const getProductionItemsLabels = ({ orderId, orderFragmentId }) => ({
	type: GET_PRODUCTION_ITEMS_LABELS,
	payload: { orderId, orderFragmentId },
})

export const getProductionItemsLabelsSuccess = items => ({
	type: GET_PRODUCTION_ITEMS_LABELS_SUCCESS,
	payload: items,
})

export const getProductionItemsLabelsFail = error => ({
	type: GET_PRODUCTION_ITEMS_LABELS_FAIL,
	payload: error,
})

export const updateProduction = ({ production }) => ({
	type: UPDATE_PRODUCTION,
	payload: { production },
})

export const updateProductionSuccess = production => ({
	type: UPDATE_PRODUCTION_SUCCESS,
	payload: production,
})

export const updateProductionFail = error => ({
	type: UPDATE_PRODUCTION_FAIL,
	payload: error,
})

export const updateProductionItem = ({ productionItem }) => ({
	type: UPDATE_PRODUCTION_ITEM,
	payload: { productionItem },
})

export const updateProductionItemSuccess = production => ({
	type: UPDATE_PRODUCTION_ITEM_SUCCESS,
	payload: production,
})

export const updateProductionItemFail = error => ({
	type: UPDATE_PRODUCTION_ITEM_FAIL,
	payload: error,
})

export const completeProduction = ({ production, newShipmentDate }) => ({
	type: COMPLETE_PRODUCTION,
	payload: { production, newShipmentDate },
})

export const completeProductionSuccess = production => ({
	type: COMPLETE_PRODUCTION_SUCCESS,
	payload: production,
})

export const completeProductionFail = error => ({
	type: COMPLETE_PRODUCTION_FAIL,
	payload: error,
})

export const getProductionItemsByOrderId = ({ orderId }) => ({
	type: GET_PRODUCTION_ITEMS_BY_ORDER_ID,
	payload: { orderId },
})
export const getProductionItemsByOrderIdSuccess = productionItems => ({
	type: GET_PRODUCTION_ITEMS_BY_ORDER_ID_SUCCESS,
	payload: productionItems,
})
export const getProductionItemsByOrderIdFail = error => ({
	type: GET_PRODUCTION_ITEMS_BY_ORDER_ID_FAIL,
	payload: error,
})

export const getProductionsPickedUpByCourier = () => ({
	type: GET_PRODUCTIONS_PICKED_UP_BY_COURIER,
})

export const getProductionsPickedUpByCourierSuccess = productions => ({
	type: GET_PRODUCTIONS_PICKED_UP_BY_COURIER_SUCCESS,
	payload: productions,
})

export const getProductionsPickedUpByCourierFail = error => ({
	type: GET_PRODUCTIONS_PICKED_UP_BY_COURIER_FAIL,
	payload: error,
})

export const getProductionsListFromOrderId = (orderId) => ({
	type: GET_PRODUCTIONS_LIST_FROM_ORDER_ID,
	payload: { orderId },
})

export const getProductionsListFromOrderIdSuccess = productions => ({
	type: GET_PRODUCTIONS_LIST_FROM_ORDER_ID_SUCCESS,
	payload: productions,
})

export const getProductionsListFromOrderIdFail = error => ({
	type: GET_PRODUCTIONS_LIST_FROM_ORDER_ID_FAIL,
	payload: error,
})

export const addProductionItemsFromScannedOrder = (items) => ({
	type: ADD_PRODUCTION_ITEMS_FROM_SCANNED_ORDER,
	payload: { items },
})
export const rejectProduction = (data) => ({
	type: REJECT_PRODUCTION,
	payload: data,
})

export const rejectProductionSuccess = () => ({
	type: REJECT_PRODUCTION_SUCCESS,
})

export const rejectProductionFail = error => ({
	type: REJECT_PRODUCTION_FAIL,
	payload: error,
})

export const getFilteredProductions = (data, signal) => ({
	type: GET_FILTERED_PRODUCTIONS,
	payload: { data, signal },
})

export const getFilteredProductionsSuccess = ({ productions, totalRows }) => ({
	type: GET_FILTERED_PRODUCTIONS_SUCCESS,
	payload: { productions, totalRows },
})

export const getFilteredProductionsFail = (error) => ({
	type: GET_FILTERED_PRODUCTIONS_FAIL,
	payload: { error },
})

export const getShipmentsImports = (data, signal) => ({
	type: GET_SHIPMENTS_IMPORTS,
	payload: { data, signal },
})

export const getShipmentsImportsSuccess = ({ list, totalCount }) => ({
	type: GET_SHIPMENTS_IMPORTS_SUCCESS,
	payload: { list, totalCount },
})

export const getShipmentsImportsFail = (error) => ({
	type: GET_SHIPMENTS_IMPORTS_FAIL,
	payload: { error },
})

export const updateShipmentImport = (data) => ({
	type: UPDATE_SHIPMENT_IMPORT,
	payload: { data },
})

export const updateShipmentImportSuccess = () => ({
	type: UPDATE_SHIPMENT_IMPORT_SUCCESS,
})

export const updateShipmentImportFail = (error) => ({
	type: UPDATE_SHIPMENT_IMPORT_FAIL,
	payload: { error },
})

export const handleCompleteProduction = (data) => {
	return {
		type: HANDLE_COMPLETE_PRODUCTION,
		payload: { data },
	}
}

export const handleCompleteProductionSuccess = () => ({
	type: HANDLE_COMPLETE_PRODUCTION_SUCCESS,
})

export const handleCompleteProductionFail = (error) => ({
	type: HANDLE_COMPLETE_PRODUCTION_FAIL,
	payload: { error },
})

export const getPurchasesDashboard = (data) => ({
	type: GET_PURCHASES_DASHBOARD,
	payload: { data },
})

export const getPurchasesDashboardSuccess = (purchasesData) => ({
	type: GET_PURCHASES_DASHBOARD_SUCCESS,
	payload: purchasesData,
})
export const getPurchasesDashboardFail = error => ({
	type: GET_PURCHASES_DASHBOARD_FAIL,
	payload: error,
})

export const getProductionsByTracking = (data) => ({
	type: GET_PRODUCTIONS_BY_TRACKING,
	payload: { data },
})

export const getProductionsByTrackingSuccess = (productions) => ({
	type: GET_PRODUCTIONS_BY_TRACKING_SUCCESS,
	payload: productions,
})

export const getProductionsByTrackingFail = error => ({
	type: GET_PRODUCTIONS_BY_TRACKING_FAIL,
	payload: error,
})

export const getTrackingNumbersFromProvider = (productions) => ({
	type: GET_TRACKING_NUMBERS_FROM_PROVIDER,
	payload: productions,
})

export const getTrackingNumbersFromProviderSuccess = (productions) => ({
	type: GET_TRACKING_NUMBERS_FROM_PROVIDER_SUCCESS,
	payload: productions,
})

export const getTrackingNumbersFromProviderFail = error => ({
	type: GET_TRACKING_NUMBERS_FROM_PROVIDER_FAIL,
	payload: error,
})
