import PropTypes from 'prop-types'
import React from 'react'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			error: null,
			errorInfo: null,
		}
	}

	static getDerivedStateFromError() {
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo,
		})
		if (process.env.NODE_ENV === 'development') {
			console.log('Uncaught error:', error, errorInfo)
		}
	}

	render() {
		if (this.state.hasError) {
			return this.props.content || (
				<div style={{
					display: 'flex',
					height: '100vh',
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
				}}>
					<h3>Something went wrong...</h3>
					{process.env.NODE_ENV === 'development' && (
						<details style={{ whiteSpace: 'pre-wrap', marginTop: '20px' }}>
							<summary>Error Details</summary>
							{this.state.error && this.state.error.toString()}
							<br />
							{this.state.errorInfo && this.state.errorInfo.componentStack}
						</details>
					)}
				</div>
			)
		}

		return this.props.children
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	content: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
}

export default ErrorBoundary
