import { isObjectEmpty } from 'common/Utils/ObjectUtils'
import { mapTechnologies, mapWorkdays, parseHolidays } from 'common/Utils/SuppliersUtils'
import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import { SectionTitle } from 'components/Common/SectionTitle'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom'
import { getSupplierDetail } from '../../store/actions'
import { ModalMessage } from './ModalMessage'
import { ProfileItem } from './ProfileItem'
import UpdateSupplierModal from './UpdateSupplierModal'

const UserProfile = ({
	isLoading,
	isLoadingDetail,
	supplier,
	getSupplierDetail,
	t,
	i18n: { language },
	isSupplierSuccessfullyUpdated,
	error,
	history,
	partConfigOptions,
}) => {

	const SUCCESS_MESSAGE = t('update_success', { ns: 'naming' })

	const [message, setMessage] = useState('')
	const [isModalOpen, setIsModalOpen] = useState(false)

	const getLocalStorageUser = () => {
		if (localStorage.getItem('authUser')) {
			const obj = JSON.parse(localStorage.getItem('authUser'))
			return obj
		}
	}

	useEffect(() => {
		const userLocalStorage = getLocalStorageUser()
		getSupplierDetail(userLocalStorage.id)
	}, [])

	useEffect(() => {
		if (error != null) {
			setMessage(error)
		}
	}, [error])

	useEffect(() => {
		if (isSupplierSuccessfullyUpdated === true) {
			setMessage(SUCCESS_MESSAGE)
		}
	}, [isSupplierSuccessfullyUpdated])

	useEffect(() => {
		if (message != '') {
			setIsModalOpen(true)
		}
	}, [message])

	const handleOnClose = () => {
		if (message === SUCCESS_MESSAGE) {
			history.go()
		} else {
			setIsModalOpen(false)
		}
	}

	const USER_ID = {
		iconClassname: 'bx bx-user-pin',
		label: t('Id', { ns: 'naming' }),
	}
	const USER_NAME = {
		iconClassname: 'bx bx-user-circle',
		label: t('firstName', { ns: 'naming' }),
	}
	const USER_EMAIL = {
		iconClassname: 'bx bxs-envelope',
		label: t('email', { ns: 'naming' }),
	}
	const COMPANY_NAME = {
		iconClassname: 'bx bxs-factory',
		label: t('commercial_brand', { ns: 'naming' }),
	}
	const ADDRESS = {
		iconClassname: 'bx bx-building',
		label: t('address', { ns: 'naming' }),
	}
	const BUSINESS_NAME = {
		iconClassname: 'bx bx-globe',
		label: t('officialName', { ns: 'naming' }),
	}
	const PHONE_NUMBER = {
		iconClassname: 'bx bxs-phone',
		label: t('phoneNumber', { ns: 'naming' }),
	}
	const LANGUAGE = {
		iconClassname: 'bx bx-sort-z-a',
		label: t('language', { ns: 'naming' }),
	}
	const FORMAT_DATE = {
		iconClassname: 'bx bx-calendar',
		label: t('format_date', { ns: 'naming' }),
	}
	const TIME_ZONE = {
		iconClassname: 'bx bx-map-alt',
		label: t('time_zone', { ns: 'naming' }),
	}
	const SHIPMENT_DAYS = {
		iconClassname: 'bx bx-cart',
		label: t('shipment_days', { ns: 'naming' }),
	}
	const WORKDAYS = {
		iconClassname: 'bx bx-calendar-star',
		label: t('work_days', { ns: 'naming' }),
	}
	const CURRENCY = {
		iconClassname: 'bx bx-money',
		label: t('currency', { ns: 'naming' }),
	}
	const HOLIDAYS = {
		iconClassname: 'bx bx-home',
		label: t('holidays', { ns: 'naming' }),
	}
	const TECHNOLOGIES = {
		iconClassname: 'bx bx-info-circle',
		label: t('technologies', { ns: 'naming' }),
	}

	const mobilephoneNumberWithPrefix = supplier && `${supplier.account?.phoneData?.mobilePhone?.prefix || ''} ${supplier.account?.phoneData?.mobilePhone?.number || ''}`

	const renderProfileItems = arrayItems => {
		return arrayItems.map(item => {
			return (
				<ProfileItem
					key={`profileItem${item.label}`}
					iconClassname={item.iconClassname}
					label={item.label}
					value={item.value}
					flag={item.flag}
					onChange={item.onChange}
					calendar={item.calendar}
				/>
			)
		})
	}

	return (

		<div className="page-content">
			<MetaTags>
				<title>Proto&Go! | {t('profile', { ns: 'naming' })}</title>
			</MetaTags>
			<div>
				{error && <Alert type="danger" centered fitContent message={error} />}
				{isLoading || isLoadingDetail ? (<div className="d-flex justify-content-center fs-2"><Loader /></div>) :
					(<div>
						{!isObjectEmpty(supplier) && !isLoading && error === null && (
							<div className="row d-flex bg-white mx-4 px-4 pt-3">
								<SectionTitle title="Profile" bgColor="bg-white" />
								<div className="d-flex row">
									<div className="col-4">
										{renderProfileItems([
											{ ...USER_ID, value: supplier.account?.id },
											{ ...USER_NAME, value: supplier.supplierInfo?.contactPerson },
											{ ...USER_EMAIL, value: supplier.account?.loginCredentials?.email },
											{ ...COMPANY_NAME, value: supplier.account?.personalInformation?.firstName },
											{ ...ADDRESS, value: supplier.supplierInfo?.address?.address },
										])}
									</div>
									<div className="col-4">
										{renderProfileItems([
											{ ...BUSINESS_NAME, value: supplier.supplierInfo.officialName },
											{ ...PHONE_NUMBER, value: mobilephoneNumberWithPrefix },
											{ ...LANGUAGE, value: t(supplier.account.personalInformation.language, { ns: 'languages' }) },
											{ ...FORMAT_DATE, value: supplier.supplierInfo.personalSettings.formatDate },
											{ ...TIME_ZONE, value: supplier.supplierInfo.timezone },
										])}
									</div>
									<div className="col-4">
										{renderProfileItems([
											{ ...SHIPMENT_DAYS, value: supplier.supplierInfo?.calendar?.shipmentDays },
											{ ...WORKDAYS, value: mapWorkdays(supplier.supplierInfo?.calendar?.workdays, t) },
											{ ...CURRENCY, value: supplier.supplierInfo?.personalSettings?.currency },
											{ ...HOLIDAYS, value: parseHolidays(supplier.supplierInfo.calendar.holidays) },
											{ ...TECHNOLOGIES, value: mapTechnologies(supplier.supplierInfo?.techTypes, partConfigOptions, language) },
										])}
									</div>
								</div>
								<div className="p-3 d-flex justify-content-end">
									<UpdateSupplierModal />
									<ModalMessage isModalOpen={isModalOpen} message={message} closeModal={handleOnClose} />
								</div>
							</div>
						)}
					</div>)}
			</div>

		</div>
	)
}

const mapStateToProps = state => {
	return {
		isLoadingDetail: state.Users.isLoadingDetail,
		isLoading: state.Users.isLoading,
		supplier: state.Users.supplierDetail,
		isSupplierSuccessfullyUpdated: state.Users.isSupplierSuccessfullyUpdated,
		error: state.Users.error,
		partConfigOptions: state.Parts.partConfigOptions,
	}
}

UserProfile.propTypes = {
	isLoading: PropTypes.bool,
	isLoadingDetail: PropTypes.bool,
	supplier: PropTypes.object,
	getSupplierDetail: PropTypes.func,
	history: PropTypes.object,
	isSupplierSuccessfullyUpdated: PropTypes.bool,
	error: PropTypes.string,
	t: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

export default withRouter(connect(mapStateToProps, {
	getSupplierDetail,
})(withTranslation()(UserProfile)))
