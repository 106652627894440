/* OFFERS */
export const GET_OFFER_LIST = 'GET_OFFER_LIST'
export const GET_OFFER_LIST_SUCCESS = 'GET_OFFER_LIST_SUCCESS'
export const GET_OFFER_LIST_FAIL = 'GET_OFFER_LIST_FAIL'
export const GET_OFFER_LIST_COUNT = 'GET_OFFER_LIST_COUNT'
export const GET_OFFER_LIST_COUNT_SUCCESS = 'GET_OFFER_LIST_COUNT_SUCCESS'
export const GET_OFFER_LIST_COUNT_FAIL = 'GET_OFFER_LIST_COUNT_FAIL'
export const GET_OFFER_DETAIL = 'GET_OFFER_DETAIL'
export const GET_OFFER_DETAIL_SUCCESS = 'GET_OFFER_DETAIL_SUCCESS'
export const GET_OFFER_DETAIL_FAIL = 'GET_OFFER_DETAIL_FAIL'
export const UPDATE_QUOTE_MARGIN = 'UPDATE_QUOTE_MARGIN'
export const UPDATE_QUOTE_MARGIN_SUCCESS = 'UPDATE_QUOTE_MARGIN_SUCCESS'
export const UPDATE_QUOTE_MARGIN_FAIL = 'UPDATE_QUOTE_MARGIN_FAIL'
export const UPLOAD_OFFER = 'UPLOAD_OFFER'
export const UPLOAD_OFFER_SUCCESS = 'UPLOAD_OFFER_SUCCESS'
export const UPLOAD_OFFER_FAIL = 'UPLOAD_OFFER_FAIL'
export const UPLOAD_BLUEPRINT = 'UPLOAD_BLUEPRINT'
export const UPLOAD_BLUEPRINT_SUCCESS = 'UPLOAD_BLUEPRINT_SUCCESS'
export const UPLOAD_BLUEPRINT_FAIL = 'UPLOAD_BLUEPRINT_FAIL'
export const DOWNLOAD_BLUEPRINT = 'DOWNLOAD_BLUEPRINT'
export const DOWNLOAD_BLUEPRINT_SUCCESS = 'DOWNLOAD_BLUEPRINT_SUCCESS'
export const DOWNLOAD_BLUEPRINT_FAIL = 'DOWNLOAD_BLUEPRINT_FAIL'
export const UPDATE_OFFER_CUSTOMER = 'UPDATE_OFFER_CUSTOMER'
export const UPDATE_OFFER_CUSTOMER_SUCCESS = 'UPDATE_OFFER_CUSTOMER_SUCCESS'
export const UPDATE_OFFER_CUSTOMER_FAIL = 'UPDATE_OFFER_CUSTOMER_FAIL'
export const GET_DOMAIN_OFFERS = 'GET_DOMAIN_OFFERS'
export const GET_DOMAIN_OFFERS_SUCCESS = 'GET_DOMAIN_OFFERS_SUCCESS'
export const GET_DOMAIN_OFFERS_FAIL = 'GET_DOMAIN_OFFERS_FAIL'
export const GET_FILTERED_OFFERS = 'GET_FILTERED_OFFERS'
export const GET_FILTERED_OFFERS_SUCCESS = 'GET_FILTERED_OFFERS_SUCCESS'
export const GET_FILTERED_OFFERS_FAIL = 'GET_FILTERED_OFFERS_FAIL'
export const DELETE_MODIFIED_BLUEPRINT = 'DELETE_MODIFIED_BLUEPRINT'
export const GET_OFFERS_DASHBOARD = 'GET_OFFERS_DASHBOARD'
export const GET_OFFERS_DASHBOARD_SUCCESS = 'GET_OFFERS_DASHBOARD_SUCCESS'
export const GET_OFFERS_DASHBOARD_FAIL = 'GET_OFFERS_DASHBOARD_FAIL'

