import { arraysAreEqual } from 'common/Utils/ArrayUtilities'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { PartName } from 'components/Common/PartName'
import PartThumbnail from 'components/Common/PartThumbnail'
import { BINDING_NOTE_TYPES } from 'constants/bindingNoteTypes'
import { PART_STATUS } from 'constants/partStatus'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { TextArea } from 'semantic-ui-react'

export const EditBindingNoteModal = (props) => {

	const {
		t,
		isOpen,
		closeModal,
		updateBindingNotes,
		updateBindingNotesStatus,
		deleteBindingNotes,
		deleteBindingNotesStatus,
		rejectPartsAfterEditingBindingNote,
		updatePartsStatus,
		successUpdatePartsStatusWithinBindingNoteModal,
		suppliersSelected,
		selectedParts,
	} = props

	const [bindingNoteTypesIds, setBindingNoteTypesIds] = useState([])
	const [comment, setComment] = useState('')
	const [resultModalIsOpen, setResultModalIsOpen] = useState(false)

	const selectBindingNoteTypesOptions = useMemo(() => {
		return BINDING_NOTE_TYPES.map(bindingNoteType => ({
			label: t(`binding_note_type_${bindingNoteType}`, { ns: 'naming' }),
			value: bindingNoteType,
		}))
	}, [])

	useEffect(() => {
		const partsBindingNoteTypes = selectedParts.map(x => x.bindingNote.typeIds || [])
		const allPartsHaveTheSameBindingNoteTypes = arraysAreEqual(...partsBindingNoteTypes)

		const partsComments = selectedParts.map(x => x.bindingNote.comment)
		const allPartsHaveTheSameComment = partsComments.every(str => str === partsComments[0])

		const hasDefaultValue = allPartsHaveTheSameBindingNoteTypes && allPartsHaveTheSameComment

		setBindingNoteTypesIds(!hasDefaultValue ? [] : selectBindingNoteTypesOptions.filter(option => {
			return partsBindingNoteTypes[0]?.includes(option.value)
		}).map(option => option.value))

		setComment(hasDefaultValue && partsComments[0] || '')
	}, [selectedParts])

	useEffect(() => {
		if (
			(updateBindingNotesStatus.success
				|| updateBindingNotesStatus.error
				|| deleteBindingNotesStatus.success
				|| deleteBindingNotesStatus.error)
			&&
			(!rejectPartsAfterEditingBindingNote
				|| successUpdatePartsStatusWithinBindingNoteModal != null)
		) {
			setResultModalIsOpen(true)
		}
	}, [
		updateBindingNotesStatus.success,
		updateBindingNotesStatus.error,
		deleteBindingNotesStatus.success,
		deleteBindingNotesStatus.error,
		successUpdatePartsStatusWithinBindingNoteModal,
	])

	const handleUpdate = () => {
		const partIds = selectedParts.map(part => part.id)
		updateBindingNotes({
			partIds: partIds,
			bindingNoteTypesIds: bindingNoteTypesIds,
			comment: comment.trim(),
		})
		if (rejectPartsAfterEditingBindingNote) {
			updatePartsStatus(partIds, PART_STATUS.REJECTED, suppliersSelected, true)
		}
	}

	const handleDelete = () => {
		const partIds = selectedParts.map(part => part.id)
		deleteBindingNotes({ partIds: partIds })
	}

	const getPartCard = (part) => {
		return (
			<div key={`row-${part.id}`}>
				<div className="part-card m-1">
					<div className="d-flex justify-content-center p-2">
						<PartThumbnail
							propsImageUrl={part.fileLinks.thumbnail}
							propsStlUrl={part.fileLinks.stlModel}
							stlData={{
								partId: part.id,
								partName: part.name,
								size: part.size,
								weight: part.weight,
							}}
						/>
					</div>
					<ul className="list-unstyled m-2">
						<li>
							<strong className="title">
								{part.id}
							</strong>
						</li>
						<li>
							<PartName
								name={part.name}
								maxLength={19}
							/>
						</li>
					</ul>
				</div>
			</div>
		)
	}

	const ResultModal = (
		<Modal
			isOpen={resultModalIsOpen}
			closeModal={() => {
				if (
					(updateBindingNotesStatus.success || deleteBindingNotesStatus.success)
					&&
					(!rejectPartsAfterEditingBindingNote || successUpdatePartsStatusWithinBindingNoteModal === true)
				) {
					history.go(0)
					return
				} else {
					setResultModalIsOpen(false)
				}
			}}
			title={(() => {
				if (updateBindingNotesStatus.success || deleteBindingNotesStatus.success) {
					if (!rejectPartsAfterEditingBindingNote) {
						if (updateBindingNotesStatus.success) {
							return t('update_success', { ns: 'naming' })
						}
						else if (deleteBindingNotesStatus.success) {
							return t('delete_success', { ns: 'naming' })
						}
					} else {
						if (successUpdatePartsStatusWithinBindingNoteModal === true) {
							return t('update_part_status_success', { ns: 'naming' })
						}
					}
				}
				return t('error_message', { ns: 'naming' })
			})()}
		/>
	)

	const saveButtonEnabled = !updateBindingNotesStatus.isLoading && bindingNoteTypesIds.length > 0
	const deleteButtonEnabled = !deleteBindingNotesStatus.isLoading

	const MainModal = (
		<Modal
			isOpen={isOpen}
			closeModal={closeModal}
			title={t('editBindingNote', { ns: 'naming' })}
			className={selectedParts?.length > 3 ? 'modal-lg' : ''}
			body={(
				<div>
					{selectedParts?.length > 0 ? (
						<div className="d-flex flex-column p-2">
							<div className="d-flex flex-row flex-wrap justify-content-center pb-3 border-bottom border-grey ">
								{selectedParts.map(getPartCard)}
							</div>
							<div className="d-felx flex-column justify-content-start mt-4">
								<h5 className="ms-3">
									{t('customer_purchase_binding_note', { ns: 'naming' })}
								</h5>
								<div className="mx-3 d-flex flex-column gap-2">
									<strong>
										{t('reason', { ns: 'naming' })}
										{' '}
										<span className="text-danger">{'*'}</span>
									</strong>
									<Select
										isMulti
										onChange={optionsSelected => setBindingNoteTypesIds(optionsSelected.map(option => option.value))}
										options={selectBindingNoteTypesOptions}
										value={selectBindingNoteTypesOptions.filter(x => bindingNoteTypesIds.includes(x.value))}
									/>
									<strong>
										{t('comment', { ns: 'naming' })}
										{' '}
										<span className="text-danger">{'*'}</span>
									</strong>
									<TextArea
										onInput={(e) => setComment(e.currentTarget.value)}
										value={comment}
									/>
								</div>
							</div>
						</div>
					) : (
						<div className="p-4">
							<h3>
								{t('at_least_one_valid_part', { ns: 'naming' })}
							</h3>
						</div>
					)}
				</div>
			)}
			buttons={selectedParts?.length > 0 ? [
				<button
					type="button"
					key="deleteBindingNotesButton"
					disabled={!deleteButtonEnabled}
					className={!rejectPartsAfterEditingBindingNote ? 'btn btn-danger' : 'd-none'}
					onClick={e => {
						e.preventDefault()
						handleDelete()
					}}
				>
					{deleteBindingNotesStatus.isLoading ? (
						<Loader />
					) : (
						t('delete', { ns: 'naming' })
					)}
				</button>,
				<button
					type="button"
					key="updateBindingNotesButton"
					disabled={!saveButtonEnabled}
					className={rejectPartsAfterEditingBindingNote ? 'btn btn-danger' : 'btn btn-primary'}
					onClick={e => {
						e.preventDefault()
						handleUpdate()
					}}
				>
					{updateBindingNotesStatus.isLoading ? (
						<Loader />
					) : (
						!rejectPartsAfterEditingBindingNote
							? t('save', { ns: 'naming' })
							: t('reject', { ns: 'naming' })
					)}
				</button>,
			] : []}
		/>
	)

	return (
		<React.Fragment>
			{MainModal}
			{ResultModal}
		</React.Fragment>
	)
}

EditBindingNoteModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	selectedParts: PropTypes.array,
	updateBindingNotes: PropTypes.func,
	updateBindingNotesStatus: PropTypes.any,
	deleteBindingNotes: PropTypes.func,
	deleteBindingNotesStatus: PropTypes.any,
	rejectPartsAfterEditingBindingNote: PropTypes.bool,
	updatePartsStatus: PropTypes.func,
	errorUpdatePartsStatus: PropTypes.bool,
	successUpdatePartsStatusWithinBindingNoteModal: PropTypes.bool,
	suppliersSelected: PropTypes.array,
}
