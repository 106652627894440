import OrganizationFlag from 'pages/Orders/OrganizationFlag'
import RealShipmentDate from 'pages/Orders/RealShipmentDate'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import PartIncidence from './PartIncidence'

export const OrderIncidence = ({ incidence, openedOrderIds, handleToggleOrder, managers }) => {
	const { order, items } = incidence

	const isOrderExpanded = openedOrderIds.includes(order.id)
	const iconClassName = isOrderExpanded ? 'fs-2 cursor-pointer bx bx-chevron-up' : 'fs-2 cursor-pointer bx bx-chevron-down'
	const incidencesCount = items.reduce((acc, item) => acc + item.incidences.length, 0)
	const manager = managers.find((manager) => manager.id === order.organization.managerId)

	return (
		<div className='d-flex flex-column'>
			<div className='bg-white w-100 my-1 mx-2 p-4 rounded d-flex flex-row align-items-center'>
				<span className='col-1'><i onClick={() => { handleToggleOrder(order.id) }} className={iconClassName} ></i></span>
				<span className='col-2'>{incidence.order.id}</span>
				<span className='col'>
					<OrganizationFlag
						country={order.shippingAddress?.country?.toLowerCase() || ''}
						organizationName={order.organization.organizationName || ''}
						firstName={order.shippingAddress.firstName || ''}
						lastName={order.shippingAddress.lastName || ''}
						userId={order.accountId || ''}
					/>
				</span>
				<span className='col'>{`${manager?.personalInformation?.firstName} ${manager?.personalInformation?.lastName}`}</span>
				<span className='col'>
					<RealShipmentDate date={order.estimatedShipmentDate} realDate={order.deliveryDate} />
				</span>
				<span className='col-1'>{incidencesCount}</span>
			</div>
			<div className='ms-4'>
				{isOrderExpanded && (
					items.map((item, index) => (
						<div key={index}>
							<PartIncidence item={item} />
						</div>
					))
				)}
			</div>
		</div>
	)
}

OrderIncidence.propTypes = {
	incidence: PropTypes.object,
	openedOrderIds: PropTypes.array,
	handleToggleOrder: PropTypes.func,
	managers: PropTypes.array,

}

const mapStateToProps = (state) => ({
	managers: state.Users.managers,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OrderIncidence))
