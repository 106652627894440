import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

const ButtonsList = ({
	buttons,
	handleOnChange,
	firstButtonActive,
	t,
	largeButtons,
}) => {
	const [buttonsList, setButtonList] = useState([])

	useEffect(() => {
		if (buttonsList.length == 0) {
			setButtonList(parseButtonList(buttons))
		}
	}, [buttons])

	const parseButtonList = butons => {
		return butons.map(element => {
			return {
				title: element.label,
				value: element.value,
				isActive: element.value == firstButtonActive ? true : false,
			}
		})
	}

	const parseButton = button => {
		const classNameActive = 'btn btn-primary mx-1  ' + (largeButtons ? 'btn-lg' : 'btn-sm')

		const className = 'btn btn-outline-warning mx-1 ' + (largeButtons ? 'btn-lg' : 'btn-sm')

		return (
			<button
				type="button"
				key="btn2"
				className={button.isActive ? classNameActive : className}
				onClick={e => activeButton(button)}
			>
				{t(button.title, { ns: 'naming' })}
			</button>
		)
	}
	const renderButtons =
		buttonsList && buttonsList.map(button => parseButton(button))

	const activeButton = button => {
		setButtonList(
			buttonsList.map(element => {
				if (element.title == button.title) {
					element.isActive = true
				} else {
					element.isActive = false
				}
				return element
			}),
		)
		handleOnChange('voucherTypeId', button.value)
	}

	const justifyContentCenterClassName = 'mb-2 d-flex justify-content-center'
	const justifyContentBetweenClassName = 'mb-2 d-flex justify-content-center p-2'

	return <div className={justifyContentBetweenClassName}>{renderButtons}</div>
}
ButtonsList.propTypes = {
	buttons: PropTypes.array,
	handleOnChange: PropTypes.func,
	firstButtonActive: PropTypes.string,
	t: PropTypes.func,
	largeButtons: PropTypes.bool,
}
export default withTranslation()(ButtonsList)
