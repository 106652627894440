import PropTypes from 'prop-types'
import React from 'react'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(/* error */) {
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		console.error('Uncaught error:', error, errorInfo)
	}

	render() {
		if (this.state.hasError) {
			return this.props.content || (
				<div style={{
					display: 'flex',
					height: '100vh',
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center',
				}}>
					<h3>Something went wrong...</h3>
				</div>
			)
		}

		return this.props.children
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	content: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
}

export default ErrorBoundary
