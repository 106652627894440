import classNames from 'classnames'
import { useCouriers } from 'common/Hooks/UseTranslatedCouriers'
import { chooseRealDate } from 'common/Utils/DateUtils'
import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import { roundAtDecimals, roundCurrency } from 'common/Utils/NumberUtilities'
import { getDate, getDateWithHour } from 'common/Utils/StringUtilities'
import { IconTooltip } from 'components/Common/IconTooltip'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PrintLabelsModal from 'components/Common/ProductionLabels/PrintLabelsModal'
import SimpleTable from 'components/Common/SimpleTable'
import { NoteTooltip } from 'components/NoteTooltip'
import Table from 'components/Table/Table'
import appRoutes from 'constants/appRoutes'
import { Couriers } from 'constants/couriers'
import { Tracking } from 'pages/SupplierProduction/Tracking'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import {
	Card,
	CardBody,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from 'reactstrap'
import { createInternalComment, getAccountByEmail, getOrganizationById } from 'store/actions'
import SelectPartOfOrderModal from './SelectPartOfOrderModal'
import InteractionsTable from './tables/InteractionsTable'
import InternalCommentTable from './tables/InternalCommentTable'
import QualityIssuesTable from './tables/QualityIssuesTable'

const OrderTabs = ({
	t,
	orderId,
	organizationId,
	clientNote,
	commentsList,
	qualityIssuesList,
	interactionList,
	productions,
	shipments,
	deliveryNotes,
	suppliers,
	openRemitModal,
	openInvoiceModal,
	email,
	getAccountByEmail,
	account,
	createInternalComment,
	isLoading,
	isCommentCreated,
	getOrganizationById,
	orderItemList,
	orderDetail
}) => {

	const PRODUCTION_STATUS = [
		{
			value: 1,
			label: <span>{t('production_status_1', { ns: 'status' })}</span>,
		},
		{
			value: 2,
			label: <span>{t('production_status_2', { ns: 'status' })}</span>,
		},
		{
			value: 3,
			label: <span>{t('production_status_3', { ns: 'status' })}</span>,
		},
		{
			value: 4,
			label: <span>{t('production_status_4', { ns: 'status' })}</span>,
		},
		{
			value: 5,
			label: <span>{t('production_status_5', { ns: 'status' })}</span>,
		},
		{
			value: 6,
			label: <span>{t('production_status_6', { ns: 'status' })}</span>,
		},
		{
			value: 7,
			label: <span>{t('production_status_7', { ns: 'status' })}</span>,
		},
		{
			value: 8,
			label: <span>{t('production_status_8', { ns: 'status' })}</span>,
		},
		{
			value: 9,
			label: <span>{t('production_status_9', { ns: 'status' })}</span>,
		},
		{
			value: 10,
			label: <span>{t('production_status_10', { ns: 'status' })}</span>,
		},
	]

	const { COURIER, COURIER_URL } = useCouriers(t)

	useEffect(() => {
		if (email) getAccountByEmail({ data: { email } })
	}, [])




	const filterOrderItems = (orderItemList, productions) => {
		const partIdsInProductions = productions.flatMap(production => production.productionItems.map(item => item.part.id))
		return orderItemList.filter(orderItem => partIdsInProductions.includes(orderItem.id.value))
	}

	const filteredOrderItemList = filterOrderItems(orderItemList, productions)

	const getSortedCrmInteractions = (interactions) => {
		const sortedInteractions = []
		for (const interaction of interactions) {
			interaction.date = getDateWithHour(interaction.date)
			sortedInteractions.push(interaction)
		}
		sortedInteractions.sort((a, b) => new Date(a.date) < new Date(b.date))
		return sortedInteractions
	}

	const [activeTab, setActiveTab] = useState('1')
	const [internalComment, setInternalComment] = useState([])
	const [newInternalComment, setNewInternalComment] = useState('')
	const [qualityIssues, setQualityIssues] = useState([])
	const [interaction, setInteraction] = useState([])
	const [isOpenCommentModal, setOpenCommentModal] = useState(false)
	const [isOpenRegisterIncidenceModal, setOpenRegisterIncidenceModal] = useState(false)
	const [isOpenMessageModal, setOpenMessageModal] = useState(false)

	useEffect(() => {
		if (internalComment && internalComment.length === 0) { setInternalComment(getSortedCrmInteractions(commentsList)) }
		if (qualityIssues && qualityIssues.length === 0) { setQualityIssues(getSortedCrmInteractions(qualityIssuesList)) }
		if (interaction && interaction.length === 0) { setInteraction(getSortedCrmInteractions(interactionList)) }
	}, [])

	useEffect(() => {
		if (isCommentCreated) {
			setInternalComment(
				[
					...internalComment,
					{
						managerFullName: `${account.personalInformation.firstName} ${account.personalInformation.lastName}`,
						message: newInternalComment,
						date: getDateWithHour(new Date()),
					},
				],
			)
			setNewInternalComment('')
			setOpenCommentModal(false)
		}
	}, [isCommentCreated])

	const saveNewInternalComment = () => {
		if (newInternalComment !== '') {
			const comment = {
				accountId: account.id,
				type: 'internal_comment',
				subject: 'Comentario interno',
				managerFullName: `${account.personalInformation.firstName} ${account.personalInformation.lastName}`,
				message: newInternalComment,
				orderId: orderId,
			}
			createInternalComment({ data: { comment } })
		}
	}

	const closeWithoutSaving = () => {
		setNewInternalComment('')
		setOpenCommentModal(false)
	}

	const getProductionList = () => {
		return productions.map(production => {
			return {
				id: orderId + '-' + production.orderFragmentId,
				supplier: suppliers.find(supplier => supplier.id == production.supplierId)?.personalInformation.firstName,
				status: PRODUCTION_STATUS.find(e => e.value === production.statusId).label,
				productionDate: getDateWithHour(production.orderDate),
				totalPrice: `${roundCurrency(production.totalPrice) || '-'}€`,
				shipmentDateEstimate: getDate(production.shipmentDateEstimate),
				shipmentDateReal: production.shipmentDateReal ? getDate(production.shipmentDateReal) : '-',
				shipmentDateDelay: production.shipmentDateDelay ? getDate(production.shipmentDateDelay) : '-',
				arrivalDate: chooseRealDate(production.arrivalDate, production.arrivalDateEstimated, t),
				comment: <NoteTooltip note={production.comment} t={t} />,
				tracking: <Tracking
					trackingId={production.shipmentTrackingNumber}
					courier={production.courier}
					estimatedArrivalDate={production.arrivalDateEstimated}
					status={production.statusId}
				/>,
				labeling: <PrintLabelsModal
					orderId={production.orderId}
					orderFragmentId={production.orderFragmentId}
					addCustomerInfoLabel
				/>,
			}
		})
	}

	const productionsHeader = {
		id: t('id', { ns: 'naming' }),
		supplier: t('supplier', { ns: 'naming' }),
		status: t('status', { ns: 'naming' }),
		productionDate: t('productionDate', { ns: 'naming' }),
		totalPrice: t('totalPrice', { ns: 'naming' }),
		shipmentDateEstimate: t('estimatedShipmentDate', { ns: 'naming' }),
		shipmentDateReal: t('realShipmentDate', { ns: 'naming' }),
		shipmentDateDelay: t('delayedShipmentDate', { ns: 'naming' }),
		arrivalDate: t('arrivalDate', { ns: 'naming' }),
		comment: t('comment', { ns: 'naming' }),
		tracking: t('tracking', { ns: 'naming' }),
		labeling: t('labeling', { ns: 'naming' }),
	}

	const shipmentsHeader = ['id', 'date', 'courier', 'tracking', 'delivery-note', 'label', 'weight', 'cost']
	const getParsedShipments = () => {
		return shipments.map((shipment) => {
			return <tr key={`shipment_${shipment.id}`} valign="middle">
				<td>{shipment.id}</td>
				<td>{getDateWithHour(shipment.shippingDate)}</td>
				<td>{COURIER[shipment.transporter.id]}</td>
				<td>{shipment.transporter.id === Couriers.HAND_DELIVERY ? (shipment.trackingNumber) : (<a
					href={COURIER_URL[shipment.transporter.id] + shipment.trackingNumber}
					target="_blank"
					rel="noopener noreferrer"
				>{shipment.trackingNumber}</a>)}</td>
				<td>{shipment.deliveryNote?.src != null && (
					<a
						href={shipment.deliveryNote.src}
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconTooltip
							key={`delivery_note_${shipment.id}`}
							name={`delivery_note_${shipment.id}`}
							icon="bx bxs-file fs-3"
							message={shipment.deliveryNote.name}
							position="top"
						/>
					</a>
				)}</td>
				<td>{shipment.label?.src != null && (
					<a
						href={shipment.label.src}
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconTooltip
							key={`label_${shipment.id}`}
							name={`label_${shipment.id}`}
							icon="bx bxs-box fs-3"
							message={shipment.label.name}
							position="top"
						/>
					</a>
				)}</td>
				<td>{shipment.weight < 1000 ? `${roundAtDecimals(shipment.weight, 0)} g` : `${roundAtDecimals(shipment.weight / 1000, 2)} kg`}</td>
				<td>{shipment.shippingCost}€</td>
			</tr>
		})
	}

	const deliveryNotesHeader = ['id', 'name', 'type', 'file']
	const getParsedDeliveryNotes = () => {
		return deliveryNotes.map((deliveryNote, index) => {
			const deliveryNoteType = deliveryNote.name.includes("PG") ? t("invoice", { ns: "naming" }) : deliveryNote.name.includes("AB") ? t("remit", { ns: "naming" }) : ""
			return <tr key={`deliveryNote_${index}`} valign="middle">
				<td>{index + 1}</td>
				<td>{deliveryNote.name}</td>
				<td>{deliveryNoteType}</td>
				<td>{deliveryNote?.src != null && (
					<a
						href={deliveryNote.src}
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconTooltip
							key={`delivery_note_${index}`}
							name={`delivery_note_${index}`}
							icon="bx bxs-file fs-3"
							message={deliveryNote.name}
							position="top"
						/>
					</a>
				)}</td>
			</tr>
		})
	}

	const startRemitGeneration = () => {
		openRemitModal()
		getOrganizationById({ id: organizationId })
	}

	const startInvoiceGeneration = () => {
		openInvoiceModal()
		getOrganizationById({ id: organizationId })
	}

	return (
		<div className="mt-4">
			<Card className="col-12 mt-1 mb-4">
				<CardBody>
					<ul className="nav nav-tabs nav-tabs-custom" role="tablist">
						<NavItem>
							<NavLink
								className={classNames({
									active: activeTab === '1',
								})}
								onClick={() => {
									setActiveTab('1')
								}}
							>
								{t('clientNote', { ns: 'naming' })} {clientNote && <i className="bx bxs-error"></i>}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classNames({
									active: activeTab === '2',
								})}
								onClick={() => {
									setActiveTab('2')
								}}
							>
								{t('internalComment', { ns: 'naming' })} {internalComment && internalComment.length !== 0 && <i className="bx bxs-error"></i>}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classNames({
									active: activeTab === '3',
								})}
								onClick={() => {
									setActiveTab('3')
								}}
							>
								{t('qualityIssues', { ns: 'naming' })} {qualityIssues && qualityIssues.length !== 0 && <i className="bx bxs-error"></i>}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classNames({
									active: activeTab === '4',
								})}
								onClick={() => {
									setActiveTab('4')
								}}
							>
								{t('interaction', { ns: 'naming' })} {interaction && interaction.length !== 0 && <i className="bx bxs-error"></i>}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classNames({
									active: activeTab === '5',
								})}
								onClick={() => {
									setActiveTab('5')
								}}
							>
								{t('productions', { ns: 'naming' })} {productions && productions.length !== 0 && <i className="bx bxs-error"></i>}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classNames({
									active: activeTab === '6',
								})}
								onClick={() => {
									setActiveTab('6')
								}}
							>
								{t('shipments', { ns: 'naming' })} {shipments && shipments.length !== 0 && <i className="bx bxs-error"></i>}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={classNames({
									active: activeTab === "7",
								})}
								onClick={() => {
									setActiveTab("7")
								}}
							>
								{t("delivery-notes", { ns: "naming" })} {deliveryNotes && deliveryNotes.length !== 0 && <i className="bx bxs-error"></i>}
							</NavLink>
						</NavItem>
					</ul>

					<TabContent activeTab={activeTab} className="p-3">
						<TabPane tabId="1" id="all-order">
							{clientNote}
						</TabPane>
						<TabPane tabId="2" id="processing">
							<div style={{ whiteSpace: 'pre-wrap' }} className="mb-2">{internalComment && internalComment.length !== 0 && <InternalCommentTable internalComments={internalComment} />}</div>
							<button
								className="btn btn-outline-secondary"
								onClick={() => setOpenCommentModal(true)}
							>
								<i className="bx bxs-message-alt-add mx-2"></i>
								<span className="py-2">{t('new', { ns: 'naming' })}</span>
							</button>
						</TabPane>
						<TabPane tabId="3" id="all-order">
							{qualityIssues && qualityIssues.length !== 0 && <QualityIssuesTable qualityIssues={qualityIssues} />}
							<button
								className="btn btn-outline-secondary"
								onClick={() => productions && productions.length !== 0 ? setOpenRegisterIncidenceModal(true) : setOpenMessageModal(true)}

							>
								<i className="bx bxs-message-alt-add mx-2"></i>
								<span className="py-2">{t('new', { ns: 'naming' })}</span>
							</button>
						</TabPane>
						<TabPane tabId="4" id="all-order">
							{interaction && interaction.length !== 0 && <InteractionsTable interactions={interaction} />}
						</TabPane>
						<TabPane tabId="5" id="all-order">
							{productions && productions.length !== 0 && <Table
								disableInteractions
								header={productionsHeader}
								items={getProductionList()}
								selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
								language={getLanguage()}
								rowQuantityList={[{ text: '32', value: 32 }]}
								allowAllItemsPerPage
								pageToNavigate={appRoutes.PRODUCTION_DETAIL_NO_ID}
								defaultSort={{
									dataField: 'orderDate',
									order: 'desc',
								}}
								styleForFieldList={[{
									field: 'id',
									styles: [
										{
											type: 'link',
										},
									],
								}]}
							></Table>}
						</TabPane>
						<TabPane tabId="6" id="order-shipments">
							{shipments && shipments.length !== 0 && (
								<SimpleTable
									key="order_shipments_table"
									header={shipmentsHeader}
									getTableBody={() => getParsedShipments()}
								/>
							)}
						</TabPane>
						<TabPane tabId="7" id="order-delivery-notes">
							<div>
								<button className="btn btn-primary mb-1 me-1 w-auto" onClick={() => startRemitGeneration()}>{t("new-remit", { ns: "naming" })}</button>
								<button className="btn btn-primary mb-1 ms-1 w-auto" onClick={() => startInvoiceGeneration()}>{t("new-invoice", { ns: "naming" })}</button>
								{deliveryNotes && deliveryNotes.length !== 0 && (
									<SimpleTable
										key="order_delivery_notes_table"
										header={deliveryNotesHeader}
										getTableBody={() => getParsedDeliveryNotes()}
									/>
								)}

							</div>
						</TabPane>
					</TabContent>
				</CardBody>
			</Card>
			<Modal
				className="internalCommentModal"
				isOpen={isOpenCommentModal}
				closeButtonText={t('close', { ns: 'naming' })}
				closeModal={() => closeWithoutSaving()}
				title={t('newComment', { ns: 'naming' })}
				body={
					<div className="mx-3">
						<textarea
							className="form-control"
							rows={10}
							name={t('internalComment', { ns: 'naming' })}
							value={newInternalComment}
							onChange={e => setNewInternalComment(e.target.value)}
						></textarea>
					</div>
				}
				buttons={[
					<button
						key="save"
						type="button"
						className="btn btn-warning"
						onClick={() => saveNewInternalComment()}
					>
						{!isLoading ? <span className="p-2">{t('save', { ns: 'naming' })}</span> : <Loader />}
					</button>,
				]}
			/>

			<SelectPartOfOrderModal
				isOpen={isOpenRegisterIncidenceModal}
				closeModal={() => setOpenRegisterIncidenceModal(false)}
				orderItemList={filteredOrderItemList}
				productions={productions}
				orderDetail={orderDetail}
				account={account}
			/>

			<ModalMessage isModalOpen={isOpenMessageModal} closeModal={() => setOpenMessageModal(false)} message={t("no-productions", { ns: "naming" })} />

		</div>
	)
}

const mapStateToProps = state => {
	return {
		email: state.Login.email,
		account: state.Users.account,
		isLoading: state.Orders.isLoading,
		isCommentCreated: state.Orders.isCommentCreated,
	}
}
OrderTabs.propTypes = {
	t: PropTypes.func,
	orderId: PropTypes.number,
	organizationId: PropTypes.number,
	clientNote: PropTypes.string,
	commentsList: PropTypes.array,
	qualityIssuesList: PropTypes.array,
	interactionList: PropTypes.array,
	productions: PropTypes.array,
	shipments: PropTypes.array,
	deliveryNotes: PropTypes.array,
	suppliers: PropTypes.array,
	openRemitModal: PropTypes.func,
	openInvoiceModal: PropTypes.func,
	email: PropTypes.string,
	getAccountByEmail: PropTypes.func,
	account: PropTypes.object,
	createInternalComment: PropTypes.func,
	isLoading: PropTypes.bool,
	isCommentCreated: PropTypes.bool,
	getOrganizationById: PropTypes.func,
	orderItemList: PropTypes.array,
	orderDetail: PropTypes.object
}
export default withRouter(connect(mapStateToProps, { getAccountByEmail, createInternalComment, getOrganizationById })(
	withTranslation()(OrderTabs),
))
