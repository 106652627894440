import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const GeneralFilter = ({ t, optionsToFilter, onChange, title }) => {
	const parseOption = option => {
		return {
			isActive: false,
			text: option,
		}
	}

	const getOptionsArray = () => {
		return optionsToFilter.map(element => {
			return parseOption(element)
		})
	}

	const [isToggleOpened, setToggle] = useState(false)
	const [optionsActiveArray, setOptionActiveArray] = useState([])

	useEffect(() => {
		if (optionsActiveArray.length === 0) {
			const optionsParsed = getOptionsArray()
			setOptionActiveArray(optionsParsed)
		}
	}, [optionsToFilter])
	const renderInput = option => {
		return (
			<div
				className={'p-3 list-status-item-filter cursor-pointer d-flex'}
				onClick={e => changeListItems(option.text, setOptionActiveArray)}
			>
				<div className="mx-2">
					<i
						className={
							option.isActive
								? 'bx bxs-check-square fs-4' + ' text-primary'
								: 'bx bxs-check-square fs-4' + ' text-white'
						}
					></i>
				</div>
				<div className="mx-2">{option.text}</div>
			</div>
		)
	}

	const changeListItems = (option, setOptionsActiveArray) => {
		activate(option, setOptionsActiveArray)
		onChange(getActiveOptions())
	}

	const activate = (option, setOptionsActiveArray) => {
		const newArray = optionsActiveArray.map(element =>
			activateOption(element, option),
		)
		setOptionsActiveArray(newArray)
	}

	const activateOption = (element, option) => {
		if (element.text == option) {
			return activateElement(element, !element.isActive)
		}
		return element
	}

	const activateElement = (element, bool) => {
		const elementActive = Object.assign(element)
		elementActive.isActive = bool
		return elementActive
	}

	const options =
		optionsActiveArray && optionsActiveArray.map(status => renderInput(status))
	const getActiveOptions = () => {
		return optionsActiveArray.filter(element => element.isActive)
	}
	return (
		<div className="my-1">
			<Dropdown
				isOpen={isToggleOpened}
				direction="center"
				size="sm"
				toggle={e => setToggle(!isToggleOpened)}
			>
				<DropdownToggle color="white" className="btn btn-secondary">
					{t(title, { ns: 'naming' })}
				</DropdownToggle>
				<DropdownMenu data-popper-placement="left-start">
					<div className="my-3 mb-0">
						<div className="d-flex flex-column justify-content-start filter-width">
							{options && options}
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
		</div>
	)
}

GeneralFilter.propTypes = {
	t: PropTypes.func,
	statusArray: PropTypes.array,
	onChange: PropTypes.func,
	resetFilter: PropTypes.func,
	optionsToFilter: PropTypes.array,
	title: PropTypes.string,
}

export default withTranslation()(GeneralFilter)
