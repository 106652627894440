import Modal from 'components/Common/Modal/Modal'
import PrefixFlag from 'pages/CRMUserDetail/ComponenteTablas/prefixFlagComponent'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
	Card,
	CardBody,
	Col,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap'
import { createNewCustomer, getManagers } from 'store/actions'

import { getSectorOptionsTranslated } from 'common/Utils/ActivitySectorUtils'
import { findManager, mapManagers } from 'common/Utils/ManagersUtils'
import { checkIsPublicDomain } from 'common/Utils/StringUtilities'
import { getCountryCodeByPrefix } from 'common/Utils/phonePrefixByCountry'
import Loader from 'components/Common/Loader'
import TextInput from 'components/Common/TextInput'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import { withTranslation } from 'react-i18next'
import Select from 'react-select'
import { createNewLead } from 'store/actions'

const ModalCreateUser = ({ createNewCustomer, errorCreateUser, isCustomer, t, managers, getManagers, isLoadingCreateUser, createNewLead, userList }) => {

	const emptyUser = {
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		prefix: '',
		organizationName: '',
		sector: '',
		managerId: '',
		comments: '',
	}

	const emptyCommonData = {
		managerId: null,
		domain: null,
	}

	const CUSTOMER_ROLE = 5

	const CUSTOMER_FIELDS = ['firstName', 'lastName', 'email', 'phoneNumber', 'prefix', 'organizationName', 'sector', 'managerId']
	const LEAD_FIELDS = ['email', 'manager']

	const [userData, setUserData] = useState(emptyUser)
	const [isOpenCreateCustomerModal, setCustomerModal] = useState(false)
	const [hasPressed, setPressed] = useState(false)
	const [isModalMessageOpen, setIsModalMessageOpen] = useState(false)
	const [message, setMessage] = useState(null)
	const [isOpenConfirmModal, setConfirmModal] = useState(false)
	const [mandatoryFields, setMandatoryFields] = useState([])
	const [commonData, setCommonData] = useState(emptyCommonData)
	const [userAllreadyExist, setUserAllreadyExist] = useState({
		exists: false,
		role: null,
	})
	const [buttonDisabled, setButtonDisabled] = useState(false)

	const checkMails = (email) => {
		mailAlreadyExist(email)
		getDomainData(email)
	}

	const mailAlreadyExist = (email) => {
		const user = userList.find(user => user.email === email)
		setUserAllreadyExist({ exists: user != null, role: user?.role })
	}

	const getDomainData = (email) => {
		if (!email || !email.includes('@') || checkIsPublicDomain(email)) {
			setCommonData(emptyCommonData)
			return null
		}
		const splittedEmail = email.split('@')[1]
		const sameDomainUsers = userList.filter(user => splittedEmail === user.email?.split('@')[1])
		if (sameDomainUsers.length === 0) {
			setCommonData(emptyCommonData)
			return null
		}
		const manager = findManager(managers, sameDomainUsers[0]?.managerId)
		setCommonData({ domain: sameDomainUsers[0]?.purchaseStats?.domain, managerId: manager })
		updateUserData('managerId', manager)
	}

	const updateUserData = (key, value) => {
		setUserData({ ...userData, [key]: value })
	}

	useEffect(() => {
		if (managers.lenght === 0) getManagers()
	}, [managers])

	useEffect(() => {
		if (isCustomer) {
			setMandatoryFields(CUSTOMER_FIELDS)
		} else {
			setMandatoryFields(LEAD_FIELDS)
		}
	}, [isCustomer])

	useEffect(() => {
		if (errorCreateUser != null) {
			setMessage(t(errorCreateUser, { ns: 'naming' }))
		}
	}, [errorCreateUser])

	useEffect(() => {
		if (message != null && !isModalMessageOpen) {
			setConfirmModal(false)
			setIsModalMessageOpen(true)

		}
	}, [message])

	const isAllDataCompleted = () => {
		const arrayData = Object.entries(userData)
		const currentMandatoryFields = arrayData.filter(([key, value]) => mandatoryFields.includes(key) && !value)
		return currentMandatoryFields.length === 0
	}

	const saveData = (sendRecoveryEmail) => {
		setPressed(true)
		if (isAllDataCompleted()) {
			const data = mapDataToInputDTO(sendRecoveryEmail)
			if (isCustomer) {
				createNewCustomer(data)
			} else {
				createNewLead(data)
			}
		}
	}

	const mapDataToInputDTO = (sendRecoveryEmail) => {
		return {
			data: {
				account: {
					loginCredentials: {
						email: userData.email,
					},
					personalInformation: {
						firstName: userData.firstName,
						lastName: userData.lastName,
						phones: [
							{
								prefix: userData.prefix,
								number: userData.phoneNumber,
							},
						],
						language: getCountryCodeByPrefix(userData.prefix),
						country: getCountryCodeByPrefix(userData.prefix),
					},
					comments: userData.comments,
					phoneData: {
						phone: {
							number: userData.phoneNumber,
							prefix: userData.prefix,
						},
					},
				},
				managerId: userData.managerId.value,
				activitySector: userData.sector.value,
				organizationName: userData.organizationName,
				sendRecoveryEmail: sendRecoveryEmail,
			},
		}
	}

	const openModalConfirm = () => {
		setPressed(true)
		if (isAllDataCompleted()) setConfirmModal(true)
	}

	const getModalTitle = () => {
		return isCustomer ? t('add_new_customer', { ns: 'naming' }) : t('add_new_lead', { ns: 'naming' })
	}

	const closeModalMessage = () => {
		location.reload()
	}

	const checkButtonDisabled = () => {
		if (!isCustomer) {
			return userAllreadyExist.exists
		}
		return userAllreadyExist.exists && userAllreadyExist.role === CUSTOMER_ROLE
	}

	useEffect(() => {
		setButtonDisabled(checkButtonDisabled())
	}, [userAllreadyExist])

	return (
		<div>
			<button
				type="button"
				className="mx-1 btn btn btn-primary d-flex"
				onClick={e => setCustomerModal(true)}
			>
				<i className='bx bx-user-plus fs-4'></i> <p className="m-0 mx-1">{!isCustomer ? t('account_user_role_7', { ns: 'userRoles' }) : t('account_user_role_5', { ns: 'userRoles' })}</p>
			</button>
			<Modal
				isOpen={isOpenCreateCustomerModal}
				closeModal={() => setCustomerModal(false)}
				title={getModalTitle()}
				className="largeModal"
				body={
					<Row>
						<Col>
							<div className="pt-4 ps-2 pe-4">
								<h4 className="d-flex flex-column">
									<div>
										<i className="bx bx-user ms-3 mx-1"></i>{t('personal_info', { ns: 'naming' })}
									</div>
								</h4>
								<Card className="col-12 mt-1 mx-0">
									<CardBody className="pt-1">
										<TabContent activeTab={true} className="p-3 pt-0">
											<TabPane tabId={true}>
												<div>
													<div>
														<TextInput
															value={userData.firstName}
															setValue={updateUserData}
															title={t('name', { ns: 'naming' })}
															objectElement="firstName"
															hasPressed={hasPressed}
															isRequired={mandatoryFields.includes('firstName')}
														/>
													</div>
													<div className="my-4">
														<TextInput
															value={userData.lastName}
															setValue={updateUserData}
															objectElement="lastName"
															title={t('lastName', { ns: 'naming' })}
															hasPressed={hasPressed}
															isRequired={mandatoryFields.includes('lastName')}
														/>
													</div>
													<div className="my-4 d-flex flex-column">
														<TextInput
															value={userData.email}
															setValue={updateUserData}
															objectElement="email"
															title={t('email', { ns: 'naming' })}
															hasPressed={hasPressed}
															isRequired={mandatoryFields.includes('email')}
															onBlur={checkMails}
														/>
														{commonData.domain &&
															<strong>
																<small className="text-danger ms-2">
																	<span className="me-1">
																		{t('user_belongs_to_domain', { ns: 'naming' })}
																	</span>
																	<span>
																		{commonData.domain}
																	</span>
																</small>
															</strong>
														}
														{userAllreadyExist.exists &&
															<strong>
																<small className="text-danger ms-2">
																	<span className="me-1">
																		{t('user_already_exist', { ns: 'naming' })}
																		<span className="mx-1">({t('account_user_role_' + userAllreadyExist.role, { ns: 'userRoles' })})</span>
																	</span>
																</small>
															</strong>
														}
													</div>
													<div className="row mt-0 ms-1">
														<div className="col-4 px-0">
															<div className="d-flex">
																{
																	isCustomer && <span className="text-primary me-1">✲</span>
																}
																<h6>{t('prefix', { ns: 'naming' })}</h6>
															</div>
															<PrefixFlag
																isEditable
																onChange={e => updateUserData('prefix', e.value)}
																prefixState={userData.prefix}
																isRequired={mandatoryFields.includes('prefix')}
															/>
															{isCustomer && hasPressed && !userData.prefix &&
																<small className="text-red">{t('mandatory_field', { ns: 'naming' })}</small>
															}
														</div>
														<div className="col-8 px-2">
															<TextInput
																value={userData.phoneNumber}
																setValue={updateUserData}
																objectElement="phoneNumber"
																title={t('phone_number', { ns: 'naming' })}
																hasPressed={hasPressed}
																isRequired={mandatoryFields.includes('phoneNumber')}
																type={'number'}
																className="my-0"
															/>
														</div>
													</div>
												</div>
											</TabPane>
										</TabContent>
									</CardBody>
								</Card>
							</div>
						</Col>
						<Col>
							<div className="pt-4 ps-2 pe-4">
								<h4>
									<i className="bx bxs-bank ms-3 mx-1"></i>{t('organization_info', { ns: 'naming' })}
								</h4>
								<div className=" row">
									<div className="d-flex justify-content-start m-0 p-0 col-12">
										<Card className="col-12 mt-1 mx-0">
											<CardBody className="pt-1">
												<div>
													<TextInput
														value={userData.organizationName}
														setValue={updateUserData}
														title={t('name', { ns: 'naming' })}
														objectElement="organizationName"
														hasPressed={hasPressed}
														isRequired={mandatoryFields.includes('organizationName')}
													/>
												</div>
												<div className="px-2">
													<div className="d-flex">
														{mandatoryFields.includes('sector') && <span className="text-primary me-1">✲</span>}
														<h6>{t('sector', { ns: 'naming' })}</h6>
													</div>
													<Select
														value={userData.sector}
														onChange={e => updateUserData('sector', e)}
														options={getSectorOptionsTranslated(t)}
														placeholder={'...'}
													/>
													{hasPressed && !userData.sector && mandatoryFields.includes('sector') &&
														<small className="text-red">{t('mandatory_field', { ns: 'naming' })}</small>
													}
												</div>
												<div className="px-2 my-3">
													<div className="d-flex">
														<span className="text-primary me-1">✲</span>
														<h6>{t('manager', { ns: 'naming' })}</h6>
													</div>
													<Select
														value={userData.managerId}
														onChange={e => updateUserData('managerId', e)}
														options={mapManagers(managers)}
														placeholder={'...'}
														isDisabled={commonData.managerId != null}
													/>
													{hasPressed && !userData.managerId &&
														<small className="text-red">{t('mandatory_field', { ns: 'naming' })}</small>
													}
												</div>
												<div className="ms-1">
													<h6>{t('observations', { ns: 'naming' })}</h6>
													<textarea className="form-control w-100" style={{ height: '90px' }} onChange={e => updateUserData('comments', e.target.value)}>

													</textarea>
												</div>
											</CardBody>
										</Card>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				}
				buttons={[
					<button
						type="button"
						key="btn2"
						className="btn btn-primary"
						onClick={e => openModalConfirm()}
						disabled={buttonDisabled}
					>
						{t('save', { ns: 'naming' })}
					</button>,
				]}

			/>

			<Modal
				isOpen={isOpenConfirmModal}
				closeModal={e => setConfirmModal(false)}
				body={
					!isLoadingCreateUser ?
						isCustomer ?
							<div className="p-4">
								<h4>{t('are_you_sure', { ns: 'naming' })}</h4>
								<h5>{t('this_send_a_mail_to_customer', { ns: 'naming' })}</h5>
							</div>
							: <div className="p-4">
								<h4>{t('create_lead', { ns: 'naming' })}</h4>
							</div>
						: <div className="p-4"><Loader className="fs-2" /></div>
				}
				buttons={isCustomer ? [
					<button
						type="button"
						key="btn2"
						className="btn btn-primary"
						onClick={e => saveData(false)}
					>
						{t('save_without_sending_mail', { ns: 'naming' })}
					</button>,
					<button
						type="button"
						key="btn2"
						className="btn btn-primary"
						onClick={e => saveData(true)}
					>
						{t('save_and_sending_mail', { ns: 'naming' })}
					</button>
					,

				] : [
					<button
						type="button"
						key="btn2"
						className="btn btn-primary"
						onClick={e => saveData(false)}
					>
						{t('save', { ns: 'naming' })}
					</button>,
				]
				}
			/>
			<ModalMessage isModalOpen={isModalMessageOpen} closeModal={closeModalMessage} message={message} />
		</div>
	)
}

const mapStateToProps = state => {
	return {
		managers: state.Users.managers,
		errorCreateUser: state.Crm.errorCreateUser,
		isLoadingCreateUser: state.Crm.isLoadingCreateUser,
	}
}

ModalCreateUser.propTypes = {
	managers: PropTypes.array,
	createNewCustomer: PropTypes.func,
	errorCreateUser: PropTypes.any,
	organizations: PropTypes.array,
	requiredItems: PropTypes.array,
	sectors: PropTypes.array,
	isCustomer: PropTypes.bool,
	t: PropTypes.func,
	getManagers: PropTypes.func,
	isLoadingCreateUser: PropTypes.bool,
	createNewLead: PropTypes.func,
	userList: PropTypes.array,
}

export default connect(mapStateToProps, { createNewCustomer, getManagers, createNewLead })(
	withTranslation()(ModalCreateUser),
)
