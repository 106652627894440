import { call, put, takeEvery } from 'redux-saga/effects'

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, VERIFY_USER_TOKEN } from './actionTypes'
import {
	apiError,
	loginSuccess,
	logoutUserSuccess,
	verifyUserTokenFail,
	verifyUserTokenSuccess,
} from './actions'

import { post } from '../../../helpers/api_helper'

import { hashPassword } from '../../../common/Utils/HashingUtilities'
import {
	LOGIN_ENDPOINT,
	LOGOUT_ENDPOINT,
	VERIFY_TOKEN_ENDPOINT,
} from '../../../constants/backendRoutes'

function* loginUser({ payload: { user, history } }) {
	try {
		const url = LOGIN_ENDPOINT
		const args = {
			data: {
				email: user.email,
				password: hashPassword(user.password),
				rememberMe: user.rememberMe ? user.rememberMe : false,
			},
		}
		const config = {}
		const isReturningWholeResponse = true

		const response = yield call(
			post,
			url,
			args,
			config,
			isReturningWholeResponse,
		)
		yield put(loginSuccess(response))
	} catch (error) {
		const errorMessage = error.message || 'unknownError'
		yield put(apiError(errorMessage))
	}
}

function* logoutUser({ payload: { email } }) {
	try {
		const url = LOGOUT_ENDPOINT
		const data = {
			email,
		}
		yield call(post, url, data)
		yield put(logoutUserSuccess())
	} catch (error) {
		yield put(apiError(error))
	}
}

function* verifyUserToken({ payload: { email, token } }) {
	try {
		const url = VERIFY_TOKEN_ENDPOINT
		const data = {
			tokenToVerify: token,
		}
		yield call(post, url, { data })
		yield put(verifyUserTokenSuccess())
	} catch (error) {
		yield put(verifyUserTokenFail())
	}
}

function* authSaga() {
	yield takeEvery(LOGIN_USER, loginUser)
	yield takeEvery(LOGOUT_USER, logoutUser)
	yield takeEvery(VERIFY_USER_TOKEN, verifyUserToken)
}

export default authSaga
