import classnames from 'classnames'
import { useState } from 'react'
import { connect } from 'react-redux'
import {
	Card,
	CardBody,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
} from 'reactstrap'
import DomainOffers from './DomainOffers'
import DomainOrders from './DomainOrders'

export const OffersAndOrdersTables = (props) => {

	const [activeTab, setActiveTab] = useState(1)

	return (
		<Card className='mt-2'>
			<CardBody>
				<ul className="nav nav-tabs nav-tabs-custom" role="tablist">
					<NavItem>
						<NavLink
							className={classnames({
								active: activeTab === 1,
							})}
							onClick={() => {
								setActiveTab(1)
							}}
						>
							Ofertas
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({
								active: activeTab === 2,
							})}
							onClick={() => {
								setActiveTab(2)
							}}
						>
							Pedidos
						</NavLink>
					</NavItem>
				</ul>

				<TabContent
					activeTab={activeTab}
					className="p-3"
				>
					<TabPane tabId={1} id="all-order">
						<DomainOffers />
					</TabPane>
					<TabPane tabId={2} id="processing">
						<DomainOrders isTabSelected={activeTab === 2} />
					</TabPane>
				</TabContent>
			</CardBody>
		</Card>
	)
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(OffersAndOrdersTables)
