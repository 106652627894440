import PropTypes from 'prop-types'

export const SectionTitle = ({ bgColor, title }) => {
	return (
		<div className="row d-flex">
			<div className="position relative">
				<hr className="border border-dark border-1 opacity-75 pt-1 mt-4"></hr>
				<div
					className={
						bgColor +
						' position-absolute top-0 start-50 translate-middle-x p-2 px-4 mb-2'
					}
				>
					<h1>{title}</h1>
				</div>
			</div>
		</div>
	)
}

SectionTitle.propTypes = {
	bgColor: PropTypes.string,
	title: PropTypes.string,
}
