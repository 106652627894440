export const bannedDomains = [
	'bouyguestelecom',
	'burdigala',
	'cdiscount',
	'connexion.numericable',
	'creditmutuel',
	'edf',
	'eresmas',
	'faceaface',
	'free',
	'gmail',
	'gmx',
	'hotmail',
	'inbox',
	'laposte',
	'libero',
	'live',
	'mail',
	'me',
	'mediacg',
	'movistar',
	'msn',
	'mycanal',
	'ono',
	'orange',
	'outlook',
	'prconsultingparis',
	'prformance',
	'rpca',
	'sfr',
	'societegenerale',
	'telefonica',
	'tiscali',
	'vfcrp',
	'wanadoo',
	'web',
	'yahoo',
]
