import classNames from 'classnames'
import { getDateWithHour } from 'common/Utils/StringUtilities'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Card, CardBody, NavItem, NavLink, TabContent, TabPane } from 'reactstrap'
import SimpleBar from 'simplebar-react'

export const ChatModal = ({ t, isOpen, closeModal, partId, messages, suppliers, managers }) => {
	const [activeTab, setActiveTab] = useState()
	const [isLoading, setIsLoading] = useState(false)

	const parsedMessages = useMemo(() => {
		setIsLoading(true)
		if (messages.length != 0) {
			setActiveTab(`${messages[0].supplierId}`)
		}
		const parsedMessages = messages.map((message) => {
			const isSupplier = message.accountId == message.supplierId
			const account = isSupplier ? suppliers?.find((supplier) => supplier.id == message.accountId) : managers?.find((manager) => manager.id == message.accountId)
			return {
				...message,
				name: isSupplier ? account?.personalInformation.firstName : `${account?.personalInformation?.firstName || ''} ${account?.personalInformation?.lastName || ''}`,
			}
		})
		setIsLoading(false)
		return parsedMessages
	}, [messages])

	return (
		<>
			<Modal
				className={messages.length != 0 && 'modal-lg'}
				isOpen={isOpen}
				closeModal={closeModal}
				title={`${t('chat', { ns: 'naming' })} - ${t('part', { ns: 'naming' })} ${partId}`}
				body={
					<div className="px-4 py-2">
						{isLoading ? <Loader /> : messages.length == 0 ? t('no-messages', { ns: 'naming' }) : (
							<Card className="col-12 mt-1 mb-4">
								<CardBody>
									<ul className="nav nav-tabs nav-tabs-custom" role="tablist">
										{suppliers?.map((supplier) => {
											return (
												<NavItem key={`supplier_${supplier.id}`}>
													<NavLink
														key={`supplier_${supplier.id}`}
														className={classNames({
															active: activeTab === `${supplier.id}`,
														})}
														onClick={() => {
															setActiveTab(`${supplier.id}`)
														}}
													>
														{supplier.personalInformation.firstName}
													</NavLink>
												</NavItem>
											)
										})}
									</ul>
									<SimpleBar style={{ maxHeight: '40rem' }}>
										<TabContent activeTab={activeTab} className="p-3">
											{parsedMessages?.map((message) => {
												const isSupplier = message.supplierId == message.accountId
												return (
													<TabPane key={`message_${message.id}`} tabId={`${message.supplierId}`} id={`message_${message.id}`}>
														<section className={`sectionprov-${isSupplier ? 'imas' : 'client'}`}>
															<text className={`textprov-${isSupplier ? 'imas' : 'client'}`}>
																{message.name}
																<br />
																{message.image != null ? (
																	<a href={message.image.src} target="_blank" rel="noopener noreferrer">
																		<img src={message.image.src} className="img-chat" />
																	</a>
																) : (
																	<span style={{ fontWeight: 'normal' }}>{message.content}</span>
																)}
																<br />
																<span style={{ fontWeight: 'normal', color: 'grey' }}>{getDateWithHour(message.creationDate)}</span>
															</text>
														</section>
													</TabPane>
												)
											})}
										</TabContent>
									</SimpleBar>
								</CardBody>
							</Card>
						)}
					</div>
				}
			/>
		</>
	)
}

const mapStateToProps = state => {
	return {
		managers: state.Users.managers,
	}
}

ChatModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	partId: PropTypes.number,
	messages: PropTypes.array,
	suppliers: PropTypes.array,
	managers: PropTypes.array,
}

export default connect(mapStateToProps, {})(withTranslation()(ChatModal))
