import { getDateWithHour } from 'common/Utils/StringUtilities'
export const getIcon = (interaction) => {
	switch (interaction.type) {
		case 'quote':
			return <i className="text-success bx bxs-file-plus mt-1 mx-2"></i>
		case 'order':
			return <i className="text-primary bx bx-store-alt mt-1 mx-2 "></i>
		case 'expedition':
			return <i className="text-muted bx bx-package mt-1 mx-2"></i>
		case 'internal_comment':
			return <i className="text-muted bx bx-file mt-1 mx-2"></i>
		case 'quality':
			return <i className="bx bx-circle mt-1 mx-2 text-primary"></i>
		case 'agent':
			return getAgentIcon(interaction.interaction)
	}
}

const getAgentIcon = (interaction) => {
	switch (interaction) {
		case 'chat':
			return <i className="bx bx-message-rounded-dots mt-1 mx-2 text-muted"></i>
		case 'call':
			return <i className="bx bx-phone mt-1 mx-2 text-muted"></i>
		case 'email':
			return <i className="bx bxs-envelope mt-1 mx-2 text-muted"></i>
		case 'visit':
			return <i className="bx bx-briefcase mt-1 mx-2 text-muted"></i>
		case 'internal_note':
			return <i className='bx bx-note mt-1 mx-2 text-muted'></i>
	}
}

const getRelatedItem = (interaction, t) => {
	const { type, related, relatedId, orderId, quoteId } = interaction

	if (type === 'agent' && relatedId) {
		const label = related === 'quote' ? t('offer', { ns: 'naming' }) : related
		return `${label} #${relatedId}`
	}

	if (orderId) {
		return `${t('order', { ns: 'naming' })} #${orderId}`
	}

	if (quoteId) {
		return `${t('offer', { ns: 'naming' })} #${quoteId}`
	}

	return null
}

export const renderInteraction = (interactionObject, t) => {

	const { interaction, account } = interactionObject
	const personalInformation = account?.personalInformation
	return (
		<div className="m-0 cursor-pointer">
			<div className="d-flex px-0 element-register ">
				{getIcon(interaction)}
				<p className="mx-1">
					<small>{interaction.date && getDateWithHour(interaction.date)} <i className="bx bx-calendar text-secondary"></i></small>
				</p>
			</div>
			<div className="mx-3 pb-2 border-start border-danger">
				<p className="mx-3 mb-0">
					<small>
						<strong className="me-1">{interaction.subject}</strong>
						<span className="text-muted">{getRelatedItem(interaction, t)}</span>
					</small>
				</p>
				<p className="my-0 p-0 mx-3">
					<i className="bx bx-user"></i>
					<small className="text-primary end-ellipsis"><strong> {`${personalInformation.firstName} ${personalInformation.lastName}`}</strong></small>
				</p>
			</div>
		</div>
	)
}
