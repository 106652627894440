import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import { PartConfigTranslations } from 'common/Utils/PartConfigTranslations'
import { getDate } from 'common/Utils/StringUtilities'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PartThumbnail from 'components/Common/PartThumbnail'
import appRoutes from 'constants/appRoutes'
import { ModalMessage } from 'pages/UserProfile/ModalMessage'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import { getSimilarPartsDetail, updateParts } from 'store/actions'

const GeometricSimilarityModal = ({
	t,
	i18n: { language },
	isGeometricSimilarityModalOpen,
	closeGeometricSimilarityModal,
	similarPartsIds,
	similarity,
	offerId,
	getSimilarPartsDetail,
	similarPartsDetail,
	updateParts,
	suppliers,
	isLoading,
	error,
	partConfigOptions,
}) => {
	const [isMessageModalOpen, setMessageModalOpen] = useState(false)
	const [message, setMessage] = useState('')
	const [similarPartsSelected, setSimilarPartsSelected] = useState([])
	const [partId, setPartId] = useState()
	const [similarPartsToRender, setSimilarPartsToRender] = useState([])

	useEffect(() => {
		if (similarPartsIds != null && similarPartsIds.length != 0 && (!similarPartsToRender || similarPartsToRender.length == 0)) {
			setPartId(similarPartsIds[0])
			getSimilarPartsDetail({ partIds: [...similarPartsIds] })
		}
	}, [similarPartsIds])

	useEffect(() => {
		if (similarity != null && similarity.length != 0) {
			setSimilarPartsSelected([
				...similarity
					.map(id => {
						const parsedId = parseInt(id)
						if (parsedId) return parsedId
					})
					.filter(e => e != undefined),
			])
		}
	}, [similarity])

	useEffect(() => {
		if (similarPartsDetail && similarPartsDetail.length != 0) {
			const orderedSimilarPartsDetail = []
			orderedSimilarPartsDetail.push(similarPartsDetail.find(partDetail => partDetail.part.id === partId))
			orderedSimilarPartsDetail.push(...similarPartsDetail.filter(partDetail => partDetail.part.offerId !== offerId))
			setSimilarPartsToRender(orderedSimilarPartsDetail)
		}
	}, [similarPartsDetail])

	useEffect(() => {
		if (error) {
			setMessage(t('error_message', { ns: 'naming' }))
			setMessageModalOpen(true)
		}
	}, [error])

	const closeModalMessage = () => {
		location.reload()
	}

	const closeModal = () => {
		setSimilarPartsSelected([])
		setPartId(null)
		setSimilarPartsToRender([])
		closeGeometricSimilarityModal()
	}

	const handleCheck = partId => {
		if (similarPartsSelected.includes(partId)) {
			setSimilarPartsSelected(similarPartsSelected.filter(part => part != partId))
		} else {
			setSimilarPartsSelected([...similarPartsSelected, partId])
		}
	}

	const save = () => {
		if (similarPartsIds && similarPartsIds.length != 0) {
			const data = {
				parts: [
					{
						id: similarPartsIds[0],
						similarity: similarPartsSelected.length != 0 ? similarPartsSelected.toString() : [],
					},
				],
			}
			updateParts(data)
		}
	}

	return (
		<div>
			<Modal
				isOpen={isGeometricSimilarityModalOpen}
				closeModal={closeModal}
				title={t('similar-parts', { ns: 'naming' })}
				size="xl"
				body={
					<div>
						{!isLoading && similarPartsToRender && similarPartsToRender.length != 0 && (
							<div className="m-1">
								<Row key={'header'} className="mx-1">
									<Col className="col-1"></Col>
									<Col className="col-2">
										<strong>IDs</strong>
									</Col>
									<Col className="col-2">
										<strong>{t('part', { ns: 'naming' })}</strong>
									</Col>
									<Col className="col-2">
										<strong>{t('quote', { ns: 'naming' })}</strong>
									</Col>
									<Col className="col-2">
										<strong>{t('material', { ns: 'naming' })}</strong>
									</Col>
									<Col className="col-2">
										<strong>{t('size', { ns: 'naming' })}</strong>
									</Col>
									<Col className="col-1">
										<strong>V</strong>
									</Col>
								</Row>
								{similarPartsToRender.map((similarPart, index) => {
									const { part, quote } = similarPart
									const material = PartConfigTranslations.getMaterialName({ part, partConfigOptions, language })
									const alloy = PartConfigTranslations.getAlloyName({ part, partConfigOptions, language })
									const hardness = PartConfigTranslations.getHardnessName({ part, partConfigOptions, language })
									const finishing = PartConfigTranslations.getFinishName({ part, partConfigOptions, language })
									const palette = PartConfigTranslations.getPaletteName({ part, partConfigOptions, language })
									const color = PartConfigTranslations.getColorName({ part, partConfigOptions, language })
									const colorFinishing = PartConfigTranslations.getColorFinishName({ part, partConfigOptions, language })
									const stlData = {
										partId: part.id,
										partName: part.name,
										size: part.size,
										weight: part.weight,
									}
									return (
										<Row key={part.id} className="mx-1 my-1">
											<Col className="col-1 d-flex justify-content-center align-items-center">
												{similarPartsIds && part.id != similarPartsIds[0] && (
													<input
														type="checkbox"
														className="form-check-input"
														id={`select_${part.id}`}
														checked={similarPartsSelected.includes(part.id)}
														onChange={e => handleCheck(part.id)}
													/>
												)}
											</Col>
											<Col className="col-2 d-flex align-items-center">
												<div>
													<div>{part.id}</div>
													<a href={`${appRoutes.OFFER_DETAIL_ROUTE_NO_ID}${part.offerId}`} target="_blank" rel="noopener noreferrer">
														{part.offerId}
													</a>
												</div>
											</Col>
											<Col className="col-2 d-flex align-items-center">
												<PartThumbnail stlData={stlData} propsStlUrl={part.fileLinks?.stlModel} propsImageUrl={part?.files?.image} />
											</Col>
											<Col className="col-2 d-flex align-items-center">
												<div>
													<div>{suppliers.find((supplier) => supplier.id == quote?.supplierId)?.personalInformation.firstName}</div>
													<div>{getDate(quote?.lastUpdateDate)}</div>
												</div>
											</Col>
											<Col className="col-2 d-flex align-items-center">
												<div>
													{material != '' && (
														<div>
															{material} {alloy}
														</div>
													)}
													{hardness != '' && <div>{hardness}</div>}
													{finishing != '' && <div>{finishing}</div>}
													{palette != '' && color != '' && (
														<div>
															{palette} {color}
														</div>
													)}
													{colorFinishing != '' && <div>{colorFinishing}</div>}
												</div>
											</Col>
											<Col className="col-2 d-flex align-items-center">{roundAtDecimals(part.size.x, 1)} x {roundAtDecimals(part.size.y, 1)} x {roundAtDecimals(part.size.z, 1)}</Col>
											<Col className="col-1 d-flex align-items-center">{roundAtDecimals(part.volume, 2)}</Col>
										</Row>
									)
								})}
							</div>
						)}
						{isLoading && (
							<div className="p-4">
								<Loader className="font-size-80 mx-auto" />
							</div>
						)}
					</div>
				}
				buttons={[
					<button type="button" key="saveButton" className="btn btn-primary" onClick={() => save()}>
						{isLoading ? <Loader className="mx-auto" /> : t('save', { ns: 'naming' })}
					</button>,
				]}
			/>
			<ModalMessage isModalOpen={isMessageModalOpen} closeModal={closeModalMessage} message={message} />
		</div>
	)
}

const mapStateToProps = state => {
	return {
		similarPartsDetail: state.Parts.similarParts,
		suppliers: state.Users.suppliers,
		isLoading: state.Parts.isLoading,
		error: state.Parts.error,
	}
}

GeometricSimilarityModal.propTypes = {
	t: PropTypes.func,
	isGeometricSimilarityModalOpen: PropTypes.bool,
	closeGeometricSimilarityModal: PropTypes.func,
	similarPartsIds: PropTypes.array,
	similarity: PropTypes.array,
	getSimilarPartsDetail: PropTypes.func,
	similarPartsDetail: PropTypes.array,
	updateParts: PropTypes.func,
	suppliers: PropTypes.array,
	isLoading: PropTypes.bool,
	error: PropTypes.object,
}

export default connect(mapStateToProps, { getSimilarPartsDetail, updateParts })(withTranslation()(GeometricSimilarityModal))
