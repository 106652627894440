import { checkIsFebruary29 } from 'common/Utils/DateUtils'
import { formatDateToMMDDYYYY } from 'common/Utils/StringUtilities'
import { MONTHS } from './constants'

export const getPreviousDateRange = (dateRange) => {
	if (dateRange == null) return undefined
	const { startDate, endDate } = dateRange
	const startDateObj = new Date(startDate)
	const endDateObj = new Date(endDate) > new Date() ? new Date() : new Date(endDate)
	if (startDateObj.getFullYear() === endDateObj.getFullYear()) {
		const previousStartDate = startDateObj.setFullYear(startDateObj.getFullYear() - 1)
		const previousEndDate = checkIsFebruary29(endDateObj) ?
			new Date(endDateObj.getFullYear() - 1, endDateObj.getMonth() + 1, 0) :
			endDateObj.setFullYear(endDateObj.getFullYear() - 1)
		return {
			startDate: formatDateToMMDDYYYY(new Date(previousStartDate)),
			endDate: formatDateToMMDDYYYY(new Date(previousEndDate)),
		}
	}
	return undefined
}

export const getLapseDivisor = (dateRange) => {
	if (dateRange == null) return
	const { startDate, endDate } = dateRange
	const startDateObj = new Date(startDate)
	const endDateObj = new Date(endDate) > new Date() ? new Date() : new Date(endDate)
	const startYear = startDateObj.getFullYear()
	const endYear = endDateObj.getFullYear()

	if (startYear !== endYear) {
		return endDateObj.getFullYear() - startDateObj.getFullYear() + 1
	} else {
		const startMonth = startDateObj.getMonth()
		const endMonth = endDateObj.getMonth()
		if (startMonth !== endMonth) {
			return endMonth - startMonth + 1
		}
		return endDateObj.getDate() - startDateObj.getDate() + 1
	}
}

const YEAR_LABEL_TYPE = 'YEAR'
const MONTH_LABEL_TYPE = 'MONTH'
const DAY_LABEL_TYPE = 'DAY'

export const getChartLabels = (dateRange, t) => {
	if (dateRange == null) return
	const { startDate, endDate } = dateRange
	const startDateObj = new Date(startDate)
	const endDateObj = new Date(endDate)

	const startYear = startDateObj.getFullYear()
	const endYear = endDateObj.getFullYear()

	if (startYear !== endYear) {
		return getNumberLabels(startYear, endYear, YEAR_LABEL_TYPE)
	} else {
		const startMonth = startDateObj.getMonth()
		const endMonth = endDateObj.getMonth()
		if (startMonth !== endMonth) {
			return getMonthLabels(startMonth, endMonth, MONTH_LABEL_TYPE, t)
		}
		return getNumberLabels(startDateObj.getDate(), endDateObj.getDate(), DAY_LABEL_TYPE)
	}
}

export const getNumberLabels = (startNumber, endNumber, type) => {
	return {
		type,
		labels: Array.from({ length: endNumber - startNumber + 1 }, (_, index) => startNumber + index),
	}
}

export const getMonthLabels = (startMonth, endMonth, type, t) => {
	return {
		type,
		labels: Array.from({ length: endMonth - startMonth + 1 }, (_, index) => {
			const currentMonth = startMonth + index < 12 ? MONTHS[startMonth + index] : MONTHS[12 - startMonth + index]
			return t(currentMonth, { ns: 'months' })
		}),
	}
}

export const getIndexByType = (type, creationDate, dateRange) => {
	const itemCreationDateObj = new Date(creationDate)
	const startDateObj = new Date(dateRange.startDate)
	switch (type) {
		case YEAR_LABEL_TYPE: return itemCreationDateObj.getFullYear() - startDateObj.getFullYear()
		case MONTH_LABEL_TYPE: return itemCreationDateObj.getMonth() - startDateObj.getMonth()
		default: return itemCreationDateObj.getDate() - startDateObj.getDate()
	}
}
