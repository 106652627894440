import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const EditCustomerModal = ({
	isEditCustomerModalOpen,
	closeEditCustomerModal,
	customers,
	selectedCustomer,
	setCustomer,
	offerId,
	t,
	updateOfferCustomer,
}) => {
	const parseCustomers = () => {
		if (!customers) return []
		return customers.map((customer) => {
			return {
				value: customer.id,
				label: customer.loginCredentials.email,
			}
		}).sort((a, b) => a.label.localeCompare(b.label))
	}

	return <Modal
		className="upload-modal"
		isOpen={isEditCustomerModalOpen}
		closeModal={closeEditCustomerModal}
		title={t('editOfferCustomer', { ns: 'naming' })}
		setValue={setCustomer}
		value={selectedCustomer}
		body={
			<div>
				<div className="w-100 m-2">
					<h5>{t('newUser', { ns: 'naming' })}</h5>
				</div>
				<div className="w-100 m-2">
					<OptionsInput
						setValue={setCustomer}
						title={t('customer', { ns: 'naming' })}
						options={parseCustomers()}
					/>
				</div>
			</div>
		}
		buttons={[
			<button
				disabled={!selectedCustomer}
				type="button"
				key="btn2"
				className="btn btn-primary"
				onClick={() => updateOfferCustomer()}
			>
				{t('submit', { ns: 'naming' })}
			</button>,
		]}
	/>
}

EditCustomerModal.propTypes = {
	isEditCustomerModalOpen: PropTypes.bool.isRequired,
	closeEditCustomerModal: PropTypes.func.isRequired,
	customers: PropTypes.array,
	selectedCustomer: PropTypes.object,
	setCustomer: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	offerId: PropTypes.string.isRequired,
	updateOfferCustomer: PropTypes.func.isRequired,
}

export default (withTranslation()(EditCustomerModal))
