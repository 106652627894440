import Breadcrumbs from 'components/Common/Breadcrumb'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import { Table2 } from 'components/Table/Table2'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { MetaTags } from 'react-meta-tags'
import { connect } from 'react-redux'
import { getBoxList, updateBoxesByRef } from '../../store/actions'
import PrintLabelsModal from './PrintLabelsModal'

export const Boxes = (props) => {

	const {
		t,
		boxList,
		getBoxList,
		getBoxListStatus = {},
		updateBoxesByRef,
		updateBoxesByRefStatus = {},
	} = props

	const [boxIdToPrint, setBoxIdToPrint] = useState(undefined)

	const breadcrumbElement = (
		<Breadcrumbs
			title={t('management', { ns: 'naming' })}
			breadcrumbItems={[
				{
					item: t('boxes', { ns: 'naming' }),
					link: '/boxes',
				},
			]}
		/>
	)

	useEffect(() => {
		if (!getBoxListStatus?.isLoading && (boxList == null || boxList.length == 0)) {
			getBoxList()
		}
	}, [])

	const handleUnassignOrder = (boxRef) => {
		updateBoxesByRef({
			data: {
				boxes: [{
					ref: boxRef,
					orderId: null,
				}],
			},
		})
	}

	const [unassignOrderResultModalIsOpen, setUnassignOrderResultModalIsOpen] = useState(false)

	const UnassignOrderResultModal = (
		<Modal
			isOpen={unassignOrderResultModalIsOpen}
			closeModal={() => {
				if (updateBoxesByRefStatus.success) history.go(0)

			}}
			body={<h4 className="m-5">
				{updateBoxesByRefStatus.isLoading ? (
					<Loader className="font-size-80 mx-auto" />
				) : updateBoxesByRefStatus.success ? (
					t('update_success', { ns: 'naming' })
				) : (
					t('error_message', { ns: 'naming' })
				)}
			</h4>}
		/>
	)

	useEffect(() => {
		if (updateBoxesByRefStatus.isLoading) {
			setUnassignOrderResultModalIsOpen(true)
		}
	}, [updateBoxesByRefStatus.isLoading])

	return (
		<div className="page-content mb-4">
			<MetaTags>
				<title>Proto&Go! | {t('boxes', { ns: 'naming' })}</title>
			</MetaTags>
			{breadcrumbElement}
			{getBoxListStatus?.isLoading ? (
				<Loader className="font-size-80 mx-auto" />
			) : getBoxListStatus?.error ? (
				<div>
					{t('error_message', { ns: 'naming' })}
				</div>
			) : (
				<div className="bg-white my-4 p-4 marginFooter">

					<div className="py-2">
						<button className='btn btn-secondary w-auto d-flex flex-row align-items-center ' onClick={() => setBoxIdToPrint(null)}>
							<span className='me-2'>{t('print_all_labels', { ns: 'naming' })} </span>
							<i className='bx bxs-printer fs-5'></i>
						</button>
						<Table2
							t={t}
							headers={{
								ref: t('reference', { ns: 'naming' }),
								barCode: t('bar_code', { ns: 'naming' }),
								assignedOrder: t('assigned_order', { ns: 'naming' }),
								actions: t('actions', { ns: 'naming' }),
							}}
							rows={boxList?.map(boxItem => {
								const hasOrderAssigned = boxItem.orderId != null
								const className = `rounded rounded-3 p-2 m-2 ${hasOrderAssigned ? 'bg-info text-white' : 'bg-light'}`
								return {
									ref: (
										<div className={className}>
											{boxItem.ref}
										</div>
									),
									barCode: (
										<div className={className}>
											{boxItem.barCode}
										</div>
									),
									assignedOrder: (
										<div className={className}>
											{boxItem.orderId || t('no_order', { ns: 'naming' })}
										</div>
									),
									actions:
										<div className='d-flex flex-row'>
											{hasOrderAssigned && <button
												className="btn btn-secondary d-flex justify-content-center w-auto me-2"
												onClick={() => handleUnassignOrder(boxItem.ref)}
											>
												<i className='bx bx-reset fs-5'></i>
											</button>}
											<button className='btn btn-secondary w-auto d-flex justify-content-center' onClick={() => setBoxIdToPrint(boxItem.id)}>
												<i className="bx bxs-printer fs-5" />
											</button>
										</div>
									,
								}
							})}
							hidePagination
						/>
					</div>
				</div>
			)}
			{UnassignOrderResultModal}
			<PrintLabelsModal boxId={boxIdToPrint} setBoxId={setBoxIdToPrint} />
		</div>
	)
}

Boxes.propTypes = {
	t: PropTypes.func,
	boxList: PropTypes.array,
	getBoxList: PropTypes.func,
	getBoxListStatus: PropTypes.shape({
		isLoading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.string,
	}),
	updateBoxesByRef: PropTypes.func,
	updateBoxesByRefStatus: PropTypes.shape({
		isLoading: PropTypes.bool,
		success: PropTypes.bool,
		error: PropTypes.string,
	}),
}

const mapStateToProps = state => {
	return {
		boxList: state.Orders.boxList,
		getBoxListStatus: state.Orders.getBoxListStatus,
		updateBoxesByRefStatus: state.Orders.updateBoxesByRefStatus,
	}
}

export default connect(mapStateToProps, {
	getBoxList,
	updateBoxesByRef,
})(withTranslation()(Boxes))
