import PropTypes from 'prop-types'

const SelectedQuantity = ({ value, setValue, min, max }) => {

	const handleOnChangeValue = (newValue) => {
		switch (true) {
			case newValue <= min || newValue == '':
				setValue(min)
				break
			case newValue >= max:
				setValue(max)
				break
			default: setValue(newValue)
		}
	}

	return (
		<div className="d-flex flex-row justify-content-start align-items-center">
			<i className="fas fa-minus-circle cursor-pointer" onClick={() => handleOnChangeValue(value - 1)}></i>
			<input className="custom-little-input" type="number" value={value} onChange={(e) => handleOnChangeValue(parseInt(e.target.value))} min={min} max={max} />
			<i className="fas fa-plus-circle cursor-pointer" onClick={() => handleOnChangeValue(value + 1)}></i>
		</div>
	)
}

SelectedQuantity.propTypes = {
	value: PropTypes.number,
	setValue: PropTypes.func,
	min: PropTypes.number,
	max: PropTypes.number,
}

export default SelectedQuantity
