import { getLanguage } from 'common/Utils/LocalStorageUtilities'
import Table from 'components/Table/Table'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

const ProductionParts = ({ t, partList }) => {
	const header = {
		partId: t('part-id', { ns: 'naming' }),
		part: t('part', { ns: 'naming' }),
		technology: t('technology', { ns: 'naming' }),
		files: t('files', { ns: 'naming' }),
		description: t('description', { ns: 'naming' }),
		price: t('price', { ns: 'naming' }),
		quantity: t('quantity', { ns: 'naming' }),
		total: t('total', { ns: 'naming' }),
		// chat: t('chat', { ns: 'naming' }), --> TODO ProductionChat no funciona todavía
		note: t('note', { ns: 'naming' }),
	}
	return (
		<Table
			header={header}
			items={partList}
			disableInteractions
			selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
			language={getLanguage()}
			rowQuantityList={[{ text: '32', value: 32 }]}
			paginationProps={{
				custom: true,
				sizePerPage: 200,
			}}
			allowAllItemsPerPage
			styleForFieldList={[
				{
					field: 'description',
					styles: [{ type: 'list', class: 'd-block' }],
				},
			]}
		></Table>
	)
}

ProductionParts.propTypes = {
	t: PropTypes.func,
	partList: PropTypes.array,
}
export default withTranslation()(ProductionParts)
