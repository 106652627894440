import { substractDates } from 'common/Utils/DateUtils'
import { roundAtDecimals } from 'common/Utils/NumberUtilities'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import TitleWithValueColumn from '../CommonComponents/TitleWithValueColumn'
import { getLapseDivisor } from '../utils'

export const BillingSideBar = ({ t, billingStats, dateRange, previousBillingStats }) => {

	const lapseDivisor = useMemo(() => getLapseDivisor(dateRange), [dateRange])
	
	const getDailyOrders = (ordersCount) => {
		return roundAtDecimals(ordersCount / substractDates(new Date(dateRange.startDate), new Date(dateRange.endDate) > new Date() ? new Date() : new Date(dateRange.endDate)), 2)
	}

	return (
		<div>
			{billingStats != null && dateRange != null &&
				<div className="d-flex flex-column w-100 border-top">
					<div>
						<TitleWithValueColumn
							title="Ventas"
							value={billingStats.ordersSubtotal}
							previousValue={previousBillingStats?.ordersSubtotal}
							isCurrency
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Pedidos"
							value={billingStats.ordersCount}
							previousValue={previousBillingStats?.ordersCount}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Empresas"
							value={billingStats.customersCount}
							previousValue={previousBillingStats?.customersCount}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Ventas Periódicas"
							value={billingStats.ordersSubtotal / lapseDivisor}
							previousValue={previousBillingStats?.ordersSubtotal / lapseDivisor}
							isCurrency
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Pedido medio"
							value={billingStats.ordersSubtotal / billingStats.ordersCount}
							previousValue={previousBillingStats?.ordersSubtotal / previousBillingStats?.ordersCount}
							isCurrency
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Pedidos diarios"
							value={getDailyOrders(billingStats.ordersCount)}
							previousValue={getDailyOrders(previousBillingStats?.ordersCount)}
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Gastos productivos"
							value={billingStats.productionCosts}
							previousValue={previousBillingStats?.productionCosts}
							isCurrency
							reversedColors
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Gastos logísticos"
							value={billingStats.shipmentCosts}
							previousValue={previousBillingStats?.shipmentCosts}
							isCurrency
							reversedColors
						/>
					</div>
					<div>
						<TitleWithValueColumn
							title="Margen Productivo"
							value={billingStats.ordersSubtotal - billingStats.productionCosts - billingStats.shipmentCosts}
							previousValue={previousBillingStats?.ordersSubtotal - previousBillingStats?.productionCosts - previousBillingStats?.shipmentCosts}
							isCurrency
						/>
					</div>
				</div>
			}
		</div>
	)
}

BillingSideBar.propTypes = {
	t: PropTypes.func,
	billingStats: PropTypes.object,
	dateRange: PropTypes.object,
	previousBillingStats: PropTypes.object,
}

const mapStateToProps = (state) => ({
	billingStats: state.Orders.billingStats,
	previousBillingStats: state.Orders.previousBillingStats,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(BillingSideBar))
