import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react'
import { setSelectedVerificationOrderId } from 'store/actions'
import Loader from '../../../components/Common/Loader'
import VerificationOrderSummary from './VerificationOrderSummary'

const OrderSummaryList = ({ t, title, orders, isLoading, setSelectedVerificationOrderId }) => {
	const [ordersMapped, setOrdersMapped] = useState([])

	useEffect(() => {
		if (orders) {
			setOrdersMapped(
				orders.map(order => {
					return (
						<div
							key={order.id}
							onClick={() => {
								setSelectedVerificationOrderId(order.id)
							}}
						>
							<VerificationOrderSummary order={order} />
						</div>
					)
				}),
			)
		}
	}, [orders])

	return (
		<div>
			<h3>{title}</h3>
			<div className="border-bottom">
				{!isLoading ? (
					<>
						<SimpleBar style={{
							maxHeight: '22.5rem',
							border: '0.5px solid lightgray',
							borderTopLeftRadius: '4px',
							borderTopRightRadius: '4px',
						}}>{ordersMapped}</SimpleBar>
						<div
							className="w-100 p-2 d-flex align-items-center"
							style={{
								border: '0.5px solid lightgray',
								borderBottomLeftRadius: '4px',
								borderBottomRightRadius: '4px',
								backgroundColor: 'whitesmoke',
							}}
						>
							{ordersMapped.length} {t('ordersInTheList', { ns: 'naming' })}
						</div>
					</>
				) : (
					<div className="d-flex justify-content-center">
						<Loader className="fs-1 p-4" />
					</div>
				)}
			</div>
		</div>
	)
}

const mapStateToProps = state => {
	return {
		isLoading: state.Orders.isLoading
	}
}

OrderSummaryList.propTypes = {
	title: PropTypes.string,
	t: PropTypes.func,
	orders: PropTypes.array,
	isLoading: PropTypes.bool,
	setSelectedVerificationOrderId: PropTypes.func,
}
export default connect(mapStateToProps, { setSelectedVerificationOrderId })(withTranslation()(OrderSummaryList))
