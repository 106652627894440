import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { } from '../../store/actions'

import { Link } from 'react-router-dom'

//i18n
import { withTranslation } from 'react-i18next'
import SidebarContent from './SidebarContent'

import logoDark from '../../assets/images/logo-dark.png'
import logo from '../../assets/images/logo.svg'

import BrandLogo from '../../components/Common/BrandLogo'

class Sidebar extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		return (
			<React.Fragment>
				<div className="vertical-menu bg-black">
					<div className="navbar-brand-box">
						<Link className="logo logo-dark" onClick={() => location.reload()} to="#">
							<span className="logo-sm">
								<img src={logo} alt="" height="22" />
							</span>
							<span className="logo-lg">
								<img src={logoDark} alt="" height="17" />
							</span>
						</Link>

						<Link className="logo logo-light" onClick={() => location.reload()} to="#">
							<span className="logo-sm">
								<BrandLogo collapsed />
							</span>
							<span className="logo-lg">
								<BrandLogo />
							</span>
						</Link>
					</div>
					<div data-simplebar className="h-100">
						<SidebarContent roles={this.props.roles} />
					</div>
					<div className="sidebar-background"></div>
				</div>
			</React.Fragment>
		)
	}
}

Sidebar.propTypes = {
	type: PropTypes.string,
	roles: PropTypes.arrayOf(PropTypes.string),
	token: PropTypes.string,
}

const mapStateToProps = state => {
	return {
		layout: state.Layout,
		roles: state.Login.roles,
		token: state.Login.token,
	}
}
export default connect(
	mapStateToProps,
	{},
)(withRouter(withTranslation()(Sidebar)))
