export const timeZones = [
	'Europe/Madrid',
	'Europe/Malta',
	'Europe/Monaco',
	'Europe/Oslo',
	'Europe/Paris',
	'Europe/Podgorica',
	'Europe/Prague',
	'Europe/Rome',
	'Europe/San_Marino',
	'Europe/Sarajevo',
	'Europe/Skopje',
	'Europe/Stockholm',
	'Europe/Tirane',
	'Europe/Vaduz',
	'Europe/Vatican',
	'Europe/Vienna',
	'Europe/Warsaw',
	'Europe/Zagreb',
	'Europe/Zurich',
	'Africa/Blantyre',
	'Africa/Bujumbura',
	'Africa/Cairo',
	'Africa/Gaborone',
	'Africa/Harare',
	'Africa/Johannesburg',
	'Africa/Kigali',
	'Africa/Lubumbashi',
	'Africa/Lusaka',
	'Africa/Maputo',
	'Africa/Maseru',
	'Africa/Mbabane',
	'Africa/Tripoli',
	'Asia/Amman',
	'Asia/Beirut',
	'Asia/Damascus',
	'Asia/Gaza',
	'Asia/Istanbul',
	'Asia/Jerusalem',
	'Asia/Nicosia',
	'Asia/Tel_Aviv',
	'Europe/Athens',
	'Europe/Bucharest',
	'Europe/Chisinau',
	'Europe/Helsinki',
	'Europe/Istanbul',
	'Europe/Kaliningrad',
	'Europe/Kiev',
	'Europe/Mariehamn',
	'Europe/Minsk',
	'Europe/Nicosia',
	'Europe/Riga',
	'Europe/Simferopol',
	'Europe/Sofia',
	'Europe/Tallinn',
	'Europe/Tiraspol',
	'Europe/Uzhgorod',
	'Europe/Vilnius',
	'Europe/Zaporozhye',
	'Africa/Addis_Ababa',
	'Africa/Asmara',
	'Africa/Asmera',
	'Africa/Dar_es_Salaam',
	'Africa/Djibouti',
	'Africa/Kampala',
	'Africa/Khartoum',
	'Africa/Mogadishu',
	'Africa/Nairobi',
	'Antarctica/Syowa',
	'Asia/Aden',
	'Asia/Baghdad',
	'Asia/Bahrain',
	'Asia/Kuwait',
	'Asia/Qatar',
	'Europe/Moscow',
	'Europe/Volgograd',
	'Indian/Antananarivo',
	'Indian/Comoro',
	'Indian/Mayotte',
	'Asia/Tehran',
	'Asia/Baku',
	'Asia/Dubai',
	'Asia/Muscat',
	'Asia/Tbilisi',
	'Asia/Yerevan',
	'Europe/Samara',
	'Indian/Mahe',
	'Indian/Mauritius',
	'Indian/Reunion',
	'Asia/Kabul',
	'Asia/Aqtau',
	'Asia/Aqtobe',
	'Asia/Ashgabat',
	'Asia/Ashkhabad',
	'Asia/Dushanbe',
	'Asia/Karachi',
	'Asia/Oral',
	'Asia/Samarkand',
	'Asia/Tashkent',
	'Asia/Yekaterinburg',
	'Indian/Kerguelen',
	'Indian/Maldives',
	'Asia/Calcutta',
	'Asia/Colombo',
	'Asia/Kolkata',
	'Asia/Katmandu',
	'Antarctica/Mawson',
	'Antarctica/Vostok',
	'Asia/Almaty',
	'Asia/Bishkek',
	'Asia/Dacca',
	'Asia/Dhaka',
	'Asia/Novosibirsk',
	'Asia/Omsk',
	'Asia/Qyzylorda',
	'Asia/Thimbu',
	'Asia/Thimphu',
	'Indian/Chagos',
	'Asia/Rangoon',
	'Indian/Cocos',
	'Antarctica/Davis',
	'Asia/Bangkok',
	'Asia/Ho_Chi_Minh',
	'Asia/Hovd',
	'Asia/Jakarta',
	'Asia/Krasnoyarsk',
	'Asia/Phnom_Penh',
	'Asia/Pontianak',
	'Asia/Saigon',
	'Asia/Vientiane',
	'Indian/Christmas',
	'Antarctica/Casey',
	'Asia/Brunei',
	'Asia/Choibalsan',
	'Asia/Chongqing',
	'Asia/Chungking',
	'Asia/Harbin',
	'Asia/Hong_Kong',
	'Asia/Irkutsk',
	'Asia/Kashgar',
	'Asia/Kuala_Lumpur',
	'Asia/Kuching',
	'Asia/Macao',
	'Asia/Macau',
	'Asia/Makassar',
	'Asia/Manila',
	'Asia/Shanghai',
	'Asia/Singapore',
	'Asia/Taipei',
	'Asia/Ujung_Pandang',
	'Asia/Ulaanbaatar',
	'Asia/Ulan_Bator',
	'Asia/Urumqi',
	'Australia/Perth',
	'Australia/West',
	'Australia/Eucla',
	'Asia/Dili',
	'Asia/Jayapura',
	'Asia/Pyongyang',
	'Asia/Seoul',
	'Asia/Tokyo',
	'Asia/Yakutsk',
	'Australia/Adelaide',
	'Australia/Broken_Hill',
	'Australia/Darwin',
	'Australia/North',
	'Australia/South',
	'Australia/Yancowinna',
	'Antarctica/DumontDUrville',
	'Asia/Sakhalin',
	'Asia/Vladivostok',
	'Australia/ACT',
	'Australia/Brisbane',
	'Australia/Canberra',
	'Australia/Currie',
	'Australia/Hobart',
	'Australia/Lindeman',
	'Australia/Melbourne',
	'Australia/NSW',
	'Australia/Queensland',
	'Australia/Sydney',
	'Australia/Tasmania',
	'Australia/Victoria',
	'Australia/LHI',
	'Australia/Lord_Howe',
	'Asia/Magadan',
	'Antarctica/McMurdo',
	'Antarctica/South_Pole',
	'Asia/Anadyr',
	'Asia/Kamchatka',
]
