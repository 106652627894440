import { getStatusClassName } from 'common/Utils/StringUtilities'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const StatusFilterDropdown = ({ statusArray, t, onChange }) => {

	const getClassName = status => {
		const statusDefault = 'rounded-pill text-white w100 '
		const UPLOADED = 40
		const REQUESTED = 20
		const NEW = 10
		const QUOTED = 30
		const REJECTED = 91

		const ORDER_PAYMENT_PENDING = 'order_status_10'
		const ORDER_PREPARING = 'order_status_20'
		const ORDER_PAYMENT_ERROR = 'order_status_30'
		const ORDER_ON_PRODUCTION = 'order_status_40'
		const ORDER_SENT = 'order_status_60'
		const ORDER_DELIVERED = 'order_status_70'
		const ORDER_CANCELLED = 'order_status_80'
		const ORDER_REFUNDED = 'order_status_90'

		switch (status) {
			case NEW:
				return statusDefault + 'bg-secondary'
			case REQUESTED:
				return statusDefault + 'bg-yellow'
			case QUOTED:
				return statusDefault + 'bg-orange'
			case UPLOADED:
				return statusDefault + 'bg-green'
			case REJECTED:
				return statusDefault + 'bg-red'
			case ORDER_PAYMENT_PENDING:
				return statusDefault + 'bg-secondary'
			case ORDER_PREPARING:
				return statusDefault + 'bg-yellow'
			case ORDER_PAYMENT_ERROR:
				return statusDefault + 'bg-red'
			case ORDER_ON_PRODUCTION:
				return statusDefault + 'bg-orange'
			case ORDER_SENT:
				return statusDefault + 'bg-green'
			case ORDER_DELIVERED:
				return statusDefault + 'bg-blue'
			case ORDER_CANCELLED:
				return statusDefault + 'bg-red'
			case ORDER_REFUNDED:
				return statusDefault + 'bg-red'
			case 1:
				return statusDefault + 'bg-orange'
			case 2:
				return statusDefault + 'bg-cyan'
			case 3:
				return statusDefault + 'bg-purple'
			case 4:
				return statusDefault + 'bg-yellow'
			case 5:
				return statusDefault + 'bg-red'
			case 6:
				return statusDefault + 'bg-green'
			case 7:
				return statusDefault + 'bg-danger'
			case 9:
				return statusDefault + 'bg-red'
			case 10:
				return statusDefault + 'bg-pink'
			case 20:
				return statusDefault + 'bg-orange'
			case 30:
				return statusDefault + 'bg-orange'
			case 40:
				return statusDefault + 'bg-green'
			case 50:
				return statusDefault + 'bg-blue'
			case 60:
				return statusDefault + 'bg-success'
			case 70:
				return statusDefault + 'bg-green'
			case 80:
				return statusDefault + 'bg-red'
			case 90:
				return statusDefault + 'bg-blue'
			default:
				return statusDefault + 'bg-blue'
		}
	}
	useEffect(() => {
		if (statusActiveArray) {
			onChange(getArrayStatus())
		}
	}, [])

	const getActiveAndClassNames = statusArray => {
		const statusActiveArray = []
		statusArray.forEach(element => {
			statusActiveArray.push({
				status: element.text,
				active: false,
				className: getStatusClassName(element.id),
			})
		})
		return statusActiveArray
	}
	const [isToggleOpened, setToggle] = useState(false)
	const [statusActiveArray, setStatusActiveArray] = useState(
		getActiveAndClassNames(statusArray),
	)

	const renderInput = status => {
		return (
			<div
				className="p-3 list-status-item-filter cursor-pointer"
				onClick={e => changeListItems(status.status, setStatusActiveArray)}
			>
				<span className={status.active ? status.className : ''}>
					{status.status}
				</span>
			</div>
		)
	}
	const changeListItems = (status, setStatusActiveArray) => {
		activate(status, setStatusActiveArray)
		onChange(getArrayStatus())
	}

	const getArrayStatus = () => {
		const activeStatusArray = []
		statusActiveArray.forEach(element => {
			if (element.active == true) {
				activeStatusArray.push(element.status)
			}
		})
		return activeStatusArray
	}

	const activate = (status, setStatusActiveArray) => {
		const newArray = statusActiveArray.map(element =>
			activateStatus(element, status),
		)
		setStatusActiveArray(newArray)
	}

	const activateStatus = (element, status) => {
		if (element.status == status) {
			return activateElement(element, !element.active)
		}
		return element
	}

	const activateElement = (element, bool) => {
		const elementActive = Object.assign(element)
		elementActive.active = bool
		return elementActive
	}

	const options = statusActiveArray.map(status => renderInput(status))

	return (
		<div className="my-1">
			<Dropdown
				isOpen={isToggleOpened}
				direction="center"
				size="sm"
				toggle={e => setToggle(!isToggleOpened)}
			>
				<DropdownToggle color="white" className="btn btn-secondary">
					{t('status', { ns: 'naming' })}
				</DropdownToggle>
				<DropdownMenu data-popper-placement="left-start">
					<div className="my-3 mb-0">
						<div className="d-flex flex-column justify-content-start filter-width">
							{options}
						</div>
					</div>
				</DropdownMenu>
			</Dropdown>
		</div>
	)
}

StatusFilterDropdown.propTypes = {
	t: PropTypes.func,
	statusArray: PropTypes.array,
	onChange: PropTypes.func,
	resetFilter: PropTypes.func,
}

export default withTranslation()(StatusFilterDropdown)
