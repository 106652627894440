import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Modal as RSModal } from 'reactstrap'

class Modal extends Component {

	constructor(props) {
		super(props)
		this.closeButtonRef = React.createRef()
	}

	componentDidUpdate(prevProps) {
		if (this.props.isOpen && !prevProps.isOpen && this.props.focusOnCloseButton) {
			setTimeout(() => {
				this.closeButtonRef.current?.focus()
			}, 0)
		}
	}

	render() {
		const { t, closeButtonText, disableInteractions, dontShowCloseButton } = this.props

		return (
			<RSModal
				isOpen={this.props.isOpen}
				centered={true}
				className={this.props.className}
				size={this.props.size}
				backdrop
				toggle={this.props.closeModal}
			>
				<div className={this.props.title && 'modal-header'}>
					<h3 className="modal-title mt-0">{this.props.title}</h3>
					{!disableInteractions &&
						<button
							type="button"
							onClick={() => this.props.closeModal()}
							className="close"
							data-dismiss="modal"
							aria-label="Close"
							ref={this.closeButtonRef}
						>
							<span aria-hidden="true">&times;</span>
						</button>
					}
				</div>
				{this.props.body}

				{!disableInteractions &&
					<div className="modal-footer" >
						{!dontShowCloseButton && <button
							type="button"
							className={
								this.props.closeButtonColor ? 'btn btn-' + this.props.closeButtonColor :
									this.props.buttons && this.props.buttons.length
										? 'btn btn-secondary'
										: 'btn btn-primary'
							}
							onClick={() => this.props.closeModal()}
						>
							{t(closeButtonText ? closeButtonText : 'close', { ns: 'naming' })}
						</button>}
						{this.props.buttons && this.props.buttons.map(button => button)}
					</div>
				}
			</RSModal>
		)
	}
}

Modal.propTypes = {
	title: PropTypes.string,
	body: PropTypes.element,
	buttons: PropTypes.arrayOf(PropTypes.element),
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	closeButtonText: PropTypes.string,
	t: PropTypes.func,
	className: PropTypes.string,
	size: PropTypes.string,
	closeButtonColor: PropTypes.string,
	disableInteractions: PropTypes.bool,
}
export default withTranslation()(Modal)
