import Modal from 'components/Common/Modal/Modal'
import LabelsPrintableContent from 'components/Common/ProductionLabels/LabelsPrintableContent'
import SimpleTable from 'components/Common/SimpleTable'
import SelectedQuantity from 'pages/Productions/SelectedQuantity'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import SimpleBar from 'simplebar-react'
import BoxLabel from './BoxLabel'
export const PrintLabelsModal = ({ boxList, setBoxId, t, boxId }) => {

	const [boxesToPrint, setBoxesToPrint] = useState([])

	const DEFAULT_QUANTITY = 5
	const getQuantity = (quantity) => {
		return quantity == null ? DEFAULT_QUANTITY : quantity
	}

	useEffect(() => {
		if (!boxList || boxId === undefined) { setBoxesToPrint([]) } else if (boxId === null) {
			setBoxesToPrint(boxList)
		} else {
			setBoxesToPrint(boxList.filter(box => box.id === boxId))
		}
	}, [boxId, boxList])

	const htmlToPrint = useMemo(() => {
		const htmlToPrint = []
		for (const box of boxesToPrint) {
			for (let i = 0; i < getQuantity(box.quantity); i++) {
				const label = <BoxLabel boxItem={box} box={box} idLabel={`box_${box.id}_${i}`} />
				htmlToPrint.push(label)
			}
		}
		return htmlToPrint
	}, [boxesToPrint])

	const handleOnChangeBoxQuantity = (quantity, boxToChange) => {
		const newBoxes = boxesToPrint.map(b => {
			if (b.id === boxToChange.id) {
				return { ...b, quantity }
			}
			return b
		})
		setBoxesToPrint(newBoxes)
	}

	const header = ['reference', 'assigned_order', 'quantity']

	const getTableBody = () => {
		return boxesToPrint.map(box => {
			return (<tr key={`body_print_boxes_${box.id}`}>
				<td>{box.ref}</td>
				<td>{box.orderId || t('no_order', { ns: 'naming' })}</td>
				<td>{<SelectedQuantity min={boxesToPrint.length === 1 ? 1 : 0} value={getQuantity(box.quantity)} setValue={(e) => handleOnChangeBoxQuantity(e, box)} />}</td>
			</tr>)
		})
	}

	return (
		<Modal
			isOpen={boxId !== undefined}
			closeModal={() => setBoxId(undefined)}
			body={
				<div className='p-4 mt-4'>
					<SimpleBar style={{ maxHeight: '40rem', borderTop: '1px solid #E5E5E5' }}>
						<SimpleTable header={header} getTableBody={getTableBody} />
					</SimpleBar>
				</div>
			}
			buttons={
				[<LabelsPrintableContent
					key={'printable_content_boxes'} //required
					id={'printable_content_boxes'} //required
					isHidingContent
					orientation='l'
					buttonDisabled={htmlToPrint == null}
					filename={'Boxes_labels'} //Filename
					buttonClassName="btn btn-primary"
					buttonContent={
						<div className="d-flex flex-row justify-content-center align-items-center m-0">
							{t('print', { ns: 'naming' })}
							<i className="bx bxs-printer font-size-20 align-middle ms-2" />
						</div>
					}
				>
					{htmlToPrint}
				</LabelsPrintableContent>]}
		/>
	)
}

PrintLabelsModal.propTypes = {
	boxList: PropTypes.array,
	t: PropTypes.func,
}

const mapStateToProps = (state) => ({
	boxList: state.Orders.boxList,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PrintLabelsModal))
