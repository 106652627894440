import {
	API_ERROR,
	LOGIN_SUCCESS,
	LOGIN_USER,
	LOGOUT_USER,
	LOGOUT_USER_SUCCESS,
	VERIFY_USER_TOKEN,
	VERIFY_USER_TOKEN_FAIL,
	VERIFY_USER_TOKEN_SUCCESS,
} from './actionTypes'

export const loginUser = (user, history) => {
	return {
		type: LOGIN_USER,
		payload: { user, history },
	}
}

export const loginSuccess = ({ data, headers }) => {
	return {
		type: LOGIN_SUCCESS,
		payload: { data, headers },
	}
}

export const logoutUser = email => {
	return {
		type: LOGOUT_USER,
		payload: { email },
	}
}

export const logoutUserSuccess = () => {
	return {
		type: LOGOUT_USER_SUCCESS,
		payload: {},
	}
}

export const apiError = error => {
	return {
		type: API_ERROR,
		payload: error,
	}
}

export const verifyUserToken = ({ email, token }) => {
	return {
		type: VERIFY_USER_TOKEN,
		payload: { email, token },
	}
}

export const verifyUserTokenSuccess = () => {
	return {
		type: VERIFY_USER_TOKEN_SUCCESS,
	}
}

export const verifyUserTokenFail = () => {
	return {
		type: VERIFY_USER_TOKEN_FAIL,
	}
}
