import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const ImagesModal = ({ isLoadingGetQualityVerificationImages, qualityVerficationImages, error, t, dontShowModalAutomatic }) => {

	const [isOpenModal, setIsOpenModal] = useState(false)

	useEffect(() => {
		if (isLoadingGetQualityVerificationImages === true && !dontShowModalAutomatic) setIsOpenModal(true)
	}, [isLoadingGetQualityVerificationImages])

	return (
		<Modal
			title={t('images', { ns: 'naming' })}
			body={
				<div className='mx-auto d-flex flex-column'>
					{isLoadingGetQualityVerificationImages ? <Loader className='fs-1' /> : error ? <Alert type={'danger'} message={error} /> :
						qualityVerficationImages.length > 0 ? qualityVerficationImages.map((image, index) => (
							<a key={`incidence-image-${index}`} href={image.src} target="_blank" rel="noopener noreferrer">
								<img className="verificationImage" key={`image_${image.name}`} src={image.src} />
							</a>
						)) : <div>{t('no_images', { ns: 'naming' })}</div>
					}
				</div>}
			isOpen={isOpenModal}
			closeModal={() => setIsOpenModal(false)}

		/>
	)
}

ImagesModal.propTypes = {
	isOpenModal: PropTypes.bool,
	setIsOpenModal: PropTypes.func,
	error: PropTypes.string,
	isLoadingGetQualityVerificationImages: PropTypes.bool,
	t: PropTypes.func,
	qualityVerficationImages: PropTypes.array,
	dontShowModalAutomatic: PropTypes.bool
}

const mapStateToProps = (state) => ({
	isLoadingGetQualityVerificationImages: state.CloudStorage.isLoadingGetQualityVerificationImages,
	error: state.CloudStorage.error,
	qualityVerficationImages: state.CloudStorage.qualityVerficationImages,
	dontShowModalAutomatic: state.CloudStorage.dontShowModalAutomatic
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ImagesModal))
