import { getDate } from 'common/Utils/StringUtilities'
import { PartName } from 'components/Common/PartName'
import RemainingDays from 'components/Common/RemainingDays'
import { Couriers } from 'constants/couriers'
import { Tracking } from 'pages/SupplierProduction/Tracking'
import PropTypes from 'prop-types'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

/**
 * Este componente es un item de listado que se utiliza en 3 paginas:
 * /receptions -> Columna a la izquierda -> Pedidos abiertos (isProductions=false)
 * /receptions -> Columna a la izquierda -> Pedidos previstos a recibir en los próximos días (isProductions=true)
 * /verifications -> Columna a la izquierda -> Pedidos abiertos (isProductions=false)
 */
const ReceptionOrderSummary = (props) => {

	const {
		order,
		isProductions,
		selectedOrderId,
	} = props

	const { t } = useTranslation('naming')

	const defaultPClassName = 'm-0 p-0 me-1 '

	return (
		<div
			className="d-flex justify-content-between border p-2 pb-3 cursor-pointer"
			style={{ backgroundColor: selectedOrderId?.orderId == order.id ? '#E3F2FD' : '' }}>
			{/* Pegado a la izquierda: */}
			<div className={'d-flex flex-column'}>
				{/* Nombre de la organización que ha hecho el pedido */}
				<strong><PartName name={order.organizationName} /></strong>
				{/* ID del pedido */}
				<div className="d-flex flex-row">
					<p className={defaultPClassName + 'me-1'}>ID: </p>
					<strong>{order.id}</strong>
				</div>
				{/* ??? */}
				{isProductions == false && (
					<div className={'d-flex flex-row'}>
						<p className={defaultPClassName}>{order.references}</p>
						<p className={defaultPClassName}>{t('Referencias')}</p>
						<p className={defaultPClassName}> {order.totalPieces}</p>
						<p className={defaultPClassName}>{t('parts')}</p>
					</div>
				)}
				{/* Fecha de llegada real */}
				{isProductions && order.arrivalDate && (
					<div className={'d-flex flex-row'}>
						<p className={defaultPClassName}>{t('arrival') + ':'}</p>
						<p className={defaultPClassName}>{getDate(order.arrivalDate)}</p>
					</div>
				)}
				{/* Fecha de envío */}
				<div className={'d-flex flex-row'}>
					<p className={defaultPClassName}>{t('shipment') + ':'}</p>
					<p className={defaultPClassName}>{getDate(order.shipmentDate)}</p>
				</div>
				{/* Semáforo: Días restantes */}
				<p className={defaultPClassName}>
					<RemainingDays date={order.shipmentDate} finalDate={order.arrivalDate} />
				</p>
				{/* Warning de Discomformidad */}
				{order.disconformity && (
					<div className="mt-2">
						<span className="bg-red rounded p-1 px-2 text-white">
							<strong>DISCONFORMITY</strong>
						</span>
					</div>
				)}
			</div>
			{/* Pegado a la derecha: */}
			<div className={'d-flex flex-column'}>
				{/* Estado del pedido (pendiente / completado / faltan piezas) */}
				<span className={'d-flex flex-row justify-content-end'}>
					{isProductions ? (
						<>
							{/* Warning: Pendiente (aun no ha llegado) */}
							{t('pending')}
							<i className='bx bxs-circle margin-top-017rem ms-1 text-warning' />
						</>
					) : order.completed ? (
						<>
							{/* Success: Pedido completado */}
							{t('complete')}
							<i className='bx bxs-circle margin-top-017rem ms-1 text-success' />
						</>
					) : (
						<>
							{/* Warning: Faltan piezas */}
							{t('parts_pending')}
							<i className='bx bxs-circle margin-top-017rem ms-1 text-danger' />
						</>
					)}
				</span>
				{isProductions ? (
					<>
						{/* Numero de seguimiento + Link */}
						<Tracking trackingId={order.trackingNumber} courier={Couriers.DHL} />
						{/* Proveedor */}
						<span className={'d-flex flex-row justify-content-end'}>
							<strong className="text-nowrap">{t('supplier')}</strong>
							<p className={defaultPClassName + 'ms-1 text-blue'}>
								<PartName name={order.supplier} maxLenght={15} />
							</p>
						</span>
					</>
				) : (
					<>
						{/* Caja asignada */}
						<strong className="text-end">{order.box || t('no_box')}</strong>
						{/* Si el pedido NO va hacia España, mostrar que es de UPS */}
						{order.receivingCountry !== 'ES' && (
							<div className="d-flex justify-content-end">
								<span className="bg-yellow rounded p-1 px-2 text-white text-end">
									<strong>UPS</strong>
								</span>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	)
}

ReceptionOrderSummary.propTypes = {
	order: PropTypes.object,
	t: PropTypes.func,
	isProductions: PropTypes.bool,
	selectedOrderId: PropTypes.any,
}

const mapStateToProps = (state) => ({
	selectedOrderId: state.Orders.selectedOrderId,
})

export default connect(
	mapStateToProps,
)(
	withTranslation()(
		ReceptionOrderSummary,
	),
)
