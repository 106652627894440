import PhoneFlag from 'pages/CRMUserDetail/ComponenteTablas/PhoneFlag'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getExpeditionNacexLabels, getExpeditionUpsLabels, getOrderDetailById, uploadOrderShipmentFiles } from 'store/actions'

const ExpeditionSearchAndClientinfo = ({ isLoading, selectedExpeditionOrderId, expeditionOrderList, getExpeditionNacexLabels, getExpeditionUpsLabels, upsLabels, nacexLabels, generatedPdf, uploadOrderShipmentFiles }) => {
	const { t } = useTranslation()
	const expeditions = useMemo(() => expeditionOrderList?.find((order) => order.id === selectedExpeditionOrderId?.orderId)?.shipments || [], [expeditionOrderList, selectedExpeditionOrderId])
	const orderInfo = useMemo(() => expeditionOrderList?.find((order) => order.id === selectedExpeditionOrderId?.orderId) || null, [expeditionOrderList, selectedExpeditionOrderId])

	return (
		<div>
			{orderInfo && !isLoading &&
				<div className="mb-3 p-4 bg-white">
					<div
						className="w-100 p-2 d-flex align-items-center"
						style={{
							border: '0.5px solid lightgray',
							borderTopLeftRadius: '4px',
							borderTopRightRadius: '4px',
							backgroundColor: 'whitesmoke',
						}}
					>
						{t('shipment-data', { ns: 'naming' })}
					</div>
					<div
						className="w-100 ps-2 d-column align-items-center"
						style={{ border: '0.5px solid lightgray', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }}
					>
						<p className="pt-3">{t('company', { ns: 'naming' })}: <strong>{orderInfo?.billingInfo?.company}</strong></p>
						<p>
							{t('name', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.billingInfo?.firstName} {orderInfo?.billingInfo?.lastName}
							</strong>
						</p>
						<p>
							{t('address', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.billingInfo?.address1}
							</strong>
						</p>
						<p>
							{t('postal_code', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.billingInfo?.postalCode}
							</strong>
						</p>
						<p>
							{t('city', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.billingInfo?.city}
							</strong>
						</p>
						<p>
							{t('province', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.billingInfo?.state}
							</strong>
						</p>
						<p>
							{t('country', { ns: 'naming' })}:&nbsp;
							<strong>
								{orderInfo?.billingInfo?.country}
							</strong>
						</p>
						<p>
							{t('email', { ns: 'naming' })}:&nbsp;
							<strong style={{ color: '#0066b2', hyphens: 'auto' }}>
								{orderInfo?.billingInfo?.email}
							</strong>
						</p>
						<p className='d-flex flex-row'>
							{t('phone', { ns: 'naming' })}:&nbsp;
							<strong>
								<PhoneFlag prefix={orderInfo?.billingInfo?.phonePrefix} phoneNumber={orderInfo?.billingInfo?.phoneNumber} />
							</strong>
						</p>

					</div>
				</div>
			}
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		orderDetail: state.Orders.orderDetail,
		isLoading: state.Orders.isLoading,
		selectedExpeditionOrderId: state.Orders.selectedExpeditionOrderId,
		orderList: state.Orders.orderList,
		expeditionOrderList: state.Orders.expeditionOrderList,
		upsLabels: state.Orders.upsLabels,
		nacexLabels: state.Orders.nacexLabels,
		generatedPdf: state.Orders.generatedPdf,
	}
}

ExpeditionSearchAndClientinfo.propTypes = {
	orderDetail: PropTypes.object,
	getOrderDetailById: PropTypes.func,
	isLoading: PropTypes.bool,
	selectedExpeditionOrderId: PropTypes.any,
	orderList: PropTypes.array,
	expeditionOrderList: PropTypes.array,
	setManualScanned: PropTypes.func,
	getExpeditionNacexLabels: PropTypes.func,
	getExpeditionUpsLabels: PropTypes.func,
	upsLabels: PropTypes.object,
	nacexLabels: PropTypes.object,
	generatedPdf: PropTypes.any,
	uploadOrderShipmentFiles: PropTypes.func,
}

export default connect(mapStateToProps, {
	getOrderDetailById,
	getExpeditionNacexLabels,
	getExpeditionUpsLabels,
	uploadOrderShipmentFiles,
})(
	withTranslation()(ExpeditionSearchAndClientinfo),
)
