import { roundCurrency, roundIntrastatWeight } from 'common/Utils/NumberUtilities'
import { getDate } from 'common/Utils/StringUtilities'
import { downloadXLSX } from 'common/Utils/downloadUtils'
import Alert from 'components/Common/Alert'
import Loader from 'components/Common/Loader'
import { PartName } from 'components/Common/PartName'
import Table from 'components/Table/Table'
import OrganizationFlag from 'pages/Orders/OrganizationFlag'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { getExportedPartListByDate } from 'store/actions'

export const Intrastat = ({ dateRange, getExportedPartListByDate, parts, noPaginatedParts, error, isLoading, t, totalRows, isLoadingNoPaginated }) => {

	const [downloadCSVRequested, setDownloadCSVRequested] = useState(false)

	const header = {
		partId: t('partId', { ns: 'naming' }),
		orderId: t('order_id', { ns: 'naming' }),
		shippedQuantity: t('shippedQuantity', { ns: 'naming' }),
		part_name: t('part_name', { ns: 'naming' }),
		weight: t('weight', { ns: 'naming' }),
		material: t('material', { ns: 'naming' }),
		totalCost: t('totalCost', { ns: 'naming' }),
		customer: t('client', { ns: 'naming' }),
		vat: t('vat', { ns: 'naming' }),
		shipmentDate: t('shipmentDate', { ns: 'naming' }),
	}

	const PAGE_SIZE_LIST = [
		{ value: 50, text: '50' },
		{ value: 100, text: '100' },
		{ value: 200, text: '200' },
		{ value: 500, text: '500' },
		{ value: 1000, text: '1000' },
	]

	const [pagination, setPagination] = useState({
		page: 1,
		pageSize: 50,
	})

	useEffect(() => {
		if (dateRange != null) {
			getExportedPartListByDate(dateRange, pagination)
		}
	}, [dateRange, pagination])

	useEffect(() => {
		if (noPaginatedParts && noPaginatedParts.length > 0 && downloadCSVRequested) {
			downloadCsv()
			setDownloadCSVRequested(false)
		}
	}, [noPaginatedParts])

	const downloadCsv = () => {

		const header = {
			partId: t('partId', { ns: 'naming' }),
			order_id: t('order_id', { ns: 'naming' }),
			shippedQuantity: t('shippedQuantity', { ns: 'naming' }),
			part_name: t('part_name', { ns: 'naming' }),
			weight: t('weight', { ns: 'naming' }),
			material: t('material', { ns: 'naming' }),
			totalCost: t('totalCost', { ns: 'naming' }),
			client: t('client', { ns: 'naming' }),
			organizationName: t('organizationName', { ns: 'naming' }),
			country: t('country', { ns: 'naming' }),
			vat: t('vat', { ns: 'naming' }),
			shipmentDate: t('shipmentDate', { ns: 'naming' }),
		}

		const csvItems = noPaginatedParts.map((part) => {
			return {
				partId: part.partId,
				order_id: part.orderId,
				shippedQuantity: part.partQuantity,
				part_name: part.partName,
				weight: `${roundIntrastatWeight(part.totalWeight)}kg`,
				material: part.partMaterial,
				totalCost: `${roundCurrency(part.subtotal)}€`,
				client: `${part.firstName} ${part.lastName}`,
				organizationName: part.organizationName,
				country: part.country ? t(part.country.toLowerCase(), { ns: 'countries' }) : '',
				vat: part.vat,
				shipmentDate: getDate(part.shippingDate),
			}
		})

		downloadXLSX({ rows: [header, ...csvItems], fileName: `intrastat_${getDate(dateRange.startDate).replaceAll('/', '-')}_to_${getDate(dateRange.endDate).replaceAll('/', '-')}` })

	}

	const handleGetNotPaginatedData = () => { getExportedPartListByDate(dateRange) }

	const getItemsToRender = () => {
		return parts && parts.sort((a, b) => (new Date(b.shippingDate).getTime()) - (new Date(a.shippingDate).getTime())).map(part => {
			return {
				partId: part.partId,
				orderId: part.orderId,
				shippedQuantity: part.partQuantity,
				part_name: <PartName name={part.partName} />,
				weight: `${roundIntrastatWeight(part.totalWeight)}kg`,
				material: part.partMaterial,
				totalCost: `${roundCurrency(part.subtotal)}€`,
				customer: <OrganizationFlag country={part.country} firstName={part.firstName} lastName={part.lastName} organizationName={part.organizationName} />,
				vat: part.vat,
				shipmentDate: getDate(part.shippingDate),

			}
		})
	}
	const itemsToRender = useMemo(() => getItemsToRender(), [parts])

	return (
		<div style={{ minHeight: '44rem' }} className={`d-flex justify-content-center w-100 ${(error || isLoading ? 'align-items-center' : 'align-items-start')}`}>
			{error ? <div className='h-100 mt-4'><Alert message={error} type='danger' /></div> : isLoading ? <Loader className='font-size-50' /> :
				<div className='w-100 h-100  py-3 px-1'>
					{
						parts.length > 0 ?
							<div className='d-flex flex-column w-auto'>
								<button disabled={isLoadingNoPaginated} onClick={() => {
									setDownloadCSVRequested(true)
									handleGetNotPaginatedData()
								}} className='btn btn-secondary m-0 pe-3 width-by-content'>{isLoadingNoPaginated ? <Loader /> : t('export', { ns: 'naming' })}</button>
								<Table
									header={header}
									items={itemsToRender}
									language="es"
									selectRow={{ mode: 'checkbox', hideSelectColumn: true }}
									rowQuantityList={[
										{ text: '100', value: 100 },
										{ text: '150', value: 150 },
										{ text: '200', value: 200 },
										{ text: '500', value: 500 },
										{ text: '1000', value: 1000 },
									]}
									customFilter={true}
									remote
									paginationProps={{
										custom: true,
										totalSize: totalRows,
										page: pagination.page,
										sizePerPage: pagination.pageSize,
										sizePerPageList: PAGE_SIZE_LIST,
										onPageChange: (page) => {
											setPagination((prevState) => { return { ...prevState, page } })
										},
										onSizePerPageChange: (sizePerPage) => {
											setPagination({
												page: 1,
												pageSize: sizePerPage,
											})
										},
									}}

								/>
							</div>

							: <div>NO DATA FOUND</div>
					}
				</div>
			}
		</div>
	)
}

Intrastat.propTypes = {
	dateRange: PropTypes.object,
	getExportedPartListByDate: PropTypes.func,
	parts: PropTypes.array,
	noPaginatedParts: PropTypes.array,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	t: PropTypes.func,
	totalRows: PropTypes.number,
	isLoadingNoPaginated: PropTypes.bool,
}

const mapStateToProps = (state) => ({
	parts: state.Orders.exportedPartListReport.parts,
	error: state.Orders.exportedPartListReport.error,
	isLoading: state.Orders.exportedPartListReport.isLoading,
	totalRows: state.Orders.exportedPartListReport.totalRows,
	noPaginatedParts: state.Orders.exportedPartListReport.noPaginatedParts,
	isLoadingNoPaginated: state.Orders.exportedPartListReport.isLoadingNoPaginated,

})

const mapDispatchToProps = { getExportedPartListByDate }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Intrastat))
