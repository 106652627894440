import { useState } from 'react'

// Example: initialState = { firstName: null, lastName: null}, 
// handleOnChange('firstName', 'alejandro')
// expected: objectData = { firstName: "alejandro", lastName: null } objecthasprop

export const useObjectState = (initialState) => {
	const [objectData, setObjectData] = useState(initialState)

	const handleOnChange = (key, vale) => {
		if (objectData.hasOwnProperty(key)) {
			setObjectData({ ...objectData, [key]: vale })
		}
	}

	const resetState = () => {
		setObjectData(initialState)
	}

	const handleOnChangeDateRange = (key, dateArray) => {
		if (objectData.hasOwnProperty(key) && dateArray[0] != null && dateArray[1] != null) {
			setObjectData({
				...objectData,
				[key]: {
					startDate: new Date(dateArray[0]),
					endDate: new Date(new Date(dateArray[1]).setHours(23, 59, 59)),
				},
			})
		}
	}

	const resetDateRange = (key) => {
		if (objectData.hasOwnProperty(key)) {
			setObjectData({ ...objectData, [key]: { startDate: null, endDate: null } })
		}
	}

	return { objectData, handleOnChange, handleOnChangeDateRange, setObjectData, resetState, resetDateRange }

} 
