import Modal from 'components/Common/Modal/Modal'
import StlViewer from 'components/Common/StlViewer/StlViewer'
import PropTypes from 'prop-types'

const StlViewerModal = ({
	isSTLViewerModalOpen,
	closeSTLViewerModal,
	stlViewerData,
}) => {
	return <Modal
		isOpen={isSTLViewerModalOpen}
		closeModal={closeSTLViewerModal}
		title={stlViewerData.partName && stlViewerData.partName}
		body={
			<StlViewer
				modelSrc={stlViewerData.modelSrc && stlViewerData.modelSrc}
				windowSize={{ x: 400, y: 400 }}
				partId={stlViewerData.partId && stlViewerData.partId}
				size={stlViewerData.size && stlViewerData.size}
				weight={stlViewerData.weight && stlViewerData.weight}
			/>
		}
	/>
}

StlViewerModal.propTypes = {
	isSTLViewerModalOpen: PropTypes.bool,
	closeSTLViewerModal: PropTypes.func,
	stlViewerData: PropTypes.object,
}

export default StlViewerModal
