import { getLanguageKeyValuePair } from 'common/Utils/LanguagesUtils'
import { mapManagers } from 'common/Utils/ManagersUtils'
import { ConfigTranslations } from 'common/Utils/PartConfigTranslations'
import { getStatusClassName, getTechnologyClassName } from 'common/Utils/StringUtilities'
import Modal from 'components/Common/Modal/Modal'
import { OptionsInput } from 'components/Common/OptionsInput'
import SearchInput from 'components/Common/SearchInput'
import Switch from 'components/Common/Switch/Switch'
import CountryFlag from 'pages/CRMUserDetail/ComponenteTablas/CountryFlag'
import PropTypes from 'prop-types'
import { useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
const OffersFilterModal = ({
	t,
	i18n: { language },
	handleOnChangeKeyword,
	handleOnChangeFilters,
	managers,
	partConfigOptions,
}) => {

	const initialState = {
		status: [],
		technologies: [],
		managers: [],
		countries: [],
		languages: [],
		dateRange: {
			startDate: null,
			endDate: null,
		},
		onlyNewUsers: false,
	}

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [filterData, setFilterData] = useState(initialState)

	const handleOnChangeFilterData = (key, value) => {
		setFilterData({ ...filterData, [key]: value.map(item => item.value) })
	}

	const handleOnChangeDateRange = (value) => {
		setFilterData({
			...filterData, dateRange: {
				startDate: value[0],
				endDate: value[1],
			},
		})
	}

	const getOfferStatusLabelById = (id) => {
		return (
			<span id={`offer_status_${id}`} className={getStatusClassName(id)}>{t(`offer_status_${id}`, { ns: 'status' })}</span>
		)
	}

	const getTechnologyLabelById = (id) => {
		return (
			<span id={ConfigTranslations.getTechnologyName(id, partConfigOptions, 'en')} className={getTechnologyClassName(id)}>{ConfigTranslations.getTechnologyName({ id, partConfigOptions, language })}</span>
		)
	}

	const statusOptions = [
		{ value: 10, label: getOfferStatusLabelById(10) },
		{ value: 20, label: getOfferStatusLabelById(20) },
		{ value: 30, label: getOfferStatusLabelById(30) },
		{ value: 40, label: getOfferStatusLabelById(40) },
		{ value: 91, label: getOfferStatusLabelById(91) },
		{ value: 92, label: getOfferStatusLabelById(92) },
	]

	const technologiesOptions = [
		{ value: 1, label: getTechnologyLabelById(1) },
		{ value: 2, label: getTechnologyLabelById(2) },
		{ value: 3, label: getTechnologyLabelById(3) },
		{ value: 4, label: getTechnologyLabelById(4) },
		{ value: 5, label: getTechnologyLabelById(5) },
	]

	const managerOptions = managers && mapManagers(managers)

	const handleOnSave = () => {
		handleOnChangeFilters(filterData)
		setIsModalOpen(false)
	}

	const handleOnCleanFilters = () => {
		setFilterData(initialState)
		handleOnChangeFilters(initialState)
		setIsModalOpen(false)
	}

	return (
		<div className='d-flex'>
			<div className="position-relative me-3">
				<SearchInput
					onChange={(e) => handleOnChangeKeyword(e)}
				/>
			</div>
			<div>
				<div id='filterDiv' className='btn btn-secondary' onClick={() => setIsModalOpen(true)}>
					{t('filter', { ns: 'naming' })}
				</div>
				<Modal
					title={t('filterOffers', { ns: 'naming' })}
					body={
						<div className='p-4'>
							<div className='d-flex flex-column'>
								<div className='my-1'>
									<span className='ms-2'>{t('status', { ns: 'naming' })}</span>
									<div id={'Status' + 'Input'}>
										<OptionsInput
											isMulti
											setValue={(e) => { handleOnChangeFilterData('status', e) }}
											value={filterData.status}
											automaticMappedValue
											options={statusOptions}
										/>
									</div>
								</div>
								<div className='my-1'>
									<span className='ms-2'>{t('technologies', { ns: 'naming' })}</span>
									<div id={'Technologies' + 'Input'}>
										<OptionsInput
											isMulti
											setValue={(e) => { handleOnChangeFilterData('technologies', e) }}
											options={technologiesOptions}
											automaticMappedValue
											value={filterData.technologies}
										/>
									</div>
								</div>
								<div style={{ display: 'flex' }}>
									<div className='my-1 ms-2' style={{ flex: 1 }}>
										<span>{t('new-user', { ns: 'naming' })}</span>
										<Switch
											id={'switch_new_user'}
											value={filterData.onlyNewUsers}
											onChange={(e) => { setFilterData({ ...filterData, onlyNewUsers: e.target.checked }) }}
											className={'fs-3'} />
									</div>
									<div className='my-1 ms-2' style={{ flex: 1 }}>
										<span>{t('offer_not_seen', { ns: 'naming' })}</span>
										<Switch
											id={'switch_offer_not_seen'}
											value={filterData.onlyOffersNotSeen}
											onChange={(e) => { setFilterData({ ...filterData, onlyOffersNotSeen: e.target.checked }) }}
											className={'fs-3'} />
									</div>
								</div>
								<div className='my-1'>
									<span className='ms-2'>{t('managers', { ns: 'naming' })}</span>
									<div id={'Managers' + 'Input'}>
										<OptionsInput
											isMulti
											setValue={(e) => { handleOnChangeFilterData('managers', e) }}
											options={managerOptions}
											automaticMappedValue
											value={filterData.managers}
										/>
									</div>
								</div>
								<div className='my-1 '>
									<span className='ms-2'>{t('countries', { ns: 'naming' })}</span>
									<div id={'Countries' + 'Input'}>
										<CountryFlag
											isEditable
											onChange={(e) => {
												handleOnChangeFilterData('countries', e)
											}}
											isMulti
											automaticMappedValue
											countryState={filterData.countries}
										/>
									</div>
								</div>
								<div className='my-1'>
									<span className='ms-2'>{t('languages', { ns: 'naming' })}</span>
									<div id={'Languages' + 'Input'}>
										<OptionsInput
											isMulti
											setValue={(e) => { handleOnChangeFilterData('languages', e) }}
											options={getLanguageKeyValuePair()}
											automaticMappedValue
											value={filterData.languages}
										/>
									</div>
								</div>
								<div className='my-1'>
									<span className='ms-2'>{t('start_end_date', { ns: 'naming' })}</span>
									<div className='mx-2'>
										<Flatpickr
											onChange={e => { handleOnChangeDateRange(e) }}
											id={'dateRange_input_filter'}
											className="form-control d-block"
											value={[filterData.dateRange?.startDate, filterData.dateRange?.endDate]}
											placeholder="Fecha inicial - Fecha final"
											options={{
												mode: 'range',
												dateFormat: 'Y-m-d',
											}}
										/>
									</div>
								</div>
							</div>
						</div>}
					buttons={[
						<button id="resetFilterButton" key="resetFilter" className='btn btn-primary' onClick={() => { handleOnCleanFilters() }}>
							{t('clean', { ns: 'naming' })}</button>,
						<button id="saveButtonOfferFilterListButton" key="saveButtonOfferFilterList" className='btn btn-primary' onClick={(e) => handleOnSave()}>
							{t('apply', { ns: 'naming' })}</button>,
					]}
					isOpen={isModalOpen}
					closeModal={() => setIsModalOpen(false)}
				/>
			</div>
		</div>
	)
}

OffersFilterModal.propTypes = {
	t: PropTypes.func,
	handleOnChangeKeyword: PropTypes.func,
	managers: PropTypes.array,
	handleOnChangeFilters: PropTypes.func,
	partConfigOptions: PropTypes.object,
}

const mapStateToProps = (state) => ({
	managers: state.Users.managers,
	partConfigOptions: state.Parts.partConfigOptions,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OffersFilterModal))
