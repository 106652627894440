import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

export const BlueprintSeenModal = ({ t, isOpen, closeModal }) => {
	return (
		<>
			<Modal
				isOpen={isOpen}
				closeModal={closeModal}
				title={`${t('attention', { ns: 'naming' })}`}
				body={
					<div className="px-4 py-2">
						{t('look-at-blueprint-first', { ns: 'naming' })}
					</div>
				}
			/>
		</>
	)
}

const mapStateToProps = state => {
	return {}
}

BlueprintSeenModal.propTypes = {
	t: PropTypes.func,
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
}

export default connect(mapStateToProps, {})(withTranslation()(BlueprintSeenModal))
