import Modal from 'components/Common/Modal/Modal'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { addExpeditionOrder, addScannedPieceToExpeditionOrder, getOpenedExpeditionOrders, getProductionItemsByOrderId, scanOrderExpeditionPart, setLastScannedPart, setSelectedExpeditionOrderId } from 'store/actions'
import useScanDetection from 'use-scan-detection'
import { getScanPartData } from 'utils/getScanPartData'
import barcodeScanner from '../../../assets/images/logistic/barcodeScanner.png'
import Loader from '../../../components/Common/Loader'
import SelectedExpeditionOrder from './SelectedExpeditionOrder'

const ExpeditionOrderInfo = (props) => {

	const {
		selectedExpeditionOrderId,
		setSelectedExpeditionOrderId,
		isLoadingOrders,
		getProductionItemsByOrderId,
		productionItemsByOrderId,
		addExpeditionOrder,
		expeditionOrderList,
		addScannedPieceToExpeditionOrder,
		setManualScanned,
		manualScanned,
		openedExpeditonOrders,
		boxList,
		scanOrderExpeditionPart,
		scanPart,
		scannedPart,
		setScannedPart,
		scannedQuantity,
		setScannedQuantity,
		scannedOrder,
		setScannedOrder,
	} = props

	const { t } = useTranslation('naming')

	const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
	const [errorMessages, setErrorMessages] = useState('')

	const productionItems = useMemo(() => productionItemsByOrderId || [], [productionItemsByOrderId])
	const selectedOrder = useMemo(() => expeditionOrderList.find((order) => order.id === selectedExpeditionOrderId?.orderId) || {}, [expeditionOrderList, selectedExpeditionOrderId])

	const INCIDENCE = 'partIncidenceError'
	const NOT_VERIFIED = 'partNotVerifiedError'

	useScanDetection({
		onComplete: (code) => {
			scanPart(code)
		},
	})

	useEffect(() => {
		const checkBoxCode = (orderId) => {
			const boxCodes = []
			boxList.forEach((box) => {
				if (box.orderId == orderId) {
					boxCodes.push(box.ref)
				}
			})
			return boxCodes.join(', ')
		}
		const checkIfCompleted = (order) => {
			let completed = true
			order.orderItems.forEach((item) => {
				if (item.shippedQuantity < item.quantity) {
					completed = false
				}
			})
			return completed
		}
		if (openedExpeditonOrders.length > 0) {
			openedExpeditonOrders.forEach((order) => {
				const parsedOrderDetail = {
					...order,
					expeditions: [],
					boxCode: checkBoxCode(order.id),
					billingInfo: order.shippingAddress,
					completed: checkIfCompleted(order),
				}
				addExpeditionOrder(parsedOrderDetail, order.id)
			})
		}
	}, [openedExpeditonOrders])

	useEffect(() => {
		if (selectedExpeditionOrderId && selectedExpeditionOrderId?.orderId != 0) {
			getProductionItemsByOrderId({ orderId: selectedExpeditionOrderId?.orderId })
		}
	}, [selectedExpeditionOrderId])

	useEffect(() => {
		if (scannedPart && scannedOrder && scannedQuantity && (selectedOrder?.id == scannedOrder)) {
			const scannedItem = selectedOrder?.orderItems?.find((item) => item.part.id === scannedPart)

			if (scannedItem?.qualityState == null) {
				setErrorMessages(NOT_VERIFIED)
				setIsOpenErrorModal(true)
				setScannedPart(null)
				setScannedQuantity(null)
				setScannedOrder(null)
				return
			}
			if (scannedItem?.qualityState == false) {
				setErrorMessages(INCIDENCE)
				setIsOpenErrorModal(true)
			}

			addScannedPieceToExpeditionOrder(scannedPart, scannedOrder, scannedQuantity)
			scanOrderExpeditionPart({
				data: {
					orderId: scannedOrder,
					partId: scannedPart,
					scannedQuantity: scannedQuantity,
				},
			})
			setScannedPart(null)
			setScannedQuantity(null)
			setScannedOrder(null)

		}
	}, [scannedPart, scannedOrder, scannedQuantity, selectedOrder])

	useEffect(() => {
		if (manualScanned) {
			const { orderId, partId, quantity } = getScanPartData(manualScanned)
			const orderExists = expeditionOrderList.some((order) => order.id == orderId)
			if (!orderExists) return
			setScannedOrder(orderId)
			setScannedPart(partId)
			setLastScannedPart(partId)
			setScannedQuantity(quantity)
			setSelectedExpeditionOrderId(orderId)
			setManualScanned(null)
		}
	}, [manualScanned])

	return !selectedExpeditionOrderId ? (
		<div
			className="d-flex flex-column align-content-center text-center bg-transparent"
			style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', borderRadius: '5px' }}
		>
			<h3 className="mt-4">{t('scanPiece')}</h3>
			<img src={barcodeScanner}
				alt="barcodeScanner"
				style={{ height: '500px', width: '100%', objectFit: 'contain', background: 'transparent' }}
			/>
		</div>
	) : (
		<>
			{isLoadingOrders ? (
				<div className="d-flex justify-content-center">
					<Loader className="fs-1 p-4" />
				</div>
			) : (
				<>
					<SelectedExpeditionOrder
						orderInfo={selectedOrder}
						productionItems={productionItems} />
					<Modal
						isOpen={isOpenErrorModal}
						closeModal={() => setIsOpenErrorModal(false)}
						title={t('error_message')}
						body={
							<div>
								<p className="pt-3 ps-3 pe-3">{t(errorMessages)}</p>
							</div>
						} />
				</>
			)}
		</>
	)

}

ExpeditionOrderInfo.propTypes = {
	// from connect HOC - mapStateToProps:
	boxList: PropTypes.any,
	expeditionOrderList: PropTypes.any,
	isLoadingOrders: PropTypes.bool,
	openedExpeditonOrders: PropTypes.any,
	productionItemsByOrderId: PropTypes.any,

	// from connect HOC - mapDispatchToProps:
	addExpeditionOrder: PropTypes.func,
	addScannedPieceToExpeditionOrder: PropTypes.func,
	getOpenedExpeditionOrders: PropTypes.func,
	getProductionItemsByOrderId: PropTypes.func,
	scanOrderExpeditionPart: PropTypes.func,
	setSelectedExpeditionOrderId: PropTypes.func,

	// from withTranslation HOC:
	t: PropTypes.func,

	// from parent component:
	selectedExpeditionOrderId: PropTypes.any,
	manualScanned: PropTypes.number,
	setManualScanned: PropTypes.func,
}

const mapStateToProps = (state) => ({
	boxList: state.Orders.boxList,
	expeditionOrderList: state.Orders.expeditionOrderList,
	isLoadingOrders: state.Orders.isLoading,
	openedExpeditonOrders: state.Orders.openedExpeditonOrders,
	productionItemsByOrderId: state.Productions.productionItemsByOrderId,
})

const mapDispatchToProps = {
	addExpeditionOrder,
	addScannedPieceToExpeditionOrder,
	getOpenedExpeditionOrders,
	getProductionItemsByOrderId,
	scanOrderExpeditionPart,
	setSelectedExpeditionOrderId,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(
	withTranslation()(
		ExpeditionOrderInfo,
	),
)
