import {
	FORGET_PASSWORD,
	FORGET_PASSWORD_ERROR,
	FORGET_PASSWORD_SUCCESS,
	RESET_FORGET_PASSWORD_EMAIL_STATE,
	RESET_PASSWORD,
	RESET_PASSWORD_ERROR,
	RESET_PASSWORD_SUCCESS,
	VERIFY_TOKEN,
	VERIFY_TOKEN_ERROR,
	VERIFY_TOKEN_SUCCESS,
} from './actionTypes'

const initialState = {
	forgetSuccessMsg: null,
	forgetError: null,
	errorId: null,
	isVerifyingToken: false,
	message: '',
	error: null,
}

const forgetPassword = (state = initialState, action) => {
	switch (action.type) {
		case FORGET_PASSWORD:
			state = {
				...state,
				forgetSuccessMsg: null,
				forgetError: null,
				isLoading: true,
			}
			break
		case FORGET_PASSWORD_SUCCESS:
			state = {
				...state,
				forgetSuccessMsg: action.payload,
				isLoading: false,
			}
			break
		case FORGET_PASSWORD_ERROR:
			state = {
				...state,
				forgetError: action.payload.message
					? action.payload.message
					: action.payload,
				isLoading: false,
			}
			break
		case RESET_FORGET_PASSWORD_EMAIL_STATE:
			state = {
				...state,
				forgetSuccessMsg: null,
				forgetError: null,
				isLoading: false,
			}
			break
		case RESET_PASSWORD:
			state = {
				...state,
				isLoading: true,
			}
			break
		case RESET_PASSWORD_SUCCESS:
			state = {
				...state,
				message: action.payload.message,
				isLoading: false,
			}
			break
		case RESET_PASSWORD_ERROR:
			state = {
				...state,
				error: action.payload.error,
				isLoading: false,
			}
			break
		case VERIFY_TOKEN:
			state = {
				...state,
				errorId: null,
				isVerifyingToken: true,
			}
			break
		case VERIFY_TOKEN_SUCCESS:
			state = {
				...state,
				errorId: action.payload.error_id,
				isVerifyingToken: false,
			}
			break
		case VERIFY_TOKEN_ERROR:
			state = {
				...state,
				error: action.payload.response.message,
				isVerifyingToken: false,
			}
			break
		default:
			state = { ...state }
			break
	}
	return state
}

export default forgetPassword
