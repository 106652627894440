import { isObject } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
	PaginationListStandalone,
	PaginationProvider,
	SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { CardTitle, Col, Row } from 'reactstrap'
import { Flag } from 'semantic-ui-react'
import { hashPassword } from '../../common/Utils/HashingUtilities'
import { countDecimals, roundAtDecimals } from '../../common/Utils/NumberUtilities'
import {
	adaptDateOnLocale,
	adaptTimerString,
	adaptWeekday,
} from '../../common/Utils/StringUtilities'
import TooltipMessage from '../Common/TooltipMessage'
import { items, rowQuantityList, selectRow, styleForFieldList } from './Mock'
import ShipmentOrderDateFilter from './ShipmentOrderDateFilter'

import FileIcon from '../Icons/FileIcon'
import PDFFileIcon from '../Icons/PDFFileIcon'
import STLFileIcon from '../Icons/STLFileIcon'
import STPFileIcon from '../Icons/STPFileIcon'

import { withGetPartConfigOptions } from 'common/Hooks/withGetPartConfigOptions'
import { ConfigTranslations } from 'common/Utils/PartConfigTranslations'
import { isReactElement } from 'common/Utils/reactUtils'
import Dropdown from 'components/Common/Dropdown/Dropdown'
import PartsFinder from 'components/Common/PartsFinder'
import QuoteQuantityPricing from 'components/Common/QuoteQuantityPricing/QuoteQuantityPricing'
import QuoteQuantityPricingForVC from 'components/Common/QuoteQuantityPricing/QuoteQuantityPricingForVC'
import { withTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import ActiveColumnsDropdown from './ActiveColumnsDropdown'
import Counters from './Counters'
import GeneralFilter from './GeneralFilter'
import StatusFilterDropdown from './StatusFilterDropdown'

function getIconComponentByFileExtension({
	src,
	fileExtension,
	tooltipMessage,
}) {
	switch (fileExtension && fileExtension.toLowerCase()) {
		case 'stp':
		case 'step':
			return <STPFileIcon src={src} tooltipMessage={tooltipMessage} />
		case 'stl':
			return <STLFileIcon src={src} tooltipMessage={tooltipMessage} />
		case 'pdf':
			if (tooltipMessage == 'Original')
				return (
					<PDFFileIcon
						invertedColor
						src={src}
						tooltipMessage={tooltipMessage}
					/>
				)
			return <PDFFileIcon src={src} tooltipMessage={tooltipMessage} />
		default:
			return (
				<FileIcon
					src={src}
					fileExtension={fileExtension}
					tooltipMessage={tooltipMessage}
					fileBackgroundColor="#FFFFFF"
					fontColor="#000000"
				/>
			)
	}
}

class Table extends Component {
	constructor(props) {
		super(props)
		this.enableSaveButtonForVC = this.props.enableSaveButtonForVC
		this.handleExpandableRow = this.handleExpandableRow.bind(this)
		this.getCellStyle = this.getCellStyle.bind(this)
		this.getColumnClass = this.getColumnClass.bind(this)
		this.handleOnChangeHeader = this.handleOnChangeHeader.bind(this)
		this.resetHeaderItems = this.resetHeaderItems.bind(this)
		this.changeStatusFilterArray = this.changeStatusFilterArray.bind(this)
		this.changeShipmentDateFilterArray =
			this.changeShipmentDateFilterArray.bind(this)
		this.changeArriveShipingDateFilterArray =
			this.changeArriveShipingDateFilterArray.bind(this)
		this.changeGeneralFilterArray = this.changeGeneralFilterArray.bind(this)

		const styleForField = this.props.styleForFieldList
			? this.props.styleForFieldList
			: styleForFieldList

		this.state = {
			expanded: [],
			isRendering: false,
			items: this.handleItemsWithActions(props.items ? props.items : items),
			defaultPageOptions: {
				totalSize: props.items ? props.items.length : items.length,
				custom: true,
			},
			rowQuantityList: props.rowQuantityList || rowQuantityList,
			styleForFieldList: styleForField,
			header: {},
			statusFilterItems: [],
			orderShipmentDateFilter: [],
			arriveShipingDateFilter: [],
			generalFilter: [],
		}
		styleForFieldList
		this.state = { ...this.state, ...this.getTableColumns() }

		if (props.allowAllItemsPerPage)
			this.state.rowQuantityList.push({
				text: 'All',
				value: this.state.items.length,
			})
	}

	sizePerPageOptionRenderer({ text, page, onSizePerPageChange }) {
		return (
			<li key={text} role="presentation" className="d-flex align-items-center">
				<a
					href="#"
					tabIndex="-1"
					role="menuitem"
					data-page={page}
					onMouseDown={e => {
						e.preventDefault()
						onSizePerPageChange(page)
					}}
					className="btn btn-default btn-primary dropdown-toggle text-white width-3rem"
				>
					{text}
				</a>
			</li>
		)
	}

	componentDidMount() {
		this.setState({ ...this.state, ...this.getTableColumns() })
		this.setState({
			...this.state,
			header: this.props.header,
			isRendering: true,
		})
		this.expandAllRows()
	}
	componentDidUpdate(prevProps, prevState) {
		if (this.props.highlightedRows !== prevProps.highlightedRows) {
			this.setState({ ...this.state, ...this.getTableColumns() })
		}
		if (this.props.header !== prevProps.header) {
			this.setState({ ...this.state, ...this.getTableColumns() })
		}
		if (this.props.items !== prevProps.items) {
			this.setState(
				{
					...this.state,
					items: this.handleItemsWithActions(this.props.items),
					isRendering: false,
				},
				() => this.setState({ ...this.state, isRendering: true }),
			)
		}
		if (this.state.header !== prevState.header) {
			this.setState({
				...this.state,
				...this.getTableColumns(this.state.header),
			})
		}
		if (
			this.state.statusFilterItems !== prevState.statusFilterItems ||
			this.state.orderShipmentDateFilter !==
			prevState.orderShipmentDateFilter ||
			this.state.arriveShipingDateFilter !==
			prevState.arriveShipingDateFilter ||
			this.state.generalFilter !== prevState.generalFilter
		) {
			this.applyFilterStatus()
		}

	}

	getTableColumns(state) {
		if (state) {
			return {
				columns: this.getColumnsFromItem(state),
			}
		}
		return {
			columns: this.getColumnsFromItem(
				this.props.header
					? this.props.header
					: this.props.items
						? this.props.items[0]
						: items[0],
			),
		}
	}
	handleItemsWithActions(items) {
		const newItems = []
		if (Array.isArray(items)) {
			items.forEach(item => {
				let newItem = { ...item }
				this.props.rowActions &&
					this.props.rowActions.forEach(rowAction => {
						newItem = {
							...newItem,
							[rowAction.label]: { ...newItem[rowAction.label], ...rowAction },
						}
					})
				newItems.push(newItem)
			})
		}
		return newItems
	}

	handleExpandableRow(rowId) {
		const { expanded } = this.state

		if (expanded.find(expandedItem => {
			return expandedItem.value == rowId
		})) {
			const newExpanded = []
			expanded.forEach(id => {
				id != rowId && newExpanded.push(id)
			})
			this.setState({ ...this.state, expanded: newExpanded })
		} else {
			expanded.push(rowId)
			this.setState({ ...this.state, expanded })
		}
	}
	expandAllRows() {
		const { items } = this.state
		const newExpanded = []

		items.forEach(item => {
			newExpanded.push(item.id)
		})
		this.setState({ expanded: newExpanded })
	}

	getCellStyle(
		data,
		dataField,
		row,
		styleList,
		pageToNavigate,
		methods,
		state,
		objectField = null,
	) {
		if (!data) return

		const { handleExpandableRow, getCellStyle, t } = methods

		const stylesForField = styleList.find(fieldWrapper =>
			fieldWrapper.field == dataField ? fieldWrapper : null,
		)

		let valueToRender =
			typeof data.text !== 'undefined'
				? data.text
				: typeof data.value !== 'undefined'
					? data.value
					: data
		const action = row[dataField] && row[dataField].action

		const isHighlightedRow = !!(this.props.highlightedRows
			? this.props.highlightedRows.find(element => element == row.id)
			: false)
		const isAbleToModify = !!(this.props.rowsAbleToModify
			? this.props.rowsAbleToModify.find(element => element == row.id)
			: false)

		//Si no hay estilos para el campo devuelve un span con el valueToRender
		if (!stylesForField)
			return (
				<span
					key={hashPassword([row.id?.value, data])}
					className={`${isHighlightedRow && this.props.highlightTextStyle}`}
					onClick={() => action && action(row.id)}
				>
					{valueToRender}
				</span>
			)

		let className

		let type
		stylesForField.styles.forEach(style => {
			if (style && !style.values) {
				className = style.class
				type = style.type
				valueToRender =
					style.nullValue && valueToRender === null
						? style.nullValue
						: valueToRender
			}

			if (className) return

			style &&
				style.values &&
				style.values.forEach(styleValue => {
					if (className) return

					if (styleValue == data || styleValue == data.id) {
						className = style.class
						type = style.type
					}
				})
		})

		if (objectField) {
			switch (objectField) {
				case 'email':
					return (
						<span
							key={hashPassword([row.id?.value, data])}
							className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
								}`}
						>
							{' '}
							<a href={'mailto:' + valueToRender}>{valueToRender}</a>
						</span>
					)
				case 'title':
					return (
						<span
							key={hashPassword([row.id?.value, data])}
							className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
								}`}
						>
							<strong>{valueToRender}</strong>
						</span>
					)
				default:
					return (
						<span
							key={hashPassword([row.id?.value, data])}
							className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
								}`}
							onClick={() => action && action(row.id)}
						>
							{valueToRender}
						</span>
					)
			}
		}
		switch (type) {
			case 'link':
				return <a href={pageToNavigate + valueToRender} target="_blank" rel="noopener noreferrer">{valueToRender}</a>
			case 'tooltip':
				return (
					valueToRender && (
						<TooltipMessage
							className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
								}`}
							message={valueToRender}
						/>
					)
				)
			case 'flag':
				return (
					<Flag
						name={valueToRender.toLowerCase() || ''}
						className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
							}`}
					/>
				)
			case 'rowExpander':
				return (
					<span onClick={() => {
						handleExpandableRow(valueToRender)
						action && action(row.id)
					}}>
						{valueToRender}
					</span>
				)
			case 'clickableImage':
				return (
					<img
						className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
							}`}
						src={data.src}
						onClick={() => data.onClick(row.id)}
					/>
				)
			case 'messagesQuantity':
				return (
					<span key={hashPassword([row.id?.value, data])}>
						{valueToRender && (
							<i
								className={
									'bx bx-sm ' +
									`${className} ${isHighlightedRow && this.props.highlightTextStyle
									}`
								}
							/>
						)}
						{valueToRender}
					</span>
				)
			case 'measure':
				if (countDecimals(Number(valueToRender)) > 2) {
					const numeric = roundAtDecimals(Number(valueToRender), 2)
					if (!isNaN(numeric)) valueToRender = numeric
				}

				return (
					<span
						className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
							}`}
					>
						{valueToRender} {data.measure && data.measure}
					</span>
				)
			case 'volumetricMeasure':
				return (
					<span
						className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
							}`}
					>
						{roundAtDecimals(data.x, 1)} * {roundAtDecimals(data.y, 1)} * {roundAtDecimals(data.z, 1)} {data.measure}
					</span>
				)
			case 'boxIcon':
			case 'chat':
				return (
					<span onClick={() => action(row.id)}>
						{data.tooltipMessage ? (
							<TooltipMessage
								className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
									}`}
								message={data.tooltipMessage}
							/>
						) : (
							<i
								className={
									'bx bx-sm ' +
									`${className} ${isHighlightedRow && this.props.highlightTextStyle
									}`
								}
							/>
						)}
					</span>
				)

			case 'reject':
				const iconHighlightedClass = stylesForField.styles[0]?.highlightClass || null
				if (iconHighlightedClass && isHighlightedRow) {
					className = iconHighlightedClass
				}
				className += ' bg-red rounded-circle p-2 '
				className += isHighlightedRow
				className += ' text-white'

				const tooltipMessage = isHighlightedRow
					? data.declinedTooltipMessage
					: data.tooltipMessage

				return (
					<span onClick={() => isAbleToModify && action(row.id)}>
						{tooltipMessage ? (
							<TooltipMessage
								className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
									}`}
								message={tooltipMessage}
							/>
						) : (
							<i
								className={
									'bx bx-sm ' +
									`${className} ${isHighlightedRow && this.props.highlightTextStyle
									}`
								}
							/>
						)}
					</span>
				)
			case '3DFiles':
			case 'blueprints':
			case 'file':
				return getIconComponentByFileExtension(data)
			case 'quoteInfo': {
				const VC_ID = 4
				const VACUM_CASTING = ConfigTranslations.getTechnologyName({ id: VC_ID, partConfigOptions: this.props.partConfigOptions, language: this.props.language })
				if (row?.technologyId != VC_ID) {

					//Si no es Mole de siliccona se cotiza en el mismo registr
					return (
						<QuoteQuantityPricing
							id={row.id}
							quantities={data.quantities}
							quantityLabel={t('quantity', { ns: 'naming' })}
							unitPriceLabel={t('unitCost', { ns: 'naming' })}
							manufacturingTimeTitleLabel={t('manufacturingTime', { ns: 'naming' })}
							manufacturingTimeLabel={t('days', { ns: 'naming' })}
							disabled={isHighlightedRow || !isAbleToModify}
							checkFields={data.checkFields}
						/>
					)
				} else {
					//Si es molde de silicona, se muestra un botón para cotizar en un dialogo
					return (
						<QuoteQuantityPricingForVC
							id={row.id.toString()}
							partsPerMold={data.quotePpm}
							moldsPerMaster={data.quoteMpm}
							quantities={data.quantities}
							disabled={isHighlightedRow || !isAbleToModify}
							checkFields={data.checkFields}
							enableSaveButtonForVC={this.enableSaveButtonForVC}
						/>
					)
				}
			}
			case 'partInfo':
				return (
					<span>
						<span
							className={`d-block mx-auto width-by-content ${isHighlightedRow && this.props.highlightTextStyle
								}`}
						>
							{getCellStyle(
								data.image,
								'clickableImage',
								row,
								styleList,
								pageToNavigate,
								{ handleExpandableRow, getCellStyle },
								state,
							)}
						</span>
						<span
							className={`d-block ${isHighlightedRow && this.props.highlightTextStyle
								}`}
						>
							<strong>{t('size', { ns: 'naming' })}:</strong>{' '}
							{getCellStyle(
								data.size,
								'size',
								row,
								styleList,
								pageToNavigate,
								{ handleExpandableRow, getCellStyle },
								state,
							)}
						</span>
						<span
							className={`d-block ${isHighlightedRow && this.props.highlightTextStyle
								}`}
						>
							<strong>{t('weight', { ns: 'naming' })}:</strong>{' '}
							{getCellStyle(
								data.weight,
								'weight',
								row,
								styleList,
								pageToNavigate,
								{ handleExpandableRow, getCellStyle },
								state,
							)}
						</span>
					</span>
				)
			case 'fileInfo':
				return (
					<span>
						<span
							className={`d-block fileName-overflow ${isHighlightedRow && this.props.highlightTextStyle
								}`}
						>
							{' '}
							{data.name}{' '}
						</span>
						<span className="d-block mx-auto width-by-content">
							{data.files.map(file => {
								return getCellStyle(
									file,
									'file',
									row,
									styleList,
									pageToNavigate,
									{ handleExpandableRow, getCellStyle },
								)
							})}
						</span>
					</span>
				)
			case 'dropdown': {

				const isMetallic =
					row.materialId && row.materialId === 1
						? true
						: false
				const getOptions = (isMetallic) => {
					const options = [{
						value: 1,
						text: t('construction_type_1', { ns: 'constructionType' }),
						isDisabled: false,
					}]

					if (isMetallic) {
						options.push({
							value: 3,
							text: t('construction_type_3', { ns: 'constructionType' }),
							isDisabled: false,
						})
					} else {
						options.push({
							value: 2,
							text: t('construction_type_2', { ns: 'constructionType' }),
							isDisabled: false,
						})
					}
					return options
				}
				const tooltipId = `tooltip_${row.id}`
				return (
					<div data-tip={t('construction_tooltip', { ns: 'naming' })} data-for={tooltipId}>
						<ReactTooltip
							id={tooltipId}
							place="top"
							type="info"
							effect="solid"
							multiline={false}
						/>
						<Dropdown
							id={row.id}
							disabled={isHighlightedRow || !isAbleToModify}
							value={t(data.text, { ns: 'constructionType' })}
							isHandlingFullObjectOnChange
							options={getOptions(isMetallic)}
							onChange={data.onChange}
							className='w-auto'
							spanClassName="d-flex justify-content-start align-items-center w-100"
						/>
					</div>
				)
			}
			case 'material': {
				return (
					<span
						className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
							}`}
					>
						{row.material.name} {row.material.alloy && row.material.alloy.text}
					</span>
				)
			}
			default:
				return (
					<span
						key={hashPassword([row.id?.value, data])}
						className={`${className} ${isHighlightedRow && this.props.highlightTextStyle
							}`}
						onClick={() => {
							action && action(row.id)
						}}
					>
						{(isObject(valueToRender) && !isReactElement(valueToRender))
							? JSON.stringify(valueToRender)
							: valueToRender}
					</span>
				)
		}
	} //Fin getCellStyle

	tableFormatter(cell, row, rowIndex, formatExtraData) {
		const {
			getCellStyle,
			handleExpandableRow,
			state,
			dataField,
			joinFields,
			t,
		} = formatExtraData

		if (joinFields && joinFields.find(joinField => joinField === dataField)) {
			return getCellStyle(
				joinFields.map(joinField => row[joinField]).join(' '),
				dataField,
				row,
				formatExtraData.styleForFieldList,
				formatExtraData.pageToNavigate,
				{
					handleExpandableRow: formatExtraData.handleExpandableRow,
					getCellStyle,
					t,
				},
				state,
			)
		}

		if (Array.isArray(cell)) {
			return (
				<span key={hashPassword(cell)}>
					{cell.map(element =>
						getCellStyle(
							element,
							dataField,
							row,
							formatExtraData.styleForFieldList,
							formatExtraData.pageToNavigate,
							{
								handleExpandableRow,
								getCellStyle,
								t,
							},
							state,
						),
					)}
				</span>
			)
		}

		if (isObject(cell)) {
			if (Array.isArray(cell.value)) {
				return (
					<span key={hashPassword(cell)}>
						{cell.value.map(element =>
							getCellStyle(
								element,
								dataField,
								row,
								formatExtraData.styleForFieldList,
								formatExtraData.pageToNavigate,
								{
									handleExpandableRow: formatExtraData.handleExpandableRow,
									getCellStyle,
									t,
								},
								state,
							),
						)}
					</span>
				)
			}

			if (isObject(cell.value)) {
				if (cell.value && cell.value.id) {
					return getCellStyle(
						cell.value,
						dataField,
						row,
						formatExtraData.styleForFieldList,
						formatExtraData.pageToNavigate,
						{
							handleExpandableRow: formatExtraData.handleExpandableRow,
							getCellStyle,
							t,
						},
						state,
					)
					//Retorno para valores de tiempo largo
				} else if (
					cell.value &&
					cell.value.getTime &&
					(!cell.kind || cell.kind != 'short')
				) {
					return (
						<span key={hashPassword(cell)}>
							{/*El día de la semana*/}
							{getCellStyle(
								adaptWeekday(cell.value.getDay(), formatExtraData.language),
								dataField,
								row,
								formatExtraData.styleForFieldList,
								formatExtraData.pageToNavigate,
								{
									handleExpandableRow: formatExtraData.handleExpandableRow,
									getCellStyle,
									t,
								},
								state,
							)}
							{/*La fecha (segun locale))*/}
							{getCellStyle(
								adaptDateOnLocale(
									cell.value.getFullYear(),
									cell.value.getMonth(),
									cell.value.getDate(),
									formatExtraData.language,
								),
								dataField,
								row,
								formatExtraData.styleForFieldList,
								formatExtraData.pageToNavigate,
								{
									handleExpandableRow: formatExtraData.handleExpandableRow,
									getCellStyle,
									t,
								},
								state,
							)}
							{/*La hora */}
							{getCellStyle(
								adaptTimerString(
									cell.value.getHours(),
									cell.value.getMinutes(),
									cell.value.getSeconds(),
								),
								dataField,
								row,
								formatExtraData.styleForFieldList,
								formatExtraData.pageToNavigate,
								{
									handleExpandableRow: formatExtraData.handleExpandableRow,
									getCellStyle,
									t,
								},
								state,
							)}
						</span>
					)
					//Los valores de tiempo corto sólo llevan la fecha
				} else if (cell.value && cell.value.getTime && cell.kind == 'short') {
					return (
						<span key={hashPassword(cell)}>
							{/*La fecha dd/mm/yyyy*/}
							{getCellStyle(
								adaptDateOnLocale(
									cell.value.getFullYear(),
									cell.value.getMonth(),
									cell.value.getDate(),
									formatExtraData.language,
								),
								dataField,
								row,
								formatExtraData.styleForFieldList,
								formatExtraData.pageToNavigate,
								{
									handleExpandableRow: formatExtraData.handleExpandableRow,
									getCellStyle,
									t,
								},
								state,
							)}
						</span>
					)
				}
				//Para el resto de valores
				return (
					<span key={hashPassword(cell)}>
						{Object.keys(cell.value).map(field =>
							getCellStyle(
								cell.value[field],
								dataField,
								row,
								formatExtraData.styleForFieldList,
								formatExtraData.pageToNavigate,
								{
									handleExpandableRow: formatExtraData.handleExpandableRow,
									getCellStyle,
									t,
								},
								state,
								field,
							),
						)}
					</span>
				)
			} else if (isObject(cell)) {
				//Aquí hacer lo mismo para fecha que con cell.value, porque la fecha de la tabla de clientes no viene en el campo value
				return getCellStyle(
					cell,
					dataField,
					row,
					formatExtraData.styleForFieldList,
					formatExtraData.pageToNavigate,
					{
						handleExpandableRow: formatExtraData.handleExpandableRow,
						getCellStyle,
						t,
					},
					state,
				)
			}
			return getCellStyle(
				cell.value ? cell.value : cell,
				dataField,
				row,
				formatExtraData.styleForFieldList,
				formatExtraData.pageToNavigate,
				{
					handleExpandableRow: formatExtraData.handleExpandableRow,
					getCellStyle,
					t,
				},
				state,
			)
		}
		return getCellStyle(
			cell,
			dataField,
			row,
			formatExtraData.styleForFieldList,
			formatExtraData.pageToNavigate,
			{
				handleExpandableRow: formatExtraData.handleExpandableRow,
				getCellStyle,
				t,
			},
			state,
		)
	}
	getColumnClass(cell, row, rowIndex, colIndex) {
		if (!this.props.highlightedRows) return

		const highlightedRows = [...this.props.highlightedRows]
		const isHighlightedRow = !!highlightedRows.find(
			highlightedRows => highlightedRows == row.id,
		)
		if (isHighlightedRow) return this.props.highlightStyle
	}
	getColumnsFromItem(item) {
		const columns = []
		Object.keys(item).forEach(field => {
			if (
				this.props.header &&
				!Object.keys(this.props.header).find(
					headerField => headerField == field,
				)
			)
				return
			let joinFields

			this.props.joinFields &&
				this.props.joinFields.forEach(joinField => {
					if (joinField.find && joinField.find(f => f === field))
						joinFields = joinField
				})

			columns.push({
				dataField: field,
				text: this.props.t(
					this.props.header
						? this.props.header[field]
						: item && item[field].label
							? item[field].label
							: item[field],
					{ ns: 'naming' },
				),
				hidden: this.props.hiddenFields && this.props.hiddenFields.find((element) => element === field) ? true : false,
				sort: !this.props.disableSorting,
				classes: this.getColumnClass,
				sortFunc: (a, b, order, dataField, rowA, rowB) => {
					let first
					let second

					if (a.value !== null && b.value !== null && (!a.value || !b.value)) {
						first = a
						second = b
					} else if (
						a.value &&
						b.value &&
						a.value.translation &&
						b.value.translation
					) {
						first = a.value.translation
						second = b.value.translation
					} else if (a.value && b.value && a.value[0] && b.value[0]) {
						first = a.value[0]
						second = b.value[0]
					} else if (a.value && b.value && a.value.title && b.value.title) {
						first = a.value.title
						second = b.value.title
					} else if (a.value && b.value && a.value.getTime && b.value.getTime) {
						first = a.value.getTime()
						second = b.value.getTime()
					} else if (a.value && b.value && a.value.id && b.value.id) {
						first = a.value.id
						second = b.value.id
					} else {
						first = a.value ? a.value : a
						second = b.value ? b.value : b
					}

					const isSortingNumbers =
						!isNaN(Number.parseInt(first, 10)) &&
						!isNaN(Number.parseInt(second, 10))

					if (order === 'asc') {
						return isSortingNumbers
							? second - first
							: this.compareString(first, second)
					}
					return isSortingNumbers
						? first - second
						: this.compareString(second, first)
				},
				formatter: this.tableFormatter,
				formatExtraData: {
					dataField: field,
					getCellStyle: this.getCellStyle,
					handleExpandableRow: this.handleExpandableRow,
					state: this.state,
					styleForFieldList: this.state.styleForFieldList,
					language: this.props.language ? this.props.language : 'en',
					pageToNavigate: this.props.pageToNavigate,
					joinFields,
					t: this.props.t,
				},
			})
		})

		return columns
	}
	compareString(first, second) {
		if (first === null && second === null) {
			return 0
		}
		if (first === null) {
			return -1
		}
		if (second === null) {
			return 1
		}
		return new String(first).localeCompare(new String(second))
	}
	handleOnTableChange(event, tableInfo) {
		if (!this.props.remote) {
			this.node.paginationContext.currPage = 1
		}
	}
	handleDataChange() {
		this.setState({
			...this.state,
			pageOptions: { page: 1 },
		})
	}
	handleOnChangeHeader(item, label) {
		const newHeader = this.getNewHeader(item, label, this.state.header)
		this.setState({
			...this.state,
			header: newHeader,
		})
	}

	resetHeaderItems() {
		this.setState({
			...this.state,
			header: this.props.header,
		})
	}

	getNewHeader(item, label, header) {
		const isInHeader = this.checkIsInHeader(item, header)
		if (isInHeader) {
			return this.removeItem(item, header)
		} else {
			return this.addItemToHeader(item, label, header)
		}
	}

	removeItem(item, header) {
		const headerInArray = Object.entries(header)
		const updatedHeader = {}
		headerInArray.forEach(element => {
			if (element[0] != item) {
				updatedHeader[element[0]] = element[1]
			}
		})
		return updatedHeader
	}

	addItemToHeader(item, label, header) {
		const newHeaderInArray = Object.entries(header)
		const elementsBeforeMyItemInArray = this.getArrayOfElements(item)
		const newItem = [item, label]
		if (elementsBeforeMyItemInArray.length > 0) {
			// eslint-disable-next-line for-direction
			for (
				let i = elementsBeforeMyItemInArray.length - 1;
				i < elementsBeforeMyItemInArray.length;
				i--
			) {
				for (let j = 0; j < newHeaderInArray.length; j++) {
					if (newHeaderInArray[j][0] == elementsBeforeMyItemInArray[i][0]) {
						newHeaderInArray.splice(j + 1, 0, newItem)
						const newHeader = Object.fromEntries(newHeaderInArray)
						return newHeader
					}
				}
			}
		} else {
			newHeaderInArray.unshift(newItem)
			const newHeader = Object.fromEntries(newHeaderInArray)
			return newHeader
		}
	}

	getArrayOfElements(item) {
		const elementsBeforeMyItemInArray = []
		const headerPropsInArray = Object.entries(this.props.header)
		for (let i = 0; i < headerPropsInArray.length; i++) {
			if (headerPropsInArray[i][0] == item) {
				return elementsBeforeMyItemInArray
			}
			elementsBeforeMyItemInArray.push(headerPropsInArray[i])
		}
		return elementsBeforeMyItemInArray
	}

	checkIsInHeader(item, header) {
		let check = false
		Object.entries(header).forEach(element => {
			if (element[0] == item) {
				check = true
			}
		})
		return check
	}

	changeListItems(listFiltred) {
		this.setState({
			...this.state,
			items: listFiltred,
		})
	}

	changeStatusFilterArray(statusItems) {
		this.setState({
			...this.state,
			statusFilterItems: statusItems,
		})
	}

	changeShipmentDateFilterArray(filterItems) {
		this.setState({
			...this.state,
			orderShipmentDateFilter: filterItems,
		})
	}

	changeArriveShipingDateFilterArray(filterItems) {
		this.setState({
			...this.state,
			arriveShipingDateFilter: filterItems,
		})
	}

	changeGeneralFilterArray(statusItems) {
		this.setState({
			...this.state,
			generalFilter: statusItems,
		})
	}

	getStatusArray() {
		const statusArray = []
		this.props.items.forEach(element => {
			if (!element.status || element.status.value.id === 0) return
			if (!statusArray.find(status => status.id === element.status.value.id)) {
				statusArray.push(element.status.value)
			}
		})
		return statusArray
	}

	applyFilterStatus() {
		const arrayFiltredByStatus = this.getArrayFiltred()
		const arrayFiltredByOrderShipingDate =
			this.getArrayFiltredByShipmentOrderDate(arrayFiltredByStatus)
		const arrayFiltredByArrivalShipingDate =
			this.getArrayFiltredByArrivalShipingDate(arrayFiltredByOrderShipingDate)
		const arrayFiltredByGeneral = this.getArrayFiltredByGeneral(
			arrayFiltredByArrivalShipingDate,
		)
		this.changeListItems(arrayFiltredByGeneral)
	}

	getArrayFiltredByShipmentOrderDate(array) {
		if (this.state.orderShipmentDateFilter.length != 0) {
			return array.filter(element =>
				this.isElementShipingOrderDateInOptions(
					element.filterShipmentOrderDate,
					this.state.orderShipmentDateFilter,
				),
			)
		}
		return array
	}

	getArrayFiltredByArrivalShipingDate(array) {
		if (this.state.arriveShipingDateFilter.length != 0) {
			return array.filter(element =>
				this.isElementShipingOrderDateInOptions(
					element.filterArriveShipingDate,
					this.state.arriveShipingDateFilter,
				),
			)
		}
		return array
	}

	getArrayFiltredByGeneral(array) {
		if (this.state.generalFilter.length != 0) {
			return array.filter(element =>
				this.isElementInGeneralFilter(
					element['supplier'],
					this.state.generalFilter,
				),
			)
		}
		return array
	}

	isElementInGeneralFilter(generalItem, options) {
		if (options.find(option => option.text == generalItem)) {
			return true
		}
		return false
	}

	isElementShipingOrderDateInOptions(filterShipmentOrderDate, options) {
		if (options.find(option => option == filterShipmentOrderDate)) {
			return true
		}
		return false
	}

	getArrayFiltred() {
		if (this.state.statusFilterItems.length != 0) {
			const arrayFiltred = []
			this.props.items.forEach(element => {
				this.state.statusFilterItems.forEach(stat => {
					if (element.status.value.text == stat) {
						arrayFiltred.push(element)
					}
				})
			})
			return arrayFiltred
		}
		return this.props.items
	}

	render() {
		const { SearchBar } = Search
		const { t } = this.props
		const statusArray = this.props.showStatusFilter && this.getStatusArray()
		return (
			<React.Fragment>
				{this.state.isRendering && (
					<div
						className={
							'container-fluid ' + this.props.className && this.props.className
						}
					>
						<Row>
							<Col className="col-12">
								{this.props.title ? (
									<CardTitle className="h4">{this.props.title}</CardTitle>
								) : null}
								{this.props.description ? (
									<p className="card-title-desc">{this.props.description}</p>
								) : null}
								<PaginationProvider
									pagination={
										this.props.paginationProps ? paginationFactory({
											...this.props.paginationProps,
											sizePerPageOptionRenderer: this.props.paginationProps.sizePerPageOptionRenderer || this.sizePerPageOptionRenderer,
											sizePerPageList: this.props.rowQuantityList || rowQuantityList,
										}) : paginationFactory({
											...this.state.defaultPageOptions,
										})
									}
									keyField="id"
									columns={this.state.columns}
									data={this.state.items}
								>

									{({ paginationProps, paginationTableProps }) => {

										return (
											<ToolkitProvider
												keyField="id"
												columns={this.state.columns}
												data={this.state.items}
												search
											>
												{toolkitProps => (
													<React.Fragment>
														{!this.props.disableInteractions && (
															<Row className="mb-2">
																<Col md="12">
																	<div className="d-flex justify-content-between align-items-center">
																		<div className="d-flex justify-content-start">
																			<div className="search-box me-2 mb-2 d-inline-block">

																				<div className={'position-relative' + (this.props.customFilter && ' d-none')} >
																					<SearchBar
																						{...toolkitProps.searchProps}
																						placeholder={t('search', {
																							ns: 'naming',
																						})}
																					/>
																					<i className="ms-3 bx bx-search-alt search-icon" />
																				</div>

																			</div>
																			{this.props.showActiveColumFilter && (
																				<div className="mx-3">
																					<ActiveColumnsDropdown
																						header={this.props.header}
																						handleOnChangeHeader={
																							this.handleOnChangeHeader
																						}
																						resetHeader={this.resetHeaderItems}
																					/>
																				</div>
																			)}
																			{statusArray && (
																				<div className={'mx-3 ' + (this.props.customFilter && 'd-none')}>
																					<StatusFilterDropdown
																						statusArray={statusArray}
																						onChange={
																							this.changeStatusFilterArray
																						}
																					/>
																				</div>
																			)}
																			{this.props.showShipmentOrderDateFilter && (
																				<div className="mx-3">
																					<ShipmentOrderDateFilter
																						onChange={
																							this.changeShipmentDateFilterArray
																						}
																						title={
																							this.props
																								.showShipmentOrderDateFilter
																						}
																					/>
																				</div>
																			)}
																			{this.props.showArriveSipingDateFilter && (
																				<div className="mx-3">
																					<ShipmentOrderDateFilter
																						onChange={
																							this
																								.changeArriveShipingDateFilterArray
																						}
																						title={
																							this.props
																								.showArriveSipingDateFilter
																						}
																					/>
																				</div>
																			)}
																			{this.props.generalFilter && (
																				<div className="mx-3">
																					<GeneralFilter
																						onChange={
																							this.changeGeneralFilterArray
																						}
																						title="suppliers"
																						optionsToFilter={
																							this.props.generalFilter
																						}
																					/>
																				</div>
																			)}
																		</div>

																		<div className="me-2">

																			{this.props.showPartsFinder &&
																				<PartsFinder />
																			}
																		</div>
																	</div>

																</Col>
															</Row>
														)}
														<Row>
															<Col xl="12">
																<div className="table-responsive">
																	<BootstrapTable
																		className={this.props.tableClassName}
																		bootstrap4
																		remote={this.props.remote}
																		onDataSizeChange={() => {
																			this.handleOnTableChange()
																		}}
																		onTableChange={(event, tableInfo) => {
																			this.handleOnTableChange(event, tableInfo)
																		}}
																		ref={n => (this.node = n)}
																		keyField={'id'}
																		responsive
																		bordered={false}
																		striped={!this.props.disableStriped}
																		defaultSorted={
																			this.props.defaultSort && [
																				this.props.defaultSort,
																			]
																		}
																		selectRow={
																			this.props.selectRow
																				? this.props.selectRow
																				: selectRow
																		}
																		expandRow={{
																			...this.props.expandRow,
																			expanded: this.state.expanded,
																		}}
																		classes={'table align-middle table-nowrap table-no-backgorund bg-white'}
																		headerWrapperClasses={'thead-light'}
																		{...toolkitProps.baseProps}
																		{...paginationTableProps}
																		noDataIndication="No hay datos"
																	/>
																</div>
															</Col>
														</Row>

														{!this.props.disableInteractions && (
															<Row className="align-items-center mt-30">
																<Col className="inner-custom-pagination d-flex">
																	<div className="d-flex flex-row">
																		<SizePerPageDropdownStandalone
																			{...paginationProps}
																			className="buttonDisplayContent"
																		/>
																	</div>
																	<div className="d-flex flex-row align-items-center ms-auto">
																		<div className='mx-2 mt-3'><Counters counters={this.props.counters} /></div>

																		<PaginationListStandalone
																			{...paginationProps}
																		/>
																	</div>
																</Col>
															</Row>
														)}

													</React.Fragment>
												)}
											</ToolkitProvider>
										)
									}}
								</PaginationProvider>

							</Col>
						</Row>
					</div>
				)}
			</React.Fragment>
		)
	}
}

Table.propTypes = {
	hiddenFields: PropTypes.arrayOf(PropTypes.string),
	disableStriped: PropTypes.bool,
	enableSaveButtonForVC: PropTypes.func,
	title: PropTypes.string,
	language: PropTypes.string,
	description: PropTypes.string,
	allowAllItemsPerPage: PropTypes.bool,
	pageToNavigate: PropTypes.string,
	rowQuantityList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			value: PropTypes.number,
		}),
	),
	defaultSort: PropTypes.shape({
		dataField: PropTypes.string,
		order: PropTypes.string,
	}),
	items: PropTypes.arrayOf(PropTypes.object),
	header: PropTypes.object,
	styleForFieldList: PropTypes.arrayOf(
		PropTypes.shape({
			field: PropTypes.string,
			styles: PropTypes.arrayOf(
				PropTypes.shape({
					type: PropTypes.string,
					values: PropTypes.array,
					class: PropTypes.string,
				}),
			),
		}),
	),
	expandRow: PropTypes.object,
	disableInteractions: PropTypes.bool,
	selectRow: PropTypes.object,
	className: PropTypes.string,
	rowActions: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			action: PropTypes.func,
		}),
	),
	highlightedRows: PropTypes.arrayOf(PropTypes.number),
	highlightStyle: PropTypes.string,
	highlightTextStyle: PropTypes.string,
	rowsAbleToModify: PropTypes.arrayOf(PropTypes.number),
	disableSorting: PropTypes.bool,
	joinFields: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
	t: PropTypes.func,
	showActiveColumFilter: PropTypes.bool,
	showStatusFilter: PropTypes.bool,
	showShipmentOrderDateFilter: PropTypes.bool,
	showArriveSipingDateFilter: PropTypes.bool,
	generalFilter: PropTypes.array,
	itemToFilterInTable: PropTypes.string,
	tableClassName: PropTypes.string,
	paginationProps: PropTypes.any,
	remote: PropTypes.bool,
	sizePerPage: PropTypes.number,
	showPartsFinder: PropTypes.bool,
	customFilter: PropTypes.bool,
	counters: PropTypes.array,

}
export default withGetPartConfigOptions(withTranslation()(Table))
